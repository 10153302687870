import type { RootState } from '.';
import { Reducer } from 'redux';

import { ActionTypes, EntitiesActionTypes } from '../actions/types';
import { DrawerAction } from '@copilot/common/utils/drawerManager/action';
import { InboxDisplayType } from '@copilot/data/requests/models';
import { DrawerTypes } from '@copilot/common/utils/drawerManager';

export interface AppState {
	impersonator?: string;
	impersonatorId?: string;
	adminUser?: string;
	user?: string;
	tenant?: string;
	drawer?: DrawerAction;
	organization?: string;
	settings?: {
		inboxType?: InboxDisplayType;
		hideLiSearchVideo?: boolean;
		isCampaignAccordionExpanded?: boolean;
		isAdvanced?: boolean;
	};
	isFetchingData?: boolean;
	userHash?: string;
}
const initialState: RootState['app'] = { user: undefined, settings: {}, userHash: undefined };

export type ReducerAction = {
	type: ActionTypes | EntitiesActionTypes | string;
	payload?: any;
	itemType?: any;
	dirtyModelIds?: any;
};

export const AppReducer: Reducer<RootState['app']> = (
	state: RootState['app'] = initialState,
	action: ReducerAction = { type: '' }
): RootState['app'] => {
	switch (action.type) {
		case ActionTypes.LoadSettings:
			return { ...state, settings: action.payload };
		case ActionTypes.UpdateSettings:
			return { ...state, settings: { ...state.settings, ...action.payload } };
		case ActionTypes.SetActiveUser:
			return { ...state, user: action.payload };
		case ActionTypes.SetActiveTenant:
			return { ...state, tenant: action.payload };
		case ActionTypes.SetActiveImpersonator:
			return { ...state, ...action.payload };
		case ActionTypes.SetActiveUserHash:
			return { ...state, userHash: action.payload };
		case ActionTypes.SetAdminUser:
			return { ...state, adminUser: action.payload };
		case ActionTypes.RemoveActiveUser:
			return { ...state, user: undefined };
		case ActionTypes.OpenDrawer:
			return {
				...state,
				drawer: new DrawerAction(action.payload.type as DrawerTypes, action.payload.props),
			};
		case ActionTypes.CloseDrawer:
			return { ...state, drawer: undefined };
		case ActionTypes.SetActiveOrganization:
			return { ...state, organization: action.payload };
		case ActionTypes.RemoveActiveOrganization:
			return { ...state, organization: undefined };
		case ActionTypes.IsFetchingData:
			return { ...state, isFetchingData: action.payload };
	}
	return state;
};
