import { ReferenceDot } from 'recharts';
import { FC, Key, SVGProps } from 'react';
import { ReactComponent as MessageIcon } from '@copilot/common/assets/icon/filled/message.svg';
import { ReactComponent as SearchIcon } from '@copilot/common/assets/icon/filled/search.svg';
import { ReactComponent as EditIcon } from '@copilot/common/assets/icon/filled/edit.svg';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { Tooltip } from 'antd';
import { PerformanceChartLabels } from './labels';

export const EventNames = {
	/**
	 * Search list updated
	 */
	SearchListUpdate: 'SearchListUpdate',
	/**
	 * Automated messages updated
	 */
	AutomatedMessagesUpdate: 'AutomatedMessagesUpdate',
	/**
	 * Automated messages and Search list updated
	 */
	AutomatedMessagesAndSearchListUpdate: 'AutomatedMessagesAndSearchListUpdate',
} as const;

const EventProperties = {
	timestamp: 'timestamp',
	type: 'type',
} as const;

export type EventNamesType = keyof typeof EventNames;

type EventType = {
	/**
	 * What type of event
	 */
	[EventProperties.type]: EventNamesType;
	/**
	 * When the event happened
	 */
	[EventProperties.timestamp]: number;
};

/**
 * Helper to map an event to an icon
 * @param type
 */
function getEventIconByType(type: EventNamesType): FC<SVGProps<SVGSVGElement>> {
	switch (type) {
		case EventNames.AutomatedMessagesUpdate:
			return MessageIcon;
		case EventNames.SearchListUpdate:
			return SearchIcon;
		case EventNames.AutomatedMessagesAndSearchListUpdate:
			return EditIcon;
		default:
			return UtilityFunctions.assertUnreachable(type);
	}
}

/**
 * Helper to map an event to the tooltip text
 * @param type
 */
function getEventTooltipByType(type: EventNamesType): string {
	switch (type) {
		case EventNames.AutomatedMessagesUpdate:
			return PerformanceChartLabels.AutomatedMessageChange;
		case EventNames.SearchListUpdate:
			return PerformanceChartLabels.SearchListChange;
		case EventNames.AutomatedMessagesAndSearchListUpdate:
			return PerformanceChartLabels.AutomatedAndSearchlist;
	}
}

type CampaignStatisticChartEventPropsType = {
	/**
	 * Icon size in px. Default to be 20
	 */
	iconSize?: number;
	/**
	 * Component Key
	 */
	key: Key;
} & EventType;

/**
 * [Presentational] Campaign events on the campaign performance tracking chart.
 * Please do not use this component directly.
 * @param props
 * @constructor
 */
function PerformanceChartEventInternal(props: CampaignStatisticChartEventPropsType) {
	const { timestamp, type, iconSize = 20, key } = props;
	const EventIcon = getEventIconByType(type);

	return (
		<ReferenceDot
			shape={({ cx, cy }) => (
				<Tooltip
					placement="right"
					overlayStyle={{ maxWidth: '500px' }}
					title={getEventTooltipByType(type)}
				>
					<EventIcon
						x={cx - iconSize / 2}
						y={cy - iconSize / 2}
						width={iconSize}
						height={iconSize}
					/>
				</Tooltip>
			)}
			x={timestamp}
			y={0}
			key={key}
		/>
	);
}

export default PerformanceChartEventInternal;
