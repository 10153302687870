import React from 'react';
import { Typography } from 'antd';

const { Text, Title } = Typography;

interface ICarouselItemProps {
	topText: string;
	titleText: string;
	bodyText: string;
	image: string;
}

/**
 * [Presentational] Returns a single pane of a carousel
 * @param props
 * @param topText - the small heading displayed under the image & above the titel
 * @param titleText - the heading of the carousel item
 * @param bodyText - the primary text content of the carousel item
 * @param image - the image to be displayed at the top of the carousel item
 * @returns <CarouselItem> - a single pane of a carousel
 */
const CarouselItem: React.FC<ICarouselItemProps> = (props) => {
	const { topText, titleText, bodyText, image } = props;

	// marginBottom is utilized to reposition the slide dots of the carousel
	return (
		<div style={{ marginBottom: '50px', textAlign: 'center' }}>
			<img style={{ height: '214px', display: 'block', margin: 'auto' }} src={image} />
			<figcaption style={{ marginTop: '15px' }}>{topText}</figcaption>
			<Title level={4} style={{ marginTop: '0.5em' }}>
				{titleText}
			</Title>
			<Text style={{ fontSize: '16px' }}>{bodyText}</Text>
		</div>
	);
};

export default CarouselItem;
