export const ASYNC_TASK_STATUS = {
	UnexpectedStop: -3,
	Aborted: -2,
	Failed: -1,
	Created: 0,
	InProgress: 1,
	Paused: 2,
	Complete: 3,
} as const;

/**
 * Status enum of an async task
 */
export type AsyncTaskStatus = ValueOf<typeof ASYNC_TASK_STATUS>;

/**
 * Status of a campaign related task
 */
export type CampaignTaskStatus = {
	hasAssociatedRunningTask: boolean; //is there a task running
	taskData?: {
		status: AsyncTaskStatus; //what is the status of the task
		campaignId: string; // what campaign is this task for
		taskId: string; //what is the id of the task
	};
};
