import { UtilityFunctions } from '@copilot/common/utils/common';
import { CampaignLaunchErrors } from '@copilot/data/responses/interface';

export const NoEnabledMembersElement = <span>At least one team member's status set to Active</span>;
export const NoSalesNavUrlElement = <span>Have a Sales Navigator URL in your Search List</span>;
export const NoCampaignSequenceElement = (
	<span>A Connection Request Message set up in Automated Messaging</span>
);
export const NoServiceScheduleElement = (
	<span>At least one day enabled in Timezone and Sending Hours</span>
);

/**
 * Get the right launch error element based on the error
 * @param error The error we encountered
 */
export const getCampaignLaunchErrorElement = (error: CampaignLaunchErrors): JSX.Element => {
	switch (error) {
		case CampaignLaunchErrors.NoEnabledMembers:
			return NoEnabledMembersElement;
		case CampaignLaunchErrors.NoSalesNavUrl:
			return NoSalesNavUrlElement;
		case CampaignLaunchErrors.NoCampaignSequence:
			return NoCampaignSequenceElement;
		case CampaignLaunchErrors.NoServiceSchedule:
			return NoServiceScheduleElement;
		default:
			return UtilityFunctions.assertUnreachable(error);
	}
};
