import { ColumnProps } from 'antd/lib/table';

import BaseColumn from './base';
import { TextWithTagsColumnDefinition } from '../../componentModels/columnDefinition';
import { Row, Col, Tag } from 'antd';
import { sortByFilteredTags } from '@copilot/common/utils/filters';

const TAG_LENGTH = 5;
const TEXT_LENGTH = 70;

export class TextWithTagsColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];
	public sorter: ColumnProps<M>['sorter'];

	constructor(c: TextWithTagsColumnDefinition) {
		super(c);

		this.render = (text: string, record: M) => {
			const { properties = [] } = c;
			const values = this.getValues<any>(properties, record);
			const textDisplay = values?.[0]
				? values[0].length > TEXT_LENGTH
					? `${values[0].substring(0, TEXT_LENGTH)}...`
					: values[0]
				: '';
			const tagsDisplay = Array.isArray(values) && values.length > 1 ? values[1] : values;
			const filteredTags = new Set<string>(values[3] as Iterable<string>);
			const tagItems: JSX.Element[] = tagsDisplay
				.slice(0, TAG_LENGTH)
				.map((tag: { tagId: string; name: string }) => {
					const color = filteredTags.has(tag.name) ? 'blue' : '';
					return (
						<Tag color={color} key={tag.tagId}>
							{tag.name}
						</Tag>
					);
				});
			const sortedTags = sortByFilteredTags(tagItems, filteredTags);

			return (
				<>
					<Row>
						<Col>{`"${textDisplay}"`}</Col>
					</Row>
					<Row
						style={{
							fontWeight: 'normal',
						}}
					>
						<Col>
							{sortedTags}
							{Array.isArray(tagsDisplay) && tagsDisplay.length > TAG_LENGTH && (
								<Tag>+{tagsDisplay.length - TAG_LENGTH} more </Tag>
							)}
						</Col>
					</Row>
				</>
			);
		};
	}
}
