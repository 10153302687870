import { FC } from 'react';
import { TextEditorContextActions, useTextEditorContext } from './context';
import { Input } from 'antd';
import styled from 'styled-components';

/** Props for Text Editor Body */
export interface ITextEditorBodyProps {
	/** Text as a placeholder */
	placeholder?: string;
	/** Whether the input is disabled */
	disabled?: boolean;
	/** Automatic sizing of text area */
	autoSize?: boolean | object;
	/** Whether the textBody is bordered */
	bordered?: boolean;
	/** Callback for when textBody is focused */
	onFocus?: () => void;
	/** Callback for when textBody is blurred */
	onBlur?: () => void;
	/** Callback for textbox value changes */
	onChange?: (value: string) => void;
}

/**
 * This component is used to render the body of the Text Editor.
 */
export const TextEditorBody: FC<ITextEditorBodyProps> = (props) => {
	const { placeholder, disabled, autoSize, bordered, onChange, onFocus, onBlur, children } =
		props;
	const { disabled: contextDisabled, text, dispatchUpdate } = useTextEditorContext();
	return (
		<>
			<StyledTextArea
				value={text}
				placeholder={placeholder}
				disabled={disabled ?? contextDisabled}
				autoSize={autoSize}
				bordered={bordered}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={(e) => {
					const newValue = e.target.value;
					dispatchUpdate({
						type: TextEditorContextActions.setText,
						payload: { text: newValue },
					});
					onChange?.(newValue);
				}}
			/>
			{children}
		</>
	);
};

const StyledTextArea = styled(Input.TextArea)`
	margin: 0px;
	padding: 4px 12px;

	::placeholder {
		color: ${(props) => props.theme['@Gray/7']};
	}
`;
