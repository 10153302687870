import React from 'react';
import styled from 'styled-components';

interface EllipsisParagraphProps {
	text: string;
	style?: React.CSSProperties;
}

const Ellipsis = styled.p`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin: 0px;
	width: 90%;
`;
const EllipsisParagraph: React.FC<EllipsisParagraphProps> = (props) => {
	const { text, style } = props;
	const showFullName = (e: React.MouseEvent<HTMLSpanElement>) => {
		const target = e.currentTarget;
		if (target.offsetWidth < target.scrollWidth && target.textContent) {
			target.setAttribute('title', target.textContent);
		}
	};

	return (
		<Ellipsis onMouseEnter={(e) => showFullName(e)} style={style}>
			{text}
		</Ellipsis>
	);
};

export default EllipsisParagraph;
