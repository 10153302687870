import React, { useCallback } from 'react';
import BasicContainer from '@copilot/common/components/containers/basic';
import { CampaignType } from '@copilot/data/responses/interface';
import ColorSetting from '@copilot/common/components/colorSetting';
import DeleteButton from '@copilot/common/components/button/delete';
import { CampaignSettingsLabels, updateCampaignColor, deleteCampaign } from './helper';
import { useCanEditPermission } from '@copilot/common/hooks/permission';
import { PermissionName } from '@copilot/common/hooks/permission/interface';

interface CampaignDashboardSettingsProps {
	campaign: { id: string; type: CampaignType; organizationId: string; primaryColour: string };
	deleteCampaignCallback?: () => void;
}

/**
 * The Settings tab for a campaign
 * @param {object} campaign the current campaign
 * @param {function} deleteCampaignCallback called after a campaign gets deleted
 */
const CampaignDashboardSettings: React.FC<CampaignDashboardSettingsProps> = (props) => {
	const { campaign, deleteCampaignCallback } = props;
	const isSettingsEditable = useCanEditPermission(PermissionName.Settings);

	const handleColorChange = useCallback(
		(colorHex: string) => {
			updateCampaignColor(campaign.id, colorHex);
		},
		[campaign.id]
	);

	const handleDeleteCampaign = useCallback(() => {
		deleteCampaign(campaign.id, deleteCampaignCallback);
	}, [campaign.id, deleteCampaignCallback]);

	return (
		<BasicContainer>
			<BasicContainer.Header>Campaign Settings</BasicContainer.Header>
			<BasicContainer.Content>
				<ColorSetting
					settingLabel={CampaignSettingsLabels.ColorLabel}
					initColor={campaign.primaryColour}
					handleColorChange={handleColorChange}
				/>
				<br />
				<br />
				{isSettingsEditable && (
					<DeleteButton
						label={CampaignSettingsLabels.DeleteLabel}
						confirmText={CampaignSettingsLabels.DeleteConfirm}
						deleteAction={handleDeleteCampaign}
					/>
				)}
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default CampaignDashboardSettings;
