import useTracking from '@copilot/common/hooks/tracking';

/**
 * Tracking for the inbox page card view
 */
type InboxPageCardViewTrackingType =
	| 'Switch to List View'
	| 'Set Reminder Clicked'
	| 'Set Reminder Specified'
	| 'Dismiss Conversation'
	| 'View Prospect Drawer'
	| 'Click and Send Template Clicked'
	| 'Resume Automation'
	| 'Send to Outbox'
	| 'Campaign Switch Dropdown Clicked'
	| 'Campaign Switched'
	| 'Complete Reminder'
	| 'Edit Reminder';

export type InboxPageCardViewTrackingParams = {
	readonly buttonClicked: InboxPageCardViewTrackingType;
	/**
	 * For Campaign Switched - the name of the campaign
	 */
	readonly label?: string;
	/**
	 * For Set Reminder Specified - the time span from now in ms
	 */
	readonly interval?: number;
};

export const useInboxPageCardViewTracking = (
	componentName: string,
	params: InboxPageCardViewTrackingParams | null = null
): ((newParams: InboxPageCardViewTrackingParams) => void) =>
	useTracking(componentName, 'inbox_page_card_view', params);
