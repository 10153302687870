import { FC, useState, useCallback } from 'react';
import NameEditorComponent from './nameEditor';
import SetOnboardingComponent from './setOnboarding';
import { ICampaignCreationModalProps } from './const';
import BaseModal from '@copilot/common/components/modals/wrappers/base';
import BasicWizardContainer from '@copilot/common/components/wizard/basic/wizardContainer';
import { ModalType } from '@copilot/common/store/models/redux/modal';
import { useCloseModal } from '@copilot/common/hooks/modal';

enum CampaignCreatorWizardSteps {
	NameEditor,
	SetOnboarding,
}

/**
 * [Presentational] Modal for creating a new campaign
 * @param {string} nameEditorDescription header for the name editor
 * @param {NameValidator} nameEditorValidator validates the name in the name editor
 * @param {boolean} showOnboardingOption whether we want to show the set onboarding step
 * @param {function} onCreate called when all steps are completed
 */
const CampaignCreationModal: FC<ICampaignCreationModalProps> = (props) => {
	const {
		nameEditorDescription,
		nameEditorValidator,
		showOnboardingOption = false,
		width = 400,
		centered = true,
		...rest
	} = props;
	const [currentStep, setCurrentStep] = useState<CampaignCreatorWizardSteps>(
		CampaignCreatorWizardSteps.NameEditor
	);
	const [campaignName, setCampaignName] = useState<string>('');
	const [showOnboarding, setShowOnboarding] = useState<boolean>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const closeModal = useCloseModal(ModalType.CampaignCreationModal);

	const onCreate = useCallback(
		async (name: string, isOnboarded?: boolean) => {
			if (name) {
				setIsLoading(true);
				await props.onCreate(name, isOnboarded);
				setIsLoading(false);
				closeModal();
			}
		},
		[props.onCreate, closeModal]
	);

	const onNameEditorContinueClick = useCallback(
		(name: string) => {
			if (showOnboardingOption) {
				setCampaignName(name);
				setCurrentStep(CampaignCreatorWizardSteps.SetOnboarding);
			} else {
				onCreate(name);
			}
		},
		[showOnboardingOption, onCreate]
	);

	const onOnboardingOptionSelected = useCallback(
		(isSelected: boolean) => {
			setShowOnboarding(isSelected);
			onCreate(campaignName, !isSelected);
		},
		[campaignName, onCreate]
	);

	return (
		<BaseModal width={width} centered={centered} {...rest}>
			<BasicWizardContainer farthestNode={currentStep}>
				<BasicWizardContainer.Step id={CampaignCreatorWizardSteps.NameEditor}>
					<NameEditorComponent
						description={nameEditorDescription}
						nameValidator={nameEditorValidator}
						onContinue={onNameEditorContinueClick}
						isLoading={isLoading}
					/>
				</BasicWizardContainer.Step>
				<BasicWizardContainer.Step id={CampaignCreatorWizardSteps.SetOnboarding}>
					<SetOnboardingComponent
						onOnboardingOptionSelected={onOnboardingOptionSelected}
						showOnboarding={showOnboarding}
						isLoading={isLoading}
					/>
				</BasicWizardContainer.Step>
			</BasicWizardContainer>
		</BaseModal>
	);
};

export default CampaignCreationModal;
