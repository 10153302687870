import { ComponentProps } from 'react';
import moment, { Moment } from 'moment';
import {
	ResponsiveContainer,
	LineChart,
	CartesianGrid,
	XAxis,
	YAxis,
	Legend,
	Line,
	ResponsiveContainerProps,
	Tooltip,
} from 'recharts';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { NumDaysInWeek, WeekDays } from '@copilot/common/utils/time';
import { getFormattedDate } from '@copilot/common/utils/dateFormat';
import PerformanceChartEventInternal from './event';
import PerformanceChartTooltipInternal from './tooltip';
import { PerformanceChartLabels } from './labels';
import { useTheme } from 'styled-components';
import { includes, isEmpty, keys } from 'lodash';
import PerformanceChartLegendsInternal from './legends';
import { HasTimestamp, PerformanceProperties, Timestamp } from './constant';
import { spacingMD, spacingXLG } from '@copilot/common/constant/commonStyles';
import { AxisDomain } from 'recharts/types/util/types';

/**
 * Performance data points
 */
type PerformanceDataPointType = {
	/**
	 * Campaign's connection rate
	 */
	[PerformanceProperties.connectionRate]: number;
	/**
	 * Campaign's Reply rate
	 */
	[PerformanceProperties.replyRate]: number;
} & HasTimestamp;

type PerformanceChartInternalPropsType = {
	/**
	 * Chart's start time in unix timestamp (millisecond)
	 */
	start: number;
	/**
	 * Chart's end time in unix timestamp (millisecond)
	 */
	end: number;
	/**
	 * Campaign events
	 */
	events?: ComponentProps<typeof PerformanceChartEventInternal>[];
	/**
	 * Campaign performance data
	 */
	data: PerformanceDataPointType[];
	/**
	 * data keys to plot on the chart. Default to all data keys
	 */
	dataKeys?: (keyof typeof PerformanceProperties)[];
} & Omit<ResponsiveContainerProps, 'children'>;

/**
 * Get the following day of week after the timestamp
 * @param timestamp
 * @param dayInWeek
 */
function getFollowingWeekday(
	timestamp: Moment,
	dayInWeek: typeof WeekDays[keyof typeof WeekDays]
): Moment {
	return timestamp
		.clone()
		.add((dayInWeek - timestamp.day() + NumDaysInWeek) % NumDaysInWeek, 'day');
}

/**
 * Get weekly time ticks
 * @param start
 * @param end
 * @param dayInWeek
 * @returns
 */
function getWeeklyTicks(
	start: number,
	end: number,
	dayInWeek: typeof WeekDays[keyof typeof WeekDays]
): number[] {
	const startTime = moment(start);
	const endTime = moment(end);
	const result = [];
	const current = getFollowingWeekday(startTime, dayInWeek);

	while (current.isSameOrBefore(endTime)) {
		result.push(current.clone().valueOf());
		current.day(NumDaysInWeek + dayInWeek);
	}

	return result;
}

/**
 * Set the y-axis maximum to 100% if data is empty or both connection rate and reply rate is zero
 * @param data
 * @param dataKeys
 * @returns
 */
function getYMax(
	dataKeys: ('connectionRate' | 'replyRate')[],
	data: PerformanceDataPointType[]
): AxisDomain {
	const isReplyRateZero = data.every((point) => point.replyRate === 0);
	const isConnectionZero = data.every((point) => point.connectionRate === 0);
	if (isEmpty(data) || isEmpty(dataKeys) || (isConnectionZero && isReplyRateZero)) {
		return [0, 1];
	}

	return [0, 'auto'];
}

/**
 * Default to all data keys
 */
const DefaultPerformanceDataKeys = keys(PerformanceProperties) as Array<
	keyof typeof PerformanceProperties
>;

/**
 * [Presentational] Campaign performance tracking chart with campaign events.
 * Please do not use this component directly.
 * @param props
 * @constructor
 */
function PerformanceChartInternal(props: PerformanceChartInternalPropsType) {
	const { start, end, data, events = [], dataKeys = DefaultPerformanceDataKeys, ...rest } = props;
	const theme = useTheme();
	return (
		<ResponsiveContainer {...rest}>
			<LineChart
				data={data}
				margin={{
					top: theme['@spacer-num-sm'],
					bottom: theme['@spacer-num-sm'],
					left: theme['@spacer-num-sm'],
					right: theme['@spacer-num-sm'],
				}}
			>
				<CartesianGrid vertical={false} />
				<XAxis
					dataKey={Timestamp}
					type="number"
					domain={[start, end]}
					ticks={getWeeklyTicks(start, end, WeekDays.Monday)}
					tickFormatter={(tick: number) => getFormattedDate(tick, 'MMM D')}
					tickSize={theme['@spacer-num-sm']}
				/>
				<YAxis
					type="number"
					tickFormatter={(tick: number) =>
						UtilityFunctions.convertDecimalToPercentage(tick, 0)
					}
					axisLine={false}
					tickLine={false}
					tickMargin={theme['@spacer-num-xs']}
					domain={getYMax(dataKeys, data)}
					tickCount={5}
				/>
				<Legend
					align="left"
					verticalAlign="top"
					content={<PerformanceChartLegendsInternal dataKeys={dataKeys} />}
					wrapperStyle={{ paddingBottom: spacingXLG, paddingTop: spacingMD }}
				/>
				<Tooltip content={PerformanceChartTooltipInternal} position={{ y: 150 }} />
				{
					/// Using PerformanceChartEventInternal as function because Re-chart fails to recognized the element
					/// after React creates the PerformanceChartEventInternal element with JSX syntax
					events.map(PerformanceChartEventInternal)
				}
				{includes(dataKeys, PerformanceProperties.connectionRate) && (
					<Line
						dataKey={PerformanceProperties.connectionRate}
						stroke={theme['@connection-rate-chart-color']}
						connectNulls
						dot={{ r: 5 }}
						activeDot={false}
						name={PerformanceChartLabels.ConnectionRate}
						isAnimationActive={false}
					/>
				)}
				{includes(dataKeys, PerformanceProperties.replyRate) && (
					<Line
						dataKey={PerformanceProperties.replyRate}
						stroke={theme['@reply-rate-chart-color']}
						connectNulls
						dot={{ r: 5 }}
						activeDot={false}
						name={PerformanceChartLabels.ReplyRate}
						isAnimationActive={false}
					/>
				)}
			</LineChart>
		</ResponsiveContainer>
	);
}

export default PerformanceChartInternal;
