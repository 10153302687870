import { BaseModel } from './base';
import { attr } from 'redux-orm';

export enum ModalStatus {
	Open = 'Open',
	Closed = 'Closed',
	Closing = 'Closing',
}

export enum ModalType {
	CampaignCreationAlertModal = 'CampaignCreationAlertModal',
	VideoModal = 'VideoModal',
	SalesNavTutorialModal = 'SalesNavTutorialModal',
	Calendly = 'Calendly',
	CampaignCreationModal = 'CampaignCreationModal',
	CustomerAddition = 'CustomerAddition',
	InitialSyncLinkedIn = 'InitialSyncLinkedIn',
	Password = 'Password',
	TeamAddition = 'TeamAddition',
	ProspectsMigration = 'ProspectsMigration',
	OrganizationMemberActivation = 'OrganizationMemberActivation',
	RestrictMemberDeactivation = 'RestrictMemberDeactivation',
	CarouselModal = 'CarouselModal',
	ChangesNotSavedModal = 'ChangesNotSavedModal',
	ReconnectLinkedInModal = 'ReconnectLinkedInModal',
	MigrationModal = 'MigrationModal',
	TeamCustomerInstanceDataModal = 'TeamCustomerInstanceDataModal',
	CampaignLaunchErrorModal = 'CampaignLaunchErrorModal',
	TagsModal = 'TagsModal',
	OutOfCredit = 'OutOfCredit',
	CreateQuickResponseModal = 'CreateQuickResponseModal',
	SmartReplyTermsOfUse = 'SmartReplyTermsOfUse',
	AIFeatureTermsOfUseModal = 'AIFeatureTermsOfUseModal',
}

export interface IModal<P = any> {
	id: string;
	type: ModalType;
	status: ModalStatus;
	props: P;
}

export class Modal extends BaseModel<typeof Modal, IModal> {
	static modelName = 'Modal';
	static fields = {
		id: attr(),
		status: attr(),
		type: attr(),
		props: attr(),
	};
}
