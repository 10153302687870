import BaseDataManager from '../base';
import { TaskResponse, EmptyResponse, TaskStatus } from '@copilot/data/responses/interface';
import { TaskModel } from '@copilot/data/responses/models';

export class TaskManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get a task
	 * @param taskId The id of the task we want
	 */
	public getTask = async (taskId: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.TASK.Default, taskId);
		const response = await this.preventConcurrency(url, () =>
			this.RequestManager.get<TaskResponse>(url).then((res) => new TaskModel(res.data))
		);
		return response;
	};

	/**
	 * Get a task
	 * @param taskId The id of the task we want
	 */
	public getStatelessTask = async (taskId: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.TASK.Stateless, taskId);
		const response = await this.preventConcurrency(url, () =>
			this.RequestManager.get<TaskResponse>(url).then((res) => new TaskModel(res.data))
		);
		return response;
	};

	/**
	 * Add a message to the task
	 * @param taskId The id of the task we want to add a message for
	 * @param message The message we want to add to the task
	 */
	protected addMessageToTask(taskId: string, message: Partial<TaskResponse['lastMessage']>) {
		const url = this.combineRoute(this.BACKEND_ROUTES.TASK.Default, taskId);
		return this.RequestManager.post<EmptyResponse>(url, message).then(() => true);
	}

	/**
	 * Monitor a task and return a taskresponse once complete.
	 * @param monitoredTaskId The task id we want to monitor
	 * @param timeoutSeconds How long before the task fails
	 * @param refreshSeconds The refresh interval
	 */
	protected monitorTask(monitoredTaskId: string, timeoutSeconds = 120, refreshSeconds = 5) {
		let refreshHandler = 0;
		// eslint-disable-next-line @typescript-eslint/ban-types
		const refresh = (resolve: Function, reject: Function, taskId: string, seconds: number) =>
			window.setTimeout(() => {
				this.getTask(taskId)
					.then((response) => {
						[TaskStatus.Complete, TaskStatus.Aborted, TaskStatus.Failed].includes(
							response.status
						)
							? resolve(response)
							: (refreshHandler = refresh(resolve, reject, taskId, seconds));
					})
					.catch((err) => reject(err));
			}, refreshSeconds * 1000);

		return new Promise<TaskResponse>((resolve, reject) => {
			refreshHandler = refresh(resolve, reject, monitoredTaskId, refreshSeconds);
			window.setTimeout(() => {
				window.clearTimeout(refreshHandler);
				reject(new ErrorEvent('Task timed out'));
			}, timeoutSeconds * 1000);
		});
	}
}

const instance = new TaskManager();
export { instance as TaskManagerInstance };
