import { TaskManager } from './base';
import { TaskModel } from '@copilot/data/responses/models';
import { TaskResponse } from '@copilot/data/responses/interface';
import { CampaignTaskStatus } from './types';

enum CopilotEventStrings {
	AGENT_INIT = 'agent-initialize',
	LOGIN_START = 'login-authenticate',
	VERIFY_CODE_EMAIL = 'login-verify-code',
	SEND_MESSAGE = 'send-message',
	SYNC_MESSAGE_THREADS = 'sync-threads',
	SEARCH_CONTACTS = 'search-contacts',
	SEND_INVITE = 'send-batch-invites',
	REMOVE_CONTACT = 'remove-contact',
}

enum UpdateCodesEnum {
	VERIFY_CODE_NEEDED = 'verify-code-needed',
	VERIFY_CODE_INVALID = 'verify-code-invalid',
	LOGIN_FAILED = 'login-failure',
	LOGIN_SUCCESS = 'login-success',
	SEARCH_RESULTS = 'search-results',
	SEARCH_COMPLETE = 'search-complete',
	SEND_MESSAGE_SUCCESS = 'send-success',
	SEND_MESSAGE_FAILED = 'send-failed',
	INVITATION_SENT = 'invite-sent',
	INVITATION_COMPLETE = 'invites-batch-complete',
	SYNC_THREADS_SUCCESS = 'sync-threads-success',
	SYNC_THREADS_FAILED = 'sync-threads-failed',
	TASK_FINISHED = 'task-complete',
	TASK_FAILED = 'task-failed',
}

class LinkedInTaskManager extends TaskManager {
	public UPDATE_CODES = UpdateCodesEnum;
	constructor() {
		super();
	}

	/**
	 * Login a user to linkedin
	 * @param memberId The orgMember id we want to login for
	 * @param username For linkedin's end
	 * @param password For linkedin's end
	 * @returns {Promise<string>} Returns the taskid of the login attempt
	 */
	public login = (memberId: string, username: string, password: string) => {
		const url = this.BACKEND_ROUTES.LINKED_IN.Login;
		const data = { memberId, username, password };
		return this.RequestManager.post<string>(url, data).then((response) => response.data);
	};

	/**
	 * Submit the user's linkedin pin
	 * @param taskId The id of the task
	 * @param pin The pin to provide to linkedin
	 */
	public submitVerificationPin = (taskId: string, pin: string) => {
		const updates = {
			code: CopilotEventStrings.VERIFY_CODE_EMAIL,
			payload: JSON.stringify({ code: pin }),
		};
		return this.addMessageToTask(taskId, updates).then((response) => response);
	};

	/**
	 * Get the login status of a task
	 * @param taskId The id of the task we want to get
	 */
	public getLoginStatus(taskId: string) {
		return this.getTask(taskId).then((task) => {
			try {
				return {
					Code: task.lastMessage.code,
					Status: task.status,
					...JSON.parse(task.lastMessage.payload),
				};
			} catch (err) {
				return { Code: task.lastMessage.code, Status: task.status };
			}
		});
	}

	/**
	 * Get the status of a task
	 * @param taskId The id of the task we want to monitor
	 */
	public getTaskStatus(taskId: string) {
		return this.monitorTask(taskId);
	}

	/**
	 * Find the latest in progress search task
	 * @param orgMemberId The org member we want to find the task for
	 * @param campaignId Optional filter to filter by campaign
	 */
	public getRunningSearchTask = async (orgMemberId: string, campaignId?: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.TASK.LinkedIn,
			'running',
			'member',
			orgMemberId,
			'search'
		);
		const params = campaignId ? { campaign: campaignId } : undefined;
		const response = await this.preventConcurrency(url, () =>
			this.RequestManager.get<TaskResponse>(url, { params }).then(
				(res) => new TaskModel(res.data)
			)
		);
		return response;
	};

	/**
	 * Find the latest in progress search task
	 * @param orgMemberId The org member we want to find the task for
	 * @param campaignId Optional filter to filter by campaign
	 */
	public getSearchTaskStatus = async (orgMemberId: string): Promise<CampaignTaskStatus> => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.TASK.LinkedIn,
			'running',
			'member',
			orgMemberId,
			'search',
			'detailed-status'
		);
		const response = await this.preventConcurrency(url, () =>
			this.RequestManager.get<CampaignTaskStatus>(url, {}).then((res) => res.data)
		);
		return response;
	};
}

const instance = new LinkedInTaskManager();
export { instance as LinkedInTaskManager };
