import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFetch } from '@copilot/common/hooks/common';
import { FeatureManager, AIFeatureManager } from '@copilot/data';
import { PermissionActions } from '@copilot/common/store/actions/permissions';
import {
	convertStringPermissionsToFlag,
	FullPermission,
	PermissionFlag,
} from '@copilot/common/hooks/permission';
import { Spin, Skeleton } from 'antd';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { TermsOfUse } from '@copilot/data/responses/interface';

interface AppDataInitializerProps {}

/**
 * An initializer loading app critical data including feature toggles and showing a loading state if feature toggles haven't been loaded yet
 * @param props
 */
const AppDataInitializer: React.FC<AppDataInitializerProps> = (props) => {
	const [hasFetched, setHasFetched] = useState<boolean>(false);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const [, fetchFeatureToggles] = useFetch(
		FeatureManager.getFeatureToggles,
		PermissionActions.setPermissions,
		(r: Record<string, string>) => convertStringPermissionsToFlag(r)
	);
	const [, fetchAIFeatureTermsOfUseState] = useFetch(
		AIFeatureManager.hasAcceptTermsOfUse,
		PermissionActions.setPermissions,
		(hasAccepted: boolean) => ({
			[TermsOfUse.AIFeature]: hasAccepted ? FullPermission : PermissionFlag.None,
		})
	);
	const isSpinnerShown = useMemo(() => activeMember && !hasFetched, [activeMember, hasFetched]);

	useEffect(() => {
		if (!activeMember) {
			setHasFetched(false);
		} else if (!hasFetched) {
			Promise.all([fetchAIFeatureTermsOfUseState(), fetchFeatureToggles()]).then(() =>
				setHasFetched(true)
			);
		}
	}, [activeMember, hasFetched]);

	return (
		<>
			{isSpinnerShown ? (
				<Spin size="large">
					{' '}
					<Skeleton paragraph={{ rows: 15 }} />{' '}
				</Spin>
			) : (
				props.children
			)}
		</>
	);
};

export default AppDataInitializer;
