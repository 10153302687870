import _ from 'lodash';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { NurturePercentageRingAndDescriptionRow } from './nurturePercentageRingAndDescriptionRow';
import { ProspectingPercentageRingAndDescriptionRow } from './prospectingPercentageRingAndDescriptionRow';
import { isNurtureCampaignStats, isProspectingCampaignStats } from '../../../helpers';
import { CampaignStats } from './types';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';

type PercentageRingAndDescriptionRowProps = {
	/**
	 * The statistics of the campaign
	 * Cannot be undefined since loading has finished
	 */
	campaignStats?: CampaignStats;
	/**
	 * Is the statistics loading
	 */
	isLoading: boolean;
	/**
	 * Whether or not calculations should be shown
	 */
	isShowingCalculations: boolean;
};

/**
 * Internal component to display row percentage rings and descriptions based on campaign
 */
function PercentageRingAndDescriptionRow({
	isLoading,
	campaignStats,
	isShowingCalculations,
}: PercentageRingAndDescriptionRowProps) {
	if (isLoading) return <LoadingIndicator isLoading style={{ width: '100%' }} />;

	if (_.isUndefined(campaignStats)) {
		throw new Error('Loading finished but campaign stats is still undefined');
	}

	if (_.isUndefined(campaignStats.__typename)) {
		throw new Error('Loading finished but campaign stats typename is still undefined');
	}

	if (isProspectingCampaignStats(campaignStats)) {
		return (
			<ProspectingPercentageRingAndDescriptionRow
				campaignStats={campaignStats}
				isShowingCalculations={isShowingCalculations}
			/>
		);
	} else if (isNurtureCampaignStats(campaignStats)) {
		return (
			<NurturePercentageRingAndDescriptionRow
				campaignStats={campaignStats}
				isShowingCalculations={isShowingCalculations}
			/>
		);
	} else {
		UtilityFunctions.assertUnreachable(campaignStats);
		throw TypeError('Campaign stats was not of type prospecting or campaign');
	}
}

export default PercentageRingAndDescriptionRow;
