import LinkedInSettings from '@copilot/common/pages/settings/linkedin/linkedInLogin';
import BaseModal, { BaseModalProps } from '../base';
import { IOrganizationTeamMember } from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/models';
import { IOrganizationMember, IProxy } from '@copilot/common/store/models/redux';

type LinkedinSyncProps = BaseModalProps & {
	orgMember: IOrganizationTeamMember | IOrganizationMember;
	onProxySet: (newProxy: IProxy) => Promise<void>;
};

export default function LinkedinLoginModal({
	orgMember,
	onProxySet,
	...modalProps
}: LinkedinSyncProps) {
	return (
		<BaseModal
			{...modalProps}
			footer={[]}
			width={'fit-content'}
			style={{ top: 0, padding: 0, margin: 0 }}
			styles={{
				content: {
					maxWidth: '100vw',
					maxHeight: '100vh',
					height: '96vh',
					width: '96vw',
					margin: '2vh 2vw',
					boxSizing: 'border-box',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
				},
			}}
		>
			<LinkedInSettings
				member={orgMember}
				isLoggedIn={orgMember?.isLinkedInLoggedIn}
				onProxySet={onProxySet}
				isRetrying={orgMember.isLiSessionInRetryState}
			/>
		</BaseModal>
	);
}
