import { FC } from 'react';
import Card from '@copilot/common/components/card/base';
import List from '@copilot/common/components/subComponents/list';
import Header from '@copilot/common/components/subComponents/header';
import { Button, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { AttentionItemLevel } from '@copilot/common/utils/attentionItem/constant';

export interface IAttentionRequiredCardItem {
	label: string;
	level: AttentionItemLevel;
	onClick?: () => void;
}

export interface IAttentionRequiredCardOrgMembers {
	orgMemberId: string;
	orgMemberDisplayName: string;
	attentionItems: IAttentionRequiredCardItem[];
}

const TagProperties = {
	[AttentionItemLevel.Blocker]: { label: 'Blocker', color: 'error' },
	[AttentionItemLevel.Warning]: { label: 'Warning', color: 'warning' },
};

const StyledButton = styled(Button)`
	&&& {
		width: ${(props) => props.theme['@spacer-size-lg']};
		padding: 0;
	}
`;

export const ATTENTION_REQUIRED_CARD_TITLE = 'Attention Required';
// Card Description for Admins
export const ATTENTION_REQUIRED_CARD_DESCRIPTION_ADMIN =
	"Your organization's health is auto-updated every 20 minutes.";
// Card Description for Non-Admins
export const ATTENTION_REQUIRED_CARD_DESCRIPTION_MEMBER =
	'Your campaign health is auto-updated every 20 minutes.';

interface IAttentionRequiredCardProps {
	description?: string;
	attentionItemsByMember: IAttentionRequiredCardOrgMembers[];
	isMemberNameShown?: boolean;
	buttonTooltipLabel?: string;
	onHelpButtonClick?: () => void;
}

/**
 * Attention Required Card to display the list of errors by member
 * @param {string} description description for the attention required card
 * @param {IAttentionRequiredCardOrgMembers[]} attentionItemsByMember the list of attention items to display
 * @param {boolean} isMemberNameShown whether we want to show the display name of the member
 * @param {string} buttonTooltipLabel tooltip for the help button
 * @param {function} onHelpButtonClick called when the help button is clicked
 */
const AttentionRequiredCard: FC<IAttentionRequiredCardProps> = (props) => {
	const {
		description = ATTENTION_REQUIRED_CARD_DESCRIPTION_ADMIN,
		attentionItemsByMember,
		isMemberNameShown = false,
		buttonTooltipLabel = 'Go to help article',
		onHelpButtonClick,
	} = props;
	return (
		<Card style={{ overflowY: 'auto', maxHeight: '100%' }}>
			<Card.HeadContainer>
				<Header
					title={ATTENTION_REQUIRED_CARD_TITLE}
					body={description}
					icon={
						onHelpButtonClick && (
							<Tooltip title={buttonTooltipLabel}>
								<StyledButton onClick={onHelpButtonClick}>
									<QuestionCircleOutlined
										style={{ display: 'flex', justifyContent: 'center' }}
									/>
								</StyledButton>
							</Tooltip>
						)
					}
				/>
			</Card.HeadContainer>
			<Card.InnerContainer>
				{attentionItemsByMember.map((member) =>
					member.attentionItems.length > 0 ? (
						<Card.InnerContainer.Section key={member.orgMemberId}>
							<List
								title={isMemberNameShown ? member.orgMemberDisplayName : undefined}
							>
								{member.attentionItems.map((item, index) => (
									<List.Field
										key={index}
										label={item.label}
										tag={TagProperties[item.level]}
										onLabelClick={item.onClick}
									/>
								))}
							</List>
						</Card.InnerContainer.Section>
					) : undefined
				)}
			</Card.InnerContainer>
		</Card>
	);
};

export default AttentionRequiredCard;
