import { FC, useContext, ReactNode, Children } from 'react';
import { Row, Col } from 'antd';
import styled, { ThemeContext } from 'styled-components';
import { isReactElement } from '@copilot/common/utils';

const RowWrapper = styled(Row)`
	@media screen and (min-width: ${(props) => props.theme['@screen-xl-min']}) {
		flex-wrap: nowrap;
	}
`;

const SiderCol = styled(Col)`
	@media screen and (max-width: ${(props) => props.theme['@screen-lg-max']}) {
		margin-top: ${(props) => props.theme['@spacer-size-sm']};
	}

	@media screen and (min-width: ${(props) => props.theme['@screen-xl-min']}) {
		width: 400px;
		min-width: 400px;
		max-width: 400px;
	}
`;

const SiderContentWrapper = styled.div`
	@media screen and (min-width: ${(props) => props.theme['@screen-xl-min']}) {
		position: absolute;
		height: 100%;
		width: calc(100% - ${(props) => props.theme['@spacer-size-sm']});
	}
`;

const ContentWithSiderLayoutMain: FC<{}> = (props) => <>{props.children}</>;
const ContentWithSiderLayoutSider: FC<{}> = (props) => <>{props.children}</>;

const getLayoutSubComponents = (children: ReactNode) => {
	let mainContent: ReactNode = null;
	let siderContent: ReactNode = null;
	Children.forEach(children, (child) => {
		if (isReactElement(child)) {
			switch (child.type) {
				case ContentWithSiderLayoutMain:
					mainContent = child;
					break;
				case ContentWithSiderLayoutSider:
					siderContent = child;
					break;
			}
		}
	});
	return { mainContent, siderContent };
};

interface IContentWithSiderLayoutStaticProps {
	Main: typeof ContentWithSiderLayoutMain;
	Sider: typeof ContentWithSiderLayoutSider;
}

interface IContentWithSiderLayoutProps {}

/**
 * Layout that takes in a main content region and a sider content of fixed size
 * that collapses to be below the main content if smaller than 1200px (screen-xl)
 */
const ContentWithSiderLayout: FC<IContentWithSiderLayoutProps> &
	IContentWithSiderLayoutStaticProps = (props) => {
	const { mainContent, siderContent } = getLayoutSubComponents(props.children);
	const themeContext = useContext(ThemeContext);
	return (
		<RowWrapper gutter={themeContext['@spacer-num-sm']}>
			<Col flex="auto">{mainContent}</Col>
			{siderContent && (
				<SiderCol flex="auto">
					<SiderContentWrapper>{siderContent}</SiderContentWrapper>
				</SiderCol>
			)}
		</RowWrapper>
	);
};

ContentWithSiderLayout.Main = ContentWithSiderLayoutMain;
ContentWithSiderLayout.Sider = ContentWithSiderLayoutSider;

export default ContentWithSiderLayout;
