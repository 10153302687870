import { useState, useEffect } from 'react';
import { InboxManager } from '../../../../core/data/managers/index';
import { useDispatch } from 'react-redux';
import { ActivityActions } from '@copilot/common/store/actions/activity';
import { ConversationResponse } from '@copilot/data/responses/interface';

export function useMessagesForLinkedinProfile(
	memberProfileId?: string,
	contactProfileId?: string
): [string, boolean] {
	const storeDispatch = useDispatch();
	const [conversationId, setConversationId] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	useEffect(() => {
		if (memberProfileId && contactProfileId) {
			setIsLoading(true);
			InboxManager.getMessagesForLinkedInProfile(memberProfileId, contactProfileId)
				.then((results: ConversationResponse[]) => {
					if (results && results.length > 0) {
						storeDispatch(ActivityActions.loadConversation(results[0]));
						setConversationId(results[0].id);
					} else {
						setConversationId('');
					}
				})
				.finally(() => setIsLoading(false));
		}
	}, [memberProfileId, contactProfileId]);

	return [conversationId, isLoading];
}
