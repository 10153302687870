/**
 * Focus the html element after inserting a string (e.g. token) at the caret position (or end if not focussed)
 * @param htmlMarkupString string to be inserted
 * @param focussableHTMLEditableElement editable html element to be focussed for editing after insertion
 */
export const inputAndFocusAtCaret = (
	htmlMarkupString: string,
	focussableHTMLEditableElement: HTMLElement
): void => {
	focussableHTMLEditableElement.focus();
	document.execCommand('insertHTML', false, htmlMarkupString);
};
