import { ReactElement } from 'react';
import styles from './listBox.module.less';

type ListBoxProps = {
	title: string;
	items: readonly string[];
};

/**
 * Component displaying list of information with a title
 * @param title
 * @param items
 */
export default function ListBox({ title, items }: ListBoxProps): ReactElement {
	return (
		<div className={styles.listBox}>
			<h4>{title}</h4>
			<ul>
				{items.map((entry) => (
					<li key={entry}>{entry}</li>
				))}
			</ul>
		</div>
	);
}
