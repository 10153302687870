import useTracking from '@copilot/common/hooks/tracking';

interface NurtureMigrationTrackingParams {
	failedNurtureMigration: number;
	nurtureMigration: number;
}

export const useNurtureMigrationTracking = (
	componentName: string,
	params: NurtureMigrationTrackingParams | null
): ((newParams: NurtureMigrationTrackingParams) => void) =>
	useTracking(componentName, 'nurture_migration', params);
