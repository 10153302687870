import { ConversationResponse, MessageResponse } from '@copilot/data/responses/interface';
import { ContactManager, InboxManager } from '@copilot/data';
import { arrayToMap, getExpectedOne, isPlainJsonObject, throwError } from '@copilot/common/utils';
import { isUndefined, orderBy } from 'lodash';
import { ISmartReply, ISmartReplyError } from '@copilot/data/graphql/_generated';
import { WriteSmartReplyMessageInput } from '@copilot/common/hooks/smartReply/smartReplyTypes';

/**
 * The type of a connection
 */
type ConnectionType = Readonly<{
	/* Organization member id */
	orgMemberId: string;
	/* Organization member's profile id */
	orgMemberProfileId: string;
	/* The prospect's profile id */
	profileId: string;
}>;

/**
 * Gets the conversation context of and org member and a contact
 * @param orgId
 * @param memberId
 * @param contactId
 */
export async function getConversation(
	orgId: string,
	memberId: string,
	contactId: string
): Promise<ConversationResponse> {
	const contactConnections: Array<{ connections: Array<ConnectionType> }> =
		await ContactManager.getMultiConnection(orgId, contactId);

	const connectionByOrgMemberId = arrayToMap(
		getExpectedOne(contactConnections).connections,
		(connection) => connection.orgMemberId
	);

	const connection =
		connectionByOrgMemberId.get(memberId) ?? throwError('Unable to get conversation context');
	const conversationResponses = await InboxManager.getMessagesForLinkedInProfile(
		connection.orgMemberProfileId,
		connection.profileId
	);

	return getExpectedOne(conversationResponses);
}

/**
 * Gets a conversation's context for writing a Smart Reply
 * @param orgId
 * @param memberId
 * @param contactId
 */
export async function getSmartReplyConversationContext(
	orgId: string,
	memberId: string,
	contactId: string
): Promise<{
	sourceName: string;
	targetName: string;
	messageThread: ReadonlyArray<WriteSmartReplyMessageInput>;
}> {
	const conversation = await getConversation(orgId, memberId, contactId);
	const messages = toMessageThread(conversation);
	return {
		sourceName: conversation.sourceName,
		targetName: conversation.targetName,
		messageThread: messages,
	};
}

/**
 * Converts a ConversationResponse object to an array of messages ordered by the time they were sent.
 * Omits messages that have not yet been delivered.
 * @param conversation
 */
function toMessageThread(
	conversation: ConversationResponse
): ReadonlyArray<WriteSmartReplyMessageInput & Readonly<{ timestamp: Date }>> {
	const inboundMessages = toMessageThreadInternal(conversation.receivedMessages, false);
	const outboundMessages = toMessageThreadInternal(conversation.sentMessages, true);
	return orderBy([...inboundMessages, ...outboundMessages], 'timestamp', 'asc');
}

/**
 * Converts an array of messages to an array of WriteSmartReplyMessageInput objects, adding their timestamp.
 * Internal function for toMessageThread.
 * @param messages
 * @param isOutbound
 */
function toMessageThreadInternal(
	messages: ReadonlyArray<MessageResponse>,
	isOutbound: boolean
): ReadonlyArray<WriteSmartReplyMessageInput & Readonly<{ timestamp: Date }>> {
	return messages
		.filter((message) => !isUndefined(message.timestamp))
		.map((message) => ({
			message: message.data,
			timestamp:
				message.timestamp ?? throwError('Unreachable code - timestamp must be defined'),
			outbound: isOutbound,
		}));
}

/**
 * Typeguard for ISmartReplyError
 * @param x
 */
export function isSmartReplyError(x: unknown): x is ISmartReplyError {
	return isPlainJsonObject(x) && x.__typename === 'SmartReplyError';
}

/**
 * Typeguard for ISmartReply
 * @param x
 */
export function isSmartReply(x: unknown): x is ISmartReply {
	return isPlainJsonObject(x) && x.__typename === 'SmartReply';
}
