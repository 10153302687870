import { useMemo, useCallback } from 'react';

import { Moment } from 'moment';
import { InboxPageListViewTrackingParams } from '../../../tracking/emailView';
import { getMillisecondsFromNow } from '@copilot/common/utils/dateUtil';
import SearchBar from '@copilot/common/components/search/bar';
import {
	searchOutlinedFilterIcon,
	withTableFilter,
} from '@copilot/common/pages/sent/ui/sentMessagesTable';
import { InboxTableMessage } from './types';
import {
	StyledContainer,
	getCalendarActionButtonRenderer,
	getCampaignNameWithIcon,
	getDismissButtonRenderer,
	getInboxTableRowClassname,
	getLastUpdatedTimestamp,
	getMessageWithTagRenderer,
} from './util';
import { TableModuleTableProps } from '@copilot/common/components/tables/tableModule/types';
import { TableModuleTable } from '@copilot/common/components/tables/tableModule/table';
import { TableModuleColumn } from '@copilot/common/components/tables/tableModule/util';

type AdditionalInboxMessagePropsType = {
	tags: string[];
	onSnooze: (threadId: string, dateSnoozeUntil: Moment) => void;
	onUnsnooze: (threadId: string) => void;
	onOpenConversationDetail: (
		contactId: string,
		threadIdx: number,
		orgMemberId: string,
		threadId: string
	) => void;
	onDismiss: (items: { threadId: string }[]) => unknown;
	onTracking: (trackingType: InboxPageListViewTrackingParams) => void;
	onLoadMore: () => void;
};

function InboxMessageTable(
	props: TableModuleTableProps<InboxTableMessage> & AdditionalInboxMessagePropsType
) {
	const { tags, onDismiss, onSnooze, onUnsnooze, onOpenConversationDetail, onTracking } = props;
	/**
	 * Callback when a row is clicked (opens up the conversation details)
	 * @param {InboxTableMessage} row the row that was clicked
	 */
	const handleRowClick = useCallback(
		(row: InboxTableMessage) => {
			const rowIndex = props.dataSource?.findIndex((m) => m.id === row.id) ?? 0;
			onOpenConversationDetail(row.contactId, rowIndex, row.orgMemberId, row.threadId);
			onTracking({ buttonClicked: 'Row Clicked' });
		},
		[onOpenConversationDetail, onTracking]
	);

	/**
	 * The button for setting and updating a reminder
	 */
	const calendarActionButton = useMemo(() => {
		// Callback when the "Set Reminder" button is clicked
		const handleSetReminderClick = () => {
			onTracking({ buttonClicked: 'Set Reminder Clicked' });
		};
		// Callback when a reminder is set by specifying a date
		const handleSetReminder = (
			threadId: string,
			reminderDate: Moment,
			isNewReminder: boolean
		) => {
			onSnooze(threadId, reminderDate);
			const interval = getMillisecondsFromNow(reminderDate);
			onTracking({
				buttonClicked: isNewReminder ? 'Set Reminder Specified' : 'Edit Reminder',
				interval,
			});
		};
		// Callback when a reminder is cancelled
		const handleCancelReminder = (threadId: string) => {
			onUnsnooze(threadId);
			onTracking({ buttonClicked: 'Cancel Reminder' });
		};
		return getCalendarActionButtonRenderer(
			handleSetReminderClick,
			handleSetReminder,
			handleCancelReminder
		);
	}, [onTracking, onSnooze, onUnsnooze]);

	/**
	 * The button for dismissing a conversation
	 */
	const dismissButton = useMemo(() => {
		// Callback when a message is dismissed
		const handleDismiss = (threads: { threadId: string }[]) => {
			onDismiss(threads);
			onTracking({ buttonClicked: 'Dismiss Conversation' });
		};
		return getDismissButtonRenderer(handleDismiss);
	}, [onDismiss, onTracking]);

	return (
		<StyledContainer>
			<TableModuleTable<InboxTableMessage>
				rowKey={(row) => row.id}
				rowClassName={getInboxTableRowClassname}
				onRow={(row) => ({
					onClick: () => handleRowClick(row),
					style: { cursor: 'pointer' },
				})}
				{...props}
				useLoadMore
			>
				<TableModuleColumn
					columnKey="contactName"
					title="Name"
					dataIndex="contactName"
					width="10%"
					key="contactName"
					filterDropdown={withTableFilter(SearchBar)}
					filterIcon={searchOutlinedFilterIcon}
				/>
				<TableModuleColumn
					columnKey="message"
					title="Messages and Tags"
					render={getMessageWithTagRenderer(tags)}
					ellipsis
					width="20%"
				/>
				<TableModuleColumn
					columnKey="campaignName"
					title="Campaign"
					dataIndex="campaignName"
					width="15%"
					render={getCampaignNameWithIcon}
				/>
				<TableModuleColumn
					columnKey="polarity"
					title="Initial Sentiment"
					dataIndex="polarity"
					width="10%"
				/>
				<TableModuleColumn
					columnKey="timestamp"
					title="Message Received"
					render={getLastUpdatedTimestamp}
					width="12%"
				/>
				<TableModuleColumn
					columnKey="calendar"
					render={calendarActionButton}
					width="180px"
				/>
				<TableModuleColumn columnKey="dismiss" render={dismissButton} width="125px" />
			</TableModuleTable>
		</StyledContainer>
	);
}
export default InboxMessageTable;
