import styles from './avatar.module.less';

type Props = {
	contactName: string;
	type: 'contact' | 'message' | 'inbox';
};

/**
 * Avatar for a contact, displaying the first letter of their name.
 * @param param0
 * @returns
 */
export function ContactAvatar({ contactName, type }: Props) {
	const avatarLetter = contactName?.length > 0 ? contactName[0] : '?';
	return <div className={[styles.avatar, styles[type]].join(' ')}>{avatarLetter}</div>;
}
