import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import { StatsManager, CampaignManager } from '@copilot/data';
import StatsLoader from '@copilot/common/components/statistics/loaders/base';
import { useFetch } from '@copilot/common/hooks/common';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import { useStatOptions } from '@copilot/common/hooks/statistics';
import { CampaignFields } from '@copilot/common/store/models/redux';
import BaseStatisticsSummary from '@copilot/common/components/statistics/summary/base';
import AreaGraph from '@copilot/common/components/charts/simple/area';
import StatisticsSummary from '@copilot/common/components/statistics/summary';
import withPercentageCharts from '@copilot/common/components/statistics/summary/withPercentageCharts';

const loader = async (id: string, start?: Date, end?: Date) => {
	const data = await StatsManager.getStatsForCampaign(id, start, end);
	return { [id]: data };
};

const BaseCampaignStatisticsSummary = withPercentageCharts(BaseStatisticsSummary);
const CampaignStatsModule = StatsLoader(
	StatisticsSummary(BaseCampaignStatisticsSummary, AreaGraph),
	loader
);

interface CampaignSummaryProps {
	campaign: CampaignFields;
}

const CampaignSummary: React.FC<CampaignSummaryProps> = (props: CampaignSummaryProps) => {
	const { campaign } = props;
	const statOptionsFetcher = useFetch(StatsManager.getCampaignStatFilters);
	const campaignUpdater = useFetch(CampaignManager.updateCampaign, CampaignActions.loadCampaign);
	const enabledStats = useMemo(
		() => ({
			tagStats: campaign?.tagStats,
			actionStats: campaign?.actionStats,
		}),
		[campaign?.tagStats, campaign?.actionStats]
	);
	const [statOptions, statsUpdater] = useStatOptions(
		campaign.id,
		enabledStats,
		statOptionsFetcher,
		campaignUpdater
	);
	return (
		<Row>
			<Col span={24}>
				<CampaignStatsModule
					id={campaign.id}
					statsOptions={statOptions}
					selectorUpdateCallback={(stats) => {
						statsUpdater(stats);
					}}
					showLegends={false}
				/>
			</Col>
		</Row>
	);
};

export default CampaignSummary;
