/**
 * Type reflecting the possible statuses an InMail can exist within
 */
export type InMailStatus = typeof InMailStatusConstants[keyof typeof InMailStatusConstants];

/**
 * InMail statuses
 */
export const InMailStatusConstants = {
	/**
	 * When the prospect accepted an InMail
	 */
	Accepted: 'INMAIL_ACCEPTED',
	/**
	 * When the prospect declines an InMail
	 */
	Declined: 'INMAIL_DECLINED',
} as const;
