import React, { useCallback } from 'react';
import BaseModal, { BaseModalProps } from '../base';
import InfoCard from '@copilot/common/components/card/infoCard';

interface RestrictMemberDeactivationProps extends BaseModalProps {
	memberName: string;
	nextDeactivationDate: string;
}

const MIN_ACTIVATION_DAYS = 14;

const RestrictMemberDeactivation: React.FC<RestrictMemberDeactivationProps> = (props) => {
	const { memberName, nextDeactivationDate, width = 572, ...modalProps } = props;

	const onOk = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => props.onCancel?.(e),
		[props.onCancel]
	);

	return (
		<BaseModal width={width} centered {...modalProps}>
			<InfoCard>
				<InfoCard.Content>
					<p>
						{memberName} needs to be activated for a minimum of {MIN_ACTIVATION_DAYS}{' '}
						days before you can deactivate them
					</p>
					<p>
						You will be able to deactivate {memberName} on{' '}
						<strong>{nextDeactivationDate}</strong>
					</p>
					<p>Please contact your account strategist for more details</p>
				</InfoCard.Content>
				<InfoCard.CTA showConfirm onConfirm={onOk} confirmText="Ok" confirmType="primary" />
			</InfoCard>
		</BaseModal>
	);
};

export default RestrictMemberDeactivation;
