import { ORM } from 'redux-orm';
import * as ReduxModels from './models/redux';

const defaultModels = ReduxModels;
type DefaultSchema = typeof defaultModels;

export const createORM = (): ORM<DefaultSchema> => {
	const orm = new ORM<DefaultSchema>({ stateSelector: (state) => state.entities });
	const dm = Object.values(defaultModels).map((m) => class extends m {} as typeof m); // Redux orm actually mutates the model which, clone it to prevent orm metadata from being reused on recreation
	orm.register(...dm);
	return orm;
};
