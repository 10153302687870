import React from 'react';
import { Button, Row, Col } from 'antd';

interface AutomatedMessageProps {
	title: string;
	description?: string;
	message: string;
	onEdit: () => void;
	onDelete?: () => void;
}

const AutomatedMessage = React.memo<AutomatedMessageProps>((props) => (
	<>
		<Row gutter={[16, 16]}>
			<Col>
				<h3>{props.title}</h3> <small>{props.description}</small>
			</Col>
			<Col>
				<Button size="small" onClick={props.onEdit}>
					Edit
				</Button>
			</Col>
			{props.onDelete && (
				<Col>
					<Button size="small" danger onClick={props.onDelete}>
						Delete
					</Button>
				</Col>
			)}
		</Row>
		<Row gutter={[16, 32]}>
			<Col span={20}>{props.message}</Col>
		</Row>
	</>
));

export default AutomatedMessage;
