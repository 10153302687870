import { FC, useRef, useState } from 'react';
import { Input, InputRef, Typography } from 'antd';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';
import styled from 'styled-components';

const StyledDiv = styled.div`
	.${(props) => props.theme['@ant-prefix']}-input-affix-wrapper {
		border-color: ${(props) => props.theme['@Neutral/20']};
		background-color: ${(props) => props.theme['@Neutral/20']};
		padding: ${(props) => props.theme['@spacer-size-xxs']}
			${(props) => props.theme['@spacer-size-xs']};
		.${(props) => props.theme['@ant-prefix']}-input {
			background-color: ${(props) => props.theme['@Neutral/20']};
		}
	}

	.${(props) => props.theme['@ant-prefix']}-input-affix-wrapper-focused {
		border-color: #0173ff;
		background-color: ${(props) => props.theme['@Neutral/10']};

		.${(props) => props.theme['@ant-prefix']}-input {
			background-color: ${(props) => props.theme['@Neutral/10']};
		}
	}

	.${(props) => props.theme['@ant-prefix']}-input-prefix,
		.${(props) => props.theme['@ant-prefix']}-input::placeholder {
		color: rgba(0, 0, 0, 0.45);
		margin-right: 8px;
	}

	.hidden {
		transition: all 0.5s ease-in-out;
		line-height: 0;
		max-height: 0;
		visibility: hidden;
		overflow: hidden;
	}

	.open {
		transition: all 0.5s ease-in-out;
		line-height: inherit;
		visibility: inherit;
		overflow: hidden;
		max-height: ${(props) => props.theme['@font-size-base']};
	}

	svg {
		font-size: ${(props) => props.theme['@prospect-info-box-icon-height']};
	}
`;

type ProspectInfoBoxPropsType = {
	/**
	 * Information box's icon
	 */
	icon: JSX.Element;
	/**
	 * Placeholder to display if the field has no value
	 */
	placeholder: string;
	/**
	 * Prospect information
	 */
	text?: string;
	/**
	 * The field is being updated
	 */
	isLoading: boolean;
	/**
	 * Handler for updating the field
	 * @param value
	 */
	onUpdate: (value: string) => void;
};

/**
 * Box providing information on a prospect.
 * @param props
 * @constructor
 */
const ProspectInfoBox: FC<ProspectInfoBoxPropsType> = (props) => {
	const { isLoading, icon, placeholder, text, onUpdate } = props;
	const [value, setValue] = useState<string | undefined>(text);
	const [focus, setFocus] = useState<boolean>(false);
	const inputRef = useRef<InputRef>(null);
	return (
		<StyledDiv>
			<LoadingIndicator
				isLoading={isLoading}
				// Override to hide "Loading..." from the spinner
				tip={''}
			>
				<Input
					ref={inputRef}
					prefix={icon}
					placeholder={placeholder}
					value={value}
					onChange={(event) => setValue(event.target.value)}
					onFocus={() => {
						setFocus(true);
					}}
					onBlur={() => {
						setValue(text);
						setFocus(false);
					}}
					onPressEnter={() => {
						onUpdate(value ?? '');
						setFocus(false);
					}}
					disabled={isLoading}
				/>
			</LoadingIndicator>
			<div className={focus ? 'open' : 'hidden'}>
				<Typography.Text type="secondary">Press enter to save</Typography.Text>
			</div>
		</StyledDiv>
	);
};

export default ProspectInfoBox;
