import { ComponentType, ReactElement, useEffect, useState } from 'react';
import { useFetch } from '@copilot/common/hooks/common';
import { OrganizationMemberManager } from '@copilot/data';
import { OnboardMessage, OnboardMessageResponse } from '@copilot/data/requests/models';
import { CampaignType } from '@copilot/data/responses/interface';
import { NURTURE_EMPTY_MESSAGE } from '../../settings/message/const';
import { CampaignFields } from '@copilot/common/store/models/redux';
import { get, isNumber, isObject } from 'lodash';
import { StatsTypeName } from '@copilot/common/constant/campaignConstant';
import {
	InMailStats,
	NurtureCampaignStats,
	ProspectingCampaignStats,
} from '../summaryV2/campaignPerformance/percentageRingAndDescription/types';
import { isPlainJsonObject } from '@copilot/common/utils';

type Updater<TType> = (state: TType) => TType;
export type MessageUpdater = (messages: OnboardMessage[] | Updater<OnboardMessage[]>) => unknown;

interface CampaignNodeActions {
	/**
	 * Messages in the campaign sequence
	 */
	messages: OnboardMessage[];
	/**
	 * Update messages
	 */
	setMessages: MessageUpdater;
	/**
	 * Resync with backend messages
	 */
	resync: () => Promise<OnboardMessageResponse>;
	/**
	 * Whether we're syncing
	 */
	isLoading: boolean;
}
/**
 * Hook to get and manage messages of a campaign sequence
 * @param campaignId Id of the campaign we want the messages for
 * @param campaignType Type of campaign
 */
export const useCampaignNodeActions = (
	campaignId: string,
	campaignType: CampaignType
): CampaignNodeActions => {
	const [messages, setMessages] = useState<OnboardMessage[]>([]);
	const [messagesFetch, fetchMessages] = useFetch(
		OrganizationMemberManager.getLinkedInMessagesByCampaign
	);

	const resync = () => fetchMessages(campaignId);

	useEffect(() => {
		resync();
	}, [campaignId]);

	useEffect(() => {
		const { data } = messagesFetch;
		if (!data) return;
		const updatedMessages =
			data[campaignId].length === 1 && campaignType === CampaignType.Nurture
				? [...data[campaignId], NURTURE_EMPTY_MESSAGE]
				: data[campaignId];
		setMessages(updatedMessages);
	}, [campaignId, campaignType, messagesFetch.data]);

	return {
		messages,
		setMessages,
		resync,
		isLoading: messagesFetch.isFetching,
	};
};

type WithNodeActionsComponent<T> = Omit<
	T,
	'onSaveCallback' | 'updateMessages' | 'campaignMessages'
>;
/**
 * HoC to inject CampaignNodeActions
 * @param Component Component we're injecting to
 */
export const withCampaignNodeActions =
	<T extends { campaign: CampaignFields }>(Component: ComponentType<T>) =>
	(props: WithNodeActionsComponent<T>): ReactElement => {
		const { campaign } = props;
		const campaignNodeActions = useCampaignNodeActions(campaign.id, campaign.type);
		return (
			<Component
				{...(props as T)}
				onSaveCallback={campaignNodeActions.resync}
				updateMessages={campaignNodeActions.setMessages}
				campaignMessages={campaignNodeActions.messages}
			/>
		);
	};

/**
 * Type guard for IProspectingCampaignStats
 * @param item
 * @returns
 */
export function isProspectingCampaignStats(item: unknown): item is ProspectingCampaignStats {
	return isObject(item) && get(item, '__typename') === StatsTypeName.prospectingCampaignStats;
}

/**
 * Type guard for IProspectingCampaignStats
 * @param item
 * @returns
 */
export function isNurtureCampaignStats(item: unknown): item is NurtureCampaignStats {
	return isObject(item) && get(item, '__typename') === StatsTypeName.nurtureCampaignStats;
}

/**
 * Type guard for InMailStats
 * @param item
 */
export function isInMailStats(item: unknown): item is InMailStats {
	return (
		isPlainJsonObject(item) &&
		isNumber(item.numInMailSent) &&
		isNumber(item.numInMailReplies) &&
		isNumber(item.numInterestedInMailReplies)
	);
}
