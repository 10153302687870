import { SmartReplyPromptCategory } from './promptMenu/promptMenuTypes';

/**
 * Determines the Prompt category the provided Inbox Message applies to.
 */
export function getSmartReplyPromptCategory(message?: {
	isSnoozed?: boolean;
	polarity?: string;
}): SmartReplyPromptCategory | undefined {
	if (message?.isSnoozed === false) return 'FOLLOW_UP';
	if (message?.polarity === 'Not Interested') return 'NOT_INTERESTED';
	return undefined;
}
