import { RuleObject, ValidatorRule } from 'rc-field-form/lib/interface';
import { Regex } from '@copilot/data/utils/index';
import { isSearchUrlValid } from '@copilot/common/utils';

export namespace Validators {
	export const validateEmail: ValidatorRule['validator'] = (rule, value, callback) => {
		const valid = Regex.Email.test(String(value).toLowerCase());
		if (!valid) callback('Please enter a valid E-mail.');
		else callback();
	};
	export const validateMultiEmails: ValidatorRule['validator'] = (rule, value, callback) => {
		if (!rule.required && !value) return callback();
		let valid = true;
		const updateValid = (status?: string) => (valid = !status);
		const emails = value.split(/[\s;]+/);
		for (let i = 0; i < emails.length; i++) {
			validateEmail({}, emails[i], updateValid);
			if (!valid) break;
		}
		if (!valid) callback('Please check if all E-mails are valid.');
		else callback();
		return undefined;
	};
	export const validatePassword: ValidatorRule['validator'] = (rule, value, callback) => {
		if (!value) callback('Please enter a password.');
		else if (value.length < 6) callback('Passwords must be at least 6 characters.');
		else callback();
	};

	export const validateSecurePassword: ValidatorRule['validator'] = (
		rule,
		value: string,
		callback
	) => {
		if (!value) callback('Please enter a password.');
		else if (value.length < 6) callback('Password must be at least 6 characters.');
		else if (!/[a-z]/.test(value))
			callback("Passwords must have at least one lowercase ('a'-'z').");
		else if (!/[A-Z]/.test(value))
			callback("Passwords must have at least one uppercase ('A'-'Z').");
		else if (!/[0-9]/.test(value)) callback("Password must have at least one digit ('0'-'9').");
		else if (!/[!@#$%^&*().]/.test(value))
			callback("Passwords must have at least one non alphanumeric character ('!@#$%^&*()').");
		else callback();
	};

	export const validateZip: ValidatorRule['validator'] = (rule, value: string, callback) => {
		if (!rule.required && !value) return callback();
		else if (!Regex.UsZip.test(value) && !Regex.CanadianPostalCode.test(value))
			callback('Please enter a valid US or Canadian zip code.');
		else callback();
		return undefined;
	};
}

export const SearchUrlValidator = (_: RuleObject, value: string) =>
	isSearchUrlValid(value)
		? Promise.resolve()
		: Promise.reject('Please enter a valid LinkedIn Sales Navigator Search URL');

export const SearchUrlValidatorAllowEmpty = (_: RuleObject, value: string) =>
	value === '' || isSearchUrlValid(value)
		? Promise.resolve()
		: Promise.reject('Invalid Sales Navigator URL');
