// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QUT5BWBljbdFvJ2UDXXr {\n  padding: 8px;\n  background-color: #f5f5f5;\n  border-radius: 4px;\n}\n.QUT5BWBljbdFvJ2UDXXr .sTOJHMSPM2p2LSzo9C9O {\n  font-weight: 700;\n}\n.QUT5BWBljbdFvJ2UDXXr ul {\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/drawer/wrappers/accountDashboard/accountCreation/accountCreationFormPanel.module.less"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,yBAAA;EACA,kBAAA;AAFF;AADA;EAMI,gBAAA;AAFJ;AAJA;EAUI,SAAA;AAHJ","sourcesContent":["@import '../../../../../../styles/antd-variables.less';\n@import '../../../../../../styles/common-variables.less';\n\n.formPanel {\n  padding: @spacer-size-xs;\n  background-color: @gray3;\n  border-radius: @border-radius-base;\n  \n  .header {\n    font-weight: @fontWeightBold;\n  }\n  \n  ul {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formPanel": "QUT5BWBljbdFvJ2UDXXr",
	"header": "sTOJHMSPM2p2LSzo9C9O"
};
export default ___CSS_LOADER_EXPORT___;
