import React, { useMemo, useState } from 'react';
import MessageEditorWithTemplates from '@copilot/common/components/editors/message/withTemplates';
import {
	MessageTypeEnum,
	MessageIntentExamples,
	MessageName,
} from '@copilot/common/pages/onboard/wizard/messaging/const';
import BasicWizardMessageEditor, { BasicWizardMessageEditorProps } from './wizard';
import { Row, Col, Divider, Alert } from 'antd';
import { DEFAULT_TIME_UNITS } from '@copilot/common/pages/settings/message/const';

interface IntentMessageEditorProps {
	message?: React.ComponentProps<typeof MessageEditorWithTemplates>['message'];
	onUpdate: (message: NonNullable<IntentMessageEditorProps['message']>) => unknown;
	templateType?: MessageTypeEnum;
	onTemplateTypeUpdate?: (type: MessageTypeEnum) => unknown;
}

const IntentMessageEditor =
	(Editor: React.FC<BasicWizardMessageEditorProps>) => (props: IntentMessageEditorProps) => {
		const {
			message = { period: 0, text: '', time: 60 },
			templateType = MessageTypeEnum.Casual,
		} = props;
		const [selectedTemplate, setSelectedTemplate] = useState<string>('');

		const messageTemplates = useMemo(() => {
			const messageGroup =
				MessageIntentExamples[
					MessageTypeEnum[templateType] as keyof typeof MessageIntentExamples
				];
			return Object.keys(messageGroup).map((k) => ({
				key: `${props.templateType}-${k}`,
				label: k,
				value: messageGroup[k as keyof typeof messageGroup],
			}));
		}, [templateType]);

		const header = (
			<>
				<Row>
					<Col span={24}>
						<h1>2nd Message</h1>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<p>
							This message aims to follow-up with your new connections. We suggest
							thanking them for connecting, and then letting them know what you would
							like to pursue in your outreach - whether a coffee, quick call or just a
							connection.
						</p>
						<Divider />
						<Alert
							message={
								<p style={{ margin: 0 }}>
									You have chosen a{' '}
									<strong>{MessageName[templateType].toLowerCase()} tone</strong>.
								</p>
							}
							type="info"
							banner
							showIcon={false}
							style={{ margin: '16px 0' }}
						/>
						<p>
							We recommend selecting an example from below and personalizing the
							message to ensure that it's specific to your business and your audience.
						</p>
						<Alert
							message={
								<p style={{ margin: 0 }}>
									Please replace all <strong>TEXT IN CAPS</strong> with your own
									personal information as they are only filler text.
								</p>
							}
							type="warning"
							showIcon
							style={{ marginTop: '16px' }}
						/>
					</Col>
				</Row>
			</>
		);
		return Editor({
			...props,
			templates: messageTemplates,
			header,
			message,
			timeUnits: DEFAULT_TIME_UNITS,
			selectedTemplate,
			updateSelectedTemplate: setSelectedTemplate,
		});
	};

export default IntentMessageEditor(BasicWizardMessageEditor);
