import styled from 'styled-components';
import { Popover as AntPopover, PopoverProps } from 'antd';
import { FC } from 'react';

const _Popover: FC<PopoverProps> = ({ className, ...restProps }) => (
	<AntPopover overlayClassName={className} {...restProps} />
);

/**
 * Copilot Popover
 * ## this fixes an issue with ant design not matching arrow color with background color
 */
const Popover = styled(_Popover)`
	&&& .${(props) => props.theme['@ant-prefix']}-popover-arrow-content::before {
		background: ${(props) => props.color};
	}
`;
export default Popover;
