import { MemberLinkedInProfileResponse, MultipleInvite } from '@copilot/data/responses/interface';

export type CampaignMemberModel = {
	id: string;
	orgMemberId: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	campaignRoles: string[];
	status: number;
	campaignId: string;
	campaignMemberId: string;
	linkedInProfile: MemberLinkedInProfileResponse;
	searchUrl: string;
	lastSearchUrlUpdate: string;
	dailyInvites: number;
	dailyOpenInMailLimit: number;
	openInMailEnabled: boolean;
	dailyMessages: number;
	multipleInvite: MultipleInvite;
};

/**
 * Gets the name of the campaign member.
 * @param model
 */
export function getName<T extends { firstName?: string; lastName?: string }>(model: T): string {
	const first = model.firstName ?? '';
	const last = model.lastName ?? '';
	return `${first}${last ? ' ' : ''}${last}`;
}

/**
 * Determines if the campaign is enabled.
 * @param model
 */
export function isEnabled(model: CampaignMemberModel): boolean {
	return !!+model.status;
}
