// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MP2MsEg15qd573KijPgn {\n  margin-bottom: 0px;\n}\n.MP2MsEg15qd573KijPgn textarea {\n  border-radius: 4px 4px 0 0;\n}\n.lL0SSwQc616X0LRxKHtb {\n  margin-bottom: 16px;\n}\n.JCv5dcAs2NdjdZz4M1PB {\n  margin-bottom: 8px;\n}\n.JrU0ntuzyS3syxIrlYFU {\n  background-color: rgba(0, 0, 0, 0.04);\n  border: 1px solid #d9d9d9;\n  border-top: 0;\n  border-radius: 0 0 4px 4px;\n  color: #00000073;\n  padding: 4px;\n}\n.Fxwm3sit9DtnyyRQ1bRP {\n  white-space: break-spaces;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/campaignDashboard/sequence/inmail/messageEditorPanel.module.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAAA;EAGI,0BAAA;AAAJ;AAIA;EACE,mBAAA;AAFF;AAKA;EACE,kBAAA;AAHF;AAMA;EACE,qCAAA;EACA,yBAAA;EACA,aAAA;EACA,0BAAA;EACA,gBAAA;EACA,YAAA;AAJF;AAOA;EACE,yBAAA;AALF","sourcesContent":["@import \"../../../../../styles/antd-variables\";\n\n.inputBox {\n  margin-bottom: 0px;\n  textarea {\n    border-radius: @border-radius-base @border-radius-base 0 0;\n  }\n}\n\n.editor {\n  margin-bottom: @spacer-size-sm;\n}\n\n.warningMessage {\n  margin-bottom: @spacer-size-xs;\n}\n\n.messageFormFooter {\n  background-color: @container-disabled-bg;\n  border: 1px solid @Gray;\n  border-top: 0;\n  border-radius: 0 0 @border-radius-base @border-radius-base;\n  color: @text-color-secondary;\n  padding: @spacer-size-xxs;\n}\n\n.message {\n  white-space: break-spaces;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputBox": "MP2MsEg15qd573KijPgn",
	"editor": "lL0SSwQc616X0LRxKHtb",
	"warningMessage": "JCv5dcAs2NdjdZz4M1PB",
	"messageFormFooter": "JrU0ntuzyS3syxIrlYFU",
	"message": "Fxwm3sit9DtnyyRQ1bRP"
};
export default ___CSS_LOADER_EXPORT___;
