import { URL } from '@copilot/common/data/urls';

/**
 * State Name
 */
export const LINKEDIN_PROFILE = 'LINKEDIN_PROFILE';

/**
 * Generate a linkedin profile url from an alias
 * @param alias
 * @returns
 */
export function generateLinkedInProfileUrl(alias: string): string {
	return `${URL.LINKEDIN_URL}/in/${alias}`;
}
