import React, { useMemo } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Input, Form, InputRef } from 'antd';

interface EditableTextDisplayProps {
	/**
	 * The type of input displayed.
	 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types}
	 * @default text
	 */
	type?: string;
	/**
	 * Returns a custom error string to be presented and
	 * blocks the confirmHandler form being called
	 */
	inputValidator?: (value: string) => string | undefined;
	confirmHandler: (value: string) => void;
	onEditClick?: () => void;
	confirmOnBlur?: boolean;
	value: string;
	allowEmpty?: boolean;
}

const EditableTextDisplay: React.FC<EditableTextDisplayProps> = (props) => {
	const {
		inputValidator,
		confirmHandler,
		onEditClick,
		confirmOnBlur,
		value,
		allowEmpty = false,
		type = 'text',
	} = props;
	const inputRef = React.useRef<InputRef>(null);
	const [stateValue, setStateValue] = React.useState(value);
	const [isInputVisible, setIsInputVisible] = React.useState<boolean>(false);
	React.useEffect(() => {
		setStateValue(value);
	}, [value]);
	const handeInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setStateValue(e.target.value);
	}, []);
	const errorMessage = useMemo(() => inputValidator?.(stateValue), [inputValidator, stateValue]);
	const handleInputConfirm = React.useCallback(() => {
		if (stateValue == '') {
			if (allowEmpty) confirmHandler(stateValue);
		} else {
			if (errorMessage) return;
			confirmHandler(stateValue);
		}
		setIsInputVisible(false);
	}, [stateValue, allowEmpty, confirmHandler, errorMessage]);
	const handleShowInput = React.useCallback(
		(event: React.MouseEvent) => {
			setIsInputVisible(true);
			if (onEditClick) onEditClick();
			event?.stopPropagation();
		},
		[onEditClick]
	);
	const handleBlur = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
		setStateValue(value);
		setIsInputVisible(false);
		event?.stopPropagation();
	}, []);
	const handleInputOnClick = React.useCallback((event: React.MouseEvent) => {
		event?.stopPropagation();
	}, []);
	return (
		<>
			{isInputVisible && (
				<Form.Item
					style={{ margin: 0 }}
					validateStatus={errorMessage ? 'error' : undefined}
					help={errorMessage}
				>
					<Input
						ref={inputRef}
						type={type}
						value={stateValue}
						onChange={handeInputChange}
						onPressEnter={handleInputConfirm}
						onBlur={confirmOnBlur ? handleInputConfirm : handleBlur}
						onClick={handleInputOnClick}
					/>
				</Form.Item>
			)}
			{!isInputVisible && (
				<span>
					{props.children}{' '}
					<a onClick={handleShowInput}>
						<EditOutlined />
					</a>
				</span>
			)}
		</>
	);
};

export default EditableTextDisplay;
