import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import DataFilter from '@copilot/common/components/tables/dataFilter';
import { useSelector } from 'react-redux';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { Row, Col, Button } from 'antd';
import styled from 'styled-components';
import { getFilterModel } from '@copilot/common/utils/filters';
import { AvailableFilters, CommonFilters } from '@copilot/common/utils/constant';

import { BaseFilterRequestModel, FilterModel } from '@copilot/data/requests/models';
import FilterList from './list';
import { IFilterTemplate } from '@copilot/common/store/models/redux';
import { getCampaignIcon } from '@copilot/common/utils/campaign';
import { getAllOrgTags } from '@copilot/common/pages/organizationDashboard/tags/data/selectors';

interface IFilterProps {
	isHideCampaigns?: boolean;
	isHideTeamMembers?: boolean;
	isHideTags?: boolean;
	selectedFilterTemplate?: IFilterTemplate | null;
	setSelectedTemplate?: (value: React.SetStateAction<string>) => void;
}

/**
 * The styled column to host filter.
 */
export const StyledFilterColumn = styled(Col)`
	margin-right: 7px;
	flex: 0 1 auto;
	display: flex;
	flex-direction: column;
	max-width: 240px;
`;

const withExtraFilters =
	<T,>(FilterComponent: React.FC<T>) =>
	(props: T & IFilterProps) => {
		const {
			isHideCampaigns,
			isHideTeamMembers,
			isHideTags,
			selectedFilterTemplate,
			setSelectedTemplate,
		} = props;
		const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
		const orgTags = useSelector(getAllOrgTags);
		const orgId = useMemo(
			() => activeMember?.organizationId ?? '',
			[activeMember?.organizationId]
		);
		const campaigns = useSelector((state) =>
			CampaignSelectors.getCampaignsByOrgId(state, orgId)
		);
		const orgMembers = useSelector((state) =>
			OrganizationMemberSelectors.getOrgMembersByOrgId(state, orgId)
		);
		const [isResetFiltersOn, setIsResetFiltersOn] = useState<boolean>(false);

		const [tagFilters, setTagFilters] = React.useState<FilterDefinition[]>([]);
		const [campaignFilters, setCampaignFilters] = React.useState<FilterDefinition[]>([]);
		const [teamMemberFilters, setTeamMemberFilters] = React.useState<FilterDefinition[]>([]);

		React.useEffect(() => {
			const filters = campaigns.map(
				(f) =>
					new FilterDefinition({
						key: f.id,
						label: f.name,
						displayName: (
							<span
								style={{
									position: 'relative',
									fontSize: 'inherit',
									top: 0,
									right: 0,
								}}
							>
								{getCampaignIcon(f.type)} {f.name}
							</span>
						),
					})
			);
			setCampaignFilters(filters);
		}, [campaigns, isResetFiltersOn]);

		React.useEffect(() => {
			const filters = orgTags.data.map(
				(f) =>
					new FilterDefinition({
						className: 'tags',
						key: f.id,
						label: f.name,
					})
			);
			setTagFilters(filters);
		}, [orgTags.data, isResetFiltersOn]);

		React.useEffect(() => {
			const filters = orgMembers.map(
				(f) =>
					new FilterDefinition({
						key: f.id,
						label: `${f.firstName} ${f.lastName}`,
					})
			);
			setTeamMemberFilters(filters);
		}, [orgMembers, isResetFiltersOn]);

		const updatedfilterRequest: Partial<BaseFilterRequestModel> = useMemo(
			() => ({
				CampaignId: getFilterModel(AvailableFilters.CampaignId, campaignFilters),
				OrgMemberId: getFilterModel(AvailableFilters.OrgMemberId, teamMemberFilters),
				TagName: getFilterModel(AvailableFilters.TagName, tagFilters),
			}),
			[tagFilters, teamMemberFilters, campaignFilters]
		);

		const applyFiltersFromTemplate = useCallback(
			(filterKey: string, filterModel: { include: string[]; exclude: string[] }) => {
				if (!filterModel) return;
				const includeFilter = filterModel.include;
				const excludeFilter = filterModel.exclude;
				switch (filterKey) {
					case AvailableFilters.CampaignId: {
						const newCampaignFilters: FilterDefinition[] = campaignFilters.map((f) => ({
							...f,
						}));
						newCampaignFilters.forEach((f) => {
							f.isVisible = includeFilter.includes(f.key);
						});
						setCampaignFilters(newCampaignFilters);
						break;
					}

					case AvailableFilters.OrgMemberId: {
						const newTeamMemberFilters: FilterDefinition[] = teamMemberFilters.map(
							(f) => ({
								...f,
							})
						);
						newTeamMemberFilters.forEach((f) => {
							f.isVisible = includeFilter.includes(f.key);
						});
						setTeamMemberFilters(newTeamMemberFilters);
						break;
					}
					case AvailableFilters.TagName: {
						const newTagFilters: FilterDefinition[] = tagFilters.map((f) => ({ ...f }));
						newTagFilters.forEach((f) => {
							f.isVisible =
								includeFilter.includes(f.label) || excludeFilter.includes(f.label);
							f.isExclude = excludeFilter.includes(f.label);
						});
						setTagFilters(newTagFilters);
						break;
					}
					default:
						throw new Error(`Failed to apply ${filterKey}`);
				}
			},
			[campaignFilters, teamMemberFilters, tagFilters]
		);

		React.useEffect(() => {
			if (!selectedFilterTemplate?.filters) return;
			const filterDictionary = selectedFilterTemplate.filters as {
				[key: string]: FilterModel;
			};
			CommonFilters.forEach((filterKey) => {
				if (filterDictionary[filterKey]) {
					applyFiltersFromTemplate(filterKey, filterDictionary[filterKey]);
				}
			});
		}, [selectedFilterTemplate?.filters]);

		useEffect(() => {
			setIsResetFiltersOn(false);
		});

		const resetFilters = useCallback(() => {
			setIsResetFiltersOn(true);
			setSelectedTemplate?.('');
		}, []);
		return (
			<>
				<Row align="middle" style={{ alignItems: 'baseline', padding: '15px 0' }}>
					{!isHideCampaigns && (
						<>
							<StyledFilterColumn>
								<DataFilter
									title="Campaigns"
									filters={campaignFilters}
									updateFilters={setCampaignFilters}
								/>
								<FilterList
									filters={campaignFilters}
									updateFilterSelector={setCampaignFilters}
								/>
							</StyledFilterColumn>
						</>
					)}
					{!isHideTags && (
						<StyledFilterColumn>
							<DataFilter
								title="Tags"
								filters={tagFilters}
								updateFilters={setTagFilters}
							/>
							<FilterList
								filters={tagFilters}
								updateFilterSelector={setTagFilters}
								isExcludable
							/>
						</StyledFilterColumn>
					)}
					{!isHideTeamMembers && (
						<StyledFilterColumn>
							<DataFilter
								title="Team Members"
								filters={teamMemberFilters}
								updateFilters={setTeamMemberFilters}
							/>
							<FilterList
								filters={teamMemberFilters}
								updateFilterSelector={setTeamMemberFilters}
							/>
						</StyledFilterColumn>
					)}
					<FilterComponent
						{...props}
						extraFilterRequest={updatedfilterRequest}
						selectedFilterTemplate={selectedFilterTemplate}
						isResetFiltersOn={isResetFiltersOn}
					/>
				</Row>
				<Button style={{ marginBottom: '7px' }} onClick={resetFilters}>
					Reset Filters
				</Button>
			</>
		);
	};

export default withExtraFilters;
