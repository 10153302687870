import React from 'react';
import { Card, Divider, Typography } from 'antd';
import { ISubscription, SubscriptionStatus } from '@copilot/data/responses/models/billing';
import ProductItemTable from './productItemTable';
import DiscountTable from './discountTable';
import CurrentSubscriptionSecondaryText from './currentSubscriptionSecondaryText';
import CurrentSubscriptionActions from './currentSubscriptionActions';
import { PreviouslyCancelledSubscriptionAlert } from './stripeAlerts';
import { CSCustomerBillingButtonDisabledReasons } from '../../pages/customerDetails/billing/tracking';

const { Title } = Typography;

/**
 * Props required to display the current subscription summary
 */
interface CurrentSubscriptionSummaryProps {
	/**
	 * The current subscription for the org member
	 */
	subscription: ISubscription;
	/**
	 * The previous subscription for the org member
	 */
	previousSubscription?: ISubscription;
	/**
	 * The customer name.
	 */
	customerName?: string;
	/**
	 * The org member id
	 */
	orgMemberId: string;
	/**
	 * The stripe customer id
	 */
	stripeCustomerId: string;
	/**
	 * If the org member's last invoice is unpaid
	 */
	wasUnpaid?: boolean;
	/**
	 * If the org member has proration on the next invoice
	 */
	hasProration?: boolean;
}

/**
 * Set of unsupported subscription types defined in B/E
 *
 * @see CopilotAI.PaymentSystem.Products.Functions/Controllers/SubscriptionsController.cs
 */
const UNSUPPORTED_SUBSCRIPTION_TYPES = new Set([
	SubscriptionStatus.canceled,
	SubscriptionStatus.incomplete_expired,
	SubscriptionStatus.past_due,
	SubscriptionStatus.unpaid,
]);

/**
 *  [Presentational] The current subscription summary to be displayed on the billing page
 *
 *  @param {ISubscription} subscription The current subscription for the org member
 *  @param {string} orgMemberId The org member id
 *  @param {string} stripeCustomerId The stripe customer id
 *  @param {boolean} [wasUnpaid] If the org member's last invoice is unpaid
 *  @param {boolean} [hasProration] If the org member has proration on the next invoice
 */
const CurrentSubscriptionSummary: React.FC<CurrentSubscriptionSummaryProps> = ({
	subscription,
	previousSubscription,
	customerName,
	orgMemberId,
	stripeCustomerId,
	wasUnpaid,
	hasProration,
}) => {
	// Calculate possible reason why might need to disable change subscription
	// This will update as we expand the support for change subscription
	let disabledChangeReason: CSCustomerBillingButtonDisabledReasons | undefined;
	if (subscription.items.length != 1) {
		// Addons
		disabledChangeReason = 'Add-ons';
	} else if (hasProration) {
		// Proration from past change
		disabledChangeReason = 'Proration';
	} else if (wasUnpaid) {
		// Unpaid
		disabledChangeReason = 'Unpaid invoice';
	} else if (subscription.scheduleEndDate) {
		// Scheduled Change
		disabledChangeReason = 'Upcoming update schedule';
	} else if (subscription.endedDate) {
		// Account Cancelled
		disabledChangeReason = 'Account cancelled';
	} else if (subscription.cancelAtDate) {
		// Cancelling
		disabledChangeReason = 'Account cancelling';
	} else if (UNSUPPORTED_SUBSCRIPTION_TYPES.has(subscription.status)) {
		// Unsupported subscription status
		disabledChangeReason = 'Unsupported Subscription Status';
	}

	return (
		<Card>
			<div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 16 }}>
				<div style={{ flex: 1, marginRight: 8 }}>
					<Title
						type={subscription.endedDate ? 'secondary' : undefined}
						ellipsis
						style={{ margin: 0 }}
						level={4}
					>
						Current Subscription
					</Title>
					<CurrentSubscriptionSecondaryText subscription={subscription} />
				</div>
				<CurrentSubscriptionActions
					orgMemberId={orgMemberId}
					subscriptionId={subscription.id}
					stripeCustomerId={stripeCustomerId}
					disabledChangeReason={disabledChangeReason}
				/>
			</div>
			{previousSubscription && customerName && subscription.trialEnd && (
				<PreviouslyCancelledSubscriptionAlert
					name={customerName}
					subscriptionId={previousSubscription.id}
				/>
			)}
			<ProductItemTable items={subscription.items} disabled={!!subscription.endedDate} />
			<Divider />
			<DiscountTable items={subscription.discounts} disabled={!!subscription.endedDate} />
		</Card>
	);
};

export default CurrentSubscriptionSummary;
