import { createLoadActions } from '@copilot/common/store/actionCreators/list';
import { createFetchSaga } from '@copilot/common/store/saga';
import { AdminManager } from '@copilot/data/managers/admin';
import { OrganizationMemberResponse } from '@copilot/data/responses/interface';
import { IBillingInfo, ICoupon, IProduct } from '@copilot/data/responses/models/billing';
import createSagaMiddleware from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';
import { UpdateSubscriptionAction, UpdateSubscriptionActionType } from './actionCreators';
import { BILLING, BILLING_ORGANIZATION_MEMBER, COUPON, PRODUCT } from './constant';
import { watchFetchInvoicePreview } from './reducers';

export const { oneWatcher: watchFetchBilling, loadOneAction: fetchBillingAction } = createFetchSaga(
	BILLING,
	createLoadActions<IBillingInfo>(BILLING)
);

export const { oneWatcher: watchFetchOrgMember, loadOneAction: fetchOrgMemberAction } =
	createFetchSaga(
		BILLING_ORGANIZATION_MEMBER,
		createLoadActions<OrganizationMemberResponse>(BILLING_ORGANIZATION_MEMBER)
	);

export const { listWatcher: watchFetchProducts, loadListAction: fetchProductsAction } =
	createFetchSaga(PRODUCT, createLoadActions<IProduct>(PRODUCT));

export const { listWatcher: watchFetchCoupons, loadListAction: fetchCouponsAction } =
	createFetchSaga(COUPON, createLoadActions<ICoupon>(COUPON));
function* updateSubscriptionAsync(action: UpdateSubscriptionAction) {
	try {
		yield call(
			AdminManager.updateSubscription,
			action.subscriptionId,
			action.pricingId,
			action.updateNow,
			action.couponId
		);
		action.callbacks.onSuccess?.();
	} catch (e) {
		action.callbacks.onError?.(e);
	}
}

function* watchUpdateSubscription() {
	yield takeEvery(UpdateSubscriptionActionType, updateSubscriptionAsync);
}

export const registerBillingSaga = (
	sagaMiddleware: ReturnType<typeof createSagaMiddleware>
): void => {
	sagaMiddleware.run(watchFetchBilling);
	sagaMiddleware.run(watchFetchOrgMember);
	sagaMiddleware.run(watchFetchProducts);
	sagaMiddleware.run(watchFetchCoupons);
	sagaMiddleware.run(watchFetchInvoicePreview);
	sagaMiddleware.run(watchUpdateSubscription);
};
