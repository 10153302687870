import { ColumnProps } from 'antd/lib/table';

import BaseColumn from './base';
import { StatusWithPopoverColumnDefinition } from '../../componentModels/columnDefinition';

export class StatusWithPopoverColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];
	public sorter: ColumnProps<M>['sorter'];

	constructor(c: StatusWithPopoverColumnDefinition) {
		super(c);
		this.render = (_: string, record: M) => {
			let icon;
			const { properties = [] } = c;
			const values = this.getValues(properties, record);
			if (c.determineStatusIcon) {
				icon = c.determineStatusIcon(values);
			}

			return <span>{icon}</span>;
		};
	}
}
