import { Button } from 'antd';
import styled from 'styled-components';
import BaseDrawer from '../../base';

/**
 * Styling constants of the contact drawer button
 */
const closeDrawerButtonHeight = 32;
const closeDrawerButtonMargin = 16;

/**
 * Styled close button for the contact drawer.
 */
export const StyledCloseDrawerButton = styled(Button)`
	height: ${closeDrawerButtonHeight}px;
	width: ${closeDrawerButtonHeight}px;
	position: absolute;
	top: ${closeDrawerButtonMargin}px;
	left: -${closeDrawerButtonHeight + closeDrawerButtonMargin}px;
	padding: 0;
	verticalalign: middle;
	svg {
		color: #3273db;
	}
`;

/**
 * Base drawer with some custom styles for the contact drawer.
 */
export const StaticBaseDrawer = styled(BaseDrawer)`
	&& > .${(props) => props.theme['@ant-prefix']}-drawer-content-wrapper {
		transform: translate(0px, 0px);
	}

	.${(props) => props.theme['@ant-prefix']}-drawer-header-no-title {
		width: 100%;
		height: 40px;
		position: absolute;
		z-index: 1;
		background-color: transparent;
	}
	.${(props) => props.theme['@ant-prefix']}-drawer-body {
		padding: 0;
	}

	.${(props) => props.theme['@ant-prefix']}-drawer-content {
		// Visible overflow allows the CLOSE button to be rendered outside of the contact drawer.
		overflow: visible;
	}
`; // Make base drawer be the containing block for any position fixed elements within the drawer

/**
 * Styles for contact drawer.
 */
export const meetingBookedStyles = {
	width: '908px',
	span: 16,
	colStyles: {
		padding: '0px',
		borderLeft: 'solid',
		borderColor: '#F0F0F0',
		borderWidth: '1px',
	},
};

/**
 * Disabled styles for contact drawer.
 */
export const meetingBookedDisabledStyles = {
	width: '720px',
	span: 24,
	colStyles: {
		padding: '0px 0px 0px 16px',
	},
};
