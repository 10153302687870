import { SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import {
	ColumnDefinition,
	ColumnActionTypes,
	ColumnActionMetaData,
} from '../../componentModels/columnDefinition';
import DrawerManager, { DrawerTypes } from '@copilot/common/utils/drawerManager';

export interface BaseColumnProps<M> extends ColumnProps<M> {
	hideForRecord: (record: any) => boolean;
}

class BaseColumn<M> implements BaseColumnProps<M> {
	public key: BaseColumnProps<M>['key'];
	public title: BaseColumnProps<M>['title'];
	public onCell: BaseColumnProps<M>['onCell'];
	public className: BaseColumnProps<M>['className'];
	public width: BaseColumnProps<M>['width'];
	public hideForRecord: BaseColumnProps<M>['hideForRecord'];
	public filterIcon: BaseColumnProps<M>['filterIcon'];
	public ellipsis: BaseColumnProps<M>['ellipsis'];

	constructor(c: ColumnDefinition) {
		this.key = c.key;
		this.title = c.label;
		this.width = c.width;
		this.ellipsis = c.ellipsis;
		this.hideForRecord = c.hideForRecord;
		const classNames = [`column-${c.key}`];
		if (c.filter) {
			this.filterIcon = (filtered: boolean) => (
				<SearchOutlined
					style={{ color: filtered ? '#108ee9' : '#aaa', fontSize: '17px' }}
				/>
			);
		}

		if (c.action && c.action.type == ColumnActionTypes.DetailsDrawer) {
			const { type, metaData } = c.action;
			const { type: metaDataType, fieldValues, values } = metaData as ColumnActionMetaData;
			this.onCell = (record: any) => {
				const options: any = {};
				//TODO Prevent parent row for multiple prospects from opening drawer.
				//     Consider less hardcoded way for multiple accordian styled tables for the future
				if (record.connections) return {};
				if (fieldValues)
					Object.keys(fieldValues).forEach(
						(k) => (options[k] = this.getValuesUsingDotNotation(fieldValues[k], record))
					);
				if (values) Object.keys(values).forEach((k) => (options[k] = values[k]));
				switch (type) {
					case ColumnActionTypes.DetailsDrawer: {
						return {
							onClick: () => {
								DrawerManager.openDrawer(metaDataType as DrawerTypes, options);
							},
						};
					}
					default:
						return {};
				}
			};
			classNames.push('clickable');
		}
		this.className = classNames.join(' ');
	}

	protected getValuesUsingDotNotation(property: string, record: any) {
		return property
			.split('.')
			.reduce((p, c) => (p !== undefined && p !== null ? p[c] : null), record);
	}

	protected getValues<TResult>(
		properties: string[] = [],
		record: M | undefined = undefined
	): TResult[] {
		return properties.map((p) => this.getValuesUsingDotNotation(p, record));
	}
}

export default BaseColumn;
