/**
 * Labels
 */
const PerformanceChartLabels = {
	ConnectionRate: 'Connection rate',
	ReplyRate: 'Reply rate',
	SearchListChange: 'Change in search list',
	AutomatedMessageChange: 'Change in automated messaging',
	AutomatedAndSearchlist: 'Change in both',
} as const;

export { PerformanceChartLabels };
