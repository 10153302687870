import { createContext } from 'react';

interface IBaseWizardContext {
	currentNode: number;
}

/**
 * Context for base wizard
 */
export const BaseWizardContext = createContext<IBaseWizardContext>({
	currentNode: 0,
});
