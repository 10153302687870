import { Button } from 'antd';
import { ComponentProps, PropsWithChildren } from 'react';
import { ICheckboxProps } from '../interface';
import PillButtonGroup from '../../buttonGroup/pill';

export type PillCheckboxProps = ICheckboxProps & ComponentProps<typeof Button>;

/**
 * [Presentational] A Higher order component that handles all the logic for forming a checkbox group
 * @param {ICheckboxProps & ComponentProps<typeof Button>} props The properties for this component
 */
const PillCheckbox = (props: PropsWithChildren<PillCheckboxProps>) => {
	const { label, isChecked, ...rest } = props;
	return (
		<PillButtonGroup>
			<Button type={isChecked ? 'primary' : 'default'} {...rest}>
				{label}
			</Button>
		</PillButtonGroup>
	);
};

export default PillCheckbox;
