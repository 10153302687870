/**
 * Max # of characters to show in a message before truncating with an ellipsis in a regular card state
 */
export const MESSAGE_ELLIPSIS_CHAR_COUNT = 144;

/**
 * Max # of characters to show in a message before truncating with an ellipsis in a consensed card state
 */
export const MESSAGE_ELLIPSISIS_CHAR_COUNT_CONDENSED = 103;

/**
 * Perfix to add to a message when it is sent from a capmaign member
 */
export const SENT_MESSAGE_PREFIX = 'You replied: ';
