import React, { useCallback, useMemo } from 'react';
import { Row, Col } from 'antd';
import TableModule from '../../tableModule';
import { CSVColumnDefinition } from '@copilot/common/components/componentModels/filterTypeDefinition';
import ExportSelector from '../exportSelector';
import { ContactConnectionDisplay } from '@copilot/common/pages/connections/helper';
import { LoadingOutlined } from '@ant-design/icons';
import { DefaultContactConnectionsTableColumns } from '@copilot/common/pages/connections/connectionTableColumns';
import { ITableModuleColumnProps, TableModuleTableProps } from '../../tableModule/types';
import { TableModuleColumn, TableModuleHeader, TableModuleTitle } from '../../tableModule/util';
import { TableModuleColumnSelector } from '../../tableModule/columnSelector';
import { TableModuleTable } from '../../tableModule/table';

interface ConnectionsTableProps extends TableModuleTableProps<ContactConnectionDisplay> {
	headerTitle?: string;
	exportData: (columns: CSVColumnDefinition[], selectedRows: string[] | undefined) => void;
	isExporting: boolean;
	columns: ITableModuleColumnProps<ContactConnectionDisplay>[];
	/**
	 * Columns that can be exported
	 */
	exportableColumns: CSVColumnDefinition[];
	onCellClick: (connection: ContactConnectionDisplay) => void;
}

/**
 * [Presentational] Contact connections table component
 * @param {ConnectionsTableProps} props
 */
const ContactConnectionsTable: React.FC<ConnectionsTableProps> = (props) => {
	const {
		headerTitle,
		exportData,
		isExporting,
		columns = DefaultContactConnectionsTableColumns,
		exportableColumns,
		onCellClick,
		...tableModuleProps
	} = props;

	const generateOnCell = useCallback(
		(connection: ContactConnectionDisplay) => ({
			onClick: () => onCellClick(connection),
		}),
		[onCellClick]
	);

	const tableColumns = useMemo(
		() =>
			columns.map((colProps: ITableModuleColumnProps<ContactConnectionDisplay>) => (
				<TableModuleColumn onCell={generateOnCell} {...colProps} />
			)),
		[columns, generateOnCell]
	);

	return (
		<TableModule
			header={
				<TableModuleHeader>
					<Row justify="end" gutter={16}>
						{!!headerTitle && (
							<Col style={{ flexGrow: 1 }}>
								<TableModuleTitle>{headerTitle}</TableModuleTitle>
							</Col>
						)}
						<Col>
							{isExporting ? (
								<>
									<LoadingOutlined /> Exporting...
								</>
							) : (
								<ExportSelector
									columns={exportableColumns}
									label="Export CSV"
									exportCSV={exportData}
								/>
							)}
						</Col>
						<Col>
							<TableModuleColumnSelector />
						</Col>
					</Row>
				</TableModuleHeader>
			}
			table={
				<TableModuleTable
					style={{ minWidth: '100%', width: '150%' }}
					tableLayout="fixed"
					rowKey="id"
					scroll={{ x: '150%' }}
					{...tableModuleProps}
				>
					{tableColumns}
				</TableModuleTable>
			}
		/>
	);
};

export default ContactConnectionsTable;
