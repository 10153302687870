export type CSVColumnDefinition = {
	key: string; ////key needs to match the column key defined in BE
	label: string;
	hideable: boolean;
	isVisible?: boolean;
};

export const ExportCSVColumns: CSVColumnDefinition[] = [
	{
		key: 'LastName',
		label: 'Last Name',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'FirstName',
		label: 'First Name',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'Company',
		label: 'Company',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'Location',
		label: 'Location',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'Email',
		label: 'Email',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'Title',
		label: 'Title',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'Phone',
		label: 'Phone',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'LinkedInUrl',
		label: 'LinkedIn Url',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'Status',
		label: 'Status',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'CampaignName',
		label: 'Campaign Name',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'OrgMemberName',
		label: 'Team Member Name',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'LastUpdated',
		label: 'Last Updated',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'TagNames',
		label: 'Tags',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'LastReply',
		label: 'Last Reply',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'FirstReply',
		label: 'First Reply',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'ConnectionDate',
		label: 'Connection Date',
		hideable: true,
		isVisible: true,
	},
	{
		key: 'MeetingBooked',
		label: 'Meeting',
		hideable: true,
		isVisible: true,
	},
];
