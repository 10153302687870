import { IsoDayEnum } from '@copilot/common/constant/enum';
import { Menu } from 'antd';
import moment, { Moment } from 'moment';
import { CopilotDateType } from '../components/input/dates';

type ReminderDateType = 'nextMonth' | 'nextWeek' | 'nextDay';

export const REMINDER_FORMATTING = 'ddd MMM Do';

export const DEFAULT_TIME = { hour: 4, minute: 0, second: 0, millisecond: 0 };

/**
 * Get 4 weeks from certain date rounding up to nearest Monday
 * @param date base date to calculate the target date from
 * @returns Moment
 */
export function getNextMonth(date: Moment) {
	if (date.isoWeekday() === IsoDayEnum.Mon) {
		return date.add(4, 'weeks');
	} else {
		return date.startOf('isoWeek').add(5, 'weeks');
	}
}

export function getNextWeek(date: Moment) {
	return date.startOf('isoWeek').add(1, 'weeks');
}

/**
 * Get date for preset date option
 * @param dateKey Type of date we want from the current date
 * @returns string
 */
function getReminderDate(dateKey: ReminderDateType) {
	return (date: Moment) => {
		switch (dateKey) {
			case 'nextDay':
				date.add(1, 'days');
				break;
			case 'nextWeek':
				date = getNextWeek(date);
				break;
			case 'nextMonth':
				date = getNextMonth(date);
				break;
			default:
				throw TypeError('date not found');
		}
		return date.set(DEFAULT_TIME);
	};
}

export const ReminderDateOptions: {
	key: string;
	label: React.ReactNode;
	evaluator: (date: Moment) => Moment;
}[] = [
	{ key: 'nextMonth', label: 'Next Month', evaluator: getReminderDate('nextMonth') },
	{ key: 'nextWeek', label: 'Next Week', evaluator: getReminderDate('nextWeek') },
	{ key: 'nextDay', label: 'Tomorrow', evaluator: getReminderDate('nextDay') },
];

export type RelativeDateKey = 'nextMonth' | 'nextWeek' | 'nextDay';

/**
 * Gets the milliseconds from now to the given date
 * @param {Moment} date the date we want to measure until
 */
export function getMillisecondsFromNow(date: Moment): number {
	const currDate: Moment = moment(new Date());
	return moment.duration(date.diff(currDate)).asMilliseconds();
}

export function getRelativeReminderDateMenuItems(
	toggleDatePicker: () => void,
	onSetDate: (date: CopilotDateType, key: RelativeDateKey) => void
) {
	const baseDate = moment().set(DEFAULT_TIME);
	const monthLater = getNextMonth(baseDate.clone());
	const weekLater = getNextWeek(baseDate.clone());
	const dayLater = baseDate.clone().add({ day: 1 });
	return (
		<Menu>
			<Menu.Item onClick={toggleDatePicker}>Custom date</Menu.Item>
			<Menu.Item onClick={() => onSetDate(monthLater, 'nextMonth')}>
				Next month ({formatRelativeReminderDate(monthLater)})
			</Menu.Item>
			<Menu.Item onClick={() => onSetDate(weekLater, 'nextWeek')}>
				Next week ({formatRelativeReminderDate(weekLater)})
			</Menu.Item>
			<Menu.Item onClick={() => onSetDate(dayLater, 'nextDay')}>
				Tomorrow ({formatRelativeReminderDate(dayLater)})
			</Menu.Item>
		</Menu>
	);
}

function formatRelativeReminderDate(date: Moment) {
	return date.format(REMINDER_FORMATTING).toString();
}
