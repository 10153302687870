import { MessageTemplateResponse } from '../interface';

export class MessageTemplateModel implements MessageTemplateResponse {
	public id: string;
	public organizationId: string;
	public orgMemberId: string;
	public campaignIds: string[];
	public name: string;
	public message: string;
	public campaigns: string[];

	constructor(template: MessageTemplateResponse) {
		this.id = template.id;
		this.organizationId = template.organizationId;
		this.orgMemberId = template.orgMemberId;
		this.campaignIds = Array.isArray(template.campaignIds) ? template.campaignIds : [];
		this.name = template.name;
		this.message = template.message;
		this.campaigns = this.campaignIds;
	}
}
