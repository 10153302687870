import { Avatar, Button, Dropdown, Menu, Space } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { ModelState } from '@copilot/common/store/models/fetch';
import { CampaignMemberModel } from '@copilot/common/utils/campaignMember/models';

type MemberName = {
	id: string;
	orgMemberId: string;
	firstName: string;
	lastName: string;
};

type MemberDropdownProps = {
	/**
	 * Current campaign members
	 */
	members: ModelState<MemberName>;
	/**
	 * Callback function to upadted the selected member
	 */
	onSelect: (id: string | undefined) => void;

	/**
	 * Current selected member
	 */
	selectedCampaignMember: CampaignMemberModel | undefined;
};

function TeamMemberDropdown({ members, onSelect, selectedCampaignMember }: MemberDropdownProps) {
	const onSelectItem = ({ key }: { key: string | number }) => onSelect(key.toString());

	const dropdownMenu = (
		<Menu onClick={onSelectItem}>
			{members.data.map((member) => (
				<Menu.Item style={{ margin: '0px' }} key={member.id}>
					<p style={{ margin: '0px' }}>
						{member.firstName} {member.lastName}
					</p>
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<Dropdown trigger={['click']} overlay={dropdownMenu}>
			<Button
				style={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
			>
				<Space size={'small'}>
					<Avatar size="small" icon={<UserOutlined />} />
					<div>
						{selectedCampaignMember?.firstName} {selectedCampaignMember?.lastName}
					</div>
					<DownOutlined style={{ float: 'right', fontSize: '75%' }} />
				</Space>
			</Button>
		</Dropdown>
	);
}

export default TeamMemberDropdown;
