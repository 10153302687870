import React, { Fragment } from 'react';
import { Col, Row, Spin, Typography } from 'antd';
import styled from 'styled-components';
import BasicContainer from '../../containers/basic';
import DateRangeFilter from '../../filters/dateRange';
import { StatFilterDefinition } from '../../componentModels/stat';
import StatSelector from '../selector';

const { Text } = Typography;

interface BaseStatisticsSummaryProps {
	title?: React.ReactNode;
	showLegends?: boolean;
	statsLoader?: (startDate?: Date, endDate?: Date) => void;
	isLoading: boolean;
	showFilter?: boolean;
	statsOptions?: StatFilterDefinition[];
	selectorUpdateCallback: StatSelector['props']['updateStats'];
	newStatCharts?: React.ReactNode[];
	campaignFilters?: TypedObject<boolean>;
	onCampaignFiltersUpdate?: (filters: TypedObject<boolean>) => void;
	campaignIdNameMapping?: TypedObject<string>;
	campaignColorLegends?: TypedObject<string>;
	onDateUpdate?: (date: { startDate?: Date; endDate?: Date }) => void;
}

const StyledBasicContainer = styled(BasicContainer)`
	&
		> .${(props) => props.theme['@ant-prefix']}-card-head
		> .${(props) => props.theme['@ant-prefix']}-card-head-wrapper
		> .${(props) => props.theme['@ant-prefix']}-card-head-title {
		padding: 0px;
	}
`;

const STATS_MIN_HEIGHT = '235px'; // height of the stats component of one row with graphs
const MinHeightCol = styled(Col)`
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: ${STATS_MIN_HEIGHT};
`;

const BaseStatisticsSummary: React.FC<BaseStatisticsSummaryProps> = (
	props: BaseStatisticsSummaryProps
) => {
	const {
		title,
		statsLoader,
		isLoading,
		statsOptions,
		selectorUpdateCallback,
		campaignFilters = {},
		showFilter = true,
		newStatCharts = [],
		onDateUpdate,
		campaignColorLegends,
		onCampaignFiltersUpdate,
		campaignIdNameMapping,
		showLegends = true,
	} = props;
	const updateDates = (startDate?: Date, endDate?: Date) => {
		onDateUpdate?.({ startDate, endDate });
		statsLoader?.(startDate, endDate);
	};

	const handleSelectorUpdate = React.useCallback(
		(stats: StatFilterDefinition[]) => {
			if (selectorUpdateCallback) selectorUpdateCallback(stats);
		},
		[selectorUpdateCallback]
	);
	const onFilterUpdate = React.useCallback(
		(id: string, isToBeDisplay: boolean) => {
			if (!onCampaignFiltersUpdate || !campaignColorLegends) return;
			const numDisplayCampaign = Object.keys(campaignFilters).filter(
				(key) => campaignFilters[key]
			).length;
			if (numDisplayCampaign === 1 && !isToBeDisplay) {
				// display all campaigns if camaign is toggled to be hidden,
				// and if all other campaigns are already hidden
				const filter = Object.keys(campaignFilters).reduce<TypedObject<boolean>>(
					(acc, key) => {
						acc[key] = true;
						return acc;
					},
					{}
				);
				onCampaignFiltersUpdate(filter);
			} else {
				const filter = { ...campaignFilters };
				filter[id] = isToBeDisplay;
				onCampaignFiltersUpdate(filter);
			}
		},
		[campaignFilters, campaignColorLegends, onCampaignFiltersUpdate]
	);

	const legends = React.useMemo(() => {
		if (!campaignColorLegends || !campaignIdNameMapping) return undefined;
		return Object.keys(campaignColorLegends).map((campaignId) => {
			const campaignColor = campaignColorLegends[campaignId] ?? 'black';
			const campaignLegendStyle: { [k: string]: any } = {
				borderColor: campaignColor,
				borderStyle: 'solid',
			};
			const isFiltered = campaignFilters[campaignId];
			if (isFiltered) {
				campaignLegendStyle.backgroundColor = campaignColor;
			}
			return (
				<Col key={campaignId}>
					<a
						style={{ color: 'black', userSelect: 'none' }}
						onClick={() => onFilterUpdate(campaignId, !isFiltered)}
					>
						<Row align="middle" gutter={[4, 16]}>
							<Col>
								<div
									style={{
										...campaignLegendStyle,
										width: '15px',
										height: '15px',
									}}
								/>
							</Col>
							<Col>
								<Text style={{ maxWidth: '500px' }} ellipsis>
									{campaignIdNameMapping[campaignId]}
								</Text>
							</Col>
						</Row>
					</a>
				</Col>
			);
		});
	}, [campaignColorLegends, campaignIdNameMapping, campaignFilters, onFilterUpdate]);

	const dataElements = React.useMemo(
		() =>
			newStatCharts.length > 0 ? (
				<div
					style={{
						display: 'grid',
						width: '100%',
						gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
						rowGap: '1em',
						minHeight: STATS_MIN_HEIGHT,
					}}
				>
					{newStatCharts.map((stat, index) => (
						<Fragment key={index}>{stat}</Fragment>
					))}
				</div>
			) : (
				<MinHeightCol span={24}>No Stats to Show</MinHeightCol>
			),
		[newStatCharts]
	);

	return (
		<StyledBasicContainer>
			<BasicContainer.Header>
				<Row justify="space-between" align="middle">
					<Col>
						<h2>{title}</h2>
					</Col>
					<Col>
						<DateRangeFilter onChange={updateDates} />
					</Col>
				</Row>
			</BasicContainer.Header>
			<BasicContainer.Content>
				<Row justify="space-between">
					<Col>
						{legends && legends.length > 1 && showLegends && (
							<Row gutter={[20, 16]}>{legends}</Row>
						)}
					</Col>
					<Col>
						{showFilter ? (
							<StatSelector
								updateStats={handleSelectorUpdate}
								stats={statsOptions ?? []}
							/>
						) : null}
					</Col>
				</Row>
				<Row>
					{isLoading && (
						<MinHeightCol span={24}>
							<Spin />
						</MinHeightCol>
					)}
					{!isLoading && dataElements}
				</Row>
			</BasicContainer.Content>
		</StyledBasicContainer>
	);
};

export default BaseStatisticsSummary;
