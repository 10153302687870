import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import { Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ReactNode } from 'react';

/**
 * Helper method to return list of permissions based off OrgRoleTitle
 * @param entitlement
 */
export const getContentByMember = (entitlement: OrgRoleTitles) => {
	switch (entitlement) {
		case OrgRoleTitles.Admin:
			return [
				'Create new campaigns',
				'Edit Team Members in campaigns',
				"Message Team Members' connections",
				"Edit all Team Members' search lists and messaging",
				'Add new Team Members (Adding to monthly billing)',
				'Change permissions for Team Members',
			];
		case OrgRoleTitles.Advanced:
			return [
				'Message only their own connections',
				'Edit their own search lists in campaigns',
				'Edit messaging in the campaigns they are in',
				'Create and delete their own campaigns',
			];
		case OrgRoleTitles.User:
			return [
				'Message only their own connections',
				'View but not edit campaigns they are in',
			];
		default:
			throw TypeError(`Description not found for ${entitlement}`);
	}
};

export const getEntitlementDescription = (role: OrgRoleTitles): ReactNode => {
	const memberPermissions = getContentByMember(role);
	return (
		<Typography>
			<Paragraph>
				<ul style={{ margin: 0 }}>
					{memberPermissions.map((permission, idx) => (
						<li key={`${role}_permission_${idx}`}>{permission}</li>
					))}
				</ul>
			</Paragraph>
		</Typography>
	);
};
