import Store from '..';
import { createSelector } from 'redux-orm';
import { IClientSelectors } from '../interface/selectors';

class ClientSelectors implements IClientSelectors {
	public getClients = createSelector(Store.ORM, (session) => session.Client.all().toRefArray());
}

const instance = new ClientSelectors();
export default instance;
