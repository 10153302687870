import React, { useState, useEffect, useMemo, useCallback } from 'react';
import BaseDrawer from '../base';
import BasicContainer from '@copilot/common/components/containers/basic';
import { useFetch } from '@copilot/common/hooks/common';
import { LinkedInManager, CampaignMemberManager } from '@copilot/data';
import DrawerManager from '@copilot/common/utils/drawerManager';
import CampaignMemberSettings from './settings';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import {
	CampaignApprovalStatus,
	CampaignType,
	EmptyResponse,
} from '@copilot/data/responses/interface';
import {
	CampaignMemberModel,
	getName,
	isEnabled,
} from '@copilot/common/utils/campaignMember/models';
import { CampaignFields } from '@copilot/common/store/models/redux';
import { AutoUpdateCampaignMemberAction } from '@copilot/common/utils/campaignMember/saga';
import { useCampaignAllocations } from './hooks';
import InviteAllocationsList from './inviteAllocationsList';

enum DailySendType {
	Messages = 'messages',
	Invites = 'invites',
}

interface DailyItem {
	invites: number;
	messages: number;
}

const DailySendAlertMessage = {
	[CampaignType.Prospecting]:
		"Following LinkedIn's guidelines, the maximum amount of daily invites per user should be less than 25.",
	[CampaignType.Nurture]: '',
};

interface CampaignMemberDrawerProps extends React.ComponentProps<typeof BaseDrawer> {
	campaign: CampaignFields;
	campaignMember: CampaignMemberModel;
}

const CampaignMemberDrawer: React.FC<CampaignMemberDrawerProps> = (props) => {
	const { campaignMember, campaign, ...baseDrawerProps } = props;
	const [dailySendItem, setDailySendItem] = useState<DailySendType>(DailySendType.Invites);
	const [initDailySendValue, setInitDailySendValue] = useState<number>(0);
	const [memberMaxDailySend, setMemberMaxDailySend] = useState<number>(0);
	const [dailySendsPerUser, setDailySendsPerUser] = useState<DailyItem>({
		invites: 0,
		messages: 0,
	});
	const [dailySendsAllocated, setDailySendsAllocated] = useState<DailyItem>({
		invites: 0,
		messages: 0,
	});
	const [, updateStatus] = useFetch(CampaignMemberManager.updateCampaignMemberStatus);
	const [, updateLinkedInInfo] = useFetch(
		CampaignMemberManager.updateCampaignMemberLinkedInInformation
	);
	const [isCampaignAllocationsLoading, campaignAllocations] = useCampaignAllocations(
		campaignMember.orgMemberId,
		campaign.type
	);

	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!campaign.organizationId) return;
		LinkedInManager.getLinkedInOrganizationSettings(campaign.organizationId).then((s) => {
			setDailySendsPerUser({
				invites: s.dailyInvitesPerUser,
				messages: s.dailyMessagesPerUser,
			});
		});
	}, [campaign?.organizationId]);

	useEffect(() => {
		if (!campaign.organizationId) return;
		LinkedInManager.getUserLinkedInProfile(
			campaign.organizationId,
			campaignMember.orgMemberId
		).then((s) => {
			setDailySendsAllocated({
				invites: s.dailyInvitesAllocated,
				messages: s.dailyMessagesAllocated,
			});
		});
	}, [campaign?.organizationId, campaignMember?.orgMemberId]);

	const setSendValues = useCallback(
		(sendType: DailySendType, sendValue: number) => {
			setDailySendItem(sendType);
			setInitDailySendValue(sendValue || 0);
			setMemberMaxDailySend(
				dailySendsPerUser[sendType] - dailySendsAllocated[sendType] + sendValue
			);
		},
		[dailySendsPerUser, dailySendsAllocated]
	);

	useEffect(() => {
		switch (campaign.type) {
			case CampaignType.Nurture:
				setSendValues(DailySendType.Messages, campaignMember.dailyMessages);
				break;
			default:
				setSendValues(DailySendType.Invites, campaignMember.dailyInvites);
		}
	}, [
		setSendValues,
		campaign?.type,
		campaignMember?.dailyMessages,
		campaignMember?.dailyInvites,
	]);

	const isSendSettingDisabled = useMemo(() => {
		const isCampaignActive = campaign.approvalStatus === CampaignApprovalStatus.Approved;
		return !isCampaignActive && !!activeAdmin;
	}, [campaign?.approvalStatus, activeAdmin]);

	const pushDailySendRequest = useCallback(
		(requests: Promise<EmptyResponse>[], newValue: number) => {
			switch (dailySendItem) {
				case DailySendType.Invites:
					if (campaignMember.dailyInvites !== newValue)
						requests.push(
							updateLinkedInInfo(campaign.id, campaignMember.id, {
								dailyInvites: newValue,
							})
						);
					break;
				case DailySendType.Messages:
					if (campaignMember.dailyMessages !== newValue)
						requests.push(
							updateLinkedInInfo(campaign.id, campaignMember.id, {
								dailyMessages: newValue,
							})
						);
					break;
			}
		},
		[
			dailySendItem,
			campaign?.id,
			campaignMember?.id,
			campaignMember?.dailyInvites,
			campaignMember?.dailyMessages,
		]
	);

	const handleCampaignMemberSettingsSave = useCallback(
		(status: number, dailySend: number) => {
			const requests = [];
			if (status !== campaignMember.status) {
				requests.push(updateStatus(campaign.id, campaignMember.id, status));
			}
			pushDailySendRequest(requests, dailySend);
			Promise.all(requests).then(() => {
				dispatch(AutoUpdateCampaignMemberAction([campaignMember.id]));
				DrawerManager.closeDrawer();
			});
		},
		[pushDailySendRequest, campaign?.id, campaignMember?.status, campaignMember?.id]
	);

	return (
		<BaseDrawer closable {...baseDrawerProps}>
			<BasicContainer bordered={false}>
				<BasicContainer.Header>
					<h2>{getName(campaignMember)}</h2>
				</BasicContainer.Header>
				<BasicContainer.Content>
					<CampaignMemberSettings
						dailySendItem={dailySendItem}
						initialStatus={isEnabled(campaignMember)}
						initDailySendValue={initDailySendValue}
						campaignMemberMaxDailySend={memberMaxDailySend}
						isSendSettingDisabled={isSendSettingDisabled}
						alertMessage={DailySendAlertMessage[campaign.type]}
						handleSettingsSave={handleCampaignMemberSettingsSave}
					/>
				</BasicContainer.Content>
			</BasicContainer>
			<InviteAllocationsList
				allocations={campaignAllocations.filter((c) => c.campaignId !== campaign.id)}
				campaignType={campaign.type}
				isLoading={isCampaignAllocationsLoading}
			/>
		</BaseDrawer>
	);
};

export default CampaignMemberDrawer;
