import EllipsisParagraph from '@copilot/common/components/common/ellipsisText';
import { getCampaignIcon } from '@copilot/common/utils/campaign';
import styled from 'styled-components';
import { Button, Typography } from 'antd';
import { FC, useMemo } from 'react';
import CampaignSelector from '../../dropdown/campaignSelector';
import {
	DownOutlined,
	ShopOutlined,
	PhoneOutlined,
	MailOutlined,
	LinkedinFilled,
} from '@ant-design/icons';
import { CampaignType } from '@copilot/data/responses/interface';
import { URL } from '@copilot/common/data/urls';

type ProspectInfoType = {
	/** name of the prospect  */
	name: string;
	/** linkedin alias of the prospect.
	 *  used for creating linkedin profile link.
	 */
	alias?: string;
	/** company of the prospect  */
	company?: string;
	/** position of the prospect  */
	position?: string;
	/** phoneNumber of the prospect  */
	phoneNumber?: string;
	/** email of the prospect  */
	email?: string;
	/** campaign name the prospect belongs to  */
	campaignName: string;
	/** campaign type the prospect belongs to  */
	campaignType: string;
	/** campaigns the prospect can migrate to */
	campaigns: { id: string; type: string; name: string }[];
	/** callback when selected campaign change */
	onCampaignChange: (targetCampaignId: string, targetCampaignName: string) => void;
	/** callback when campaign dropdown is visible */
	onCampaignDropdownVisible: () => void;
};

const { Text } = Typography;

const SenderContainer = styled.div`
	display: flex;
	align-items: center;
`;

const NameText = styled(Text)`
	font-size: 18px;
	margin-right: 6.75px;
`;

const ExtraInfoText = styled(Text)`
	font-size: 14px;
	display: block;
`;

const ContactInfoContainer = styled.div`
	margin: 24px 0 24px;
`;

/**
 * [Presentational] Component for displaying prospect information in message card
 * @param props
 */
export const ProspectInfo: FC<ProspectInfoType> = (props) => {
	const {
		name,
		alias,
		company,
		position,
		phoneNumber,
		email,
		campaignType,
		campaignName,
		campaigns,
		onCampaignChange,
		onCampaignDropdownVisible,
	} = props;
	const campaignIcon = useMemo(
		() => getCampaignIcon(campaignType as CampaignType),
		[campaignType]
	);

	return (
		<>
			<SenderContainer>
				<NameText strong ellipsis className="contact-name" title={name}>
					{name}
				</NameText>
				{alias && (
					<a href={`${URL.LINKEDIN_URL}/in/${alias}`} target="_blank">
						<LinkedinFilled style={{ fontSize: '16.5px' }} />
					</a>
				)}
			</SenderContainer>
			<ExtraInfoText className="contact-position">{position}</ExtraInfoText>
			<ContactInfoContainer>
				{company && (
					<ExtraInfoText className="contact-company">
						<ShopOutlined /> {company}
					</ExtraInfoText>
				)}
				{phoneNumber && (
					<ExtraInfoText className="contact-phoneNumber">
						<PhoneOutlined /> {phoneNumber}
					</ExtraInfoText>
				)}
				{email && (
					<ExtraInfoText className="contact-email">
						<MailOutlined /> {email}
					</ExtraInfoText>
				)}
			</ContactInfoContainer>
			{campaigns.length ? (
				<CampaignSelector
					campaigns={campaigns}
					onSelect={onCampaignChange}
					onDropdownVisible={onCampaignDropdownVisible}
					className="contact-campaign"
				>
					<Button block style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
						{campaignIcon}{' '}
						<EllipsisParagraph text={campaignName} style={{ textAlign: 'left' }} />
						<DownOutlined style={{ fontSize: '8px' }} />
					</Button>
				</CampaignSelector>
			) : (
				<NameText style={{ display: 'flex', alignItems: 'baseline' }}>
					{campaignIcon}{' '}
					<EllipsisParagraph text={campaignName} style={{ textAlign: 'left' }} />
				</NameText>
			)}
		</>
	);
};
