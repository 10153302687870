import { BaseModel } from './base';
import { attr } from 'redux-orm';

export interface IProxy {
	name: string;
	value: number;
}

export class Proxy extends BaseModel<typeof Proxy, IProxy> {
	static modelName = 'Proxy';
	static fields = {
		name: attr(),
		value: attr(),
	};
}
