import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { useFeatureToggle } from '../feature';
import { Features } from '@copilot/data/responses/interface';
import { Prompt, usePrompts } from './prompt';

interface ISmartReplyProviderProps {
	/** Whether the user is a team user or not */
	isTeamUser: boolean;
}

type SmartReplyContextType = Readonly<{
	prompts: Prompt[];
	getByKey: (key: string) => Prompt | undefined;
}>;

const SmartReplyContext = createContext<SmartReplyContextType>({
	prompts: [],
	getByKey: () => undefined,
});

type PromptHookReturnType = {
	writePrompts: Prompt[];
	editPrompts: Prompt[];
	getByKey: SmartReplyContextType['getByKey'];
};

export function useSmartReplyPrompts(): PromptHookReturnType {
	const { prompts, getByKey } = useContext(SmartReplyContext);
	const writePrompts = prompts.filter((prompt) => prompt.action == 'WRITE');
	const editPrompts = prompts.filter((prompt) => prompt.action == 'EDIT');
	return { writePrompts, editPrompts, getByKey };
}

/**
 * Provides the smart reply prompts to the application
 * @returns
 */
export function SmartReplyProvider({
	children,
	isTeamUser,
}: PropsWithChildren<ISmartReplyProviderProps>): JSX.Element {
	const isSmartReplyEnabled = useFeatureToggle(Features.ChatGPTFeature);
	const { prompts, getPrompts } = usePrompts(isTeamUser);
	const promptsByKey: Record<string, Prompt> = prompts.reduce(
		(acc, prompt) => ({ ...acc, [prompt.id]: prompt }),
		{}
	);
	const getByKey = (key: string) => promptsByKey[key];
	useEffect(() => {
		if (isSmartReplyEnabled) getPrompts();
	}, [isSmartReplyEnabled]);
	return (
		<SmartReplyContext.Provider value={{ prompts, getByKey }}>
			{children}
		</SmartReplyContext.Provider>
	);
}
