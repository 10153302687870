import { Tag } from 'antd';
import { ComponentType, Key, useCallback, useState } from 'react';
import styled from 'styled-components';
import Table, {
	TableProps,
	withTablePagination,
} from '@copilot/common/components/tables/basicTable';
import Column from 'antd/es/table/Column';
import drawerManager from '@copilot/common/utils/drawerManager';
import { ThreadStatus } from '@copilot/common/store/models/const/enum';
import { UtilityFunctions } from '@copilot/common/utils/common';
import SearchBar from '@copilot/common/components/search/bar';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { getFirstElement } from '@copilot/common/utils/common/array';
import { useViewProspectDrawerTracking } from '@copilot/common/components/drawer/wrappers/contact/tracking';
import { SearchOutlined } from '@ant-design/icons';

const StyledContainer = styled.div`
	background-color: white;
`;

export const SentMessagesTableColumnKeys = {
	status: 'status',
	search: 'search',
	message: 'message',
	lastUpdate: 'LastSentDate',
};
/**
 * Expected type of the sent messages
 */
type SentMessageType = {
	name: string;
	message: string;
	contactId: string;
	lastSent: string;
	orgMemberId: string;
	isContactDeleted: boolean;
	status: ThreadStatus;
};

const SentMessagesColumnsTitles = {
	Name: 'Name',
	Message: 'Message',
	LastUpdate: 'Last Updated',
};

/**
 * Get the status icon for the current row
 * @param _ Not used
 * @param record Row's sent message
 */
const getStatusIcon = (_: string, record: SentMessageType) => {
	if (record.isContactDeleted) return <Tag color="red">Removed</Tag>;
	switch (record.status) {
		case ThreadStatus.Pending:
			return <Tag color="blue">Sending</Tag>;
		case ThreadStatus.Success:
		case ThreadStatus.Canceled:
		case ThreadStatus.Unknown:
		case ThreadStatus.Failed:
			return <></>;
		default:
			return UtilityFunctions.assertUnreachable(record.status);
	}
};

/**
 * Determine whether or not the row is clickable based on the row's record
 * @param record
 */
const getIsRecordClickable = (record: SentMessageType) => !record.isContactDeleted;

/**
 * Style the cursor on hovor
 * @param record
 */
const getCursorStyle = (record: SentMessageType) => {
	if (getIsRecordClickable(record)) return { style: { cursor: 'pointer' } };
	return { style: { cursor: 'default' } };
};

/**
 * Format the Last Updated timestamp
 * @param timestamp timestamp to format
 */
const getLastUpdatedTimestamp = (timestamp: string) => new Date(timestamp).toLocaleDateString();

/**
 * Open the prospect drawer on row click unless the contact is deleted
 * @param record
 */
const handleRowClick = (record: SentMessageType, trackEvent: (param: any) => void) => {
	if (getIsRecordClickable(record)) {
		drawerManager.openContactDrawer({
			id: record.contactId,
			memberId: record.orgMemberId,
		});
		trackEvent({ buttonClicked: 'Sent Table Row' });
	}
};

/**
 * Expected props type for the search component
 */
type SearchComponentType = {
	initialValue?: string;
	onSearch: () => void;
	onReset: () => void;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	value: string;
	placeholder?: string;
};

const getSelectedValue = (keys: Key[]) => getFirstElement(keys)?.toString();

/**
 * HOC to make a search component into a filter dropdown
 */
export const withTableFilter =
	(Component: ComponentType<SearchComponentType>) => (props: FilterDropdownProps) => {
		const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
		const [searchValue, setSearchValue] = useState<string>(
			getSelectedValue(selectedKeys) ?? ''
		);
		const handleSearchChange = useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value),
			[]
		);
		const handleSearch = () => {
			setSelectedKeys([searchValue]);
			confirm();
		};
		const handleReset = () => {
			setSelectedKeys([]);
			clearFilters?.();
			setSearchValue('');
		};
		return (
			<Component
				value={searchValue}
				onSearch={handleSearch}
				onReset={handleReset}
				onChange={handleSearchChange}
				placeholder="Search name"
			/>
		);
	};

export const searchOutlinedFilterIcon = (filtered: boolean) => (
	<SearchOutlined style={{ color: filtered ? '#108ee9' : '#aaa', fontSize: '17px' }} />
);

const TableWithPagination = withTablePagination<SentMessageType>(Table);

/**
 * Presentation Component of the Sent Messages Table
 * @param props
 */
const SentMessagesTable = (
	props: Omit<TableProps<SentMessageType>, 'pagination'> & { total: number }
): JSX.Element => {
	const { onRow, ...rest } = props;
	const updateTrackingParams = useViewProspectDrawerTracking('Sent Page', null);

	return (
		<StyledContainer>
			<TableWithPagination
				{...rest}
				rowKey="id"
				onRow={(record) => ({
					...onRow?.(record),
					...getCursorStyle(record),
					onClick: () => handleRowClick(record, updateTrackingParams),
				})}
			>
				<Column<SentMessageType>
					key={SentMessagesTableColumnKeys.status}
					render={getStatusIcon}
					width="80px"
				/>
				<Column<SentMessageType>
					title={SentMessagesColumnsTitles.Name}
					dataIndex="name"
					key={SentMessagesTableColumnKeys.search}
					width="15%"
					filterDropdown={withTableFilter(SearchBar)}
					filterIcon={searchOutlinedFilterIcon}
				/>
				<Column<SentMessageType>
					title={SentMessagesColumnsTitles.Message}
					dataIndex="message"
					key={SentMessagesTableColumnKeys.message}
					width="60%"
					ellipsis
				/>
				<Column<SentMessageType>
					title={SentMessagesColumnsTitles.LastUpdate}
					dataIndex="lastSent"
					key={SentMessagesTableColumnKeys.lastUpdate}
					width="15%"
					sorter
					render={getLastUpdatedTimestamp}
					defaultSortOrder="descend"
				/>
			</TableWithPagination>
		</StyledContainer>
	);
};

export default SentMessagesTable;
