import React from 'react';
import { Card, Col, Divider, Row, Space, Tag, Typography } from 'antd';
import type { IInvoice } from '@copilot/data/responses/models/billing';
import ProductItemTable from './productItemTable';
import DiscountTable from './discountTable';
import {
	getFormattedDate,
	LONG_DATE_FORMAT,
	SHORT_DATE_FORMAT,
} from '@copilot/common/utils/dateFormat';

const { Title, Text } = Typography;

/**
 * Props required to display the most recent invoice summary
 */
interface NextInvoiceSummaryProps {
	/**
	 * Invoice to be displayed in next invoice summary.
	 * If null, the summary will display canceled invoice (e.g. account is canceling or is already canceled).
	 */
	invoice?: IInvoice;
}

/**
 * The most next invoice summary to be displayed on the
 * billing page
 *
 * @param props props for the next invoice
 * @returns
 */
const NextInvoiceSummary: React.FC<NextInvoiceSummaryProps> = ({ invoice, children }) => {
	const start = invoice?.periodStart && getFormattedDate(invoice.periodStart, LONG_DATE_FORMAT);
	const end = invoice?.periodEnd && getFormattedDate(invoice.periodEnd, LONG_DATE_FORMAT);

	return (
		<Card>
			<Title type={!invoice ? 'secondary' : undefined} level={4} style={{ margin: 0 }}>
				Next Invoice
			</Title>
			{invoice && (
				<Text style={{ fontSize: 14 }} type="secondary">
					{`${start} - ${end}`}
				</Text>
			)}
			{children}
			<ProductItemTable items={invoice?.lines ?? []} disabled={!invoice} />
			<Divider />
			<DiscountTable items={invoice?.discounts ?? []} disabled={!invoice} />
			{invoice && (
				<>
					{!!invoice.proratedAmount && (
						<div>
							<Divider />
							<Row justify="space-between">
								<Col>Proration</Col>
								<Col className="text-right">
									<Text>{invoice.formattedProratedAmount}</Text>
								</Col>
							</Row>
						</div>
					)}
					{!!invoice.taxAmount && (
						<div>
							{!invoice.proratedAmount && <Divider />}
							<Row justify="space-between">
								<Col>
									<Space>
										<Text>Tax</Text>
										<Tag>{invoice.formattedTaxRate}</Tag>
									</Space>
								</Col>
								<Col className="text-right">
									<Text>{invoice.formattedTaxAmount}</Text>
								</Col>
							</Row>
						</div>
					)}
					{!!invoice.credit && (
						<div>
							<Divider />
							<Row justify="space-between">
								<Col>Credit Balance</Col>
								<Col className="text-right">
									<Text>{invoice.formattedCredit}</Text>
								</Col>
							</Row>
						</div>
					)}
					<Divider />
					<Row justify="space-between">
						<Col>
							{`Scheduled on ${getFormattedDate(
								invoice.createdDate,
								SHORT_DATE_FORMAT
							)}`}
						</Col>
						<Col className="text-right">
							<Title level={5}>{invoice.formattedAmountDue}</Title>
						</Col>
					</Row>
				</>
			)}
		</Card>
	);
};

export default NextInvoiceSummary;
