import { Dropdown, Menu } from 'antd';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { QuestionCircleOutlined, BookFilled, BugFilled, WarningFilled } from '@ant-design/icons';

const DropdownButtonContainer = styled.div`
	display: flex;
	align-items: center;

	.anticon {
		margin-right: 5px;
		font-size: 22px;
	}
`;

const MenuItem = styled(Menu.Item)`
	padding: 16px;
	height: 48px;
`;

type ISupportDropdownProps = {
	isHelpPortalDisabled: boolean;
	isReportBugDisabled: boolean;
	onOpenHelpPortal: () => void;
	onClickReportIssue?: () => void;
	onLoad?: () => void;
	onOpenSiteStatus?: () => void;
};

const DropdownKeys = {
	helpPortal: 'helpPortal',
	reportBug: 'reportBug',
	appcuesLaunchpad: 'appcuesLaunchpad',
	siteStatus: 'siteStatus',
};

/**
 * [Presentational] Dropdown menu for support resources
 * @param isHelpPortalDisabled is the help portal item disabled?
 * @param isReportBugDisabled is the report bug item disabled?
 * @param onOpenHelpPortal callback for when the help portal is clicked
 * @param onLoad callback for when the component finishes loading; called once
 */
const SupportDropdown: FC<ISupportDropdownProps> = (props) => {
	const {
		onOpenHelpPortal,
		onClickReportIssue,
		isHelpPortalDisabled,
		isReportBugDisabled,
		onOpenSiteStatus,
		onLoad,
	} = props;
	useEffect(() => onLoad?.(), [onLoad]);

	return (
		<Dropdown
			overlay={
				<Menu>
					<MenuItem
						key={DropdownKeys.helpPortal}
						id={DropdownKeys.helpPortal}
						onClick={onOpenHelpPortal}
						icon={<BookFilled />}
						disabled={isHelpPortalDisabled}
					>
						<span>Help Portal</span>
					</MenuItem>
					<MenuItem
						key={DropdownKeys.reportBug}
						id={DropdownKeys.reportBug}
						onClick={onClickReportIssue}
						icon={<BugFilled />}
						disabled={isReportBugDisabled}
					>
						<span>Report an Issue</span>
					</MenuItem>
					{onOpenSiteStatus && (
						<MenuItem
							key={DropdownKeys.siteStatus}
							id={DropdownKeys.siteStatus}
							onClick={onOpenSiteStatus}
							icon={<WarningFilled />}
						>
							<span>Site Status</span>
						</MenuItem>
					)}
				</Menu>
			}
			trigger={['click']}
			placement="bottomLeft"
			overlayStyle={{
				width: '250px',
			}}
			forceRender
		>
			{/* This need to be a div instead of Fragment for the dropdown to work properly */}
			<DropdownButtonContainer>
				<QuestionCircleOutlined />
				<span
					style={{
						fontSize: '15px',
						display: 'inline-block',
					}}
				>
					Support
				</span>
			</DropdownButtonContainer>
		</Dropdown>
	);
};

export default SupportDropdown;
