import React from 'react';
import { Col, Row, InputNumber } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import TimingOptions from './timingOptions';
import {
	NodeTimerPeriodEnum,
	NodeTimerPeriodStringEnum,
} from '@copilot/common/store/models/const/enum';
import { isNil } from 'lodash';

export const MINIMUM_MINUTES = 20;

export const RECOMMENDED_TIMING = {
	time: 60,
	period: NodeTimerPeriodEnum.Minutes,
};

interface MessageEditorTimingProps {
	period: NodeTimerPeriodEnum;
	time: number;
	onPeriodUpdate: (value: number) => void;
	onTimeUpdate: (value: number) => void;
	timeUnits: NodeTimerPeriodStringEnum[];
}

/**
 * Timing Editor for Message
 * @param {NodeTimerPeriodEnum} period selected time period ex. Hours, Weeks
 * @param {number} time selected amount of time
 * @callback onPeriodUpdate called on time period update
 * @callback onTimeUPdate called on time update
 * @param {NodeTimerPeriodStringEnum} timeUnits available time units to select
 *
 */

const MessageEditorTiming: React.FC<MessageEditorTimingProps> = (
	props: MessageEditorTimingProps
) => {
	const { period, time, onPeriodUpdate, onTimeUpdate, timeUnits } = props;

	const handleTimeUpdate = (value: number | null) => {
		if (!isNil(value)) onTimeUpdate?.(value);
	};
	const handlePeriodUpdate = (event: RadioChangeEvent) => {
		const value: number = event?.target?.value;
		if (value !== undefined) {
			onPeriodUpdate?.(value);
			//if period is Minutes and time is < 20 reset it to prevent crash
			if (value === NodeTimerPeriodEnum.Minutes && time < MINIMUM_MINUTES) {
				onTimeUpdate?.(MINIMUM_MINUTES);
			}
		}
	};
	return (
		<>
			<Row>
				<Col span={4} style={{ marginRight: '30px' }}>
					<InputNumber name="time" min={0} value={time} onChange={handleTimeUpdate} />
				</Col>
				<Col style={{ marginLeft: '20px' }}>
					<TimingOptions
						timeUnits={timeUnits}
						period={period}
						onPeriodUpdate={handlePeriodUpdate}
					/>
				</Col>
			</Row>
		</>
	);
};

export default MessageEditorTiming;
