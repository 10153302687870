import { Space, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { ComponentType, SVGProps } from 'react';
import { ReactComponent as SearchIcon } from '@copilot/common/assets/icon/filled/search.svg';
import { ReactComponent as MessageIcon } from '@copilot/common/assets/icon/filled/message.svg';
import { ReactComponent as EditIcon } from '@copilot/common/assets/icon/filled/edit.svg';
import { useTheme } from 'styled-components';
import { PerformanceProperties } from './constant';
import { includes } from 'lodash';

export const legendClassname = 'legend-item' as const;

type LegendPropsType = {
	/**
	 * Label for the campaign performance
	 */
	label: string;
	/**
	 * Classname for legends
	 */
	className?: string;
};

type PerformanceLegendPropsType = {
	/**
	 * Line color
	 */
	color: string;
} & LegendPropsType;

/**
 * Icon for showing the line graph's legends
 * @returns
 */
function LineGraphLengendIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="-128 -128 1280 1280"
			fill="currentColor"
			width="1em"
			height="1em"
		>
			<rect x="64" y="384" width="1152" height="180" />
		</svg>
	);
}

/**
 * Legends for the campaign performance
 * @constructor
 */
function PerformanceLegend(props: PerformanceLegendPropsType) {
	const { color, label, className } = props;
	const theme = useTheme();
	return (
		<Space className={className}>
			<Icon
				component={LineGraphLengendIcon}
				style={{ color, verticalAlign: 'middle', fontSize: theme['@legends-icon-size'] }}
			/>
			<Typography.Text style={{ fontSize: theme['@legends-font-size'] }}>
				{label}
			</Typography.Text>
		</Space>
	);
}

type EventLegendPropsType = {
	/**
	 * Campaign event icon
	 */
	icon: ComponentType<SVGProps<SVGSVGElement>>;
} & LegendPropsType;

/**
 * Legends for the campaign events
 * @constructor
 */
function EventLegend(props: EventLegendPropsType) {
	const { icon, label, className } = props;
	const theme = useTheme();
	return (
		<Space className={className}>
			<Icon
				component={icon}
				style={{ verticalAlign: 'middle', fontSize: theme['@legends-icon-size'] }}
			/>
			<Typography.Text style={{ fontSize: theme['@legends-font-size'] }}>
				{label}
			</Typography.Text>
		</Space>
	);
}

/**
 * Legend labels
 */
const PerformanceChartLegendLabels = {
	ConnectionRate: 'Connection rate',
	ReplyRate: 'Reply rate',
	SearchListChange: 'Change in search list',
	AutomatedMessageChange: 'Change in automated message',
	BothChanges: 'Change in both',
} as const;

type PerformanceChartLegendsInternalPropsType = {
	/**
	 * data keys to plot on the chart
	 */
	dataKeys?: (keyof typeof PerformanceProperties)[];
};

/**
 * [Presentational] Legends for the campaign performance chart
 * Please do not use this component directly.
 * @constructor
 */
function PerformanceChartLegendsInternal(props: PerformanceChartLegendsInternalPropsType) {
	const { dataKeys } = props;
	const theme = useTheme();

	return (
		<Space size="large">
			{includes(dataKeys, PerformanceProperties.connectionRate) && (
				<PerformanceLegend
					className={legendClassname}
					color={theme['@connection-rate-chart-color']}
					label={PerformanceChartLegendLabels.ConnectionRate}
				/>
			)}
			{includes(dataKeys, PerformanceProperties.replyRate) && (
				<PerformanceLegend
					className={legendClassname}
					color={theme['@reply-rate-chart-color']}
					label={PerformanceChartLegendLabels.ReplyRate}
				/>
			)}
			<EventLegend
				className={legendClassname}
				icon={SearchIcon}
				label={PerformanceChartLegendLabels.SearchListChange}
			/>
			<EventLegend
				className={legendClassname}
				icon={MessageIcon}
				label={PerformanceChartLegendLabels.AutomatedMessageChange}
			/>
			<EventLegend
				className={legendClassname}
				icon={EditIcon}
				label={PerformanceChartLegendLabels.BothChanges}
			/>
		</Space>
	);
}

export default PerformanceChartLegendsInternal;
