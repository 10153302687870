// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".n5XVe2s69WUGSA3eebV4 {\n  padding: 8px 16px;\n  border-bottom: 1px solid #ebebeb;\n}\n.n5XVe2s69WUGSA3eebV4 .wWdue2tvtcwl_qy_CXe0 {\n  font-size: 12px;\n  color: #8c8c8c;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/drawer/wrappers/contact/drawer/threadNavigator.module.less"],"names":[],"mappings":"AAEA;EACC,iBAAA;EACA,gCAAA;AADD;AADA;EAIE,eAAA;EACA,cAAA;AAAF","sourcesContent":["@import '../../../../../../styles/antd-variables.less';\n\n.threadNavigatorWrapper {\n\tpadding: 8px 16px;\n\tborder-bottom: 1px solid #ebebeb;\n\t.threadNavigatorText {\n\t\tfont-size: 12px;\n\t\tcolor: @Gray-7;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"threadNavigatorWrapper": "n5XVe2s69WUGSA3eebV4",
	"threadNavigatorText": "wWdue2tvtcwl_qy_CXe0"
};
export default ___CSS_LOADER_EXPORT___;
