import Store from '..';
import { createSelector } from 'redux-orm';

export namespace ServiceScheduleSelectors {
	export const getServiceScheduleByOwnerId = createSelector(
		Store.ORM,
		(_, ownerId: string) => ownerId,
		(session, ownerId: string) => session.ServiceSchedule.filter({ ownerId }).first()?.ref
	);
}
