import { ModelState } from '@copilot/common/store/models/singleEntity';

/**
 * Create Selector for single entities
 * @param name State name
 */
export const createFetchSingleSelector =
	<T>(name: string) =>
	(state: Record<string, ModelState<T>>) =>
		state?.[name.toLowerCase()];
