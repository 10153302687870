import React from 'react';
import { Checkbox, Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

export interface IWithCheckboxProps {
	checkboxLabel: string;
	onChange: (checked: boolean) => void;
}

/**
 * [HOC] add checkbox to component
 * @param {string} [checkboxLabel] label for checkbox
 * @callback onChange called on change on checkbox
 */

const withCheckbox =
	<T,>(WrappedComponent: React.FC<T>) =>
	(props: T & IWithCheckboxProps): JSX.Element => {
		const { onChange, checkboxLabel = '' } = props;

		const handleOnChange = (event: CheckboxChangeEvent) => {
			onChange(event.target.checked);
		};

		return (
			<>
				<Row align="middle">
					<Col>
						<WrappedComponent {...props} />
					</Col>
				</Row>
				<br />
				<Checkbox onChange={handleOnChange}>{checkboxLabel}</Checkbox>
			</>
		);
	};
export default withCheckbox;
