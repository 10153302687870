import { Button, Space, Tooltip, Typography } from 'antd';
import {
	CloseOutlined,
	ClockCircleOutlined,
	RightOutlined,
	CheckOutlined,
} from '@ant-design/icons';
import MessageCard from '@copilot/common/components/card/messageCard';
import { useCallback, useMemo, useState } from 'react';
import { Moment } from 'moment';
import DropdownReminderSelector from '@copilot/common/components/selector/reminder/dropdownSelector/dropdownReminderSelector';
import { getMillisecondsFromNow } from '@copilot/common/utils/dateUtil';
import { ProspectInfo } from './prospectInfo';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features, TermsOfUse } from '@copilot/data/responses/interface';
import { getFormattedDate, LONG_DATE_TIME_FORMAT } from '@copilot/common/utils/dateFormat';
import { useSelector } from 'react-redux';
import { selectLinkedInProfile } from '@copilot/common/utils/linkedinProfile/selector';
import MessageForm from '@copilot/common/components/forms/messageForm';
import { useSendMessageWithReminderTrackingParamsTracking } from '@copilot/common/components/selector/reminder/tracking';
import { linkedInDisconnectedMsg } from '@copilot/common/constant/strings';
import { useQuickResponseModal } from '@copilot/common/hooks/quickResponse';
import { useSmartReply } from '@copilot/common/hooks/smartReply';
import isUndefined from 'lodash/isUndefined';
import isEqual from 'lodash/isEqual';
import styles from './messageCard.module.less';
import { calcDisplayMessage, isExpandButtonVisible } from './util';
import { SENT_MESSAGE_PREFIX } from './constants';
import { MessageCardProps } from './types';
import { getSmartReplyPromptCategory } from '../../../../../../components/forms/messageForm/smartReply/smartReplyUtils';
import { useTermsOfUse } from '@copilot/common/hooks/termsOfUse';
import { Config } from '@copilot/common/config';
import { AIFeatureManager } from '@copilot/data';

const { Text, Paragraph } = Typography;

/**
 * [Presentational] Inbox Message Card
 * @param props
 */
function InboxMessageCard({
	organizationId,
	onSubmit,
	onDismiss,
	onOpenConversationDetail,
	threadIdx,
	onSnooze,
	onTracking,
	messageTemplates,
	message,
	migratableCampaigns,
	onMigrate,
	automatedSteps,
	onSetupTemplate,
	onCompleteReminder,
	isReminderTabSelected = false,
}: MessageCardProps) {
	const campaignsExcludingCurrent = useMemo(
		() => migratableCampaigns.filter((campaign) => campaign.id != message.campaignId),
		[migratableCampaigns]
	);

	const isReminderTabFeatureEnabled = useFeatureToggle(Features.ReminderTabFeature);

	const [smartReplyResult, { writeSmartReply, regenerateSmartReply, editSmartReply }] =
		useSmartReply(organizationId, message.orgMemberId, message.contactId);

	const isQuickReplyModalFeatureEnabled = useFeatureToggle(
		Features.SmartReplySaveResponseFeature
	);
	const [createQuickReplyModal] = useQuickResponseModal();
	const linkedInAlias = useSelector(selectLinkedInProfile(message.linkedInProfileId))?.alias;
	const updateSendWithReminderTracking =
		useSendMessageWithReminderTrackingParamsTracking('Send With Reminder');

	const [isExpandedMessageVisible, setIsExpandedMessageVisible] = useState<boolean>(false);

	const videoInAppValidationEnabled =
		!Config.isAgency && useFeatureToggle(Features.VideoValidationFeature);
	const { hasAccepted: hasAcceptedAITermsOfUse } = useTermsOfUse(
		TermsOfUse.AIFeature,
		AIFeatureManager.acceptTermsOfUse
	);

	/**
	 * Callback when the "Set Reminder" button is clicked
	 */
	const handleSetReminderClick = useCallback(() => {
		onTracking?.({ buttonClicked: 'Set Reminder Clicked' });
	}, [onTracking]);

	/**
	 * Callback when a reminder is set by specifying a date
	 * @param {Moment} date the date of the reminder
	 */
	const handleSetReminder = useCallback(
		(date: Moment) => {
			onSnooze(date);
			const interval = getMillisecondsFromNow(date);
			onTracking?.({ buttonClicked: 'Set Reminder Specified', interval });
		},
		[onSnooze, message.id, onTracking]
	);

	/**
	 * Callback when the Campaign migration dropdown is opened
	 */
	const handleCampaignDropdownVisible = useCallback(() => {
		onTracking?.({ buttonClicked: 'Campaign Switch Dropdown Clicked' });
	}, [onTracking]);

	/**
	 * Callback when the campaign is changed and the connection is migrated to that campaign
	 * @param {string} targetCampaignId id of the campaign the connection is migrated to
	 * @param {string} targetCampaignName name of the campaign the connection is migrated to
	 */
	const handleCampaignChange = useCallback(
		(targetCampaignId: string, targetCampaignName: string) => {
			onMigrate(message.connectionId, targetCampaignId);
			onTracking?.({ buttonClicked: 'Campaign Switched', label: targetCampaignName });
		},
		[onMigrate, message.connectionId, onTracking]
	);

	/**
	 * Callback when View Prospect is clicked and the prospect drawer is opened
	 */
	const handleViewProspect = useCallback(() => {
		onOpenConversationDetail(
			message.contactId,
			threadIdx,
			message.orgMemberId,
			message.threadId
		);
		onTracking?.({ buttonClicked: 'View Prospect Drawer' });
	}, [
		onOpenConversationDetail,
		message.contactId,
		message.orgMemberId,
		message.threadId,
		onTracking,
	]);

	/**
	 * Callback when a message is dismissed
	 */
	const handleDismiss = useCallback(() => {
		onDismiss(message);
		onTracking?.({ buttonClicked: 'Dismiss Conversation' });
	}, [onDismiss, message, onTracking]);

	/**
	 * Callback when "Send to Outbox" is clicked and the message is sent out
	 * @param {string} messageText the message that will be sent out
	 * @param {string} nodeId the node id of the message that will be sent out
	 */
	const handleSubmit = useCallback(
		(
			messageText: string,
			removeReminder: boolean,
			automationNodeId?: string,
			templateId?: string
		) => {
			onSubmit(messageText, removeReminder, automationNodeId, templateId, message.campaignId);
			onTracking?.({ buttonClicked: 'Send to Outbox' });
		},
		[onSubmit, onTracking, message.campaignId]
	);

	/**
	 * Callback when a reminded thread is completed
	 */
	function handleCompleteReminder() {
		onCompleteReminder();
		onTracking?.({ buttonClicked: 'Complete Reminder' });
	}

	function onToggleMessageExpand() {
		setIsExpandedMessageVisible(!isExpandedMessageVisible);
	}

	/**
	 * Callback when Message is sent out with reminder
	 * @param date selected reminder date
	 */
	function handleSendWithReminder(date: Moment) {
		onSnooze(date);
		const interval = getMillisecondsFromNow(date);
		updateSendWithReminderTracking('Sent from Inbox Cardview', interval);
	}

	function getSetUpTemplateHandler() {
		return isQuickReplyModalFeatureEnabled
			? (formMessage: string) => createQuickReplyModal([message.campaignId], formMessage)
			: () => onSetupTemplate(message.campaignId);
	}

	const isLastMessageReceived = isEqual(message.lastReceived, message.lastMessage);

	const lastMessage =
		isLastMessageReceived || isUndefined(message.lastSent)
			? message.lastReceived
			: message.lastSent;

	const isDisabled = !message.isLoggedIn || message.isConversationRestricted;

	const isReminderButtonVisible = isReminderTabFeatureEnabled && isReminderTabSelected;

	return (
		<MessageCard
			leftBordercolor={message.primaryCampaignColour}
			placeholder={isDisabled ? linkedInDisconnectedMsg : undefined}
			formName={message.id}
			loading={message.isSubmitting}
			sender={
				<ProspectInfo
					name={message.contactName}
					alias={linkedInAlias}
					company={message.company}
					position={message.position}
					phoneNumber={message.phoneNumber}
					email={message.email}
					campaignName={message.campaignName}
					campaignType={message.campaignType}
					campaigns={campaignsExcludingCurrent}
					onCampaignChange={handleCampaignChange}
					onCampaignDropdownVisible={handleCampaignDropdownVisible}
				/>
			}
			recipient={
				<Text type="secondary" className={styles.recipientContainer}>
					{`Sent ${isLastMessageReceived ? 'to' : 'by'}`} {message.orgMemberName}{' '}
					&#128900; {getFormattedDate(lastMessage.timestamp, LONG_DATE_TIME_FORMAT)}
				</Text>
			}
			content={
				<div>
					<Paragraph
						strong={isLastMessageReceived}
						title={lastMessage.message}
						className={
							isLastMessageReceived
								? styles.lastMessageContent
								: styles.messageContent
						}
					>
						{`${isLastMessageReceived ? '' : SENT_MESSAGE_PREFIX}${calcDisplayMessage(
							lastMessage.message,
							isReminderButtonVisible,
							isExpandedMessageVisible
						)}`}
					</Paragraph>
					{isExpandButtonVisible(
						lastMessage.message,
						isReminderButtonVisible,
						isLastMessageReceived
					) ? (
						<Button
							type="link"
							onClick={onToggleMessageExpand}
							className={styles.messageExpandButton}
						>
							{isExpandedMessageVisible ? 'show less' : 'view entire message'}
						</Button>
					) : null}
				</div>
			}
			form={
				<MessageForm
					messageTemplates={messageTemplates}
					automatedSteps={automatedSteps}
					isThreadRestricted={message.isConversationRestricted}
					onSetupTemplate={getSetUpTemplateHandler()}
					onSubmit={handleSubmit}
					onSetReminder={handleSendWithReminder}
					onCardTracking={onTracking}
					placeholder={isDisabled ? linkedInDisconnectedMsg : undefined}
					isDisabled={isDisabled}
					chatGPTWriteRetryCallback={regenerateSmartReply}
					chatGPTWriteCallback={writeSmartReply}
					chatGPTEditCallback={editSmartReply}
					chatGPTQueryResult={smartReplyResult}
					smartReplyPromptCategory={getSmartReplyPromptCategory(message)}
					videoInAppValidationEnabled={videoInAppValidationEnabled}
					hasAcceptedAITermsOfUse={hasAcceptedAITermsOfUse}
				/>
			}
			actions={
				<Space size={8}>
					<Tooltip title="Set Reminder">
						<DropdownReminderSelector
							isReminderSet={false}
							onUpdate={handleSetReminder}
							onSetReminderClick={handleSetReminderClick}
							onCancel={() => {}}
							isIconOnly
							buttonType="default"
							buttonClass={styles.cardButton}
							buttonIcon={<ClockCircleOutlined />}
						/>
					</Tooltip>
					{isReminderButtonVisible ? (
						<Button
							ghost={false}
							block
							icon={<CheckOutlined />}
							className={styles.positiveCardButton}
							onClick={handleCompleteReminder}
						>
							Mark as complete
						</Button>
					) : (
						<Tooltip title="Dismiss Conversation">
							<Button
								ghost={false}
								block
								icon={<CloseOutlined />}
								className={styles.cardButton}
								onClick={handleDismiss}
							/>
						</Tooltip>
					)}
					<Tooltip title="View More">
						<Button
							onClick={handleViewProspect}
							ghost={false}
							block
							className={styles.cardButton}
							icon={<RightOutlined />}
						>
							More
						</Button>
					</Tooltip>
				</Space>
			}
		/>
	);
}

export default InboxMessageCard;
