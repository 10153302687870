import { ReactElement } from 'react';
import { Checkbox, Col, Form, Input, Row, Select, Space, Tooltip } from 'antd';
import { useTheme } from 'styled-components';
import { FormInstance } from 'antd/es/form';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getContentByMember } from '@copilot/common/components/editors/orgMemberInfo/const';
import { UserDetail } from '@copilot/common/pages/accountDetailsPage/types';
import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import {
	IOrganizationMemberRole,
	IUpdateUserGivenNamesInput,
} from '@copilot/data/graphql/_generated';
import Section from '@copilot/common/components/drawer/wrappers/accountDashboard/section';
import ListBox from '@copilot/common/components/drawer/wrappers/accountDashboard/listBox';

const MAP_ORG_MEMBER_ROLE_TO_TITLE = {
	[IOrganizationMemberRole.Admin]: OrgRoleTitles.Admin,
	[IOrganizationMemberRole.Basic]: OrgRoleTitles.User,
	[IOrganizationMemberRole.Advanced]: OrgRoleTitles.Advanced,
};

/**
 * Props used for the user edit form
 */
type UserEditFormProps = Readonly<{
	/** The form */
	form: FormInstance<IUpdateUserGivenNamesInput>;
	/** The initial values */
	initialValues: UserDetail;
	/** Callback when the form finishes */
	onFinish: (values: IUpdateUserGivenNamesInput) => void;
}>;

/**
 * Component for the body of the user edit form
 * @param props
 */
export default function UserEditForm({
	initialValues,
	form,
	onFinish,
}: UserEditFormProps): ReactElement {
	const theme = useTheme();

	return (
		<Form form={form} onFinish={onFinish}>
			<Section
				title="User Information"
				body={
					<>
						<Row
							gutter={theme['@spacer-num-xs']}
							style={{ marginBottom: theme['@spacer-num-xs'] }}
						>
							<Col span={12}>
								<label htmlFor="firstName">First Name</label>
								<div>
									<Form.Item
										name="firstName"
										initialValue={initialValues.firstName}
										style={{ marginBottom: 0 }}
										rules={[
											{
												required: true,
												whitespace: true,
												message: 'Required',
											},
										]}
									>
										<Input />
									</Form.Item>
								</div>
							</Col>

							<Col span={12}>
								<label htmlFor="lastName">Last Name</label>
								<Form.Item
									name="lastName"
									initialValue={initialValues.lastName}
									style={{ marginBottom: 0 }}
									rules={[
										{
											required: true,
											whitespace: true,
											message: 'Required',
										},
									]}
								>
									<Input />
								</Form.Item>
							</Col>
						</Row>

						<Space>
							<label>Email</label>
							<Tooltip overlay={'Go to the teams tab to edit this field.'}>
								<InfoCircleOutlined />
							</Tooltip>
						</Space>
						<Row gutter={theme['@spacer-num-xs']}>
							<Col span={12}>
								<Input
									disabled
									value={initialValues.userName}
									style={{ marginBottom: theme['@spacer-num-xs'] }}
								/>
							</Col>
						</Row>
						<Space>
							<label>VPN Port</label>
							<Tooltip overlay={'Go to the teams tab to edit this field.'}>
								<InfoCircleOutlined />
							</Tooltip>
						</Space>
						<Input
							disabled
							value={initialValues.vpnPort}
							style={{ marginBottom: theme['@spacer-num-xs'] }}
						/>
						<label>Permission</label>
						<Row gutter={theme['@spacer-num-xs']}>
							<Col span={12}>
								<Select
									style={{ width: '100%' }}
									value={initialValues.role}
									options={[
										{
											value: IOrganizationMemberRole.Admin,
											label: 'Administrator',
										},
										{
											value: IOrganizationMemberRole.Advanced,
											label: 'Advanced',
										},
										{
											value: IOrganizationMemberRole.Basic,
											label: 'Basic',
										},
									]}
									disabled={true}
								/>
							</Col>
							<Col span={12} style={{ textAlign: 'right' }}>
								<Space>
									<Checkbox
										checked={initialValues.isPrimaryContact}
										disabled={true}
									>
										Primary Contact
									</Checkbox>
									<Tooltip
										overlay={`The primary contact for each customer 
										account must be an Administrator. They are your 
										main point of contact. To change the primary contact, 
										please reach out to our support team.`}
									>
										<InfoCircleOutlined />
									</Tooltip>
								</Space>
							</Col>
						</Row>
					</>
				}
			/>
			<ListBox
				title="Prospecting Tool Permissions"
				items={getContentByMember(
					MAP_ORG_MEMBER_ROLE_TO_TITLE[
						initialValues.role ?? IOrganizationMemberRole.Basic
					]
				)}
			/>
		</Form>
	);
}
