import React, { useCallback } from 'react';
import { List } from 'antd';
import NoteItem from './item';
import SubmitForm from '../submitForm';
import { Contact, Note } from '@copilot/common/store/models/redux';
import { ActivityManager } from '@copilot/data';
import { SessionBoundModel } from 'redux-orm';
import { ContactActions } from '@copilot/common/store/actions/contact';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import Layout from '@copilot/common/components/layout';
import { spacingMD } from '@copilot/common/constant/commonStyles';
import { checkHasAccess } from '@copilot/common/utils/access';
import { DRAWER_SAVE_NOTE_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';

export interface NotesProps {
	contact: SessionBoundModel<Contact> | null;
	orgMemberId: string;
}

const Notes: React.FC<NotesProps> = (props) => {
	const { contact, orgMemberId } = props;
	const activeUser = useSelector(OrganizationMemberSelectors.getActiveMember);
	const storeDispatch = useDispatch();

	const deleteNote = useCallback(
		async (note: Note) => {
			if (!contact) return;
			await ActivityManager.deleteNotesFromContact(contact.id, note);
			const { organization: _, ...rest } = contact.ref;
			storeDispatch(
				ContactActions.updateContact({
					...rest,
					notes: [...rest.notes.filter((n) => n.id != note.id)],
				})
			);
		},
		[contact]
	);

	const submitNote = useCallback(
		async (msg: string) => {
			if (!contact) return;
			const response = await ActivityManager.submitNoteToContact(
				contact.id,
				orgMemberId,
				msg
			);
			const { organization: _, ...rest } = contact.ref;
			storeDispatch(
				ContactActions.updateContact({
					...rest,
					notes: [...rest.notes, response],
				})
			);
		},
		[contact, orgMemberId]
	);

	const generateList = useCallback(() => {
		if (!contact?.notes || !activeUser?.id) return null;
		const { notes } = contact;
		const filteredNotes = notes.filter((note) => note.orgMemberId === orgMemberId);
		return (
			<List
				dataSource={filteredNotes}
				itemLayout="vertical"
				className="notes-list"
				renderItem={(item) => (
					<NoteItem
						key={item.id}
						note={item}
						className=""
						onRemoveNote={deleteNote}
						isDeletable={checkHasAccess(
							activeUser.id,
							item.metaData.AddedBy,
							activeUser?.isOrgAdmin
						)}
					/>
				)}
			/>
		);
	}, [deleteNote, orgMemberId, activeUser, contact?.notes]);

	return (
		<Layout>
			<Layout.Content>
				<div style={{ padding: spacingMD }}>{generateList()}</div>
			</Layout.Content>
			<Layout.Footer>
				<div style={{ padding: spacingMD }}>
					<SubmitForm
						submitButtonText="Save"
						onSubmit={submitNote}
						submitTrackingID={DRAWER_SAVE_NOTE_TRACKING_ID}
					/>
				</div>
			</Layout.Footer>
		</Layout>
	);
};

export default Notes;
