import {
	Children,
	ComponentProps,
	createContext,
	FC,
	isValidElement,
	ReactElement,
	ReactNode,
	useContext,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { Row, Col, Switch, Slider, Typography } from 'antd';
import { SliderRangeProps } from 'antd/lib/slider';

export const MIN_DAILY_SCHEDULE_HOURS = 3;
const StyledCol = styled(Col)`
	${(props) => {
		const prefix = props.theme['@ant-prefix'];
		return `
		.${prefix}-tooltip {
			z-index: 0;
		}
		.${prefix}-tooltip-inner {
			background-color: white;
			color: black;
			box-shadow: none;
			white-space: nowrap;
		}
	`;
	}}
`;

export const timeFormatter = (dayMinutes: number): string => {
	const hours = Math.floor(dayMinutes / 60) % 12;
	const minutes = dayMinutes % 60;
	const unit = dayMinutes >= 720 && dayMinutes < 1440 ? 'p.m.' : 'a.m.';
	return `${hours === 0 ? 12 : hours < 10 ? hours : hours}:${
		minutes < 10 ? `0${minutes}` : minutes
	} ${unit}`;
};

interface LabelledRangeSliderProps extends Omit<SliderRangeProps, 'range'> {
	label: ReactNode;
	isEnabled: boolean;
	onEnableToggle: (state: boolean) => unknown;
	validator?: (value: number[]) => string | undefined;
}

/**
 * Range selector with enable toggle and label to the left
 * @returns
 */
const LabelledRangeSlider: FC<LabelledRangeSliderProps> = (props) => {
	const { label, isEnabled, onEnableToggle, validator, ...sliderProps } = props;
	const { value } = sliderProps;

	const error = validator?.(value ?? [0, 0]);
	const theme = useTheme();
	return (
		<>
			<Row align={'middle'} gutter={theme['@spacer-num-sm']}>
				<Col>{label}</Col>
				<Col>
					<Switch checked={isEnabled} onChange={onEnableToggle} />
				</Col>
				<StyledCol flex="auto">
					{isEnabled ? (
						<Slider
							min={0}
							max={1440}
							step={30}
							tooltipVisible
							range
							tipFormatter={(time?: number) => (time ? timeFormatter(time) : time)}
							getTooltipPopupContainer={(node) => node.parentElement ?? node}
							allowCross={false}
							{...sliderProps}
						/>
					) : (
						<Typography.Text>Disabled</Typography.Text>
					)}
				</StyledCol>
			</Row>
			<Row style={{ minHeight: '2em', padding: 0, margin: 0 }}>
				{isEnabled && error ? (
					<Col offset={5}>
						<h4 style={{ color: 'red' }}>{error}</h4>
					</Col>
				) : null}
			</Row>
		</>
	);
};

interface ISyncedRangeSliderContext {
	syncedOnChange: (value: number[]) => unknown;
	isSynced: boolean;
}
/**
 * Context for synced range slider
 */
const SyncedRangeSliderContext = createContext<ISyncedRangeSliderContext>({
	syncedOnChange: () => {},
	isSynced: false,
});

interface RangeSlidersProps {
	isSynced: boolean;
}

type RangeSliderComponent = FC<RangeSlidersProps> & { Slider: typeof SyncedRangeSlider };

/**
 * Container component for a a group of ranged sliders that can be in sync
 */
const RangeSliders: RangeSliderComponent = ({ children, isSynced }) => {
	const onChangeCallbacks =
		Children.map(children, (child) =>
			isSyncedRangeSlider(child) ? child.props.onChange : undefined
		)?.filter((callback) => !!callback) ?? [];

	const syncedOnChange = (value: number[]) =>
		onChangeCallbacks.forEach((callback) => callback(value));

	return (
		<SyncedRangeSliderContext.Provider value={{ syncedOnChange, isSynced }}>
			{Children.map(children, (child) => (
				<div style={{ marginTop: '2em' }}>{child}</div>
			))}
		</SyncedRangeSliderContext.Provider>
	);
};

const withSync = (Component: typeof LabelledRangeSlider): FC<LabelledRangeSliderProps> => {
	return (props) => {
		const { syncedOnChange, isSynced } =
			useContext<ISyncedRangeSliderContext>(SyncedRangeSliderContext);
		const { onChange: originalOnChange, ...componentProps } = props;
		const onChange = isSynced ? syncedOnChange : originalOnChange;
		return <Component {...componentProps} onChange={onChange} />;
	};
};

const SyncedRangeSlider = withSync(LabelledRangeSlider);

const isSyncedRangeSlider = (
	element: ReactNode
): element is ReactElement<ComponentProps<typeof SyncedRangeSlider>> =>
	isValidElement(element) && element.type === SyncedRangeSlider;

RangeSliders.Slider = SyncedRangeSlider;

export default RangeSliders;
