import { ILinkedInMeta, LinkedInMeta } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = LinkedInMeta.modelName;
export namespace LinkedInMetaActions {
	export const loadLinkedInMeta = (meta: Partial<ILinkedInMeta> | Partial<ILinkedInMeta>[]) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: meta,
	});
}
