import { PersonalizedInsightsManager } from '@copilot/data/managers/personalizedInsights';
import { CampaignConnectionModel } from '../model';
import modalManager from '@copilot/common/utils/modalManager';
import notificationManager from '@copilot/common/utils/notificationManager';

/**
 * Retrieve the campaign history for the selected org member or an empty array if they have no connections/no history
 * @param campaignConnectionArr
 * @param selectedMemberId
 * @returns
 */
export function parseCampaignHistory(
	campaignConnectionArr: readonly CampaignConnectionModel[],
	selectedMemberId: string
) {
	const campaignConnection = campaignConnectionArr.filter(
		(contactConnect) => contactConnect.orgMemberId === selectedMemberId
	);
	if (campaignConnection.length !== 1) {
		return [];
	}
	return campaignConnection[0].campaignHistory ? campaignConnection[0].campaignHistory : [];
}

/**
 * Fetch remaining credits for personal insights, if we have some remaining open personal insights in a new tab. Otherwise show out of credits modal
 * @param profileId
 */
export async function handleOpenPersonalizedInsights(profileId: string) {
	const credits = await PersonalizedInsightsManager.getRemainingCredits();
	// we no longer wants to show the confirmation modal when user has credits remaining
	if (credits.data.available <= 0) {
		modalManager.openOutOfCreditModal({});
	} else if (profileId) {
		window.open(`/insights/${profileId}`, '_blank');
	} else {
		notificationManager.showErrorNotification({
			message: 'Failed to grab personalized insights due to missing alias',
			description: 'Please try again later',
			duration: 1000,
		});
	}
}
