import { FC, useMemo } from 'react';
import { InboxMessagesPageProps } from '@copilot/common/pages/inbox/ui/types';
import {
	InboxCategoryTabsAdditionalProps,
	MessageTemplateType,
	TemplateAndAutomationProps,
} from '@copilot/common/pages/inbox/ui/card/types';
import InboxPageLayout from '@copilot/common/pages/inbox/ui/inboxPageLayout';
import styled from 'styled-components';
import BasicContainer from '@copilot/common/components/containers/basic';
import { Alert, Button } from 'antd';
import { InboxDisplayType } from '@copilot/data/requests/models';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';
import {
	SlideRightThenShrinkAnimation,
	SlideRightThenShrinkAnimationGroup,
} from '@copilot/common/animation/slideRightThenShrink';
import { getChildByType } from '@copilot/common/utils/common';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';
import { useInboxPageCardViewTracking } from '@copilot/common/pages/inbox/tracking/cardView';
import { useDispatch, useSelector } from 'react-redux';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { CampaignType } from '@copilot/data/responses/interface';
import { SessionBoundModel } from 'redux-orm';
import { Campaign } from '@copilot/common/store/models/redux';
import { sendAutomatedMessage, sendManualMessage } from '../../data/actionCreators';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { calcAutomatedSteps } from '../component/automationHelpers';
import InboxMessageCard from '../component/card/messageCard';
import partial from 'lodash/partial';

const ContentContainer = styled(BasicContainer.Content)`
	background-color: ${(props) => props.theme['@layout-body-background']};
`;

/**
 * [Presentational] Tabs sub-component
 * @param props
 */
const Tabs: FC<{}> = (props) => <ErrorBoundary>{props.children}</ErrorBoundary>;

type InboxCardViewProps = {
	messageTemplates: MessageTemplateType[];
} & InboxMessagesPageProps &
	InboxCategoryTabsAdditionalProps &
	TemplateAndAutomationProps;

/**
 * [Presentational] Base Component for the Inbox Card View
 * @param props
 */
function InboxCardView({
	onViewUpdate,
	data,
	onSnooze,
	onDismiss,
	onLoadMore,
	loading,
	onOpenConversationDetail,
	messageTemplates,
	children,
	onMigrate,
	onSetupTemplate,
	onSetupAutomation,
	showSetUpAutomation,
	isReminderTabSelected,
	onCompleteReminder,
	showLoadMore,
}: InboxCardViewProps) {
	const filterComponent = getChildByType(children, InboxPageLayout.Filter);
	const tabsComponent = getChildByType(children, Tabs);
	const nurtureCampaigns = useSelector(
		CampaignSelectors.getCampaignsByType([CampaignType.Nurture])
	);
	const organizationId = useSelector(OrganizationSelectors.getActiveOrganizationId);

	const updateInboxCardViewTracking = useInboxPageCardViewTracking('Inbox Card View');
	const dispatch = useDispatch();

	const campaignToMessageTemplateMap = useMemo(() => {
		const campaignTemplateLookup = new Map<string, MessageTemplateType[]>();
		messageTemplates.forEach((messageTemplate) => {
			messageTemplate.campaignIds.forEach((campaignId) => {
				if (campaignTemplateLookup.has(campaignId)) {
					campaignTemplateLookup.get(campaignId)?.push(messageTemplate);
				} else {
					campaignTemplateLookup.set(campaignId, [messageTemplate]);
				}
			});
		});
		return campaignTemplateLookup;
	}, [messageTemplates]);

	const orgMemberToNurtureCampaignMap: Map<string, SessionBoundModel<Campaign, {}>[]> =
		useMemo(() => {
			const internalMap = new Map<string, SessionBoundModel<Campaign, {}>[]>();
			data.forEach((message) => {
				if (!internalMap.has(message.orgMemberId)) {
					internalMap.set(
						message.orgMemberId,
						nurtureCampaigns.filter(
							(campaign) =>
								campaign.members
									.toModelArray()
									.filter((member) => member.orgMemberId === message.orgMemberId)
									.length > 0
						)
					);
				}
			});

			return internalMap;
		}, [data, nurtureCampaigns]);

	function onSubmitInboxMessage(
		threadId: string,
		msgText: string,
		removeReminder: boolean,
		nodeId?: string,
		templateId?: string,
		campaignId?: string
	) {
		nodeId
			? dispatch(sendAutomatedMessage(threadId, msgText, nodeId))
			: dispatch(
					sendManualMessage(threadId, msgText, removeReminder, templateId, campaignId)
			  );
	}

	return (
		<InboxPageLayout viewType={InboxDisplayType.Cards} onViewUpdate={onViewUpdate}>
			<InboxPageLayout.Header>
				<h2>Inbox</h2>
			</InboxPageLayout.Header>
			{filterComponent}
			<InboxPageLayout.Content>
				{tabsComponent}
				<ContentContainer>
					<LoadingIndicator isLoading={loading}>
						<SlideRightThenShrinkAnimationGroup>
							{data.map((message, idx) => (
								<SlideRightThenShrinkAnimation timeout={800} key={message.id}>
									<InboxMessageCard
										organizationId={organizationId}
										threadIdx={idx}
										showSetUpAutomation={showSetUpAutomation}
										message={message}
										onSnooze={(dateSnoozeUntil: moment.Moment) =>
											onSnooze(message.id, dateSnoozeUntil)
										}
										onDismiss={onDismiss}
										onMigrate={onMigrate}
										onOpenConversationDetail={onOpenConversationDetail}
										onSetupTemplate={() => onSetupTemplate(message.campaignId)}
										onSetupAutomation={
											onSetupAutomation
												? () => onSetupAutomation(message.campaignId)
												: undefined
										}
										messageTemplates={
											campaignToMessageTemplateMap.get(message.campaignId) ??
											[]
										}
										migratableCampaigns={
											orgMemberToNurtureCampaignMap.get(
												message.orgMemberId
											) ?? []
										}
										onTracking={updateInboxCardViewTracking}
										isReminderTabSelected={isReminderTabSelected}
										onCompleteReminder={() => onCompleteReminder(message.id)}
										automatedSteps={calcAutomatedSteps(message.nextNodes)}
										onSubmit={partial(onSubmitInboxMessage, message.threadId)}
									/>
								</SlideRightThenShrinkAnimation>
							))}
						</SlideRightThenShrinkAnimationGroup>
						{!data.length && (
							<Alert
								message="No messages to load"
								type="info"
								style={{ backgroundColor: '#f2f2f2', borderColor: '#d9d9d9' }}
							/>
						)}
						{showLoadMore ? (
							<div
								style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
							>
								<Button
									block
									style={{ maxWidth: '1152px' }}
									onClick={() => {
										onLoadMore(data.length);
									}}
								>
									Load More
								</Button>
							</div>
						) : null}
					</LoadingIndicator>
				</ContentContainer>
			</InboxPageLayout.Content>
		</InboxPageLayout>
	);
}

InboxCardView.Filter = InboxPageLayout.Filter;
InboxCardView.Tabs = Tabs;

export default InboxCardView;
