import { FC, useContext } from 'react';
import { Row, Col, Divider, Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import { CheckCircleFilled } from '@ant-design/icons';
import BasicContainer from '@copilot/common/components/containers/basic';
import { ThemeContext } from 'styled-components';

interface INewUserCompleteProps {
	onRedirect: () => void;
	isButtonLoading: boolean;
}

/**
 * [Presentational] Complete step of the in-app New User Onboarding
 * @param {function} onRedirect takes the user to the dashboard
 */
const NewUserComplete: FC<INewUserCompleteProps> = (props) => {
	const { onRedirect, isButtonLoading } = props;
	const themeContext = useContext(ThemeContext);

	return (
		<Row justify="center">
			<Col xxl={20}>
				<BasicContainer style={{ textAlign: 'center' }}>
					<BasicContainer.Content style={{ padding: '36px 40px' }}>
						<Row justify="center" style={{ marginBottom: '18px' }}>
							<Col>
								<CheckCircleFilled
									style={{
										color: themeContext['@success-color'],
										fontSize: '4em',
									}}
								/>
							</Col>
						</Row>
						<Row justify="center">
							<Col xxl={18}>
								<Title level={4} style={{ margin: 0 }}>
									Our experts will review your campaign to make sure you are
									set-up to hit your goals.
								</Title>
							</Col>
						</Row>
						<Row justify="center">
							<Col span={24}>
								<Divider style={{ margin: '28px 0' }} />
							</Col>
						</Row>
						<Row justify="center">
							<Col>
								<Title level={5} style={{ margin: 0 }}>
									Let us know how we did!
								</Title>
							</Col>
						</Row>
						<Row justify="center" style={{ marginTop: '24px' }}>
							<Col>
								<Button
									type="primary"
									onClick={onRedirect}
									loading={isButtonLoading}
								>
									Take me to my dashboard
								</Button>
							</Col>
						</Row>
					</BasicContainer.Content>
				</BasicContainer>
			</Col>
		</Row>
	);
};

export default NewUserComplete;
