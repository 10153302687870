import { attr } from 'redux-orm';
import { BaseModel } from './base';

export interface IPushNotification {
	id: string;
	name: string;
	notificationCode: string;
	description: string;
	availablePushMethods: number;
	isActive: boolean;
	subscriptions: Record<string, number>;
}

export class PushNotification extends BaseModel<typeof PushNotification, IPushNotification> {
	static modelName = 'PushNotification';
	static fields = {
		id: attr(),
		name: attr(),
		notificationCode: attr(),
		description: attr(),
		availablePushMethods: attr(),
		isActive: attr(),
		subscriptions: attr(),
	};
}
