import { PaginatedResponse } from '@copilot/data/responses/interface';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getLoadActionTypes, LoadActions } from '@copilot/common/store/actionCreators/list';
import { LoadSingleActions } from '@copilot/common/store/actionCreators/singleEntity';
import { ModelStateMap } from '@copilot/common/store/models/fetch';
import notificationManager from '@copilot/common/utils/notificationManager';
import { getStateLocationByName } from '@copilot/common/store/selectors/fetch';

type CallbacksType = {
	onSuccess?: () => void;
	onError?: () => void;
};

/**
 * Creates a selector that gets the model state map by its name.
 * Use internally.
 * @param name
 */
const createFetchStateSelector =
	<T>(name: string) =>
	(state: Record<string, ModelStateMap<T>>) =>
		getStateLocationByName(state, name);

/**
 * Create Saga for fetch and the action to trigger it
 * @param name State name
 * @param actions Actions for to update the store action
 * @param loadOneFunctionFactory function to retrieve one item
 */
export const createFetchSaga = <
	LoadListFn extends (...args: any[]) => Promise<PaginatedResponse<U> | U[]>,
	LoadOneFn extends (...args: any[]) => Promise<U>,
	UpsertListFn extends (...args: any[]) => Promise<PaginatedResponse<U> | U[]>,
	DeleteOneFn extends (...args: any[]) => Promise<void>,
	U extends { id: string }
>(
	name: string,
	actions: LoadActions<U>,
	loadOneFunctionFactory?: (qualifier: string, id: string) => Promise<U>
) => {
	const listActionType = `FETCH_LIST_${name}`;
	const oneActionType = `FETCH_ONE_${name}`;
	const deleteActionType = `DELETE_ONE_${name}`;
	const upsertListActionType = `UPSERT_LIST_${name}`;
	const autoUpdateActionType = `AUTO_UPDATE_${name}`;
	const actionTypes = getLoadActionTypes(name);

	const modelStateMapSelector = createFetchStateSelector<{ id: string }>(name);

	const segmentedLoadListAction =
		(loader: LoadListFn, ...args: ArgumentTypes<LoadListFn>) =>
		(qualifier?: string) => ({
			type: listActionType,
			args,
			loader,
			qualifier,
		});
	const loadListAction = (loader: LoadListFn, ...args: ArgumentTypes<LoadListFn>) =>
		segmentedLoadListAction(loader, ...args)();

	const segmentedLoadOneAction =
		(loader: LoadOneFn, ...args: ArgumentTypes<LoadOneFn>) =>
		(qualifier?: string) => ({
			type: oneActionType,
			args,
			loader,
			qualifier,
		});
	const loadOneAction = (loader: LoadOneFn, ...args: ArgumentTypes<LoadOneFn>) =>
		segmentedLoadOneAction(loader, ...args)();

	const segmentedUpsertListAction =
		(loader: UpsertListFn, ...args: ArgumentTypes<UpsertListFn>) =>
		(qualifier?: string) => ({
			type: upsertListActionType,
			args,
			loader,
			qualifier,
		});
	const upsertListAction = (loader: UpsertListFn, ...args: ArgumentTypes<UpsertListFn>) =>
		segmentedUpsertListAction(loader, ...args)();

	const segmentedDeleteOneAction =
		(loader: DeleteOneFn, removalId: string, ...args: ArgumentTypes<DeleteOneFn>) =>
		(qualifier?: string) => ({
			type: deleteActionType,
			args,
			removalId,
			loader,
			qualifier,
		});
	const deleteOneAction = (
		loader: DeleteOneFn,
		removalId: string,
		...args: ArgumentTypes<DeleteOneFn>
	) => segmentedDeleteOneAction(loader, removalId, ...args)();

	const segmentedAddToDirtyListAction = (qualifier?: string) => (dirtyModelIds: string[]) =>
		actions.addToDirtyList(qualifier)(dirtyModelIds);
	const addToDirtyListAction = (dirtyModelIds: string[]) =>
		segmentedAddToDirtyListAction()(dirtyModelIds);

	const autoUpdateAction = (ids: string[]) => ({
		type: autoUpdateActionType,
		ids,
	});

	function* fetchListAsync(action: ReturnType<typeof loadListAction>) {
		try {
			yield put(actions.load(action.qualifier)());
			const payload: PromiseFactoryThenType<typeof action.loader> = yield call(
				action.loader,
				...action.args
			);
			yield put(actions.loadListSuccess(action.qualifier)(payload));
		} catch (error) {
			yield put(actions.loadListError(action.qualifier)(error));
		}
	}
	function* listWatcher() {
		yield takeEvery(listActionType, fetchListAsync);
	}

	function* fetchOneAsync(action: ReturnType<typeof loadOneAction>) {
		try {
			yield put(actions.load(action.qualifier)());
			const payload: U = yield call(action.loader, ...action.args);
			yield put(actions.loadOneSuccess(action.qualifier)(payload));
		} catch (error) {
			yield put(actions.loadOneError(action.qualifier)(error));
		}
	}

	function* upsertListAsync(action: ReturnType<typeof upsertListAction>) {
		try {
			yield put(actions.load(action.qualifier)());
			const payload: PromiseFactoryThenType<typeof action.loader> = yield call(
				action.loader,
				...action.args
			);
			yield put(actions.upsertListSuccess(action.qualifier)(payload));
		} catch (error) {
			yield put(actions.upsertListError(action.qualifier)(error));
		}
	}
	function* upsertListWatcher() {
		yield takeEvery(upsertListActionType, upsertListAsync);
	}

	function* oneWatcher() {
		yield takeEvery(oneActionType, fetchOneAsync);
	}

	function* deleteOneAsync(action: ReturnType<typeof deleteOneAction>) {
		try {
			yield put(actions.load(action.qualifier)());
			yield call(action.loader, ...action.args);
			yield put(actions.deleteOneSuccess(action.qualifier)(action.removalId));
		} catch (error) {
			yield put(actions.deleteOneError(action.qualifier)(error));
		}
	}
	function* deleteOneWatcher() {
		yield takeEvery(deleteActionType, deleteOneAsync);
	}

	function* addToDirtyList(action: ReturnType<typeof addToDirtyListAction>) {
		try {
			yield put(actions.addToDirtyListSuccess(action.qualifier)(action.dirtyModelIds));
		} catch (error) {}
	}

	function* addToDirtyListWatcher() {
		yield takeEvery(actionTypes.addToDirtyListAction, addToDirtyList);
	}

	function* autoUpdate(action: ReturnType<typeof autoUpdateAction>) {
		if (!loadOneFunctionFactory) return;
		try {
			const stateMap: ModelStateMap<{ id: string }> = yield select(modelStateMapSelector);

			for (const qualifier in stateMap) {
				const stateWithDirtyList = stateMap[qualifier];
				const data = stateWithDirtyList?.state?.data;
				const dirtyIds = stateWithDirtyList?.dirtyModelIds ?? [];
				const updatableIds = data.map((model) => model.id).concat(dirtyIds);
				const idsToUpdate = updatableIds.filter((id) => action.ids.indexOf(id) >= 0);
				const calls = idsToUpdate.map((id) =>
					call(() => loadOneFunctionFactory(qualifier, id))
				);
				const result: U[] = yield all(calls);
				const updateActions = result.map((updatedModel) =>
					put(actions.loadOneSuccess(qualifier)(updatedModel))
				);
				yield all(updateActions);
			}
		} catch (error) {
			notificationManager.showErrorNotification({
				message: 'Failed to update UI.  Please refresh the browser.',
			});
		}
	}

	function* autoUpdateWatcher() {
		yield takeEvery(autoUpdateActionType, autoUpdate);
	}

	return {
		oneWatcher,
		listWatcher,
		upsertListWatcher,
		segmentedLoadListAction,
		loadListAction,
		segmentedLoadOneAction,
		loadOneAction,
		segmentedUpsertListAction,
		upsertListAction,
		deleteOneWatcher,
		segmentedDeleteOneAction,
		deleteOneAction,
		segmentedAddToDirtyListAction,
		addToDirtyListAction,
		addToDirtyListWatcher,
		autoUpdateAction,
		autoUpdateWatcher,
	};
};

/**
 * Create Saga for fetching a single entity and the action to trigger it
 * @param name State name
 * @param actions Actions for updating the store
 */
export const createSingleEntitySaga = <LoadSingleFn extends (...args: any[]) => Promise<U>, U>(
	name: string,
	actions: LoadSingleActions<U>
) => {
	const fetchSingleType = `FETCH_${name}`;
	const fetchSingleAction = (
		loader: LoadSingleFn,
		callbacks: CallbacksType,
		...args: ArgumentTypes<LoadSingleFn>
	) => ({
		type: fetchSingleType,
		loader,
		onSuccess: callbacks.onSuccess,
		onError: callbacks.onError,
		args,
	});

	function* fetchSingleAsync(action: ReturnType<typeof fetchSingleAction>) {
		try {
			yield put(actions.loadSingle());
			const payload: U = yield call(action.loader, ...action.args);
			yield put(actions.loadSingleSuccess(payload));
			if (action.onSuccess) yield call(action.onSuccess);
		} catch (error) {
			yield put(actions.loadSingleError(error));
			if (action.onError) yield call(action.onError);
		}
	}

	function* fetchWatcher() {
		yield takeEvery(fetchSingleType, fetchSingleAsync);
	}

	return { fetchWatcher, fetchSingleAction };
};
