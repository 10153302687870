import React from 'react';
import { AdminManager } from '@copilot/data/managers/admin';
import { TeamClientActions } from '@copilot/common/store/actions';
import { useFetch } from '@copilot/common/hooks/common';
import TeamInfo from '@copilot/common/components/forms/teamInfo';

import type { ITeamInfoFormValues } from '@copilot/common/components/forms/teamInfo';
import type { AdminClientCreateModel } from '@copilot/data/responses/interface';
import { ITenant } from '@copilot/data/graphql/_generated';

interface TeamInfoWrapperProps {
	onSuccess: () => void;
	defaultInstanceId: string;
	nodeTenantIdMap: Map<string, ITenant> | undefined;
}

const TeamInfoWrapper: React.FC<TeamInfoWrapperProps> = (props) => {
	const [teamCreate, createTeam] = useFetch(AdminManager.createTeam, TeamClientActions.load);
	const onNext = (values: ITeamInfoFormValues) => {
		const model: Partial<AdminClientCreateModel> = {
			instanceId: values.instanceId,
			tenantId: values.tenantId,
			firmName: values.customerOrganizationName,
			firstName: values.customerFirstName,
			lastName: values.customerLastName,
			email: values.customerEmail,
			password: values.newPassword,
			vpnPort: parseInt(values.proxy, 10),
			stripeCustomerId: values.customerStripeId,
		};
		createTeam(model).then(props.onSuccess);
	};

	return (
		<TeamInfo
			onNext={onNext}
			isError={teamCreate.error}
			isSubmitting={teamCreate.isFetching}
			defaultInstanceId={props.defaultInstanceId}
			nodeTenantIdMap={props.nodeTenantIdMap}
		/>
	);
};

export default TeamInfoWrapper;
