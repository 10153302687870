import useTracking from '@copilot/common/hooks/tracking';

const EVENT_NAME_PAGE = 'cs_customer_billing';
const EVENT_NAME_EDIT = 'cs_customer_billing_edit';

/**
 * Possible button clicks on customers billing page
 */
export type CSCustomerBillingButtonClickTypes =
	| 'Expand Current Subscription'
	| "Go to Customer's Stripe"
	| 'Expand Recent Invoice'
	| 'View Invoice'
	| 'Download Invoice'
	| 'Change Subscription'
	| 'View Stripe'
	| 'View Changes on Stripe'
	| 'View full invoice on Stripe'
	| 'View on Stripe';

/**
 * Reasons the Customer Billing button is disabled
 */
export type CSCustomerBillingButtonDisabledReasons =
	| 'Add-ons'
	| 'Unpaid invoice'
	| 'Upcoming update schedule'
	| 'Account cancelled'
	| 'Account cancelling'
	| 'Proration'
	| 'Unsupported Subscription Status';

interface CSTeamsCustomerBillingTrackingParamter {
	/** Parameter for button clicks on cs_customer_billing event */
	buttonClicked: CSCustomerBillingButtonClickTypes;
	reason: CSCustomerBillingButtonDisabledReasons;
}

/**
 * Specific hook for tracking on Customer Billing Page on CS Dash
 * @param componentName name of the component this hook it attached to
 * @param params parameters to track
 */
export const useCSCustomerBillingTracking = (
	componentName: string,
	params?: Partial<CSTeamsCustomerBillingTrackingParamter>
): ((newParams: Partial<CSTeamsCustomerBillingTrackingParamter>) => void) =>
	useTracking(componentName, EVENT_NAME_PAGE, params);

/**
 * The Current step that the user is on
 */
export type CSCustomerBillingEditWizardStepTypes = 'Choose New Plan' | 'Subscription Confirmation';

/**
 * Possible button clicks on customers billing page
 */
export type CSCustomerBillingEditButtonClickTypes =
	| 'Continue'
	| 'Update Subscription'
	| 'Start Subscription'
	| 'Back'
	| 'Cancel';

/**
 *
 */
interface CSTeamsCustomerBillingEditTrackingParamter {
	wizardStep: CSCustomerBillingEditWizardStepTypes;
	/** Parameter for button clicks on cs_customer_billing_edit event */
	buttonClicked: CSCustomerBillingEditButtonClickTypes;

	/** Payload for a change that occurs on the customer */
	isPriceIncrease: boolean;
	isTermIncrease: boolean;
	coupon: string;
	isUpdateNow: boolean;
}

/**
 * Specific hook for tracking on Customer Billing Edit Page on CS Dash
 * @param componentName name of the component this hook it attached to
 * @param params parameters to track
 */
export const useCSCustomerBillingEditTracking = (
	componentName: string,
	params?: Partial<CSTeamsCustomerBillingEditTrackingParamter>
): ((newParams: Partial<CSTeamsCustomerBillingEditTrackingParamter>) => void) =>
	useTracking(componentName, EVENT_NAME_EDIT, params);
