import { useEffect, useState } from 'react';
import { Button } from 'antd';
import TextArea from '../../forms/common/inputs/textarea';
import styles from './submitForm.module.less';
import isEmpty from 'lodash/isEmpty';

type SubmitFormProps = {
	submitButtonText: string;
	onSubmit: (x: string) => Promise<void>;
	disabled?: boolean;
	placeholder?: string;
	value?: string;
	submitTrackingID?: string;
};

/**
 * Simple text area form with a submit button, minimal validation
 * @param param0
 * @returns
 */
function SubmitForm({
	submitButtonText: submitText,
	onSubmit: handleSubmit,
	disabled,
	placeholder,
	value = '',
	submitTrackingID,
}: SubmitFormProps) {
	const [currMessage, setCurrMessage] = useState<string>(''); // used for button disabling
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	useEffect(() => {
		setCurrMessage(value);
	}, [value]);

	const canSubmit = !isEmpty(currMessage.trim()) && !disabled;

	async function onSubmit() {
		if (canSubmit) {
			setIsSubmitting(true);
			await handleSubmit(currMessage);
			setIsSubmitting(false);
			setCurrMessage('');
		}
	}

	return (
		<div className={styles.submitFormWrapper}>
			<TextArea
				disabled={disabled}
				onCtrlEnter={() => void onSubmit()}
				rows={3}
				autoSize={{ minRows: 3, maxRows: 5 }}
				placeholder={placeholder}
				onChange={(event) => setCurrMessage(event.target.value)}
				value={currMessage}
			/>
			<Button
				type="primary"
				disabled={!canSubmit}
				loading={isSubmitting}
				onClick={() => void onSubmit()}
				className={styles.submitButton}
				data-tracking-id={submitTrackingID}
			>
				{submitText}
			</Button>
		</div>
	);
}

export default SubmitForm;
