import React from 'react';
import { useSelector } from 'react-redux';
import BaseSidebar, { MenuIconType } from '@copilot/common/components/menus/sidebar';
import { AppSelectors } from '@copilot/common/store/selectors/app';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { Config } from '@copilot/common/config';

export const MenuKeys = {
	Root: '/',
	Categorize: '/categorize',
};

const MenuItems: () => React.ComponentProps<typeof BaseSidebar>['menuItems'] = () => {
	const items = [];

	if (!Config.hideCSTeamsDash) {
		items.push({
			key: MenuKeys.Root,
			to: MenuKeys.Root,
			icon: MenuIconType.TeamOutlined,
			label: 'Team Customers',
		});
	}

	// if in copilot's environment, return all tabs
	if (!Config.isAgency) {
		items.push({
			key: MenuKeys.Categorize,
			to: MenuKeys.Categorize,
			icon: MenuIconType.DashboardOutlined,
			label: 'Categorize',
		});
	}

	return items;
};

const Sidebar: React.FC = () => {
	const location = useSelector(AppSelectors.getCurrentLocation);
	const pathname = React.useMemo(() => {
		const pathSplit = location.pathname.split('/');
		if (!pathSplit[1]) return '/';
		else return location.pathname;
	}, [location.pathname]);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	if (!activeMember) return <span />;
	return (
		<BaseSidebar
			menuOptions={{
				mode: 'inline',
				theme: Config.isAgency ? 'light' : 'dark',
				selectedKeys: [pathname],
			}}
			menuItems={MenuItems()}
		/>
	);
};
export default Sidebar;
