import React, { useMemo } from 'react';
import { Select } from 'antd';

interface TagSelectorProps {
	selectedTags: string[];
	placeholder: string;
	onTagUpdate: (x: string[]) => void;
	availableTags?: string[];
}

/**
 * Selector for tags. Shows selected tags, selectable tags and can create new tags
 * @param {string[]} selectedTags a list of selected tags
 * @param {string} placeholder placeholder of selector
 * @callback onTagUpdate called on updating selector
 * @param {string[]} [availableTags=[]] tags that already exist we can select from
 */

const TagSelector: React.FC<TagSelectorProps> = (props) => {
	const { Option } = Select;
	const { availableTags = [], selectedTags, placeholder, onTagUpdate } = props;

	const availableTagNames = useMemo(() => new Set(availableTags), [availableTags]);
	const tagOptions = useMemo(
		() =>
			availableTags.map((tag) => (
				<Option key={tag} value={tag}>
					{tag}
				</Option>
			)),
		[availableTags]
	);

	const handleChange = (tags: string[]) => {
		for (let i = 0; i < tags.length; i++) {
			//To allow selecting existing tags that violate rules below
			if (availableTagNames.has(tags[i])) continue;
			const regLetters = /^[a-zA-Z0-9 ]+$/i;
			if (tags[i].length > 50) {
				alert('Tags must be less than 50 characters');
				return;
			} else if (!regLetters.test(tags[i])) {
				alert('Please use only alphanumericals');
				return;
			}
		}
		onTagUpdate(tags);
	};

	return (
		<Select
			mode="tags"
			style={{ width: '100%', minWidth: '200px' }}
			placeholder={placeholder}
			onChange={handleChange}
			value={selectedTags}
			tokenSeparators={[',']}
		>
			{tagOptions}
		</Select>
	);
};
export default TagSelector;
