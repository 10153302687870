import { CampaignsFilters } from '../types';
import { CampaignModelState, State } from './reducer';

type Selector<TState, TSelected> = (state: TState) => TSelected;

/**
 * Gets paged campaigns.
 * @param state
 */
export const getPagedCampaigns: Selector<State, CampaignModelState<CampaignsFilters>> = (state) =>
	state?.sagaPagedCampaigns;
