import { MutableRefObject, RefObject } from 'react';
import { SIGNAL_R_OPTIONS } from './constants';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { SignalRPeer } from '@copilot/common/screensharing/client';
import { CompleteSignal, UpdateSignal } from '@copilot/common/screensharing/signalrclient';
import { RTCState } from './types';
import { Config } from '@copilot/common/config';
import { IProxy } from '@copilot/common/store/models/redux';

type StreamLoginHelperProps = {
	partnerVideoRef: RefObject<HTMLVideoElement>;
	roomName: string | undefined;
	setRtcState: (signal: RTCState) => void;
	rtcState: RTCState;
	setIsLoaderVisible: (value: boolean) => void;
	rtcRef: MutableRefObject<SignalRPeer | undefined>;
};

/**
 * Helper function to handle the stream login, instantiates the signalRPeer and attaches listeners
 * @param param0
 */
export async function streamLoginHelper({
	partnerVideoRef,
	roomName,
	setRtcState,
	rtcState,
	setIsLoaderVisible,
	rtcRef,
}: StreamLoginHelperProps) {
	const shouldInitialize = roomName && rtcState === 'preparing' && isNil(rtcRef.current);
	if (!shouldInitialize) return;

	rtcRef.current = new SignalRPeer(
		`${Config.webRtcSignalingURL}/hubs/rtcsignaling`,
		SIGNAL_R_OPTIONS,
		function handleUpdate(signal: UpdateSignal) {
			setRtcState(signal);
		},
		function handleComplete(signal: CompleteSignal) {
			setRtcState(signal);
		}
	);

	attachListeners(rtcRef.current, partnerVideoRef, setIsLoaderVisible, setRtcState);

	await rtcRef.current.initConn();
	rtcRef.current.joinConnection(roomName);
}

/**
 * Attach all relevant listeners to the signalRPeer
 * @param signalRPeer
 * @param partnerVideoRef
 * @param setIsLoaderVisible
 * @param setRtcState
 */
function attachListeners(
	signalRPeer: SignalRPeer,
	partnerVideoRef: RefObject<HTMLVideoElement>,
	setIsLoaderVisible: (value: boolean) => void,
	setRtcState: (signal: RTCState) => void
) {
	function mouseupListener(e: MouseEvent) {
		const data = getMouseData(e);
		data.click = true;
		console.log('send mouseup', data);
		signalRPeer.send(data);
	}

	function isKeyboardPasteEvent(e: KeyboardEvent) {
		if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV') {
			return true;
		}

		return false;
	}
	function keydownListener(e: KeyboardEvent) {
		if (isKeyboardPasteEvent(e)) return;
		e.preventDefault();

		const data = {
			code: e.code,
			shift: e.shiftKey,
			meta: e.metaKey,
			control: e.ctrlKey,
			alt: e.altKey,
		};
		signalRPeer.send(data);
	}

	function pasteListener(event: ClipboardEvent) {
		const clipboardData = event.clipboardData?.getData('text');

		if (isNil(clipboardData) || isEmpty(clipboardData)) return;

		signalRPeer.send({ clipboardData });
	}

	function getMouseData(e: MouseEvent) {
		const data: any = {
			clientX: 0,
			clientY: 0,
			canvasWidth: 0,
			canvasHeight: 0,
		};
		const videoSize = partnerVideoRef.current?.getBoundingClientRect() ?? {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
		};

		data.clientX = e.clientX - videoSize.x;
		data.clientY = e.clientY - videoSize.y;

		if (partnerVideoRef.current) {
			data.canvasWidth = videoSize.width;
			data.canvasHeight = videoSize.height;
		}

		return data;
	}

	signalRPeer.on('connect', () => {
		console.log('Connected!');
		setRtcState('running');
		partnerVideoRef.current?.addEventListener('mouseup', mouseupListener);
		window.document.addEventListener('paste', pasteListener);
		window.addEventListener('keydown', keydownListener);
	});
	signalRPeer.on('close', () => {
		partnerVideoRef.current?.removeEventListener('mouseup', mouseupListener);
		window.document.removeEventListener('paste', pasteListener);
		window.removeEventListener('keydown', keydownListener);
		setIsLoaderVisible(false);
	});
	signalRPeer.on('stream', (stream: any) => {
		console.log('got the stream');
		if (partnerVideoRef.current) {
			console.log('adding stream');
			partnerVideoRef.current.srcObject = stream;
			partnerVideoRef.current.play();
			setIsLoaderVisible(false);
		}
	});
}

export function createProxyLookup(proxies: IProxy[]): { [region: string]: number[] } {
	return proxies
		.sort((a, b) => a.name.localeCompare(b.name))
		.reduce((acc, proxy) => {
			if (!acc[proxy.name]) {
				acc[proxy.name] = [];
			}
			acc[proxy.name].push(proxy.value);
			return acc;
		}, {} as { [region: string]: number[] });
}
