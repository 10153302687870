// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b1d6VZhHVYLukOhuQpSr {\n  max-width: 900px;\n  margin-left: auto;\n  margin-right: auto;\n}\n.b1d6VZhHVYLukOhuQpSr .copilot-card-body {\n  padding: 24px 67.5px;\n  min-width: 450px;\n}\n._NI8dzRBgfcB2AthNpxR {\n  height: 752px;\n  margin-left: 0px;\n  margin-right: 0px;\n}\n.Ski6zZ7xFd7R1tj2s_zg {\n  width: 100%;\n}\n.ZU99eE8HKWoaVqCvzQj6 {\n  width: 471px;\n}\n.xKtCkMQfcYTyYtCqtsxw {\n  max-width: 246px;\n}\n.xKtCkMQfcYTyYtCqtsxw li {\n  list-style-type: disc;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/campaignDashboard/sequence/inmail/inmailSequenceTab.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;AAJA;EAMI,oBAAA;EACA,gBAAA;AACJ;AAGA;EACE,aAAA;EACA,gBAAA;EACA,iBAAA;AADF;AAIA;EACE,WAAA;AAFF;AAKA;EACE,YAAA;AAHF;AAMA;EACE,gBAAA;AAJF;AAGA;EAGI,qBAAA;AAHJ","sourcesContent":[".container {\n  max-width: 900px;\n  margin-left: auto;\n  margin-right: auto;\n  \n  :global(.copilot-card-body) {\n    padding: 24px 67.5px;\n    min-width: 450px;\n  }\n}\n\n.divider {\n  height: 752px;\n  margin-left: 0px;\n  margin-right: 0px;\n}\n\n.editor {\n  width: 100%;\n}\n\n.messages {\n  width: 471px;\n}\n\n.guideline {\n  max-width: 246px;\n  li {\n    list-style-type: disc;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "b1d6VZhHVYLukOhuQpSr",
	"divider": "_NI8dzRBgfcB2AthNpxR",
	"editor": "Ski6zZ7xFd7R1tj2s_zg",
	"messages": "ZU99eE8HKWoaVqCvzQj6",
	"guideline": "xKtCkMQfcYTyYtCqtsxw"
};
export default ___CSS_LOADER_EXPORT___;
