import { CopilotDatePicker } from '@copilot/common/components/input/dates';
import styles from './reminderPicker.module.less';
import moment, { Moment } from 'moment';
import { type ComponentProps, useRef, useState } from 'react';
import isNil from 'lodash/isNil';
import { Button } from 'antd';
import { DEFAULT_DATE_FORMAT_WITH_TIME_GRANULARITY } from '@copilot/common/utils/dateFormat';
import { CSSProperties } from 'styled-components';

export type ReminderPickerProps = {
	selectedDate?: Moment;
	onToggleDatePicker?: () => void;
	onSetReminderDate: (selectedDate: Moment) => void;
	onCancel?: () => void;
	disabledDates?: (current: Moment) => any;
	isIconOnly?: boolean;
	buttonStyle?: CSSProperties;
	/**
	 * Configuration for aligning the datepicker to the input box
	 */
	alignment?: ComponentProps<typeof CopilotDatePicker>['dropdownAlign'];
};

/**
 * Specialized date picker for reminders
 * The wrapper is needed to isolate workarounds needed to get the antd picker to meet spec (it is not very extensible/has a lot of hardcoded behavior)
 * @param param0
 * @returns
 */
export default function ReminderPicker({
	selectedDate: initSelectedDate,
	onToggleDatePicker,
	onSetReminderDate,
	disabledDates,
	isIconOnly,
	buttonStyle,
	onCancel,
	alignment,
}: ReminderPickerProps) {
	const [selectedDate, setSelectedDate] = useState<Moment | undefined>(initSelectedDate);
	const pickerInputRef = useRef(null);
	return (
		<CopilotDatePicker
			popupClassName={styles.picker}
			defaultValue={selectedDate}
			className={styles.pickerInput}
			defaultOpen={true}
			showNow={false}
			onOpenChange={() => onToggleDatePicker?.()}
			showTime={{
				defaultValue: moment('04:00:00', 'HH:mm:ss'),
				format: 'HH:mm',
			}}
			placeholder="Remind on"
			disabledDate={disabledDates}
			style={isIconOnly ? undefined : buttonStyle}
			dropdownAlign={alignment}
			inputRender={(props) => <input {...props} ref={pickerInputRef} />}
			onMouseDown={() => {
				if (pickerInputRef.current) {
					setSelectedDate(
						moment(
							(pickerInputRef.current as HTMLInputElement)?.value,
							DEFAULT_DATE_FORMAT_WITH_TIME_GRANULARITY
						)
					);
				}
			}}
			renderExtraFooter={() => (
				<div className={styles.pickerButtonSection}>
					<Button
						size="small"
						onClick={() => {
							setSelectedDate(initSelectedDate);
							onCancel?.();
						}}
					>
						Cancel
					</Button>
					<Button
						size="small"
						type="primary"
						disabled={isNil(selectedDate)}
						onClick={() => {
							if (!isNil(selectedDate)) {
								onSetReminderDate(selectedDate);
							}
						}}
					>
						Set reminder
					</Button>
				</div>
			)}
		/>
	);
}
