/**
 * Inbox messages state Name
 */
export const INBOX_MESSAGES = 'INBOX_MESSAGES';

/**
 * Inbox messages counts state Name
 */
export const INBOX_MESSAGE_COUNTS = 'INBOX_MESSAGE_COUNTS';

/**
 * Inbox message's reminder statuses
 */
export const REMINDER_STATUSES = {
	Upcoming: 'upcoming',
	Due: 'due',
	NotSet: 'notSet',
} as const;

/**
 * Inbox message's version
 */
export const INBOX_VERSIONS = {
	V3: 'v3',
	V4: 'v4',
} as const;

/**
 * Sort by message fields
 */
export const SORT_BY_FIELDS = {
	REMINDER_TIMESTAMP_ASCENDING: 'ReminderTimestamp',
	LAST_RECEIVED_DESCENDING: '-LastReceivedDate',
	LAST_MESSAGE_DESCENDING: '-LastMessageDate',
} as const;
