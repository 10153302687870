import { RadialBarChart, PolarAngleAxis, RadialBar, ResponsiveContainer } from 'recharts';

type PercentageRingGraphProps = {
	/**
	 * The number out of maxProgress to colour in
	 * If more than maxProgress, ring will be completely filled in (same as if equal to maxProgress)
	 */
	value: number;
	/**
	 * The progress value at which the ring will be fully coloured in
	 * Default is 100
	 */
	maxProgress?: number;

	/**
	 * The width & height of the circle in px
	 * Default is 200px
	 */
	circleSize?: number;

	/**
	 * Width of the percentage ring
	 * Default is 8
	 */
	ringWidth?: number;

	/**
	 * Colour of the percentage ring
	 */
	ringColour: string;

	/**
	 * Size of percentage text
	 * Default is 1.5em
	 */
	textSize?: string;

	/**
	 * Colour of percentage text
	 */
	textColour: string;

	/**
	 * Renderer for text within ring
	 */
	renderText: (value: number) => string;
};

/**
 * [Presentational] Ring chart to display one number (value) as a percentage of a maximum number
 */
export function PercentageRingGraph({
	value,
	maxProgress = 100,
	circleSize = 150,
	ringWidth = 8,
	ringColour,
	textSize = '1.5em',
	textColour,
	renderText,
}: PercentageRingGraphProps) {
	const data = [{ value }];

	return (
		<ResponsiveContainer width="100%" height={circleSize}>
			<RadialBarChart
				width={circleSize}
				height={circleSize}
				innerRadius="65%"
				outerRadius="100%"
				data={data}
				barSize={ringWidth}
				startAngle={90}
				endAngle={-270}
			>
				<PolarAngleAxis type="number" domain={[0, maxProgress]} tick={false} />
				<RadialBar
					background
					isAnimationActive
					dataKey="value"
					cornerRadius="25%"
					fill={ringColour}
				/>
				<text
					x="50%"
					y="50%"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={textSize}
					fontWeight="500"
					fill={textColour}
				>
					{renderText(value)}
				</text>
			</RadialBarChart>
		</ResponsiveContainer>
	);
}
