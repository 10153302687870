import BaseDataManager, { PaginationObject, QueryObject } from './base';

import {
	PaginatedResponse,
	OutboxResponse,
	SentResponse,
	ScheduledResponse,
	LinkedInMessageStatus,
} from '../responses/interface';
import { PaginatedResultModel } from '../responses/models/paginatedResult';
import { OutboxModel } from '../responses/models';
import { SentModel } from '../responses/models/sent';
import { ScheduledModel } from '../responses/models/scheduled';
import {
	convertFiltersToSearchParams,
	convertSorterToSearchParams,
	Query,
} from '../utils/converter';

enum ContactManagerKeyMap {
	status = 'status',
	error = 'error',
}

class OutboxManager extends BaseDataManager {
	constructor() {
		super();
	}

	public get KeyMap() {
		return ContactManagerKeyMap;
	}
	/**
	 * Load pending and failed messages from the database includes pending and errors
	 * @param query
	 */
	getOutboxMessages = (orgMemberId: string | null, query: QueryObject = new QueryObject()) => {
		const url = this.paginate(
			this.combineRoute(this.BACKEND_ROUTES.OUTBOX.Default, 'pending'),
			query
		);
		const params = orgMemberId ? { o: orgMemberId } : {};

		return this.RequestManager.get<PaginatedResponse<OutboxResponse>>(url, {
			params: { ...query.toQueryParam(), ...params },
		})
			.then((response) => new PaginatedResultModel(response.data, OutboxModel))
			.catch(() => new PaginatedResultModel(null, OutboxModel));
	};

	/**
	 * @deprecated Load sent messages from the database
	 * @param query
	 */
	getSentMessagesLegacy = (
		orgMemberId: string | null,
		query: QueryObject = new QueryObject()
	) => {
		const url = this.paginate(
			this.combineRoute(this.BACKEND_ROUTES.OUTBOX.Default, 'sent'),
			query
		);
		const params = orgMemberId ? { o: orgMemberId } : {};

		return this.RequestManager.get<PaginatedResponse<SentResponse>>(url, {
			//TODO update to use orgMemberID
			params: { ...query.toQueryParam(), ...params },
		})
			.then((response) => new PaginatedResultModel(response.data, SentModel))
			.catch(() => new PaginatedResultModel(null, SentModel));
	};

	/**
	 * Load sent messages from the database
	 * @param orgMemberId
	 * @param pagination
	 * @param filters
	 * @param sorter
	 */
	getSentMessages = (query: Query) => {
		const url = this.paginate(
			this.combineRoute(this.BACKEND_ROUTES.OUTBOX.Default, 'sent'),
			(query.pagination?.current ?? 1) - 1,
			query.pagination?.pageSize ?? 10
		);
		const sortBy = query.sorter ? convertSorterToSearchParams(query.sorter) : null;
		const searchParams = query.filters ? convertFiltersToSearchParams(query.filters) : null;
		return this.RequestManager.get<PaginatedResponse<SentResponse>>(url, {
			params: {
				...searchParams,
				sortBy,
			},
		})
			.then((response) => new PaginatedResultModel(response.data, SentModel))
			.catch(() => new PaginatedResultModel(null, SentModel));
	};

	/**
	 * Load customer's sent messages from the database
	 * @param orgMemberId
	 * @param pagination
	 * @param filters
	 * @param sorter
	 */
	getCustomerSentMessages = (query: Query, orgMemberId: string) => {
		const url = this.paginate(
			this.combineRoute(this.BACKEND_ROUTES.OUTBOX.Default, 'sent'),
			(query.pagination?.current ?? 1) - 1,
			query.pagination?.pageSize ?? 10
		);
		const sortBy = query.sorter ? convertSorterToSearchParams(query.sorter) : null;
		const searchParams = query.filters ? convertFiltersToSearchParams(query.filters) : null;
		return this.RequestManager.get<PaginatedResponse<SentResponse>>(url, {
			params: {
				...searchParams,
				sortBy,
				o: orgMemberId,
			},
		})
			.then((response) => new PaginatedResultModel(response.data, SentModel))
			.catch(() => new PaginatedResultModel(null, SentModel));
	};

	/**
	 * Load scheduled messages from the database
	 * @param query
	 */
	getScheduledMessages = (orgMemberId: string | null, query: QueryObject = new QueryObject()) => {
		const url = this.paginate(
			this.combineRoute(this.BACKEND_ROUTES.OUTBOX.Default, 'schedule'),
			query
		);
		const params = orgMemberId ? { o: orgMemberId } : {};

		return this.RequestManager.get<PaginatedResponse<ScheduledResponse>>(url, {
			params: { ...query.toQueryParam(), ...params },
		})
			.then((response) => new PaginatedResultModel(response.data, ScheduledModel))
			.catch(() => new PaginatedResultModel(null, ScheduledModel));
	};

	/**
	 * Get scheduled messages for one specific contact
	 * @param orgMemberId The org member we're querying for
	 * @param contactId The contact the scheduled messages belong to
	 * @param pagination
	 */
	getScheduledMessagesForContact = (
		orgMemberId: string,
		contactId: string,
		pagination: PaginationObject = new PaginationObject()
	) => {
		const query = new QueryObject(pagination);
		query.addFilter('ContactId', '==', contactId);
		return this.getScheduledMessages(orgMemberId, query);
	};

	/**
	 * update status of linkedin message tasks
	 * @param outboxIds
	 * @param status
	 */
	updateOutboxMessageStatus = (
		outboxIds: string[],
		status: LinkedInMessageStatus.Canceled | LinkedInMessageStatus.Pending
	) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.OUTBOX.Default, 'status');

		return this.RequestManager.post(url, { ids: outboxIds, status }).then((response) => {
			console.log(response);
		});
	};
}

const instance = new OutboxManager();
export default instance;
