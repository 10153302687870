import React from 'react';
import { SmileTwoTone } from '@ant-design/icons';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import BasicContainer from '@copilot/common/components/containers/basic';

const StyledList = styled.ol`
	margin-left: 0;
	padding-left: 1em;
	li:before {
		display: inline-block;
		width: 1em;
		margin-left: -1em;
	}
`;

const CampaignOnboardComplete = React.memo(() => (
	<BasicContainer>
		<BasicContainer.Content>
			<Row>
				<Col>
					<SmileTwoTone css=" svg {height: 9em; width: 9em;} " />
				</Col>
				<br />
				<Col span={21}>
					<h2>Great work creating your new campaign! What happens next?</h2>
					<br />
					<StyledList>
						<li>
							You will receive a confirmation email confirming you have booked a
							meeting
						</li>
						<li>
							Your Account Strategist will look at your search to ensure there are no
							issues
						</li>
						<li>
							Your billing will change once Account Strategist kicks off your new
							campaign
						</li>
					</StyledList>
				</Col>
			</Row>
		</BasicContainer.Content>
	</BasicContainer>
));

export default CampaignOnboardComplete;
