import { StatsResponse } from '../interface';

export class StatsModel {
	public types: any[];
	public labels: string[];
	public data: any[][];
	// public times: Date[];
	public rows: any[];
	public totals: number[];

	constructor(stats: PartialBy<StatsModel, 'totals'>) {
		this.types = stats.types || [];
		this.labels = stats.labels || [];
		this.data = stats.data || [];
		this.rows = stats.rows || [];
		this.totals = this.getTotals();
	}

	private getTotals = () => {
		const totals: number[] = [];
		this.data.forEach((d) => {
			d.forEach((c, idx) => {
				if (totals.length <= idx) totals.push(0);
				totals[idx] += c;
			});
		});
		return totals;
	};

	static fromResponse = (stats: StatsResponse, includeTagStats = true) => {
		const statsMap: { [k: string]: boolean } = {};
		stats.forEach((stat) => {
			Object.keys(stat.actionStats).forEach((actionStatKey) => {
				statsMap[actionStatKey] = true;
			});
			if (includeTagStats)
				Object.keys(stat.tagStats).forEach((actionStatKey) => {
					statsMap[actionStatKey] = true;
				});
		});

		const labels = Object.keys(statsMap);
		const data = stats.map((s) => labels.map((l) => s.actionStats[l] || s.tagStats[l] || 0));
		const rows = stats.map((s) => new Date(s.timestamp.replace('Z', '')));
		return new StatsModel({
			types: labels,
			labels,
			data,
			rows,
		});
	};
}
