import React, { useState } from 'react';
import UserInfo, { UserInfoWrapperValues } from './userInfo';
import { AdminManager } from '@copilot/data/managers/admin';
import { ResponseType } from '../constant';
import Store from '@copilot/common/store';
import { ClientActions } from '@copilot/common/store/actions';

import type { AdminClientCreateModel } from '@copilot/data/responses/interface';
import type { IClient } from '@copilot/common/store/models/redux';

interface UserInfoWrapperProps {
	onNext: (responseType?: ResponseType) => void;
}

const UserInfoWrapper: React.FC<UserInfoWrapperProps> = (props) => {
	const [err, setError] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const onNext = (values: UserInfoWrapperValues) => {
		const model = {
			FirstName: values.customerFirstName,
			LastName: values.customerLastName,
			StripeCustomerId: values.customerStripeId,
			Email: values.customerEmail,
			Password: values.newPassword,
			IsIndividualUser: true,
			vpnPort: values.proxy,
		} as unknown as AdminClientCreateModel;
		setSubmitting(true);
		AdminManager.createCustomer(model)
			.then((customer: IClient) => {
				Store.Dispatch(ClientActions.load(customer));
				props.onNext(ResponseType.Success);
			})
			.catch(() => {
				setError(true);
				setSubmitting(false);
				// props.onNext(ResponseType.Fail);
			});
	};

	return <UserInfo onNext={onNext} err={err} submitting={submitting} />;
};

export default UserInfoWrapper;
