import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { AppActions } from '@copilot/common/store/actions/app';
import { useFetch } from '@copilot/common/hooks/common';
import { ConstantManager } from '@copilot/data';
import { ProxyActions } from '@copilot/common/store/actions/proxy';
import { AdminManager } from '@copilot/data/managers/admin';
import { CSMemberActions } from '@copilot/common/store/actions/csMember';
import { usePermission } from '@copilot/common/hooks/permission';
import { InstanceDataActions } from '@copilot/common/store/actions/instance';

interface AdminDataInitializerProps {}

const AdminDataInitializer: React.FC<AdminDataInitializerProps> = (props) => {
	const storeDispatch = useDispatch();
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const adminMember = useSelector(OrganizationMemberSelectors.getAdminMember);
	const [, , loadProfile] = usePermission();
	const [, getProxyMapping] = useFetch(
		ConstantManager.loadProxies,
		ProxyActions.loadProxies,
		(proxies) =>
			Object.keys(proxies).map((proxy) => {
				const porxyPort = parseInt(proxy);
				return {
					id: porxyPort,
					name: proxies[proxy],
					value: porxyPort,
				};
			})
	);
	const [, getInstanceData] = useFetch(
		AdminManager.getAllInstanceData,
		InstanceDataActions.loadInstances
	);
	const [, getCSMembers] = useFetch(AdminManager.getCSMembers, CSMemberActions.load, (members) =>
		members.map((m) => ({
			id: m.userId,
			firstName: m.firstName,
			lastName: m.lastName,
			email: m.email,
		}))
	);

	React.useEffect(() => {
		if (!activeMember?.id) return;
		// Assign admin object to store if it is sysadmin
		if (activeMember.isSysAdmin && !adminMember) {
			storeDispatch(AppActions.loadAdminUser(activeMember.id));
			//load CS User permissions
			loadProfile('CSUser');
		}
		getProxyMapping();
		getInstanceData();
		getCSMembers();
	}, [activeMember?.id]);

	return <>{props.children}</>;
};

export default AdminDataInitializer;
