import InboxMessageSelectors from './selector';
import { OrmState } from 'redux-orm';
import {
	dismissInboxMessagesSuccess,
	InboxMessagesApplyTagsAction,
	InboxMessagesApplyTagsActionType,
	InboxMessagesDismissAction,
	InboxMessagesDismissActionType,
	InboxMessagesLoadUnreadCountActionType,
	InboxMessagesMarkAsReadAction,
	InboxMessagesMarkAsReadActionType,
	InboxMessagesMigrateConnectionAction,
	InboxMessagesMigrateConnectionActionType,
	InboxMessagesMigrateConnectionTagsAction,
	InboxMessagesMigrateConnectionTagsActionType,
	InboxMessagesResetFilterActionType,
	InboxMessagesSendAutomatedMessageAction,
	InboxMessagesSendAutomatedMessageActionType,
	InboxMessagesSendManualMessageAction,
	InboxMessagesSendManualMessageActionType,
	InboxMessagesSnoozeAction,
	InboxMessagesSnoozeActionType,
	InboxMessagesUnsnoozeActionType,
	InboxMessagesUpdateFilterAction,
	InboxMessagesUpdateFilterActionType,
	InboxMessagesUpdatePaginationAction,
	InboxMessagesUpdatePaginationActionType,
	InboxMessagesUpdateSearchTermActionType,
	InboxMessagesUpdateSearchTermAction,
	InboxMessagesUpdateViewAction,
	InboxMessagesUpdateViewActionType,
	InboxPageLoadAction,
	InboxPageLoadActionType,
	loadInboxFailed,
	loadInboxMessages,
	loadInboxMessagesFailed,
	loadInboxMessagesSuccess,
	loadInboxSuccess,
	loadUnreadMessagesCount,
	markInboxMessagesAsReadSuccess,
	migrateConnectionSuccess,
	resetInboxMessagesFilter,
	resetInboxMessagesFilterInStore,
	sendMessageFailed,
	sendMessageSuccess,
	updateDisplayTypeSuccess,
	updateInboxMessagesPagination,
	updateInboxMessagesSuccess,
	InboxMessagesCompleteReminderActionType,
	InboxMessagesCompleteReminderAction,
	loadInboxCountsSuccess,
	InboxMessagesResetFilterInStoreActionType,
	updateInboxMessagesFilter,
	updateInboxMessagesVersion,
	InboxMessagesActionsCountActionType,
	InboxMessagesMemberActionsCountActionType,
	loadMemberActionsMessageCount,
	loadActionsMessageCount,
} from './actionCreators';
import {
	CampaignManager,
	ContactManager,
	InboxManager,
	OrganizationManager,
	OrganizationMemberManager,
	TagManager,
} from '@copilot/data';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import notificationManager from '@copilot/common/utils/notificationManager';
import { NotificationContent } from '@copilot/common/constant/notificationContent';
import { AppActions } from '@copilot/common/store/actions/app';
import { InboxModelState } from './reducers';
import { LoadMorePaginationObject } from '@copilot/data/managers/base';
import {
	CampaignStatusEnum,
	InboxDisplayType,
	InboxFilterRequestModel,
} from '@copilot/data/requests/models';
import createSagaMiddleware from 'redux-saga';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import {
	CampaignResponse,
	ConnectionMigrationResponse,
	LoadMorePaginatedResponse,
	OrganizationMemberResponse,
	PaginatedResponse,
} from '@copilot/data/responses/interface';
import { RootState } from '@copilot/common/store/reducers';
import { SentimentNameMap } from '@copilot/common/constant/enum';
import { InboxMessageFilters } from '../ui/types';
import type { InboxModel } from '@copilot/data/responses/models/inbox';
// We need to import Store to retrieve items from redux orm
// eslint-disable-next-line import/no-cycle
import Store from '@copilot/common/store';
import { NotificationType } from '@copilot/common/store/models/const/enum';
import { INotification } from '@copilot/common/store/models/redux';
import { NotificationActions } from '@copilot/common/store/actions/notification';
import {
	getCampaignTagToConnectionIdsMap,
	updateConnectionTagsForMigration,
} from '@copilot/common/utils/batchActions';
import { getOrganizationMemberName } from '@copilot/common/utils/organizationMember';
import isNil from 'lodash/isNil';
import { INBOX_VERSIONS } from '@copilot/common/pages/inbox/data/constant';
import { InboxVersion } from '@copilot/common/pages/inbox/data/types';
import { convertFilterToRequestModel, getSortByField } from './helper';

const messageLoaders: Record<
	InboxVersion,
	{
		getMessages: typeof InboxManager.getMessagesWithQuery;
		getCounts: typeof InboxManager.getMessageCountByCategory;
	}
> = {
	[INBOX_VERSIONS.V3]: {
		getCounts: InboxManager.getMessageCountByCategory,
		getMessages: InboxManager.getMessagesWithQuery,
	},
	[INBOX_VERSIONS.V4]: {
		getCounts: (
			orgId: string | undefined,
			viewType: InboxDisplayType,
			filters: Partial<InboxFilterRequestModel>
		) => InboxManager.getMessageCounts(filters),
		getMessages: InboxManager.getMessagesWithQueryV4,
	},
};

//#region Update view type

function* updateViewTypeAsync(action: InboxMessagesUpdateViewAction) {
	try {
		yield put(updateDisplayTypeSuccess());
		const orgMemberId: string = yield select((state: RootState) => state.app.user);
		// TODO: COPILOT-3059 update to use isAdmin from the redux store
		const inboxType: InboxDisplayType = action.isAdmin
			? action.viewType // don't update the view setting when CS changes the view type
			: yield call(OrganizationMemberManager.updateInboxView, orgMemberId, action.viewType);
		yield put(updateDisplayTypeSuccess(inboxType));

		if (!isNil(action.filters)) {
			yield put(updateInboxMessagesFilter(action.filters));
		} else {
			yield put(resetInboxMessagesFilter());
		}
	} catch {
		yield put(loadInboxFailed());
	}
}

function* watchViewTypeUpdate() {
	yield takeLatest(InboxMessagesUpdateViewActionType, updateViewTypeAsync);
}

//#endregion

//#region filter

function* loadAsync(action: InboxPageLoadAction) {
	yield put(updateInboxMessagesVersion(action.version));
	try {
		const { results: campaigns }: PaginatedResponse<CampaignResponse> = yield call(
			CampaignManager.getCampaignsByOrganization,
			action.organizationId,
			0,
			1000
		);
		const teamMembers: OrganizationMemberResponse[] = yield call(
			OrganizationManager.getMembers,
			action.organizationId
		);
		const tags: { id: string; name: string }[] = yield call(
			TagManager.getTags,
			action.organizationId
		);
		const { feSettings }: PromiseFactoryThenType<typeof OrganizationMemberManager.getMember> =
			yield call(OrganizationMemberManager.getMember);
		const userSettingsViewType = feSettings?.inboxType ?? InboxDisplayType.Email;
		const inboxType = isNil(action.defaultViewType)
			? userSettingsViewType
			: action.defaultViewType;
		const filters = {
			showSnoozedOnly: false,
			newMessagesOnly: false,
			showReminderOnly: false,
			tags: tags.map((tag) => new FilterDefinition({ key: tag.id, label: tag.name })),
			campaigns: campaigns
				.sort((a, b) => a.name.localeCompare(b.name))
				.map(
					(campaign) =>
						new FilterDefinition({
							key: campaign.id,
							label: campaign.name,
							type: campaign.type,
							properties: [campaign.status === CampaignStatusEnum.Enabled],
						})
				),
			teamMembers: teamMembers
				.sort((a, b) =>
					getOrganizationMemberName(a).localeCompare(getOrganizationMemberName(b))
				)
				.map(
					(teamMember) =>
						new FilterDefinition({
							key: teamMember.id,
							label: getOrganizationMemberName(teamMember),
							isVisible: teamMember.id === action.defaultTeamMemberId,
						})
				),
			sentiment: Object.keys(SentimentNameMap).map(
				(sentiment) =>
					new FilterDefinition({
						key: sentiment,
						label: SentimentNameMap[sentiment as keyof typeof SentimentNameMap],
					})
			),
			reminderStatuses: [],
			lastMessageTypes: [],
		};

		yield put(
			loadInboxSuccess<InboxMessageFilters>(
				isNil(action.defaultFilters) ? filters : { ...filters, ...action.defaultFilters },
				inboxType,
				// TODO: COPILOT-3059 update to use isAdmin from the redux store
				action.isAdmin ? action.organizationId : undefined
			)
		);
		yield put(updateInboxMessagesPagination());
	} catch {
		yield put(loadInboxFailed());
	}
}

function* watchLoad() {
	yield takeLatest(InboxPageLoadActionType, loadAsync);
}

function* updateFilterAsync(action: InboxMessagesUpdateFilterAction) {
	try {
		yield put(loadInboxMessages());
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		const { getMessages } = messageLoaders[currentState.version];
		if (currentState.viewType != undefined) {
			const pagination = new LoadMorePaginationObject({
				offset: currentState.offset,
				pageSize: currentState.pageSize,
			});
			const filter = convertFilterToRequestModel(
				{ ...currentState.filter, ...action.filter },
				currentState.viewType
			);
			const sortBy = getSortByField(currentState.version, filter.ReminderStatus);
			const results: LoadMorePaginatedResponse<InboxModel> = yield call(
				getMessages,
				currentState.viewType,
				filter,
				pagination,
				currentState.searchTerm,
				currentState.csOrgId,
				sortBy
			);
			yield put(loadInboxMessagesSuccess(results));
		} else {
			yield put(loadInboxMessagesFailed());
		}
	} catch (e) {
		yield put(loadInboxMessagesFailed());
	}
}

function* watchFilterUpdate() {
	yield takeLatest(InboxMessagesUpdateFilterActionType, updateFilterAsync);
}

function* watchFilterCountUpdate() {
	yield takeLatest(InboxMessagesUpdateFilterActionType, updateCountsAsync);
}

function* updateSearchTermAsync(action: InboxMessagesUpdateSearchTermAction) {
	try {
		yield put(loadInboxMessages());
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		const { getMessages } = messageLoaders[currentState.version];
		if (currentState.viewType != undefined) {
			const pagination = new LoadMorePaginationObject({
				offset: currentState.offset,
				pageSize: currentState.pageSize,
			});
			const filter = convertFilterToRequestModel(currentState.filter, currentState.viewType);
			const sortBy = getSortByField(currentState.version, filter.ReminderStatus);
			const results: LoadMorePaginatedResponse<InboxModel> = yield call(
				getMessages,
				currentState.viewType,
				filter,
				pagination,
				action.searchTerm,
				currentState.csOrgId,
				sortBy
			);

			yield put(loadInboxMessagesSuccess(results));
		} else {
			yield put(loadInboxMessagesFailed());
		}
	} catch {
		yield put(loadInboxMessagesFailed());
	}
}

function* watchSearchTermUpdate() {
	yield takeLatest(InboxMessagesUpdateSearchTermActionType, updateSearchTermAsync);
}
function* watchSearchTermUpdateCount() {
	yield takeLatest(InboxMessagesUpdateSearchTermActionType, updateCountsAsync);
}

function* resetFilterAsync() {
	try {
		yield put(loadInboxMessages());
		yield put(resetInboxMessagesFilterInStore());
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		const { getMessages } = messageLoaders[currentState.version];
		if (currentState.viewType != undefined) {
			const pagination = new LoadMorePaginationObject({
				offset: currentState.offset,
				pageSize: currentState.pageSize,
			});
			const filter = convertFilterToRequestModel(currentState.filter, currentState.viewType);
			const sortBy = getSortByField(currentState.version, filter.ReminderStatus);
			const results: LoadMorePaginatedResponse<InboxModel> = yield call(
				getMessages,
				currentState.viewType,
				filter,
				pagination,
				currentState.searchTerm,
				currentState.csOrgId,
				sortBy
			);

			yield put(loadInboxMessagesSuccess(results));
		} else {
			yield put(loadInboxMessagesFailed());
		}
	} catch {
		yield put(loadInboxMessagesFailed());
	}
}

function* watchFilterReset() {
	yield takeLatest(InboxMessagesResetFilterActionType, resetFilterAsync);
}

function* watchFilterResetCountUpdate() {
	yield takeLatest(InboxMessagesResetFilterInStoreActionType, updateCountsAsync);
}

//#endregion

//#region pagination
function* updatePaginationAsync(action: InboxMessagesUpdatePaginationAction) {
	try {
		yield put(loadInboxMessages());
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		const { getMessages } = messageLoaders[currentState.version];
		if (currentState.viewType != undefined) {
			const pagination = new LoadMorePaginationObject({
				offset: action.offset,
				pageSize: action.pageSize,
			});
			const filter = convertFilterToRequestModel(currentState.filter, currentState.viewType);
			const sortBy = getSortByField(currentState.version, filter.ReminderStatus);
			const results: LoadMorePaginatedResponse<InboxModel> = yield call(
				getMessages,
				currentState.viewType,
				filter,
				pagination,
				currentState.searchTerm,
				currentState.csOrgId,
				sortBy
			);

			yield put(loadInboxMessagesSuccess(results));
		} else {
			yield put(loadInboxMessagesFailed());
		}
	} catch {
		yield put(loadInboxMessagesFailed());
	}
}

function* updateCountsAsync() {
	try {
		yield put(loadInboxMessages());
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		const { getCounts } = messageLoaders[currentState.version];
		if (currentState.viewType != undefined) {
			const filter = convertFilterToRequestModel(currentState.filter, currentState.viewType);
			const results: Record<string, number> = yield call(
				getCounts,
				currentState.csOrgId,
				currentState.viewType,
				filter
			);

			yield put(loadInboxCountsSuccess(results));
		}
	} catch (e) {
		yield put(loadInboxMessagesFailed());
	}
}

function* watchPaginationUpdate() {
	yield takeLatest(InboxMessagesUpdatePaginationActionType, updatePaginationAsync);
}

function* watchPaginationCountUpdate() {
	yield takeLatest(InboxMessagesUpdatePaginationActionType, updateCountsAsync);
}

//#endregion

//#region Dismiss
function* dismissAsync(action: InboxMessagesDismissAction) {
	try {
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		const { getCounts } = messageLoaders[currentState.version];
		if (currentState.viewType != undefined) {
			const filter = convertFilterToRequestModel(currentState.filter, currentState.viewType);
			const [, messageCounts]: [boolean, Record<string, number>] = yield all([
				call(InboxManager.archiveMessage, action.threadIds),
				call(getCounts, currentState.csOrgId, currentState.viewType, filter),
			]);
			yield call(
				notificationManager.showSuccessNotification,
				NotificationContent.DismissMessage.Success
			);
			yield put(dismissInboxMessagesSuccess(action.threadIds, messageCounts));
		}
	} catch {
		yield call(
			notificationManager.showErrorNotification,
			NotificationContent.DismissMessage.Fail
		);
	}
}

function* watchDismiss() {
	yield takeEvery(InboxMessagesDismissActionType, dismissAsync);
}

//#endregion

//#region Mark As Read
function* markAsReadAsync(action: InboxMessagesMarkAsReadAction) {
	try {
		yield call(InboxManager.setRead, action.threadIds, action.isRead);
		yield put(markInboxMessagesAsReadSuccess(action.threadIds, action.isRead));
		yield put(loadUnreadMessagesCount());
		yield put(loadActionsMessageCount());
		yield put(loadMemberActionsMessageCount());
		if (action.threadIds.length > 1) {
			// Show notification for batch actions
			yield call(
				notificationManager.showSuccessNotification,
				NotificationContent.MarkAsRead.Success
			);
		}
	} catch {
		if (action.threadIds.length > 1) {
			yield call(
				notificationManager.showErrorNotification,
				NotificationContent.MarkAsRead.Fail
			);
		}
	}
}

function* watchMarkAsRead() {
	yield takeEvery(InboxMessagesMarkAsReadActionType, markAsReadAsync);
}

function* loadUnreadMessagesCountAsync() {
	const unreadCount: number = yield call(InboxManager.getUnreadCount);
	const notificationObject: INotification = {
		id: NotificationType.Inbox,
		title: NotificationType.Inbox,
		body: `${unreadCount}`,
		actions: [],
	};
	yield put(NotificationActions.loadNotifications([notificationObject]));
}

function* watchLoadUnreadMessagesCount() {
	yield takeEvery(InboxMessagesLoadUnreadCountActionType, loadUnreadMessagesCountAsync);
}

const ActionsMessageCountKey = 'actions';

function* loadActionsMessageCountAsync() {
	const messageCounts: { [k: string]: number } = yield call(InboxManager.getMessageCounts, {});
	const notificationObject: INotification = {
		id: NotificationType.Actions,
		title: NotificationType.Actions,
		body: `${messageCounts[ActionsMessageCountKey]}`,
		actions: [],
	};
	yield put(NotificationActions.loadNotifications([notificationObject]));
}

function* watchActionsMessageCountAsync() {
	yield takeEvery(InboxMessagesActionsCountActionType, loadActionsMessageCountAsync);
}

function* loadMemberActionsMessageCountAsync() {
	const orgMemberId: string = yield select((state: RootState) => state.app.user);
	const messageCounts: { [k: string]: number } = yield call(InboxManager.getMessageCounts, {
		OrgMemberId: { include: [orgMemberId], exclude: [] },
	});
	const notificationObject: INotification = {
		id: NotificationType.MemberActions,
		title: NotificationType.MemberActions,
		body: `${messageCounts[ActionsMessageCountKey]}`,
		actions: [],
	};
	yield put(NotificationActions.loadNotifications([notificationObject]));
}

function* watchOrgMemberActionsMessageCountAsync() {
	yield takeEvery(InboxMessagesMemberActionsCountActionType, loadMemberActionsMessageCountAsync);
}
//#endregion

//#region Apply Tags
function* applyTagsAsync(action: InboxMessagesApplyTagsAction) {
	try {
		//TODO add reducer to update tags in store. Currently the tag won't show up in page after adding it.
		yield call(
			ContactManager.updateConnectionTags,
			action.connectionIds,
			action.tags,
			action.csOrgId,
			action.overrideTags,
			action.filterRequest
		);
		yield call(notificationManager.showSuccessNotification, { message: 'Tags applied' });
		yield put(AppActions.closeDrawer());
	} catch {
		yield call(notificationManager.showErrorNotification, {
			message: 'Something went wrong when trying to apply tags. Please try again.',
		});
	} finally {
		action.onFinally?.();
	}
}

function* watchApplyTags() {
	yield takeEvery(InboxMessagesApplyTagsActionType, applyTagsAsync);
}

//#endregion

//#region Snooze
function* snoozeAsync(action: InboxMessagesSnoozeAction) {
	try {
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		const { getCounts } = messageLoaders[currentState.version];
		if (currentState.viewType === InboxDisplayType.Cards) {
			const filter = convertFilterToRequestModel(currentState.filter, currentState.viewType);
			const [, messageCounts]: [boolean, Record<string, number>] = yield all([
				call(InboxManager.snoozeThread, action.threadId, action.dateSnoozeUntil),
				call(getCounts, currentState.csOrgId, currentState.viewType, filter),
			]);
			yield put(
				updateInboxMessagesSuccess(action.threadId, { isSnoozed: true }, messageCounts)
			);
		} else {
			yield call(InboxManager.snoozeThread, action.threadId, action.dateSnoozeUntil);
			yield put(updateInboxMessagesSuccess(action.threadId, { isSnoozed: true }));
		}
		yield call(
			notificationManager.showSuccessNotification,
			NotificationContent.SnoozeThread.Success
		);
	} catch {
		yield call(
			notificationManager.showErrorNotification,
			NotificationContent.SnoozeThread.Fail
		);
	}
}

function* watchSnooze() {
	yield takeEvery(InboxMessagesSnoozeActionType, snoozeAsync);
}
//#endregion

//#region Complete Reminder
function* completeReminderAsync(action: InboxMessagesCompleteReminderAction) {
	try {
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		const { getCounts } = messageLoaders[currentState.version];
		if (currentState.viewType === InboxDisplayType.Cards) {
			const filter = convertFilterToRequestModel(currentState.filter, currentState.viewType);
			const [, messageCounts]: [boolean, Record<string, number>] = yield all([
				call(InboxManager.completeRemindedThread, action.threadId),
				call(getCounts, currentState.csOrgId, currentState.viewType, filter),
			]);
			yield put(
				updateInboxMessagesSuccess(
					action.threadId,
					{ isSnoozed: undefined, reminder: undefined },
					messageCounts
				)
			);
		}
		yield call(
			notificationManager.showSuccessNotification,
			NotificationContent.CompleteRemindedThread.Success
		);
	} catch {
		yield call(
			notificationManager.showErrorNotification,
			NotificationContent.CompleteRemindedThread.Fail
		);
	}
}

function* watchCompleteReminder() {
	yield takeEvery(InboxMessagesCompleteReminderActionType, completeReminderAsync);
}
//#endregion

//#region Unsnooze
function* unsnoozeAsync(action: InboxMessagesSnoozeAction) {
	try {
		yield call(InboxManager.snoozeThread, action.threadId);
		yield put(updateInboxMessagesSuccess(action.threadId, { isSnoozed: undefined }));
		yield call(
			notificationManager.showSuccessNotification,
			NotificationContent.UnsnoozeThread.Success
		);
	} catch {
		yield call(
			notificationManager.showErrorNotification,
			NotificationContent.UnsnoozeThread.Fail
		);
	}
}

function* watchUnsnooze() {
	yield takeEvery(InboxMessagesUnsnoozeActionType, unsnoozeAsync);
}
//#endregion

//#region Send Message
function* sendAutomatedMessageAsync(action: InboxMessagesSendAutomatedMessageAction) {
	try {
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		const { getCounts } = messageLoaders[currentState.version];
		const thread = currentState.data?.find((item) => item.threadId == action.threadId);
		if (thread && currentState.viewType != undefined) {
			const filter = convertFilterToRequestModel(currentState.filter, currentState.viewType);
			const [, messageCounts]: [void, Record<string, number>] = yield all([
				call(
					InboxManager.sendAutomationReply,
					thread.campaignMemberId,
					thread.contactId,
					action.message,
					action.nodeId
				),
				call(getCounts, currentState.csOrgId, currentState.viewType, filter),
			]);
			yield put(sendMessageSuccess(action.threadId, messageCounts));
			yield call(
				notificationManager.showSuccessNotification,
				NotificationContent.SendMessage.Success
			);
		} else {
			yield put(sendMessageFailed(action.threadId));
			yield call(
				notificationManager.showErrorNotification,
				NotificationContent.SendMessage.Fail
			);
		}
	} catch {
		yield put(sendMessageFailed(action.threadId));
		yield call(notificationManager.showErrorNotification, NotificationContent.SendMessage.Fail);
	}
}

function* watchSendAutomatedMessage() {
	yield takeEvery(InboxMessagesSendAutomatedMessageActionType, sendAutomatedMessageAsync);
}

function* sendManualMessageAsync(action: InboxMessagesSendManualMessageAction) {
	try {
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		const { getCounts } = messageLoaders[currentState.version];
		const thread = currentState.data?.find((item) => item.threadId == action.threadId);
		if (thread && currentState.viewType != undefined) {
			const filter = convertFilterToRequestModel(currentState.filter, currentState.viewType);
			const [, messageCounts]: [void, Record<string, number>] = yield all([
				call(
					InboxManager.sendManualReply,
					thread.linkedInThreadId,
					action.message,
					thread.orgMemberId,
					action.removeReminder,
					action.templateId,
					action.campaignId
				),
				call(getCounts, currentState.csOrgId, currentState.viewType, filter),
			]);
			yield put(sendMessageSuccess(action.threadId, messageCounts));
			yield call(
				notificationManager.showSuccessNotification,
				NotificationContent.SendMessage.Success
			);
		} else {
			yield put(sendMessageFailed(action.threadId));
			yield call(
				notificationManager.showErrorNotification,
				NotificationContent.SendMessage.Fail
			);
		}
	} catch {
		yield put(sendMessageFailed(action.threadId));
		yield call(notificationManager.showErrorNotification, NotificationContent.SendMessage.Fail);
	}
}

function* watchSendManualMessage() {
	yield takeEvery(InboxMessagesSendManualMessageActionType, sendManualMessageAsync);
}
//#endregion

//#region Migrate Connection
function* migrateConnectionAsync(action: InboxMessagesMigrateConnectionAction) {
	try {
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		const connectionsToMigrate = new Set(action.connectionIds);
		const threadsToMigrate =
			currentState.data?.filter((thread) => connectionsToMigrate.has(thread.connectionId)) ??
			[];
		if (threadsToMigrate.length === 0) {
			throw new Error('No threads found with matching connection ids');
		}
		const { orgMemberId } = threadsToMigrate[0];
		const { id: targetCampaignMemberId }: { id: string } = yield select(
			(state: { entities: OrmState<any> }) =>
				Store.ORM.session(state.entities)
					.Campaign.withId(action.targetCampaignId)
					?.members.filter({ orgMemberId })
					.first()
		);
		const migrationResponse: ConnectionMigrationResponse = yield call(
			CampaignManager.migrateConnections,
			action.targetCampaignId,
			targetCampaignMemberId,
			action.connectionIds,
			undefined,
			true
		);
		yield put(migrateConnectionSuccess(migrationResponse));
		action.callbacks.onSuccess?.(migrationResponse);
		yield call(
			notificationManager.showSuccessNotification,
			NotificationContent.MigrateConnection.Success
		);
	} catch {
		action.callbacks.onError?.();
		yield call(
			notificationManager.showErrorNotification,
			NotificationContent.MigrateConnection.Fail
		);
	}
}

function* watchMigrateConnection() {
	yield takeEvery(InboxMessagesMigrateConnectionActionType, migrateConnectionAsync);
}

function* migrateConnectionTagsAsync(action: InboxMessagesMigrateConnectionTagsAction) {
	try {
		const currentState: InboxModelState<InboxMessageFilters> = yield select(
			InboxMessageSelectors
		);
		//Assumption is that tag migration should only happen after a successful connection migration
		const prevMigratedThreads = currentState.prevMigratedThreads ?? [];
		const campaignToConnectionsMap = action.createCampaignTag
			? getCampaignTagToConnectionIdsMap(prevMigratedThreads)
			: null;
		yield call(
			updateConnectionTagsForMigration,
			action.connectionIds,
			action.selectedTags,
			campaignToConnectionsMap,
			action.csOrgId
		);
		action.callbacks.onSuccess?.();
	} catch {
		action.callbacks.onError?.();
	}
}

function* watchMigrateConnectionTags() {
	yield takeEvery(InboxMessagesMigrateConnectionTagsActionType, migrateConnectionTagsAsync);
}

//#endregion

/**
 * Apply Inbox sagas to the saga middleware
 * @param sagaMiddleware
 */
export const registerInboxSaga = (
	sagaMiddleware: ReturnType<typeof createSagaMiddleware>
): void => {
	sagaMiddleware.run(watchApplyTags);
	sagaMiddleware.run(watchDismiss);
	sagaMiddleware.run(watchFilterReset);
	sagaMiddleware.run(watchFilterResetCountUpdate);
	sagaMiddleware.run(watchFilterUpdate);
	sagaMiddleware.run(watchFilterCountUpdate);
	sagaMiddleware.run(watchMarkAsRead);
	sagaMiddleware.run(watchLoadUnreadMessagesCount);
	sagaMiddleware.run(watchPaginationUpdate);
	sagaMiddleware.run(watchPaginationCountUpdate);
	sagaMiddleware.run(watchSnooze);
	sagaMiddleware.run(watchCompleteReminder);
	sagaMiddleware.run(watchUnsnooze);
	sagaMiddleware.run(watchLoad);
	sagaMiddleware.run(watchViewTypeUpdate);
	sagaMiddleware.run(watchSendAutomatedMessage);
	sagaMiddleware.run(watchSendManualMessage);
	sagaMiddleware.run(watchMigrateConnection);
	sagaMiddleware.run(watchMigrateConnectionTags);
	sagaMiddleware.run(watchSearchTermUpdate);
	sagaMiddleware.run(watchSearchTermUpdateCount);
	sagaMiddleware.run(watchActionsMessageCountAsync);
	sagaMiddleware.run(watchOrgMemberActionsMessageCountAsync);
};
