import { Collapse, Typography } from 'antd';
import { SearchUrlType } from '@copilot/common/utils/constant';
import isNil from 'lodash/isNil';
import styles from './searchUrlSection.module.less';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';

const { Text, Link, Paragraph } = Typography;

type SearchUrlStatusModuleProps = {
	searchType: SearchUrlType; // are we in an prospecting search or a nurture search?
	isInitialSearch: boolean; // is this the first search
	numPeople: number; //number of people in searchlist
	updateDate?: string; //date the serachlist was last updated
	url?: string; //searchlist url (eg the url of the searchlist in sales navigator)
};

/**
 * Component that displays the search url/list information
 */
export default function SearchUrlStatusSection({
	searchType,
	isInitialSearch,
	numPeople,
	updateDate,
	url,
}: SearchUrlStatusModuleProps) {
	const isStopAutomationRefactorEnabled = useFeatureToggle(
		Features.StopAutomationRefactorFeature
	);
	return (
		<div className={styles.searchUrlStatusSectionWrapper}>
			{isInitialSearch ? (
				<Paragraph>
					{searchType === SearchUrlType.Search
						? "There's no current prospects in your list."
						: 'Import 1st degree connections directly from LinkedIn into this Nurture List'}
				</Paragraph>
			) : (
				<div className={styles.searchUrlWrapper}>
					<div>
						{searchType === SearchUrlType.Search
							? 'Current search URL'
							: 'Below is the last Sales Navigator URL you used to add connections to this Nurture List.'}
						{!isNil(updateDate) &&
							` (Last updated ${new Date(updateDate).toLocaleDateString()}):`}
						{url && (
							<div className={styles.searchUrl}>
								<Link underline target="_blank" href={url}>
									{url}
								</Link>
							</div>
						)}
					</div>
					{searchType === SearchUrlType.Search && (
						<>
							<div>
								We've collected <Text strong>{numPeople} prospects</Text> from this
								URL.
							</div>
							<Collapse
								size="small"
								className={styles.searchUrlCollapse}
								items={[
									{
										key: '1',
										label: 'Why is my search list number different than Sales Navigator?',
										children: (
											<div className={styles.searchUrlHelperText}>
												Sales navigator and Search list may have a different
												number for these reasons:
												<ol>
													<li>
														<b>Display Cap:</b> This page displays a
														maximum of 2,500 prospects at a time.
													</li>
													<li>
														<b>No Re-invites:</b> We auto-exclude
														prospects previously invited to avoid
														LinkedIn restrictions. To check who you’ve
														sent an invite to, visit{' '}
														<Link
															href="https://www.linkedin.com/mynetwork/invitation-manager/sent/"
															target="_blank"
															rel="no-referrer"
														>
															this page on LinkedIn.
														</Link>
													</li>
													<li>
														<b>Duplicate Control:</b> If you or your
														team member have “Dupe prospect” turned on,
														it prevents recollecting the same prospect
														within a time period across campaigns.
													</li>
													{isStopAutomationRefactorEnabled && (
														<li>
															<b>Stop Automation:</b> If “Stop
															Automation” is turned on, it prevents
															recollecting the same prospect within a
															campaign.
														</li>
													)}
												</ol>
											</div>
										),
									},
								]}
							/>
						</>
					)}
				</div>
			)}
		</div>
	);
}
