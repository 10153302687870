import { useFetchV2 } from '@copilot/common/hooks/common';
import { LinkedInManager } from '@copilot/data';
import { InMailSequenceMessage } from '@copilot/data/requests/models';
import { useState } from 'react';

/**
 * Hook to get an inmail sequence for campaigns
 * @returns
 */
export function useInMailSequence(): InMailSequenceHookData {
	const [aggregatedData, setAggregatedData] = useState<InMailSequenceMessage[]>();
	const [fetchState, fetchSequence] = useFetchV2(LinkedInManager.getInMailSequence, (result) => {
		setAggregatedData(result);
		return result;
	});
	const [updateState, updateSequence] = useFetchV2(
		LinkedInManager.setInMailSequence,
		(result, [_, sequence]) => {
			setAggregatedData(sequence);
			return result;
		}
	);

	return {
		data: aggregatedData,
		fetchState,
		updateState,
		fetchSequence,
		updateSequence,
	};
}

export type InMailSequenceHookData = {
	data?: InMailSequenceMessage[];
	fetchState: ReturnType<typeof useFetchV2>[0];
	updateState: ReturnType<typeof useFetchV2>[0];
	fetchSequence: ReturnType<typeof useFetchV2>[1];
	updateSequence: ReturnType<typeof useFetchV2>[1];
};
