import { isPlainJsonObject } from '../../../utils/index';
import { isString } from 'lodash';

/**
 * Names for the fields collected in the Account Creation form
 */
export const ACCOUNT_CREATION_INPUT_FIELDS = {
	accountName: 'accountName',
	firstName: 'firstName',
	lastName: 'lastName',
	email: 'email',
	permission: 'permission',
	accountManagerId: 'accountMangerId',
} as const;

/**
 * Permission levels permissible for an Account's main contact
 */
export const Permissions = {
	Admin: 'Admin',
} as const;

/**
 * Input required to add a new account
 */
export type AccountCreationInput = Readonly<{
	[ACCOUNT_CREATION_INPUT_FIELDS.accountName]: string;
	[ACCOUNT_CREATION_INPUT_FIELDS.firstName]: string;
	[ACCOUNT_CREATION_INPUT_FIELDS.lastName]: string;
	[ACCOUNT_CREATION_INPUT_FIELDS.email]: string;
	[ACCOUNT_CREATION_INPUT_FIELDS.permission]: typeof Permissions[keyof typeof Permissions];
	[ACCOUNT_CREATION_INPUT_FIELDS.accountManagerId]?: string; //optional
}>;

/**
 * Typeguard for AccountCreationInput
 * @param obj
 */
export function isAccountCreationInput(obj: unknown): obj is AccountCreationInput {
	return (
		isPlainJsonObject(obj) &&
		isString(obj[ACCOUNT_CREATION_INPUT_FIELDS.accountName]) &&
		isString(obj[ACCOUNT_CREATION_INPUT_FIELDS.firstName]) &&
		isString(obj[ACCOUNT_CREATION_INPUT_FIELDS.lastName]) &&
		isString(obj[ACCOUNT_CREATION_INPUT_FIELDS.email]) &&
		obj[ACCOUNT_CREATION_INPUT_FIELDS.permission] === Permissions.Admin
	);
}

/**
 * Type reflecting the DTO of AddAccount
 */
export type AccountCreationResponseDTO = Readonly<{
	id: string;
	name: string;
	orgInfo: {
		id: string;
	};
}>;

/**
 * Type guard for AddAccountResponse
 * @param obj
 */
export function isAccountCreationResponse(obj: unknown): obj is AccountCreationResponseDTO {
	return (
		isPlainJsonObject(obj) &&
		isPlainJsonObject(obj.orgInfo) &&
		isString(obj.id) &&
		isString(obj.name) &&
		isString(obj.orgInfo.id)
	);
}

/**
 * Response from account creation
 */
export type AccountCreationResponseModel = Readonly<{
	/**
	 * Id of account created
	 */
	accountId: string;
	/**
	 * Name of account created
	 */
	accountName: string;
	/**
	 * Id of org created
	 */
	orgId: string;
	/**
	 * Id of primary contact user created
	 */
	userId: string;
}>;

/**
 * Callback for creating a new account
 */
export type CreateAccountCallbackType = (
	input: AccountCreationInput
) => Promise<AccountCreationResponseModel>;

/**
 * The return type of the account creation hook
 */
export type AccountCreationHookType = [createAccount: CreateAccountCallbackType, loading: boolean];
