import { Button, Dropdown, Menu } from 'antd';
import { ReactNode, useState } from 'react';
import { Moment } from 'moment';
import { DownOutlined } from '@ant-design/icons';
import { arrayToCustomMap, disablePrevDates } from '@copilot/common/utils';
import {
	REMINDER_FORMATTING,
	getRelativeReminderDateMenuItems,
} from '@copilot/common/utils/dateUtil';
import ReminderPicker from './reminder/reminderPicker/reminderPicker';
import isNil from 'lodash/isNil';

const CUSTOM_DATE_KEY = 'customDate';

type DateDropdownProps = {
	/**
	 * Callback on selecting date
	 */
	onSelectDate: (date: Moment) => void;
	/**
	 * Selectable date options
	 */
	dateOptions: { key: string; label: ReactNode; evaluator: (date: Moment) => Moment }[];
	/**
	 * Selected date to set reminder on
	 */
	selectedDate?: Moment;
	/**
	 * Whether to show display date column or not
	 */
	showDisplayDate?: boolean;
	/**
	 * Whether to surround dropdown with border or not
	 */
	isBordered?: boolean;
	/**
	 * The text colour of the closed dropdown
	 */
	selectionColour?: string;
	/** Whether the date dropdown should be disabled */
	disabled?: boolean;
};

//TODO-COPILOT-7636 fix initial selection state https://cassia.atlassian.net/browse/COPILOT-7636
/**
 * [Presentational] Dropdown with Date Options
 */
function DateDropdown({
	selectedDate,
	onSelectDate,
	dateOptions,
	showDisplayDate = true,
	isBordered = true,
	selectionColour,
	disabled,
}: DateDropdownProps) {
	const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
	const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
	const [selectedDateKey, setSelectedDateKey] = useState<string | null>(null);

	const dateLabel = arrayToCustomMap(
		dateOptions,
		(option) => option.key,
		(option) => option.label
	);

	function handleSelect(date: Moment, dateKey: string) {
		setSelectedDateKey(dateKey);
		onSelectDate(date);
		setIsDropdownVisible(false);
	}

	function toggleDatePicker(toggleOn: boolean) {
		setIsDatePickerOpen(toggleOn);
		setIsDropdownVisible(toggleOn);
	}

	function handleCustomDateSelect(date: Moment) {
		setSelectedDateKey(CUSTOM_DATE_KEY);
		onSelectDate(date);
		toggleDatePicker(false);
	}

	function handleDropdownChange(visible: boolean) {
		setIsDropdownVisible(visible);
	}

	function calcDropdownLabel() {
		if (isNil(selectedDateKey) || !selectedDate) {
			return 'Choose Date';
		}

		return selectedDateKey === CUSTOM_DATE_KEY
			? selectedDate?.format(REMINDER_FORMATTING)
			: dateLabel[selectedDateKey];
	}

	return (
		<Dropdown
			disabled={disabled}
			overlay={
				<Menu selectedKeys={[selectedDateKey ?? '']}>
					{isDatePickerOpen ? (
						<ReminderPicker
							onToggleDatePicker={() => toggleDatePicker(false)}
							onCancel={() => toggleDatePicker(false)}
							onSetReminderDate={handleCustomDateSelect}
							disabledDates={disablePrevDates}
						/>
					) : (
						getRelativeReminderDateMenuItems(
							() => toggleDatePicker(!isDatePickerOpen),
							handleSelect
						)
					)}
				</Menu>
			}
			trigger={['click']}
			open={isDropdownVisible || isDatePickerOpen}
			overlayStyle={{
				width: showDisplayDate ? '330px' : '',
				boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.5)',
			}}
			onOpenChange={handleDropdownChange}
			destroyPopupOnHide
		>
			<Button style={{ color: selectionColour ?? '' }} type={isBordered ? 'default' : 'link'}>
				{calcDropdownLabel()}
				<DownOutlined />
			</Button>
		</Dropdown>
	);
}

export default DateDropdown;
