import { Typography } from 'antd';

const { Text } = Typography;

/**
 * Renders the message content for a linkedIn message, showing subject message and signature for inMails and just the message for other message types.
 * @param data
 * @param subject
 * @returns
 */
export function renderMessageContent(data: string, subject?: string) {
	const [msg, ...rest] = data.split('\n\n\n');
	let tmpMsg = msg;
	for (let i = 0; i < rest.length - 1; i++) {
		tmpMsg += '   ' + rest[i];
	}

	const signature = rest[rest.length - 1];

	return subject ? (
		<div style={{ marginTop: '12px' }}>
			<Text>
				<Text strong>Subject: </Text>
				{subject}
			</Text>
			<div style={{ marginTop: '12px', marginBottom: '16px' }}>
				<Text>
					<Text strong>Message: </Text>
					{tmpMsg}
					<br />
					<br />
					{signature}
				</Text>
			</div>
		</div>
	) : (
		<Text>{data}</Text>
	);
}
