import React, { ReactElement, useContext } from 'react';
import { Button } from 'antd';
import { CheckCircleFilled, ClockCircleFilled, SyncOutlined } from '@ant-design/icons';
import { ThemeContext } from 'styled-components';
import { isReactElement } from '@copilot/common/utils';

export const LinkedInResultStatus = { Error: -1, Success: 0, Syncing: 1 };

interface ILinkedinLoginErrorProps {
	onClickCallback?: () => void;
	buttonText?: string;
	header: string | ReactElement;
	callToAction?: string;
	img?: string;
	status?: number;
	loading?: boolean;
}

/**
 * Displays the result step of Linkedin login.
 * @param callToAction display text of the call to action
 * @param onClickCallback on click of the Continue button
 * @param buttonText CTA button label
 * @param header a list of string to display in header. Each string represent a line
 * @param img img to display
 * @param status login result status
 */
const LinkedinLoginResult: React.FC<ILinkedinLoginErrorProps> = (props) => {
	const {
		callToAction,
		status,
		img,
		onClickCallback,
		buttonText = 'Continue',
		header,
		loading = false,
	} = props;
	const themeContext = useContext(ThemeContext);

	return (
		<div style={{ textAlign: 'center' }}>
			{img && <img src={img} />}
			{status === LinkedInResultStatus.Success && (
				<CheckCircleFilled
					style={{ color: themeContext['@success-color'], fontSize: '4em' }}
				/>
			)}
			{status === LinkedInResultStatus.Error && (
				<ClockCircleFilled style={{ fontSize: '4em' }} />
			)}

			{status == LinkedInResultStatus.Syncing && <SyncOutlined style={{ fontSize: '4em' }} />}
			{isReactElement(header) ? header : <h2 style={{ fontWeight: 'bold' }}>{header}</h2>}
			{callToAction && (
				<p style={{ marginBottom: '16px', color: '#3A4351' }}>{callToAction}</p>
			)}
			{onClickCallback && (
				<Button type="primary" size="large" onClick={onClickCallback} loading={loading}>
					{buttonText}
				</Button>
			)}
		</div>
	);
};

export default LinkedinLoginResult;
