import { LoginResponseToken } from '@copilot/data/responses/interface';
import { IOrganizationMember, OrganizationMember } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = OrganizationMember.modelName;

export namespace OrganizationMemberActions {
	export const loadOrganizationMember = (
		member: Partial<IOrganizationMember> | Partial<IOrganizationMember>[]
	) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: member,
	});

	export const loadOrganizationMemberFromToken = (
		tokenPayload: LoginResponseToken
	): {
		type: EntitiesActionTypes;
		itemType: string;
		payload: Partial<IOrganizationMember>;
	} => {
		const {
			omid: id,
			email,
			firstName,
			lastName,
			otype: orgType,
			oid,
			orole,
			jti,
		} = tokenPayload;
		return {
			type: EntitiesActionTypes.Create,
			itemType,
			payload: {
				id,
				email,
				userId: jti,
				firstName,
				lastName,
				orgType: parseInt(orgType, 10),
				organizationId: oid,
				orgRoles: [orole],
				userRoles: [
					tokenPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
				],
			},
		};
	};

	export const updateOrganizationMember = (member: Partial<IOrganizationMember>) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: member,
	});

	export const deleteOrganizationMember = (member: Partial<IOrganizationMember>) => ({
		type: EntitiesActionTypes.Delete,
		itemType,
		payload: member,
	});

	export const resetOrganizationMembers = () => ({
		type: EntitiesActionTypes.Reset,
		itemType,
	});
}
