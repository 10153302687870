import modalManager from '@copilot/common/utils/modalManager';
import { PageHeader } from '@ant-design/pro-layout';
import { Skeleton } from 'antd';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const StyledPageHeader = styled(PageHeader)`
	&& {
		background-color: #ffffff;
		box-shadow: 0px 4px 4px rgba(163, 163, 163, 0.25);
	}
	.copilot-page-header-heading-title {
		font-family: Lato;
		font-size: 18px;
		font-style: normal;
		font-weight: normal;
		line-height: 22px;
		color: #4f4f4f;
	}
	.copilot-page-header-back {
		font-size: 20px;
	}
`;

interface CustomerDetailsHeaderProps {
	/**
	 * Title to be displayed in the header
	 */
	title?: string;
	/**
	 * URL to visit on back
	 */
	url?: string;

	/**
	 * Whether to show a confirmation when leaving
	 */
	confirmOnLeave: boolean;
}

/**
 * [Presentational] component for Customer Details Header
 */
const CustomerDetailsHeader: React.FC<CustomerDetailsHeaderProps> = (props) => {
	const { title, url = '/', confirmOnLeave } = props;

	const history = useHistory();

	const onBack = () => history.push(url);
	const confirm = () => {
		modalManager.openChangesNotSavedModal({
			onConfirm: onBack,
		});
	};

	return (
		<StyledPageHeader
			onBack={confirmOnLeave ? confirm : onBack}
			title={
				title ?? (
					<Skeleton
						active
						title={{ style: { margin: 0 }, width: 200 }}
						paragraph={false}
					/>
				)
			}
		/>
	);
};

export default CustomerDetailsHeader;
