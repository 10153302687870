import type { DrawerTypes } from '.';

export interface IDrawerAction {
	type: DrawerTypes;
	props: any;
}

export class DrawerAction implements IDrawerAction {
	public type: IDrawerAction['type'];
	public props: IDrawerAction['props'];

	constructor(type: IDrawerAction['type'], props: IDrawerAction['props']) {
		this.type = type;
		this.props = props;
	}
}
