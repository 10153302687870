import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router';
import LoginLayout from '@copilot/common/pages/layouts/login';
import RouteWithPermissions from '@copilot/common/router/routeWithPermissions';
import { UserRoleGroups } from '@copilot/common/config/roles';
import LoginPage from '@copilot/common/pages/login';
import BaseLayout from '@copilot/common/pages/layouts/base';
import BaseSidebar from '../components/menus/sidebar';
import { BaseHeaderWithCalendly } from '@copilot/cs/src/components/menus/header';
import CategorizePage from '../pages/categorize';
import withImpersonation from '../pages/customer/withImpersonation';
import { createCampaignDashboard } from '@copilot/common/pages/campaignDashboard';
import withIndividualUserType from '@copilot/common/hoc/userType/individual';
import { Config } from '@copilot/common/config';
import TeamCustomersPage from '../pages/customers/teams';
import TeamDashboard from '../pages/customer/teams';
import { EnvironmentType } from '../components/menus/header/constant';
import BillingPage from '@copilot/cs/src/pages/customerDetails/billing';
import CustomerDetailsLayout from '@copilot/cs/src/pages/customerDetails/ui/layout';
import ChangeSubscriptionWizard from '@copilot/cs/src/pages/customerDetails/billing/edit';
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
} from '@copilot/common/utils/authenticationProvider';
import B2CSignUpPage from '@copilot/common/pages/b2csignup';

const BaseLayoutRoutes = [
	'/',
	'/teams',
	'/teams/:oid/:omid?',
	'/customer/:oid/:omid?',
	'/customers',
	'/customer/:oid/:omid/campaign/:campaignId?',
	'/inbox/:id?',
	'/categorize',
];
const LoginLayoutRoutes = ['/login'];
const CustomerDetailsLayoutRoutes = ['/details/:orgMemberId/billing'];
const BillingLayoutRoutes = ['/details/:orgMemberId/billing/edit'];
const SignUpLayoutRoutes = ['/signup'];

const CampaignDashboard = withImpersonation(withIndividualUserType(createCampaignDashboard));

const Router: React.FC<RouteProps> = (props) => (
	<>
		<Switch location={props.location}>
			{Config.isB2CEnabled && (
				<Route exact path={SignUpLayoutRoutes}>
					<LoginLayout>
						<Switch>
							<RouteWithPermissions
								exact
								path="/signup"
								render={(renderProps) => <B2CSignUpPage {...renderProps} />}
								allowedRoles={UserRoleGroups.Guest}
								roleRedirectTo="/"
							/>
						</Switch>
					</LoginLayout>
				</Route>
			)}
			<Route exact path={LoginLayoutRoutes}>
				<UnauthenticatedTemplate>
					<LoginLayout>
						<Switch>
							<RouteWithPermissions
								exact
								path="/login"
								render={() => <LoginPage hidePwdRecovery />}
								allowedRoles={UserRoleGroups.Guest}
								roleRedirectTo="/"
							/>
						</Switch>
					</LoginLayout>
				</UnauthenticatedTemplate>
			</Route>
			<Route exact path={BaseLayoutRoutes}>
				<AuthenticatedTemplate>
					<BaseLayout>
						<BaseLayout.Header>
							<BaseHeaderWithCalendly
								environmentType={EnvironmentType.AGENCY}
								showProfile={false}
								showSettings
							/>
						</BaseLayout.Header>
						<BaseLayout.Sidebar>
							<BaseSidebar />
						</BaseLayout.Sidebar>
						<BaseLayout.Content>
							<Switch>
								<RouteWithPermissions
									exact
									path={'/'}
									component={TeamCustomersPage}
									allowedRoles={UserRoleGroups.Admin}
								/>
								<RouteWithPermissions
									exact
									path="/customer/:oid/:omid/campaign/:campaignId?"
									component={CampaignDashboard}
									allowedRoles={UserRoleGroups.Admin}
								/>
								<RouteWithPermissions
									exact
									path="/teams/:oid/:omid?"
									component={withImpersonation(TeamDashboard)}
									allowedRoles={UserRoleGroups.Admin}
								/>
								{!Config.isAgency && (
									<RouteWithPermissions
										exact
										path="/categorize"
										component={CategorizePage}
										allowedRoles={UserRoleGroups.VirtualAssistant}
									/>
								)}
								<Redirect to="/" />
							</Switch>
						</BaseLayout.Content>
					</BaseLayout>
				</AuthenticatedTemplate>
			</Route>
			<Route exact path={CustomerDetailsLayoutRoutes}>
				<CustomerDetailsLayout
					includeSidebar
					getHeaderTitle={() => 'Customers'}
					getBackUrl={() => '/'}
				>
					<AuthenticatedTemplate>
						<Switch>
							<RouteWithPermissions
								exact
								path="/details/:orgMemberId/billing"
								component={BillingPage}
								allowedRoles={UserRoleGroups.Admin}
							/>
						</Switch>
					</AuthenticatedTemplate>
				</CustomerDetailsLayout>
			</Route>
			<Route exact path={BillingLayoutRoutes}>
				<CustomerDetailsLayout confirmOnLeave>
					<AuthenticatedTemplate>
						<Switch>
							<RouteWithPermissions
								exact
								path="/details/:orgMemberId/billing/edit"
								component={ChangeSubscriptionWizard}
								allowedRoles={UserRoleGroups.Admin}
							/>
						</Switch>
					</AuthenticatedTemplate>
				</CustomerDetailsLayout>
			</Route>
		</Switch>
	</>
);

export default Router;
