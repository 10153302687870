import _ from 'lodash';
import { InboxTableMessage } from './component/table/types';

export const ALL = 'All';
export const POTENTIALLY_INTERESTED = 'PotentiallyInterested';
export const INTERESTED = 'Interested';
export const NOT_INTERESTED = 'NotInterested';
export const MAYBE = 'Maybe';
export const IN_PROGRESS = 'InProgress';
export const REMINDER = 'Reminder';

export const TAB_MESSAGES_FILTER = {
	[ALL]: (message: InboxTableMessage) =>
		message.isSnoozed === false || _.isUndefined(message.isSnoozed),
	[REMINDER]: (message: InboxTableMessage) => message.isSnoozed === false,
};
