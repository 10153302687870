// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OmUqfEdLenuuJVd1HnDg {\n  display: flex;\n  flex-direction: column;\n  row-gap: 4px;\n}\n.J0Eja6s91HWHYjqBotOA {\n  max-width: fit-content;\n  margin-left: auto;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/componentModels/activities/submitForm.module.less"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,YAAA;AACD;AAEA;EACC,sBAAA;EACA,iBAAA;AAAD","sourcesContent":[".submitFormWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\trow-gap: 4px;\n}\n\n.submitButton {\n\tmax-width: fit-content;\n\tmargin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitFormWrapper": "OmUqfEdLenuuJVd1HnDg",
	"submitButton": "J0Eja6s91HWHYjqBotOA"
};
export default ___CSS_LOADER_EXPORT___;
