import React, { useCallback, useMemo, Fragment } from 'react';
import { Col, Row } from 'antd';
import { StatFilterDefinition } from '../../componentModels/stat';
import SimpleChart from '../../charts/simple';
import StatSelector from '../selector';
import BaseStatisticsSummary from './base';
import { useSelector } from 'react-redux';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { Campaign } from '@copilot/common/store/models/redux';
import { Ref } from 'redux-orm';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { CampaignPercentageStatsWithReplyOverNurture } from '../type';
import { SummaryStatsAdapter } from '@copilot/common/adapter/statistic';
import { DAY_IN_MILLISECOND } from '@copilot/common/utils/constant';
import { LinkedInStatLabelMap } from '@copilot/common/constant/enum';

interface StatisticsSummaryProps extends React.ComponentProps<typeof BaseStatisticsSummary> {
	statsLoader?: (startDate?: Date, endDate?: Date) => void;
	isLoading: boolean;
	showFilter?: boolean;
	stats: SummaryStatsAdapter;
	statsOptions?: StatFilterDefinition[];
	selectorUpdateCallback: (
		stats: StatFilterDefinition[]
	) => unknown | StatSelector['props']['updateStats'];
	campaignColour?: string;
	id?: string;
}

interface IChartData {
	totals?: {
		[k: string]: number;
	};
	data?: {
		[k: string]: number;
	}[];
	description: string;
	key: string;
}

interface OverviewComponentProps {
	campaignRatios: TypedObject<CampaignPercentageStatsWithReplyOverNurture>;
	campaigns: readonly Ref<Campaign>[];
}

interface GraphComponentProps {
	timeSeriesKey?: string;
	data: { [k: string]: number }[];
	datakeyFilters?: TypedObject<boolean>;
	colors?: TypedObject<string>;
	ownerMap?: TypedObject<string>;
}

interface StatsComponentProps extends React.ComponentProps<typeof BaseStatisticsSummary> {
	campaignRatios: TypedObject<CampaignPercentageStatsWithReplyOverNurture>;
	isSingleDay: boolean;
	id: string;
}
const StatisticsSummary =
	(
		StatsComponent: React.FC<StatsComponentProps>,
		GraphComponent: React.FC<GraphComponentProps>,
		OverviewComponent?: React.FC<OverviewComponentProps>
	) =>
	(props: StatisticsSummaryProps) => {
		const { stats, statsOptions, id = '', ...rest } = props;
		const [isSingleDayStats, setIsSingleDayStats] = React.useState<boolean>(false);
		const [campaignFilters, setCampaignFilters] = React.useState<TypedObject<boolean>>({});
		const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
		const orgId = activeMember?.organizationId ?? '';
		const campaigns = useSelector((state) =>
			CampaignSelectors.getCampaignsByOrgId(state, orgId)
		);

		const colors = useMemo(
			() =>
				campaigns.reduce<Record<string, string>>(
					(obj, campaign) => ({ ...obj, [campaign.id]: campaign.primaryColour }),
					{}
				),
			[campaigns]
		);

		const campaignIdNameMapping = useMemo(
			() =>
				campaigns.reduce<Record<string, string>>(
					(obj, campaign) => ({ ...obj, [campaign.id]: campaign.name }),
					{}
				),
			[campaigns]
		);

		React.useEffect(() => {
			const filters: TypedObject<boolean> = {};
			campaigns.forEach((campaign: Ref<Campaign>) => {
				filters[campaign.id] = true;
			});
			setCampaignFilters({ ...filters, [orgId]: true } as TypedObject<boolean>);
		}, [campaigns, orgId]);

		const displayedStats = useMemo<IChartData[]>(() => {
			if (!stats) return [];
			const displayedTypes = (statsOptions ?? []).reduce<{ [k: string]: boolean }>(
				(acc, s) => {
					if (s.isVisible) acc[s.key] = s.isVisible;
					return acc;
				},
				{}
			);
			return Object.keys(displayedTypes).map((t) => {
				if (!stats.data[t])
					return {
						key: t,
						description:
							LinkedInStatLabelMap[t as keyof typeof LinkedInStatLabelMap] ?? t,
					};
				return {
					description: LinkedInStatLabelMap[t as keyof typeof LinkedInStatLabelMap] ?? t,
					data: stats.data[t],
					key: t,
					totals: stats.totals[t],
				};
			});
		}, [stats, statsOptions]);

		const generateGraph = useCallback(
			(totals: IChartData['totals'], data: IChartData['data'], key: IChartData['key']) => {
				const statName =
					LinkedInStatLabelMap[key as keyof typeof LinkedInStatLabelMap] ?? key;
				let total = 0;
				if (totals) {
					Object.keys(totals).forEach((totalsKey) => {
						if (campaignFilters[totalsKey] || orgId === totalsKey) {
							total += totals[totalsKey];
						}
					});
				}
				if (isSingleDayStats || !data || !totals)
					return (
						<SimpleChart title={total} description={statName}>
							{!isSingleDayStats && <div style={{ minHeight: 130 }} />}
						</SimpleChart>
					);
				else {
					return (
						<SimpleChart title={total} description={statName}>
							<GraphComponent
								data={data}
								ownerMap={campaignIdNameMapping}
								colors={colors}
								datakeyFilters={campaignFilters}
								timeSeriesKey="timestamp"
							/>
						</SimpleChart>
					);
				}
			},
			[campaignIdNameMapping, colors, campaignFilters, isSingleDayStats]
		);

		const newStatCharts = useMemo(() => {
			if (displayedStats.length === 0) return [];
			const generatedStats = displayedStats.map((s: IChartData) => (
				<Fragment key={s.key}>{generateGraph(s.totals, s.data, s.key)}</Fragment>
			));
			return generatedStats;
		}, [displayedStats, campaignFilters, colors, campaignIdNameMapping]);

		const onDateUpdate = useCallback((dates: { startDate?: Date; endDate?: Date }) => {
			if (!dates.startDate || !dates.endDate) return;
			setIsSingleDayStats(
				dates.endDate.getTime() - dates.startDate.getTime() < DAY_IN_MILLISECOND
			);
		}, []);
		const calculateRatios = useMemo(() => stats?.ratio ?? {}, [stats]);
		return (
			<>
				<Row>
					<Col span={24}>
						<StatsComponent
							id={id}
							onDateUpdate={onDateUpdate}
							newStatCharts={newStatCharts}
							campaignColorLegends={colors}
							campaignIdNameMapping={campaignIdNameMapping}
							onCampaignFiltersUpdate={setCampaignFilters}
							campaignFilters={campaignFilters}
							statsOptions={statsOptions}
							campaignRatios={calculateRatios}
							isSingleDay={isSingleDayStats}
							{...rest}
						/>
					</Col>
				</Row>

				{OverviewComponent && (
					<Row style={{ marginTop: '15px' }}>
						<Col span={24}>
							<OverviewComponent
								campaignRatios={calculateRatios}
								campaigns={campaigns}
							/>
						</Col>
					</Row>
				)}
			</>
		);
	};

export default StatisticsSummary;
