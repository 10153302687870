export const INMAIL_MESSAGE_CHARACTER_LIMIT = 1800; // Max character limit for inmail message
export const INMAIL_MESSAGE_RECOMMENDED_CHARACTOR_LIMIT = 200; // The recommended limit for inmail message
export const INMAIL_SUBJECT_CHARACTER_LIMIT = 150; // Max character limit for inmail subject

export const INMAIL_INITIAL_NODE_ID = 'LINKEDIN_PROSPECTING_INITIAL_INMAIL_NODE'; // Static id for initial inmail node

// Keys available for editing inmail messages
export const INMAIL_FORM_KEYS = {
	message: 'message', // The message to send
	subject: 'subject', // The subject of the message
} as const;

// Instruction text for enabling InMails
export const INMAIL_ENABLE_INSTRUCTIONS = [
	'Enable the "Send InMail to Open Profiles" option in the Search List tab to execute this campaign.',
	'* This sequence will be available to all team members who activate the "Send InMail to Open Profiles" option',
].join('\n');

// Compliance message for InMail sequence
export const INMAIL_COMPLIANCE_MESSAGE =
	'Working in regulated industry (like financial services)? Submit message changes to your compliance team for review and approval.';

export const INMAIL_CHARACTER_COUNT_RECOMMENDATION_MESSAGE =
	'Shorter message (~200 characters) have a 22% better response rate.';

// Default initial node for inmail sequence
export const INMAIL_DEFAULT_INITIAL_NODE = {
	nodeId: INMAIL_INITIAL_NODE_ID,
	subject: '',
	text: '',
	period: 0,
	time: 0,
} as const;
