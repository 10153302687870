import { useSelector } from 'react-redux';
import modalManager from '@copilot/common/utils/modalManager';
import { TemplateManager } from '@copilot/data';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { MessageTemplateActions } from '@copilot/common/store/actions';
import notificationManager from '@copilot/common/utils/notificationManager';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useFetch } from '@copilot/common/hooks/common';
import { partition, throwError } from '@copilot/common/utils';
import { CreateQuickResponseModalCallback } from './types';

/**
 * Message displayed when a request successfully creates a quick response
 */
const templateCreationSuccess = {
	message: 'Quick response created',
	description: 'Your quick response has been created',
};

/**
 * Message displayed when a request successfully updates a quick response
 */
const templateUpdateSuccess = {
	message: 'Quick response updated',
	description: 'Your quick response has been updated successfully',
};

/**
 * Message displayed when a request fails to create a quick response
 */
const templateCreationFailure = {
	message: 'Failed to add quick response',
	description: 'Please try again',
};

/**
 * Message displayed when a request fails to update a quick response
 */
const templateUpdateFailure = {
	message: 'Changes failed to save',
	description: 'Please try again',
};

/**
 * Type of the callback used to open a modal to edit an existing quick response
 */
type EditQuickResponseModalCallback = (templateId: string) => Promise<void>;

/**
 * Type returned by the useQuickResponseModal hook
 */
type QuickResponseModalHookType = readonly [
	CreateQuickResponseModalCallback,
	EditQuickResponseModalCallback
];

/**
 * [Smart] Hook for creating a new quick response w/ a modal
 */
export const useQuickResponseModal = (): QuickResponseModalHookType => {
	const [, createTemplateFetch] = useFetch(
		TemplateManager.createTemplateMessage,
		MessageTemplateActions.updateOne
	);
	const [, updateTemplateFetch] = useFetch(
		TemplateManager.editTemplateMessage,
		MessageTemplateActions.updateOne
	);

	// Get the user's organization ID
	const organizationId =
		useSelector(OrganizationMemberSelectors.getActiveMember)?.organizationId ??
		throwError('expected user to exist when using quick response modals');

	// Get IDs of all campaigns available to the user
	const campaigns = useSelector(CampaignSelectors.getCampaigns).map((campaign) => ({
		label: campaign.name,
		value: campaign.id,
	}));

	/**
	 * Create a new quick response
	 * @param title
	 * @param message
	 * @param campaignIds
	 */
	const createOnSaveCallback = (title: string, message: string, campaignIds: string[]) => {
		const messageTemplate = { name: title, message, campaignIds, organizationId };
		createTemplateFetch(messageTemplate)
			.then(() => notificationManager.showSuccessNotification(templateCreationSuccess))
			.catch(() => notificationManager.showErrorNotification(templateCreationFailure));
	};

	/**
	 * Open a modal to create a new quick response
	 * @param defaultCampaignIds
	 * @param message
	 */
	const createQuickResponseModal = (defaultCampaignIds?: string[], message?: string) => {
		modalManager.openCreateQuickResponseModal({
			message,
			campaignOptions: campaigns,
			selectedCampaigns: defaultCampaignIds ?? [],
			onSaveCallback: createOnSaveCallback,
		});
	};

	/**
	 * Update a quick response
	 * @param id
	 * @param name
	 * @param message
	 * @param campaignIds
	 */
	const updateOnSaveCallback = (
		id: string,
		name?: string,
		message?: string,
		campaignIds?: string[]
	) => {
		const messageTemplate = { id, name, message, campaignIds, organizationId };
		updateTemplateFetch(messageTemplate)
			.then(() => notificationManager.showSuccessNotification(templateUpdateSuccess))
			.catch(() => notificationManager.showErrorNotification(templateUpdateFailure));
	};

	/**
	 * Open a modal to edit an existing quick response
	 * @param templateId
	 */
	const editQuickResponseModal = async (templateId: string) => {
		const template = await TemplateManager.loadTemplateMessage(templateId);
		const allIds = new Set(campaigns.map((c) => c.value));
		const [selected, hidden] = partition(template.campaignIds, (id) => allIds.has(id));

		modalManager.openCreateQuickResponseModal({
			title: template.name,
			message: template.message,
			selectedCampaigns: selected,
			campaignOptions: campaigns,
			onSaveCallback: (title, message, campaignIds) =>
				updateOnSaveCallback(templateId, title, message, [...hidden, ...campaignIds]),
		});
	};

	return [createQuickResponseModal, editQuickResponseModal];
};
