import styled from 'styled-components';

const SideInfo = styled.div`
	width: 250px;
	padding: 0 24px 24px 24px;
	border-radius: 4px;
`;

const InfoLi = styled.li`
	line-height: normal;
	margin-bottom: 8px;
	font-size: small;
`;

interface LinkedInSideInfoProps {}

const LinkedInSideInfo: React.FC<LinkedInSideInfoProps> = () => (
	<SideInfo>
		<h4 style={{ margin: '16px 0' }}>Troubleshooting Tips &mdash; LinkedIn Verification Pin</h4>
		<ul style={{ paddingLeft: '18px' }}>
			<InfoLi>Check that you've entered the right verification pin.</InfoLi>
			<InfoLi>Check your junk or spam mail.</InfoLi>
			<InfoLi>
				Ensure that you are looking in the right place for the verification code. (Double
				check that you are in the right email mailbox or if the pin was sent via SMS)
			</InfoLi>
			<InfoLi>
				If all the above steps fail, you can verify at a later date with help from our
				customer success team.
			</InfoLi>
		</ul>
	</SideInfo>
);

export default LinkedInSideInfo;
