export const spacingXSS = '4px';
export const spacingXS = '8px';
export const spacingMD = '16px';
export const spacingLG = '24px';
export const spacingXLG = '48px';

// Constants for when we eventally merge in the poc for font and spacing sizes
// export const spacingXS = `${AntTheme["@spacer-size-xs"]}`;
// export const spacingMD= `${AntTheme["@spacer-size-md"]}`;
// export const spacingLG = `${AntTheme["@spacer-size-lg"]}`;

export const fontSizeSM = '12px';
export const fontSizeMD = '14px';
export const fontSizeLg = '20px';

//Font Weights as per https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#meaning_of_relative_weights
export const fontWeightThin = 100;
export const fontWeightExtraLight = 200;
export const fontWeightLight = 300;
export const fontWeightNormal = 400;
export const fontWeightMedium = 500;
export const fontWeightSemiBold = 600;
export const fontWeightBold = 700;
export const fontWeightExtraBold = 800;
export const fontWeightBlack = 900;
export const fontWeightExtraBlack = 950;
