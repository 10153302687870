import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import BackgroundBlob from '@copilot/common/assets/images/login/teal_blob.svg';
import BackgroundTealTriangle from '@copilot/common/assets/images/login/teal_triangle.svg';
import BackgroundYellowTriangle from '@copilot/common/assets/images/login/yellow_triangle.svg';
import BackgroundYellowSquareTilted from '@copilot/common/assets/images/login/yellow_square_tilted.svg';
import BackgroundYellowSquareRotated from '@copilot/common/assets/images/login/yellow_square_rotated.svg';
import BackgroundLogo from '@copilot/common/assets/images/login/logo.png';

const BackgroundWrapper = styled(Col)`
	width: 100vw;
	height: 100vh;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	// mobile only
	@media screen and (max-width: ${(props) => props.theme['@screen-xs-max']}) {
		display: none;
	}
`;

const BackgroundWrapperRow = styled(Row)`
	width: 100%;
	align-items: center;
	justify-content: center;
	@media screen and (max-width: ${(props) => props.theme['@screen-sm-max']}) {
		justify-content: flex-end;
		margin-right: 2%;
	}
`;

const StyledBackgroundBlob = styled.img`
	width: 100%;
	margin-top: -3vh;
	@media screen and (min-width: ${(props) => props.theme['@screen-lg-min']}) {
		margin-left: 2.5%;
	}
`;

const StyledBackgroundTealTriangle = styled.img`
	position: absolute;
	width: 7%;
	top: 5%;
	left: -8%;
	@media screen and (max-width: ${(props) => props.theme['@screen-md-max']}) {
		top: calc(-60px + 5%);
		left: calc(-70px + 4%);
	}
	@media screen and (max-width: ${(props) => props.theme['@screen-sm-max']}) {
		top: calc(-90px + 8%);
		left: calc(-50px + 5%);
	}
`;

const StyledBackgroundYellowSquareRotated = styled.img`
	position: absolute;
	width: 8%;
	top: 50%;
	left: -8%;
	@media screen and (max-width: ${(props) => props.theme['@screen-md-max']}) {
		top: 52%;
		left: -5%;
	}
`;

const StyledBackgroundLogoLowerLeft = styled.img`
	position: absolute;
	width: 4%;
	bottom: -3%;
	left: 10%;
	@media screen and (max-width: ${(props) => props.theme['@screen-md-max']}) {
		bottom: 5%;
		left: calc(30px + 5%);
	}
	@media screen and (max-width: ${(props) => props.theme['@screen-sm-max']}) {
		bottom: -15%;
		left: 8%;
	}
`;

const StyledBackgroundYellowSquareTilted = styled.img`
	position: absolute;
	width: 8%;
	bottom: -12%;
	right: 25%;
	@media screen and (max-width: ${(props) => props.theme['@screen-md-max']}) {
		bottom: calc(-120px + 10%);
		right: 22%;
	}
	@media screen and (max-width: ${(props) => props.theme['@screen-sm-max']}) {
		bottom: calc(-120px + 5%);
		right: 20%;
	}
`;

const StyledBackgroundLogoLowerRight = styled.img`
	position: absolute;
	width: 5%;
	bottom: 15%;
	right: -2%;
	@media screen and (max-width: ${(props) => props.theme['@screen-md-max']}) {
		bottom: 12%;
		right: 3%;
	}
`;

const StyledBackgroundYellowTriangle = styled.img`
	position: absolute;
	width: 7%;
	top: 15%;
	right: 7%;
	@media screen and (max-width: ${(props) => props.theme['@screen-md-max']}) {
		top: 20%;
		right: 3%;
	}
	@media screen and (max-width: ${(props) => props.theme['@screen-sm-max']}) {
		top: 15%;
	}
`;

const StyledBackgroundLogoUpper = styled.img`
	position: absolute;
	width: 5%;
	top: -12%;
	left: 28%;
	@media screen and (max-width: ${(props) => props.theme['@screen-lg-max']}) {
		top: calc(-100px + 7%);
	}
	@media screen and (max-width: ${(props) => props.theme['@screen-md-max']}) {
		top: calc(-120px + 9%);
		left: 25%;
	}
	@media screen and (max-width: ${(props) => props.theme['@screen-sm-max']}) {
		top: calc(-150px + 15%);
		left: 22%;
	}
`;

/**
 * Responsive CoPilot Background with polygonal shapes that is hidden in mobile view
 */
const LoginLayoutBackground: React.FC = () => (
	<BackgroundWrapper>
		<BackgroundWrapperRow>
			<Col sm={22} md={20} lg={16} xl={14} xxl={12}>
				<StyledBackgroundBlob src={BackgroundBlob} />
				<StyledBackgroundTealTriangle src={BackgroundTealTriangle} />
				<StyledBackgroundYellowSquareRotated src={BackgroundYellowSquareRotated} />
				<StyledBackgroundLogoLowerLeft src={BackgroundLogo} />
				<StyledBackgroundYellowSquareTilted src={BackgroundYellowSquareTilted} />
				<StyledBackgroundLogoLowerRight src={BackgroundLogo} />
				<StyledBackgroundYellowTriangle src={BackgroundYellowTriangle} />
				<StyledBackgroundLogoUpper src={BackgroundLogo} />
			</Col>
		</BackgroundWrapperRow>
	</BackgroundWrapper>
);

export default LoginLayoutBackground;
