import { StatData } from '@copilot/common/components/statistics/type';

export namespace StatsUtilityFunctions {
	export const combineStatData = (base: StatData, extra: StatData): StatData => {
		if (base.name !== extra.name)
			throw Error(`Stats mismatch: ${base.name} and ${extra.name} are not the same`);
		return { name: base.name, value: (base.value ?? 0) + (extra.value ?? 0) };
	};

	export const combinePercentageStats = (
		base: [StatData, StatData],
		extra: [StatData, StatData]
	): [StatData, StatData] => [
		combineStatData(base[0], extra[0]),
		combineStatData(base[1], extra[1]),
	];
}
