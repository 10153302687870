import { ILocation } from '@copilot/common/components/linkedin/searchCriteria/location';
import useTracking from '@copilot/common/hooks/tracking';
import { MessageTypeEnum } from './messaging/const';
import IntentMessageEditor from '@copilot/common/components/editors/message/wizard/intent';

export type WizardStepTypes =
	| 'Prospecting Onboard Welcome'
	| 'Prospecting Onboard LinkedInLogin'
	| 'Prospecting Onboard LinkedIn Login Success'
	| 'Prospecting Onboard Location'
	| 'Prospecting Onboard Occupation'
	| 'Prospecting Onboard Occupation Details'
	| 'Prospecting Onboard Connection Request Message'
	| 'Prospecting Onboard Second Message'
	| 'Prospecting Onboard Followup Messages'
	| 'Prospecting Onboard Schedule'
	| 'Prospecting Onboard Summary'
	| 'Prospecting Onboard Feedback'
	| 'Prospecting Onboard Complete';

export interface WizardTrackingPayloadInfo {
	// location
	location: ILocation;
	// Occupation
	occupation: string[];
	extraOccupations: string[];
	// Occupation Details
	experience: string[];
	headCount: string[];
	recentJobChange: boolean;
	exclusions: string[];
	// Messaging
	messageTemplateType: MessageTypeEnum;
	connectionMessage: string;
	secondMessage: NonNullable<React.ComponentProps<typeof IntentMessageEditor>['message']>;
	followupMessages: NonNullable<React.ComponentProps<typeof IntentMessageEditor>['message']>[];
}

export interface WizardTrackingPayloadParameter extends WizardTrackingPayloadInfo {
	isFollowupTimeChanged: boolean;
	followupTimePeriod: string;
}

export interface WizardTrackingParameter {
	wizardStep: WizardStepTypes;
	payload: WizardTrackingPayloadParameter;
}

export const useOnboardingWizardTracking = (
	componentName: string,
	params: Partial<WizardTrackingParameter> | null
): ((newParams: Partial<WizardTrackingParameter>) => void) =>
	useTracking(componentName, 'prospecting_onboard', params);

type ProspectCampaignOnboardEvents =
	| 'Create new campaign'
	| 'Sales Navigator'
	| 'Copilot Search'
	| 'Book Meeting';
export const useProspectCampaignOnboardTracking = (
	componentName: string,
	paramOnLoad: { buttonClicked: ProspectCampaignOnboardEvents } | null = null
) => useTracking(componentName, 'prospect_campaign_onboard', paramOnLoad);
