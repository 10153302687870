import { useEffect, useCallback, useState, useMemo } from 'react';
import { useDebounce } from '@copilot/common/hooks/common';
import { FilterMapping, CustomerSuccessQuery } from '../../pages/customers/query';

import type { QueryObject } from '@copilot/data/managers/base';
import type { PaginatedResultModel } from '@copilot/data/responses/models/paginatedResult';
import type { IQueryProperties } from '@copilot/common/model/filters';
import { useDispatch } from 'react-redux';
import { AppActions } from '@copilot/common/store/actions/app';
import { EnumUtils } from '@copilot/common/utils';
import { ErrorsMap, ErrorStatus } from '@copilot/common/store/models/const/enum';
import { Tooltip } from 'antd';
import { StatusUtilityFunctions } from '../../utils/status';

const SORT_KEY = 'Sort';
const ORDER_KEY = 'Order';
const SEARCH_KEY = 'search';

export const useCustomersFetcher = <R, M>(
	searchParams: Partial<IQueryProperties>,
	getter: (query?: QueryObject) => Promise<PaginatedResultModel<R, M>>,
	debounceDelay?: number
) => {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState<number>(1);

	const [pageSize, setPageSize] = useState<number>(20);

	useEffect(() => {
		setCurrentPage(1); // resets to the first page when we have new search params
	}, [searchParams]);

	const onShowSizeChange = useCallback((current: number, size: number) => {
		setPageSize(size);
	}, []);

	/**
	 * Convert params to query
	 * @param queryDictionary query params
	 */
	const getData = useCallback(
		(queryDictionary: Partial<IQueryProperties>) => {
			const query = new CustomerSuccessQuery({
				pageSize,
				page: currentPage - 1,
				filterMapping: FilterMapping,
			});
			query.addSorter(queryDictionary[SORT_KEY] ?? '', queryDictionary[ORDER_KEY] ?? '');
			if (queryDictionary[SEARCH_KEY])
				query.addParameter(SEARCH_KEY, queryDictionary[SEARCH_KEY]!);

			Object.keys(queryDictionary).forEach((k) => {
				if (!queryDictionary[k as keyof IQueryProperties]) return;
				if (k === SORT_KEY || k === ORDER_KEY || k === SEARCH_KEY) return;
				query.addMappedFilter(k, queryDictionary[k as keyof IQueryProperties]!);
			});

			getter(query).finally(() => dispatch(AppActions.setFetchingDataState(false)));
		},
		[pageSize, currentPage, getter]
	);

	const getClientsDebounced = useDebounce(getData, debounceDelay);

	const loadClients = useCallback(() => {
		getClientsDebounced(searchParams);
		dispatch(AppActions.setFetchingDataState(true));
	}, [getClientsDebounced, searchParams]);

	const onReload = useCallback(() => {
		loadClients();
	}, [loadClients]);

	useEffect(() => {
		loadClients();
	}, [loadClients]);

	return {
		pageSize,
		currentPage,
		onShowSizeChange,
		onPageChange: setCurrentPage,
		onReload,
	};
};

/**
 * Maps the linkedin error Bit to a corresponding error string and element for the component to dispay
 * @param linkedInError
 * @returns errors, errorIcons
 */
export const useCustomerLinkedinError = (linkedInError: number) => {
	const errors = useMemo(() => {
		const results: string[] = [];
		if (linkedInError === 0) return results;
		const errorKeys = EnumUtils.getEnumKeys(ErrorStatus, 'string').reverse();
		for (const key of errorKeys) {
			if (linkedInError < ErrorsMap[key]) continue;
			results.push(key.toString() as string);
			linkedInError -= ErrorsMap[key];
		}
		return results;
	}, [linkedInError]);

	const errorIcons = useMemo(
		() =>
			errors.map((error) => (
				<Tooltip key={error} placement="topRight" title={error} trigger="hover">
					{StatusUtilityFunctions.getErrorIcon(error, '3em')}
				</Tooltip>
			)),
		[errors]
	);

	return [errors, errorIcons];
};
