/**
 * Generic type used for storing a list of data.
 */
export type ModelState<T> = {
	data: T[];
	totalCount: number;
	loading: boolean;
	error: boolean;
};

/**
 * Generic type used for extracting single data from ModelState
 */
export type ModelSingleState<T> = {
	data: T | undefined;
	loading: boolean;
	error: boolean;
};

export type ModelStateWithDirtyList<T> = {
	state: ModelState<T>;
	dirtyModelIds: string[];
};

export type ModelStateMap<T> = { [key: string]: ModelStateWithDirtyList<T> };

export const createBaseModelState = <T>(): ModelState<T> => ({
	data: [],
	totalCount: -1,
	loading: false,
	error: false,
});

export const createBaseModelStateWithDirtyList = <T>(): ModelStateWithDirtyList<T> => ({
	state: createBaseModelState<T>(),
	dirtyModelIds: [],
});
