import { FC, ReactNode, ComponentProps, useContext } from 'react';
import { Row, Col, Typography, Tag } from 'antd';
import styled, { ThemeContext } from 'styled-components';

const { Text, Link } = Typography;

const SecondaryText = styled(Text)`
	&&& {
		color: ${(props) => props.theme['@text-color-secondary']};
	}
`;

interface IListFieldProps {
	label: string;
	subLabel?: string;
	value?: string;
	subValue?: string;
	tag?: { label: string; color?: ComponentProps<typeof Tag>['color'] };
	icon?: ReactNode;
	subIcon?: ReactNode;
	onLabelClick?: () => void;
}

/**
 * Field in a List Component
 * @param {string} label main label for the field
 * @param {string} subLabel sub label for the field, appears below the main label
 * @param {string} value value for the field, appears floating on the right
 * @param {string} subValue additional information for the value, appears right of the value
 * @param {object} tag props for a tag which will appear right of the subValue
 * @param {ReactNode} icon icon that will appear right of the tag
 * @param {ReactNode} subIcon additional icon that appears right of the first icon
 * @param {function} onLabelClick called when the label is clicked, makes the label appear clickable
 */
const ListField: FC<IListFieldProps> = (props) => {
	const { label, subLabel, value, subValue, tag, icon, subIcon, onLabelClick } = props;
	const themeContext = useContext(ThemeContext);
	return (
		<Row wrap={false} gutter={themeContext['@spacer-num-xs']}>
			<Col flex="auto">
				<Row>
					<Col>
						{onLabelClick ? (
							<Link underline onClick={onLabelClick}>
								{label}
							</Link>
						) : (
							<Text>{label}</Text>
						)}
					</Col>
				</Row>
				{!!subLabel && (
					<Row>
						<Col>
							<SecondaryText>{subLabel}</SecondaryText>
						</Col>
					</Row>
				)}
			</Col>
			<Col>
				<Row wrap={false} align="middle" gutter={themeContext['@spacer-num-xs']}>
					{!!value && (
						<Col>
							<Text>{value}</Text>
						</Col>
					)}
					{!!subValue && (
						<Col>
							<SecondaryText>{subValue}</SecondaryText>
						</Col>
					)}
					{tag && (
						<Col>
							<Row>
								{/* TODO: replace with custom tag component */}
								<Tag color={tag.color} style={{ margin: 0 }}>
									{tag.label}
								</Tag>
							</Row>
						</Col>
					)}
					{icon && <Col>{icon}</Col>}
					{subIcon && <Col>{subIcon}</Col>}
				</Row>
			</Col>
		</Row>
	);
};

export default ListField;
