import React, { HTMLProps } from 'react';

interface CalendlyProps extends HTMLProps<HTMLDivElement> {
	calendlyUrl: string;
	prefill: { [k: string]: any };
	onEventScheduled?: () => void;
	utmCampaignId?: string;
	utmSource?: string;
}
const CalendlyEvents = {
	Scheduled: 'calendly.event_scheduled',
	ProfilePageViewed: 'calendly.profile_page_viewed',
	EventTypeViewed: 'calendly.event_type_viewed',
	DateTimeSelected: 'calendly.date_and_time_selected',
};

const CalendlySnippet: React.FC<CalendlyProps> = (props) => {
	const {
		calendlyUrl,
		onEventScheduled,
		utmCampaignId = '',
		utmSource = '',
		prefill = {},
		...rest
	} = props;
	const divRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const canUseDOM = !!(
			typeof window !== 'undefined' &&
			window.document &&
			window.document.createElement
		);

		const container = divRef.current;

		if (!canUseDOM || !container) return undefined;

		window.Calendly.initInlineWidget({
			url: calendlyUrl,
			parentElement: container,
			utm: {
				utmCampaign: utmCampaignId,
				utmSource,
			},
			prefill,
		});

		function calendlyEventListener(e: any) {
			if (e.data.event && e.data.event.indexOf('calendly') === 0) {
				//handle window callback
				if (e.data.event === CalendlyEvents.Scheduled) {
					onEventScheduled?.();
				}
				console.log(e.data);
			}
		}

		window.addEventListener('message', calendlyEventListener);

		return () => {
			window.removeEventListener('message', calendlyEventListener);
		};
	}, []);
	return <div ref={divRef} {...rest} />;
};

export default CalendlySnippet;
