import * as React from 'react';
import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';

export interface TagListProps extends TagProps {
	tags: string[];
	handleTagClose?: (tag: string) => void;
}

class TagList extends React.Component<TagListProps> {
	constructor(props: TagListProps) {
		super(props);

		this.generateTagNodes = this.generateTagNodes.bind(this);
	}

	generateTagNodes(props: TagListProps) {
		const { tags, handleTagClose, ...rest } = props;
		return tags.map((tag) => {
			const p = handleTagClose ? { ...rest, onClose: () => handleTagClose(tag) } : props;
			return (
				<Tag className="tag" key={tag} {...p}>
					{tag}
				</Tag>
			);
		});
	}

	render() {
		const tags = this.generateTagNodes(this.props);
		return <>{tags}</>;
	}
}

export default TagList;
