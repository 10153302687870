import React, { useMemo } from 'react';
import { Select } from 'antd';
import { IInstanceData } from '@copilot/data/responses/models/instances';
import { useTheme } from 'styled-components';

/**
 * Converts an array of IInstanceData to an array of Select.Option
 */
const getSelectOptionsFromInstances = (instances: readonly IInstanceData[]) =>
	instances.map((instance) => (
		<Select.Option key={instance.name} value={instance.id}>
			{instance.name}
		</Select.Option>
	));

interface InstanceDataDropdownProps {
	instances: readonly IInstanceData[];
	onChange: (value: string) => void;
	instanceId?: string;
	isSelectAllShown?: boolean;
}

/**
 * A dropdown used to select an instance data
 * @param instances the instances that are to be displayed as select options
 * @param onChange callback passed to the dropdown
 * @param instanceId current value of the dropdown
 */
const InstanceDataDropdown: React.FC<InstanceDataDropdownProps> = (props) => {
	const { instances, onChange, instanceId, isSelectAllShown = false } = props;
	const theme = useTheme();
	const instanceOptions = useMemo(() => getSelectOptionsFromInstances(instances), [instances]);
	const instanceSelect = useMemo(
		() => (
			<Select
				value={instanceId}
				onChange={onChange}
				style={{ minWidth: theme['@dropdown-min-width'] }}
			>
				{isSelectAllShown && (
					<Select.Option key="all" value="all">
						All Domains
					</Select.Option>
				)}
				{instanceOptions}
			</Select>
		),
		[onChange, instanceId]
	);

	return instanceSelect;
};

export { getSelectOptionsFromInstances, InstanceDataDropdown };
