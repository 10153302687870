import { ComponentProps, ReactNode } from 'react';
import { Tabs as AntdTabs } from 'antd';
import { isObject } from 'lodash';

type TabsPropsType = Omit<
	ComponentProps<typeof AntdTabs>,
	'tabBarExtraContent' | 'renderTabBar'
> & {
	/**
	 * Extra content in tab bar
	 */
	tabBarExtraContent?: ReactNode | { left?: ReactNode; right?: ReactNode; bottom?: ReactNode };
};

/**
 * Custom typeguard to determine if the tabBarExtraContent field is an object
 * @param input
 */
const isReactNode = (input: TabsPropsType['tabBarExtraContent']): input is ReactNode => {
	return !isObject(input);
};

/**
 * Custom Tabs component that enable us to add content between the Tab bar and tab panel
 * @param props
 * @constructor
 */
const Tabs = (props: TabsPropsType) => {
	const { tabBarExtraContent, ...rest } = props;
	if (isReactNode(tabBarExtraContent))
		return <AntdTabs {...rest} tabBarExtraContent={tabBarExtraContent} />;
	const { bottom, left, right } = tabBarExtraContent;
	return (
		<AntdTabs
			{...rest}
			tabBarExtraContent={{ left, right }}
			renderTabBar={(tabBarProps, DefaultTabBarComponent) => (
				<>
					<DefaultTabBarComponent {...tabBarProps} />
					{bottom}
				</>
			)}
		/>
	);
};

Tabs.TabPane = AntdTabs.TabPane;

export default Tabs;
