import { EntitiesActionTypes } from './types';
import { FilterTemplate, IFilterTemplate } from '../models/redux/filterTemplate';

const itemType = FilterTemplate.modelName;
export namespace FilterTemplateActions {
	export const loadTemplates = (
		templates: Partial<IFilterTemplate> | Partial<IFilterTemplate>[]
	) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: templates,
	});

	export const deleteTemplate = (template: Partial<IFilterTemplate>) => ({
		type: EntitiesActionTypes.Delete,
		itemType,
		payload: template,
	});
}
