import useTracking from '@copilot/common/hooks/tracking';

export type OpenUsageLimitsDrawer =
	| 'Usage Limits Page'
	| 'Campaign Dashboard'
	| 'Campaign Toggle Notification';

interface OpenUsageLimitsDrawerTrackingParams {
	buttonClicked: OpenUsageLimitsDrawer;
}

export const useOpenUsageLimitsDrawerTracking = (
	componentName: string,
	params: OpenUsageLimitsDrawerTrackingParams | null
): ((newParams: OpenUsageLimitsDrawerTrackingParams) => void) =>
	useTracking(componentName, 'open_usage_limits_drawer', params);
