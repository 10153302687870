import { UtilityFunctions } from './common';
import moment, { Moment } from 'moment';
import { getPluralizedEnding as getPluralizedEndingInner } from './stringFormat';
import * as _ from 'lodash';
import { BaseOptionType } from 'antd/lib/cascader';
import BaseDataManager from '@copilot/data/managers/base';
import { appInsights } from '../components/snippets/applicationInsights';
import { isPlainObject } from 'lodash';
import { ServerError } from '@apollo/client';

type Primitive = string | number | boolean | bigint | null | undefined;

/**
 * Formats firstname and lastname to full name
 * @param {string} firstname
 * @param {string} lastname
 * @return {string} Full Name
 */
export const formatName = (firstname = '', lastname = ''): string =>
	`${firstname}${firstname && lastname ? ' ' : ''}${lastname}`;

/**
 * Formats object with firstName and lastName into a full name
 * @param {object} user object with firstName and lastName
 * @returns {string} Full Name
 */
export const formatNameFromObject = (user: { firstName?: string; lastName?: string }) =>
	formatName(user.firstName, user.lastName);

/**
 * Creates a new object with certain properties ommitted
 * @param {T} target Object we want
 * @param {string[]} omitKeys Keys we want omitted
 * @returns {Partial<T>} New object with properties omitted
 */
export function omit<T extends Record<string, unknown>, K extends keyof T>(
	target: T,
	...omitKeys: K[]
): Omit<T, K> {
	return (Object.keys(target) as K[]).reduce((res, key) => {
		if (!omitKeys.includes(key)) {
			res[key] = target[key];
		}
		return res;
	}, {} as any);
}

/**
 * Wrap a promise to make it cancellable
 * @param {Promise<T>} promise Promise we want to wrap
 * @returns {{promise: Promise<T>, cancel: () => void}} Wrapped promise
 */
export function makeCancelable<T>(promise: Promise<T>): {
	promise: Promise<T>;
	cancel: () => void;
} {
	let hasCanceled = false;
	const wrappedPromise = new Promise<T>((resolve, reject) => {
		promise.then(
			(val) => !hasCanceled && resolve(val),
			(error) => !hasCanceled && reject(error)
		);
	});
	return {
		promise: wrappedPromise,
		cancel() {
			hasCanceled = true;
		},
	};
}

/**
 * Convert utc date object to local
 */
export function convertUTCDateToLocalDate(date: Date): Date {
	const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

	const offset = date.getTimezoneOffset() / 60;
	const hours = date.getHours();

	newDate.setHours(hours - offset);

	return newDate;
}

/**
 * Format a fraction into percent
 * @param fraction Fraction we want to format
 */
export function formatPercentage(fraction: number): string {
	return `${(fraction * 100).toFixed(2)}%`;
}

export class EnumUtils {
	/**
	 * Returns the enum keys
	 * @param enumObj enum object
	 */
	static getEnumKeys(enumObj: Record<string, unknown>, valueType: string): any[] {
		return EnumUtils.getEnumValues(enumObj, valueType).map((value) => enumObj[value]);
	}

	/**
	 * Returns the enum values
	 * @param enumObj enum object
	 */
	static getEnumValues(enumObj: Record<string, unknown>, valueType: string): any[] {
		return Object.keys(enumObj).filter((key) => typeof enumObj[key] === valueType);
	}
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce(func: Function, wait = 500, immediate = false): (this: any) => void {
	let timeout: number | null;
	return function (this: any) {
		const context = this;
		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		if (timeout) clearTimeout(timeout);
		timeout = window.setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

/**
 * Check if a value's type simply a string
 * @param value the value to check for
 */
export function isString(value: any): value is string {
	if (typeof value === 'string') return true;
	return false;
}

/**
 * Helper function for Antd Cascader to allow for search ability
 * Note that this function only support options that has a string label
 * @param inputValue string to search options for
 * @param path the options to search the input value from
 */
export function locationSearch(inputValue: string, path: BaseOptionType[]): boolean {
	return path.some((option) => {
		if (!isString(option.label))
			throw Error('Unable to search because some label is not a string');
		return option.label.toLowerCase().includes(inputValue.toLowerCase());
	});
}

/**
 * Get number of days left to the endDate
 * @param endDate date we want to get the number of days from today
 */
export function getDaysLeft(endDate: Date): number {
	const today = new Date();
	if (!endDate || today >= endDate) return 0;
	const oneDay = 24 * 60 * 60 * 1000;
	return Math.round(Math.abs((today.getTime() - endDate.getTime()) / oneDay));
}

/**
 * Does a deep comparison between two json objects recursively
 * @param x
 * @param y
 */
export const deepEqual = (
	x: { [key: string]: any } | Primitive,
	y: { [key: string]: any } | Primitive
): boolean => {
	if ((x === undefined && y === undefined) || (x === null && y === null)) {
		return true;
	} else if (x === undefined || y === undefined || x === null || y === null) {
		return false;
	}

	if (x === y) {
		return true;
	}

	if (typeof x !== typeof y) {
		return false;
	}

	if (typeof x != 'object' && typeof y != 'object' && x !== y) {
		return false;
	}

	const xKeys = Object.keys(x);
	const yKeys = Object.keys(y);

	if (xKeys.length !== yKeys.length) {
		return false;
	}

	return xKeys.every((key) => {
		if (!Object.prototype.hasOwnProperty.call(y, key)) {
			return false;
		}

		const xValue: { [key: string]: any } | Primitive = typeof x === 'object' ? x[key] : x;
		const yValue: { [key: string]: any } | Primitive = typeof y === 'object' ? y[key] : y;
		return deepEqual(xValue, yValue);
	});
};

export enum NameErrorCodes {
	NoError,
	NoName,
	DuplicatedName,
	TooLong,
}

export const checkIsErrorCodeError = (code: NameErrorCodes): boolean => {
	switch (code) {
		case NameErrorCodes.NoError:
			return false;
		case NameErrorCodes.NoName:
		case NameErrorCodes.DuplicatedName:
		case NameErrorCodes.TooLong:
			return true;
		default:
			UtilityFunctions.assertUnreachable(code);
			// Shouldn't return this since the function above will throw error, but just adding this
			// line to get rid of the "not all code paths return a value" error
			return true;
	}
};
/**
 * validate if a name is duplicated
 * @param name name to validate
 * @param existingName existing names we want to validate the name against for duplication
 */
const checkHasDupes = (name: string, existingNames: string[]) => {
	const lowerCasedName = name.toLowerCase();
	return !!existingNames.find((n) => n.toLowerCase() === lowerCasedName);
};

/**
 * validate if a name exceed char limit
 * @param name name to validate
 * @param maxCharLimit maximum character limit
 */
const checkMaxCharLimit = (name: string, maxCharLimit: number) => name.length > maxCharLimit;

/**
 * validate if a name is valid
 * @param name name to validate
 * @param existingName existing names we want to validate the name against for duplication
 * @param maxCharLimit maximum character limit
 */
export const validateNewName = (
	name: string,
	existingNames: string[],
	maxCharLimit?: number
): NameErrorCodes => {
	if (!name) return NameErrorCodes.NoName;
	if (maxCharLimit && checkMaxCharLimit(name, maxCharLimit)) return NameErrorCodes.TooLong;
	if (checkHasDupes(name, existingNames)) return NameErrorCodes.DuplicatedName;

	return NameErrorCodes.NoError;
};

/**
 * Convert number to abbreviated ordinal number
 * ex. 1 -> 1st
 * @param number number we want to convert into ordinal number
 * @returns {string} abbreviated ordinal number as string
 */
export const convertToOrdinalAbbr = (number: number): string => {
	const j = number % 10;
	const k = number % 100;
	if (j == 1 && k != 11) {
		return `${number}st`;
	}
	if (j == 2 && k != 12) {
		return `${number}nd`;
	}
	if (j == 3 && k != 13) {
		return `${number}rd`;
	}
	return `${number}th`;
};

const special = [
	'zeroth',
	'first',
	'second',
	'third',
	'fourth',
	'fifth',
	'sixth',
	'seventh',
	'eighth',
	'ninth',
	'tenth',
	'eleventh',
	'twelfth',
	'thirteenth',
	'fourteenth',
	'fifteenth',
	'sixteenth',
	'seventeenth',
	'eighteenth',
	'nineteenth',
];
const prefix = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

/**
 * Convert number to ordinal number
 * ex. 1 -> first.
 * Supports 1 to 99 and above 99 returns 99th+
 * @param number number we want to convert into ordinal number
 * @returns {string} ordinal number as string
 */
export const convertToOrdinal = (number: number): string => {
	if (number < 20) return special[number];
	if (number > 99) return '99th+';
	if (number % 10 === 0) return `${prefix[Math.floor(number / 10) - 2]}ieth`;

	return `${prefix[Math.floor(number / 10) - 2]}y-${special[number % 10]}`;
};

/**
 * Generate an array of ordinal numbers in string form first, second,..n
 * @param start start number inclusive
 * @param end end number exclusive
 */
export const generateOrdinalStringNumArray = (start: number, end: number): string[] => {
	if (start < 0 || end < 0 || end < start) {
		throw new Error(
			'Either one of parameters are negative or end num is smaller than start num'
		);
	}
	return [...Array(end - start).keys()].map((num) => {
		const ordinalNumber = convertToOrdinal(num + start);
		return capitalizeFirstLetter(ordinalNumber);
	});
};

/**
 * disable previous dates
 * @param current
 */
export const disablePrevDates = (current: Moment): any =>
	// Can not select days before today and today
	current && current < moment().endOf('day');

/**
 * disable future dates
 * @param current
 */
export const disableFutureDates = (current: Moment): any =>
	current && current > moment().endOf('day');

/**
 * check if the given ReactNode is a ReactNodeArray
 * @param children ReactNode we want to check
 */
export const isReactNodeArray = (children: React.ReactNode): children is React.ReactNodeArray =>
	Array.isArray(children);

/**
 * check if the given ReactNode is a ReactElement
 * @param child ReactNode we want to check
 */
// Should we use React.isValidElement instead?
// eslint-disable-next-line no-prototype-builtins
export const isReactElement = (child: React.ReactNode): child is React.ReactElement =>
	typeof child === 'object' && !!child?.hasOwnProperty('type');

/**
 * Capitalize first letter of a string
 * @param string string we want to capitalize the first letter
 */
export const capitalizeFirstLetter = (string: string): string =>
	string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Generate an array of ordinal numbers 1st..n
 * @param start start number
 * @param end end number
 * @param suffix suffix to add
 */
export const generateOrdinalNumArray = (start: number, end: number, suffix?: string): string[] =>
	[...Array(end - start).keys()].map((num) => {
		const ordinalNumber = convertToOrdinalAbbr(num + start);
		return suffix ? `${ordinalNumber}${suffix}` : ordinalNumber;
	});

/**
 * Generates a key-value map from a given array
 * @param array array we want to use to generate the map
 * @param keyExtractor gets the key for the array element
 * @param valueExtractor gets the value for the array element
 */
export const arrayToCustomMap = (
	array: any[] | readonly any[],
	keyExtractor: (element: any) => string,
	valueExtractor: (element: any) => any
): { [k: string]: any } => {
	const map: { [k: string]: any } = {};
	array.forEach((element) => {
		map[keyExtractor(element)] = valueExtractor(element);
	});
	return map;
};

/**
 * Generate a map from a given array with specified key
 * @param arr array we want to use to generate the map
 * @param keyFn gets the key from the array element
 */
export const arrayToMap = <T, Key>(arr: T[], keyFn: (val: T) => Key): Map<Key, T> => {
	const map = new Map<Key, T>();
	arr.map((element) => map.set(keyFn(element), element));
	return map;
};

/**
 * Splits an array into two based on the predicate
 */
export const partition = <T>(array: T[], predicate: (val: T) => boolean): [T[], T[]] => {
	const pass: T[] = [];
	const fail: T[] = [];
	array.forEach((val) => {
		predicate(val) ? pass.push(val) : fail.push(val);
	});
	return [pass, fail];
};

/**
 * Returns the pluralized ending ('s') if array has multiple entries
 * @param arr array we want to check the length of
 */
export const getPluralizedEnding = (arr: any[]): string => getPluralizedEndingInner(arr.length);

/**
 * Replace non alpha numerics with a string
 * @param name string we want to replace non alpha numerics from
 * @param replaceTo string to replace to
 */
export const replaceNonAlphaNumerics = (name: string, replaceTo: string): string =>
	name.replace(/[^a-zA-Z\d\s:]/g, replaceTo);

/**
 * Generate an array with no duplicates
 * @param T the type of the array elements
 * @param arr array we want to remove duplicates
 */
export const getUniqueArray = <T>(arr: T[]): T[] => [...new Set(arr)];

/**
 * Flatten an array after calling callback function on every element
 * @param {any[]} arr array we want to validate and update
 * @param {function} callback called on each item from arr
 */
export const compactMap = <T, R>(arr: T[], callback: (x: T) => R | undefined): R[] =>
	_.compact(arr.map(callback));

/**
 * Returns whether or not a search url is valid
 * @param {string} searchUrl the search url to validate
 */
export const isSearchUrlValid = (searchUrl: string): boolean =>
	!!searchUrl && searchUrl.startsWith('https://www.linkedin.com/sales/search/people');

/**
 * Logs user out by clearing cookie and reloading page. User will be redirected to the login page.
 */
export function handleUserLogout(isB2CEnabled: boolean): void {
	document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	document.cookie = 'crm_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	if (appInsights?.ApplicationInsights)
		appInsights.ApplicationInsights.clearAuthenticatedUserContext();
	if (isB2CEnabled) {
		BaseDataManager.RequestManager.getB2CInstance()?.logoutRedirect();
	} else {
		window.location.reload();
	}
}

/**
 * Convert a percentage to rate
 * @param percent
 */
export function toRate(percent: number): number {
	return percent / 100;
}

/**
 * Throws with the message provided.
 * @param message
 */
export function throwError(message: string): never {
	throw new Error(message);
}

/**
 * Gets the first and only item within the array.
 * If the array is empty, throws.
 * If the array contains more than one element, also throws.
 * @param arr The array containing only one element.
 */
export function getExpectedOne<T>(arr: readonly T[]): T {
	if (arr.length === 0) {
		throw new Error('Expected 1 element in the array but is empty');
	} else if (arr.length > 1) {
		throw new Error(`There are more than 1 element in the array: ${JSON.stringify(arr)}`);
	} else {
		return arr[0];
	}
}

/**
 * Typeguard for JSON objects
 * @param x
 */
export function isPlainJsonObject(x: unknown): x is Record<string, unknown> {
	return isPlainObject(x);
}

/**
 * Determine if an error is a server error
 * @param err
 */
export function isServerError(err: unknown): err is ServerError {
	return err instanceof Error && 'statusCode' in err;
}

/**
 * A generic reducer for partial update for objects
 * @param state
 * @param action
 */
export function partialUpdateReducer<T>(state: T, action: Partial<T>) {
	return { ...state, ...action };
}
