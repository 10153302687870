import { CampaignSearchListTabActionTypes } from './const';

/**
 * Data that gets stored for a particular campaign in the campaign search list tab store object
 */
export type CampaignSearchListTabObjectType = {
	selectedCampaignMemberId?: string;
};

type UpdateOneCampaignSearchListTabAction = {
	type: CampaignSearchListTabActionTypes.UpdateOne;
	campaignId: string;
	payload: Partial<CampaignSearchListTabObjectType>;
};

type ResetOneCampaignSearchListTabAction = {
	type: CampaignSearchListTabActionTypes.ResetOne;
	campaignId: string;
};

type ResetAllCampaignSearchListTabAction = {
	type: CampaignSearchListTabActionTypes.ResetAll;
};

/**
 * Updates the selectedCampaignMemberId for the given campaign
 * @param {string} campaignId the id of the current campaign
 * @param {string | undefined} memberId the id of the selected campaign member
 */
export const updateSearchListSelectedMemberId = (
	campaignId: string,
	memberId: string | undefined
): UpdateOneCampaignSearchListTabAction => ({
	type: CampaignSearchListTabActionTypes.UpdateOne,
	campaignId,
	payload: {
		selectedCampaignMemberId: memberId,
	},
});

/**
 * Resets the campaign search list tab store object for the given campaign
 * @param {string} campaignId the id of the current campaign
 */
export const resetOneCampaignSearchListTab = (
	campaignId: string
): ResetOneCampaignSearchListTabAction => ({
	type: CampaignSearchListTabActionTypes.ResetOne,
	campaignId,
});

/**
 * Resets all the search list tab values
 */
export const resetAllCampaignSearchListTab = (): ResetAllCampaignSearchListTabAction => ({
	type: CampaignSearchListTabActionTypes.ResetAll,
});

export type CampaignSearchListTabAction =
	| UpdateOneCampaignSearchListTabAction
	| ResetOneCampaignSearchListTabAction
	| ResetAllCampaignSearchListTabAction;
