import React, { useState, useEffect, useMemo } from 'react';
import BasicContainer from '@copilot/common/components/containers/basic';
import { useFetch } from '@copilot/common/hooks/common';
import { OrganizationMemberManager } from '@copilot/data';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useSelector } from 'react-redux';
import { Config } from '@copilot/common/config';
import CalendlySnippet from '@copilot/common/components/snippets/calendly';
import { IOrganizationMember } from '@copilot/common/store/models/redux';

interface CampaignOnboardWizardBookingProps {
	campaignId: string;
	onBooking: () => void;
}
const CampaignOnboardWizardBooking = (props: CampaignOnboardWizardBookingProps) => {
	const activeMember: IOrganizationMember | null = useSelector(
		OrganizationMemberSelectors.getActiveMember
	);
	const [, fetchAdminMember] = useFetch(OrganizationMemberManager.getCSCalendlyUrl);

	const [calendlyUrl, setCalendlyUrl] = useState<string | undefined>();

	useEffect(() => {
		fetchAdminMember(activeMember?.id ?? '')
			.then((response: string) => {
				if (response) {
					setCalendlyUrl(response);
				} else {
					setCalendlyUrl(Config.csCalendlyURL);
				}
			})
			.catch(() => setCalendlyUrl(Config.csCalendlyURL));
	}, [activeMember?.id]);

	const header = useMemo(
		() => (
			<>
				<h1>Book a meeting</h1>
				<p>
					Book a meeting with your Account Strategist to review your search and messaging.
				</p>
			</>
		),
		[]
	);

	const calendlyComponent = React.useMemo(() => {
		if (!calendlyUrl) return null;
		return (
			<CalendlySnippet
				calendlyUrl={calendlyUrl}
				utmCampaignId={props.campaignId}
				utmSource="multiCampaign"
				onEventScheduled={props.onBooking}
				style={{ minWidth: '320px', height: '1200px' }}
				prefill={{
					name: `${activeMember?.firstName ?? ''} ${activeMember?.lastName ?? ''}`,
					email: activeMember?.email ?? '',
					customAnswers: {
						a2: 'Creating another campaign',
					},
				}}
			/>
		);
	}, [
		activeMember?.firstName,
		activeMember?.lastName,
		activeMember?.email,
		calendlyUrl,
		props.onBooking,
	]);

	return (
		<BasicContainer>
			<BasicContainer.Content>
				{header}
				{calendlyComponent}
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default CampaignOnboardWizardBooking;
