import { Button, ButtonProps } from 'antd';
import { isUndefined, merge } from 'lodash';
import { useTextEditorContext } from './context';

type TextEditorButtonProps = Readonly<{
	/** Callback for clicking */
	onClick?: (textValue: string) => void;
	/** Whether the button has a border */
	bordered?: boolean;
}> &
	Omit<ButtonProps, 'onClick'>;

const borderlessStyle = { border: 0, boxShadow: 'none' };

/**
 * Button component for the Text Editor
 */
export function TextEditorButton(props: TextEditorButtonProps): JSX.Element {
	const { onClick, bordered, style, ...rest } = props;
	const { disabled, text } = useTextEditorContext();
	const handleClick = () => onClick?.(text);
	const styles =
		isUndefined(bordered) || bordered === true ? style : merge(borderlessStyle, style);
	return (
		<Button style={styles} onClick={handleClick} type="text" disabled={disabled} {...rest}>
			{props.children}
		</Button>
	);
}
