import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/** date & time custom scalar type */
	DateTime: number;
	_Any: any;
	_FieldSet: any;
};

/** Account response */
export type IAccount = {
	__typename?: 'Account';
	/** Manager of the account - reseller admin or CS who manages the account */
	accountManager?: Maybe<IAccountManager>;
	/** Account's child accounts - relevant to master account */
	childAccounts: Array<IAccount>;
	/** Summary of child accounts that match optional Account Filters */
	childAccountsSummary?: Maybe<IChildAccountsSummary>;
	/** Credits information */
	credits: IAccountCredits;
	/** Account's creation date */
	dateCreated?: Maybe<Scalars['DateTime']>;
	/**
	 * The gooddata workspace ID for the account. Currently this will only be populated for master accounts that have a
	 * provisioned gooddata workspace.
	 */
	gdWorkspaceId?: Maybe<Scalars['String']>;
	/** Account's ID */
	id: Scalars['ID'];
	/** The activity status of the account */
	isActive: Scalars['Boolean'];
	/** The accounts set logo url */
	logoUrl?: Maybe<Scalars['String']>;
	/** Account's name */
	name: Scalars['String'];
	/** Information from deprecated Organization */
	orgInfo?: Maybe<IOrgInfo>;
	/**
	 * Parent master account to the account. May be undefined if user does not have access to view the account, or
	 * the account is the root level account.
	 */
	parentAccount?: Maybe<IAccount>;
	/** The primary contact of the account */
	primaryContact?: Maybe<IUser>;
	/** Account's type */
	type: IAccountType;
	/** Users of the account */
	users: Array<IUser>;
};

/** Account response */
export type IAccountChildAccountsArgs = {
	input: IAccountFilters;
	pagination: IPagination;
};

/** Account response */
export type IAccountChildAccountsSummaryArgs = {
	input?: InputMaybe<IAccountFilters>;
};

/** Credit limits for an account */
export type IAccountCredits = {
	__typename?: 'AccountCredits';
	/** Personalized Insights credits */
	personalizedInsights: Scalars['Int'];
	/** Smart Reply credits */
	smartReply: Scalars['Int'];
};

/** Possible filters for account query */
export type IAccountFilter = {
	/** ID of the account to fetch */
	id: Scalars['ID'];
};

/** Filters for the accounts query */
export type IAccountFilters = {
	/** Filter on the account manager assigned to account */
	accountManagerUserId?: InputMaybe<Scalars['ID']>;
	/** Filter that is used for fuzzy search on the account name */
	accountName?: InputMaybe<Scalars['String']>;
	/** If included filter by accounts that are either active or inactive */
	isActive?: InputMaybe<Scalars['Boolean']>;
	/** The master account of the accounts to fetch */
	masterAccountId?: InputMaybe<Scalars['String']>;
};

/** Account manager information */
export type IAccountManager = {
	__typename?: 'AccountManager';
	/** Account Manager's first name */
	firstName: Scalars['String'];
	/** Account Manager's Id */
	id: Scalars['ID'];
	/** Account Manager's last name */
	lastName: Scalars['String'];
};

/** Account type */
export enum IAccountType {
	/** Master Account - reseller account which has capability to add prospecting account for its customers */
	Master = 'MASTER',
	/** Regular Account - prospecting account */
	Regular = 'REGULAR',
}

/** Input for Activating users */
export type IActivateUsersInput = {
	/** Account users belong to, only single account be updated at a time */
	accountId: Scalars['ID'];
	/** users to activate */
	users: Array<Scalars['ID']>;
};

/** Input for adding an account */
export type IAddAccountInput = {
	/** Name of added account */
	accountName: Scalars['String'];
	/** Parent account of the added account */
	parentAccountId: Scalars['ID'];
};

/** Input for creating an organization */
export type IAddOrganizationInput = {
	/** Instance id of the organization, or null to create tenant under instance id of requestor */
	domainInstanceId?: InputMaybe<Scalars['ID']>;
	/** Monthly invites the organization is allowed to send, or null to use default of 2000 invites */
	monthlyInvites?: InputMaybe<Scalars['Int']>;
	/** Monthly messages the organization is allowed to send, or null to use default of 400 messages */
	monthlyMessages?: InputMaybe<Scalars['Int']>;
	/** Organization name */
	organizationName: Scalars['String'];
	/** Tenant id to create the organization under, or null to create organization under node tenant of requestor */
	tenantId?: InputMaybe<Scalars['ID']>;
};

export enum IAttentionLevel {
	/** The item requires immediate attention */
	Blocker = 'BLOCKER',
	/** The item requires attention */
	Warning = 'WARNING',
}

export type IBaseAttentionItem = {
	/** The attention item level */
	level: IAttentionLevel;
};

export type IBaseCampaignStats = {
	/**
	 * The end of the date range the stats were collected from.
	 * Null or undefined if there is no end to the date range.
	 */
	end?: Maybe<Scalars['DateTime']>;
	/** The number of interested replies from contacts in the target campaign */
	numInterestedReplies: Scalars['Int'];
	/** The number of meetings booked with LinkedIn users targeted by the campaign */
	numMeetingsBooked: Scalars['Int'];
	/** The number of replies from LinkedIn users targeted by the campaign. */
	numUserReplies: Scalars['Int'];
	/** The ID of the owner of the stats (i.e. the campaign member or campaign they are for) */
	ownerId: Scalars['ID'];
	/** The type of the owner of the campaign. */
	ownerType: ICampaignStatsOwnerType;
	/** The percentage of replies that were categorized as an interested reply */
	percentInterestedReplied?: Maybe<Scalars['Float']>;
	/** The percentage of users contacted by the who've returned a message. */
	percentReplied?: Maybe<Scalars['Float']>;
	/**
	 * The start of the date range the stats were collected from.
	 * Null or undefined if there is no start to the date range.
	 */
	start?: Maybe<Scalars['DateTime']>;
};

export enum IBlockedStatus {
	/** Is blocked */
	Blocked = 'BLOCKED',
	/** Is not blocked */
	Unblocked = 'UNBLOCKED',
	/** The blocked status is unknown */
	Unknown = 'UNKNOWN',
}

/** A campaign. */
export type ICampaign = {
	__typename?: 'Campaign';
	/** A list of items require attention */
	attentionItems: Array<ICampaignAttentionItem>;
	/** The ID of the campaign. */
	id: Scalars['ID'];
	/** A list of members in the campaign. */
	members: Array<ICampaignMember>;
	/** The name of the campaign. */
	name?: Maybe<Scalars['String']>;
	/** The number of active org members within the campaign. */
	numActiveMembers?: Maybe<Scalars['Int']>;
	/** The statistics describing the campaign's efforts. Start and end date are provided as milliseconds since the Unix Epoch. */
	stats?: Maybe<ICampaignStats>;
	/** The status of the campaign. */
	status?: Maybe<ICampaignStatus>;
	/** The type of the campaign. */
	type?: Maybe<ICampaignType>;
};

/** A campaign. */
export type ICampaignStatsArgs = {
	end?: InputMaybe<Scalars['DateTime']>;
	start?: InputMaybe<Scalars['DateTime']>;
};

/** A campaign attention item */
export type ICampaignAttentionItem = IBaseAttentionItem & {
	__typename?: 'CampaignAttentionItem';
	/** The attention item level */
	level: IAttentionLevel;
	/** The campaign member's ID */
	memberId: Scalars['ID'];
	/** The campaign attention item type */
	type: ICampaignAttentionType;
};

/** Campaign attention item's type */
export enum ICampaignAttentionType {
	/** The number of campaign connections to invite is below threshold */
	CampaignSearchListLow = 'CAMPAIGN_SEARCH_LIST_LOW',
	/** The daily allocated invites is not set for a prospecting campaign */
	NoInvitesSetForProspectingCampaign = 'NO_INVITES_SET_FOR_PROSPECTING_CAMPAIGN',
	/** The number of outbound messages is not set for a nurture campaign */
	NoMessagesSetForNurtureCampaign = 'NO_MESSAGES_SET_FOR_NURTURE_CAMPAIGN',
}

/** A campaign connection. */
export type ICampaignConnection = {
	__typename?: 'CampaignConnection';
	/** Whether if the connection is blocked */
	blockedStatus: IBlockedStatus;
	/** The history of the campaign connection in different campaigns. */
	campaignHistory: Array<ICampaignHistory>;
	/** The ID of the campaign the connection belongs to. */
	campaignId: Scalars['String'];
	/** Campaign member that the connection belongs to */
	campaignMember: ICampaignMember;
	/** The company of the connection */
	company?: Maybe<Scalars['String']>;
	/** Information from deprecated Org Contact */
	contactInfo?: Maybe<IContactInfo>;
	/** The date the connection was created */
	dateCreated: Scalars['DateTime'];
	/** The ID of the campaign connection. */
	id: Scalars['ID'];
	/** The linkedin invite url */
	inviteUrl: Scalars['String'];
	/** Whether or not the connection was manually terminated */
	isManualTermination?: Maybe<Scalars['Boolean']>;
	/** The location of the connection */
	location?: Maybe<Scalars['String']>;
	/** A meeting booked with the connection. */
	meeting: IMeetingContext;
	/** The name of the connection */
	name: Scalars['String'];
	/** The connection's next node in campaign */
	nextAutomationNode?: Maybe<INextAutomationNode>;
	/** The ID of the profile */
	profileId: Scalars['ID'];
	/** The reply prediction score */
	replyPrediction?: Maybe<Scalars['Float']>;
	/** The status of the connection */
	status: IConnectionStatus;
	/** Information from linkedin thread */
	threadInfo?: Maybe<IThreadInfo>;
	/** The job title of the connection */
	title?: Maybe<Scalars['String']>;
};

/** Input used for querying campaign connections */
export type ICampaignConnectionFilters = {
	/** Blocked Status */
	blockedStatus?: InputMaybe<Array<IBlockedStatus>>;
	/** Campaign member ids to filter connections by */
	campaignMemberIds?: InputMaybe<Array<Scalars['ID']>>;
	/** Whether or not to filter by open profile */
	isOpenProfile?: InputMaybe<Scalars['Boolean']>;
	/** text search to filter connections by */
	searchText?: InputMaybe<Scalars['String']>;
	/** Connection status to filter connections by. Currently only supports fetching one status */
	status?: InputMaybe<Array<IConnectionStatus>>;
};

/** Input used for querying campaign connections */
export type ICampaignConnectionsInput = {
	/** Contact id to filter connections by */
	contactId: Scalars['ID'];
};

/** Input used for querying campaign connections */
export type ICampaignConnectionsPaginatedInput = {
	/** Filters to apply when querying campaign connections */
	filters: ICampaignConnectionFilters;
	/** Pagination details */
	pagination: IPaginationInput;
	/** Sort details */
	sort: ISortInput;
};

/** A record of a campaign connection in a campaign. */
export type ICampaignHistory = {
	__typename?: 'CampaignHistory';
	/** The ID of the campaign. */
	campaignId: Scalars['ID'];
	/** The last known date at which the connection was included in the campaign. */
	end?: Maybe<Scalars['DateTime']>;
	/** The name of the campaign. */
	name: Scalars['String'];
	/** The first known date at which the connection was included in the campaign. */
	start: Scalars['DateTime'];
};

/** Input used for querying campaigns. */
export type ICampaignInput = {
	/** Query for campaigns for specific org */
	orgId?: InputMaybe<Scalars['ID']>;
	/** Query for campaign the user is a part of */
	orgMemberId?: InputMaybe<Scalars['ID']>;
};

/** A campaign member. */
export type ICampaignMember = {
	__typename?: 'CampaignMember';
	/** The number of invites the member has allocated */
	dailyInvitesAllocated: Scalars['Int'];
	/** The number of messages the number has allocated */
	dailyMessagesAllocated: Scalars['Int'];
	/** The number of daily Open InMail the member has allocated */
	dailyOpenInMailAllocated: Scalars['Int'];
	/** The campaign member's ID. */
	id: Scalars['ID'];
	/** The ID of the member within the organization. */
	orgMemberId: Scalars['ID'];
};

/** The input for a campaign member events query. */
export type ICampaignMemberEventsInput = {
	/** The campaign member ID to query for. */
	campaignMemberId: Scalars['ID'];
	/** The last day of the time frame to query. Should provided alongside numDays. */
	end?: InputMaybe<Scalars['DateTime']>;
	/** Length of the time frame to query for. Should be provided alongside end. */
	numDays?: InputMaybe<Scalars['Int']>;
};

export type ICampaignStats = INurtureCampaignStats | IProspectingCampaignStats;

/** Possible filters to use when querying for Campaign Stats. */
export type ICampaignStatsFilters = {
	/** The campaign IDs of the requested stats. Only one of CampaignIds or CampaignMemberIds or OrganizationFilters should be used in a single query. */
	campaignIds?: InputMaybe<Array<Scalars['ID']>>;
	/** The campaign member IDs of the requested stats. Only one of CampaignIds or CampaignMemberIds or OrganizationFilters should be used in a single query. */
	campaignMemberIds?: InputMaybe<Array<Scalars['ID']>>;
	/** Stats of campaign types to be fetched if specified. */
	campaignTypes?: InputMaybe<Array<ICampaignType>>;
	/** Specifies the latest date to aggregate stats from. */
	end?: InputMaybe<Scalars['DateTime']>;
	/** Interval group stats by. */
	interval?: InputMaybe<ITimeInterval>;
	/** The organization filter of the requested stats. Only one of CampaignIds or CampaignMemberIds or OrganizationFilters should be used in a single query. */
	organizationFilters?: InputMaybe<Array<IOrganizationFilter>>;
	/** Specifies the earliest date to aggregate stats from. */
	start?: InputMaybe<Scalars['DateTime']>;
};

/** Input used for querying campaign stats. */
export type ICampaignStatsInput = {
	/** Filters to apply when querying campaign stats. */
	filter?: InputMaybe<ICampaignStatsFilters>;
};

export enum ICampaignStatsOwnerType {
	/** Campaign stats of a campaign. */
	Campaign = 'CAMPAIGN',
	/** Campaign stats of a campaign member. */
	CampaignMember = 'CAMPAIGN_MEMBER',
	/** Aggregated campaign stats of an organization */
	Organization = 'ORGANIZATION',
	/** Aggregated campaign stats of an organization member */
	OrganizationMember = 'ORGANIZATION_MEMBER',
}

/** The different statuses of a campaign. */
export enum ICampaignStatus {
	/** The type of a disabled campaign. */
	Disabled = 'DISABLED',
	/** The type of an active campaign. */
	Enabled = 'ENABLED',
	/** The type of an UNKNOWN campaign. */
	Unknown = 'UNKNOWN',
}

/** Input for the desired status for a campaign */
export enum ICampaignStatusInput {
	/** The value used to set a campaign to disabled. */
	Disabled = 'DISABLED',
	/** The value used to set a campaign to enabled. */
	Enabled = 'ENABLED',
}

/** The different types of campaigns. */
export enum ICampaignType {
	/** The type of a LinkedIn nurture campaign. */
	Nurture = 'NURTURE',
	/** The type of a LinkedIn prospecting campaign. */
	Prospecting = 'PROSPECTING',
}

/** A usage event within the Campaign domain. */
export type ICampaignUsage = {
	__typename?: 'CampaignUsage';
	/** The action causing the usage. */
	action: ICampaignUsageAction;
	/** The ID of the campaign the event pertained to. */
	campaignId: Scalars['ID'];
	/** The ID of the campaign member the event pertained to. */
	campaignMemberId?: Maybe<Scalars['ID']>;
	/** The date & time the usage occurred on. */
	dateCreated: Scalars['DateTime'];
};

/** The different types of actions that can occur within the campaign domain. */
export enum ICampaignUsageAction {
	/** An update to a campaign's messaging. */
	MessagingChange = 'MESSAGING_CHANGE',
	/** An update to a campaign's search list and messaging */
	SearchListAndMessagingChange = 'SEARCH_LIST_AND_MESSAGING_CHANGE',
	/** An update to a campaign's search list. */
	SearchListChange = 'SEARCH_LIST_CHANGE',
}

export type IChatCompletionChoice = {
	__typename?: 'ChatCompletionChoice';
	/** The chat completed message generated by ChatGPT. */
	message: IChatCompletionMessage;
};

export type IChatCompletionInput = {
	/** The penalty for repeated tokens in the generated text, between -2.0 and 2.0. */
	frequencyPenalty?: InputMaybe<Scalars['Float']>;
	/** The maximum number of tokens to generate. */
	maxLength?: InputMaybe<Scalars['Int']>;
	/** The messages to complete. */
	messages: Array<IChatCompletionMessageInput>;
	/** The penalty for tokens that are present in the generated text, between -2.0 and 2.0. */
	presencePenalty?: InputMaybe<Scalars['Float']>;
	/** The expected format to generate the response in */
	responseFormat?: InputMaybe<IChatCompletionResponseFormatInput>;
	/** The randomness of the generated text, between 0.0 and 2.0. */
	temperature?: InputMaybe<Scalars['Float']>;
	/** The diversity of the generated text. */
	topP?: InputMaybe<Scalars['Float']>;
};

export type IChatCompletionMessage = {
	__typename?: 'ChatCompletionMessage';
	/** The text of the message. */
	content: Scalars['String'];
	/** The role of the message. */
	role: IChatCompletionMessageRole;
};

export type IChatCompletionMessageInput = {
	/** The text of the message. */
	content: Scalars['String'];
	/** The role of the message. */
	role: IChatCompletionMessageRole;
};

export enum IChatCompletionMessageRole {
	/** The author of the message is the assistant. */
	Assistant = 'ASSISTANT',
	/** The author of the message is the system. */
	System = 'SYSTEM',
	/** The author of the message is the user. */
	User = 'USER',
}

export type IChatCompletionOrModerationResult = IChatCompletionResult | IModerationResult;

/** Response format */
export enum IChatCompletionResponseFormat {
	/** JSON Object response */
	JsonObject = 'json_object',
	/** Regular text response */
	Text = 'text',
}

/** Chat Completion response type */
export type IChatCompletionResponseFormatInput = {
	/** The type of response we're expecting */
	type: IChatCompletionResponseFormat;
};

export type IChatCompletionResult = {
	__typename?: 'ChatCompletionResult';
	/** A list of chat completed responses by ChatGPT. */
	choices: Array<IChatCompletionChoice>;
};

export type IChildAccountsSummary = {
	__typename?: 'ChildAccountsSummary';
	/** Total child accounts, includes all types of accounts */
	totalAccounts: Scalars['Int'];
	/** Total active users in child accounts */
	totalActiveUsers: Scalars['Int'];
	/** Total users in child accounts */
	totalUsers: Scalars['Int'];
};

export enum IConnectionStatus {
	AlreadyPending = 'ALREADY_PENDING',
	CampaignError = 'CAMPAIGN_ERROR',
	Connected = 'CONNECTED',
	InviteError = 'INVITE_ERROR',
	InviteSent = 'INVITE_SENT',
	ManualOverride = 'MANUAL_OVERRIDE',
	OutOfNetwork = 'OUT_OF_NETWORK',
	Rejected = 'REJECTED',
	Stranger = 'STRANGER',
	Unknown = 'UNKNOWN',
	UserRejected = 'USER_REJECTED',
	Withdrawn = 'WITHDRAWN',
}

/** Input used for querying campaign connections */
export type IConnectionsByBlockedStatusInput = {
	/** Is Blocked Status */
	blockedStatus: IBlockedStatus;
	/** Campaign member ids to filter connections by */
	campaignMemberIds: Array<Scalars['ID']>;
	/** Pagination details */
	pagination: IPaginationInput;
	/** text search to filter connections by */
	searchText?: InputMaybe<Scalars['String']>;
};

/** Input used for querying campaign connections */
export type IConnectionsByConnectionStatusInput = {
	/** Campaign member ids to filter connections by */
	campaignMemberIds: Array<Scalars['ID']>;
	/** Whether or not to filter by open profile */
	isOpenProfile?: InputMaybe<Scalars['Boolean']>;
	/** Pagination details */
	pagination: IPaginationInput;
	/** text search to filter connections by */
	searchText?: InputMaybe<Scalars['String']>;
	/** Connection status to filter connections by. Currently only supports fetching one status */
	status: Array<IConnectionStatus>;
};

/** Input for querying connections by meeting status. */
export type IConnectionsByMeetingsInput = {
	/** IDs of campaigns the connection must be a part of */
	campaigns?: InputMaybe<Array<Scalars['ID']>>;
	/** Meeting statuses to match with */
	meeting: IMeetingInput;
	/** IDs of the org members the connection must be matched with */
	members?: InputMaybe<Array<Scalars['ID']>>;
	/** Pagination details */
	pagination: IPaginationInput;
};

/**
 * Input for querying connections by meeting user.
 * If no query filter is provided, results to all connections accessible by the user.
 */
export type IConnectionsByUserInput = {
	/** Pagination details */
	pagination: IPaginationInput;
};

/** Connections response with pagination context */
export type IConnectionsResponse = {
	__typename?: 'ConnectionsResponse';
	/** Connection results in the page */
	content: Array<ICampaignConnection>;
	/** Count of connections matching query */
	count: Scalars['Int'];
	/** Pagination context */
	pages: IPaginationContext;
};

/** ContactInfo to be embedded in Connection to surface OrgContact information that is still required */
export type IContactInfo = {
	__typename?: 'ContactInfo';
	/** Contact's Id */
	id: Scalars['ID'];
};

/** Credits allocation for an organization */
export type ICredits = {
	__typename?: 'Credits';
	smartReply: Scalars['Int'];
};

/** Input for deactivating users */
export type IDeactivateUsersInput = {
	/** Account users belong to, only single account be updated at a time */
	accountId: Scalars['ID'];
	/** users to deactivate */
	users: Array<Scalars['ID']>;
};

/** Input for the editMessage query */
export type IEditMessageInput = {
	/** Instructions on how to edit the message */
	instruction: IEditMessageInstruction;
	/** The message to be edited */
	message: Scalars['String'];
};

/** Instruction for how to edit a message */
export type IEditMessageInstruction = {
	/** List of instructions on how to edit the message */
	instructions: Array<Scalars['String']>;
};

/** Response generated from the editMessage query */
export type IEditMessageResponse = IMessageResult | IModerationResult;

/** Input for generating an edited message with Smart Reply */
export type IEditSmartReplyInput = {
	/** The message to enhance */
	message: Scalars['String'];
	/** Prompt to use to enhance the provided message */
	prompt: ISmartReplyPrompt;
};

/** An attention item for a user */
export type IIssue = IBaseAttentionItem & {
	__typename?: 'Issue';
	/** The priority of the issue */
	level: IAttentionLevel;
	/** The type of issue */
	type: IOrgMemberAttentionType;
};

/** A dictionary entry for keys to values */
export type IKeyValueInput = {
	/** The key of the entry */
	key: Scalars['String'];
	/** The value of the entry */
	value: Scalars['String'];
};

/** The DTO for a LinkedIn auth request */
export type ILinkedInAuthDto = {
	__typename?: 'LinkedInAuthDTO';
	/** The URL the user should be redirected to complete authentication */
	url: Scalars['String'];
};

/** The DTO for a LinkedIn auth error */
export type ILinkedInAuthError = {
	__typename?: 'LinkedInAuthError';
	/** The type of error experienced */
	type: ILinkedInAuthErrors;
};

/** Errors that can be encountered during LinkedIn authentication */
export enum ILinkedInAuthErrors {
	/** Authorization is already under way, please wait for it to complete */
	InProgress = 'IN_PROGRESS',
	/** An unexpected error was encountered */
	UnexpectedError = 'UNEXPECTED_ERROR',
}

/** Parameters required for initiate LinkedIn authentication */
export type ILinkedInAuthInput = {
	/** The URL to redirect to after a failed LinkedIn authentication */
	failureRedirectUrl: Scalars['String'];
	/** Org Member's ID */
	orgMemberId: Scalars['ID'];
	/** The URL to redirect to after a successful LinkedIn authentication */
	successRedirectUrl: Scalars['String'];
};

/** The response for a LinkedIn auth request */
export type ILinkedInAuthResponse = ILinkedInAuthDto | ILinkedInAuthError;

/** LinkedIn errors that can be exhibited by organization members */
export type ILinkedInErrors = {
	__typename?: 'LinkedInErrors';
	/** Member is blacklisted on linkedIn */
	blackListed: Scalars['Boolean'];
	/** Member has failed messages in the outbox */
	hasFailedMessages: Scalars['Boolean'];
	/** Member has their invitation allowance throttled */
	invitesThrottled: Scalars['Boolean'];
	/** Member is logged out of linkedIn */
	loggedOut: Scalars['Boolean'];
	/** Member does not have a sales navigator subscription */
	salesNavMissing: Scalars['Boolean'];
};

/** A meeting with a campaign connection. */
export type IMeetingContext = {
	__typename?: 'MeetingContext';
	/**
	 * Whether or not the meeting is currently booked.
	 * @deprecated booked is deprecated. Use bookedDate instead
	 */
	booked: Scalars['Boolean'];
	/** The date meeting is booked */
	bookedDate?: Maybe<Scalars['DateTime']>;
	/** The date meeting is detected */
	detectedDate?: Maybe<Scalars['DateTime']>;
	/** The status of meeting booked detection (undefined, true, false) */
	isDetectionAccurate?: Maybe<Scalars['Boolean']>;
};

/** Input used for matching meetings */
export type IMeetingInput = {
	/** Specifies the latest time of meetings queried */
	end?: InputMaybe<Scalars['DateTime']>;
	/** Specifies the earliest time of meetings queried */
	start?: InputMaybe<Scalars['DateTime']>;
	/** The status that should be searched for */
	status?: InputMaybe<IMeetingStatus>;
};

/** Input for desired status for a meeting */
export enum IMeetingStatus {
	/** A meeting is booked */
	Booked = 'BOOKED',
	/** A meeting is detected */
	Detected = 'DETECTED',
	/** A meeting is neither booked nor detected */
	None = 'NONE',
}

/** Input describing a message between two */
export type IMessageInput = {
	/** The message */
	message: Scalars['String'];
	/** Boolean indicating if the message was outbound (sent to the prospect) or inbound (received from the prospect) */
	outbound: Scalars['Boolean'];
};

/** A response with a generated message */
export type IMessageResult = {
	__typename?: 'MessageResult';
	/** The generated message */
	message: Scalars['String'];
};

export type IModerationCategories = {
	__typename?: 'ModerationCategories';
	/** Whether the text contains hateful language */
	hate: Scalars['Boolean'];
	/** Whether the text contains threatening language */
	hateThreatening: Scalars['Boolean'];
	/** Whether the text contains self-hard language */
	selfHarm: Scalars['Boolean'];
	/** Whether the text contains sexual language */
	sexual: Scalars['Boolean'];
	/** Whether the text contains sexual language related to minors */
	sexualMinors: Scalars['Boolean'];
	/** Whether the text contains violent language */
	violence: Scalars['Boolean'];
	/** Whether the text contains violent language that is graphic */
	violenceGraphic: Scalars['Boolean'];
};

export type IModerationResult = {
	__typename?: 'ModerationResult';
	/** The categories that the text is flagged for */
	categories: IModerationCategories;
	/** Whether the text is flagged for moderation */
	flagged: Scalars['Boolean'];
};

export type IMutation = {
	__typename?: 'Mutation';
	/** Activate a regular account */
	activateRegularAccount: IAccount;
	/** Activate a set of users for a single account */
	activateUsers: Array<IUser>;
	/** Create an account */
	addAccount: IAccount;
	/** Create an organization */
	addOrganization: IOrganization;
	/**
	 * Books a meeting with a campaign connection. Deprecated in favour of updateConnectionMeeting.
	 * @deprecated Use 'updateConnectionMeeting'
	 */
	bookMeeting: ICampaignConnection;
	/** Deactivate a regular account */
	deactivateRegularAccount: IAccount;
	/** Deactivate a set of users for a single account */
	deactivateUsers: Array<IUser>;
	/** Kicks off the LinkedIn authentication process by generating a Hosted Auth URL */
	generateLinkedInAuth: ILinkedInAuthResponse;
	/** Rejects meeting booked detection in the meeting context of a campaign connection */
	rejectMeetingDetection: ICampaignConnection;
	/** stop automation for a campaign connection */
	stopAutomation: ICampaignConnection;
	/** Records a messaging change for the provided campaign. */
	trackMessagingChange: ICampaignUsage;
	/** Records a search list update for the provided campaign member. */
	trackSearchListUpdate: ICampaignUsage;
	/** Update account owner by account id */
	updateAccountManagerByAccountId: IAccount;
	/** Update an accounts name */
	updateAccountName: IAccount;
	/** Update the status of a campaign. */
	updateCampaignStatus: ICampaign;
	/** Updates the meeting context of a campaign connection. */
	updateConnectionMeeting: ICampaignConnection;
	/** Update OrgContact's company */
	updateOrgContactCompany: IOrgContact;
	/** Update OrgContact's email address */
	updateOrgContactEmail: IOrgContact;
	/** Update OrgContact's phone number */
	updateOrgContactPhoneNumber: IOrgContact;
	/** Update OrgContact's title */
	updateOrgContactTitle: IOrgContact;
	/** Update a tenant */
	updateTenant: ITenant;
	/** Update a users given names */
	updateUserGivenNames: IUser;
};

export type IMutationActivateRegularAccountArgs = {
	accountId: Scalars['ID'];
};

export type IMutationActivateUsersArgs = {
	input: IActivateUsersInput;
};

export type IMutationAddAccountArgs = {
	input: IAddAccountInput;
};

export type IMutationAddOrganizationArgs = {
	input: IAddOrganizationInput;
};

export type IMutationBookMeetingArgs = {
	campaignConnectionId: Scalars['ID'];
};

export type IMutationDeactivateRegularAccountArgs = {
	accountId: Scalars['ID'];
};

export type IMutationDeactivateUsersArgs = {
	input: IDeactivateUsersInput;
};

export type IMutationGenerateLinkedInAuthArgs = {
	input: ILinkedInAuthInput;
};

export type IMutationRejectMeetingDetectionArgs = {
	campaignConnectionId: Scalars['ID'];
};

export type IMutationStopAutomationArgs = {
	campaignConnectionId: Scalars['ID'];
};

export type IMutationTrackMessagingChangeArgs = {
	campaignId: Scalars['ID'];
	campaignMemberId?: InputMaybe<Scalars['ID']>;
};

export type IMutationTrackSearchListUpdateArgs = {
	campaignId: Scalars['ID'];
	campaignMemberId: Scalars['ID'];
};

export type IMutationUpdateAccountManagerByAccountIdArgs = {
	input: IUpdateAccountManagerByAccountIdInput;
};

export type IMutationUpdateAccountNameArgs = {
	input: IUpdateAccountNameInput;
};

export type IMutationUpdateCampaignStatusArgs = {
	campaignId: Scalars['ID'];
	status: ICampaignStatusInput;
};

export type IMutationUpdateConnectionMeetingArgs = {
	campaignConnectionId: Scalars['ID'];
	isBooked: Scalars['Boolean'];
};

export type IMutationUpdateOrgContactCompanyArgs = {
	company: Scalars['String'];
	orgContactId: Scalars['ID'];
};

export type IMutationUpdateOrgContactEmailArgs = {
	email: Scalars['String'];
	orgContactId: Scalars['ID'];
};

export type IMutationUpdateOrgContactPhoneNumberArgs = {
	orgContactId: Scalars['ID'];
	phoneNumber: Scalars['String'];
};

export type IMutationUpdateOrgContactTitleArgs = {
	orgContactId: Scalars['ID'];
	title: Scalars['String'];
};

export type IMutationUpdateTenantArgs = {
	input: IUpdateTenantInput;
};

export type IMutationUpdateUserGivenNamesArgs = {
	input: IUpdateUserGivenNamesInput;
};

/** Next node in the campaign automation sequence */
export type INextAutomationNode = {
	__typename?: 'NextAutomationNode';
	/** Id of automation */
	id: Scalars['ID'];
	/** The node's message, should it exist */
	message?: Maybe<Scalars['String']>;
	/** Name of automation node */
	name: Scalars['String'];
};

export type INurtureCampaignStats = IBaseCampaignStats & {
	__typename?: 'NurtureCampaignStats';
	/**
	 * The end of the date range the stats were collected from.
	 * Null or undefined if there is no end to the date range.
	 */
	end?: Maybe<Scalars['DateTime']>;
	/** The number of interested replies from contacts in the target campaign */
	numInterestedReplies: Scalars['Int'];
	/** The number of meetings booked with LinkedIn users targeted by the campaign */
	numMeetingsBooked: Scalars['Int'];
	/** The number of messages sent out by the campaign. */
	numSendMessages: Scalars['Int'];
	/** The number of replies from LinkedIn users targeted by the campaign. */
	numUserReplies: Scalars['Int'];
	/** The ID of the owner of the stats (i.e. the campaign member or campaign they are for) */
	ownerId: Scalars['ID'];
	/** The type of the owner of the campaign. */
	ownerType: ICampaignStatsOwnerType;
	/** The percentage of replies that were categorized as an interested reply */
	percentInterestedReplied?: Maybe<Scalars['Float']>;
	/** The percentage of users contacted by the who've returned a message. */
	percentReplied?: Maybe<Scalars['Float']>;
	/**
	 * The start of the date range the stats were collected from.
	 * Null or undefined if there is no start to the date range.
	 */
	start?: Maybe<Scalars['DateTime']>;
};

/** Org Contact Information */
export type IOrgContact = {
	__typename?: 'OrgContact';
	/**
	 * Company of the organization contact.
	 * Empty string if information is unknown.
	 */
	company: Scalars['String'];
	/**
	 * Email of the organization contact.
	 * Empty string if information is unknown.
	 */
	email: Scalars['String'];
	/** First name of the organization contact */
	firstName: Scalars['String'];
	/** Org Contact's ID */
	id: Scalars['ID'];
	/** Last name of the organization contact */
	lastName: Scalars['String'];
	/** ID of the organization the contact belongs to */
	organizationId: Scalars['ID'];
	/**
	 * Phone number of the organization contact.
	 * Empty string if information is unknown.
	 */
	phoneNumber: Scalars['String'];
	/**
	 * Title of the organization contact.
	 * Empty string if information is unknown.
	 */
	title: Scalars['String'];
};

/** Possible filters for orgContacts query */
export type IOrgContactsFilters = {
	/** Specific org contacts to get */
	orgContactIds: Array<Scalars['ID']>;
};

/** Possible filters for organization query */
export type IOrgFilters = {
	/** Whether to include individual organizations in the query */
	includeIndividuals?: InputMaybe<Scalars['Boolean']>;
	/** Specific organizations to get by tenant ids */
	tenantIds: Array<Scalars['ID']>;
};

/** OrgInfo to be embedded in Account to surface Organization information still required */
export type IOrgInfo = {
	__typename?: 'OrgInfo';
	/** Organization's ID */
	id: Scalars['ID'];
};

/** Org Member Information */
export type IOrgMember = {
	__typename?: 'OrgMember';
	/** Attention items associated with the org member */
	attentionItems: Array<IOrgMemberAttentionItem>;
	/** Org Member's ID */
	id: Scalars['ID'];
	/** Whether the organization member is active */
	isActive: Scalars['Boolean'];
	/** LinkedIn errors exhibited by the org member */
	linkedInErrors: ILinkedInErrors;
	/** ID of the organization the member belongs to */
	organizationId: Scalars['ID'];
	/** Org member's role */
	role: IOrganizationMemberRole;
	/** Org Member's  user */
	user: IUser;
	/** Org Member's user id */
	userId: Scalars['ID'];
};

/** Organization Member Attention item */
export type IOrgMemberAttentionItem = IBaseAttentionItem & {
	__typename?: 'OrgMemberAttentionItem';
	/** The attention item level */
	level: IAttentionLevel;
	/** The org member's ID */
	memberId: Scalars['ID'];
	/** The campaign attention item type */
	type: IOrgMemberAttentionType;
};

/** Org Member attention item's type */
export enum IOrgMemberAttentionType {
	/** Member is logged out of LinkedIn */
	LoggedOutOfLinkedin = 'LOGGED_OUT_OF_LINKEDIN',
	/** Member is missing sales nav connection */
	SalesNavMissing = 'SALES_NAV_MISSING',
}

/** OrgMemberInfo to be embedded in User to surface OrgMember information still required */
export type IOrgMemberInfo = {
	__typename?: 'OrgMemberInfo';
	/** Org Member's ID */
	id: Scalars['ID'];
	/** Org Member's role */
	role: IOrganizationMemberRole;
};

/** Possible filters for orgMembers query. All filters are ANDed if specified. */
export type IOrgMembersFilters = {
	/** Specific Org Members that belong in the provided organizations to be retrieved */
	orgMemberIds?: InputMaybe<Array<Scalars['ID']>>;
	/** Organization IDs of the org members to be retrieved */
	organizationIds: Array<Scalars['ID']>;
};

/** Organization Information (to be deprecated) */
export type IOrganization = {
	__typename?: 'Organization';
	/** Organization's account manager */
	accountManager?: Maybe<IAccountManager>;
	/** Organization's credit allocation */
	credits: ICredits;
	/**
	 * Organization's customer success owner
	 * @deprecated Use `accountManager`
	 */
	customerSuccessOwner?: Maybe<IUser>;
	/** Organization's ID */
	id: Scalars['ID'];
	/** Organization's members */
	members: Array<IOrgMember>;
	/** Organization's name */
	name: Scalars['String'];
	/** Organization's Tenant Id */
	tenantId: Scalars['ID'];
};

export type IOrganizationFilter = {
	/** Organization Ids */
	id: Scalars['ID'];
	/** Sepcific org members in the organization */
	memberIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** Possible filters for the organizations query */
export type IOrganizationFilters = {
	/** IDs of the organizations to fetch */
	organizationIds: Array<Scalars['ID']>;
};

export enum IOrganizationMemberRole {
	/** Admin role */
	Admin = 'ADMIN',
	/** Advanced user role */
	Advanced = 'ADVANCED',
	/** Basic role */
	Basic = 'BASIC',
}

/** Defines pagination options for a given query, currently only includes offset based pagination */
export type IPagination = {
	/** The (0 based) page index of results to show */
	pageIndex: Scalars['Int'];
	/** Max number of entities to include in a page */
	pageSize: Scalars['Int'];
};

/** Pagination context */
export type IPaginationContext = {
	__typename?: 'PaginationContext';
	/** The current page */
	current: Scalars['Int'];
	/** The total number of pages */
	total: Scalars['Int'];
};

/** Input used to define pagination details */
export type IPaginationInput = {
	/** Starting page of the results queried */
	page?: InputMaybe<Scalars['Int']>;
	/** Number of items queried */
	pageSize?: InputMaybe<Scalars['Int']>;
};

/** Prompt Information */
export type IPrompt = {
	__typename?: 'Prompt';
	/** The actions the prompt applies to */
	action: IPromptActions;
	/** Prompt ID */
	id: Scalars['ID'];
	/** Label of the prompt */
	label: Scalars['String'];
	/** The use case the prompt applies to */
	useCases?: Maybe<Array<IPromptUseCases>>;
};

/** Actions a prompt may apply to */
export enum IPromptActions {
	/** Action for editing an existing message */
	Edit = 'EDIT',
	/** Action for generating a new message */
	Write = 'WRITE',
}

/** Use cases a prompt may be intended for */
export enum IPromptUseCases {
	/** Use case for prompts intended to follow up with unresponsive prospects */
	FollowUp = 'FOLLOW_UP',
	/**
	 * Use case for prompts intended to push the conversation forward with
	 * interested prospects
	 */
	Interested = 'INTERESTED',
	/**
	 * Use case for prompts intended to politely close the conversation with a
	 * prospect who is not interested
	 */
	NotInterested = 'NOT_INTERESTED',
}

export type IProspectingCampaignStats = IBaseCampaignStats & {
	__typename?: 'ProspectingCampaignStats';
	/**
	 * The end of the date range the stats were collected from.
	 * Null or undefined if there is no end to the date range.
	 */
	end?: Maybe<Scalars['DateTime']>;
	/** The number of replies to an InMail */
	numInMailReplies: Scalars['Int'];
	/** The number of InMail sent */
	numInMailSent: Scalars['Int'];
	/** The number of interested replies to an InMail */
	numInterestedInMailReplies: Scalars['Int'];
	/** The number of interested replies from contacts in the target campaign */
	numInterestedReplies: Scalars['Int'];
	/** The number of invites sent out through the campaign. */
	numInvitesSent: Scalars['Int'];
	/** The number of meetings booked with LinkedIn users targeted by the campaign */
	numMeetingsBooked: Scalars['Int'];
	/** The number of replies from LinkedIn users targeted by the campaign. */
	numUserReplies: Scalars['Int'];
	/** The number of LinkedIn users connected to by the campaign. */
	numUsersConnected: Scalars['Int'];
	/** The ID of the owner of the stats (i.e. the campaign member or campaign they are for) */
	ownerId: Scalars['ID'];
	/** The type of the owner of the campaign. */
	ownerType: ICampaignStatsOwnerType;
	/** The percentage of users contacted by the campaign who accepted a connection request. */
	percentConnected?: Maybe<Scalars['Float']>;
	/** The percentage of users contacted by InMail who've returned a message */
	percentInMailReplied?: Maybe<Scalars['Float']>;
	/** The percentage of InMail replies that were categorized as an interested reply */
	percentInterestedInMailReplied?: Maybe<Scalars['Float']>;
	/** The percentage of replies that were categorized as an interested reply */
	percentInterestedReplied?: Maybe<Scalars['Float']>;
	/** The percentage of users contacted by the who've returned a message. */
	percentReplied?: Maybe<Scalars['Float']>;
	/**
	 * The start of the date range the stats were collected from.
	 * Null or undefined if there is no start to the date range.
	 */
	start?: Maybe<Scalars['DateTime']>;
};

export type IQuery = {
	__typename?: 'Query';
	_service: I_Service;
	/** Query for accounts by the given filters */
	account: IAccount;
	/** Query for getting account by id */
	admin_account: IAccount;
	/** Query for accounts by the given filters */
	admin_accounts: Array<IAccount>;
	/** Query for getting the count of accounts by the given filters */
	admin_num_accounts: Scalars['Int'];
	/** Query for organizations by the given filters */
	admin_organizations: Array<IOrganization>;
	/** Query for tenants by the given filters */
	admin_tenants: Array<ITenant>;
	/** Query for users by the given filters */
	admin_users: Array<IUser>;
	/**
	 * Query for campaign connections
	 * @deprecated Use 'campaignConnectionsPaginated'
	 */
	campaignConnections: Array<ICampaignConnection>;
	/**
	 * Query for campaign connections by connection status
	 * @deprecated Use 'campaignConnectionsPaginated'
	 */
	campaignConnectionsByBlockedStatus: IConnectionsResponse;
	/**
	 * Query for campaign connections by connection status
	 * @deprecated Use 'campaignConnectionsPaginated'
	 */
	campaignConnectionsByConnectionStatus: IConnectionsResponse;
	/** Query for detected or booked meetings */
	campaignConnectionsByMeetings: IConnectionsResponse;
	/**
	 * Query for connections accessible by the authenticated user
	 * @deprecated Use 'campaignConnectionsByMeetings'
	 */
	campaignConnectionsByUser: IConnectionsResponse;
	/** Query for campaign connections paginated */
	campaignConnectionsPaginated: IConnectionsResponse;
	/** Query for events pertaining to a campaign member. */
	campaignMemberEvents: Array<ICampaignUsage>;
	/** Query for a campaign's stats between the start and end date grouped by the provided interval. */
	campaignStats: Array<ICampaignStats>;
	/**
	 * Query for all campaigns, optionally filtering by a search term.
	 * @deprecated campaignSummaries is deprecated. Use campaigns instead.
	 */
	campaignSummaries: Array<ICampaign>;
	/** Query for campaigns by campaign id */
	campaignSummary: ICampaign;
	/** Query for campaigns */
	campaigns: Array<ICampaign>;
	/** Query for chat completion given messages and optional parameters. */
	chatCompletion: IChatCompletionOrModerationResult;
	/** Query for editing a message. */
	editMessage: IEditMessageResponse;
	/** Enhance a message with Smart Reply */
	editSmartReply: ISmartReplyResponse;
	/** Query for org contacts by the given filters */
	orgContacts: Array<IOrgContact>;
	/** Query for org members by the given filters */
	orgMembers: Array<IOrgMember>;
	/** Query for organizations by the given filters */
	organizations: Array<IOrganization>;
	/** Get all Smart Reply Prompts */
	prompts: Array<IPrompt>;
	/** Query for generating text based off a prompt label and values to replace in that prompt */
	secureMessage: ISecureMessageResponse;
	/** Query for text completion given a prompt and optional parameters. */
	textCompletion: ITextCompletionOrModerationResult;
	/** Write a Smart Reply */
	writeSmartReply: ISmartReplyResponse;
};

export type IQueryAccountArgs = {
	input: IAccountFilter;
};

export type IQueryAdmin_AccountArgs = {
	id: Scalars['ID'];
};

export type IQueryAdmin_AccountsArgs = {
	input: IAccountFilters;
	pagination?: InputMaybe<IPagination>;
};

export type IQueryAdmin_Num_AccountsArgs = {
	input: IAccountFilters;
};

export type IQueryAdmin_OrganizationsArgs = {
	input: IOrgFilters;
};

export type IQueryAdmin_TenantsArgs = {
	input: ITenantFilters;
};

export type IQueryAdmin_UsersArgs = {
	input: IUserFilters;
};

export type IQueryCampaignConnectionsArgs = {
	input: ICampaignConnectionsInput;
};

export type IQueryCampaignConnectionsByBlockedStatusArgs = {
	input: IConnectionsByBlockedStatusInput;
};

export type IQueryCampaignConnectionsByConnectionStatusArgs = {
	input: IConnectionsByConnectionStatusInput;
};

export type IQueryCampaignConnectionsByMeetingsArgs = {
	input: IConnectionsByMeetingsInput;
};

export type IQueryCampaignConnectionsByUserArgs = {
	input: IConnectionsByUserInput;
};

export type IQueryCampaignConnectionsPaginatedArgs = {
	input: ICampaignConnectionsPaginatedInput;
};

export type IQueryCampaignMemberEventsArgs = {
	input: ICampaignMemberEventsInput;
};

export type IQueryCampaignStatsArgs = {
	input: ICampaignStatsInput;
};

export type IQueryCampaignSummariesArgs = {
	searchTerm?: InputMaybe<Scalars['String']>;
};

export type IQueryCampaignSummaryArgs = {
	campaignId: Scalars['ID'];
};

export type IQueryCampaignsArgs = {
	input: ICampaignInput;
};

export type IQueryChatCompletionArgs = {
	input: IChatCompletionInput;
};

export type IQueryEditMessageArgs = {
	input: IEditMessageInput;
};

export type IQueryEditSmartReplyArgs = {
	input: IEditSmartReplyInput;
};

export type IQueryOrgContactsArgs = {
	input: IOrgContactsFilters;
};

export type IQueryOrgMembersArgs = {
	input: IOrgMembersFilters;
};

export type IQueryOrganizationsArgs = {
	input: IOrganizationFilters;
};

export type IQuerySecureMessageArgs = {
	input: ISecureMessageInput;
};

export type IQueryTextCompletionArgs = {
	input: ITextCompletionInput;
};

export type IQueryWriteSmartReplyArgs = {
	input: IWriteSmartReplyInput;
};

/** Input for the secureMessage query */
export type ISecureMessageInput = {
	/** The prompt message label to lookup */
	promptLabel: Scalars['String'];
	/** The prompt message values to replace */
	promptValues?: InputMaybe<Array<IKeyValueInput>>;
};

/** Response generated from the secureMessage query */
export type ISecureMessageResponse = IMessageResult | IModerationResult;

/** A Smart Reply */
export type ISmartReply = {
	__typename?: 'SmartReply';
	/** The Smart Reply's message */
	message: Scalars['String'];
};

/** A Smart Reply error */
export type ISmartReplyError = {
	__typename?: 'SmartReplyError';
	/** The error's message */
	message: Scalars['String'];
	/** The cause of the error */
	type: ISmartReplyErrorTypes;
};

/** Errors that can be encountered while writing Smart Replies */
export enum ISmartReplyErrorTypes {
	Connection = 'CONNECTION',
	Credit = 'CREDIT',
	Generation = 'GENERATION',
	Moderation = 'MODERATION',
	Parsing = 'PARSING',
	Prompt = 'PROMPT',
}

/** Input describing a participant in a messaging thread */
export type ISmartReplyParticipant = {
	/** The name of the participant */
	name: Scalars['String'];
};

/** Input describing a Smart Reply prompt */
export type ISmartReplyPrompt = {
	/** Additional directions to be included in the prompt */
	message?: InputMaybe<Scalars['String']>;
	/** The ID of the prompt to be used */
	promptId: Scalars['ID'];
};

/** Types provided in response to a generateSmartReply query */
export type ISmartReplyResponse = ISmartReply | ISmartReplyError;

/** Input for sorting */
export type ISortInput = {
	/** Field to sort by */
	field: Scalars['String'];
	/** Sort order */
	order: ISortOrder;
};

export enum ISortOrder {
	/** Ascending sort direction */
	Asc = 'ASC',
	/** Descending sort direction */
	Desc = 'DESC',
}

/** Tenant Information */
export type ITenant = {
	__typename?: 'Tenant';
	/** Tenant's child tenants */
	childTenants: Array<ITenant>;
	/** Tenant's ID */
	id: Scalars['ID'];
	/** Tenant's name */
	name: Scalars['String'];
	/** Tenant's type */
	type: ITenantType;
};

/** Possible filters for tenant query */
export type ITenantFilters = {
	/** Specific tenants to get by tenant ids. Only one of tenantIds or tenantTypes should be used in a single query. */
	tenantIds?: InputMaybe<Array<Scalars['ID']>>;
	/** Specific organizations to get by tenant types. Only one of tenantIds or tenantTypes should be used in a single query. */
	tenantTypes?: InputMaybe<Array<ITenantType>>;
};

/** Tenant type */
export enum ITenantType {
	/** Leaf Tenant */
	Leaf = 'LEAF',
	/** Tenant that is not a leaf */
	Node = 'NODE',
}

export type ITextCompletionChoice = {
	__typename?: 'TextCompletionChoice';
	/** The text generated by ChatGPT. */
	text: Scalars['String'];
};

export type ITextCompletionInput = {
	/** The penalty for repeated tokens in the generated text, between -2.0 and 2.0. */
	frequencyPenalty?: InputMaybe<Scalars['Float']>;
	/** The maximum number of tokens to generate. */
	maxLength?: InputMaybe<Scalars['Int']>;
	/** The penalty for tokens that are present in the generated text, between -2.0 and 2.0. */
	presencePenalty?: InputMaybe<Scalars['Float']>;
	/** The prompt to complete. */
	prompt: Scalars['String'];
	/** The randomness of the generated text, between 0.0 and 2.0. */
	temperature?: InputMaybe<Scalars['Float']>;
	/** The diversity of the generated text. */
	topP?: InputMaybe<Scalars['Float']>;
};

export type ITextCompletionOrModerationResult = IModerationResult | ITextCompletionResult;

export type ITextCompletionResult = {
	__typename?: 'TextCompletionResult';
	/** A list of text completed responses by ChatGPT. */
	choices: Array<ITextCompletionChoice>;
};

/** ThreadInfo to be embedded in Connection to surface linkedin thread information that is still required */
export type IThreadInfo = {
	__typename?: 'ThreadInfo';
	/** Thread's Id */
	id: Scalars['ID'];
};

/** Possible intervals query for campaign stats by */
export enum ITimeInterval {
	/** Time interval of a week. */
	Week = 'WEEK',
}

/** Input for updating account manager for an account */
export type IUpdateAccountManagerByAccountIdInput = {
	/** Account Id to update for */
	accountId: Scalars['ID'];
	/** Account manager user id */
	accountManagerUserId: Scalars['String'];
};

/** Input for updating an accounts name */
export type IUpdateAccountNameInput = {
	/** Account Id to update for */
	accountId: Scalars['ID'];
	/** Account name to update to */
	accountName: Scalars['String'];
};

/** Input for updating a tenant */
export type IUpdateTenantInput = {
	/** Tenant id */
	id: Scalars['ID'];
	/** Parent Tenant id to update to */
	parentTenantId: Scalars['ID'];
};

/** Input for updating a users given names */
export type IUpdateUserGivenNamesInput = {
	/** First name */
	firstName: Scalars['String'];
	/** User id */
	id: Scalars['ID'];
	/** Last name */
	lastName: Scalars['String'];
};

/** User Information */
export type IUser = {
	__typename?: 'User';
	/** Account's ID to which the user belongs */
	accountId: Scalars['ID'];
	/** User's first name */
	firstName: Scalars['String'];
	/** User's ID */
	id: Scalars['ID'];
	/** Whether or not the user is active */
	isActive: Scalars['Boolean'];
	/** The issues pertaining to the user */
	issues?: Maybe<Array<IIssue>>;
	/** User's last name */
	lastName: Scalars['String'];
	/** User's last seen on the platform */
	lastSeen?: Maybe<Scalars['Int']>;
	/** Information from deprecated OrgMember */
	orgMemberInfo?: Maybe<IOrgMemberInfo>;
	/** User's username */
	userName: Scalars['String'];
	/** Users configured vpn proxy port */
	vpnPort?: Maybe<Scalars['String']>;
};

/** Filters for the users query */
export type IUserFilters = {
	/** The master account of the users to fetch */
	masterAccountId?: InputMaybe<Scalars['String']>;
};

/** Input for generating a smart reply */
export type IWriteSmartReplyInput = {
	/** The existing messaging thread between the two participants */
	messageThread: Array<IMessageInput>;
	/** Prompt to use to write a Smart Reply */
	prompt: ISmartReplyPrompt;
	/** The participant to converse with */
	recipient: ISmartReplyParticipant;
	/** The participant to take the perspective of when writing the Smart Reply */
	sender: ISmartReplyParticipant;
};

export type I_Service = {
	__typename?: '_Service';
	sdl?: Maybe<Scalars['String']>;
};

export type ICampaignSummaryAttentionItemsQueryVariables = Exact<{
	campaignId: Scalars['ID'];
	organizationIds: Array<Scalars['ID']> | Scalars['ID'];
	orgMemberIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type ICampaignSummaryAttentionItemsQuery = {
	__typename?: 'Query';
	campaignSummary: {
		__typename?: 'Campaign';
		name?: string | null;
		attentionItems: Array<{
			__typename?: 'CampaignAttentionItem';
			level: IAttentionLevel;
			memberId: string;
			type: ICampaignAttentionType;
		}>;
	};
	orgMembers: Array<{
		__typename?: 'OrgMember';
		id: string;
		organizationId: string;
		attentionItems: Array<{
			__typename?: 'OrgMemberAttentionItem';
			level: IAttentionLevel;
			memberId: string;
			type: IOrgMemberAttentionType;
		}>;
	}>;
};

export type IUpdateConnectionMeetingMutationVariables = Exact<{
	connectionId: Scalars['ID'];
	isBooked: Scalars['Boolean'];
}>;

export type IUpdateConnectionMeetingMutation = {
	__typename?: 'Mutation';
	updateConnectionMeeting: {
		__typename?: 'CampaignConnection';
		id: string;
		meeting: { __typename?: 'MeetingContext'; booked: boolean };
	};
};

export type ICampaignMemberEventsAndStatsQueryVariables = Exact<{
	campaignMemberId: Scalars['ID'];
	end: Scalars['DateTime'];
	interval?: InputMaybe<ITimeInterval>;
	start?: InputMaybe<Scalars['DateTime']>;
	numDays: Scalars['Int'];
}>;

export type ICampaignMemberEventsAndStatsQuery = {
	__typename?: 'Query';
	campaignMemberEvents: Array<{
		__typename?: 'CampaignUsage';
		action: ICampaignUsageAction;
		campaignId: string;
		campaignMemberId?: string | null;
		dateCreated: number;
	}>;
	campaignStats: Array<
		| {
				__typename?: 'NurtureCampaignStats';
				ownerId: string;
				ownerType: ICampaignStatsOwnerType;
				start?: number | null;
				end?: number | null;
				numSendMessages: number;
				numUserReplies: number;
				numInterestedReplies: number;
				percentReplied?: number | null;
				percentInterestedReplied?: number | null;
		  }
		| {
				__typename?: 'ProspectingCampaignStats';
				ownerId: string;
				ownerType: ICampaignStatsOwnerType;
				start?: number | null;
				end?: number | null;
				numUserReplies: number;
				numUsersConnected: number;
				numInvitesSent: number;
				numInterestedReplies: number;
				percentConnected?: number | null;
				percentReplied?: number | null;
				percentInterestedReplied?: number | null;
		  }
	>;
};

export type ICampaignStatsQueryVariables = Exact<{
	input: ICampaignStatsInput;
}>;

export type ICampaignStatsQuery = {
	__typename?: 'Query';
	campaignStats: Array<
		| {
				__typename?: 'NurtureCampaignStats';
				ownerId: string;
				ownerType: ICampaignStatsOwnerType;
				start?: number | null;
				end?: number | null;
				numSendMessages: number;
				numUserReplies: number;
				numInterestedReplies: number;
				percentReplied?: number | null;
				percentInterestedReplied?: number | null;
		  }
		| {
				__typename?: 'ProspectingCampaignStats';
				ownerId: string;
				ownerType: ICampaignStatsOwnerType;
				start?: number | null;
				end?: number | null;
				numUserReplies: number;
				numUsersConnected: number;
				numInvitesSent: number;
				numInterestedReplies: number;
				numInMailSent: number;
				numInMailReplies: number;
				numInterestedInMailReplies: number;
				percentConnected?: number | null;
				percentReplied?: number | null;
				percentInterestedReplied?: number | null;
				percentInMailReplied?: number | null;
				percentInterestedInMailReplied?: number | null;
		  }
	>;
};

export type ICampaignSummariesQueryVariables = Exact<{
	start?: InputMaybe<Scalars['DateTime']>;
	end?: InputMaybe<Scalars['DateTime']>;
	searchTerm?: InputMaybe<Scalars['String']>;
}>;

export type ICampaignSummariesQuery = {
	__typename?: 'Query';
	campaignSummaries: Array<{
		__typename?: 'Campaign';
		id: string;
		name?: string | null;
		numActiveMembers?: number | null;
		status?: ICampaignStatus | null;
		type?: ICampaignType | null;
		members: Array<{ __typename?: 'CampaignMember'; id: string; orgMemberId: string }>;
		stats?:
			| {
					__typename?: 'NurtureCampaignStats';
					numUserReplies: number;
					percentReplied?: number | null;
			  }
			| {
					__typename?: 'ProspectingCampaignStats';
					numUserReplies: number;
					numUsersConnected: number;
					numInvitesSent: number;
					percentConnected?: number | null;
					percentReplied?: number | null;
			  }
			| null;
	}>;
};

export type ICampaignConnectionsQueryVariables = Exact<{
	input: ICampaignConnectionsInput;
}>;

export type ICampaignConnectionsQuery = {
	__typename?: 'Query';
	campaignConnections: Array<{
		__typename?: 'CampaignConnection';
		id: string;
		nextAutomationNode?: {
			__typename?: 'NextAutomationNode';
			id: string;
			name: string;
			message?: string | null;
		} | null;
	}>;
};

export type ICampaignAllocationsByUserQueryVariables = Exact<{
	input: ICampaignInput;
}>;

export type ICampaignAllocationsByUserQuery = {
	__typename?: 'Query';
	campaigns: Array<{
		__typename?: 'Campaign';
		id: string;
		name?: string | null;
		type?: ICampaignType | null;
		members: Array<{
			__typename?: 'CampaignMember';
			orgMemberId: string;
			dailyInvitesAllocated: number;
			dailyMessagesAllocated: number;
		}>;
	}>;
};

export type IOrganizationContactInformationQueryVariables = Exact<{
	contactId: Scalars['ID'];
}>;

export type IOrganizationContactInformationQuery = {
	__typename?: 'Query';
	campaignConnections: Array<{
		__typename?: 'CampaignConnection';
		id: string;
		campaignId: string;
		campaignMember: { __typename?: 'CampaignMember'; id: string; orgMemberId: string };
		campaignHistory: Array<{
			__typename?: 'CampaignHistory';
			start: number;
			end?: number | null;
			name: string;
		}>;
		meeting: { __typename?: 'MeetingContext'; bookedDate?: number | null };
	}>;
	orgContacts: Array<{
		__typename?: 'OrgContact';
		id: string;
		firstName: string;
		lastName: string;
		organizationId: string;
		title: string;
		company: string;
		email: string;
		phoneNumber: string;
	}>;
};

export type IContactFieldsFragment = {
	__typename?: 'OrgContact';
	id: string;
	firstName: string;
	lastName: string;
	organizationId: string;
	title: string;
	company: string;
	email: string;
	phoneNumber: string;
};

export type IUpdateOrgContactCompanyMutationVariables = Exact<{
	orgContactId: Scalars['ID'];
	company: Scalars['String'];
}>;

export type IUpdateOrgContactCompanyMutation = {
	__typename?: 'Mutation';
	updateOrgContactCompany: {
		__typename?: 'OrgContact';
		id: string;
		firstName: string;
		lastName: string;
		organizationId: string;
		title: string;
		company: string;
		email: string;
		phoneNumber: string;
	};
};

export type IUpdateOrgContactEmailMutationVariables = Exact<{
	orgContactId: Scalars['ID'];
	email: Scalars['String'];
}>;

export type IUpdateOrgContactEmailMutation = {
	__typename?: 'Mutation';
	updateOrgContactEmail: {
		__typename?: 'OrgContact';
		id: string;
		firstName: string;
		lastName: string;
		organizationId: string;
		title: string;
		company: string;
		email: string;
		phoneNumber: string;
	};
};

export type IUpdateOrgContactPhoneNumberMutationVariables = Exact<{
	orgContactId: Scalars['ID'];
	phoneNumber: Scalars['String'];
}>;

export type IUpdateOrgContactPhoneNumberMutation = {
	__typename?: 'Mutation';
	updateOrgContactPhoneNumber: {
		__typename?: 'OrgContact';
		id: string;
		firstName: string;
		lastName: string;
		organizationId: string;
		title: string;
		company: string;
		email: string;
		phoneNumber: string;
	};
};

export type IUpdateOrgContactTitleMutationVariables = Exact<{
	orgContactId: Scalars['ID'];
	title: Scalars['String'];
}>;

export type IUpdateOrgContactTitleMutation = {
	__typename?: 'Mutation';
	updateOrgContactTitle: {
		__typename?: 'OrgContact';
		id: string;
		firstName: string;
		lastName: string;
		organizationId: string;
		title: string;
		company: string;
		email: string;
		phoneNumber: string;
	};
};

export type IAddAccountMutationVariables = Exact<{
	input: IAddAccountInput;
}>;

export type IAddAccountMutation = {
	__typename?: 'Mutation';
	addAccount: {
		__typename?: 'Account';
		id: string;
		name: string;
		orgInfo?: { __typename?: 'OrgInfo'; id: string } | null;
	};
};

export type IUpdateUserGivenNamesMutationVariables = Exact<{
	input: IUpdateUserGivenNamesInput;
}>;

export type IUpdateUserGivenNamesMutation = {
	__typename?: 'Mutation';
	updateUserGivenNames: { __typename?: 'User'; id: string; firstName: string; lastName: string };
};

export type IPromptsQueryVariables = Exact<{ [key: string]: never }>;

export type IPromptsQuery = {
	__typename?: 'Query';
	prompts: Array<{
		__typename?: 'Prompt';
		id: string;
		label: string;
		action: IPromptActions;
		useCases?: Array<IPromptUseCases> | null;
	}>;
};

export type IEditSmartReplyQueryVariables = Exact<{
	input: IEditSmartReplyInput;
}>;

export type IEditSmartReplyQuery = {
	__typename?: 'Query';
	editSmartReply:
		| { __typename?: 'SmartReply'; message: string }
		| { __typename?: 'SmartReplyError'; message: string; type: ISmartReplyErrorTypes };
};

export type IWriteSmartReplyQueryVariables = Exact<{
	input: IWriteSmartReplyInput;
}>;

export type IWriteSmartReplyQuery = {
	__typename?: 'Query';
	writeSmartReply:
		| { __typename?: 'SmartReply'; message: string }
		| { __typename?: 'SmartReplyError'; message: string; type: ISmartReplyErrorTypes };
};

export type ISearchAccountsQueryVariables = Exact<{
	accountsFilter: IAccountFilters;
	pagination?: InputMaybe<IPagination>;
}>;

export type ISearchAccountsQuery = {
	__typename?: 'Query';
	admin_accounts: Array<{
		__typename?: 'Account';
		id: string;
		name: string;
		isActive: boolean;
		type: IAccountType;
		logoUrl?: string | null;
		parentAccount?: { __typename?: 'Account'; name: string } | null;
	}>;
};

export type IAccountSummaryFragment = {
	__typename?: 'Account';
	id: string;
	name: string;
	isActive: boolean;
	type: IAccountType;
	logoUrl?: string | null;
	parentAccount?: { __typename?: 'Account'; name: string } | null;
};

export type IAccountsQueryVariables = Exact<{
	accountsFilter: IAccountFilters;
	pagination?: InputMaybe<IPagination>;
}>;

export type IAccountsQuery = {
	__typename?: 'Query';
	admin_num_accounts: number;
	admin_accounts: Array<{
		__typename?: 'Account';
		id: string;
		name: string;
		isActive: boolean;
		type: IAccountType;
		logoUrl?: string | null;
		dateCreated?: number | null;
		users: Array<{
			__typename?: 'User';
			id: string;
			firstName: string;
			lastName: string;
			userName: string;
			lastSeen?: number | null;
			isActive: boolean;
			accountId: string;
			issues?: Array<{
				__typename?: 'Issue';
				level: IAttentionLevel;
				type: IOrgMemberAttentionType;
			}> | null;
		}>;
		orgInfo?: { __typename?: 'OrgInfo'; id: string } | null;
		primaryContact?: { __typename?: 'User'; id: string } | null;
		accountManager?: {
			__typename?: 'AccountManager';
			firstName: string;
			lastName: string;
			id: string;
		} | null;
	}>;
};

export type IActivateRegularAccountMutationVariables = Exact<{
	accountId: Scalars['ID'];
}>;

export type IActivateRegularAccountMutation = {
	__typename?: 'Mutation';
	activateRegularAccount: { __typename?: 'Account'; id: string };
};

export type IDeactivateRegularAccountMutationVariables = Exact<{
	accountId: Scalars['ID'];
}>;

export type IDeactivateRegularAccountMutation = {
	__typename?: 'Mutation';
	deactivateRegularAccount: { __typename?: 'Account'; id: string };
};

export type IAccountQueryVariables = Exact<{
	adminAccountId: Scalars['ID'];
}>;

export type IAccountQuery = {
	__typename?: 'Query';
	admin_account: {
		__typename?: 'Account';
		id: string;
		isActive: boolean;
		name: string;
		type: IAccountType;
		logoUrl?: string | null;
		dateCreated?: number | null;
		orgInfo?: { __typename?: 'OrgInfo'; id: string } | null;
		users: Array<{
			__typename?: 'User';
			id: string;
			firstName: string;
			lastName: string;
			userName: string;
			lastSeen?: number | null;
			isActive: boolean;
			accountId: string;
			vpnPort?: string | null;
			issues?: Array<{
				__typename?: 'Issue';
				level: IAttentionLevel;
				type: IOrgMemberAttentionType;
			}> | null;
			orgMemberInfo?: {
				__typename?: 'OrgMemberInfo';
				id: string;
				role: IOrganizationMemberRole;
			} | null;
		}>;
		primaryContact?: { __typename?: 'User'; id: string } | null;
		childAccountsSummary?: {
			__typename?: 'ChildAccountsSummary';
			totalUsers: number;
			totalActiveUsers: number;
			totalAccounts: number;
		} | null;
	};
};

export type IUserDetailsFragment = {
	__typename?: 'User';
	id: string;
	firstName: string;
	lastName: string;
	userName: string;
	lastSeen?: number | null;
	isActive: boolean;
	accountId: string;
	vpnPort?: string | null;
	issues?: Array<{
		__typename?: 'Issue';
		level: IAttentionLevel;
		type: IOrgMemberAttentionType;
	}> | null;
	orgMemberInfo?: {
		__typename?: 'OrgMemberInfo';
		id: string;
		role: IOrganizationMemberRole;
	} | null;
};

export type IDeactivateUsersMutationVariables = Exact<{
	input: IDeactivateUsersInput;
}>;

export type IDeactivateUsersMutation = {
	__typename?: 'Mutation';
	deactivateUsers: Array<{ __typename?: 'User'; id: string }>;
};

export type IActivateUsersMutationVariables = Exact<{
	input: IActivateUsersInput;
}>;

export type IActivateUsersMutation = {
	__typename?: 'Mutation';
	activateUsers: Array<{ __typename?: 'User'; id: string }>;
};

export type IUpdateAccountNameMutationVariables = Exact<{
	input: IUpdateAccountNameInput;
}>;

export type IUpdateAccountNameMutation = {
	__typename?: 'Mutation';
	updateAccountName: { __typename?: 'Account'; name: string };
};

export type IUpdateAccountManagerMutationVariables = Exact<{
	input: IUpdateAccountManagerByAccountIdInput;
}>;

export type IUpdateAccountManagerMutation = {
	__typename?: 'Mutation';
	updateAccountManagerByAccountId: { __typename?: 'Account'; id: string };
};

export type IAccountCampaignsQueryVariables = Exact<{
	campaignsInput: ICampaignInput;
	adminOrganizationsInput: IOrgFilters;
}>;

export type IAccountCampaignsQuery = {
	__typename?: 'Query';
	campaigns: Array<{
		__typename?: 'Campaign';
		status?: ICampaignStatus | null;
		type?: ICampaignType | null;
		name?: string | null;
		numActiveMembers?: number | null;
		id: string;
		members: Array<{
			__typename?: 'CampaignMember';
			orgMemberId: string;
			dailyInvitesAllocated: number;
			dailyMessagesAllocated: number;
			dailyOpenInMailAllocated: number;
		}>;
		attentionItems: Array<{
			__typename?: 'CampaignAttentionItem';
			memberId: string;
			level: IAttentionLevel;
			type: ICampaignAttentionType;
		}>;
	}>;
	admin_organizations: Array<{
		__typename?: 'Organization';
		members: Array<{
			__typename?: 'OrgMember';
			attentionItems: Array<{
				__typename?: 'OrgMemberAttentionItem';
				level: IAttentionLevel;
				memberId: string;
				type: IOrgMemberAttentionType;
			}>;
		}>;
	}>;
};

export type ICampaignDetailsFragment = {
	__typename?: 'Campaign';
	status?: ICampaignStatus | null;
	type?: ICampaignType | null;
	name?: string | null;
	numActiveMembers?: number | null;
	id: string;
	members: Array<{
		__typename?: 'CampaignMember';
		orgMemberId: string;
		dailyInvitesAllocated: number;
		dailyMessagesAllocated: number;
		dailyOpenInMailAllocated: number;
	}>;
	attentionItems: Array<{
		__typename?: 'CampaignAttentionItem';
		memberId: string;
		level: IAttentionLevel;
		type: ICampaignAttentionType;
	}>;
};

export type IAccountCampaignStatsQueryVariables = Exact<{
	input: ICampaignStatsInput;
}>;

export type IAccountCampaignStatsQuery = {
	__typename?: 'Query';
	campaignStats: Array<
		| {
				__typename?: 'NurtureCampaignStats';
				ownerId: string;
				ownerType: ICampaignStatsOwnerType;
				start?: number | null;
				end?: number | null;
				numMeetingsBooked: number;
				percentReplied?: number | null;
				percentInterestedReplied?: number | null;
		  }
		| {
				__typename?: 'ProspectingCampaignStats';
				ownerId: string;
				ownerType: ICampaignStatsOwnerType;
				start?: number | null;
				end?: number | null;
				numMeetingsBooked: number;
				percentConnected?: number | null;
				percentReplied?: number | null;
				percentInterestedReplied?: number | null;
				percentInMailReplied?: number | null;
				percentInterestedInMailReplied?: number | null;
		  }
	>;
};

export type ITenantDataQueryVariables = Exact<{
	tenantFilter: ITenantFilters;
}>;

export type ITenantDataQuery = {
	__typename?: 'Query';
	admin_tenants: Array<{
		__typename?: 'Tenant';
		id: string;
		name: string;
		childTenants: Array<{ __typename?: 'Tenant'; id: string; name: string; type: ITenantType }>;
	}>;
};

export type IAdminOrgDataQueryVariables = Exact<{
	orgFilter: IOrgFilters;
}>;

export type IAdminOrgDataQuery = {
	__typename?: 'Query';
	admin_organizations: Array<{
		__typename?: 'Organization';
		id: string;
		name: string;
		members: Array<{
			__typename?: 'OrgMember';
			id: string;
			userId: string;
			role: IOrganizationMemberRole;
			isActive: boolean;
		}>;
		customerSuccessOwner?: {
			__typename?: 'User';
			id: string;
			firstName: string;
			lastName: string;
		} | null;
	}>;
};

export type ITwoLevelsTenantDataQueryVariables = Exact<{
	tenantFilter: ITenantFilters;
}>;

export type ITwoLevelsTenantDataQuery = {
	__typename?: 'Query';
	admin_tenants: Array<{
		__typename?: 'Tenant';
		id: string;
		name: string;
		type: ITenantType;
		childTenants: Array<{
			__typename?: 'Tenant';
			id: string;
			name: string;
			type: ITenantType;
			childTenants: Array<{
				__typename?: 'Tenant';
				id: string;
				name: string;
				type: ITenantType;
			}>;
		}>;
	}>;
};

export type IUpdateParentTenantMutationVariables = Exact<{
	updateTenantInput: IUpdateTenantInput;
}>;

export type IUpdateParentTenantMutation = {
	__typename?: 'Mutation';
	updateTenant: { __typename?: 'Tenant'; id: string; name: string; type: ITenantType };
};

export type IStopAutomationMutationVariables = Exact<{
	campaignConnectionId: Scalars['ID'];
}>;

export type IStopAutomationMutation = {
	__typename?: 'Mutation';
	stopAutomation: { __typename?: 'CampaignConnection'; id: string };
};

export type ICampaignsByUserQueryVariables = Exact<{
	input: ICampaignInput;
}>;

export type ICampaignsByUserQuery = {
	__typename?: 'Query';
	campaigns: Array<{
		__typename?: 'Campaign';
		id: string;
		name?: string | null;
		type?: ICampaignType | null;
		members: Array<{
			__typename?: 'CampaignMember';
			orgMemberId: string;
			dailyOpenInMailAllocated: number;
		}>;
	}>;
};

export type IConnectionsByConnectionStatusQueryVariables = Exact<{
	input: IConnectionsByConnectionStatusInput;
}>;

export type IConnectionsByConnectionStatusQuery = {
	__typename?: 'Query';
	campaignConnectionsByConnectionStatus: {
		__typename?: 'ConnectionsResponse';
		count: number;
		pages: { __typename?: 'PaginationContext'; current: number; total: number };
		content: Array<{
			__typename?: 'CampaignConnection';
			id: string;
			campaignId: string;
			name: string;
			status: IConnectionStatus;
			title?: string | null;
			company?: string | null;
			location?: string | null;
			profileId: string;
			inviteUrl: string;
			replyPrediction?: number | null;
			isManualTermination?: boolean | null;
			dateCreated: number;
			campaignMember: { __typename?: 'CampaignMember'; id: string; orgMemberId: string };
			contactInfo?: { __typename?: 'ContactInfo'; id: string } | null;
			nextAutomationNode?: {
				__typename?: 'NextAutomationNode';
				id: string;
				name: string;
			} | null;
		}>;
	};
};

export type ICampaignConnectionsByMeetingsQueryVariables = Exact<{
	input: IConnectionsByMeetingsInput;
}>;

export type ICampaignConnectionsByMeetingsQuery = {
	__typename?: 'Query';
	campaignConnectionsByMeetings: {
		__typename?: 'ConnectionsResponse';
		count: number;
		pages: { __typename?: 'PaginationContext'; current: number; total: number };
		content: Array<{
			__typename?: 'CampaignConnection';
			id: string;
			name: string;
			title?: string | null;
			company?: string | null;
			profileId: string;
			meeting: {
				__typename?: 'MeetingContext';
				booked: boolean;
				bookedDate?: number | null;
				detectedDate?: number | null;
			};
			campaignMember: {
				__typename?: 'CampaignMember';
				id: string;
				orgMemberId: string;
				dailyOpenInMailAllocated: number;
				dailyInvitesAllocated: number;
				dailyMessagesAllocated: number;
			};
			campaignHistory: Array<{
				__typename?: 'CampaignHistory';
				campaignId: string;
				name: string;
				start: number;
				end?: number | null;
			}>;
			contactInfo?: { __typename?: 'ContactInfo'; id: string } | null;
		}>;
	};
};

export type IDetectedMeetingsCountQueryVariables = Exact<{
	input: IConnectionsByMeetingsInput;
}>;

export type IDetectedMeetingsCountQuery = {
	__typename?: 'Query';
	campaignConnectionsByMeetings: { __typename?: 'ConnectionsResponse'; count: number };
};

export type IRejectDetectionMutationVariables = Exact<{
	campaignConnectionId: Scalars['ID'];
}>;

export type IRejectDetectionMutation = {
	__typename?: 'Mutation';
	rejectMeetingDetection: { __typename?: 'CampaignConnection'; id: string };
};

export type IConfirmDetectionMutationVariables = Exact<{
	campaignConnectionId: Scalars['ID'];
	isBooked: Scalars['Boolean'];
}>;

export type IConfirmDetectionMutation = {
	__typename?: 'Mutation';
	updateConnectionMeeting: { __typename?: 'CampaignConnection'; id: string };
};

export type IOrganizationStatsQueryVariables = Exact<{
	organizationId: Scalars['ID'];
	orgMemberIds: Array<Scalars['ID']> | Scalars['ID'];
	start?: InputMaybe<Scalars['DateTime']>;
	end?: InputMaybe<Scalars['DateTime']>;
}>;

export type IOrganizationStatsQuery = {
	__typename?: 'Query';
	campaignStats: Array<
		| {
				__typename?: 'NurtureCampaignStats';
				ownerId: string;
				ownerType: ICampaignStatsOwnerType;
				start?: number | null;
				end?: number | null;
				numSendMessages: number;
				numUserReplies: number;
				numInterestedReplies: number;
				percentReplied?: number | null;
				percentInterestedReplied?: number | null;
				numMeetingsBooked: number;
		  }
		| {
				__typename?: 'ProspectingCampaignStats';
				ownerId: string;
				ownerType: ICampaignStatsOwnerType;
				start?: number | null;
				end?: number | null;
				numUserReplies: number;
				numUsersConnected: number;
				numInvitesSent: number;
				numInterestedReplies: number;
				percentConnected?: number | null;
				percentReplied?: number | null;
				percentInterestedReplied?: number | null;
				numMeetingsBooked: number;
		  }
	>;
};

export type IOrgMembersAndCampaignsQueryVariables = Exact<{
	organizationId: Scalars['ID'];
}>;

export type IOrgMembersAndCampaignsQuery = {
	__typename?: 'Query';
	orgMembers: Array<{
		__typename?: 'OrgMember';
		id: string;
		isActive: boolean;
		organizationId: string;
		attentionItems: Array<{
			__typename?: 'OrgMemberAttentionItem';
			level: IAttentionLevel;
			memberId: string;
			type: IOrgMemberAttentionType;
		}>;
		linkedInErrors: {
			__typename?: 'LinkedInErrors';
			loggedOut: boolean;
			blackListed: boolean;
			hasFailedMessages: boolean;
			invitesThrottled: boolean;
			salesNavMissing: boolean;
		};
	}>;
	campaignSummaries: Array<{
		__typename?: 'Campaign';
		id: string;
		name?: string | null;
		attentionItems: Array<{
			__typename?: 'CampaignAttentionItem';
			level: IAttentionLevel;
			memberId: string;
			type: ICampaignAttentionType;
		}>;
		members: Array<{ __typename?: 'CampaignMember'; id: string; orgMemberId: string }>;
	}>;
};

export type IGenerateLinkedInAuthMutationVariables = Exact<{
	input: ILinkedInAuthInput;
}>;

export type IGenerateLinkedInAuthMutation = {
	__typename?: 'Mutation';
	generateLinkedInAuth:
		| { __typename?: 'LinkedInAuthDTO'; url: string }
		| { __typename?: 'LinkedInAuthError'; type: ILinkedInAuthErrors };
};

export const ContactFieldsFragmentDoc = gql`
	fragment ContactFields on OrgContact {
		id
		firstName
		lastName
		organizationId
		title
		company
		email
		phoneNumber
	}
`;
export const AccountSummaryFragmentDoc = gql`
	fragment AccountSummary on Account {
		id
		name
		isActive
		type
		logoUrl
		parentAccount {
			name
		}
	}
`;
export const UserDetailsFragmentDoc = gql`
	fragment UserDetails on User {
		id
		firstName
		lastName
		userName
		lastSeen
		isActive
		accountId
		vpnPort
		issues {
			level
			type
		}
		orgMemberInfo {
			id
			role
		}
	}
`;
export const CampaignDetailsFragmentDoc = gql`
	fragment CampaignDetails on Campaign {
		status
		type
		name
		numActiveMembers
		members {
			orgMemberId
			dailyInvitesAllocated
			dailyMessagesAllocated
			dailyOpenInMailAllocated
		}
		id
		attentionItems {
			memberId
			level
			type
		}
	}
`;
export const CampaignSummaryAttentionItemsDocument = gql`
	query CampaignSummaryAttentionItems(
		$campaignId: ID!
		$organizationIds: [ID!]!
		$orgMemberIds: [ID!]
	) {
		campaignSummary(campaignId: $campaignId) {
			name
			attentionItems {
				level
				memberId
				type
			}
		}
		orgMembers(input: { organizationIds: $organizationIds, orgMemberIds: $orgMemberIds }) {
			id
			organizationId
			attentionItems {
				level
				memberId
				type
			}
		}
	}
`;

/**
 * __useCampaignSummaryAttentionItemsQuery__
 *
 * To run a query within a React component, call `useCampaignSummaryAttentionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignSummaryAttentionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignSummaryAttentionItemsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      organizationIds: // value for 'organizationIds'
 *      orgMemberIds: // value for 'orgMemberIds'
 *   },
 * });
 */
export function useCampaignSummaryAttentionItemsQuery(
	baseOptions: Apollo.QueryHookOptions<
		ICampaignSummaryAttentionItemsQuery,
		ICampaignSummaryAttentionItemsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		ICampaignSummaryAttentionItemsQuery,
		ICampaignSummaryAttentionItemsQueryVariables
	>(CampaignSummaryAttentionItemsDocument, options);
}
export function useCampaignSummaryAttentionItemsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ICampaignSummaryAttentionItemsQuery,
		ICampaignSummaryAttentionItemsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ICampaignSummaryAttentionItemsQuery,
		ICampaignSummaryAttentionItemsQueryVariables
	>(CampaignSummaryAttentionItemsDocument, options);
}
export type CampaignSummaryAttentionItemsQueryHookResult = ReturnType<
	typeof useCampaignSummaryAttentionItemsQuery
>;
export type CampaignSummaryAttentionItemsLazyQueryHookResult = ReturnType<
	typeof useCampaignSummaryAttentionItemsLazyQuery
>;
export type CampaignSummaryAttentionItemsQueryResult = Apollo.QueryResult<
	ICampaignSummaryAttentionItemsQuery,
	ICampaignSummaryAttentionItemsQueryVariables
>;
export function refetchCampaignSummaryAttentionItemsQuery(
	variables: ICampaignSummaryAttentionItemsQueryVariables
) {
	return { query: CampaignSummaryAttentionItemsDocument, variables: variables };
}
export const UpdateConnectionMeetingDocument = gql`
	mutation UpdateConnectionMeeting($connectionId: ID!, $isBooked: Boolean!) {
		updateConnectionMeeting(campaignConnectionId: $connectionId, isBooked: $isBooked) {
			... on CampaignConnection {
				id
				meeting {
					booked
				}
			}
		}
	}
`;
export type IUpdateConnectionMeetingMutationFn = Apollo.MutationFunction<
	IUpdateConnectionMeetingMutation,
	IUpdateConnectionMeetingMutationVariables
>;

/**
 * __useUpdateConnectionMeetingMutation__
 *
 * To run a mutation, you first call `useUpdateConnectionMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectionMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectionMeetingMutation, { data, loading, error }] = useUpdateConnectionMeetingMutation({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *      isBooked: // value for 'isBooked'
 *   },
 * });
 */
export function useUpdateConnectionMeetingMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IUpdateConnectionMeetingMutation,
		IUpdateConnectionMeetingMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		IUpdateConnectionMeetingMutation,
		IUpdateConnectionMeetingMutationVariables
	>(UpdateConnectionMeetingDocument, options);
}
export type UpdateConnectionMeetingMutationHookResult = ReturnType<
	typeof useUpdateConnectionMeetingMutation
>;
export type UpdateConnectionMeetingMutationResult =
	Apollo.MutationResult<IUpdateConnectionMeetingMutation>;
export type UpdateConnectionMeetingMutationOptions = Apollo.BaseMutationOptions<
	IUpdateConnectionMeetingMutation,
	IUpdateConnectionMeetingMutationVariables
>;
export const CampaignMemberEventsAndStatsDocument = gql`
	query CampaignMemberEventsAndStats(
		$campaignMemberId: ID!
		$end: DateTime!
		$interval: TimeInterval
		$start: DateTime
		$numDays: Int!
	) {
		campaignMemberEvents(
			input: { campaignMemberId: $campaignMemberId, end: $end, numDays: $numDays }
		) {
			... on CampaignUsage {
				action
				campaignId
				campaignMemberId
				dateCreated
			}
		}
		campaignStats(
			input: {
				filter: {
					campaignMemberIds: [$campaignMemberId]
					interval: $interval
					end: $end
					start: $start
				}
			}
		) {
			... on NurtureCampaignStats {
				ownerId
				ownerType
				start
				end
				numSendMessages
				numUserReplies
				numInterestedReplies
				percentReplied
				percentInterestedReplied
			}
			... on ProspectingCampaignStats {
				ownerId
				ownerType
				start
				end
				numUserReplies
				numUsersConnected
				numInvitesSent
				numInterestedReplies
				percentConnected
				percentReplied
				percentInterestedReplied
			}
		}
	}
`;

/**
 * __useCampaignMemberEventsAndStatsQuery__
 *
 * To run a query within a React component, call `useCampaignMemberEventsAndStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignMemberEventsAndStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignMemberEventsAndStatsQuery({
 *   variables: {
 *      campaignMemberId: // value for 'campaignMemberId'
 *      end: // value for 'end'
 *      interval: // value for 'interval'
 *      start: // value for 'start'
 *      numDays: // value for 'numDays'
 *   },
 * });
 */
export function useCampaignMemberEventsAndStatsQuery(
	baseOptions: Apollo.QueryHookOptions<
		ICampaignMemberEventsAndStatsQuery,
		ICampaignMemberEventsAndStatsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		ICampaignMemberEventsAndStatsQuery,
		ICampaignMemberEventsAndStatsQueryVariables
	>(CampaignMemberEventsAndStatsDocument, options);
}
export function useCampaignMemberEventsAndStatsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ICampaignMemberEventsAndStatsQuery,
		ICampaignMemberEventsAndStatsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ICampaignMemberEventsAndStatsQuery,
		ICampaignMemberEventsAndStatsQueryVariables
	>(CampaignMemberEventsAndStatsDocument, options);
}
export type CampaignMemberEventsAndStatsQueryHookResult = ReturnType<
	typeof useCampaignMemberEventsAndStatsQuery
>;
export type CampaignMemberEventsAndStatsLazyQueryHookResult = ReturnType<
	typeof useCampaignMemberEventsAndStatsLazyQuery
>;
export type CampaignMemberEventsAndStatsQueryResult = Apollo.QueryResult<
	ICampaignMemberEventsAndStatsQuery,
	ICampaignMemberEventsAndStatsQueryVariables
>;
export function refetchCampaignMemberEventsAndStatsQuery(
	variables: ICampaignMemberEventsAndStatsQueryVariables
) {
	return { query: CampaignMemberEventsAndStatsDocument, variables: variables };
}
export const CampaignStatsDocument = gql`
	query CampaignStats($input: CampaignStatsInput!) {
		campaignStats(input: $input) {
			... on NurtureCampaignStats {
				ownerId
				ownerType
				start
				end
				numSendMessages
				numUserReplies
				numInterestedReplies
				percentReplied
				percentInterestedReplied
			}
			... on ProspectingCampaignStats {
				ownerId
				ownerType
				start
				end
				numUserReplies
				numUsersConnected
				numInvitesSent
				numInterestedReplies
				numInMailSent
				numInMailReplies
				numInterestedInMailReplies
				percentConnected
				percentReplied
				percentInterestedReplied
				percentInMailReplied
				percentInterestedInMailReplied
			}
		}
	}
`;

/**
 * __useCampaignStatsQuery__
 *
 * To run a query within a React component, call `useCampaignStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignStatsQuery(
	baseOptions: Apollo.QueryHookOptions<ICampaignStatsQuery, ICampaignStatsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ICampaignStatsQuery, ICampaignStatsQueryVariables>(
		CampaignStatsDocument,
		options
	);
}
export function useCampaignStatsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ICampaignStatsQuery, ICampaignStatsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ICampaignStatsQuery, ICampaignStatsQueryVariables>(
		CampaignStatsDocument,
		options
	);
}
export type CampaignStatsQueryHookResult = ReturnType<typeof useCampaignStatsQuery>;
export type CampaignStatsLazyQueryHookResult = ReturnType<typeof useCampaignStatsLazyQuery>;
export type CampaignStatsQueryResult = Apollo.QueryResult<
	ICampaignStatsQuery,
	ICampaignStatsQueryVariables
>;
export function refetchCampaignStatsQuery(variables: ICampaignStatsQueryVariables) {
	return { query: CampaignStatsDocument, variables: variables };
}
export const CampaignSummariesDocument = gql`
	query CampaignSummaries($start: DateTime, $end: DateTime, $searchTerm: String) {
		campaignSummaries(searchTerm: $searchTerm) {
			id
			members {
				id
				orgMemberId
			}
			name
			numActiveMembers
			stats(start: $start, end: $end) {
				... on NurtureCampaignStats {
					numUserReplies
					percentReplied
				}
				... on ProspectingCampaignStats {
					numUserReplies
					numUsersConnected
					numInvitesSent
					percentConnected
					percentReplied
				}
			}
			status
			type
		}
	}
`;

/**
 * __useCampaignSummariesQuery__
 *
 * To run a query within a React component, call `useCampaignSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignSummariesQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useCampaignSummariesQuery(
	baseOptions?: Apollo.QueryHookOptions<ICampaignSummariesQuery, ICampaignSummariesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ICampaignSummariesQuery, ICampaignSummariesQueryVariables>(
		CampaignSummariesDocument,
		options
	);
}
export function useCampaignSummariesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ICampaignSummariesQuery,
		ICampaignSummariesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ICampaignSummariesQuery, ICampaignSummariesQueryVariables>(
		CampaignSummariesDocument,
		options
	);
}
export type CampaignSummariesQueryHookResult = ReturnType<typeof useCampaignSummariesQuery>;
export type CampaignSummariesLazyQueryHookResult = ReturnType<typeof useCampaignSummariesLazyQuery>;
export type CampaignSummariesQueryResult = Apollo.QueryResult<
	ICampaignSummariesQuery,
	ICampaignSummariesQueryVariables
>;
export function refetchCampaignSummariesQuery(variables?: ICampaignSummariesQueryVariables) {
	return { query: CampaignSummariesDocument, variables: variables };
}
export const CampaignConnectionsDocument = gql`
	query CampaignConnections($input: CampaignConnectionsInput!) {
		campaignConnections(input: $input) {
			id
			nextAutomationNode {
				id
				name
				message
			}
		}
	}
`;

/**
 * __useCampaignConnectionsQuery__
 *
 * To run a query within a React component, call `useCampaignConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignConnectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignConnectionsQuery(
	baseOptions: Apollo.QueryHookOptions<
		ICampaignConnectionsQuery,
		ICampaignConnectionsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ICampaignConnectionsQuery, ICampaignConnectionsQueryVariables>(
		CampaignConnectionsDocument,
		options
	);
}
export function useCampaignConnectionsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ICampaignConnectionsQuery,
		ICampaignConnectionsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ICampaignConnectionsQuery, ICampaignConnectionsQueryVariables>(
		CampaignConnectionsDocument,
		options
	);
}
export type CampaignConnectionsQueryHookResult = ReturnType<typeof useCampaignConnectionsQuery>;
export type CampaignConnectionsLazyQueryHookResult = ReturnType<
	typeof useCampaignConnectionsLazyQuery
>;
export type CampaignConnectionsQueryResult = Apollo.QueryResult<
	ICampaignConnectionsQuery,
	ICampaignConnectionsQueryVariables
>;
export function refetchCampaignConnectionsQuery(variables: ICampaignConnectionsQueryVariables) {
	return { query: CampaignConnectionsDocument, variables: variables };
}
export const CampaignAllocationsByUserDocument = gql`
	query campaignAllocationsByUser($input: CampaignInput!) {
		campaigns(input: $input) {
			id
			name
			type
			members {
				orgMemberId
				dailyInvitesAllocated
				dailyMessagesAllocated
			}
		}
	}
`;

/**
 * __useCampaignAllocationsByUserQuery__
 *
 * To run a query within a React component, call `useCampaignAllocationsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignAllocationsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignAllocationsByUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignAllocationsByUserQuery(
	baseOptions: Apollo.QueryHookOptions<
		ICampaignAllocationsByUserQuery,
		ICampaignAllocationsByUserQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		ICampaignAllocationsByUserQuery,
		ICampaignAllocationsByUserQueryVariables
	>(CampaignAllocationsByUserDocument, options);
}
export function useCampaignAllocationsByUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ICampaignAllocationsByUserQuery,
		ICampaignAllocationsByUserQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ICampaignAllocationsByUserQuery,
		ICampaignAllocationsByUserQueryVariables
	>(CampaignAllocationsByUserDocument, options);
}
export type CampaignAllocationsByUserQueryHookResult = ReturnType<
	typeof useCampaignAllocationsByUserQuery
>;
export type CampaignAllocationsByUserLazyQueryHookResult = ReturnType<
	typeof useCampaignAllocationsByUserLazyQuery
>;
export type CampaignAllocationsByUserQueryResult = Apollo.QueryResult<
	ICampaignAllocationsByUserQuery,
	ICampaignAllocationsByUserQueryVariables
>;
export function refetchCampaignAllocationsByUserQuery(
	variables: ICampaignAllocationsByUserQueryVariables
) {
	return { query: CampaignAllocationsByUserDocument, variables: variables };
}
export const OrganizationContactInformationDocument = gql`
	query OrganizationContactInformation($contactId: ID!) {
		campaignConnections(input: { contactId: $contactId }) {
			id
			campaignId
			campaignMember {
				id
				orgMemberId
			}
			campaignHistory {
				start
				end
				name
			}
			meeting {
				bookedDate
			}
		}
		orgContacts(input: { orgContactIds: [$contactId] }) {
			id
			firstName
			lastName
			organizationId
			title
			company
			email
			phoneNumber
		}
	}
`;

/**
 * __useOrganizationContactInformationQuery__
 *
 * To run a query within a React component, call `useOrganizationContactInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationContactInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationContactInformationQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useOrganizationContactInformationQuery(
	baseOptions: Apollo.QueryHookOptions<
		IOrganizationContactInformationQuery,
		IOrganizationContactInformationQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		IOrganizationContactInformationQuery,
		IOrganizationContactInformationQueryVariables
	>(OrganizationContactInformationDocument, options);
}
export function useOrganizationContactInformationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		IOrganizationContactInformationQuery,
		IOrganizationContactInformationQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		IOrganizationContactInformationQuery,
		IOrganizationContactInformationQueryVariables
	>(OrganizationContactInformationDocument, options);
}
export type OrganizationContactInformationQueryHookResult = ReturnType<
	typeof useOrganizationContactInformationQuery
>;
export type OrganizationContactInformationLazyQueryHookResult = ReturnType<
	typeof useOrganizationContactInformationLazyQuery
>;
export type OrganizationContactInformationQueryResult = Apollo.QueryResult<
	IOrganizationContactInformationQuery,
	IOrganizationContactInformationQueryVariables
>;
export function refetchOrganizationContactInformationQuery(
	variables: IOrganizationContactInformationQueryVariables
) {
	return { query: OrganizationContactInformationDocument, variables: variables };
}
export const UpdateOrgContactCompanyDocument = gql`
	mutation UpdateOrgContactCompany($orgContactId: ID!, $company: String!) {
		updateOrgContactCompany(orgContactId: $orgContactId, company: $company) {
			...ContactFields
		}
	}
	${ContactFieldsFragmentDoc}
`;
export type IUpdateOrgContactCompanyMutationFn = Apollo.MutationFunction<
	IUpdateOrgContactCompanyMutation,
	IUpdateOrgContactCompanyMutationVariables
>;

/**
 * __useUpdateOrgContactCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateOrgContactCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgContactCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgContactCompanyMutation, { data, loading, error }] = useUpdateOrgContactCompanyMutation({
 *   variables: {
 *      orgContactId: // value for 'orgContactId'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateOrgContactCompanyMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IUpdateOrgContactCompanyMutation,
		IUpdateOrgContactCompanyMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		IUpdateOrgContactCompanyMutation,
		IUpdateOrgContactCompanyMutationVariables
	>(UpdateOrgContactCompanyDocument, options);
}
export type UpdateOrgContactCompanyMutationHookResult = ReturnType<
	typeof useUpdateOrgContactCompanyMutation
>;
export type UpdateOrgContactCompanyMutationResult =
	Apollo.MutationResult<IUpdateOrgContactCompanyMutation>;
export type UpdateOrgContactCompanyMutationOptions = Apollo.BaseMutationOptions<
	IUpdateOrgContactCompanyMutation,
	IUpdateOrgContactCompanyMutationVariables
>;
export const UpdateOrgContactEmailDocument = gql`
	mutation UpdateOrgContactEmail($orgContactId: ID!, $email: String!) {
		updateOrgContactEmail(orgContactId: $orgContactId, email: $email) {
			...ContactFields
		}
	}
	${ContactFieldsFragmentDoc}
`;
export type IUpdateOrgContactEmailMutationFn = Apollo.MutationFunction<
	IUpdateOrgContactEmailMutation,
	IUpdateOrgContactEmailMutationVariables
>;

/**
 * __useUpdateOrgContactEmailMutation__
 *
 * To run a mutation, you first call `useUpdateOrgContactEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgContactEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgContactEmailMutation, { data, loading, error }] = useUpdateOrgContactEmailMutation({
 *   variables: {
 *      orgContactId: // value for 'orgContactId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateOrgContactEmailMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IUpdateOrgContactEmailMutation,
		IUpdateOrgContactEmailMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		IUpdateOrgContactEmailMutation,
		IUpdateOrgContactEmailMutationVariables
	>(UpdateOrgContactEmailDocument, options);
}
export type UpdateOrgContactEmailMutationHookResult = ReturnType<
	typeof useUpdateOrgContactEmailMutation
>;
export type UpdateOrgContactEmailMutationResult =
	Apollo.MutationResult<IUpdateOrgContactEmailMutation>;
export type UpdateOrgContactEmailMutationOptions = Apollo.BaseMutationOptions<
	IUpdateOrgContactEmailMutation,
	IUpdateOrgContactEmailMutationVariables
>;
export const UpdateOrgContactPhoneNumberDocument = gql`
	mutation UpdateOrgContactPhoneNumber($orgContactId: ID!, $phoneNumber: String!) {
		updateOrgContactPhoneNumber(orgContactId: $orgContactId, phoneNumber: $phoneNumber) {
			...ContactFields
		}
	}
	${ContactFieldsFragmentDoc}
`;
export type IUpdateOrgContactPhoneNumberMutationFn = Apollo.MutationFunction<
	IUpdateOrgContactPhoneNumberMutation,
	IUpdateOrgContactPhoneNumberMutationVariables
>;

/**
 * __useUpdateOrgContactPhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdateOrgContactPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgContactPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgContactPhoneNumberMutation, { data, loading, error }] = useUpdateOrgContactPhoneNumberMutation({
 *   variables: {
 *      orgContactId: // value for 'orgContactId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateOrgContactPhoneNumberMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IUpdateOrgContactPhoneNumberMutation,
		IUpdateOrgContactPhoneNumberMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		IUpdateOrgContactPhoneNumberMutation,
		IUpdateOrgContactPhoneNumberMutationVariables
	>(UpdateOrgContactPhoneNumberDocument, options);
}
export type UpdateOrgContactPhoneNumberMutationHookResult = ReturnType<
	typeof useUpdateOrgContactPhoneNumberMutation
>;
export type UpdateOrgContactPhoneNumberMutationResult =
	Apollo.MutationResult<IUpdateOrgContactPhoneNumberMutation>;
export type UpdateOrgContactPhoneNumberMutationOptions = Apollo.BaseMutationOptions<
	IUpdateOrgContactPhoneNumberMutation,
	IUpdateOrgContactPhoneNumberMutationVariables
>;
export const UpdateOrgContactTitleDocument = gql`
	mutation UpdateOrgContactTitle($orgContactId: ID!, $title: String!) {
		updateOrgContactTitle(orgContactId: $orgContactId, title: $title) {
			...ContactFields
		}
	}
	${ContactFieldsFragmentDoc}
`;
export type IUpdateOrgContactTitleMutationFn = Apollo.MutationFunction<
	IUpdateOrgContactTitleMutation,
	IUpdateOrgContactTitleMutationVariables
>;

/**
 * __useUpdateOrgContactTitleMutation__
 *
 * To run a mutation, you first call `useUpdateOrgContactTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgContactTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgContactTitleMutation, { data, loading, error }] = useUpdateOrgContactTitleMutation({
 *   variables: {
 *      orgContactId: // value for 'orgContactId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateOrgContactTitleMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IUpdateOrgContactTitleMutation,
		IUpdateOrgContactTitleMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		IUpdateOrgContactTitleMutation,
		IUpdateOrgContactTitleMutationVariables
	>(UpdateOrgContactTitleDocument, options);
}
export type UpdateOrgContactTitleMutationHookResult = ReturnType<
	typeof useUpdateOrgContactTitleMutation
>;
export type UpdateOrgContactTitleMutationResult =
	Apollo.MutationResult<IUpdateOrgContactTitleMutation>;
export type UpdateOrgContactTitleMutationOptions = Apollo.BaseMutationOptions<
	IUpdateOrgContactTitleMutation,
	IUpdateOrgContactTitleMutationVariables
>;
export const AddAccountDocument = gql`
	mutation addAccount($input: AddAccountInput!) {
		addAccount(input: $input) {
			id
			name
			orgInfo {
				id
			}
		}
	}
`;
export type IAddAccountMutationFn = Apollo.MutationFunction<
	IAddAccountMutation,
	IAddAccountMutationVariables
>;

/**
 * __useAddAccountMutation__
 *
 * To run a mutation, you first call `useAddAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountMutation, { data, loading, error }] = useAddAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAccountMutation(
	baseOptions?: Apollo.MutationHookOptions<IAddAccountMutation, IAddAccountMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<IAddAccountMutation, IAddAccountMutationVariables>(
		AddAccountDocument,
		options
	);
}
export type AddAccountMutationHookResult = ReturnType<typeof useAddAccountMutation>;
export type AddAccountMutationResult = Apollo.MutationResult<IAddAccountMutation>;
export type AddAccountMutationOptions = Apollo.BaseMutationOptions<
	IAddAccountMutation,
	IAddAccountMutationVariables
>;
export const UpdateUserGivenNamesDocument = gql`
	mutation updateUserGivenNames($input: UpdateUserGivenNamesInput!) {
		updateUserGivenNames(input: $input) {
			id
			firstName
			lastName
		}
	}
`;
export type IUpdateUserGivenNamesMutationFn = Apollo.MutationFunction<
	IUpdateUserGivenNamesMutation,
	IUpdateUserGivenNamesMutationVariables
>;

/**
 * __useUpdateUserGivenNamesMutation__
 *
 * To run a mutation, you first call `useUpdateUserGivenNamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGivenNamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGivenNamesMutation, { data, loading, error }] = useUpdateUserGivenNamesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserGivenNamesMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IUpdateUserGivenNamesMutation,
		IUpdateUserGivenNamesMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		IUpdateUserGivenNamesMutation,
		IUpdateUserGivenNamesMutationVariables
	>(UpdateUserGivenNamesDocument, options);
}
export type UpdateUserGivenNamesMutationHookResult = ReturnType<
	typeof useUpdateUserGivenNamesMutation
>;
export type UpdateUserGivenNamesMutationResult =
	Apollo.MutationResult<IUpdateUserGivenNamesMutation>;
export type UpdateUserGivenNamesMutationOptions = Apollo.BaseMutationOptions<
	IUpdateUserGivenNamesMutation,
	IUpdateUserGivenNamesMutationVariables
>;
export const PromptsDocument = gql`
	query prompts {
		prompts {
			id
			label
			action
			useCases
		}
	}
`;

/**
 * __usePromptsQuery__
 *
 * To run a query within a React component, call `usePromptsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromptsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePromptsQuery(
	baseOptions?: Apollo.QueryHookOptions<IPromptsQuery, IPromptsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<IPromptsQuery, IPromptsQueryVariables>(PromptsDocument, options);
}
export function usePromptsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<IPromptsQuery, IPromptsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<IPromptsQuery, IPromptsQueryVariables>(PromptsDocument, options);
}
export type PromptsQueryHookResult = ReturnType<typeof usePromptsQuery>;
export type PromptsLazyQueryHookResult = ReturnType<typeof usePromptsLazyQuery>;
export type PromptsQueryResult = Apollo.QueryResult<IPromptsQuery, IPromptsQueryVariables>;
export function refetchPromptsQuery(variables?: IPromptsQueryVariables) {
	return { query: PromptsDocument, variables: variables };
}
export const EditSmartReplyDocument = gql`
	query EditSmartReply($input: EditSmartReplyInput!) {
		editSmartReply(input: $input) {
			... on SmartReply {
				message
			}
			... on SmartReplyError {
				message
				type
			}
		}
	}
`;

/**
 * __useEditSmartReplyQuery__
 *
 * To run a query within a React component, call `useEditSmartReplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditSmartReplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditSmartReplyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSmartReplyQuery(
	baseOptions: Apollo.QueryHookOptions<IEditSmartReplyQuery, IEditSmartReplyQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<IEditSmartReplyQuery, IEditSmartReplyQueryVariables>(
		EditSmartReplyDocument,
		options
	);
}
export function useEditSmartReplyLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<IEditSmartReplyQuery, IEditSmartReplyQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<IEditSmartReplyQuery, IEditSmartReplyQueryVariables>(
		EditSmartReplyDocument,
		options
	);
}
export type EditSmartReplyQueryHookResult = ReturnType<typeof useEditSmartReplyQuery>;
export type EditSmartReplyLazyQueryHookResult = ReturnType<typeof useEditSmartReplyLazyQuery>;
export type EditSmartReplyQueryResult = Apollo.QueryResult<
	IEditSmartReplyQuery,
	IEditSmartReplyQueryVariables
>;
export function refetchEditSmartReplyQuery(variables: IEditSmartReplyQueryVariables) {
	return { query: EditSmartReplyDocument, variables: variables };
}
export const WriteSmartReplyDocument = gql`
	query WriteSmartReply($input: WriteSmartReplyInput!) {
		writeSmartReply(input: $input) {
			... on SmartReply {
				message
			}
			... on SmartReplyError {
				message
				type
			}
		}
	}
`;

/**
 * __useWriteSmartReplyQuery__
 *
 * To run a query within a React component, call `useWriteSmartReplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useWriteSmartReplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWriteSmartReplyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWriteSmartReplyQuery(
	baseOptions: Apollo.QueryHookOptions<IWriteSmartReplyQuery, IWriteSmartReplyQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<IWriteSmartReplyQuery, IWriteSmartReplyQueryVariables>(
		WriteSmartReplyDocument,
		options
	);
}
export function useWriteSmartReplyLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<IWriteSmartReplyQuery, IWriteSmartReplyQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<IWriteSmartReplyQuery, IWriteSmartReplyQueryVariables>(
		WriteSmartReplyDocument,
		options
	);
}
export type WriteSmartReplyQueryHookResult = ReturnType<typeof useWriteSmartReplyQuery>;
export type WriteSmartReplyLazyQueryHookResult = ReturnType<typeof useWriteSmartReplyLazyQuery>;
export type WriteSmartReplyQueryResult = Apollo.QueryResult<
	IWriteSmartReplyQuery,
	IWriteSmartReplyQueryVariables
>;
export function refetchWriteSmartReplyQuery(variables: IWriteSmartReplyQueryVariables) {
	return { query: WriteSmartReplyDocument, variables: variables };
}
export const SearchAccountsDocument = gql`
	query searchAccounts($accountsFilter: AccountFilters!, $pagination: Pagination) {
		admin_accounts(input: $accountsFilter, pagination: $pagination) {
			...AccountSummary
		}
	}
	${AccountSummaryFragmentDoc}
`;

/**
 * __useSearchAccountsQuery__
 *
 * To run a query within a React component, call `useSearchAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAccountsQuery({
 *   variables: {
 *      accountsFilter: // value for 'accountsFilter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchAccountsQuery(
	baseOptions: Apollo.QueryHookOptions<ISearchAccountsQuery, ISearchAccountsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ISearchAccountsQuery, ISearchAccountsQueryVariables>(
		SearchAccountsDocument,
		options
	);
}
export function useSearchAccountsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ISearchAccountsQuery, ISearchAccountsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ISearchAccountsQuery, ISearchAccountsQueryVariables>(
		SearchAccountsDocument,
		options
	);
}
export type SearchAccountsQueryHookResult = ReturnType<typeof useSearchAccountsQuery>;
export type SearchAccountsLazyQueryHookResult = ReturnType<typeof useSearchAccountsLazyQuery>;
export type SearchAccountsQueryResult = Apollo.QueryResult<
	ISearchAccountsQuery,
	ISearchAccountsQueryVariables
>;
export function refetchSearchAccountsQuery(variables: ISearchAccountsQueryVariables) {
	return { query: SearchAccountsDocument, variables: variables };
}
export const AccountsDocument = gql`
	query accounts($accountsFilter: AccountFilters!, $pagination: Pagination) {
		admin_num_accounts(input: $accountsFilter)
		admin_accounts(input: $accountsFilter, pagination: $pagination) {
			id
			name
			isActive
			type
			logoUrl
			dateCreated
			users {
				id
				firstName
				lastName
				userName
				lastSeen
				isActive
				accountId
				issues {
					level
					type
				}
			}
			orgInfo {
				id
			}
			primaryContact {
				id
			}
			accountManager {
				firstName
				lastName
				id
			}
		}
	}
`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      accountsFilter: // value for 'accountsFilter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAccountsQuery(
	baseOptions: Apollo.QueryHookOptions<IAccountsQuery, IAccountsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<IAccountsQuery, IAccountsQueryVariables>(AccountsDocument, options);
}
export function useAccountsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<IAccountsQuery, IAccountsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<IAccountsQuery, IAccountsQueryVariables>(AccountsDocument, options);
}
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<IAccountsQuery, IAccountsQueryVariables>;
export function refetchAccountsQuery(variables: IAccountsQueryVariables) {
	return { query: AccountsDocument, variables: variables };
}
export const ActivateRegularAccountDocument = gql`
	mutation activateRegularAccount($accountId: ID!) {
		activateRegularAccount(accountId: $accountId) {
			id
		}
	}
`;
export type IActivateRegularAccountMutationFn = Apollo.MutationFunction<
	IActivateRegularAccountMutation,
	IActivateRegularAccountMutationVariables
>;

/**
 * __useActivateRegularAccountMutation__
 *
 * To run a mutation, you first call `useActivateRegularAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateRegularAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateRegularAccountMutation, { data, loading, error }] = useActivateRegularAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useActivateRegularAccountMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IActivateRegularAccountMutation,
		IActivateRegularAccountMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		IActivateRegularAccountMutation,
		IActivateRegularAccountMutationVariables
	>(ActivateRegularAccountDocument, options);
}
export type ActivateRegularAccountMutationHookResult = ReturnType<
	typeof useActivateRegularAccountMutation
>;
export type ActivateRegularAccountMutationResult =
	Apollo.MutationResult<IActivateRegularAccountMutation>;
export type ActivateRegularAccountMutationOptions = Apollo.BaseMutationOptions<
	IActivateRegularAccountMutation,
	IActivateRegularAccountMutationVariables
>;
export const DeactivateRegularAccountDocument = gql`
	mutation deactivateRegularAccount($accountId: ID!) {
		deactivateRegularAccount(accountId: $accountId) {
			id
		}
	}
`;
export type IDeactivateRegularAccountMutationFn = Apollo.MutationFunction<
	IDeactivateRegularAccountMutation,
	IDeactivateRegularAccountMutationVariables
>;

/**
 * __useDeactivateRegularAccountMutation__
 *
 * To run a mutation, you first call `useDeactivateRegularAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateRegularAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateRegularAccountMutation, { data, loading, error }] = useDeactivateRegularAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeactivateRegularAccountMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IDeactivateRegularAccountMutation,
		IDeactivateRegularAccountMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		IDeactivateRegularAccountMutation,
		IDeactivateRegularAccountMutationVariables
	>(DeactivateRegularAccountDocument, options);
}
export type DeactivateRegularAccountMutationHookResult = ReturnType<
	typeof useDeactivateRegularAccountMutation
>;
export type DeactivateRegularAccountMutationResult =
	Apollo.MutationResult<IDeactivateRegularAccountMutation>;
export type DeactivateRegularAccountMutationOptions = Apollo.BaseMutationOptions<
	IDeactivateRegularAccountMutation,
	IDeactivateRegularAccountMutationVariables
>;
export const AccountDocument = gql`
	query account($adminAccountId: ID!) {
		admin_account(id: $adminAccountId) {
			id
			isActive
			name
			orgInfo {
				id
			}
			users {
				...UserDetails
			}
			primaryContact {
				id
			}
			type
			logoUrl
			dateCreated
			childAccountsSummary {
				totalUsers
				totalActiveUsers
				totalAccounts
			}
		}
	}
	${UserDetailsFragmentDoc}
`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      adminAccountId: // value for 'adminAccountId'
 *   },
 * });
 */
export function useAccountQuery(
	baseOptions: Apollo.QueryHookOptions<IAccountQuery, IAccountQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<IAccountQuery, IAccountQueryVariables>(AccountDocument, options);
}
export function useAccountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<IAccountQuery, IAccountQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<IAccountQuery, IAccountQueryVariables>(AccountDocument, options);
}
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<IAccountQuery, IAccountQueryVariables>;
export function refetchAccountQuery(variables: IAccountQueryVariables) {
	return { query: AccountDocument, variables: variables };
}
export const DeactivateUsersDocument = gql`
	mutation deactivateUsers($input: DeactivateUsersInput!) {
		deactivateUsers(input: $input) {
			id
		}
	}
`;
export type IDeactivateUsersMutationFn = Apollo.MutationFunction<
	IDeactivateUsersMutation,
	IDeactivateUsersMutationVariables
>;

/**
 * __useDeactivateUsersMutation__
 *
 * To run a mutation, you first call `useDeactivateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUsersMutation, { data, loading, error }] = useDeactivateUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateUsersMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IDeactivateUsersMutation,
		IDeactivateUsersMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<IDeactivateUsersMutation, IDeactivateUsersMutationVariables>(
		DeactivateUsersDocument,
		options
	);
}
export type DeactivateUsersMutationHookResult = ReturnType<typeof useDeactivateUsersMutation>;
export type DeactivateUsersMutationResult = Apollo.MutationResult<IDeactivateUsersMutation>;
export type DeactivateUsersMutationOptions = Apollo.BaseMutationOptions<
	IDeactivateUsersMutation,
	IDeactivateUsersMutationVariables
>;
export const ActivateUsersDocument = gql`
	mutation activateUsers($input: ActivateUsersInput!) {
		activateUsers(input: $input) {
			id
		}
	}
`;
export type IActivateUsersMutationFn = Apollo.MutationFunction<
	IActivateUsersMutation,
	IActivateUsersMutationVariables
>;

/**
 * __useActivateUsersMutation__
 *
 * To run a mutation, you first call `useActivateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUsersMutation, { data, loading, error }] = useActivateUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateUsersMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IActivateUsersMutation,
		IActivateUsersMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<IActivateUsersMutation, IActivateUsersMutationVariables>(
		ActivateUsersDocument,
		options
	);
}
export type ActivateUsersMutationHookResult = ReturnType<typeof useActivateUsersMutation>;
export type ActivateUsersMutationResult = Apollo.MutationResult<IActivateUsersMutation>;
export type ActivateUsersMutationOptions = Apollo.BaseMutationOptions<
	IActivateUsersMutation,
	IActivateUsersMutationVariables
>;
export const UpdateAccountNameDocument = gql`
	mutation updateAccountName($input: UpdateAccountNameInput!) {
		updateAccountName(input: $input) {
			name
		}
	}
`;
export type IUpdateAccountNameMutationFn = Apollo.MutationFunction<
	IUpdateAccountNameMutation,
	IUpdateAccountNameMutationVariables
>;

/**
 * __useUpdateAccountNameMutation__
 *
 * To run a mutation, you first call `useUpdateAccountNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountNameMutation, { data, loading, error }] = useUpdateAccountNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountNameMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IUpdateAccountNameMutation,
		IUpdateAccountNameMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<IUpdateAccountNameMutation, IUpdateAccountNameMutationVariables>(
		UpdateAccountNameDocument,
		options
	);
}
export type UpdateAccountNameMutationHookResult = ReturnType<typeof useUpdateAccountNameMutation>;
export type UpdateAccountNameMutationResult = Apollo.MutationResult<IUpdateAccountNameMutation>;
export type UpdateAccountNameMutationOptions = Apollo.BaseMutationOptions<
	IUpdateAccountNameMutation,
	IUpdateAccountNameMutationVariables
>;
export const UpdateAccountManagerDocument = gql`
	mutation updateAccountManager($input: UpdateAccountManagerByAccountIdInput!) {
		updateAccountManagerByAccountId(input: $input) {
			id
		}
	}
`;
export type IUpdateAccountManagerMutationFn = Apollo.MutationFunction<
	IUpdateAccountManagerMutation,
	IUpdateAccountManagerMutationVariables
>;

/**
 * __useUpdateAccountManagerMutation__
 *
 * To run a mutation, you first call `useUpdateAccountManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountManagerMutation, { data, loading, error }] = useUpdateAccountManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountManagerMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IUpdateAccountManagerMutation,
		IUpdateAccountManagerMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		IUpdateAccountManagerMutation,
		IUpdateAccountManagerMutationVariables
	>(UpdateAccountManagerDocument, options);
}
export type UpdateAccountManagerMutationHookResult = ReturnType<
	typeof useUpdateAccountManagerMutation
>;
export type UpdateAccountManagerMutationResult =
	Apollo.MutationResult<IUpdateAccountManagerMutation>;
export type UpdateAccountManagerMutationOptions = Apollo.BaseMutationOptions<
	IUpdateAccountManagerMutation,
	IUpdateAccountManagerMutationVariables
>;
export const AccountCampaignsDocument = gql`
	query accountCampaigns($campaignsInput: CampaignInput!, $adminOrganizationsInput: OrgFilters!) {
		campaigns(input: $campaignsInput) {
			...CampaignDetails
		}
		admin_organizations(input: $adminOrganizationsInput) {
			members {
				attentionItems {
					level
					memberId
					type
				}
			}
		}
	}
	${CampaignDetailsFragmentDoc}
`;

/**
 * __useAccountCampaignsQuery__
 *
 * To run a query within a React component, call `useAccountCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCampaignsQuery({
 *   variables: {
 *      campaignsInput: // value for 'campaignsInput'
 *      adminOrganizationsInput: // value for 'adminOrganizationsInput'
 *   },
 * });
 */
export function useAccountCampaignsQuery(
	baseOptions: Apollo.QueryHookOptions<IAccountCampaignsQuery, IAccountCampaignsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<IAccountCampaignsQuery, IAccountCampaignsQueryVariables>(
		AccountCampaignsDocument,
		options
	);
}
export function useAccountCampaignsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		IAccountCampaignsQuery,
		IAccountCampaignsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<IAccountCampaignsQuery, IAccountCampaignsQueryVariables>(
		AccountCampaignsDocument,
		options
	);
}
export type AccountCampaignsQueryHookResult = ReturnType<typeof useAccountCampaignsQuery>;
export type AccountCampaignsLazyQueryHookResult = ReturnType<typeof useAccountCampaignsLazyQuery>;
export type AccountCampaignsQueryResult = Apollo.QueryResult<
	IAccountCampaignsQuery,
	IAccountCampaignsQueryVariables
>;
export function refetchAccountCampaignsQuery(variables: IAccountCampaignsQueryVariables) {
	return { query: AccountCampaignsDocument, variables: variables };
}
export const AccountCampaignStatsDocument = gql`
	query accountCampaignStats($input: CampaignStatsInput!) {
		campaignStats(input: $input) {
			... on NurtureCampaignStats {
				ownerId
				ownerType
				start
				end
				numMeetingsBooked
				percentReplied
				percentInterestedReplied
			}
			... on ProspectingCampaignStats {
				ownerId
				ownerType
				start
				end
				numMeetingsBooked
				percentConnected
				percentReplied
				percentInterestedReplied
				percentInMailReplied
				percentInterestedInMailReplied
			}
		}
	}
`;

/**
 * __useAccountCampaignStatsQuery__
 *
 * To run a query within a React component, call `useAccountCampaignStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCampaignStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCampaignStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountCampaignStatsQuery(
	baseOptions: Apollo.QueryHookOptions<
		IAccountCampaignStatsQuery,
		IAccountCampaignStatsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<IAccountCampaignStatsQuery, IAccountCampaignStatsQueryVariables>(
		AccountCampaignStatsDocument,
		options
	);
}
export function useAccountCampaignStatsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		IAccountCampaignStatsQuery,
		IAccountCampaignStatsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<IAccountCampaignStatsQuery, IAccountCampaignStatsQueryVariables>(
		AccountCampaignStatsDocument,
		options
	);
}
export type AccountCampaignStatsQueryHookResult = ReturnType<typeof useAccountCampaignStatsQuery>;
export type AccountCampaignStatsLazyQueryHookResult = ReturnType<
	typeof useAccountCampaignStatsLazyQuery
>;
export type AccountCampaignStatsQueryResult = Apollo.QueryResult<
	IAccountCampaignStatsQuery,
	IAccountCampaignStatsQueryVariables
>;
export function refetchAccountCampaignStatsQuery(variables: IAccountCampaignStatsQueryVariables) {
	return { query: AccountCampaignStatsDocument, variables: variables };
}
export const TenantDataDocument = gql`
	query tenantData($tenantFilter: TenantFilters!) {
		admin_tenants(input: $tenantFilter) {
			id
			name
			childTenants {
				id
				name
				type
			}
		}
	}
`;

/**
 * __useTenantDataQuery__
 *
 * To run a query within a React component, call `useTenantDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantDataQuery({
 *   variables: {
 *      tenantFilter: // value for 'tenantFilter'
 *   },
 * });
 */
export function useTenantDataQuery(
	baseOptions: Apollo.QueryHookOptions<ITenantDataQuery, ITenantDataQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ITenantDataQuery, ITenantDataQueryVariables>(
		TenantDataDocument,
		options
	);
}
export function useTenantDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ITenantDataQuery, ITenantDataQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ITenantDataQuery, ITenantDataQueryVariables>(
		TenantDataDocument,
		options
	);
}
export type TenantDataQueryHookResult = ReturnType<typeof useTenantDataQuery>;
export type TenantDataLazyQueryHookResult = ReturnType<typeof useTenantDataLazyQuery>;
export type TenantDataQueryResult = Apollo.QueryResult<ITenantDataQuery, ITenantDataQueryVariables>;
export function refetchTenantDataQuery(variables: ITenantDataQueryVariables) {
	return { query: TenantDataDocument, variables: variables };
}
export const AdminOrgDataDocument = gql`
	query adminOrgData($orgFilter: OrgFilters!) {
		admin_organizations(input: $orgFilter) {
			id
			name
			members {
				id
				userId
				role
				isActive
			}
			customerSuccessOwner {
				id
				firstName
				lastName
			}
		}
	}
`;

/**
 * __useAdminOrgDataQuery__
 *
 * To run a query within a React component, call `useAdminOrgDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrgDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrgDataQuery({
 *   variables: {
 *      orgFilter: // value for 'orgFilter'
 *   },
 * });
 */
export function useAdminOrgDataQuery(
	baseOptions: Apollo.QueryHookOptions<IAdminOrgDataQuery, IAdminOrgDataQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<IAdminOrgDataQuery, IAdminOrgDataQueryVariables>(
		AdminOrgDataDocument,
		options
	);
}
export function useAdminOrgDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<IAdminOrgDataQuery, IAdminOrgDataQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<IAdminOrgDataQuery, IAdminOrgDataQueryVariables>(
		AdminOrgDataDocument,
		options
	);
}
export type AdminOrgDataQueryHookResult = ReturnType<typeof useAdminOrgDataQuery>;
export type AdminOrgDataLazyQueryHookResult = ReturnType<typeof useAdminOrgDataLazyQuery>;
export type AdminOrgDataQueryResult = Apollo.QueryResult<
	IAdminOrgDataQuery,
	IAdminOrgDataQueryVariables
>;
export function refetchAdminOrgDataQuery(variables: IAdminOrgDataQueryVariables) {
	return { query: AdminOrgDataDocument, variables: variables };
}
export const TwoLevelsTenantDataDocument = gql`
	query twoLevelsTenantData($tenantFilter: TenantFilters!) {
		admin_tenants(input: $tenantFilter) {
			id
			name
			type
			childTenants {
				id
				name
				type
				childTenants {
					id
					name
					type
				}
			}
		}
	}
`;

/**
 * __useTwoLevelsTenantDataQuery__
 *
 * To run a query within a React component, call `useTwoLevelsTenantDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwoLevelsTenantDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwoLevelsTenantDataQuery({
 *   variables: {
 *      tenantFilter: // value for 'tenantFilter'
 *   },
 * });
 */
export function useTwoLevelsTenantDataQuery(
	baseOptions: Apollo.QueryHookOptions<
		ITwoLevelsTenantDataQuery,
		ITwoLevelsTenantDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ITwoLevelsTenantDataQuery, ITwoLevelsTenantDataQueryVariables>(
		TwoLevelsTenantDataDocument,
		options
	);
}
export function useTwoLevelsTenantDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ITwoLevelsTenantDataQuery,
		ITwoLevelsTenantDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ITwoLevelsTenantDataQuery, ITwoLevelsTenantDataQueryVariables>(
		TwoLevelsTenantDataDocument,
		options
	);
}
export type TwoLevelsTenantDataQueryHookResult = ReturnType<typeof useTwoLevelsTenantDataQuery>;
export type TwoLevelsTenantDataLazyQueryHookResult = ReturnType<
	typeof useTwoLevelsTenantDataLazyQuery
>;
export type TwoLevelsTenantDataQueryResult = Apollo.QueryResult<
	ITwoLevelsTenantDataQuery,
	ITwoLevelsTenantDataQueryVariables
>;
export function refetchTwoLevelsTenantDataQuery(variables: ITwoLevelsTenantDataQueryVariables) {
	return { query: TwoLevelsTenantDataDocument, variables: variables };
}
export const UpdateParentTenantDocument = gql`
	mutation updateParentTenant($updateTenantInput: UpdateTenantInput!) {
		updateTenant(input: $updateTenantInput) {
			id
			name
			type
		}
	}
`;
export type IUpdateParentTenantMutationFn = Apollo.MutationFunction<
	IUpdateParentTenantMutation,
	IUpdateParentTenantMutationVariables
>;

/**
 * __useUpdateParentTenantMutation__
 *
 * To run a mutation, you first call `useUpdateParentTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParentTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParentTenantMutation, { data, loading, error }] = useUpdateParentTenantMutation({
 *   variables: {
 *      updateTenantInput: // value for 'updateTenantInput'
 *   },
 * });
 */
export function useUpdateParentTenantMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IUpdateParentTenantMutation,
		IUpdateParentTenantMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<IUpdateParentTenantMutation, IUpdateParentTenantMutationVariables>(
		UpdateParentTenantDocument,
		options
	);
}
export type UpdateParentTenantMutationHookResult = ReturnType<typeof useUpdateParentTenantMutation>;
export type UpdateParentTenantMutationResult = Apollo.MutationResult<IUpdateParentTenantMutation>;
export type UpdateParentTenantMutationOptions = Apollo.BaseMutationOptions<
	IUpdateParentTenantMutation,
	IUpdateParentTenantMutationVariables
>;
export const StopAutomationDocument = gql`
	mutation StopAutomation($campaignConnectionId: ID!) {
		stopAutomation(campaignConnectionId: $campaignConnectionId) {
			id
		}
	}
`;
export type IStopAutomationMutationFn = Apollo.MutationFunction<
	IStopAutomationMutation,
	IStopAutomationMutationVariables
>;

/**
 * __useStopAutomationMutation__
 *
 * To run a mutation, you first call `useStopAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopAutomationMutation, { data, loading, error }] = useStopAutomationMutation({
 *   variables: {
 *      campaignConnectionId: // value for 'campaignConnectionId'
 *   },
 * });
 */
export function useStopAutomationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IStopAutomationMutation,
		IStopAutomationMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<IStopAutomationMutation, IStopAutomationMutationVariables>(
		StopAutomationDocument,
		options
	);
}
export type StopAutomationMutationHookResult = ReturnType<typeof useStopAutomationMutation>;
export type StopAutomationMutationResult = Apollo.MutationResult<IStopAutomationMutation>;
export type StopAutomationMutationOptions = Apollo.BaseMutationOptions<
	IStopAutomationMutation,
	IStopAutomationMutationVariables
>;
export const CampaignsByUserDocument = gql`
	query campaignsByUser($input: CampaignInput!) {
		campaigns(input: $input) {
			id
			name
			type
			members {
				orgMemberId
				dailyOpenInMailAllocated
			}
		}
	}
`;

/**
 * __useCampaignsByUserQuery__
 *
 * To run a query within a React component, call `useCampaignsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsByUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignsByUserQuery(
	baseOptions: Apollo.QueryHookOptions<ICampaignsByUserQuery, ICampaignsByUserQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ICampaignsByUserQuery, ICampaignsByUserQueryVariables>(
		CampaignsByUserDocument,
		options
	);
}
export function useCampaignsByUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ICampaignsByUserQuery, ICampaignsByUserQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ICampaignsByUserQuery, ICampaignsByUserQueryVariables>(
		CampaignsByUserDocument,
		options
	);
}
export type CampaignsByUserQueryHookResult = ReturnType<typeof useCampaignsByUserQuery>;
export type CampaignsByUserLazyQueryHookResult = ReturnType<typeof useCampaignsByUserLazyQuery>;
export type CampaignsByUserQueryResult = Apollo.QueryResult<
	ICampaignsByUserQuery,
	ICampaignsByUserQueryVariables
>;
export function refetchCampaignsByUserQuery(variables: ICampaignsByUserQueryVariables) {
	return { query: CampaignsByUserDocument, variables: variables };
}
export const ConnectionsByConnectionStatusDocument = gql`
	query ConnectionsByConnectionStatus($input: ConnectionsByConnectionStatusInput!) {
		campaignConnectionsByConnectionStatus(input: $input) {
			count
			pages {
				current
				total
			}
			content {
				id
				campaignId
				campaignMember {
					id
					orgMemberId
				}
				contactInfo {
					id
				}
				name
				status
				title
				company
				location
				profileId
				inviteUrl
				replyPrediction
				isManualTermination
				nextAutomationNode {
					id
					name
				}
				dateCreated
			}
		}
	}
`;

/**
 * __useConnectionsByConnectionStatusQuery__
 *
 * To run a query within a React component, call `useConnectionsByConnectionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionsByConnectionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionsByConnectionStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectionsByConnectionStatusQuery(
	baseOptions: Apollo.QueryHookOptions<
		IConnectionsByConnectionStatusQuery,
		IConnectionsByConnectionStatusQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		IConnectionsByConnectionStatusQuery,
		IConnectionsByConnectionStatusQueryVariables
	>(ConnectionsByConnectionStatusDocument, options);
}
export function useConnectionsByConnectionStatusLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		IConnectionsByConnectionStatusQuery,
		IConnectionsByConnectionStatusQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		IConnectionsByConnectionStatusQuery,
		IConnectionsByConnectionStatusQueryVariables
	>(ConnectionsByConnectionStatusDocument, options);
}
export type ConnectionsByConnectionStatusQueryHookResult = ReturnType<
	typeof useConnectionsByConnectionStatusQuery
>;
export type ConnectionsByConnectionStatusLazyQueryHookResult = ReturnType<
	typeof useConnectionsByConnectionStatusLazyQuery
>;
export type ConnectionsByConnectionStatusQueryResult = Apollo.QueryResult<
	IConnectionsByConnectionStatusQuery,
	IConnectionsByConnectionStatusQueryVariables
>;
export function refetchConnectionsByConnectionStatusQuery(
	variables: IConnectionsByConnectionStatusQueryVariables
) {
	return { query: ConnectionsByConnectionStatusDocument, variables: variables };
}
export const CampaignConnectionsByMeetingsDocument = gql`
	query CampaignConnectionsByMeetings($input: ConnectionsByMeetingsInput!) {
		campaignConnectionsByMeetings(input: $input) {
			count
			pages {
				current
				total
			}
			content {
				id
				meeting {
					booked
					bookedDate
					detectedDate
				}
				campaignMember {
					id
					orgMemberId
					dailyOpenInMailAllocated
					dailyInvitesAllocated
					dailyMessagesAllocated
				}
				campaignHistory {
					campaignId
					name
					start
					end
				}
				contactInfo {
					id
				}
				name
				title
				company
				profileId
			}
		}
	}
`;

/**
 * __useCampaignConnectionsByMeetingsQuery__
 *
 * To run a query within a React component, call `useCampaignConnectionsByMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignConnectionsByMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignConnectionsByMeetingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignConnectionsByMeetingsQuery(
	baseOptions: Apollo.QueryHookOptions<
		ICampaignConnectionsByMeetingsQuery,
		ICampaignConnectionsByMeetingsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		ICampaignConnectionsByMeetingsQuery,
		ICampaignConnectionsByMeetingsQueryVariables
	>(CampaignConnectionsByMeetingsDocument, options);
}
export function useCampaignConnectionsByMeetingsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ICampaignConnectionsByMeetingsQuery,
		ICampaignConnectionsByMeetingsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ICampaignConnectionsByMeetingsQuery,
		ICampaignConnectionsByMeetingsQueryVariables
	>(CampaignConnectionsByMeetingsDocument, options);
}
export type CampaignConnectionsByMeetingsQueryHookResult = ReturnType<
	typeof useCampaignConnectionsByMeetingsQuery
>;
export type CampaignConnectionsByMeetingsLazyQueryHookResult = ReturnType<
	typeof useCampaignConnectionsByMeetingsLazyQuery
>;
export type CampaignConnectionsByMeetingsQueryResult = Apollo.QueryResult<
	ICampaignConnectionsByMeetingsQuery,
	ICampaignConnectionsByMeetingsQueryVariables
>;
export function refetchCampaignConnectionsByMeetingsQuery(
	variables: ICampaignConnectionsByMeetingsQueryVariables
) {
	return { query: CampaignConnectionsByMeetingsDocument, variables: variables };
}
export const DetectedMeetingsCountDocument = gql`
	query DetectedMeetingsCount($input: ConnectionsByMeetingsInput!) {
		campaignConnectionsByMeetings(input: $input) {
			count
		}
	}
`;

/**
 * __useDetectedMeetingsCountQuery__
 *
 * To run a query within a React component, call `useDetectedMeetingsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetectedMeetingsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetectedMeetingsCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetectedMeetingsCountQuery(
	baseOptions: Apollo.QueryHookOptions<
		IDetectedMeetingsCountQuery,
		IDetectedMeetingsCountQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<IDetectedMeetingsCountQuery, IDetectedMeetingsCountQueryVariables>(
		DetectedMeetingsCountDocument,
		options
	);
}
export function useDetectedMeetingsCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		IDetectedMeetingsCountQuery,
		IDetectedMeetingsCountQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<IDetectedMeetingsCountQuery, IDetectedMeetingsCountQueryVariables>(
		DetectedMeetingsCountDocument,
		options
	);
}
export type DetectedMeetingsCountQueryHookResult = ReturnType<typeof useDetectedMeetingsCountQuery>;
export type DetectedMeetingsCountLazyQueryHookResult = ReturnType<
	typeof useDetectedMeetingsCountLazyQuery
>;
export type DetectedMeetingsCountQueryResult = Apollo.QueryResult<
	IDetectedMeetingsCountQuery,
	IDetectedMeetingsCountQueryVariables
>;
export function refetchDetectedMeetingsCountQuery(variables: IDetectedMeetingsCountQueryVariables) {
	return { query: DetectedMeetingsCountDocument, variables: variables };
}
export const RejectDetectionDocument = gql`
	mutation RejectDetection($campaignConnectionId: ID!) {
		rejectMeetingDetection(campaignConnectionId: $campaignConnectionId) {
			id
		}
	}
`;
export type IRejectDetectionMutationFn = Apollo.MutationFunction<
	IRejectDetectionMutation,
	IRejectDetectionMutationVariables
>;

/**
 * __useRejectDetectionMutation__
 *
 * To run a mutation, you first call `useRejectDetectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectDetectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectDetectionMutation, { data, loading, error }] = useRejectDetectionMutation({
 *   variables: {
 *      campaignConnectionId: // value for 'campaignConnectionId'
 *   },
 * });
 */
export function useRejectDetectionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IRejectDetectionMutation,
		IRejectDetectionMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<IRejectDetectionMutation, IRejectDetectionMutationVariables>(
		RejectDetectionDocument,
		options
	);
}
export type RejectDetectionMutationHookResult = ReturnType<typeof useRejectDetectionMutation>;
export type RejectDetectionMutationResult = Apollo.MutationResult<IRejectDetectionMutation>;
export type RejectDetectionMutationOptions = Apollo.BaseMutationOptions<
	IRejectDetectionMutation,
	IRejectDetectionMutationVariables
>;
export const ConfirmDetectionDocument = gql`
	mutation ConfirmDetection($campaignConnectionId: ID!, $isBooked: Boolean!) {
		updateConnectionMeeting(campaignConnectionId: $campaignConnectionId, isBooked: $isBooked) {
			id
		}
	}
`;
export type IConfirmDetectionMutationFn = Apollo.MutationFunction<
	IConfirmDetectionMutation,
	IConfirmDetectionMutationVariables
>;

/**
 * __useConfirmDetectionMutation__
 *
 * To run a mutation, you first call `useConfirmDetectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmDetectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmDetectionMutation, { data, loading, error }] = useConfirmDetectionMutation({
 *   variables: {
 *      campaignConnectionId: // value for 'campaignConnectionId'
 *      isBooked: // value for 'isBooked'
 *   },
 * });
 */
export function useConfirmDetectionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IConfirmDetectionMutation,
		IConfirmDetectionMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<IConfirmDetectionMutation, IConfirmDetectionMutationVariables>(
		ConfirmDetectionDocument,
		options
	);
}
export type ConfirmDetectionMutationHookResult = ReturnType<typeof useConfirmDetectionMutation>;
export type ConfirmDetectionMutationResult = Apollo.MutationResult<IConfirmDetectionMutation>;
export type ConfirmDetectionMutationOptions = Apollo.BaseMutationOptions<
	IConfirmDetectionMutation,
	IConfirmDetectionMutationVariables
>;
export const OrganizationStatsDocument = gql`
	query OrganizationStats(
		$organizationId: ID!
		$orgMemberIds: [ID!]!
		$start: DateTime
		$end: DateTime
	) {
		campaignStats(
			input: {
				filter: {
					organizationFilters: [{ id: $organizationId, memberIds: $orgMemberIds }]
					start: $start
					end: $end
					campaignTypes: [PROSPECTING]
				}
			}
		) {
			... on NurtureCampaignStats {
				ownerId
				ownerType
				start
				end
				numSendMessages
				numUserReplies
				numInterestedReplies
				percentReplied
				percentInterestedReplied
				numMeetingsBooked
			}
			... on ProspectingCampaignStats {
				ownerId
				ownerType
				start
				end
				numUserReplies
				numUsersConnected
				numInvitesSent
				numInterestedReplies
				percentConnected
				percentReplied
				percentInterestedReplied
				numMeetingsBooked
			}
		}
	}
`;

/**
 * __useOrganizationStatsQuery__
 *
 * To run a query within a React component, call `useOrganizationStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationStatsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      orgMemberIds: // value for 'orgMemberIds'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useOrganizationStatsQuery(
	baseOptions: Apollo.QueryHookOptions<IOrganizationStatsQuery, IOrganizationStatsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<IOrganizationStatsQuery, IOrganizationStatsQueryVariables>(
		OrganizationStatsDocument,
		options
	);
}
export function useOrganizationStatsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		IOrganizationStatsQuery,
		IOrganizationStatsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<IOrganizationStatsQuery, IOrganizationStatsQueryVariables>(
		OrganizationStatsDocument,
		options
	);
}
export type OrganizationStatsQueryHookResult = ReturnType<typeof useOrganizationStatsQuery>;
export type OrganizationStatsLazyQueryHookResult = ReturnType<typeof useOrganizationStatsLazyQuery>;
export type OrganizationStatsQueryResult = Apollo.QueryResult<
	IOrganizationStatsQuery,
	IOrganizationStatsQueryVariables
>;
export function refetchOrganizationStatsQuery(variables: IOrganizationStatsQueryVariables) {
	return { query: OrganizationStatsDocument, variables: variables };
}
export const OrgMembersAndCampaignsDocument = gql`
	query OrgMembersAndCampaigns($organizationId: ID!) {
		orgMembers(input: { organizationIds: [$organizationId] }) {
			id
			isActive
			organizationId
			attentionItems {
				level
				memberId
				type
			}
			linkedInErrors {
				loggedOut
				blackListed
				hasFailedMessages
				invitesThrottled
				salesNavMissing
			}
		}
		campaignSummaries {
			id
			attentionItems {
				level
				memberId
				type
			}
			members {
				id
				orgMemberId
			}
			name
		}
	}
`;

/**
 * __useOrgMembersAndCampaignsQuery__
 *
 * To run a query within a React component, call `useOrgMembersAndCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgMembersAndCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgMembersAndCampaignsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrgMembersAndCampaignsQuery(
	baseOptions: Apollo.QueryHookOptions<
		IOrgMembersAndCampaignsQuery,
		IOrgMembersAndCampaignsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<IOrgMembersAndCampaignsQuery, IOrgMembersAndCampaignsQueryVariables>(
		OrgMembersAndCampaignsDocument,
		options
	);
}
export function useOrgMembersAndCampaignsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		IOrgMembersAndCampaignsQuery,
		IOrgMembersAndCampaignsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<IOrgMembersAndCampaignsQuery, IOrgMembersAndCampaignsQueryVariables>(
		OrgMembersAndCampaignsDocument,
		options
	);
}
export type OrgMembersAndCampaignsQueryHookResult = ReturnType<
	typeof useOrgMembersAndCampaignsQuery
>;
export type OrgMembersAndCampaignsLazyQueryHookResult = ReturnType<
	typeof useOrgMembersAndCampaignsLazyQuery
>;
export type OrgMembersAndCampaignsQueryResult = Apollo.QueryResult<
	IOrgMembersAndCampaignsQuery,
	IOrgMembersAndCampaignsQueryVariables
>;
export function refetchOrgMembersAndCampaignsQuery(
	variables: IOrgMembersAndCampaignsQueryVariables
) {
	return { query: OrgMembersAndCampaignsDocument, variables: variables };
}
export const GenerateLinkedInAuthDocument = gql`
	mutation GenerateLinkedInAuth($input: LinkedInAuthInput!) {
		generateLinkedInAuth(input: $input) {
			... on LinkedInAuthDTO {
				url
			}
			... on LinkedInAuthError {
				type
			}
		}
	}
`;
export type IGenerateLinkedInAuthMutationFn = Apollo.MutationFunction<
	IGenerateLinkedInAuthMutation,
	IGenerateLinkedInAuthMutationVariables
>;

/**
 * __useGenerateLinkedInAuthMutation__
 *
 * To run a mutation, you first call `useGenerateLinkedInAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLinkedInAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLinkedInAuthMutation, { data, loading, error }] = useGenerateLinkedInAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateLinkedInAuthMutation(
	baseOptions?: Apollo.MutationHookOptions<
		IGenerateLinkedInAuthMutation,
		IGenerateLinkedInAuthMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		IGenerateLinkedInAuthMutation,
		IGenerateLinkedInAuthMutationVariables
	>(GenerateLinkedInAuthDocument, options);
}
export type GenerateLinkedInAuthMutationHookResult = ReturnType<
	typeof useGenerateLinkedInAuthMutation
>;
export type GenerateLinkedInAuthMutationResult =
	Apollo.MutationResult<IGenerateLinkedInAuthMutation>;
export type GenerateLinkedInAuthMutationOptions = Apollo.BaseMutationOptions<
	IGenerateLinkedInAuthMutation,
	IGenerateLinkedInAuthMutationVariables
>;
