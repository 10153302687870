import { Typography } from 'antd';
import { Config } from '@copilot/common/config';
import Popover from '@copilot/common/components/containers/tooltips/popover';
import { QuestionCircleOutlined } from '@ant-design/icons';

const popoverFontColor = '#ffffff';
const replyPredictionPopoverContent = (
	<div style={{ maxWidth: '350px' }}>
		<Typography.Paragraph style={{ color: popoverFontColor }}>
			Prospects that show a higher likelihood of reply are surfaced at the top of your search
			list. Invites are prioritized to them first.
		</Typography.Paragraph>
		{Config.replyPredictionArticleURL && (
			<Typography.Link
				href={Config.replyPredictionArticleURL}
				target="_blank"
				style={{ color: popoverFontColor }}
				strong
				underline
			>
				Learn more about Reply Prediction AI
			</Typography.Link>
		)}
	</div>
);

/**
 * [Presentation] Reply Prediction information icon
 * @constructor
 */
export function ReplyPredictionInfoIcon() {
	return (
		<Popover content={replyPredictionPopoverContent} trigger="click" color="#1b2635">
			<Typography.Link>
				<QuestionCircleOutlined />
			</Typography.Link>
		</Popover>
	);
}
