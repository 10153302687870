import {
	ColumnDefinition,
	ColumnTypes,
	ColumnActionTypes,
	IconColumnDefinition,
} from '@copilot/common/components/componentModels/columnDefinition';
import { getCampaignIcon } from '@copilot/common/utils/campaign';
import { DrawerTypes } from '@copilot/common/utils/drawerManager';

export enum ContactConnectionsTableColumns {
	Name = 'name',
	Tags = 'tags',
	Title = 'title',
	Company = 'company',
	Email = 'email',
	Phone = 'phoneNumber',
	Location = 'location',
	LastUpdated = 'lastThreadActivity',
	ConnectedMember = 'orgMemberName',
	Campaign = 'campaignName',
}

const openContactDrawerAction = {
	type: ColumnActionTypes.DetailsDrawer,
	metaData: {
		type: [DrawerTypes.ContactDrawer],
		fieldValues: {
			id: 'contactId',
			memberId: 'orgMemberId',
		},
		values: {},
	},
};

export const COLUMN_MAP = {
	[ContactConnectionsTableColumns.Name]: new ColumnDefinition({
		Key: 'name',
		Properties: ['name'],
		Label: 'Name',
		Type: ColumnTypes.String,
		IsVisible: true,
		ellipsis: true,
		action: openContactDrawerAction,
	}),
	[ContactConnectionsTableColumns.Tags]: new ColumnDefinition({
		Key: 'tags',
		Properties: ['tags', 'filteredTags'],
		Label: 'Tags',
		Type: ColumnTypes.Tags,
		IsVisible: true,
		action: openContactDrawerAction,
	}),
	[ContactConnectionsTableColumns.ConnectedMember]: new ColumnDefinition({
		Key: 'orgMemberName',
		Properties: ['orgMemberName'],
		Label: 'Team Member',
		Type: ColumnTypes.StringWithId,
		IsVisible: true,
		action: openContactDrawerAction,
	}),
	[ContactConnectionsTableColumns.Title]: new ColumnDefinition({
		Key: 'position',
		Properties: ['position'],
		Label: 'Title',
		Type: ColumnTypes.String,
		IsVisible: true,
		ellipsis: true,
		action: openContactDrawerAction,
	}),
	[ContactConnectionsTableColumns.Company]: new ColumnDefinition({
		Key: 'company',
		Properties: ['company'],
		Label: 'Company',
		Type: ColumnTypes.String,
		ellipsis: true,
		IsVisible: true,
		action: openContactDrawerAction,
	}),

	[ContactConnectionsTableColumns.Email]: new ColumnDefinition({
		Key: 'email',
		Properties: ['email'],
		Label: 'Email',
		Type: ColumnTypes.String,
		IsVisible: true,
		action: openContactDrawerAction,
	}),
	[ContactConnectionsTableColumns.Phone]: new ColumnDefinition({
		Key: 'phoneNumber',
		Properties: ['phoneNumber'],
		Label: 'Phone Number',
		Type: ColumnTypes.String,
		IsVisible: true,
		action: openContactDrawerAction,
	}),
	[ContactConnectionsTableColumns.Location]: new ColumnDefinition({
		Key: 'location',
		Properties: ['location'],
		Label: 'Location',
		Type: ColumnTypes.String,
		IsVisible: true,
		action: openContactDrawerAction,
	}),
	[ContactConnectionsTableColumns.Campaign]: new IconColumnDefinition({
		Key: 'campaignName',
		Properties: ['campaignName', 'campaignType'],
		Label: 'Campaign',
		Type: ColumnTypes.TextWithIcon,
		IsVisible: true,
		action: openContactDrawerAction,
		determineIconFunction: getCampaignIcon,
	}),
	[ContactConnectionsTableColumns.LastUpdated]: new ColumnDefinition({
		Key: 'lastThreadActivity',
		Properties: ['lastThreadActivity'],
		Label: 'Last Updated',
		Type: ColumnTypes.Date,
		IsVisible: true,
		action: openContactDrawerAction,
	}),
};

export const ContactConnectionsColumns = Object.values(ContactConnectionsTableColumns).map(
	(c) => COLUMN_MAP[c]
);
