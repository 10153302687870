import { CampaignStatusEnum } from '@copilot/data/requests/models';
import { CampaignType } from '@copilot/data/responses/interface';
import { OrganizationType } from '@copilot/common/store/models/const/enum';
import { NotificationModel, NotificationType } from '@copilot/common/store/models/notification';

const NOTIFICATION_DURATION = 7;
export const NOTIFICATION_KEY = 'CampaignStatusUpdateNotification';

const ErrorNotificationBaseEnable = {
	message: 'Failed to Enable Campaign',
	description: 'Please try again.',
};

const ErrorNotificationBaseDisable = {
	message: 'Failed to Disable Campaign',
	description: 'Please try again.',
};

const ErrorNotificationDescription = {
	[CampaignStatusEnum.Enabled]: {
		[CampaignType.Prospecting]: {
			[OrganizationType.Enterprise]: ErrorNotificationBaseEnable,
			[OrganizationType.Individual]: ErrorNotificationBaseEnable,
		},
		[CampaignType.Nurture]: {
			[OrganizationType.Enterprise]: {
				message: 'Failed to Enable Campaign',
				description: "Please check that you've set daily messages",
			},
			[OrganizationType.Individual]: {
				message: 'Failed to Enable Campaign',
				description: "Please check that you've set daily messages",
				buttonLabel: 'Set Daily Messages',
				buttonType: 'primary',
			},
		},
	},
	[CampaignStatusEnum.Disabled]: {
		[CampaignType.Prospecting]: {
			[OrganizationType.Enterprise]: ErrorNotificationBaseDisable,
			[OrganizationType.Individual]: ErrorNotificationBaseDisable,
		},
		[CampaignType.Nurture]: {
			[OrganizationType.Enterprise]: ErrorNotificationBaseDisable,
			[OrganizationType.Individual]: ErrorNotificationBaseDisable,
		},
	},
};

const SuccessNotificationDescription = {
	[CampaignStatusEnum.Enabled]: {
		[CampaignType.Prospecting]: {
			[OrganizationType.Enterprise]: {
				message: 'Campaign Enabled',
				description: 'Automated tasks will begin shortly',
			},
			[OrganizationType.Individual]: {
				message: 'Campaign Enabled',
				buttonLabel: 'Allocate Invites',
				buttonType: 'primary',
			},
		},
		[CampaignType.Nurture]: {
			[OrganizationType.Enterprise]: {
				message: 'Campaign Enabled',
				description: 'Automated Messaging will begin shortly',
			},
			[OrganizationType.Individual]: {
				message: 'Campaign Enabled',
				description: 'Automated Messaging will begin shortly',
			},
		},
	},
	[CampaignStatusEnum.Disabled]: {
		[CampaignType.Prospecting]: {
			[OrganizationType.Enterprise]: {
				message: 'Campaign Disabled',
				description: 'Invites for this campaign have stopped sending',
			},
			[OrganizationType.Individual]: {
				message: 'Campaign Disabled',
				description: 'Invites for this campaign have stopped sending',
				buttonLabel: 'Manage Invites',
			},
		},
		[CampaignType.Nurture]: {
			[OrganizationType.Enterprise]: {
				message: 'Campaign Disabled',
				description: 'Automated Messaging has stopped',
			},
			[OrganizationType.Individual]: {
				message: 'Campaign Disabled',
				description: 'Automated Messaging has stopped',
			},
		},
	},
};

export const getCampaignToggleNotification = (
	notificationType: NotificationType,
	isSuccess: boolean,
	status: CampaignStatusEnum,
	campaignType: CampaignType,
	orgType: OrganizationType
) => {
	const acceptedStatus =
		status === CampaignStatusEnum.Enabled
			? CampaignStatusEnum.Enabled
			: CampaignStatusEnum.Disabled;
	const notificationContent = isSuccess
		? SuccessNotificationDescription[acceptedStatus][campaignType][orgType]
		: ErrorNotificationDescription[acceptedStatus][campaignType][orgType];
	return {
		key: NOTIFICATION_KEY,
		type: notificationType,
		duration: NOTIFICATION_DURATION,
		content: notificationContent,
	} as NotificationModel;
};
