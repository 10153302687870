import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { CopyOutlined, DeleteOutlined, MoreOutlined, EditOutlined } from '@ant-design/icons';
import {
	Col,
	Row,
	Button,
	Dropdown,
	Menu,
	Popconfirm,
	Tag,
	Spin,
	Input,
	Typography,
	InputRef,
	Space,
	theme,
} from 'antd';
import { useFetch } from '@copilot/common/hooks/common';
import { CampaignManager } from '@copilot/data';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import { useDispatch, useSelector } from 'react-redux';
import { CampaignFields } from '@copilot/common/store/models/redux';
import { useHistory } from 'react-router-dom';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useCampaignActiveToggle } from '@copilot/common/hooks/campaign';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { getCampaignIcon } from '@copilot/common/utils/campaign';
import CampaignStatusDisplay from '@copilot/common/components/campaignStatusDisplay';
import { useCampaignMemberLinkedInfoFetch } from '@copilot/common/hooks/requests/campaignMember';
import { useCanEditPermission } from '@copilot/common/hooks/permission';
import { PermissionName } from '@copilot/common/hooks/permission/interface';
import { CampaignMemberSelectors } from '@copilot/common/store/selectors/campaignMember';
import { blue, grey } from '@ant-design/colors';
import { fontSizeMD, spacingXS, spacingXSS } from '@copilot/common/constant/commonStyles';

const { Text } = Typography;
const { useToken } = theme;

export enum CampaignMenuOptions {
	Rename,
	Duplicate,
	Delete,
}

const DefaultTeamsCampaignMenuOptions: CampaignMenuOptions[] = [
	CampaignMenuOptions.Duplicate,
	CampaignMenuOptions.Delete,
];

interface CampaignDashboardHeaderProps {
	campaign: CampaignFields;
	hideUsageLimits?: boolean;
	hideCampaignStatus?: boolean;
	dropdownMenuOptions?: CampaignMenuOptions[];
}

const statusIconSize = '20px';

/**
 * Header of the the campaign dashboard
 * @param {CampaignFields} campaign current campaign
 * @param {boolean} hideUsageLimits hides the usage limits (individuals only, always hidden for teams)
 * @param {boolean} hideCampaignStatus hides the campaign status (admins only, always hidden for non-admins)
 * @param {CampaignMenuOptions[]} dropdownMenuOptions options for the dropdown menu
 */
const CampaignDashboardHeader: React.FC<CampaignDashboardHeaderProps> = (props) => {
	const { campaign, hideCampaignStatus = false, dropdownMenuOptions } = props;
	const dispatch = useDispatch();
	const history = useHistory();
	const isSettingsEditable = useCanEditPermission(PermissionName.Settings);
	const [, activeToggle] = useCampaignActiveToggle(props.campaign?.id ?? '');

	const [, loadCampaign] = useFetch(
		CampaignManager.getCampaignByCamapignId,
		CampaignActions.loadCampaign
	);
	const [nameUpdate, updateName] = useFetch(
		CampaignManager.renameCampaign,
		CampaignActions.loadCampaign
	);

	const [, fetchLinkedInMemberInfo] = useCampaignMemberLinkedInfoFetch();
	//initialize the default state
	const [unsavedCampaignName, setUnsavedCampaignName] = useState(campaign.name);
	const [isEditingName, setIsEditingName] = useState<boolean>(false);

	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const campaignMember = useSelector(
		CampaignMemberSelectors.getByCampaignAndOrgMember(
			activeMember?.id ?? '',
			campaign?.id ?? ''
		)
	);

	//maintain the consistency of the state to the current campaign
	useEffect(() => {
		setUnsavedCampaignName(campaign.name);
		setIsEditingName(false);
	}, [campaign.name]);
	useEffect(() => {
		if (campaignMember?.id) {
			fetchLinkedInMemberInfo(campaign.id, campaignMember.id);
		}
	}, [campaign.id, campaignMember?.id]);

	const handleNameUpdate = useCallback(() => {
		//TODO: Duplicate check of the Campaign Name
		if (campaign?.id && campaign.name !== unsavedCampaignName)
			updateName?.(campaign.id, unsavedCampaignName);
		setIsEditingName(false);
	}, [campaign?.id, updateName, unsavedCampaignName]);

	const duplicateCampaign = useCallback(() => {
		campaign?.id &&
			CampaignManager.cloneCampaign(campaign.id, `Copy of ${campaign.name}`)
				.then((campaignId: string) => {
					if (campaignId) {
						loadCampaign(campaignId);
					}
					notificationManager.showSuccessNotification({
						message: 'Campaign duplicated',
						description: 'Campaign has been duplicated.',
					});
				})
				.catch(() => {
					notificationManager.showErrorNotification({
						message: 'Failed',
						description: 'Please refresh the page and try again',
					});
				});
	}, [campaign?.id]);

	const deleteCampaign = useCallback(() => {
		campaign?.id &&
			CampaignManager.deleteCampaign(campaign.id)
				.then(() => {
					dispatch(CampaignActions.deleteCampaign({ id: campaign.id }));
					notificationManager.showSuccessNotification({
						message: 'Campaign Deleted',
						description: 'Campaign has been deleted.',
					});
					history.replace('/');
				})
				.catch(() => {
					notificationManager.showErrorNotification({
						message: 'Failed',
						description: 'Please refresh the page and try again',
					});
				});
	}, [campaign?.id]);

	const menu = useMemo(() => {
		return (
			<Menu>
				{DefaultTeamsCampaignMenuOptions.map((option: CampaignMenuOptions) => {
					switch (option) {
						case CampaignMenuOptions.Duplicate:
							return (
								<Menu.Item key="duplicate">
									<Button type="link" onClick={duplicateCampaign}>
										<CopyOutlined />
										<span>Duplicate Campaign</span>
									</Button>
								</Menu.Item>
							);
						case CampaignMenuOptions.Delete:
							return (
								<Menu.Item key="delete">
									<Popconfirm
										placement="topLeft"
										title="Are you sure you want to delete this campaign?"
										onConfirm={deleteCampaign}
										okText="Yes"
										cancelText="No"
									>
										<Button type="link" style={{ color: 'red' }}>
											<DeleteOutlined />
											Delete Campaign
										</Button>
									</Popconfirm>
								</Menu.Item>
							);
						default:
							throw new TypeError('Case not handled');
					}
				})}
			</Menu>
		);
	}, [dropdownMenuOptions, duplicateCampaign, deleteCampaign]);

	const newCampaignTypeTag = (
		<Tag
			icon={getCampaignIcon(campaign.type)}
			style={{
				backgroundColor: '#f1f5fb',
				border: 0,
				padding: '5px 7px 3px 7px',
				fontSize: fontSizeMD,
			}}
		/>
	);

	const inputFieldRef = useRef<InputRef>(null);
	useEffect(() => {
		if (inputFieldRef.current && isEditingName) inputFieldRef.current.focus();
	}, [isEditingName]);

	const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setUnsavedCampaignName(e.target.value);
	}, []);

	/// [COPILOT-6105] wrapped with useMemo to prevent re-rendering
	const CampaignOptionsDropdown = useMemo(
		() => (
			<Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
				<Button type="link" style={{ padding: '0px', marginTop: spacingXS }}>
					<h4>
						<MoreOutlined />
					</h4>
				</Button>
			</Dropdown>
		),
		[menu]
	);

	const nameEditor = (
		<Input
			type="text"
			value={unsavedCampaignName}
			onChange={handleInputChange}
			onPressEnter={handleNameUpdate}
			onBlur={handleNameUpdate}
			ref={inputFieldRef}
		/>
	);

	const { token } = useToken();
	return (
		<>
			<Row gutter={[8, 0]} align={'middle'}>
				<Col span={18}>
					<Space>
						{newCampaignTypeTag}
						<Spin spinning={nameUpdate.isFetching}>
							{isSettingsEditable && isEditingName ? (
								nameEditor
							) : (
								<Text
									ellipsis
									style={{
										verticalAlign: 'middle',
										fontSize: token.fontSizeHeading5,
									}}
								>
									{campaign.name}
								</Text>
							)}
						</Spin>
						{isSettingsEditable ? (
							<EditOutlined
								style={{
									fontSize: fontSizeMD,
									color: blue[5],
									marginLeft: spacingXSS,
								}}
								onClick={() => setIsEditingName(true)}
							/>
						) : null}
					</Space>
				</Col>
				{isSettingsEditable && (
					<>
						{!hideCampaignStatus ? (
							<>
								<Col span={4} style={{ margin: '0 4px 0 -12px' }}>
									<span
										style={{
											fontSize: fontSizeMD,
											color: grey[5],
											textAlign: 'right',
											display: 'block',
										}}
									>
										Campaign status
									</span>
								</Col>
								<Col>
									<CampaignStatusDisplay
										approvalStatus={campaign.approvalStatus}
										isEnabled={!!+campaign.status}
										isPaused={!!activeMember?.unpauseDate}
										statusIconSize={statusIconSize}
										checkedStatusText="ON"
										uncheckedStatusText="OFF"
										statusToggleStyle={{ width: 'unset' }}
										onToggleChange={activeToggle}
										campaignType={campaign.type}
									/>
								</Col>
								<Col>{CampaignOptionsDropdown}</Col>
							</>
						) : (
							<>
								<Col span={5} />
								<Col>{CampaignOptionsDropdown}</Col>
							</>
						)}
					</>
				)}
			</Row>
		</>
	);
};
export default CampaignDashboardHeader;
