import React, { useCallback, useState } from 'react';
import { Row, Col, Checkbox, Button, Modal } from 'antd';
import BaseModal from '../base';
import WistiaPlayer from '@copilot/common/components/embed/wistia';
import { LinkedInSearchType } from '@copilot/data/responses/interface';
import { VideoModalDetails, VideoModalDetailsType } from './const';

interface VideoModalContentProps {
	videoModalDetails: VideoModalDetailsType;
	hideFooter: boolean;
	skipHelpPreference: boolean;
	onSkipHelpPreferenceToggled: (preference: boolean) => void;
	onSearchViewSwitch: (e: React.MouseEvent<HTMLButtonElement>) => void;
	onFooterOkClicked: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
const VideoModalContent: React.FC<VideoModalContentProps> = (props) => {
	const {
		videoModalDetails,
		hideFooter,
		skipHelpPreference,
		onSkipHelpPreferenceToggled,
		onSearchViewSwitch,
		onFooterOkClicked,
	} = props;
	return (
		<>
			<Row>
				<Col span={24}>
					<h2>{videoModalDetails.title}</h2>
					<p>{videoModalDetails.description}</p>
				</Col>
			</Row>
			<Row justify="center" gutter={[16, 16]}>
				<Col span={24}>
					<WistiaPlayer videoHash={videoModalDetails.url} />
				</Col>
			</Row>
			<br />
			{!hideFooter && (
				<Row justify="space-between" gutter={[16, 16]}>
					<Col>
						<Checkbox
							onChange={(e) => {
								onSkipHelpPreferenceToggled(e.target.checked);
							}}
							checked={skipHelpPreference}
						>
							Don't show this again
						</Checkbox>
					</Col>
					<Col>
						<Row gutter={[16, 16]}>
							<Col>
								<Button onClick={onSearchViewSwitch}>
									Switch to our user friendly search
								</Button>
							</Col>
							<Col>
								<Button type="primary" onClick={onFooterOkClicked}>
									Got it
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			)}
		</>
	);
};

interface VideoModalProps extends React.ComponentProps<typeof Modal> {
	videoType: string;
	skipPreference: boolean;
	hideFooter?: boolean;
	onHandleCommit?: (view: LinkedInSearchType, preference: boolean) => void;
}

const VideoModal = React.memo<VideoModalProps>((props) => {
	const { videoType, skipPreference, hideFooter = false, onHandleCommit, ...rest } = props;
	const [skipHelpPreference, setSkipHelpPreference] = useState<boolean>(skipPreference);

	const handleCommit = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>, searchType: LinkedInSearchType) => {
			onHandleCommit?.(searchType, skipHelpPreference);
			props.onCancel?.(event);
		},
		[onHandleCommit, props.onCancel, skipHelpPreference]
	);

	const onSearchViewSwitch = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			handleCommit(e, LinkedInSearchType.Criteria);
		},
		[handleCommit]
	);

	const onFooterOkClicked = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			handleCommit(e, LinkedInSearchType.Url);
		},
		[handleCommit]
	);

	return (
		<BaseModal {...rest}>
			<VideoModalContent
				videoModalDetails={VideoModalDetails[videoType]}
				hideFooter={hideFooter}
				skipHelpPreference={skipHelpPreference}
				onSkipHelpPreferenceToggled={setSkipHelpPreference}
				onSearchViewSwitch={onSearchViewSwitch}
				onFooterOkClicked={onFooterOkClicked}
			/>
		</BaseModal>
	);
});

export default VideoModal;
