import { ReactNode, useCallback, useReducer } from 'react';
import BasicContainer from '@copilot/common/components/containers/basic';
import {
	createUpdateColumnContextListAction,
	createUpdateHiddenColumnsAction,
	createUpdateSelectedRowsAction,
} from './util';
import { tableReducer } from './reducer';
import { INITIAL_CONTEXT_STATE, TableContextAny, TableContextColumn } from './types';

type TableModuleProps = {
	header: ReactNode;
	table: ReactNode;
};

/**
 * [Presentational] Table module component to compose tables with common functionalities like CSV export and column selection
 * @param {ITableModuleProps} props
 */
function TableModule({ header, table }: TableModuleProps) {
	const [contextReducer, dispatch] = useReducer(tableReducer, INITIAL_CONTEXT_STATE);
	const updateColumnContextList = useCallback(
		(columns: TableContextColumn[]) => dispatch(createUpdateColumnContextListAction(columns)),
		[dispatch]
	);
	const updateSelectedRows = useCallback(
		(selectedRows: Record<string, any>[]) =>
			dispatch(createUpdateSelectedRowsAction(selectedRows)),
		[dispatch]
	);
	const updateHiddenColumns = useCallback(
		(columns: { columnKey: string; isVisible: boolean }[]) =>
			dispatch(createUpdateHiddenColumnsAction(columns)),
		[dispatch]
	);
	return (
		<TableContextAny.Provider
			value={{
				state: contextReducer,
				actions: {
					updateColumnContextList,
					updateSelectedRows,
					updateHiddenColumns,
				},
			}}
		>
			<BasicContainer>
				<BasicContainer.Header>{header}</BasicContainer.Header>
				<BasicContainer.Content style={{ padding: '0px' }}>{table}</BasicContainer.Content>
			</BasicContainer>
		</TableContextAny.Provider>
	);
}

export default TableModule;
