export enum LinkedInLoginWizardSteps {
	Login,
	Pin,
	Done,
	Loading,
	Error,
}

export enum LinkedInLoginErrorMessages {
	Email = "You've entered the wrong LinkedIn email or password. Please try again.",
	Password = "You've entered the wrong LinkedIn email or password. Please try again.",
	Pin = 'Verification Pin was invalid. Please verify the pin matches the one sent from LinkedIn.',
}
