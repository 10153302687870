import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Row, Collapse, Select } from 'antd';
import BubbleCheckboxGroup from '@copilot/common/components/forms/common/generics/checkboxes/bubbleGroup';
import styled from 'styled-components';

interface IOccupationGroup {
	label: string;
	options: { key: string; value: string; label: string }[];
}

interface OccupationProps {
	options: IOccupationGroup[];
	onUpdate?: (values: string[]) => unknown;
	value: string[];
	otherOccupations: string[];
	onOtherOccupationsUpdate: (values: string[]) => unknown;
}

const convertValues = (groups: IOccupationGroup[], values: string[]) => {
	const groupMap = groups.reduce((acc, v) => {
		v.options.forEach((o) => {
			acc[o.value] = v.label;
		});
		return acc;
	}, {} as { [k: string]: string });

	return groups.reduce((acc, g) => {
		acc[g.label] = values.filter((v) => groupMap[v] === g.label);
		return acc;
	}, {} as { [k: string]: string[] });
};

const CountSpan = styled.span`
	margin-right: 10px;
`;

const LinkedInSearchOccupation: React.FC<OccupationProps> = (props) => {
	const {
		options = [],
		onUpdate,
		value = [],
		otherOccupations = [],
		onOtherOccupationsUpdate,
	} = props;
	const [selectedValues, setSelectedValues] = useState<{ [k: string]: string[] }>(
		convertValues(options, value)
	);

	useEffect(() => {
		const values = Object.values(selectedValues).reduce((acc, v) => [...acc, ...v], []);
		onUpdate?.(values);
	}, [selectedValues, onUpdate]);

	const handleCheckboxUpdateFactory = useCallback(
		(label: string) => (values: string[]) => {
			setSelectedValues((v) => ({ ...v, [label]: values }));
		},
		[]
	);

	const panels = useMemo(
		() =>
			options.map((group) => {
				const header = group.label ? (
					<Row justify="space-between">
						<Col>{group.label}</Col>
						<Col>
							<CountSpan>
								{selectedValues[group.label]
									? selectedValues[group.label].length
									: 0}
								/{group.options.length}
							</CountSpan>
							<strong>
								<PlusOutlined />
							</strong>
						</Col>
					</Row>
				) : (
					''
				);
				return (
					<Collapse.Panel
						key={group.label}
						showArrow={false}
						header={header}
						style={{ backgroundColor: '#EDEDED' }}
					>
						<Row>
							<Col>
								<BubbleCheckboxGroup
									showCheckAll
									options={group.options}
									onUpdate={handleCheckboxUpdateFactory(group.label)}
									selectedValues={selectedValues[group.label]}
								/>
							</Col>
						</Row>
					</Collapse.Panel>
				);
			}),
		[options, selectedValues]
	);

	const defaultOpenPanels = useMemo(() => {
		const result = [];
		for (let i = 0; i < options.length; i++) {
			if (selectedValues[options[i].label] && selectedValues[options[i].label].length > 0)
				result.push(options[i].label);
		}
		return result.length > 0 ? result : [options[0]?.label ?? ''];
	}, [options, selectedValues]);

	return (
		<Row>
			<Col span={24}>
				<Row>
					<Col>
						<h1>Occupation</h1>
					</Col>
				</Row>
				<Row>
					<Col>
						<p>Please select the occupations of your ideal prospects.</p>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Collapse defaultActiveKey={defaultOpenPanels}>{panels}</Collapse>
					</Col>
				</Row>
				<br />
				<br />
				<Row>
					<Col>
						<h3>Add additional occupational titles for your search</h3>
						<p style={{ fontSize: '14px' }}>
							Separate multiple titles with commas or press Enter.
						</p>
					</Col>
				</Row>
				<Row style={{ display: 'block' }}>
					<Col>
						<Select
							mode="tags"
							style={{ width: '100%' }}
							maxTagCount={25}
							showArrow={false}
							tokenSeparators={[',']}
							placeholder="Optional"
							value={otherOccupations}
							onChange={onOtherOccupationsUpdate}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default LinkedInSearchOccupation;
