import React from 'react';
import { Row, Typography } from 'antd';

const { Title, Text } = Typography;

/**
 * Properties to display product summary.
 */
interface ProductSummaryProps {
	productName: string;
	productInterval: string;
	formattedAmount: string;
}

/**
 * A summary of a priced product.
 */
const ProductSummary: React.FC<ProductSummaryProps> = ({
	productName,
	productInterval,
	formattedAmount,
}) => (
	<>
		<Title level={5}>Plan</Title>
		<Row justify="space-between">
			<Text>{productName}</Text>
			<Text className="text-right">{formattedAmount}</Text>
		</Row>
		<Row>
			<Text type="secondary">{productInterval}</Text>
		</Row>
	</>
);

export default ProductSummary;
