import { ICampaignType } from '@copilot/data/graphql/_generated';

// Thresholds for campaign metrics
// rate that is equal of lower than 15
export const ALERT_CONNECTION_INTERESTED_RATE = 15;
// rate that is equal or higher than 20
export const SUCCESS_CONNECTION_INTERESTED_RATE = 20;
// rate that is equal of lower than 10
export const ALERT_REPLY_RATE = 10;
// rate that is equal or higher than 15
export const SUCCESS_REPLY_RATE = 15;

// Type name string for prospecting campaign stats
export const PROSPECTING_STATS_TYPENAME = 'ProspectingCampaignStats';
// Type name string for nurture campaign stats
export const NURTURE_STATS_TYPENAME = 'NurtureCampaignStats';

// Mapping from ICampaignType to campaign stat type name
export const CampaignTypeToStatTypeMapping = {
	[ICampaignType.Prospecting]: PROSPECTING_STATS_TYPENAME,
	[ICampaignType.Nurture]: NURTURE_STATS_TYPENAME,
};

// String representing no information
export const BLANK_STATE_STRING = '-';
