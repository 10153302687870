import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import EllipsisParagraph from '@copilot/common/components/common/ellipsisText';
import { IHistory } from '@copilot/data/responses/interface';
import { ProspectDrawerTrackingParams } from '../tracking';

interface AdditionalInfo {
	history: IHistory<string>[];
	campaignName: string;
	organizationMemberName: string;
	dateCreated: string;
	contactUrl: string;
}

export interface ProspectDataProps {
	additionalInfo: AdditionalInfo;
	onTracking?: (params: ProspectDrawerTrackingParams) => void;
}
const ProspectData: React.FC<ProspectDataProps> = (props) => {
	const { additionalInfo, onTracking } = props;
	if (!additionalInfo) return null;

	const history = useMemo(
		() =>
			additionalInfo.history.map((c) => (
				<div key={c.dates[0]}>
					<strong>
						<EllipsisParagraph text={c.data} />
					</strong>
					{`${c.dates[0]} - ${c.dates[1]}`}
				</div>
			)),
		[additionalInfo.history]
	);
	return (
		<>
			<br />
			<Row justify="space-between">
				<Col span={12}>
					<span>
						<h3 className="bold-text">Campaign History</h3>
						{history}
					</span>
				</Col>
				<Col span={12}>
					<span>
						<h3 className="bold-text">Added to Contacts</h3>
						{additionalInfo.dateCreated}
					</span>
				</Col>
			</Row>
			<br />
			<Row>
				<Col span={12}>
					<span>
						<h3 className="bold-text">Team Members Connected</h3>
						{additionalInfo.organizationMemberName}
					</span>
				</Col>{' '}
				<Col span={12}>
					<span>
						<h3 className="bold-text">LinkedIn URL</h3>
						<a
							target="_blank"
							onClick={() =>
								onTracking?.({ buttonClicked: 'Prospect Info LinkedIn URL' })
							}
							href={additionalInfo.contactUrl}
						>
							{additionalInfo.contactUrl}
						</a>
					</span>
				</Col>
			</Row>
		</>
	);
};

export default ProspectData;
