import React, { useCallback, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Icon, {
	DashboardOutlined as DashboardOutlinedIcon,
	UserOutlined as UserOutlinedIcon,
	TeamOutlined as TeamOutlinedIcon,
	MessageOutlined as MessageOutlinedIcon,
	HourglassOutlined as HourglassOutlinedIcon,
	NotificationOutlined as NotificationOutlinedIcon,
	UploadOutlined as UploadOutlinedIcon,
	SettingOutlined as SettingOutlinedIcon,
	DollarOutlined as DollarOutlinedIcon,
	RocketOutlined as RocketOutlinedIcon,
} from '@ant-design/icons';
import { Row, Col, Menu, Tooltip, Badge } from 'antd';
import { MenuProps } from 'antd/lib/menu';

import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { NotificationsSelectors } from '@copilot/common/store/selectors/notifications';
import { INotification } from '@copilot/common/store/models/redux';
import { NotificationType } from '@copilot/common/store/models/const/enum';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { AppSelectors } from '@copilot/common/store/selectors';
import { Config } from '@copilot/common/config';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { ReactComponent as PeopleSearchOutlinedIcon } from '@copilot/common/assets/icon/peopleSearch.svg';
import { ReactComponent as SproutOutlinedIcon } from '@copilot/common/assets/icon/sprout.svg';
import { InboxDisplayType } from '@copilot/data/requests/models';
import { AgencyCode } from '@copilot/common/config/constant';
import { useHistory } from 'react-router';

export const MenuKeys = {
	Root: '/',
	Inbox: '/inbox',
	Outbox: '/outbox',
	Sent: '/sent',
	Campaign: '/campaign',
	Connections: '/connections',
	Settings: '/settings',
	Referral: '/referral',
};

//TODO: CPD-2708 Update to pass icon as component instead of MenuIconType
export interface BaseMenuItem<IType> {
	key: string;
	render?: (item: IType) => React.ReactNode;
	label?: string;
	icon?: MenuIconType;
	onClick?: () => unknown;
}

export interface IMenuItem extends BaseMenuItem<IMenuItem> {
	to?: string;
	notification?: React.ReactElement;
	badge?: React.ReactElement;
}

export interface IMenuGroupItem extends BaseMenuItem<IMenuGroupItem> {
	subMenuItems: IMenuItem[];
	notification?: React.ReactElement;
}

interface SidebarProps {
	header?: React.ReactNode;
	menuOptions: MenuProps;
	menuItems: (IMenuItem | IMenuGroupItem)[];
}
//just normal CSS. ths ` thing is for opening a string
//height is using props to grab the header height of 50px
const LogoImage = styled.img`
	display: block;
	height: ${(props) => props.theme['@layout-header-height']};
	margin: 0px auto;
`;

const EllipsisLink = styled.a`
	overflow: hidden;
	text-overflow: ellipsis;
`;

// menu icon types must be defined here and specified in getIcon
export enum MenuIconType {
	DashboardOutlined = 1, // add 1 to prevent "0" being displayed
	UserOutlined,
	TeamOutlined,
	MessageOutlined,
	HourglassOutlined,
	NotificationOutlined,
	UploadOutlined,
	SettingOutlined,
	DollarOutlined,
	RocketOutlined,
	PeopleSearchOutlined,
	SproutOutlined,
}

export const getIcon = (iconType: MenuIconType) => {
	switch (iconType) {
		case MenuIconType.DashboardOutlined:
			return <DashboardOutlinedIcon />;
		case MenuIconType.UserOutlined:
			return <UserOutlinedIcon />;
		case MenuIconType.TeamOutlined:
			return <TeamOutlinedIcon />;
		case MenuIconType.MessageOutlined:
			return <MessageOutlinedIcon />;
		case MenuIconType.HourglassOutlined:
			return <HourglassOutlinedIcon />;
		case MenuIconType.NotificationOutlined:
			return <NotificationOutlinedIcon />;
		case MenuIconType.UploadOutlined:
			return <UploadOutlinedIcon />;
		case MenuIconType.SettingOutlined:
			return <SettingOutlinedIcon />;
		case MenuIconType.DollarOutlined:
			return <DollarOutlinedIcon />;
		case MenuIconType.RocketOutlined:
			return <RocketOutlinedIcon />;
		case MenuIconType.PeopleSearchOutlined:
			return <Icon component={PeopleSearchOutlinedIcon} />;
		case MenuIconType.SproutOutlined:
			return <Icon component={SproutOutlinedIcon} />;
		default:
			return UtilityFunctions.assertUnreachable(iconType);
	}
};

interface MenuLabelProps extends Pick<BaseMenuItem<IMenuItem | IMenuGroupItem>, 'label' | 'icon'> {
	notification?: React.ReactElement;
	badge?: React.ReactElement;
}

//TODO: CPD-2708 Update to pass icon as component instead of MenuIconType
const MenuLabel: React.FC<MenuLabelProps> = (props) => {
	const { icon, label, notification, badge } = props;
	return (
		<>
			{icon && getIcon(icon)}
			{label && <span>{label}</span>}
			{notification && <span>{notification}</span>}
			{badge && <span style={{ float: 'right' }}>{badge}</span>}
		</>
	);
};

const MenuItem: React.FC<IMenuItem> = (props) => {
	const { key, to, render, label, icon, notification, badge, ...rest } = props;
	const history = useHistory();
	const contents = React.useMemo(
		() =>
			render ? (
				render(props)
			) : (
				<MenuLabel label={label} icon={icon} notification={notification} badge={badge} />
			),
		[render, label, icon, notification]
	);
	function onClick(e: React.MouseEvent<HTMLAnchorElement>) {
		e.preventDefault();
		if (to) {
			const shouldOpenNewTab = e.ctrlKey || e.metaKey;
			shouldOpenNewTab ? window.open(to) : history.push(to);
		}
	}

	return (
		<Menu.Item key={key} {...rest}>
			{to && (
				<EllipsisLink onClick={onClick} rel="noopener noreferrer">
					{contents}
				</EllipsisLink>
			)}
			{!to && <div>{contents}</div>}
		</Menu.Item>
	);
};

const MenuGroupItem: React.FC<IMenuGroupItem> = (props) => {
	const { key, render: _, label, icon, subMenuItems, notification, ...rest } = props;
	const titleElement = React.useMemo(
		() => <MenuLabel icon={icon} label={label} notification={notification} />,
		[icon, label, notification]
	);

	return (
		<Menu.SubMenu {...rest} key={key} title={titleElement}>
			{subMenuItems.map((i) => (
				<MenuItem {...i} />
			))}
		</Menu.SubMenu>
	);
};

const getLogo = () => {
	if (Config.isAgency) {
		switch (Config.agencyCode) {
			case AgencyCode.cleverly:
				return require('../../../assets/images/cleverly.png');
			case AgencyCode.prosocial:
			default:
				return require('../../../assets/images/sociallylogo.png');
		}
	} else {
		return require('../../../assets/images/copilot_white.png');
	}
};

const Sidebar: React.FC<SidebarProps> = (props) => {
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const logo = getLogo();
	const logoSrc = React.useMemo(() => {
		if (!activeOrganization || !activeOrganization || !activeOrganization.name)
			return undefined;
		return activeOrganization.logoUrl ? activeOrganization.logoUrl : logo;
	}, [activeOrganization]);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const notification = useSelector(NotificationsSelectors.getNotifications);

	const appSettings = useSelector(AppSelectors.getSettings);
	const themeContext = useContext(ThemeContext);
	const getNotificationDisplay = useCallback(
		(notificationMap: { [x: string]: INotification }, key: string) => {
			let color = '';
			let number = 0;
			switch (key) {
				case MenuKeys.Inbox:
					if (
						appSettings &&
						appSettings.inboxType === InboxDisplayType.Email &&
						notificationMap &&
						notificationMap[NotificationType.Inbox] &&
						parseInt(notificationMap[NotificationType.Inbox].body) > 0
					) {
						color = themeContext['@notification-display-color'];
						number = parseInt(notificationMap[NotificationType.Inbox].body);
					}
					break;
				// case MenuKeys.Sent:
				// 	if (
				// 		notificationMap &&
				// 		notificationMap[NotificationType.Sent] &&
				// 		parseInt(notificationMap[NotificationType.Sent].body) > 0
				// 	) {
				// 		color = '#005bbd';
				// 		number = parseInt(notificationMap[NotificationType.Sent].body);
				// 	}
				// 	break;
				case MenuKeys.Outbox:
					if (
						notificationMap[NotificationType.Pending] &&
						parseInt(notificationMap[NotificationType.Pending].body) > 0
					) {
						color = themeContext['@notification-display-color'];
						number += parseInt(notificationMap[NotificationType.Pending].body);
					}

					if (
						notificationMap[NotificationType.Error] &&
						parseInt(notificationMap[NotificationType.Error].body) > 0
					) {
						color = '#fbc02d';
						number += parseInt(notificationMap[NotificationType.Error].body);
					}

					if (notificationMap[NotificationType.LoggedOut]) {
						color = 'red';
					}
					break;
				default:
					return undefined;
			}
			return (
				<Badge
					count={number}
					style={{
						backgroundColor: color,
						color: 'white',
						boxShadow: 'none',
					}}
				/>
			);
		},
		[notification, appSettings?.inboxType]
	);

	const transcribeNotification = (key: string) => {
		//only posting notifications for inbox, outbox and sent
		if (
			notification &&
			(key == MenuKeys.Outbox || key == MenuKeys.Sent || key == MenuKeys.Inbox)
		) {
			const notificationMap: { [x: string]: INotification } = notification.reduce(
				(map: any, obj: INotification) => {
					map[obj.id] = obj;
					return map;
				},
				{}
			);
			if (notificationMap) {
				return getNotificationDisplay(notificationMap, key);
			}
		}
		return '';
	};

	const menuItems = React.useMemo(
		() =>
			props.menuItems.map((m) => {
				const notificationItem = (
					<span style={{ float: 'right' }}>{transcribeNotification(m.key)}</span>
				);
				return 'subMenuItems' in m ? (
					<MenuGroupItem {...m} notification={notificationItem} />
				) : (
					<MenuItem {...m} notification={notificationItem} />
				);
			}),
		[props.menuItems, notification]
	);
	const openKeys = React.useMemo(() => {
		if (props.menuOptions.selectedKeys && props.menuOptions.selectedKeys.length > 0) {
			const key = props.menuOptions.selectedKeys[0];
			const keyArr = key.split('/');
			return keyArr.length > 1 ? [`/${keyArr[1]}`] : [];
		}
		return [];
	}, [props.menuOptions.selectedKeys]);

	const logoElement = React.useMemo(() => {
		if (!logoSrc) return undefined;
		if (activeMember?.isSysAdmin) {
			const version = Config.gitHash ? `ver ${Config.gitHash}` : 'Unknown';
			return (
				<Tooltip title={version} placement="right">
					<LogoImage src={logoSrc} />{' '}
				</Tooltip>
			);
		} else {
			return <LogoImage src={logoSrc} />;
		}
	}, [logoSrc, activeMember]);

	return (
		<>
			<Row style={{ display: 'block' }}>
				<Col>{props.header ?? logoElement}</Col>
			</Row>
			<Menu
				mode="inline"
				{...props.menuOptions}
				defaultOpenKeys={openKeys}
				style={{ textOverflow: 'ellipsis' }}
			>
				{menuItems}
			</Menu>
		</>
	);
};

export default Sidebar;
