import { List, Popconfirm, Tooltip, Button, Typography } from 'antd';
import { ListItemProps } from 'antd/lib/list';
import { Note } from '@copilot/common/store/models/redux';
import styles from './notes.module.less';
import { useState } from 'react';

type NoteItemProps = ListItemProps & {
	note: Note;
	onRemoveNote: (note: any) => Promise<void>;
	isDeletable: boolean;
};

export default function NoteItem({
	note,
	onRemoveNote: handleRemoveNote,
	isDeletable,
}: NoteItemProps) {
	const { message, timestamp } = note;
	const dateString = new Date(timestamp).toLocaleString();
	const [isLoading, setIsLoading] = useState(false);

	async function onRemoveNote() {
		setIsLoading(true);
		await handleRemoveNote(note);
		setIsLoading(false);
	}

	return (
		<List.Item>
			<div className={styles.noteItemHeader}>
				<div>Note:</div>
				<div>{dateString}</div>
			</div>
			<div className={styles.noteItemBody}>
				<Typography.Text>{message}</Typography.Text>

				{isDeletable ? (
					<Popconfirm
						title="Are you sure you want to delete this note?"
						onConfirm={() => {
							void onRemoveNote();
						}}
						okText="Yes"
						cancelText="No"
						placement="rightBottom"
					>
						<Button loading={isLoading}>Delete</Button>
					</Popconfirm>
				) : (
					<Tooltip
						placement="topRight"
						title="This note was not created by you. Please contact admin to delete the note."
						trigger="hover"
						overlayClassName={styles.disabledTooltip}
					>
						<Button disabled style={{ pointerEvents: 'all' }}>
							Delete
						</Button>
					</Tooltip>
				)}
			</div>
		</List.Item>
	);
}
