import { BaseModel } from './base';
import { attr } from 'redux-orm';
import { ColumnDefinition } from '@copilot/common/components/componentModels/columnDefinition';

export interface IColumn {
	id: string;
	columns: ColumnDefinition[];
}

export class Column extends BaseModel<typeof Column, IColumn> {
	static modelName = 'Column';
	static fields = {
		id: attr(),
		columns: attr(),
	};
}
