interface AuthorizationHeader {
	headers: { Authorization: string };
}

/**
 * Authorization manager to get authenticated tokens and authorization headers
 */
export interface AuthorizationManager {
	getAuthenticatedToken: (url: string) => Promise<string>;
	getAuthorizationHeader: (url: string) => Promise<AuthorizationHeader>;
}

/**
 * Legacy Authorization Manager that stores the authenticated token in cookies
 */
export class LegacyAuthorizationManager implements AuthorizationManager {
	constructor() {}

	public async getAuthenticatedToken(): Promise<string> {
		return Promise.resolve(
			document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, '$1')
		);
	}

	/**
	 * Return the headers for authorizing a request
	 * @returns {AuthorizationHeader} Authorization header
	 */
	public async getAuthorizationHeader(): Promise<AuthorizationHeader> {
		return { headers: { Authorization: `Bearer ${await this.getAuthenticatedToken()}` } };
	}

	/**
	 * Store a token into the cookies
	 * @param {string} token Token we want to store
	 */
	public storeToken(token: string): void {
		if (token !== null) document.cookie = `token=${token}`;
		else document.cookie = "token=''";
	}
}
