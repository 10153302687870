import React, { useState, useCallback, useEffect } from 'react';
import { Upload } from 'antd';
import {
	UploadRequestOption,
	UploadProgressEvent,
	UploadRequestError,
} from 'rc-upload/lib/interface';

interface FileUploaderProps extends React.ComponentProps<typeof Upload> {
	onUpload: (options: UploadRequestOption, requestConfig: any) => Promise<any>;
	maxSize?: number;
}

const defaultMaxSize = 2 * 1024 * 1024;

type UploadFunctionProps = Required<React.ComponentProps<typeof Upload>>;

const FileUploader: React.FC<FileUploaderProps> = (props) => {
	const {
		maxSize = defaultMaxSize,
		beforeUpload,
		onUpload,
		customRequest,
		fileList: propsFileList = [],
		...uploadProps
	} = props;
	const [fileList, setFileList] = useState(propsFileList);
	useEffect(() => {
		setFileList(propsFileList);
	}, [propsFileList]);
	const verifyFile = useCallback<
		NonNullable<React.ComponentProps<typeof Upload>['beforeUpload']>
	>(
		(file, list) => {
			const sizePassed = maxSize === undefined ? true : file.size <= maxSize;
			const propPassed = beforeUpload ? beforeUpload(file, list) : true;
			return sizePassed && propPassed;
		},
		[maxSize]
	);
	const uploadFile = useCallback<
		NonNullable<React.ComponentProps<typeof Upload>['customRequest']>
	>(
		(options: UploadRequestOption) => {
			if (!onUpload) return undefined;
			const requestConfig = {
				onUploadProgress: (progressEvent: UploadProgressEvent) =>
					options.onProgress?.({ percent: progressEvent.percent ?? 0 }),
			};
			return onUpload(options, requestConfig)
				.then((result) => options.onSuccess?.(result as object))
				.catch((err: UploadRequestError) => {
					options.onError?.(err);
					throw err;
				});
		},
		[onUpload]
	);
	const handleChange = useCallback<UploadFunctionProps['onChange']>((changes) => {
		const { fileList: list } = changes;
		if (list.length > 1) setFileList(fileList.slice(-1));
		else setFileList(fileList);
	}, []);
	return (
		<Upload
			{...uploadProps}
			beforeUpload={verifyFile}
			customRequest={customRequest ?? uploadFile}
			fileList={fileList}
			onChange={handleChange}
		/>
	);
};

export default FileUploader;
