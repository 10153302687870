import { Col, Row } from 'antd';
import BasicContainer from '../../containers/basic';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';
import styles from './messageCard.module.less';

type MessageCardProps = {
	/** Message Card's left boundary's color */
	leftBordercolor: string;
	/** Unique identifier for the Message Card form */
	formName: string;
	/** Whether or not the card is loading */
	loading: boolean;
	/** (optional) placeholder message override  */
	placeholder?: string;
	/** slot for sender sub element */
	sender: JSX.Element;
	/** slot for recipient sub element */
	recipient: JSX.Element;
	/** slot for content sub element */
	content: JSX.Element;
	/** slot for form sub element */
	form: JSX.Element;
	/** slot for actions sub element */
	actions: JSX.Element;
};

/**
 * [Presentational] Message Card with reply textarea
 * @param props
 */
function MessageCard(props: MessageCardProps) {
	const { loading, sender, content, recipient, form, actions } = props;
	return (
		<LoadingIndicator isLoading={loading} tip="Sending to outbox">
			<BasicContainer className={styles.messageCardContainer}>
				<BasicContainer.Content className={styles.messageCardContainerContent}>
					<Row justify="space-between">
						<Col span={5} className={styles.messageCardSenderContainer}>
							{sender}
						</Col>
						<Col span={19} className={styles.messageCardMainContainer}>
							<>
								<div className={styles.messageCardHeaderContent}>
									{content} {actions}
								</div>
								{recipient}
								{form}
							</>
						</Col>
					</Row>
				</BasicContainer.Content>
			</BasicContainer>
		</LoadingIndicator>
	);
}

export default MessageCard;
