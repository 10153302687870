import { FC } from 'react';
import {
	InboxCategoryTabsBaseProps,
	InboxCategoryTabsAdditionalProps,
	InboxCategoryTabsSubComponentsType,
} from '@copilot/common/pages/inbox/ui/card/types';
import InboxCardViewFilters from '@copilot/common/pages/inbox/ui/card/filters';
import { FiltersForReminderTab } from '@copilot/common/pages/inbox/ui/card/filters/const';
import InboxCardViewByReminderTab from '../tabs/default';
import { ALL, REMINDER, TAB_MESSAGES_FILTER } from '../../../constant';
import { LoadMorePaginationProps } from '@copilot/common/components/tables/tableModule/types';

/**
 * [Smart] HOC for the Inbox Card View reminder tab and corresponding filters
 * @param Component
 */
function withReminderTab<T extends InboxCategoryTabsBaseProps & LoadMorePaginationProps>(
	Component: FC<T & InboxCategoryTabsAdditionalProps> & InboxCategoryTabsSubComponentsType
): FC<T> {
	return function (props: T) {
		const {
			filters,
			filterUpdateCallbacks,
			batchActionCallbacks,
			data,
			counts,
			onLoadMore,
			hasMore = false,
		} = props;
		const { showReminderOnly } = filters;
		const { onShowReminderOnlyUpdate } = filterUpdateCallbacks;
		const { onDismiss } = batchActionCallbacks;
		function handleLoadMore(offset: number) {
			onLoadMore?.(offset);
		}

		const selectedTabName = showReminderOnly ? REMINDER : ALL;

		const filteredMessages = data.filter((message) =>
			TAB_MESSAGES_FILTER[selectedTabName](message)
		);

		return (
			<Component
				{...props}
				data={filteredMessages}
				onDismiss={(thread) => onDismiss([thread])}
				onLoadMore={handleLoadMore}
				isReminderTabSelected={showReminderOnly}
				showLoadMore={hasMore}
			>
				<Component.Tabs>
					<InboxCardViewByReminderTab
						counts={counts}
						reminderTabName={selectedTabName}
						onReminderTabUpdate={(key: string) =>
							onShowReminderOnlyUpdate(key == REMINDER)
						}
					/>
				</Component.Tabs>
				<Component.Filter>
					<InboxCardViewFilters
						filters={filters}
						filterUpdateCallbacks={filterUpdateCallbacks}
						filterVisibility={FiltersForReminderTab}
					/>
				</Component.Filter>
			</Component>
		);
	};
}

export default withReminderTab;
