import { notification, ConfigProvider } from 'antd';
// import { ConfigProps, ArgsProps } from 'antd/lib/notification';
import AntTheme from '../../config/antd.theme';
import { ArgsProps, NotificationConfig } from 'antd/lib/notification/interface';

export interface NotificationManagerOptions extends NotificationConfig {}

export interface INotificationConfig extends ArgsProps {}

const notificationOptions: Partial<ArgsProps> = {
	duration: 1.5,
};

const defaultConfig: NotificationManagerOptions = {
	prefixCls: `${AntTheme['@ant-prefix']}-notification`,
	placement: 'bottomRight',
};

/** Manager for showing notifications in app */
class NotificationManager<P> {
	constructor(options?: P) {
		notification.config(options ?? defaultConfig);
	}

	/**
	 * Show a basic notification
	 * @param {INotificationConfig} config Configuration for Notification
	 */
	showNotification(config: INotificationConfig) {
		notification.open(this.mergeOptions(config));
	}

	/**
	 * Show a success notification
	 * @param {INotificationConfig} config Configuration for Notification
	 */
	showSuccessNotification = (config: INotificationConfig) => {
		notification.success(this.mergeOptions(config));
	};

	/**
	 * Show an error notification
	 * @param {INotificationConfig} config Configuration for Notification
	 */
	showErrorNotification = (config: INotificationConfig) => {
		notification.error(this.mergeOptions(config));
	};

	/**
	 * Show an info notification
	 * @param {INotificationConfig} config Configuration for Notification
	 */
	showInfoNotification(config: INotificationConfig) {
		notification.info(this.mergeOptions(config));
	}

	/**
	 * Show a warning notification
	 * @param {INotificationConfig} config Configuration for Notification
	 */
	showWarningNotification(config: INotificationConfig) {
		notification.warning(this.mergeOptions(config));
	}

	/**
	 * Show a warn notification
	 * @param {INotificationConfig} config Configuration for Notification
	 */
	showWarnNotification(config: INotificationConfig) {
		notification.warning(this.mergeOptions(config));
	}

	private mergeOptions(config: INotificationConfig) {
		return {
			...notificationOptions,
			...config,
			/*
				Wrapped around with Config Provider due to an antd bug where
				the prefix does not auto-applied inside the notification card.
				See: https://github.com/ant-design/ant-design/issues/21119
			*/
			description: config.description ? (
				<ConfigProvider prefixCls="copilot">{config.description}</ConfigProvider>
			) : (
				config.description
			),
		};
	}
}

export default new NotificationManager<NotificationManagerOptions>(defaultConfig);
