import { SUBSCRIPTION } from './constant';

export const UpdateSubscriptionActionType = `UPDATE_${SUBSCRIPTION}` as const;

type CallbacksType<T> = {
	onSuccess?: (result?: T) => void;
	onError?: (exception: any) => void;
};

/**
 * The type of action to update subscription
 */
export type UpdateSubscriptionAction = {
	type: typeof UpdateSubscriptionActionType;
	subscriptionId: string;
	pricingId: string;
	updateNow: boolean;
	couponId?: string;
	callbacks: CallbacksType<void>;
};

/**
 * The action to update subscription
 * @param subscriptionId The subscription id to update
 * @param pricingId the pricing id
 * @param updateNow whether to update now or update next billing date
 * @param [couponId] the coupon id
 * @param [callbacks] the callbacks
 * @returns UpdateSubscriptionAction
 */
export const updateSubscription = (
	subscriptionId: string,
	pricingId: string,
	updateNow: boolean,
	couponId?: string,
	callbacks: CallbacksType<void> = { onSuccess: () => {}, onError: () => {} }
): UpdateSubscriptionAction => ({
	type: UpdateSubscriptionActionType,
	pricingId,
	updateNow,
	couponId,
	subscriptionId,
	callbacks,
});
