import React from 'react';
import { Button, Form, Input } from 'antd';
import { Validators } from '../common/validators';
import styled from 'styled-components';

const StyledFormItem = styled(Form.Item)`
	&&& {
		height: 68px;
		margin-bottom: 0;
		text-align: left;

		& .${(props) => props.theme['@ant-prefix']}-form-item-explain {
			font-size: 14px;
		}
	}
`;

interface LoginFormValues {
	email: string;
	password: string;
}

interface LoginFormProps {
	autoComplete?: string;
	status?: { isSubmitting: boolean };
	onSubmit: (
		username: LoginFormValues['email'],
		password: LoginFormValues['password']
	) => Promise<boolean>;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
	const { autoComplete, status = { isSubmitting: false, errorMessage: '' }, onSubmit } = props;
	const [form] = Form.useForm();

	const handleFinish = React.useCallback(
		(values: LoginFormValues) => {
			onSubmit(values.email, values.password).catch(() => form.resetFields(['password']));
		},
		[onSubmit, form]
	);

	return (
		<Form form={form} autoComplete={autoComplete} onFinish={handleFinish} scrollToFirstError>
			<StyledFormItem
				name="email"
				rules={[
					{ type: 'email', message: 'Please enter a valid email.' },
					{ required: true, message: 'Please enter your email.' },
				]}
			>
				<Input
					type="email"
					placeholder="Email"
					name={autoComplete === 'off' ? 'e-search' : 'email'}
					data-lpignore={autoComplete === 'off' ? 'true' : 'false'}
					autoComplete={autoComplete}
					disabled={status.isSubmitting}
					style={{ padding: '8px 12px' }}
				/>
			</StyledFormItem>
			<StyledFormItem
				name="password"
				rules={[{ required: true, validator: Validators.validatePassword }]}
			>
				<Input.Password
					placeholder="Password"
					name={autoComplete === 'off' ? 'e-search' : 'email'}
					data-lpignore={autoComplete === 'off' ? 'true' : 'false'}
					autoComplete={autoComplete}
					disabled={status.isSubmitting}
					style={{ padding: '8px 12px' }}
				/>
			</StyledFormItem>
			<StyledFormItem>
				<Button
					type="primary"
					htmlType="submit"
					loading={status.isSubmitting}
					style={{ padding: '8px 12px', width: '100%', height: 'auto' }}
				>
					Log In
				</Button>
			</StyledFormItem>
		</Form>
	);
};

export default LoginForm;
