import { useState, useEffect } from 'react';
import { SearchCriteria } from '@copilot/data/responses/interface';
import { CampaignManager } from '@copilot/data';

export const useSearchCriteria = (campaignId: string, campaignMemberId: string) => {
	const [criteria, setCriteria] = useState<SearchCriteria[]>([]);

	useEffect(() => {
		(async () => {
			let results: SearchCriteria[] = [];

			if (campaignMemberId) {
				try {
					results = await CampaignManager.getSearchCriterias(campaignMemberId);
				} catch (err) {}
			}

			if ((!results || results.length == 0) && campaignId) {
				try {
					results = await CampaignManager.getSearchCriterias(campaignId);
				} catch (err) {}
			}

			if (results?.length > 0) setCriteria(results);
		})();
	}, [campaignId, campaignMemberId]);

	return criteria;
};
