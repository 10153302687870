import React, { useMemo, useCallback, useEffect } from 'react';
import CollapsibleContainer, {
	CollapsibleContainerProps,
} from '@copilot/common/components/containers/collapsible';
import CampaignSummary from './campaignSummary';
import { Campaign, CampaignFields } from '@copilot/common/store/models/redux';
import CampaignSummaryHeader from './campaignHeader';
import HeaderContentLayout from '../../layouts/headerContent';
import CampaignDashboardSequence from '../../campaignDashboard/sequence';
import { CampaignType } from '@copilot/data/responses/interface';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import { CampaignManager } from '@copilot/data';
import { useFetch } from '@copilot/common/hooks/common';
import BasicContainer from '@copilot/common/components/containers/basic';
import ScheduleSetting from '../../settings/schedule';
import {
	CampaignDashboardTabKeys,
	CampaignDashboardTabLabel,
} from '@copilot/common/utils/campaign/dashboardTabs';
import ProspectingList from '../../campaignDashboard/searchList/campaignDashboardSearchList/prospectingList';
import NurtureList from '../../campaignDashboard/nurtureList/nurtureList';
import { ScheduleHierarchyType } from '../../settings/schedule/constant';
import { CampaignStatusEnum } from '@copilot/data/requests/models';
import { OrganizationType } from '@copilot/common/store/models/const/enum';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import CampaignMemberTableContainer from '@copilot/common/pages/organizationDashboard/summary/campaignMemberTableContainer';
import CampaignDashboardSettings from '../../campaignDashboard/settings';
import { loadCampaignMembersAction } from '@copilot/common/utils/campaignMember/saga';
import { Ref } from 'redux-orm';
import { withCampaignNodeActions } from '../../campaignDashboard/helpers';
import { ICampaignDashboardSearchListBaseProps } from '@copilot/common/pages/campaignDashboard/searchList/const';
import { useInMailSequence } from '@copilot/common/pages/campaignDashboard/sequence/data/inmail';
import { isEmpty } from 'lodash';

const CampaignDashboardSequenceWithActions = withCampaignNodeActions(CampaignDashboardSequence);

function CampaignSearchListWithOpenInMailSequence({
	campaignId,
	...rest
}: { campaignId: string } & Omit<
	ICampaignDashboardSearchListBaseProps,
	'isMissingInMailSequence'
>) {
	const {
		data: openInMailSequence,
		fetchState: { isFetching: isLoadingOpenInMailSequence },
		fetchSequence: fetchOpenInMailSequence,
	} = useInMailSequence();

	useEffect(() => {
		fetchOpenInMailSequence(campaignId);
	}, [campaignId]);

	const isMissingInMailSequence = !isLoadingOpenInMailSequence && isEmpty(openInMailSequence);

	return (
		<ProspectingList
			{...rest}
			isMissingInMailSequence={isMissingInMailSequence}
			campaignId={campaignId}
		/>
	);
}

type CampaignCollapseProps = CollapsibleContainerProps & {
	campaign: CampaignFields;
	campaignNameUpdateHandler: (id: string, name: string) => Promise<void>;
	isTeamUser: boolean;
	isAdminUser: boolean;
};

const CampaignCollapse: React.FC<CampaignCollapseProps> = (props) => {
	const {
		campaign,
		isAdminUser,
		isTeamUser,
		campaignNameUpdateHandler,
		...collapsibleContainerProps
	} = props;

	return (
		<CollapsibleContainer {...collapsibleContainerProps}>
			<CollapsibleContainer.Header>
				<CampaignSummaryHeader
					campaign={campaign}
					campaignNameUpdateHandler={campaignNameUpdateHandler}
				/>
			</CollapsibleContainer.Header>
			<CollapsibleContainer.Content>
				<HeaderContentLayout.Tabs>
					<HeaderContentLayout.TabPanes
						tab={
							<CampaignDashboardTabLabel tabKey={CampaignDashboardTabKeys.Summary} />
						}
						key={CampaignDashboardTabKeys.Summary}
					>
						<CampaignSummary campaign={campaign} />
					</HeaderContentLayout.TabPanes>
					<HeaderContentLayout.TabPanes
						tab={
							<CampaignDashboardTabLabel
								tabKey={CampaignDashboardTabKeys.TeamMembers}
							/>
						}
						key={CampaignDashboardTabKeys.TeamMembers}
					>
						<CampaignMemberTableContainer campaign={campaign} />
					</HeaderContentLayout.TabPanes>
					{isAdminUser && (
						<>
							{campaign.type === CampaignType.Prospecting && (
								<HeaderContentLayout.TabPanes
									tab={
										<CampaignDashboardTabLabel
											tabKey={CampaignDashboardTabKeys.SearchList}
											usePlural={isTeamUser}
										/>
									}
									key={CampaignDashboardTabKeys.SearchList}
								>
									<CampaignSearchListWithOpenInMailSequence
										campaignId={campaign.id}
										isTeamUser={isTeamUser}
									/>
								</HeaderContentLayout.TabPanes>
							)}
							{campaign.type === CampaignType.Nurture && (
								<HeaderContentLayout.TabPanes
									tab={
										<CampaignDashboardTabLabel
											tabKey={CampaignDashboardTabKeys.NurtureList}
											usePlural={isTeamUser}
										/>
									}
									key={CampaignDashboardTabKeys.NurtureList}
								>
									<NurtureList
										campaignId={campaign.id}
										isTeamUser={isTeamUser}
										isMissingInMailSequence={false}
									/>
								</HeaderContentLayout.TabPanes>
							)}
							<HeaderContentLayout.TabPanes
								tab={
									<CampaignDashboardTabLabel
										tabKey={CampaignDashboardTabKeys.Sequence}
									/>
								}
								key={CampaignDashboardTabKeys.Sequence}
							>
								<CampaignDashboardSequenceWithActions
									campaign={campaign}
									isCampaignEnable={
										campaign.status === CampaignStatusEnum.Enabled
									}
								/>
							</HeaderContentLayout.TabPanes>
							<HeaderContentLayout.TabPanes
								tab={
									<CampaignDashboardTabLabel
										tabKey={CampaignDashboardTabKeys.Schedule}
									/>
								}
								key={CampaignDashboardTabKeys.Schedule}
							>
								<BasicContainer>
									<BasicContainer.Header>
										Timezone and Sending Hours
									</BasicContainer.Header>
									<BasicContainer.Content>
										<ScheduleSetting
											ownerId={campaign.id}
											ownerType={ScheduleHierarchyType.Campaign}
											orgId={campaign.organizationId}
											isEditable={
												campaign.status !== CampaignStatusEnum.Enabled
											}
										/>
									</BasicContainer.Content>
								</BasicContainer>
							</HeaderContentLayout.TabPanes>
							<HeaderContentLayout.TabPanes
								tab={
									<CampaignDashboardTabLabel
										tabKey={CampaignDashboardTabKeys.Settings}
									/>
								}
								key={CampaignDashboardTabKeys.Settings}
							>
								<CampaignDashboardSettings campaign={props.campaign} />
							</HeaderContentLayout.TabPanes>
						</>
					)}
				</HeaderContentLayout.Tabs>
			</CollapsibleContainer.Content>
		</CollapsibleContainer>
	);
};

interface CampaignCollapseListProps {
	campaigns: readonly Ref<Campaign>[];
}

const CampaignCollapseList: React.FC<CampaignCollapseListProps> = (props) => {
	const { campaigns } = props;
	const storeDispatch = useDispatch();
	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	const isTeamUser =
		useSelector(OrganizationSelectors.getActiveOrganization)?.orgType ===
		OrganizationType.Enterprise;
	const [, updateName] = useFetch(
		CampaignManager.renameCampaign,
		CampaignActions.updateCampaign,
		(r) => ({ id: r.id, name: r.name })
	);

	const nameUpdateHandler = useCallback(
		(id: string, name: string): Promise<void> => {
			updateName(id, name);
			return Promise.resolve();
		},
		[updateName]
	);

	const campaignCollapses = useMemo(
		() =>
			campaigns.map((c) => (
				<CampaignCollapse
					key={c.id}
					campaign={c}
					onToggle={() => storeDispatch(loadCampaignMembersAction(c.id))}
					isAdminUser={!!activeAdmin}
					isTeamUser={isTeamUser}
					style={{ margin: '20px 0' }}
					campaignNameUpdateHandler={nameUpdateHandler}
				/>
			)),
		[campaigns, activeAdmin, isTeamUser, nameUpdateHandler, storeDispatch]
	);

	return <>{campaignCollapses}</>;
};

export default CampaignCollapseList;
