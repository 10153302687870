import { createReduxSingleEntity } from '@copilot/common/store/common/singleEntity';
import { NewUserOnboardModel } from '@copilot/data/responses/models/newUserOnboard';

const NEW_USER_ONBOARD = 'NEW_USER_ONBOARD';

export const {
	reducer: newUserOnboardReducer,
	selector: getNewUserOnboard,
	watcher: watchFetchNewUserOnboard,
	action: fetchNewUserOnboard,
} = createReduxSingleEntity<NewUserOnboardModel>(NEW_USER_ONBOARD);
