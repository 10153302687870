import { LinkedInSentMessageError, ThreadStatus } from '@copilot/common/store/models/const/enum';
import {
	Contact,
	ConversationThread,
	IInboxMessage,
	IMessage,
	IOutboxMessage,
	OrganizationMember,
} from '@copilot/common/store/models/redux';
import { InboxManager } from '@copilot/data';
import { LinkedInMessageStatus } from '@copilot/data/responses/interface';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { SessionBoundModel } from 'redux-orm';
import { formatNameFromObject } from '@copilot/common/utils';
import { ProspectDrawerTrackingParams } from '@copilot/common/components/drawer/wrappers/contact/tracking';
import Store from '@copilot/common/store';
import { OutboxActions } from '@copilot/common/store/actions/outbox';
import notificationManager from '@copilot/common/utils/notificationManager';
import { ActivityActions } from '@copilot/common/store/actions/activity';

/**
 * Getter for the submission function for the conversation wrapper
 * @param orgMemberProp
 * @param conversationProp
 * @param campaignId
 * @param contactProp
 * @param tempOutgoing
 * @param onTracking
 * @param setTempOutgoing
 * @param setIsThreadRestricted
 * @param messageItem
 * @param onSuccess
 * @returns
 */
export function getSubmissionFunction(
	orgMemberProp: SessionBoundModel<OrganizationMember>,
	conversationProp: SessionBoundModel<ConversationThread>,
	campaignId: string | undefined,
	contactProp: SessionBoundModel<Contact>,
	tempOutgoing: IMessage[],
	onTracking: ((params: ProspectDrawerTrackingParams) => void) | undefined,
	setTempOutgoing: (tempOutgoing: IMessage[]) => void,
	setIsThreadRestricted: (isThreadRestricted: boolean) => void,
	messageItem: IInboxMessage | undefined,
	onSuccess: () => void
) {
	// TODO: COPILOT-7983 move remove and send reminder logic when sending a message to backend
	return async function onSubmit(
		msg: string,
		manualReplyRemoveReminder: boolean,
		automatedStepId?: string,
		templateId?: string
	) {
		if (orgMemberProp.id && conversationProp.threadId && msg.trim() !== '') {
			try {
				let response;
				if (isEmpty(automatedStepId)) {
					response = await InboxManager.sendManualReply(
						conversationProp.threadId,
						msg,
						orgMemberProp.id,
						manualReplyRemoveReminder,
						templateId,
						campaignId
					);
				} else if (!isNil(messageItem)) {
					// messageItem required to send automated reply
					response = await InboxManager.sendAutomationReply(
						messageItem.campaignMemberId,
						contactProp.id,
						msg,
						String(automatedStepId)
					);
				} else {
					throw new Error('Message item should be not null when resuming automation');
				}
				const tempOutgoingMessage = createTempOutgoingMessage(msg);
				if (response) {
					onTracking?.({ buttonClicked: 'Send to Outbox Button' });
					notificationManager.showSuccessNotification({
						description: 'Sent to Outbox',
						message: 'Message has been sent to Outbox',
					});

					onSuccess();

					const outboxMessage = createOutboxMessage(
						contactProp,
						conversationProp.threadId,
						msg,
						orgMemberProp.id
					);

					Store.Dispatch(OutboxActions.loadOutboxMessages([outboxMessage]));
					setTempOutgoing([...tempOutgoing, tempOutgoingMessage]);

					if (manualReplyRemoveReminder) {
						Store.Dispatch(
							ActivityActions.updateConversation({
								id: conversationProp.id,
								isSnoozed: false,
								snoozedDateTime: undefined,
							})
						);
					}
				} else {
					notificationManager.showWarningNotification({
						description: 'Failed',
						message: 'Failed to send message.',
					});
					setIsThreadRestricted(true);
				}
			} catch {
				notificationManager.showWarningNotification({
					description: 'Failed',
					message: 'Failed to send message.',
				});
			}
		}
	};
}

/**
 * Create temporary outgoing message
 * To ensure message displays in the drawer as pending immediately after the user submits a message
 * @param message message text
 */
function createTempOutgoingMessage(message: string): IMessage {
	return {
		data: message,
		isSelf: true,
		timestamp: new Date(),
		pending: true,
		status: LinkedInMessageStatus.Pending,
	};
}

/**
 * Create outbox message to store in redux store
 * @param contact contact to send a message
 * @param threadId threadId
 * @param message message text
 * @param orgMemberId sender's orgMemberId
 */
function createOutboxMessage(
	contact: SessionBoundModel<Contact>,
	threadId: string,
	message: string,
	orgMemberId: string
): IOutboxMessage {
	return {
		name: formatNameFromObject(contact),
		status: ThreadStatus.Pending,
		threadId,
		message,
		timestamp: `${new Date()}`,
		dateCreated: `${new Date()}`,
		error: LinkedInSentMessageError.NoError,
		contactId: contact.id,
		id: threadId,
		orgMemberId,
		isContactDeleted: false,
	};
}
