import React from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';

export interface BaseModalProps extends ModalProps {}

// TODO Update to use useModal in antv5 instead of BaseModal
export interface BaseModalWithIdProps extends BaseModalProps {
	modalId: string;
}

const BaseModal: React.FC<BaseModalProps> = (props) => {
	const { children, ...rest } = props;
	return (
		<Modal {...rest}>
			<ErrorBoundary>{children}</ErrorBoundary>
		</Modal>
	);
};

export default BaseModal;
