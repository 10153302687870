import { CampaignPerformanceInternal } from './campaignPerformance';
import { useCampaignStatsQuery } from '@copilot/data/graphql/_generated';
import { isUndefined } from 'lodash';
import {
	DateFilters,
	SelectedDateFilter,
} from '@copilot/common/pages/campaignDashboard/summaryV2/dataFilterUtils';
import { isProspectingCampaignStats } from '@copilot/common/pages/campaignDashboard/helpers';

type CampaignPerformanceContainerProps = {
	/**
	 * Campaign member id of whom the performance stats are displayed
	 */
	campaignMemberId: string | undefined;
	/**
	 * The date filter specifying the time range the stats exist within.
	 */
	dateFilter: SelectedDateFilter;
	/**
	 * Function used to update the date filter.
	 */
	setDateFilter: (
		update: SelectedDateFilter | ((update: SelectedDateFilter) => SelectedDateFilter)
	) => void;
};

/**
 * Container for Campaign Performance component
 */
export function CampaignPerformanceContainer({
	campaignMemberId,
	dateFilter,
	setDateFilter,
}: CampaignPerformanceContainerProps) {
	const { data, error, loading, refetch } = useCampaignStatsQuery({
		variables: {
			input: {
				filter: {
					campaignMemberIds: [campaignMemberId ?? ''],
					start: dateFilter.selectedDates?.start?.valueOf(),
					end: dateFilter.selectedDates?.end?.valueOf(),
				},
			},
		},
		notifyOnNetworkStatusChange: true,
		skip: !campaignMemberId,
	});

	if (!isUndefined(error)) throw error;

	const requery = (selectedDates: DateFilters) => {
		if (campaignMemberId) {
			// only refetch if we have a selected member
			refetch({
				input: {
					filter: {
						campaignMemberIds: [campaignMemberId],
						start: selectedDates.startMs,
						end: selectedDates.endMs,
					},
				},
			});
		}
	};

	const isProspectingCampaignPerformance = isProspectingCampaignStats(data?.campaignStats[0]);
	const shouldIncludeInMail = isProspectingCampaignPerformance;
	return (
		<CampaignPerformanceInternal
			shouldIncludeInMail={shouldIncludeInMail}
			isLoading={loading || !data}
			campaignStats={data?.campaignStats[0]}
			dateFilter={dateFilter}
			setDateFilter={setDateFilter}
			onChange={requery}
		/>
	);
}
