import { FC } from 'react';
import { Row, Col } from 'antd';
import BasicContainer from '@copilot/common/components/containers/basic';
import InstructionSteps from '@copilot/common/components/instructionSteps';

const AddConnectionsInstructionSteps = [
	{ title: 'Go to Connections', description: 'Navigate to the Connections page' },
	{
		title: 'Select Connections',
		description: 'Filter then select all of the Connections you want to move',
	},
	{
		title: 'Add to Campaign',
		description: 'Select "Add to Campaign" in the menu and choose this Campaign',
	},
];

interface IEmptyNurtureListPlaceholder {
	sectionTitle: string;
	onGetStartedClick: () => void;
}

/**
 * Placeholder to be used in place of an empty nurture list
 * @param {string} sectionTitle title for the nurture list section
 * @param {function} onGetStartedClick called when Get Started is clicked on the instruction steps
 */
const EmptyNurtureListPlaceholder: FC<IEmptyNurtureListPlaceholder> = (props) => {
	const { sectionTitle, onGetStartedClick } = props;
	return (
		<>
			<Row style={{ display: 'block' }}>
				<Col>
					<BasicContainer>
						<BasicContainer.Header>
							<h2>{sectionTitle} (0)</h2>
						</BasicContainer.Header>
					</BasicContainer>
				</Col>
			</Row>
			<Row justify="center">
				<Col style={{ width: '800px', margin: '56px 0' }}>
					<InstructionSteps
						title="Engage your connections by creating a nurture list"
						steps={AddConnectionsInstructionSteps}
						buttonLabel="Get Started"
						showShadow
						onButtonClick={onGetStartedClick}
					/>
				</Col>
			</Row>
		</>
	);
};

export default EmptyNurtureListPlaceholder;
