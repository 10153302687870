import { VerticalDivider } from '@copilot/common/components/verticalDivider';
import {
	ALERT_CONNECTION_INTERESTED_RATE,
	ALERT_REPLY_RATE,
} from '@copilot/common/constant/campaignConst';
import { Col, Row } from 'antd';
import _ from 'lodash';
import { useTheme } from 'styled-components';
import { MetricConditionalPercentageRingAndDescription } from './metricConditionalRingAndDescription';
import { formatPercentage, getCalculationString } from './helpers';
import drawerManager from '@copilot/common/utils/drawerManager';
import { ProspectingCampaignStats } from './types';
import { CampaignStatsType } from '@copilot/common/utils/campaign';

const connectionTips = [
	"Revise your LinkedIn profile and make sure it's up to date",
	"Check you're targeting the right people within your search lists",
	"Tweak your connection messages under 'Automated Messaging'. Consider if they're personalized and speak to your chosen audience!",
];

const replyTips = [
	'Move a conversation forward by using personalized and engaging follow-up messages',
	'Include a clear ask in your messages. Prompting questions are a good start',
	'Avoid an immediate jump into a sales pitch',
];

interface ProspectingPercentageRingAndDescriptionProps {
	/**
	 * The statistics of the prospecting campaign
	 */
	campaignStats: ProspectingCampaignStats;
	/**
	 * Whether or not calculations should be shown
	 */
	isShowingCalculations: boolean;
}

/**
 * Internal component for prospecting campaigns
 * Displays a row of conditionally coloured percentage rings with descriptions
 */
export function ProspectingPercentageRingAndDescriptionRow({
	campaignStats,
	isShowingCalculations,
}: ProspectingPercentageRingAndDescriptionProps) {
	const {
		numUserReplies,
		numUsersConnected,
		numInvitesSent,
		numInterestedReplies,
		percentReplied,
		percentConnected,
		percentInterestedReplied,
	} = campaignStats;

	const isConnectionRateValid = !_.isNil(percentConnected);
	const connectionRate = isConnectionRateValid ? formatPercentage(percentConnected) : 0;
	const isConnectionLow = connectionRate <= ALERT_CONNECTION_INTERESTED_RATE;

	const isReplyRateValid = !_.isNil(percentReplied);
	const replyRate = isReplyRateValid ? formatPercentage(percentReplied) : 0;
	const isReplyLow = replyRate <= ALERT_REPLY_RATE;

	const isInterestedRateValid = !_.isNil(percentInterestedReplied);
	const interestedRate = isInterestedRateValid ? formatPercentage(percentInterestedReplied) : 0;

	const theme = useTheme();

	const getOpenTipsDrawerCallback = (tips: string[]) => {
		return () => drawerManager.openTipsDrawer({ tips });
	};

	return (
		<Row
			style={{ textAlign: 'center', justifyContent: 'space-evenly' }}
			gutter={[theme['@spacer-num-xs'], 0]}
		>
			<Col span={7}>
				<MetricConditionalPercentageRingAndDescription
					isValid={isConnectionRateValid}
					metricName="Connection rate"
					metricValue={connectionRate}
					metricType={CampaignStatsType.ConnectionRate}
					showCalculations={isShowingCalculations}
					calculationDescription={getCalculationString(
						'connections',
						'invites',
						numUsersConnected,
						numInvitesSent,
						connectionRate
					)}
					onClickTips={
						isConnectionRateValid && isConnectionLow
							? getOpenTipsDrawerCallback(connectionTips)
							: undefined
					}
				/>
			</Col>
			<Col>
				<VerticalDivider />
			</Col>
			<Col span={7}>
				<MetricConditionalPercentageRingAndDescription
					isValid={isReplyRateValid}
					metricName="Reply rate"
					metricValue={replyRate}
					metricType={CampaignStatsType.ReplyRate}
					showCalculations={isShowingCalculations}
					calculationDescription={getCalculationString(
						'replies',
						'connections',
						numUserReplies,
						numUsersConnected,
						replyRate
					)}
					onClickTips={
						isReplyRateValid && isReplyLow
							? getOpenTipsDrawerCallback(replyTips)
							: undefined
					}
				/>
			</Col>
			<Col>
				<VerticalDivider />
			</Col>
			<Col span={7}>
				<MetricConditionalPercentageRingAndDescription
					isValid={isInterestedRateValid}
					metricName="Interested rate"
					metricValue={interestedRate}
					metricType={CampaignStatsType.InterestedRate}
					showCalculations={isShowingCalculations}
					calculationDescription={getCalculationString(
						'interested replies',
						'replies',
						numInterestedReplies,
						numUserReplies,
						interestedRate
					)}
				/>
			</Col>
		</Row>
	);
}
