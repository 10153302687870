import { ReactElement } from 'react';
import styles from './drawerHeader.module.less';
import { Typography } from 'antd';

/**
 * Header for the team user Edit drawer
 */
export default function DrawerHeader({ title }: { title: string }): ReactElement {
	return (
		<header className={styles.header}>
			<Typography.Title level={2}>{title}</Typography.Title>
		</header>
	);
}
