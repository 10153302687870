import { OrmState, ORM, createReducer } from 'redux-orm';
import { Reducer } from 'redux';
import { EntitiesActionTypes, ActionTypes } from '../actions/types';
import { BaseModel } from '../models/redux/base';
// eslint-disable-next-line
import { IndexedModelClasses } from 'redux-orm/ORM';
import { ReducerAction } from './app';

export const createEntitiesReducer = <S extends IndexedModelClasses<any>>(
	orm: ORM<S, keyof S>
): Reducer<OrmState<S>> => {
	const initialState = orm.getEmptyState();
	const baseReducer = createReducer(orm);
	return (state: OrmState<S> = initialState, action: ReducerAction = { type: '' }) => {
		const { itemType, payload } = action;
		const session = orm.session(state);
		const ModelClass: typeof BaseModel = (session as any)[itemType];
		const entities = Array.isArray(payload) ? payload : [payload];
		switch (action.type) {
			case EntitiesActionTypes.Create:
				entities.forEach((entity) => ModelClass.parse(entity));
				break;
			case EntitiesActionTypes.Update:
				entities.forEach((entity) => ModelClass.upsert(entity));
				break;
			case EntitiesActionTypes.Delete:
				entities.forEach((entity) => {
					const instance = ModelClass.withId(entity.id);
					if (instance) instance.delete();
				});
				break;
			case EntitiesActionTypes.Reset:
				ModelClass.all().delete();
				break;
			case ActionTypes.RemoveActiveUser:
				return orm.getEmptyState();
			default:
				//Runs reducers defined on entity models. This allows for handling actions specific to an entity.
				return baseReducer(state, action);
		}
		return (session as any).state;
	};
};
