import BaseDataManager from './base';
import {
	NotificationSubscriptionResponse,
	PushMethods,
	EmptyResponse,
	NotificationResponse,
} from '../responses/interface';

class NotificationManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get notification subscriptions
	 */
	public getNotificationOptions = (): Promise<NotificationSubscriptionResponse> => {
		const url = this.BACKEND_ROUTES.NOTIFICATIONS.Settings;
		return this.RequestManager.get<NotificationSubscriptionResponse>(url).then(
			(response) => response.data
		);
	};

	/**
	 * Subscribe / Unsubscribe a single user to a notification with the code
	 * @param code Code to subscribe notification for
	 * @param subscription Subscription option with ownerId and push methods
	 */
	public updateNotificationSubscriptions = (
		code: string,
		subscription: { ownerIds: string[]; pushMethods: PushMethods[] }
	) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.NOTIFICATIONS.Settings,
			code,
			'subscribe'
		);
		return this.RequestManager.post<EmptyResponse>(url, subscription).then(
			(response) => response.data
		);
	};

	/**
	 * Get notification for org member
	 * @param orgMemberId org member id
	 */
	public getNotifications = (orgMemberId: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.OUTBOX.Default, 'alerts');

		return this.RequestManager.get<NotificationResponse>(url, {
			params: { o: orgMemberId },
		}).then((response) => response.data);
	};
}

const instance = new NotificationManager();
export default instance;
