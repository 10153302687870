import React, { useCallback } from 'react';
import { Spin, Row, Col, Button } from 'antd';
import TimezoneSelector from '@copilot/common/components/selector/timezone';
import WeeklyScheduleSelector, {
	Weekday,
} from '@copilot/common/components/selector/weeklyScheduleSelector';
import { DaySchedule } from '@copilot/data/responses/interface';

import { timeFormatter } from '@copilot/common/components/selector/timeRangeSelector';
import { useSchedule } from '@copilot/common/hooks/serviceSchedule';
import { ScheduleHierarchyType } from './constant';

interface ScheduleSettingProps {
	ownerId: string;
	ownerType: ScheduleHierarchyType;
	orgId?: string;
	isEditable: boolean;
}

const ScheduleSetting: React.FC<ScheduleSettingProps> = (props) => {
	const { ownerId, ownerType, orgId, isEditable } = props;
	const [editView, setEditView] = React.useState<boolean>(false);

	const header = React.useMemo(
		() => (
			<>
				<Col span={13}>
					<h2>Set your Timezone and Sending Hours</h2>
					<p>
						You can set the ideal timezone and sending hours for your campaign using the
						scales below. Your invites, follow-ups and automated messages will all be
						sent during the selected times.
					</p>
				</Col>
			</>
		),
		[]
	);
	const [{ schedule, isFetching }, setSchedule] = useSchedule(orgId ?? '', ownerId, ownerType);

	const handleTimezoneUpdate = useCallback(
		(timezoneCode: string) => {
			if (!schedule) return;
			setSchedule({ ...schedule, timezoneCode });
		},
		[setSchedule, schedule]
	);

	const timezoneSelector = useCallback(
		() =>
			schedule && (
				<>
					<TimezoneSelector
						timezoneId={schedule.timezoneCode}
						editable={isEditable}
						onTimezoneChange={handleTimezoneUpdate}
					/>
				</>
			),
		[schedule, isEditable, handleTimezoneUpdate]
	);

	const handleWeeklyScheduleUpdate = (weeklySchedule: DaySchedule[], isSync: boolean) => {
		setSchedule({
			...schedule,
			weeklySchedule,
			synchronization: isSync,
		});
		setEditView(false);
	};

	const weeklyScheduleSelector = React.useCallback(
		() =>
			schedule && (
				<>
					<Row>
						<Col>
							<h3>Sending Hours</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<WeeklyScheduleSelector
								schedule={schedule.weeklySchedule}
								isSync={schedule.synchronization}
								onSubmit={handleWeeklyScheduleUpdate}
								onDiscard={() => setEditView(false)}
							/>
						</Col>
					</Row>
				</>
			),
		[schedule, handleWeeklyScheduleUpdate]
	);

	const weeklySchedule = React.useMemo(
		() =>
			schedule && (
				<>
					<Row align="bottom">
						<Col span={6} style={{ minWidth: '20em' }}>
							<h3>Sending Hours</h3>
						</Col>
						{isEditable && (
							<Col>
								<Button onClick={() => setEditView(true)}>Edit</Button>
							</Col>
						)}
					</Row>
					{schedule.weeklySchedule?.map((daySchedule: DaySchedule, index: number) => (
						<Row style={{ marginTop: '2em' }} key={Weekday[index]}>
							<Col span={1} style={{ minWidth: '5em' }}>
								<h3>
									<strong>{Weekday[index]}</strong>
								</h3>
							</Col>
							<Col offset={1}>
								<h3>
									{daySchedule.enable
										? `${timeFormatter(daySchedule.start)} - ${timeFormatter(
												daySchedule.end
										  )}`
										: 'Disabled'}
								</h3>
							</Col>
						</Row>
					))}
				</>
			),
		[schedule?.weeklySchedule, isEditable]
	);

	return (
		<>
			<Row>{header}</Row>
			<Spin spinning={isFetching}>
				{timezoneSelector()}
				<br />
				<br />
				{editView ? weeklyScheduleSelector() : weeklySchedule}
			</Spin>
		</>
	);
};
export default ScheduleSetting;
