import React from 'react';
import CampaignMemberTable from '@copilot/common/components/tables/tableTypes/campaignMember';
import { ModelState } from '@copilot/common/store/models/fetch';
import { CampaignMemberModel } from '@copilot/common/utils/campaignMember/models';
import { useSelector } from 'react-redux';
import { selectCampaignMembers } from '@copilot/common/utils/campaignMember/selector';
import { CampaignFields } from '@copilot/common/store/models/redux';

interface CampaignMemberTableContainerProps {
	campaign: CampaignFields;
}

const CampaignMemberTableContainer: React.FC<CampaignMemberTableContainerProps> = (props) => {
	const { campaign } = props;
	const campaignMembers: ModelState<CampaignMemberModel> = useSelector(
		selectCampaignMembers(campaign.id)
	);

	return (
		<CampaignMemberTable
			allowRowClick
			data={campaignMembers?.data}
			campaign={campaign}
			campaignType={campaign.type}
		/>
	);
};

export default CampaignMemberTableContainer;
