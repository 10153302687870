import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import useTracking from '@copilot/common/hooks/tracking';
import { useMemo } from 'react';

type TrackingUserType = 'Admin' | 'Base' | 'Advanced';

export enum AttentionItemTrackingErrorType {
	BlockerLoggedOut = 'Blocker: User logged out of LinkedIn',
	BlockerEmptySearchList = 'Blocker: Empty search list',
	WarningLowSearchList = 'Warning: Low search list',
	WarningNoInvitesMessagesSet = 'Warning: No invites/messages set',
	HelpArticleButton = 'Help Article Button',
}

/**
 * Type of button clicked
 */
type AttentionItemClickTrackingType = `[${TrackingUserType}] ${AttentionItemTrackingErrorType}`;

interface AttentionItemClickTrackingParams {
	buttonClicked: AttentionItemClickTrackingType;
}

const useAttentionItemClickTracking = (
	componentName: string,
	params: AttentionItemClickTrackingParams | null
): ((newParams: AttentionItemClickTrackingParams) => void) =>
	useTracking(componentName, 'attention_item_click', params);

/**
 * Tracking for Attention Item clicks
 * @param {string} componentName name of the component the hook is called in
 * @param {string} orgRoleTitle org role of the user, used for button click prefix
 */
export const useAttentionItemButtonClickTracking = (
	componentName: string,
	orgRoleTitle?: string
) => {
	const updateCampaignSearchListTracking = useAttentionItemClickTracking(componentName, null);

	const userType: TrackingUserType = useMemo(() => {
		switch (orgRoleTitle) {
			case OrgRoleTitles.Advanced:
				return 'Advanced';
			case OrgRoleTitles.User:
				return 'Base';
			default:
				return 'Admin'; // default to admin for tracking
		}
	}, [orgRoleTitle]);

	return (errorType: AttentionItemTrackingErrorType) => {
		const trackingType: AttentionItemClickTrackingType = `[${userType}] ${errorType}`;
		updateCampaignSearchListTracking({ buttonClicked: trackingType });
	};
};
