import { SequenceNode } from '../types';
import { AutomatedStep } from './card/messageCard/types';

/**
 * Function to calculate automated steps from next nodes, should be removed and handled on BE
 * TODO: https://cassia.atlassian.net/browse/COPILOT-7618
 * @param nextNodes
 * @returns
 */
export function calcAutomatedSteps(nextNodes: SequenceNode[] | undefined | null): AutomatedStep[] {
	return (
		nextNodes?.map((node) => ({
			...node,
			message:
				node.actions.find((action) => action.actionType == 'SendMessage')?.parameters[0] ??
				'',
		})) ?? []
	);
}
