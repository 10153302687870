import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthorizationManager } from './authorization';
import { isString } from 'lodash';

interface RequestOptions extends AxiosRequestConfig {}

const defaultOptions: RequestOptions = {};

interface AjaxManagerOptions {
	authorizationManager?: AuthorizationManager;
}
class AjaxManager {
	private authorizationManager: AjaxManagerOptions['authorizationManager'];
	constructor(options: AjaxManagerOptions = {}) {
		this.authorizationManager = options.authorizationManager;

		this.get = this.get.bind(this);
		this.post = this.post.bind(this);
		this.put = this.put.bind(this);
		this.delete = this.delete.bind(this);
		this.patch = this.patch.bind(this);
	}

	/**
	 * Merge request options with the authorization header
	 * @param {RequestOptions} options Options for the request we want to make
	 */
	private async getRequestOptions(
		url: string,
		options: RequestOptions = {}
	): Promise<RequestOptions> {
		if (!this.authorizationManager) return options;
		const { headers, ...rest } = options;
		const urlOrigin = this.getOrigin(url);
		const authorizationHeader = !isString(options.headers?.Authorization)
			? (await this.authorizationManager.getAuthorizationHeader(urlOrigin)).headers
			: { Authorization: options.headers?.Authorization ?? '' };
		const newHeaders = { headers: { ...authorizationHeader, ...headers } };
		return { ...defaultOptions, ...newHeaders, ...rest };
	}

	/**
	 * Get the origin of a url
	 * @param url The url we want to get the origin for
	 * @returns
	 */
	private getOrigin = (url: string) => {
		const { origin } = new URL(url);
		return origin;
	};

	/**
	 * Middleware for all responses
	 * @param handler Function we want to call for every response
	 */
	public applyResponseMiddleware(
		responseHandler: (
			value: AxiosResponse<any>
		) => AxiosResponse<any> | Promise<AxiosResponse<any>>,
		errorHandler: (error: any) => any
	): void {
		axios.interceptors.response.use(responseHandler, errorHandler);
	}

	/**
	 * Make a get request
	 * @param {string} url Url for the request
	 * @param {RequestOptions} options Options for the request
	 */
	public async get<T = any>(
		url: string,
		options: RequestOptions = {}
	): Promise<AxiosResponse<T>> {
		const reqOptions = await this.getRequestOptions(url, options);
		return axios.get<T>(url, reqOptions);
	}

	/**
	 * Make a post request
	 * @param {string} url Url for the request
	 * @param {any} data Post data
	 * @param {RequestOptions} options Options for the request
	 */
	public async post<T = any>(
		url: string,
		data: any = {},
		options: RequestOptions = {}
	): Promise<AxiosResponse<T>> {
		const reqOptions = await this.getRequestOptions(url, options);
		return axios.post<T>(url, data, reqOptions);
	}

	/**
	 * Make a put request
	 * @param {string} url Url for the request
	 * @param {any} data Put data
	 * @param {RequestOptions} options Options for the request
	 */
	public async put<T = any>(
		url: string,
		data: any = {},
		options: RequestOptions = {}
	): Promise<AxiosResponse<T>> {
		const reqOptions = await this.getRequestOptions(url, options);
		return axios.put<T>(url, data, reqOptions);
	}

	/**
	 * Make a delete request
	 * @param {string} url Url for the request
	 * @param {RequestOptions} options Options for the request
	 */
	public async delete<T = any>(
		url: string,
		options: RequestOptions = {}
	): Promise<AxiosResponse<T>> {
		const reqOptions = await this.getRequestOptions(url, options);
		return axios.delete<T>(url, reqOptions);
	}

	/**
	 * Make a patch request
	 * @param {string} url Url for the request
	 * @param {any} data Patch data
	 * @param {RequestOptions} options Options for the request
	 */
	public async patch<T = any>(
		url: string,
		data: any = {},
		options: RequestOptions = {}
	): Promise<AxiosResponse<T>> {
		const reqOptions = await this.getRequestOptions(url, options);
		return axios.patch<T>(url, data, reqOptions);
	}
}

export default AjaxManager;
