import React, { useCallback } from 'react';
import { Drawer, Col, Row, Spin } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
	getOneOrganizationTeamMember,
	getOneOrganizationTeamMemberState,
} from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/selectors';
import BasicContainer from '@copilot/common/components/containers/basic';
import { OrgRoleTitles, AdminMemberSelectionTypes } from '@copilot/common/store/models/const/enum';
import { OrganizationMemberManager } from '@copilot/data';
import DrawerManager from '@copilot/common/utils/drawerManager';
import modalManager from '@copilot/common/utils/modalManager';
import { withNotification } from '@copilot/common/hoc/utils';
import { OrganizationMemberRequestModel } from '@copilot/data/requests/models';
import {
	LoadOneTeamMemberAction,
	updateMemberAndGetLinkedIn,
	UpdateActiveStatusAction,
} from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/saga';
import moment from 'moment';
import { LONG_DATE_FORMAT } from '@copilot/common/utils/dateFormat';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';
import { useFetch } from '@copilot/common/hooks/common';
import { useOrganizationTeamMemberTracking } from '@copilot/common/pages/organizationDashboard/orgTeamMembers/tracking';
import OrgMemberBasicInfoEditor from '@copilot/common/components/editors/orgMemberInfo/basicInfo';
import Title from 'antd/lib/typography/Title';
import OrgMemberEntitlement from '@copilot/common/components/editors/orgMemberInfo/entitlement';
import { BasicOrgMemberInfo } from './types';
import { getMemberRoleType } from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/helper';
import { IOrganizationTeamMember } from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/models';
import { ModelSingleState } from '@copilot/common/store/models/fetch';
import { useTeamUserRoleChangeTracking } from '@copilot/common/components/editors/orgMemberInfo/tracking';
import { IProxy } from '@copilot/common/store/models/redux';
import partialRight from 'lodash/partialRight';

interface AdminMemberDrawerProps extends DrawerProps {
	id: string;
}

const MEMBER_TYPES = [
	{ name: AdminMemberSelectionTypes.AdminUser, id: OrgRoleTitles.Admin },
	{ name: AdminMemberSelectionTypes.AdvancedUser, id: OrgRoleTitles.Advanced },
	{ name: AdminMemberSelectionTypes.BaseUser, id: OrgRoleTitles.User },
];

const StyledDrawer = styled(Drawer)`
	flex-direction: column;
	height: 100%;
	.${(props) => props.theme['@ant-prefix']}-drawer-close {
		position: absolute;
		top: 20px;
		right: 0;
		z-index: 1;
	}
`;

const resentLoginEmailWithNotification = withNotification(OrganizationMemberManager.resetPassword);

const AdminMemberPanel: React.FC<{ member: IOrganizationTeamMember }> = (props) => {
	const { member } = props;
	const memberState: ModelSingleState<IOrganizationTeamMember> = useSelector(
		getOneOrganizationTeamMemberState(member.id)
	);
	const dispatch = useDispatch();
	const updateMember = useCallback(
		(memberToUpdate: Partial<OrganizationMemberRequestModel>, closeDrawer = false) =>
			dispatch(
				LoadOneTeamMemberAction(
					updateMemberAndGetLinkedIn,
					memberToUpdate,
					closeDrawer ? DrawerManager.closeDrawer : undefined
				)
			),
		[]
	);
	const trackButtonClicked = useOrganizationTeamMemberTracking('Admin Member Drawer');
	const updateTeamUserRoleTracking = useTeamUserRoleChangeTracking('Admin Member Drawer');

	const [resentLoginEmailFetch, resentLoginEmail] = useFetch(resentLoginEmailWithNotification);

	const handleSaveBasicInfo = useCallback(
		(values: BasicOrgMemberInfo) => {
			updateMember({ id: member.id, ...values });
		},
		[updateMember, member.id]
	);

	const handleSaveEntitlement = useCallback(
		(entitlement: OrgRoleTitles) => {
			updateMember({ id: member.id, orgRoles: [entitlement] });
			updateTeamUserRoleTracking(getMemberRoleType(member), entitlement);
		},
		[updateMember, updateTeamUserRoleTracking, member]
	);

	const sendResetEmail = useCallback(() => {
		resentLoginEmail(member.email, false);
	}, [member.email]);

	const onMemberActiveStatusChange = useCallback(
		(updateIsActive: boolean) => {
			if (updateIsActive) {
				// on activation
				trackButtonClicked('Organization Member Activation Confirm');
			} else {
				// on deactivation
				trackButtonClicked('Organization Member Deactivation Confirm');
			}
			dispatch(UpdateActiveStatusAction(member.id, updateIsActive));
		},
		[trackButtonClicked]
	);

	const openActivationModal = useCallback(
		(updateIsActive: boolean) => {
			if (!updateIsActive) {
				const nextDeactivationTime = moment.utc(member.nextDeactivationDate);
				// check if the member's next deactivation time after the current time
				if (nextDeactivationTime > moment.utc()) {
					trackButtonClicked('Organization Member Restriction Modal');
					modalManager.openRestrictMemberDeactivationModal({
						memberName: member.firstName,
						nextDeactivationDate: nextDeactivationTime.format(LONG_DATE_FORMAT),
					});
					return;
				}
			}

			if (updateIsActive) {
				// on activation
				trackButtonClicked('Organization Member Activation Modal');
			} else {
				// on deactivation
				trackButtonClicked('Organization Member Deactivation Modal');
			}
			modalManager.openOrganizationMemberActivationModal({
				member,
				onConfirm: () => onMemberActiveStatusChange(updateIsActive),
			});
		},
		[member, onMemberActiveStatusChange]
	);

	async function onSaveProxy(proxy: IProxy) {
		return new Promise<void>((resolve) => {
			dispatch(
				LoadOneTeamMemberAction(
					partialRight(updateMemberAndGetLinkedIn, () => {
						resolve();
					}),
					{
						vpnPort: proxy.value,
						id: member.id,
					}
				)
			);
		});
	}

	return (
		<LoadingIndicator isLoading={resentLoginEmailFetch.isFetching || memberState.loading}>
			<BasicContainer bordered={false}>
				<BasicContainer.Header>
					<Row>
						<Col>
							<Title level={3}>{member.name} Details and Permissions</Title>
						</Col>
					</Row>
				</BasicContainer.Header>
				<BasicContainer.Content>
					<OrgMemberBasicInfoEditor
						orgMember={member}
						isEditable={member.isActive}
						onSave={handleSaveBasicInfo}
						onResendEmail={sendResetEmail}
						onActivate={() => openActivationModal(true)}
						onDeactivate={() => openActivationModal(false)}
						onSaveProxy={onSaveProxy}
					/>
					<OrgMemberEntitlement
						entitlement={getMemberRoleType(member)}
						entitlementTypes={MEMBER_TYPES}
						isEditable={member.isActive}
						onSave={handleSaveEntitlement}
					/>
				</BasicContainer.Content>
			</BasicContainer>
		</LoadingIndicator>
	);
};

const AdminMemberDrawer: React.FC<AdminMemberDrawerProps> = (props) => {
	const { id, width = '720px', ...drawerProps } = props;
	const member = useSelector(getOneOrganizationTeamMember(id));

	return (
		<StyledDrawer {...drawerProps} width={width} visible>
			{member ? <AdminMemberPanel member={member} /> : <Spin size="large" />}
		</StyledDrawer>
	);
};

export default AdminMemberDrawer;
