import React, { useCallback } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import BasicContainer from '@copilot/common/components/containers/basic';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import LogoUploader from '@copilot/common/components/forms/common/inputs/fileUploader/logo';
import { OrganizationManager } from '@copilot/data';
import { useFetch } from '@copilot/common/hooks/common';
import { OrganizationActions } from '@copilot/common/store/actions/organization';
import EditableTextDisplay from '@copilot/common/components/forms/common/inputs/editableTextDisplay';
import { AxiosRequestConfig } from 'axios';
import { UploadRequestOption } from 'rc-upload/lib/interface';

interface OrganizationDashboardSettingsProps {
	organizationId: string;
}

const OrganizationDashboardSettings: React.FC<OrganizationDashboardSettingsProps> = (props) => {
	const { organizationId } = props;
	const organization = useSelector(OrganizationSelectors.getOrganization(organizationId));
	const [, uploadLogo] = useFetch(
		OrganizationManager.uploadLogo,
		OrganizationActions.updateOrganization,
		(r) => ({ id: organizationId, logoUrl: r })
	);
	const [, removeLogo] = useFetch(
		OrganizationManager.removeLogo,
		OrganizationActions.updateOrganization,
		() => ({ id: organizationId, logoUrl: '' })
	);
	const [, renameOrganization] = useFetch(
		OrganizationManager.renameOrganization,
		OrganizationActions.updateOrganization
	);
	if (!organization) return null;
	const handleLogoUpload = useCallback(
		(options: UploadRequestOption, requestConfig: AxiosRequestConfig<any>) =>
			organization?.id
				? uploadLogo(organization.id, options.file, requestConfig)
				: Promise.reject(),
		[organization?.id]
	);
	const handleLogoRemove = useCallback(() => {
		organization?.id && removeLogo(organization.id);
	}, [organization?.id]);
	const handleOrganizationRename = useCallback(
		(name: string) => {
			if (organization?.id) renameOrganization(organization.id, name);
		},
		[organization?.id]
	);
	return (
		<BasicContainer>
			<BasicContainer.Header>Organization Settings</BasicContainer.Header>
			<BasicContainer.Content>
				<Row justify="start">
					<Col span={6} style={{ margin: '0px 20px' }}>
						<label>Organization Name:</label>
					</Col>
					<Col span={8} style={{ marginLeft: '10px' }}>
						<Row justify="start">
							<EditableTextDisplay
								value={organization.name || ''}
								confirmHandler={handleOrganizationRename}
								confirmOnBlur
							>
								{organization.name}
							</EditableTextDisplay>
						</Row>
					</Col>
				</Row>
				<br />
				<Row align="top" justify="start">
					<Col span={6} style={{ margin: '0px 20px' }}>
						<label>Upload Organization Logo:</label>
					</Col>
					<Col>
						<LogoUploader
							logo={organization.logoUrl}
							onRemove={handleLogoRemove}
							onUpload={handleLogoUpload}
						/>
					</Col>
				</Row>
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default OrganizationDashboardSettings;
