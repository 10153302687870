import Store from '..';
import { createSelector, OrmState } from 'redux-orm';
import { OrgRoleTitles } from '../models/const/enum';

export namespace OrganizationMemberSelectors {
	//TODO Tried implementing a separate orgmember selector that can be used across the
	//     other selectors
	// export const getMemberSelector = createSelector(
	// 	Store.ORM,
	// 	(state, id) => id,
	// 	(state: typeof Store.State) => state.entities.LinkedInMeta,
	// 	(session, id, meta) => session.OrganizationMember.withId(id)
	// );

	export const getActiveMember = createSelector(
		Store.ORM,
		(state: typeof Store.State) => state.app.user,
		(state: typeof Store.State) => state.entities.LinkedInMeta,
		(session, userId: string) => session.OrganizationMember.withId(userId)
	);

	export const getAdminMember = createSelector(
		Store.ORM,
		(state: typeof Store.State) => state.app.adminUser,
		(state: typeof Store.State) => state.entities.LinkedInMeta,
		(session, userId: string) => session.OrganizationMember.withId(userId)
	);

	export const getOrgMember = createSelector(
		Store.ORM,
		(state, id) => id,
		(state: typeof Store.State) => state.entities.LinkedInMeta,
		(session, id: string) => session.OrganizationMember.withId(id)
	);

	export const getOrgMembers = (ids: string[]) => (state: { entities: OrmState<any> }) => {
		const idMap = ids.reduce((acc, i) => {
			acc[i] = true;
			return acc;
		}, {} as { [k: string]: boolean });
		const evaluator = (record: any) => idMap[record.id];
		return Store.ORM.session(state.entities)
			.OrganizationMember.filter(evaluator)
			.toModelArray();
	};

	export const getAllOrgMembers = (state: { entities: OrmState<any> }) =>
		Store.ORM.session(state.entities).OrganizationMember.all().toRefArray();

	export const getOrgMembersByOrgId = createSelector(
		Store.ORM,
		(_, organizationId) => organizationId,
		(session, organizationId) =>
			session.OrganizationMember.filter({ organizationId }).all().toRefArray()
	);

	export const getOwnerAndAdminsByOrgId = createSelector(
		Store.ORM,
		(_, organizationId) => organizationId,
		(session, organizationId) =>
			session.OrganizationMember.filter({ organizationId })
				.filter(
					(item) =>
						item.orgRoles.includes(OrgRoleTitles.Owner) ||
						item.orgRoles.includes(OrgRoleTitles.Admin)
				)
				.all()
				.toRefArray()
	);
}
