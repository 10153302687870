import React, { FC, ReactNodeArray } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import DrawerContainerManager from '@copilot/common/components/drawer';
import ModalManager from '@copilot/common/components/modals/manager';
import { Config } from '@copilot/common/config';
import { getChildByType } from '@copilot/common/utils/common';

export const SIDEBAR_WIDTH = '200px';

const LayoutSider = styled(Layout.Sider)`
	min-height: 100vh;
	left: 0;
	overflow: auto;
	box-shadow: 10px 10px 26px -18px rgba(0, 0, 0, 0.75);
	z-index: 5;
	::-webkit-scrollbar {
		width: 10px;
		background-color: transparent;
	}
	::-webkit-scrollbar-track {
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(255, 255, 255, 0.3);
	}
`;

const InnerLayout = styled(Layout)`
	min-height: 100vh;
	min-width: 800px;
	::-webkit-scrollbar {
		width: 10px;
		background-color: transparent;
	}
	::-webkit-scrollbar-track {
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.3);
	}
`;

const LayoutHeader = styled(Layout.Header)`
	&& {
		background-color: ${(props) =>
			props.theme['@layout-header-base-background-v1'] ??
			props.theme['@layout-header-base-background']};
		line-height: ${(props) => props.theme['@layout-header-height']};
		height: ${(props) => props.theme['@layout-header-height']};
	}
`;

const Header: FC<{}> = (props) => <>{props.children}</>;
const Sidebar: FC<{}> = (props) => <>{props.children}</>;
const Content: FC<{}> = (props) => <>{props.children}</>;
const Notification: FC<{}> = (props) => <>{props.children}</>;

type BaseLayoutChildComponentsType = {
	Header: typeof Header;
	Sidebar: typeof Sidebar;
	Content: typeof Content;
	Notification: typeof Notification;
};

/**
 * [Presentational] Two-columns layout with Sidebar in the left column, Header and Content in the right column
 */
const BaseLayout: React.FC<{ children: ReactNodeArray }> & BaseLayoutChildComponentsType = (
	props
) => {
	const HeaderComponent = getChildByType(props.children, Header);
	const SidebarComponent = getChildByType(props.children, Sidebar);
	const ContentComponent = getChildByType(props.children, Content);
	const NotificationComponent = getChildByType(props.children, Notification);
	return (
		<>
			<Layout style={{ minHeight: '100vh' }}>
				{!!SidebarComponent && (
					<LayoutSider
						style={{
							position: 'fixed',
						}}
						/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
						// @ts-ignore
						theme={Config.isAgency ? 'light' : 'dark'}
					>
						{SidebarComponent}
					</LayoutSider>
				)}
				<InnerLayout style={{ paddingLeft: SidebarComponent ? SIDEBAR_WIDTH : undefined }}>
					{NotificationComponent}
					<LayoutHeader>{HeaderComponent}</LayoutHeader>
					<Layout.Content>
						{ContentComponent}
						<DrawerContainerManager />
						<ModalManager />
					</Layout.Content>
				</InnerLayout>
			</Layout>
		</>
	);
};

BaseLayout.Header = Header;
BaseLayout.Sidebar = Sidebar;
BaseLayout.Content = Content;
BaseLayout.Notification = Notification;

export default BaseLayout;
