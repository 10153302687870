import { ReactElement, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import InboxPageBatchAction from './batchActions';
import { InboxMessagesFiltersProps, InboxMessagesPageProps } from '../types';
import { InboxDisplayType } from '@copilot/data/requests/models';
import InboxPageLayout from '@copilot/common/pages/inbox/ui/inboxPageLayout';
import BasicContainer from '@copilot/common/components/containers/basic';
import InboxMessageTable from '@copilot/common/pages/inbox/ui/component/table';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import { useInboxPageListViewTracking } from '../../tracking/emailView';
import { TableProps } from 'antd/lib/table';
import { InboxTableMessage } from '../component/table/types';

const filterStatus = (tags: FilterDefinition[]) =>
	tags.reduce<string[]>(
		(arr, tag) => (tag.isVisible && !tag.isExclude ? [...arr, tag.label] : arr),
		[]
	);

export type InboxEmailViewProps = {
	children: ReactElement<InboxMessagesFiltersProps>;
} & InboxMessagesPageProps;

/**
 * Presentation Component for Inbox Email page
 * @param props
 */
function InboxEmailView({
	data,
	onSearch,
	batchActionCallbacks,
	onSnooze,
	onUnsnooze,
	onViewUpdate,
	onOpenConversationDetail,
	filters,
	children,
	onLoadMore,
	hasMore,
	...rest
}: InboxEmailViewProps) {
	const { onDismiss } = batchActionCallbacks;
	const onTracking = useInboxPageListViewTracking('Inbox List View');
	const themeContext = useContext(ThemeContext);
	const [selectedRows, setSelectedRows] = useState<
		{
			threadId: string;
			connectionId: string;
			campaignId: string;
			contactName: string;
			orgMemberId: string;
		}[]
	>([]);

	const handleChange: TableProps<InboxTableMessage>['onChange'] = (
		pagination,
		allFilters,
		_sorter,
		extra
	) => {
		if (extra.action === 'filter') {
			const searchTerm = allFilters['.$contactName']?.toString() ?? '';
			onSearch(searchTerm);
		}
	};

	return (
		<InboxPageLayout viewType={InboxDisplayType.Email} onViewUpdate={onViewUpdate}>
			<InboxPageLayout.Header>
				<h2>Inbox</h2>
			</InboxPageLayout.Header>
			<InboxPageLayout.Filter>{children}</InboxPageLayout.Filter>
			<InboxPageLayout.Content>
				<BasicContainer.Content
					style={{
						padding: themeContext['@card-padding-base'],
						background: themeContext['@layout-body-background'],
					}}
				>
					<InboxMessageTable
						{...rest}
						dataSource={data}
						onLoadMore={() => onLoadMore(data.length)}
						hasMore={hasMore}
						onChange={handleChange}
						rowSelection={{ onChange: (_, rows) => setSelectedRows(rows) }}
						onSnooze={onSnooze}
						onUnsnooze={onUnsnooze}
						onOpenConversationDetail={onOpenConversationDetail}
						onDismiss={onDismiss}
						onTracking={onTracking}
						tags={filterStatus(filters.tags)}
					/>
					<InboxPageBatchAction
						messages={selectedRows}
						callbacks={batchActionCallbacks}
					/>
				</BasicContainer.Content>
			</InboxPageLayout.Content>
		</InboxPageLayout>
	);
}
export default InboxEmailView;
