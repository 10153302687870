import { attr } from 'redux-orm';
import { BaseModel } from './base';

export interface IMemberLinkedInProfile {
	id: string;
	urn: string;
	name: string;
	title: string;
	company: string;
	alias: string;
}

export class MemberLinkedInProfile extends BaseModel<
	typeof MemberLinkedInProfile,
	IMemberLinkedInProfile
> {
	static modelName = 'MemberLinkedInProfile';
	static fields = {
		id: attr(),
		urn: attr(),
		name: attr(),
		title: attr(),
		company: attr(),
		alias: attr(),
	};
}

export interface ILinkedinOrganizationMember {
	orgMemberId: string;
	linkedInProfile: MemberLinkedInProfile;
	isLoggedIn: boolean;
	dailyInvitesAllocated: number;
	dailyMessagesAllocated: number;
}

export class LinkedinOrganizationMember extends BaseModel<
	typeof LinkedinOrganizationMember,
	ILinkedinOrganizationMember
> {
	static modelName = 'LinkedinOrganizationMember';
	static fields = {
		orgMemberId: attr(),
		linkedInProfile: attr(),
		isLoggedIn: attr(),
		dailyInvitesAllocated: attr(),
		dailyMessagesAllocated: attr(),
	};
}
