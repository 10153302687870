import { QueryResponseType } from '@copilot/common/hooks/types';

/**
 * Key for custom prompts
 */
export const CUSTOM_PROMPT_KEY = 'CUSTOM';

/**
 * Errors generated by AI message generation.
 */
export const WriteSmartReplyErrors = {
	disabled: 'smart reply is disabled',
	moderation: 'message generation failed due to a terms of service error',
	unexpectedFormat: 'unable to handle ai message response',
	outOfCredit: 'the user ran out of credit',
	generation: 'unable to generate smart reply',
} as const;

/**
 * Type representing states of the useChatCompletion hook
 */
export type WriteSmartReplyQueryType = QueryResponseType<string>;

/**
 * Type of the prompt used as input when writing a Smart Reply
 */
export type WriteSmartReplyPromptInput = Readonly<{
	promptId: string;
	message?: string;
}>;

/**
 * Type of the messages used as input when writing a Smart Reply
 */
export type WriteSmartReplyMessageInput = Readonly<{
	message: string;
	outbound: boolean;
}>;

/**
 * Type of the Chat Completion callback
 * @param prompt		The prompt to use (old flow) or the prompt ID to use (new flow)
 * @param message		The custom message to use as a prompt
 */
export type WriteSmartReplyCallbackType = (prompt: string, message?: string) => Promise<void>;

/**
 * Type of the Chat Completion regenerate callback
 */
export type WriteSmartReplyRegenerateCallbackType = () => void;

/**
 * The type returned from useChatCompletion hook
 */
export type WriteSmartReplyHookType = [
	WriteSmartReplyQueryType,
	{
		writeSmartReply: WriteSmartReplyCallbackType;
		regenerateSmartReply: WriteSmartReplyRegenerateCallbackType;
	}
];

/**
 * Type of the prompt used as input when writing a Smart Reply
 */
export type EditSmartReplyPromptInput = Readonly<{
	promptId: string;
	message?: string;
}>;

/**
 * Type representing states of the useChatCompletion hook
 */
export type EditSmartReplyQueryType = QueryResponseType<string>;

/**
 * Type of the Chat Completion callback
 * @param messageToBeEdited	The message to be edited
 * @param prompt			The prompt to use (old flow) or the prompt ID to use (new flow)
 * @param message			The custom message to use as a prompt
 */
export type EditSmartReplyCallbackType = (
	messageToBeEdited: string,
	prompt: string,
	message?: string
) => Promise<void>;

/**
 * The type returned from useChatCompletion hook
 */
export type EditSmartReplyHookType = [
	EditSmartReplyQueryType,
	{
		editSmartReply: EditSmartReplyCallbackType;
	}
];

export type SmartReplyHookType = [
	QueryResponseType<string>,
	{
		writeSmartReply: WriteSmartReplyCallbackType;
		regenerateSmartReply: WriteSmartReplyRegenerateCallbackType;
		editSmartReply: EditSmartReplyCallbackType;
	}
];
