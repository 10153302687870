import BasicContainer from '@copilot/common/components/containers/basic';
import Store from '@copilot/common/store';
import { CampaignDashboardTabKeys } from '@copilot/common/utils/campaign/dashboardTabs';
import { UtilityFunctions } from '@copilot/common/utils/common';
import {
	IAttentionLevel,
	ICampaignAttentionType,
	ICampaignSummaryAttentionItemsQuery,
	IOrgMemberAttentionType,
} from '@copilot/data/graphql/_generated';
import { isEmpty, isNil } from 'lodash';
import { ComponentProps, FC, ReactNode } from 'react';
import AttentionRequired from './attentionRequired';
import useCampaignAttentionItems from '@copilot/common/pages/campaignDashboard/summaryV2/hooks/useCampaignAttentionItems';
import { Typography } from 'antd';
import { AttentionRequiredLevels } from '@copilot/common/components/lists/attentionRequired/constant';
import { useHistory } from 'react-router';
import { History } from 'history';

type AttentionRequiredContainerPropsType = {
	/**
	 * campaign member's id
	 */
	campaignMemberId: string;
	/**
	 * campaign's id
	 */
	campaignId: string;
	/**
	 * organization's id
	 */
	organizationId: string;
	/**
	 * Org member's id
	 */
	orgMemberId: string;
	/**
	 * Path to redirect user when clicking on LinkedIn reconnect attention item
	 */
	linkedInReconnectRedirectPath: string;

	/**
	 * Member's name to display in warnings section
	 */
	memberName: string;
};

/**
 * Map attention items to the issue's copy
 * @param type
 * @param campaignName
 * @returns
 */
function getAttentionItemCopy(type: ICampaignAttentionType, campaignName: string): ReactNode {
	switch (type) {
		case ICampaignAttentionType.CampaignSearchListLow:
			return (
				<Typography.Text>
					Search list in <Typography.Text strong>{campaignName}</Typography.Text> is
					running low and a new search list will be needed soon.
				</Typography.Text>
			);
		case ICampaignAttentionType.NoInvitesSetForProspectingCampaign:
			return (
				<Typography.Text>
					No invites set in <Typography.Text strong>{campaignName}</Typography.Text>. The
					campaign will not run until they are set.
				</Typography.Text>
			);
		case ICampaignAttentionType.NoMessagesSetForNurtureCampaign:
			return (
				<Typography.Text>
					No messages set in <Typography.Text strong>{campaignName}</Typography.Text>. The
					campaign will not run until they are set.
				</Typography.Text>
			);
		default:
			return UtilityFunctions.assertUnreachable(type);
	}
}

/**
 * Map org attention items to the issue's copy
 * @param type
 * @returns
 */
function getOrgAttentionItemCopy(type: IOrgMemberAttentionType, memberName: string): ReactNode {
	switch (type) {
		case IOrgMemberAttentionType.LoggedOutOfLinkedin:
			return (
				<Typography.Text>
					{!isEmpty(memberName) ? `${memberName}'s` : ''} LinkedIn is disconnected
				</Typography.Text>
			);
		case IOrgMemberAttentionType.SalesNavMissing:
			return <></>;
		default:
			return UtilityFunctions.assertUnreachable(type);
	}
}

/**
 * Map attention items to onClick event
 * @param type
 * @param campaignId
 * @param campaignMemberId
 * @returns
 */
function getActionItemOnClick(
	type: ICampaignAttentionType,
	campaignId: string,
	campaignMemberId: string
): () => void {
	const history = Store.History;
	switch (type) {
		case ICampaignAttentionType.CampaignSearchListLow:
			return () =>
				history.push(`/campaign/${campaignId}?tab=${CampaignDashboardTabKeys.SearchList}`, {
					searchListRedirectMemberId: campaignMemberId,
				});
		case ICampaignAttentionType.NoInvitesSetForProspectingCampaign:
		case ICampaignAttentionType.NoMessagesSetForNurtureCampaign:
			return () =>
				history.push(
					`/campaign/${campaignId}?tab=${CampaignDashboardTabKeys.TeamMembers}`,
					{ teamMembersRedirectMemberId: campaignMemberId }
				);
		default:
			return UtilityFunctions.assertUnreachable(type);
	}
}

/**
 * Map org member attention items to onClick event
 * @param type
 * @param history
 * @param orgMemberId
 * @param linkedInReconnectRedirectPath
 * @returns
 */
function getOrgMemberActionItemOnClick(
	type: IOrgMemberAttentionType,
	history: History<unknown>,
	orgMemberId: string,
	linkedInReconnectRedirectPath: string
): () => void {
	switch (type) {
		case IOrgMemberAttentionType.LoggedOutOfLinkedin:
			return () =>
				history.push(linkedInReconnectRedirectPath, {
					teamMembersRedirectMemberId: orgMemberId,
				});
		case IOrgMemberAttentionType.SalesNavMissing:
			return () => {};
		default:
			return UtilityFunctions.assertUnreachable(type);
	}
}

/**
 * Convert item DTO to model
 * @param item
 * @param campaignName
 * @param campaignId
 * @returns
 */
function toAttentionRequiredItem(
	item: ArrayElement<ICampaignSummaryAttentionItemsQuery['campaignSummary']['attentionItems']>,
	campaignName: string,
	campaignId: string
): ArrayElement<ComponentProps<typeof AttentionRequired>['items']> {
	return {
		issue: getAttentionItemCopy(item.type, campaignName),
		level:
			item.level === IAttentionLevel.Warning
				? AttentionRequiredLevels.warning
				: AttentionRequiredLevels.blocker,
		actionLabel: 'Update',
		onActionClick: getActionItemOnClick(item.type, campaignId, item.memberId),
	};
}

/**
 * Convert item DTO to model
 * @param item
 * @param history
 * @param linkedInReconnectRedirectPath
 * @returns
 */
function toOrgMemberAttentionRequiredItem(
	item: ArrayElement<ICampaignSummaryAttentionItemsQuery['orgMembers'][0]['attentionItems']>,
	history: History,
	linkedInReconnectRedirectPath: string,
	memberName: string
): ArrayElement<ComponentProps<typeof AttentionRequired>['items']> {
	return {
		issue: getOrgAttentionItemCopy(item.type, memberName),
		level:
			item.level === IAttentionLevel.Warning
				? AttentionRequiredLevels.warning
				: AttentionRequiredLevels.blocker,
		actionLabel: 'Reconnect',
		onActionClick: getOrgMemberActionItemOnClick(
			item.type,
			history,
			item.memberId,
			linkedInReconnectRedirectPath
		),
	};
}

/**
 * Smart component to fetch attention required items
 * @param props
 * @returns
 */
const AttentionRequiredContainer: FC<AttentionRequiredContainerPropsType> = (props) => {
	const {
		campaignMemberId,
		campaignId,
		organizationId,
		orgMemberId,
		linkedInReconnectRedirectPath,
		memberName,
	} = props;
	const { data, error, loading } = useCampaignAttentionItems(
		campaignId,
		campaignMemberId,
		organizationId,
		orgMemberId
	);
	const history = useHistory();
	// the component is hidden unless there are items to display
	if (loading || error || isNil(data)) return null;

	const campaignItems = data.campaignSummary.attentionItems.map((item) =>
		toAttentionRequiredItem(item, data.campaignSummary.name ?? '', campaignId)
	);

	const orgMemberItems = data.orgMembers.attentionItems
		.filter((item) => item.type !== IOrgMemberAttentionType.SalesNavMissing)
		.map((item) =>
			toOrgMemberAttentionRequiredItem(
				item,
				history,
				linkedInReconnectRedirectPath,
				memberName
			)
		);

	const items = [...orgMemberItems, ...campaignItems];

	// hide component if the current campaign member does not have any items
	if (isEmpty(items)) return null;

	return (
		<BasicContainer>
			<BasicContainer.Content style={{ padding: 0 }}>
				<AttentionRequired items={items} />
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default AttentionRequiredContainer;
