import React, { useMemo, ComponentProps } from 'react';
import {
	PauseCircleTwoTone,
	MinusCircleTwoTone,
	CloseCircleTwoTone,
	CheckCircleTwoTone,
	LockTwoTone,
	EditTwoTone,
} from '@ant-design/icons';
import { Row, Col } from 'antd';
import { UtilityFunctions } from '@copilot/common/utils/common';

export enum StatusFormatIconType {
	PauseCircle,
	MinusCircle,
	CloseCircle,
	CheckCircle,
	Lock,
	Edit,
}

export interface StatusFormatterProps {
	iconSize: string;
	iconType: StatusFormatIconType;
	color: string;
	text: string;
	alignment?: ComponentProps<typeof Row>['justify'];
}

const StatusFormatter: React.FC<StatusFormatterProps> = (props) => {
	const { iconSize, iconType, color, text, alignment = 'center' } = props;

	const iconProps = useMemo(
		() => ({
			twoToneColor: color,
			css: `svg {height: ${iconSize}; width: ${iconSize};}`,
		}),
		[color, iconSize]
	);

	const statusFormatIcon = useMemo(() => {
		switch (iconType) {
			case StatusFormatIconType.PauseCircle:
				return <PauseCircleTwoTone {...iconProps} />;
			case StatusFormatIconType.MinusCircle:
				return <MinusCircleTwoTone {...iconProps} />;
			case StatusFormatIconType.CloseCircle:
				return <CloseCircleTwoTone {...iconProps} />;
			case StatusFormatIconType.CheckCircle:
				return <CheckCircleTwoTone {...iconProps} />;
			case StatusFormatIconType.Lock:
				return <LockTwoTone {...iconProps} />;
			case StatusFormatIconType.Edit:
				return <EditTwoTone {...iconProps} />;
			default:
				return UtilityFunctions.assertUnreachable(iconType);
		}
	}, [iconType, iconProps]);

	return (
		<Row gutter={8} justify={alignment}>
			<Col>{statusFormatIcon}</Col>
			<Col style={{ color }}>
				<strong>{text}</strong>
			</Col>
		</Row>
	);
};

export default StatusFormatter;
