import React from 'react';
import { useFetch } from '../common';
import {
	EXCLUDED_STATS,
	StatFilterDefinition,
} from '@copilot/common/components/componentModels/stat';
import { LinkedInStatLabelMap } from '@copilot/common/constant/enum';

export const useStatOptions = (
	id: string,
	enabledStats: {
		tagStats: string[] | undefined;
		actionStats: string[] | undefined;
	},
	getter: ReturnType<typeof useFetch>,
	updater: ReturnType<typeof useFetch>
): [StatFilterDefinition[], (stats: StatFilterDefinition[]) => Promise<void>] => {
	const data = getter[0].data as { actionStats: string[]; tagStats: string[] } | undefined;
	React.useEffect(() => {
		id && getter[1](id);
	}, [id]);

	const statOptions = React.useMemo(() => {
		if (!data) return [];
		const options = [...data.actionStats, ...data.tagStats];
		const enabled = [
			...(enabledStats.actionStats ?? data.actionStats),
			...(enabledStats.tagStats ?? []),
		];
		const checkedOptions = enabled.reduce<{ [k: string]: boolean }>((acc, s) => {
			acc[s] = true;
			return acc;
		}, {});
		return options
			.filter((o) => !(EXCLUDED_STATS as any)[o])
			.map(
				(d) =>
					new StatFilterDefinition({
						key: d,
						label: LinkedInStatLabelMap[d as keyof typeof LinkedInStatLabelMap] ?? d,
						isVisible: checkedOptions[d],
						hideable: true,
					})
			);
	}, [data, enabledStats]);

	const filterListUpdater = React.useCallback(
		async (stats: StatFilterDefinition[]) => {
			if (id && data) {
				const actionStatOptions = data.actionStats.reduce<{
					[k: string]: boolean;
				}>((acc, s) => {
					acc[s] = true;
					return acc;
				}, {});
				const tagStatOptions = data.tagStats.reduce<{
					[k: string]: boolean;
				}>((acc, s) => {
					acc[s] = true;
					return acc;
				}, {});

				const actionStats: string[] = [];
				const tagStats: string[] = [];
				stats.forEach((s) => {
					if (s.isVisible) {
						if (actionStatOptions[s.key]) actionStats.push(s.key);
						if (tagStatOptions[s.key]) tagStats.push(s.key);
					}
				});

				await updater[1](id, { actionStats, tagStats });
			}
		},
		[id, data]
	);

	return [statOptions, filterListUpdater];
};
