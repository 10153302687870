import { isValidElement, ReactNode, ReactNodeArray, SyntheticEvent } from 'react';
import {
	LinkedInSentMessageError,
	OrgRoleTitles,
	ThreadStatus,
} from '@copilot/common/store/models/const/enum';
import { BrowserVendor } from '../constant';
import { Config } from '@copilot/common/config';
import { Tag } from 'antd';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

export namespace UtilityFunctions {
	export const stopPropogation = (event: SyntheticEvent): void => event.stopPropagation();

	/**
	 * Generate a Percentage from decimal number. Will return 0% if number is zero regardless of number of decimals
	 * @param {number} n The number to convert
	 * @param {number} numOfDigit Number of decimal digits, default set to 1
	 */
	export const convertDecimalToPercentage = (n?: number, numOfDigit = 1): string => {
		if (n === 0) {
			return '0%';
		}
		return n === undefined || Number.isNaN(n) || !Number.isFinite(n)
			? 'N/A'
			: `${(n * 100).toFixed(numOfDigit)}%`;
	};

	export const constructUrl = (
		paths: string[],
		params?: { [k: string]: any },
		host = ''
	): string => {
		let uri = encodeURI(`${host}/${paths.join('/')}`);
		if (params) {
			uri += `?${Object.keys(params)
				.map(
					(key) =>
						`${encodeURIComponent(key)}=${encodeURIComponent(
							params[key] as string | number | boolean
						)}`
				)
				.join('&')}`;
		}
		return uri;
	};

	/**
	 * Generate a referral url using a users first and last name
	 * @param {string} firstName First name of the referrer
	 * @param {string} lastName Last name of the referrer
	 */
	export const generateReferralUrl = (firstName: string, lastName: string): string =>
		constructUrl(
			[],
			{
				utm_medium: 'customer_referral',
				utm_source: 'app',
				utm_content: `${firstName}${lastName}`,
			},
			Config.referralURL
		);

	export const stringTruncate = (s: string, maxLength: number): string =>
		s.length <= maxLength ? s : `${s.substring(0, maxLength - 1)}...`;

	export const isClientChrome = () => window.navigator.vendor === BrowserVendor.Chrome;

	/**
	 * Util function for exhaustive check
	 */
	export const assertUnreachable = (_: never): never => {
		throw new TypeError('Case not handled');
	};

	export const getThreadStatusIcon = (
		status: [ThreadStatus, LinkedInSentMessageError, boolean]
	): JSX.Element => {
		const [threadStatus, , isContactDeleted] = status;
		if (isContactDeleted) return <Tag color="red">Removed</Tag>;
		switch (threadStatus) {
			case ThreadStatus.Pending:
				return <Tag color="blue">Sending</Tag>;
			case ThreadStatus.Success:
			case ThreadStatus.Canceled:
			case ThreadStatus.Unknown:
			case ThreadStatus.Failed:
				return <></>;
			default:
				return assertUnreachable(threadStatus);
		}
	};
	/**
	 * Redirect to the Main Page
	 */
	export const redirectToMain = (): JSX.Element => <Redirect to="/" />;
}

/**
 * Get a random Date object within the past number of days
 * @param days
 */
export const getRandomDateFromDays = (days = 1): Date => {
	const randomDays = Math.round(Math.random() * days);
	const date = new Date();
	date.setDate(date.getDate() - randomDays);
	return date;
};

/**
 * Generate a random boolean
 */
export const getRandomBoolean = (): boolean => Math.random() >= 0.5;

/**
 * Find the first matching component from the children ReactNodeArray
 * @param children
 * @param Component
 */
export const getChildByType = (
	children: ReactNodeArray,
	Component: ReactNode
): ReactNode | undefined =>
	children.find((child) => isValidElement(child) && child.type === Component);

/**
 * Check if orgRoles is OrgAdmin or above
 * @param orgRoles
 */
export const getIsOrgAdminOrAbove = (orgRoles: string[]): boolean =>
	orgRoles.includes(OrgRoleTitles.Owner) || orgRoles.includes(OrgRoleTitles.Admin);

/**
 * Add link to element
 * @param element element we want to add link to
 * @param destination link destination to redirect to
 */
export const addLink = (element: ReactNode, destination: string): ReactNode => (
	<Link to={destination}>{element}</Link>
);

/**
 * Check if a string is empty
 * @param str
 */
export function isEmptyString(str: string): boolean {
	return isEmpty(str.trim());
}
