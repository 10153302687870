import { ProspectTableColumns } from '@copilot/common/components/tables/tableTypes/prospect/columns';

/**
 * Consts for the nurture list nodes and mappings
 */
export const INITIATE_NODE = 0;
export const TERMINATE_NODE = -1;
export const NodeToStageNameMap: { [k: number]: string } = {
	[INITIATE_NODE]: 'Awaiting 1st Message',
	[TERMINATE_NODE]: 'Done',
};
export const StepTriggerLabelMap: Record<string, string> = {
	'User initiated terminate': 'Automation Stopped',
};

/**
 * Automation step labels
 */
export const NurtureAutomationStepLabels = {
	AUTOMATION_STOPPED: 'Automation Stopped',
	AUTOMATION_DONE: 'Done',
} as const;

/**
 * Nurture table column constants
 */
export const NurtureTableColumns = [
	ProspectTableColumns.ContactUrl,
	ProspectTableColumns.Name,
	ProspectTableColumns.CurrentStage,
	ProspectTableColumns.Actions,
];

/**
 * Default page size for the nurture table
 */
export const NURTURE_TABLE_DEFAULT_PAGE_SIZE = 10;
