import { Card, Row, Col, Typography, Tag } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { FC, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import AllocationEditor from '../allocation';
import { Allocation } from './interface';

const ColoredSegment = styled(Card)`
	&& {
		margin-left: 15px;
		box-shadow: -15px 0 0 0 ${(props) => props.color};
		background-color: white;
		border-right-width: 0px;
		border-radius: 0;
		padding: 10px 10px 10px 15px;
	}
`;

const DisabledTag = styled(Tag)`
	&& {
		margin-left: 10px;
	}
`;

// Hack for now as Antd supports ReactNodes as it's suffix. Build our own in the future
const AllocationLabel: FC<ParagraphProps | { ellipsis: { suffix?: ReactNode } }> =
	Typography.Paragraph as FC<ParagraphProps | { ellipsis: { suffix?: ReactNode } }>;

interface IItemContainerProps {
	allocationRender?: (item: Allocation) => ReactNode;
	item: Allocation;
}

/**
 * [Presentational]
 * An allocation item display component with a colored left side
 * @param props
 * @returns
 */
const DisplayContainer: FC<IItemContainerProps> = (props) => {
	const { item, allocationRender } = props;
	const allocationNode = useMemo(
		() => (allocationRender ? allocationRender(item) : item.allocation),
		[allocationRender, item]
	);
	return (
		<ColoredSegment color={item.color}>
			<Row gutter={48} align="middle">
				<Col xs={10} md={12} lg={10} xl={6}>
					<AllocationLabel
						strong
						ellipsis={{
							suffix: !item.isEnabled ? (
								<DisabledTag>Disabled</DisabledTag>
							) : undefined,
						}}
						style={{ width: '100%', marginBottom: '0px' }}
					>
						{item.label}
					</AllocationLabel>
				</Col>
				{allocationNode}
			</Row>
		</ColoredSegment>
	);
};

interface IEditContainerProps {
	onValueChange: (value: number, index: number) => void;
	item: Allocation;
	index: number;
	max: number;
	recommendValue?: number;
}

/**
 * [Presentational]
 * An allocation item edit component with a colored left side
 * @param props
 * @returns
 */
const EditContainer: FC<IEditContainerProps> = (props) => {
	const { item, index, max, onValueChange, recommendValue } = props;
	return (
		<ColoredSegment color={item.color}>
			<Row gutter={48} align="middle">
				<Col>
					<AllocationLabel
						strong
						ellipsis={{
							suffix: !item.isEnabled ? (
								<DisabledTag>Disabled</DisabledTag>
							) : undefined,
						}}
						style={{ width: '100%', marginBottom: '0px' }}
					>
						{item.label}
					</AllocationLabel>
				</Col>
			</Row>
			<AllocationEditor
				value={item.allocation}
				maxValue={max}
				onValueChange={(v) => onValueChange(v, index)}
				recommendedValue={recommendValue}
			/>
		</ColoredSegment>
	);
};

interface ILimitsEditViewProps {
	items: Allocation[];
	onValueChange: (value: number, index: number) => void;
	max: number;
	recommendValue?: number;
}

/**
 * [Presentational]
 * List of allocation items in edit view
 * @param props
 * @returns
 */
export const LimitsEditView: FC<ILimitsEditViewProps> = (props) => {
	const { items, max, recommendValue, onValueChange } = props;
	return (
		<Row align="middle">
			{items.map((item, index) => (
				<Col span={24} key={item.id}>
					<EditContainer
						item={item}
						index={index}
						max={max}
						onValueChange={onValueChange}
						recommendValue={recommendValue}
					/>
				</Col>
			))}
		</Row>
	);
};
interface ILimitsDisplayViewProps {
	items: Allocation[];
	allocationRender?: (item: Allocation) => React.ReactNode;
}

/**
 * [Presentational]
 * List of allocation items in display view
 * @param props
 * @returns
 */
export const LimitsDisplayView: FC<ILimitsDisplayViewProps> = (props) => {
	const { items, allocationRender } = props;
	return (
		<Row align="middle">
			{items.map((item) => (
				<Col span={24} key={item.id}>
					<DisplayContainer item={item} allocationRender={allocationRender} />
				</Col>
			))}
		</Row>
	);
};
