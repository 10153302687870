import BaseDataManager from './base';
import {
	OrganizationMemberResponse,
	OrganizationMemberSettingsResponse,
	EmptyResponse,
} from '../responses/interface';
import {
	MeUpdateRequest,
	OrganizationMemberRequestModel,
	OnboardMessageResponse,
} from '../requests/models';
import { CsOwnerInformation } from '@copilot/data/responses/models/organizationMember';
import { IOrganizationMember } from '@copilot/common/store/models/redux/organizationMember';

class OrganizationMemberManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get info for an Organization Member or the logged in Organization Member
	 * @param {string|undefined} The id of the Organization Member
	 */
	public getMember = (id?: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default, id ?? 'me');
		return this.RequestManager.get<OrganizationMemberResponse>(url).then(
			(response) => response.data
		);
	};

	/**
	 * Update the currently logged in user
	 * @param user The user fields to update
	 * @returns Updated user
	 */
	public updateSelf = (user: Partial<MeUpdateRequest>): Promise<IOrganizationMember> =>
		this.RequestManager.put<IOrganizationMember>(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Me,
			user
		).then((response) => response.data);

	/**
	 * Reset the currently logged in user's sales nav error status
	 * @param user The user fields to update
	 * @returns Updated user
	 */
	public resetMySalesNavErrorStatus = (): Promise<IOrganizationMember> =>
		this.RequestManager.put<IOrganizationMember>(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.ResetMySalesNavErrorStatus
		).then((response) => response.data);
	/**
	 * Clear the user's current cookies and disconnect from linkedin
	 * @param user The user fields to update
	 * @returns Updated user
	 */
	public disconnectMyLinkedIn = (): Promise<IOrganizationMember> =>
		this.RequestManager.put<IOrganizationMember>(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.DisconnectLinkedIn
		).then((response) => response.data);

	/**
	 * Clear the user's current cookies and disconnect from linkedin
	 * @param user The user fields to update
	 * @returns Updated user
	 */
	public disconnectMemberLinkedIn = (id: string): Promise<IOrganizationMember> =>
		this.RequestManager.put<IOrganizationMember>(
			this.combineRoute(
				this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default,
				id,
				'disconnect-linkedin'
			)
		).then((response) => response.data);

	/**
	 * Reset the member's sales nav error status
	 * @param user The user fields to update
	 * @returns Updated user
	 */
	public resetMemberSalesNavErrorStatus = (id: string): Promise<IOrganizationMember> =>
		this.RequestManager.put<IOrganizationMember>(
			this.combineRoute(
				this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default,
				id,
				'clear-sales-nav-error'
			)
		).then((response) => response.data);
	/**
	/**
	 * Update the inbox view settings for an organization member
	 * @param id The org member's id
	 * @param viewType The inbox view we want to set
	 */
	public updateInboxView = (
		id: string,
		viewType: OrganizationMemberSettingsResponse['inboxType']
	) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default,
			id,
			'inboxView'
		);
		return this.RequestManager.put<EmptyResponse>(url, undefined, {
			params: { viewType },
		}).then(() => viewType);
	};

	/**
	 * Update the inbox view settings for an organization member
	 * @param id The org member's id
	 * @param viewType The inbox view we want to set
	 */
	public updateFEUserSetting = (
		id: string,
		userSettings: Partial<OrganizationMemberSettingsResponse>
	) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.SETTINGS.FE, id);
		return this.RequestManager.put<OrganizationMemberSettingsResponse>(url, userSettings).then(
			(r) => r.data
		);
	};

	/**
	 * Update an organization member
	 * @param {Partial<OrganizationMemberRequestModel>} member The Organization Member model we
	 *                                                         want to update
	 */
	public updateMember = (member: Partial<OrganizationMemberRequestModel>) => {
		if (!member || !member.id) return Promise.reject(new Error('Member with id not provided'));
		const url = this.combineRoute(this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default, member.id);
		return this.RequestManager.put<OrganizationMemberResponse>(url, member).then((r) => r.data);
	};

	/**
	 * Get the linkedin message templates for an organization member
	 * @param organizationMemberId The organization member id
	 */
	public getLinkedInMessages(organizationMemberId: string) {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.LINKED_IN.Onboard,
			organizationMemberId,
			'messages'
		);
		return this.RequestManager.get<OnboardMessageResponse>(url).then(
			(response) => response.data
		);
	}

	/**
	 * Get the linkedin message templates for a campaign
	 * @param campaignID campaign id
	 */
	public getLinkedInMessagesByCampaign = (campaignID: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignID,
			'messages'
		);
		return this.RequestManager.get<OnboardMessageResponse>(url).then(
			(response) => response.data
		);
	};

	/**
	 * Check whether an organization member is logged into linkedin.
	 * @param orgMemberId The id of the organization member
	 */
	public checkIsLoggedIn = (orgMemberId: string): Promise<boolean> => {
		if (!orgMemberId) Promise.reject(new Error('No organization member provided.'));
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default,
			orgMemberId,
			'linkedin',
			'isLoggedIn'
		);
		return this.RequestManager.get<boolean>(url).then((response) => response.data);
	};

	/**
	 * @deprecated
	 * Retrieve the CS owner's calendly url based on customers org member id
	 * @param {string} id The id of the organization member we want to retrieve
	 */
	public getCSCalendlyUrl = (id: string) => {
		if (!id) return Promise.reject('No id given');
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default,
			id,
			'cscalendlyurl'
		);
		return this.RequestManager.get<string>(url).then((response) => response.data);
	};

	/**
	 * Retrieve the cs owner's information based on customers org member id
	 * @param {string} id The id of the organization member we want to retrieve
	 */
	public getCSOwnerInfo = async (id: string | undefined): Promise<CsOwnerInformation> => {
		if (!id) return Promise.reject('No id given');
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default,
			id,
			'customerSupport'
		);
		const response = await this.RequestManager.get<CsOwnerInformation>(url);
		return response.data;
	};

	/**
	 * Send a reset password link to the email
	 * @param email email to reset password for
	 * @param isIndividualUser
	 * @returns {Promise<boolean>}
	 */
	public resetPassword = (email: string, isIndividualUser: boolean) =>
		this.RequestManager.post<EmptyResponse>(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.ResetPassword,
			{
				email,
				isIndividualUser,
			}
		).then((response) => response.data);

	/**
	 * Remove a member from an organization
	 * @param {string} id The id of the organization member we want to remove
	 */
	public removeMember = async (id: string): Promise<void> => {
		if (!id) return Promise.reject('No id given');
		const url = this.combineRoute(this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default, id);
		await this.RequestManager.delete(url);
		return Promise.resolve();
	};

	public checkIsPaused(orgMemberId: string): Promise<boolean> {
		//TODO update route when BE is in
		if (!orgMemberId) Promise.reject(new Error('No organization member provided.'));
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default,
			orgMemberId,
			'linkedin',
			'isPaused'
		);
		return this.RequestManager.get<boolean>(url).then((response) => response.data);
	}

	/**
	 * Update org member's preference on whether or not to hide the Search URL video modal
	 * @param orgMemberId The id of the organization member that we want to update preference for
	 * @param shouldHide Whether to hide the search video modal or not
	 */
	public updateHideSearchModalPreference = (orgMemberId: string, shouldHide: boolean) => {
		if (!orgMemberId) Promise.reject(new Error('No organization member provided.'));
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION_MEMBER.Default,
			orgMemberId,
			'liSearchModal'
		);
		return this.RequestManager.put<EmptyResponse>(url, undefined, {
			params: { shouldHide },
		}).then(() => shouldHide);
	};
}

const instance = new OrganizationMemberManager();
export default instance;
