import { Dropdown, Menu, Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import { DownOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import { DropdownOption } from './types';
import styles from './dropdownWithTitle.module.less';

/**
 * [Presentational] Team member dropdown component, for use in search and nurture lists
 * @param {string} description description for the dropdown
 * @param {string} title title for the dropdown
 * @param {string} dropdownLabel label for the dropdown button
 * @param {DropdownOption[]} options the options to be displayed in the dropdown
 * @param {function} onSelect called when a menu item is selected
 */
type TeamMemberDropDownProps = {
	description?: string;
	title?: string;
	dropdownLabel?: string;
	options: DropdownOption[];
	onSelect: (id: string) => void;
	selectedMemberName?: string;
};

export default function TeamMemberDropDown({
	description = 'Search lists are used to help find your ideal target audience. Create a search URL on LinkedIn Sales Navigator to capture prospects for this campaign.',
	title = 'Select team member to view',
	dropdownLabel = 'Team Members',
	selectedMemberName,
	options,
	onSelect,
}: TeamMemberDropDownProps) {
	return (
		<div>
			<Title level={4} className={styles.description}>
				{description}
			</Title>

			<div className={styles.dropDownWrapper}>
				<Title level={3} className={styles.title}>
					{title}
				</Title>
				<Dropdown
					trigger={['click']}
					overlay={
						<Menu onClick={(i) => onSelect(i.key)}>
							{options.map((option) => (
								<Menu.Item key={option.id}>{option.label}</Menu.Item>
							))}
						</Menu>
					}
				>
					<Button className={styles.dropDownButton}>
						{isEmpty(selectedMemberName) ? dropdownLabel : selectedMemberName}
						<DownOutlined />
					</Button>
				</Dropdown>
			</div>
		</div>
	);
}
