import { Row, Col } from 'antd';
import { FC, memo } from 'react';

interface ITitleWithEnlargedNumberProps {
	title: string;
	count: number;
}

/**
 * [Presentational]
 * Alert message that displays a title and an enlarged numerical value
 */
export const TitleWithEnlargedNumber: FC<ITitleWithEnlargedNumberProps> = memo((props) => {
	const { title, count } = props;
	return (
		<Row>
			<Col span={24}>
				<span>{title}</span>
			</Col>
			<Col span={24}>
				<span style={{ fontSize: '1.5em' }}>{count}</span>
			</Col>
		</Row>
	);
});
