import { useMsal } from '@azure/msal-react';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { B2CCustomPolicyRoutes } from '@copilot/data/config/routes';
import { getAuthRedirectRequestOptions } from '@copilot/common/utils/authenticationProvider';

interface B2CSignUpPageProps extends RouteComponentProps {
	isIndividualUser?: boolean;
}

/**
 * Sign up page, redirects to the B2C SignUpInvitation user journey and shows a loading menu
 * @param isIndividualUser used to determine which base url to redirect to after the journey is complete
 */
const B2CSignUpPage: React.FC<B2CSignUpPageProps> = (props) => {
	const queryParams = new URLSearchParams(props.location.search);
	const identityId = queryParams.get('identityId')!;
	const email = queryParams.get('email')!;
	const id_token_hint = queryParams.get('id_token_hint');

	const msal = useMsal();
	useEffect(() => {
		(async () => {
			const pca = msal.instance;
			await pca.handleRedirectPromise();
			const redirectUri = window.location.origin;
			const authOptions = getAuthRedirectRequestOptions({
				authority: B2CCustomPolicyRoutes.SignUp,
				scopes: [],
				redirectUri,
				extraQueryParameters: {
					identityId: encodeURIComponent(identityId),
					email: encodeURIComponent(email),
					id_token_hint: id_token_hint ? encodeURIComponent(id_token_hint) : '',
				},
			});
			pca.loginRedirect(authOptions);
		})();
	}, [identityId, email]);

	return <LoadingIndicator isLoading />;
};

export default B2CSignUpPage;
