// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WWV4_AsYjEAQauSszdBR {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n.WWV4_AsYjEAQauSszdBR > * {\n  width: 800px;\n  margin: 56px 0;\n}\n.z13u9DWdXR2SbhMhd9uX {\n  padding: 16px;\n  margin-bottom: 16px;\n}\n.z13u9DWdXR2SbhMhd9uX .copilot-card-body {\n  padding: 0;\n}\n.oAZIiCXXBi4HbMrstjPX {\n  margin: 16px 0;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 28px;\n}\n.bkji49tDWXiUhuK_SMRh {\n  max-width: 450px;\n}\n.yXAMs08HI9ArLXS2H3RO {\n  max-width: 250px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/campaignDashboard/searchListCommon/searchListCommon.module.less","webpack://./../../common/pages/campaignDashboard/nurtureList/nurtureList.module.less"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,uBAAA;EACA,WAAA;ACCD;ADAC;EACC,YAAA;EACA,cAAA;ACEF;ADCA;EACC,aAAA;EAIA,mBAAA;ACFD;ADHA;EAGE,UAAA;ACGF;ADEA;EACC,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;ACAD;AApBA;EACC,gBAAA;AAsBD;AAnBA;EACC,gBAAA;AAqBD","sourcesContent":[".instructionWrapper {\n\tdisplay: flex;\n\tjustify-content: center;\n\twidth: 100%;\n\t& > * {\n\t\twidth: 800px;\n\t\tmargin: 56px 0;\n\t}\n}\n.searchUrlModule {\n\tpadding: 16px;\n\t:global(.copilot-card-body) {\n\t\tpadding: 0;\n\t}\n\tmargin-bottom: 16px;\n}\n\n.searchListTitle {\n\tmargin: 16px 0;\n\tfont-size: 20px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tline-height: 28px;\n}\n","@import '../searchListCommon/searchListCommon.module.less';\n\n.connectionMovementTitle {\n\tmax-width: 450px;\n}\n\n.pauseAutomationTitle {\n\tmax-width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instructionWrapper": "WWV4_AsYjEAQauSszdBR",
	"searchUrlModule": "z13u9DWdXR2SbhMhd9uX",
	"searchListTitle": "oAZIiCXXBi4HbMrstjPX",
	"connectionMovementTitle": "bkji49tDWXiUhuK_SMRh",
	"pauseAutomationTitle": "yXAMs08HI9ArLXS2H3RO"
};
export default ___CSS_LOADER_EXPORT___;
