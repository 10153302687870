import { Col, Row, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import BaseColumn from './base';
import { TagColumnDefinition } from '../../componentModels/columnDefinition';
import TagFilter from '../../filters/tag';
import { sortByFilteredTags } from '@copilot/common/utils/filters';

const MAX_TAGS = 4;
//TODO: update to functional component
export class TagsColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];
	public filterDropdown: ColumnProps<M>['filterDropdown'];
	public filterIcon: ColumnProps<M>['filterIcon'];
	public onFilterDropdownVisibleChange: ColumnProps<M>['onFilterDropdownVisibleChange'];
	// public sorter: ColumnProps<M>['sorter'];
	constructor(c: TagColumnDefinition) {
		super(c);
		this.render = (text: string, record: M) => {
			const { properties = [] } = c;
			const tagsArr = this.getValues<any>(properties, record);

			const tags = tagsArr[0];
			const filteredTags = new Set<string>(tagsArr[1] as Iterable<string>);

			const tagDisplay = [];
			let i;
			for (i = 0; i < tags.length; i++) {
				if (i >= MAX_TAGS) {
					tagDisplay.push(
						<span key={i} style={{ cursor: 'default' }}>
							...
						</span>
					);
					break;
				}
				if (tags[i])
					tagDisplay.push(
						filteredTags.has(tags[i].name as string) ? (
							<Tag key={i} color="blue">
								{tags[i].name}
							</Tag>
						) : (
							<Tag key={i}>{tags[i].name}</Tag>
						)
					);
			}

			return filteredTags.size == 0
				? tagDisplay
				: sortByFilteredTags(tagDisplay, filteredTags);
		};

		if (c.filter) {
			this.filterDropdown = ({ setSelectedKeys, confirm, clearFilters, visible }: any) => (
				<Row className="cmpt-tables-filter" gutter={16}>
					<Col>
						<TagFilter
							confirm={confirm}
							resetFields={clearFilters}
							setTag={setSelectedKeys}
							tags={c.tagFilters}
							open={visible}
						/>
					</Col>
				</Row>
			);
		}
		// this.sorter = (a: M, b: M) => {
		// 	let { properties = [] } = c;
		// 	let { aTagsLength, bTagsLength } = properties.reduce(
		// 		(acc, p) => {
		// 			acc.aTagsLength += (a as any)[p].length;
		// 			acc.bTagsLength += (b as any)[p].length;
		// 			return acc;
		// 		},
		// 		{ aTagsLength: 0, bTagsLength: 0 }
		// 	);
		// 	return aTagsLength - bTagsLength;
		// };
	}
}
