import React, { useContext } from 'react';
import { Col, Row, Card } from 'antd';
import styled, { ThemeContext } from 'styled-components';
import { Config } from '@copilot/common/config';
import BasicContainer from '@copilot/common/components/containers/basic';
import LoginLayoutBackground from '@copilot/common/pages/layouts/login/background';
import { AgencyCode } from '@copilot/common/config/constant';

const LogoCenteredLayoutContainer = styled(Row)`
	background-color: ${(props) =>
		props.theme[
			Config.isAgency ? '@layout-sider-background-light' : '@layout-sider-background'
		]};
	flex-direction: column;
	height: 100%;
	min-height: 100vh;
	width: 100%;
`;

const StyledLogoCard = styled(Card)`
	&&& {
		padding: 10% 5%;
		text-align: center;

		// mobile only
		@media screen and (max-width: ${(props) => props.theme['@screen-xs-max']}) {
			border: 0;
			margin-top: -15vh;
		}

		// non-mobile only
		@media screen and (min-width: ${(props) => props.theme['@screen-sm-min']}) {
			box-shadow: 0 0 30px 10px #0000000d;
		}
	}
`;

const StyledLogoCardLogo = styled.img`
	width: 75%;
	display: block;
	margin: auto;
	margin-bottom: 10%;
`;

const getLogo = () => {
	if (Config.isAgency) {
		switch (Config.agencyCode) {
			case AgencyCode.cleverly:
				return require('../../../assets/images/cleverly.png');
			case AgencyCode.prosocial:
			default:
				return null;
		}
	} else {
		return require('../../../assets/images/copilotai_black.png');
	}
};

/**
 * Layout for the Login Page, handled differently for CoPilot and Agencies
 */
const LoginLayout: React.FC = (props) => {
	const themeContext = useContext(ThemeContext);
	const logo = getLogo();

	if (!Config.isAgency)
		return (
			<Row justify="center" align="middle" style={{ height: '100vh', position: 'relative' }}>
				<LoginLayoutBackground />
				<Col xs={24} sm={14} md={12} lg={10} xl={8} xxl={6}>
					<StyledLogoCard>
						<StyledLogoCardLogo src={logo} />
						{props.children}
					</StyledLogoCard>
				</Col>
			</Row>
		);
	return (
		<LogoCenteredLayoutContainer justify="center" align="middle">
			<Col style={{ textAlign: 'center' }}>
				<img className="logo" src={logo} style={{ width: themeContext['@logo-width'] }} />
			</Col>
			<Col span={12} style={{ width: '100%' }}>
				<Row>
					<Col span={24}>
						<BasicContainer
							style={{
								textAlign: 'center',
								padding: '24px 8px 8px',
								maxWidth: '600px',
								margin: 'auto',
							}}
						>
							<BasicContainer.Content>{props.children}</BasicContainer.Content>
						</BasicContainer>
					</Col>
				</Row>
			</Col>
		</LogoCenteredLayoutContainer>
	);
};

export default LoginLayout;
