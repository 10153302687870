import { IOrganization, Organization } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = Organization.modelName;
export namespace OrganizationActions {
	export const loadOrganization = (
		organization: Partial<IOrganization> | Partial<IOrganization>[]
	) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: organization,
	});

	export const updateOrganization = (organization: Partial<IOrganization>) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: organization,
	});

	export const deleteOrganization = (organization: Partial<IOrganization>) => ({
		type: EntitiesActionTypes.Delete,
		itemType,
		payload: organization,
	});
}
