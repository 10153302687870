import { Typography } from 'antd';
import { ListItemProps } from 'antd/lib/list';
import { Contact, IMessage, OrganizationMember } from '@copilot/common/store/models/redux';
import { SessionBoundModel } from 'redux-orm';
import { ProspectDrawerTrackingParams } from '@copilot/common/components/drawer/wrappers/contact/tracking';
import { InMailCampaignFooterStyle, MessageListItem, OrgMemberAvatar } from './styles';
import moment from 'moment';
import { ContactAvatar } from './contact-avatar';
import { renderMessageContent } from './util';
import { InMailStatusConstants } from '@copilot/common/constant/inMailConstant';
import { DeclinedMessage } from '../../../../../copy/inMailCopy';
import { CONVERSATION_ITEM_VIEW_LI_PROFILE_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';

export type MessageItemProps = ListItemProps & {
	message: IMessage;
	contact: SessionBoundModel<Contact>;
	orgMember: SessionBoundModel<OrganizationMember>;
	activeMember: SessionBoundModel<OrganizationMember> | null;
	sourceName: string;
	targetName: string;
	threadUrl: string | undefined;
	onTracking?: (params: ProspectDrawerTrackingParams) => void;
};

const { Link, Text } = Typography;

function ConversationItem(props: MessageItemProps) {
	const { threadUrl, orgMember, activeMember, targetName, onTracking } = props;
	const { timestamp, isSelf, data, subject } = props.message;
	const date = timestamp ? moment(timestamp).format('hh:mmA') : null;
	const isViewedByOwner = orgMember && activeMember && orgMember.id === activeMember.id;
	const messageSender = isSelf
		? `${orgMember.firstName} ${orgMember.lastName} ${isViewedByOwner ? '(You)' : ''}`
		: targetName;
	const isInMailCampaignMesaage = Boolean(subject);
	const isPending = Boolean(props.message.pending);
	const isInMailDeclined = props.message.inMailStatus === InMailStatusConstants.Declined;

	return (
		<MessageListItem
			style={{
				border: isPending ? 'solid 1px #91CAFF' : undefined,
				backgroundColor: isInMailDeclined ? '#0000000f' : undefined,
			}}
			className={`cmpt-module-conversation-item activity-item ${
				isSelf ? 'self-item' : 'contact-item'
			}`}
		>
			<div
				style={{
					display: 'flex',
					columnGap: '16px',
					width: '100%',
				}}
			>
				{isSelf ? (
					<OrgMemberAvatar />
				) : (
					<ContactAvatar contactName={targetName} type="message" />
				)}
				<div className="message-item-wrapper">
					<div className="item-header">
						{!isSelf ? (
							<Link
								href={threadUrl}
								target="_blank"
								underline
								onClick={() =>
									onTracking?.({
										buttonClicked: 'View on LinkedIn Button',
									})
								}
								data-tracking-id={CONVERSATION_ITEM_VIEW_LI_PROFILE_TRACKING_ID}
							>
								<Text strong>{messageSender}</Text>
							</Link>
						) : (
							<>
								<Text strong>{messageSender}</Text>
							</>
						)}
						<div style={{ fontSize: '12px' }}>
							{isPending ? (
								<>
									<span style={{ opacity: '0.45' }}> {'Pending,'} </span>

									<Link
										href={'/outbox'}
										style={{ padding: '0', fontSize: '12px', color: '#1677FF' }}
									>
										View in outbox
									</Link>
									{''}
								</>
							) : (
								<span style={{ opacity: '0.45' }}>{date}</span>
							)}
						</div>
					</div>
					{isInMailDeclined ? (
						<span style={{ fontSize: '12px' }}>
							<DeclinedMessage />
						</span>
					) : null}
					<div className="item-content">{renderMessageContent(data, subject)}</div>
				</div>
			</div>
			{isInMailCampaignMesaage ? (
				<div style={InMailCampaignFooterStyle}>
					<Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>InMail Campaign</Text>
				</div>
			) : null}
		</MessageListItem>
	);
}

export default ConversationItem;
