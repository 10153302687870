import {
	NodeTimerPeriodEnum,
	NodeTimerPeriodStringEnum,
} from '@copilot/common/store/models/const/enum';

export type Template = {
	key: string;
	label: string;
	value: string;
};

//TODO: [Nurture] Update nurture messaging templates
export const NURTURE_MESSAGE_TEMPLATES = [
	{ key: 'nurture1', label: 'Example 1', value: 'Testing sample 1' },
	{ key: 'nurture2', label: 'Example 2', value: 'Testing sample 2' },
	{ key: 'nurture3', label: 'Example 3', value: 'Testing sample 3' },
];

export const NURTURE_DEFAULT_TIME = 1;

export const NURTURE_EMPTY_MESSAGE = {
	nodeId: '',
	period: NodeTimerPeriodEnum.Months,
	text: '',
	time: NURTURE_DEFAULT_TIME,
};

/**
 * Default time units to display in Timing Editors. Listed in order of showing
 */
export const DEFAULT_TIME_UNITS = [
	NodeTimerPeriodStringEnum.Minutes,
	NodeTimerPeriodStringEnum.Hours,
	NodeTimerPeriodStringEnum.Days,
	NodeTimerPeriodStringEnum.Weeks,
];

/**
 * Time units to display in Timing Editors when we are interested in longer period of time only.
 * Listed in order of showing
 */
export const LONGER_TIME_UNITS = [
	NodeTimerPeriodStringEnum.Days,
	NodeTimerPeriodStringEnum.Weeks,
	NodeTimerPeriodStringEnum.Months,
];

export enum MessageType {
	FollowUp = 'follow up',
	Nurture = 'Nurture',
}

export const MAX_MESSAGE_NUMBER = 10;

export const REQUEST_MESSAGE_CHAR_LIMIT = 295;
