import { Col, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import BaseColumn from './base';
import { StringWithIdColumnDefinition } from '../../componentModels/columnDefinition';
import IdFilter from '../../filters/id';

const convertToValue = (values: string[]) => values.join(' ');

export class StringWithIdColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];
	public filterDropdown: ColumnProps<M>['filterDropdown'];
	public filterIcon: ColumnProps<M>['filterIcon'];
	public onFilterDropdownVisibleChange: ColumnProps<M>['onFilterDropdownVisibleChange'];
	constructor(c: StringWithIdColumnDefinition) {
		super(c);
		this.render = (text: string, record: M) => {
			const { properties = [] } = c;
			const values = convertToValue(this.getValues(properties, record));

			return (
				<div>
					<span>{values}</span>
				</div>
			);
		};

		if (c.filter) {
			this.filterDropdown = ({ setSelectedKeys, confirm, clearFilters, visible }: any) => (
				<Row className="cmpt-tables-filter" gutter={16}>
					<Col>
						<IdFilter
							confirm={confirm}
							columnKey={c.key}
							resetFields={clearFilters}
							setModel={setSelectedKeys}
							models={c.idFilters}
							open={visible}
						/>
					</Col>
				</Row>
			);
		}
	}
}
