/**
 * Redux State Name
 */
export const ATTENTION_ITEMS = 'ATTENTION_ITEMS';

export enum AttentionItemType {
	/**
	 * BLOCKER: User Logged out of LinkedIn
	 */
	LoggedOutOfLinkedIn = 0,
	/**
	 * BLOCKER: Search list is empty
	 * WARNING: Less than 100 prospects left in the search list for a user in a campaign
	 */
	CampaignSearchListLow,
	/**
	 * BLOCKER: User hasn't sent ANY invites for 2 days and is active with invites
	 * WARNING: User hasn't sent invites today in a campaign
	 */
	NoInvitesSentForProspectingCampaign,
	/**
	 * BLOCKER: User hasn't sent ANY messages for 2 days and is active with messages
	 * WARNING: User hasn't sent messages today in a campaign
	 */
	NoMessagesSentForNurtureCampaign,
	/**
	 * WARNING: User enabled but with no invites
	 */
	NoInvitesSetForProspectingCampaign,
	/**
	 * WARNING: User enabled but with no messages
	 */
	NoMessagesSetForNurtureCampaign,
	/**
	 * WARNING: Using more invites than the recommended limit (25)
	 */
	AllocatedInvitesForCampaignsOverLimit,
}

export enum AttentionItemLevel {
	Warning = 0,
	Blocker,
}
