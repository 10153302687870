import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { ContactManager, CampaignManager, TemplateManager, TagManager } from '@copilot/data';
import { useFetch, usePreviousRef } from '@copilot/common/hooks/common';
import BasicContainer from '@copilot/common/components/containers/basic';
import { ArrowUpOutlined, ArrowDownOutlined, StarOutlined } from '@ant-design/icons';

import { Col, Button, Row, Popover } from 'antd';
import styled from 'styled-components';
import { ContactConnectionSelectors } from '@copilot/common/store/selectors/contactConnection';
import ContactConnectionsTable from '@copilot/common/components/tables/tableTypes/contact/connections';
import { useParams, useLocation } from 'react-router';
import drawerManager from '@copilot/common/utils/drawerManager';

import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { PaginationObject } from '@copilot/data/managers/base';
import { OrganizationType, TagLevel } from '@copilot/common/store/models/const/enum';
import withExtraFilters from '@copilot/common/components/filters/tables';
import ConnectionFilters from './filters';
import { CSVColumnDefinition } from '@copilot/common/components/componentModels/filterTypeDefinition';
import {
	ConnectionsFilterRequestModel,
	ContactConnectionDeleteRequestModel,
	FiltersRequestModel,
} from '@copilot/data/requests/models';
import FilterTemplateForm from '@copilot/common/components/forms/common/generics/filterTemplate';
import FilterTemplateSelector from '@copilot/common/components/selector/filterTemplateSelector';
import { FilterTemplateActions } from '@copilot/common/store/actions/filterTemplate';
import { FilterTemplateSelectors } from '@copilot/common/store/selectors/filterTemplate';
import { deepEqual, replaceNonAlphaNumerics } from '@copilot/common/utils';
import { Ref } from 'redux-orm';
import { ConnectionResponse, ContactTagResponse } from '@copilot/data/responses/interface';
import {
	CampaignFields,
	ContactConnection,
	IContactConnection,
	IContactTag,
} from '@copilot/common/store/models/redux';
import notificationManager from '@copilot/common/utils/notificationManager';
import { ContactConnectionActions } from '@copilot/common/store/actions';
import ConnectionPageBatchAction from './batchAction';
import {
	updateConnectionTagsForMigration,
	getUniqueTags,
	parseConnectionToData,
} from '@copilot/common/utils/batchActions';
import { MigrateApplyTags } from '@copilot/common/components/componentModels/activities/batchActions/type';
import { ConnectionsPageButtonClicks, useConnectionsPageTracking } from './tracking';
import SearchInput, { SearchInputPlaceholder } from '@copilot/common/components/search/input';
import { createContactConnections, ContactConnectionDisplay } from './helper';
import { useCanViewPermission } from '@copilot/common/hooks/permission';
import { PermissionName } from '@copilot/common/hooks/permission/interface';
import { useViewProspectDrawerTracking } from '@copilot/common/components/drawer/wrappers/contact/tracking';
import { loadOrgTagsAction, upsertOrgTagAction } from '../organizationDashboard/tags/data/saga';
import { Tag } from '../organizationDashboard/tags/data/models';
import { ContactTagModel } from './data/types';
import {
	useContactConnectionsTableColumns,
	useExportCsvColumns,
} from '@copilot/common/pages/connections/hook';
import {
	CONNECTIONS_SAVE_TEMPLATE_TRACKING_ID,
	CONNECTIONS_SEARCH_TRACKING_ID,
} from '@copilot/common/tracking/userpilotEventConsts';

const Content = styled(BasicContainer.Content)`
	${(props) => {
		const cardPaddingBase = props.theme['@card-padding-base'];
		return `
			background-color: ${props.theme['@layout-body-background']};
			padding: ${cardPaddingBase}
			`;
	}}
`;

const ChildMargin = styled(Col)`
	> * {
		margin-right: 0.5em;
	}
`;

interface ConnectionsPageRouteParams {
	connectionId: string;
}

interface ConnectionsPageProps {}

const ConnectionsFilter = withExtraFilters(ConnectionFilters);

const DefaultPage = 1;

enum SortByDateKey {
	asc = '-LastThreadActivity',
	desc = 'LastThreadActivity',
}

type SelectAllType = 'All' | 'CurrentPage' | 'None';

/**
 * Update Connections with new properties
 * @param {readonly Ref<ContactConnection>[]} selectedContactConnections selected ContactConnections to update connections from
 * @param {function} reducer called to determine changes and update connections
 */
const getUpdatedConnections = (
	selectedContactConnections: readonly Ref<ContactConnection>[],
	reducer: (connection: ConnectionResponse) => ConnectionResponse
) =>
	selectedContactConnections.map((contactConnection) => ({
		...contactConnection,
		connections: contactConnection.connections.map((connection) => reducer(connection)),
	}));

/**
 * Update connections with new tags
 * @param connectionToTagsMap map to find corresponding tags to connection
 * @param campaign target campaign to migrate to
 * @param campaignMemberId target campaignMemberId to migrate to
 */
const addTagsToConnectionsReducer =
	(
		connectionToTagsMap: Record<string, IContactTag[]>,
		campaign?: CampaignFields,
		campaignMemberId?: string
	) =>
	(connection: ConnectionResponse) => {
		const updatedConnection = { ...connection };
		if (connectionToTagsMap[connection.id]) {
			const consolidatedConnectionTags = getUniqueTags([
				...connection.tags,
				...connectionToTagsMap[connection.id],
			]);
			updatedConnection.tags = consolidatedConnectionTags;
			if (campaign) {
				updatedConnection.campaignId = campaign.id;
				updatedConnection.campaignName = campaign.name;
			}
			if (campaignMemberId) {
				updatedConnection.campaignMemberId = campaignMemberId;
			}
		}
		return updatedConnection;
	};

/**
 * Update connections with new campaign
 * @param campaign target campaign to migrate to
 * @param campaignMemberId target campaignMemberId to migrate to
 * @param validator validator to check if we need to update the connection
 */
const addNewCampaignToConnectionsReducer =
	(campaign: CampaignFields, campaignMemberId: string, validator: Set<string>) =>
	(connection: ConnectionResponse) => {
		const updatedConnection = { ...connection };
		if (validator.has(connection.id)) {
			updatedConnection.campaignId = campaign.id;
			updatedConnection.campaignName = campaign.name;
			updatedConnection.campaignType = campaign.type;
			updatedConnection.campaignMemberId = campaignMemberId;
		}
		return updatedConnection;
	};

/**
 * Create a campaignTag to connectionIds map
 * @param contactConnections list of contactConnections
 * @param validator validator to check if we need to update the connection
 */
const getCampaignTagToConnectionIdsMap = (
	contactConnections: readonly Ref<ContactConnection>[],
	validator: Set<string>
) => {
	const campaignTagToConnectionIdsMap: { [campaignName: string]: string[] } = {};

	contactConnections.forEach((contactConnection) => {
		contactConnection.connections.forEach((connection) => {
			if (validator.has(connection.id)) {
				const campaignTagName = replaceNonAlphaNumerics(
					//Grabbing previous campaign name
					connection.history[connection.history.length - 1].campaignName,
					' '
				);
				campaignTagToConnectionIdsMap[campaignTagName] = campaignTagToConnectionIdsMap[
					campaignTagName
				]
					? [...campaignTagToConnectionIdsMap[campaignTagName], connection.id]
					: [connection.id];
			}
		});
	});
	return campaignTagToConnectionIdsMap;
};

/**
 * Convert contact tags to TagModel objects
 * @param {Partial<ContactTagModel[]>} contactTags
 * @returns
 */
export const convertContactTags = (contactTags: Partial<ContactTagModel>[]) => {
	const tags: Tag[] = contactTags.map((tag: Partial<ContactTagModel>) => ({
		id: tag.tagId ?? '',
		name: tag.name ?? '',
		description: tag.name ?? '',
		level: TagLevel.Organization,
	}));
	return Promise.resolve(tags);
};

//TODO ConnectionsPage and CampaignDashboardSummary have similar codes. Refactor these two files
const ConnectionsPage: React.FC<ConnectionsPageProps> = () => {
	const params = useParams<ConnectionsPageRouteParams>();
	const location = useLocation();
	const { connectionId = '' } = params;
	const searchParams = new URLSearchParams(decodeURIComponent(location.search));
	const updateTrackingParams = useConnectionsPageTracking('Connections Page', null);
	const updateViewDrawerTracking = useViewProspectDrawerTracking('Connections Page', null);

	const contactConnectionsTableColumns = useContactConnectionsTableColumns();
	const exportCsvColumns = useExportCsvColumns();

	const qOrgMemberId = searchParams.get('qOrgMemberId');
	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	const isAdmin = activeAdmin != null && activeAdmin != undefined;
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const isTeamUser = useMemo(
		() => activeOrganization?.orgType === OrganizationType.Enterprise,
		[activeOrganization?.orgType]
	);

	const [currentPage, setCurrentPage] = useState<number | undefined>(DefaultPage);
	const memberId = activeMember?.id ?? '';
	const organizationId = activeMember?.organizationId ?? '';
	const csOrgId = isAdmin ? organizationId : null;
	const isTeamMembersVisible = useCanViewPermission(PermissionName.TeamMembers);
	const [searchName, setSearchName] = useState<string>();
	const [sortBy, setSortBy] = useState<string>(SortByDateKey.asc);
	const [connectionFilterRequest, setConnectionFilterRequest] = useState<
		Partial<ConnectionsFilterRequestModel>
	>({});

	const [selectedRows, setSelectedRows] = useState<ContactConnectionDisplay[]>([]);
	const [selectRowType, setSelectRowType] = useState<SelectAllType>('None');
	const isSelectAllFilteredData = useMemo(() => selectRowType === 'All', [selectRowType]);
	const hideMigrateBatchAction = useMemo(
		() => isSelectAllFilteredData && isTeamUser,
		[isSelectAllFilteredData, isTeamUser]
	);

	const [filterRequest, setFilterRequest] = useState<Partial<FiltersRequestModel>>({});
	const prevFilterRequest = usePreviousRef(filterRequest);
	const batchActionFilters = useMemo(
		() => (isSelectAllFilteredData ? filterRequest : undefined),
		[isSelectAllFilteredData]
	);

	const [isVisibleFilterTemplate, setIsVisibleFilterTemplate] = useState<boolean>(false);
	const [csvFetch, fetchCSV] = useFetch(ContactManager.downloadConnectionsCSV);

	const [drawerLoaded, setLoadDrawer] = useState<boolean>(false);
	const storeDispatch = useDispatch();
	const [multiConnectionsFetch, fetchMultiConnections] = useFetch(
		ContactManager.getMultiConnections,
		ContactConnectionActions.load,
		(r) => r.results
	);
	const contactConnections = useSelector(ContactConnectionSelectors.getContactConnections);

	const [, fetchFilterTemplates] = useFetch(
		TemplateManager.getTemplateFilterByOrgMemberId,
		FilterTemplateActions.loadTemplates
	);

	useEffect(() => {
		if (!memberId) return;
		fetchFilterTemplates(memberId);
	}, [memberId]);

	useEffect(() => {
		// Load all tags for the organization
		if (organizationId) {
			storeDispatch(loadOrgTagsAction(TagManager.getTags, organizationId));
		}
	}, [organizationId]);

	const [filterTagNames, setFilterTagNames] = useState<string[]>([]);
	const contactConnectionTotal = multiConnectionsFetch.data
		? multiConnectionsFetch.data.totalCount
		: 0;

	const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');

	const selectedTemplate = useSelector(
		FilterTemplateSelectors.getFilterTemplate(selectedTemplateId)
	);
	const allFilterTemplates = useSelector(FilterTemplateSelectors.getFilterTemplates);

	/**
	 * The contact connections to be displayed in the ContactConnectionsTable.
	 */
	const displayedContactConnections = useMemo(
		() => createContactConnections(contactConnections, filterTagNames),
		[contactConnections, filterTagNames]
	);

	useEffect(() => {
		if (!memberId) return;
		const orgMemberIds = [];
		if (
			activeOrganization?.orgType == OrganizationType.Enterprise &&
			!activeMember?.isOrgAdmin
		) {
			orgMemberIds.push(memberId);
		}
		const query = new PaginationObject();
		const filterRequestModel: Partial<FiltersRequestModel> = {};
		if (sortBy) filterRequestModel.SortBy = sortBy;
		if (searchName) filterRequestModel.SearchTerm = searchName;
		filterRequestModel.FilterBy = connectionFilterRequest;
		if (connectionFilterRequest.TagName) {
			setFilterTagNames(connectionFilterRequest.TagName.include);
		}
		if (prevFilterRequest && !deepEqual(filterRequestModel, prevFilterRequest)) {
			setFilterRequest(filterRequestModel);
			//reseting the store to display names shown in the table in the BatchTag drawer when tagging everyone from the filtered list
			//Without resetting, drawer shows everyone that has been loaded
			storeDispatch(ContactConnectionActions.resetAll());
			setSelectedRows([]);
			fetchMultiConnections(organizationId, query, filterRequestModel, isAdmin);
			setCurrentPage(DefaultPage);
		}
	}, [
		memberId,
		organizationId,
		activeMember?.isOrgAdmin,
		sortBy,
		searchName,
		connectionFilterRequest,
		isAdmin,
	]);

	useEffect(() => {
		(async () => {
			if (connectionId != '' && !drawerLoaded) {
				const response = await CampaignManager.getConnectionById(connectionId);
				const mId = qOrgMemberId && qOrgMemberId != '' ? qOrgMemberId : activeMember?.id;
				if (response.contactId && mId) {
					drawerManager.openContactDrawer({
						id: response.contactId,
						memberId: mId,
						orgId: organizationId,
					});
				}
				setLoadDrawer(true);
			}
		})();
	}, [connectionId]);

	const handleTrackingUpdate = (buttonName: ConnectionsPageButtonClicks) => {
		updateTrackingParams({ buttonClicked: buttonName });
	};

	/**
	 * Details on childrenColumnName:
	 * Connections column for expanded rows for multi-connections per contact. When there is only one connection,
	 * connections from data need to be undefined to prevent having duplicate rows.
	 * To enable this, Table props type has been updated from IContactConnection to ContactConnectionDisplay
	 * More details and Modification can be found in @copilot/common/components/tables/tableTypes/contact/connections
	 * @returns {string} childrenColumnName for multi-connections or empty string
	 */
	const getChildrenColumnName = () => (activeMember?.isOrgAdmin ? 'connections' : '');

	/**
	 * Downloads and fetches the contact CSV for selected columns and contact connections.
	 * @param columns selected columns
	 * @param selectedConnectionIds selected contact connection ids
	 */
	const getContactCSV = useCallback(
		(columns: CSVColumnDefinition[], selectedConnectionIds: string[] | undefined) => {
			if (selectedConnectionIds?.length === 0) {
				selectedConnectionIds = undefined;
			}
			const enabledColumns: string[] = columns.filter((c) => c.isVisible).map((c) => c.key);
			fetchCSV(
				csOrgId,
				enabledColumns,
				filterRequest,
				isSelectAllFilteredData ? undefined : selectedConnectionIds
			);
			handleTrackingUpdate('Export CSV');
		},
		[activeAdmin, csOrgId, filterRequest, isSelectAllFilteredData]
	);

	const handleSorter = useCallback(() => {
		if (sortBy === SortByDateKey.asc) {
			setSortBy(SortByDateKey.desc);
		} else setSortBy(SortByDateKey.asc);
	}, [sortBy]);

	const sorter = useMemo(
		() => (
			<Button type="primary" ghost onClick={handleSorter}>
				Last Updated
				{sortBy == SortByDateKey.asc ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
			</Button>
		),
		[sortBy, handleSorter]
	);

	const filterTemplateForm = (
		<FilterTemplateForm
			ownerId={memberId}
			filterRequest={connectionFilterRequest}
			setIsVisible={setIsVisibleFilterTemplate}
			onTrackingUpdate={handleTrackingUpdate}
		/>
	);

	const handleRowChange = useCallback((rowKeys, rows: ContactConnectionDisplay[]) => {
		setSelectedRows(rows);
	}, []);

	const rowSelectionOptions = useMemo(
		() => [
			{
				key: 'All',
				text: 'Select All Pages',
				onSelect: () => {
					setSelectRowType('All');
					setSelectedRows(displayedContactConnections);
				},
			},
			{
				key: 'CurrentPage',
				text: 'Select Visible Page',
				onSelect: () => {
					setSelectRowType('CurrentPage');
					setSelectedRows(displayedContactConnections);
				},
			},
		],
		[displayedContactConnections]
	);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys: selectedRows.map((r) => r.id),
			onChange: handleRowChange,
			onSelect: () => setSelectRowType('None'),
			selections: rowSelectionOptions,
			columnWidth: '80px',
		}),
		[selectedRows, handleRowChange, rowSelectionOptions]
	);

	const resetSelectedRows = () => {
		setSelectedRows([]);
	};

	const pagination = useMemo(
		() => ({
			total: contactConnectionTotal,
			current: currentPage,
			showSizeChanger: true,
		}),
		[contactConnectionTotal, currentPage]
	);

	const handleContactConnectionsTableChange = useCallback<
		NonNullable<React.ComponentProps<typeof ContactConnectionsTable>['onChange']>
	>(
		(page) => {
			if (!memberId) return;
			const query = new PaginationObject({
				page: (page.current ?? 1) - 1,
				pageSize: page.pageSize,
			});
			setCurrentPage(page.current);
			storeDispatch(ContactConnectionActions.resetAll());
			resetSelectedRows();
			fetchMultiConnections(organizationId, query, filterRequest, isAdmin);
		},
		[memberId, filterRequest, !activeMember?.isOrgAdmin, isAdmin]
	);
	//#region ContactConnectionsTable BatchActions Callbacks

	/**
	 * Called to update store on apply batch tag
	 * @param tags new tags
	 */
	const handleApplyBatchTagUpdate = useCallback(
		(tags: IContactTag[]) => {
			const connectionTagsMap = parseConnectionToData(tags);
			const updatedContactConnections = getUpdatedConnections(
				contactConnections,
				addTagsToConnectionsReducer(connectionTagsMap)
			);
			storeDispatch(ContactConnectionActions.updateMany(updatedContactConnections));
			storeDispatch(upsertOrgTagAction(convertContactTags, tags));
		},
		[contactConnections]
	);

	const applybatchTag = useCallback(
		async (
			connectionIds: string[],
			tags: string[],
			// eslint-disable-next-line @typescript-eslint/no-inferrable-types
			overwriteTags: boolean = false,
			filter?: Partial<FiltersRequestModel>
		) => {
			const response = await ContactManager.updateConnectionTags(
				isSelectAllFilteredData ? [] : connectionIds,
				tags,
				csOrgId,
				overwriteTags,
				filter
			);
			handleApplyBatchTagUpdate(response);
			resetSelectedRows();
		},
		[handleApplyBatchTagUpdate, isSelectAllFilteredData, csOrgId]
	);

	/**
	 * Called to update store on migrate connections
	 * @param migrationStatus status after the migration
	 * @param campaign target campaign
	 * @param campaignMemberId target campaign memberId
	 */
	const handleMigrateConnectionUpdate = useCallback(
		(ids: string[], campaign: CampaignFields, campaignMemberId: string) => {
			const successConnectionsSet = new Set(ids);
			const updatedContactConnections: IContactConnection[] = getUpdatedConnections(
				contactConnections,
				addNewCampaignToConnectionsReducer(
					campaign,
					campaignMemberId,
					successConnectionsSet
				)
			);
			storeDispatch(ContactConnectionActions.updateMany(updatedContactConnections));
		},
		[contactConnections]
	);

	const migrateConnections = async (
		campaign: CampaignFields,
		campaignMemberId: string,
		connectionIds: string[],
		omid?: string
	) => {
		const result = await CampaignManager.migrateConnections(
			campaign.id,
			campaignMemberId,
			connectionIds,
			batchActionFilters,
			undefined,
			omid
		);
		const migratedIds = result.migratableConnections.map((c) => c.id);
		handleMigrateConnectionUpdate(migratedIds, campaign, campaignMemberId);
		resetSelectedRows();
		return result;
	};

	/**
	 * Create a mapping between campaign name and connections that used to belong to the campaign
	 * @param {Set<string>} connectionIdSet a set of unique connectionIds
	 */

	const createPrevCampaignToConnectionsMap = useCallback(
		(connectionIdSet: Set<string>) =>
			getCampaignTagToConnectionIdsMap(contactConnections, connectionIdSet),
		[contactConnections]
	);

	/**
	 * Update ContactConnection with new tags and campaign
	 * @param tagList new tags to update the connection to
	 * @param campaign new campaign to update the connection to
	 * @param campaignMemberId new campaignMemberId to update the connection to
	 */
	const handleTagMigratedConnectionUpdate = useCallback(
		(tagList: ContactTagResponse[], campaign: CampaignFields, campaignMemberId: string) => {
			const connectionToTagsMap = parseConnectionToData(tagList);
			const updatedContactConnections: IContactConnection[] = getUpdatedConnections(
				contactConnections,
				addTagsToConnectionsReducer(connectionToTagsMap, campaign, campaignMemberId)
			);
			return updatedContactConnections;
		},
		[contactConnections, getUpdatedConnections]
	);

	const tagMigratedConnection: MigrateApplyTags = useCallback(
		async (
			createCampaignTag: boolean,
			selectedTags: string[],
			connectionIds: string[],
			campaign: CampaignFields,
			campaignMemberId: string
		) => {
			const connectionIdsSet = new Set(connectionIds);
			const campaignToConnectionsMap = createCampaignTag
				? createPrevCampaignToConnectionsMap(connectionIdsSet)
				: null;
			const createTags = updateConnectionTagsForMigration(
				connectionIds,
				selectedTags,
				campaignToConnectionsMap,
				csOrgId
			);

			const results = await createTags;
			const updatedContactConnections = handleTagMigratedConnectionUpdate(
				results,
				campaign,
				campaignMemberId
			);
			storeDispatch(ContactConnectionActions.updateMany(updatedContactConnections));
			storeDispatch(upsertOrgTagAction(convertContactTags, results));
		},
		[handleTagMigratedConnectionUpdate, createPrevCampaignToConnectionsMap, csOrgId]
	);

	const removeContacts = useCallback(
		async (contactConnectionDeleteModels: ContactConnectionDeleteRequestModel[]) => {
			if (!organizationId) return;
			try {
				await ContactManager.deleteContacts(contactConnectionDeleteModels, csOrgId);
				notificationManager.showSuccessNotification({
					message: 'Prospects Removed',
				});
				resetSelectedRows();
				storeDispatch(
					ContactConnectionActions.deleteMany(
						contactConnectionDeleteModels.map((c) => ({ id: c.contactId }))
					)
				);
			} catch {
				notificationManager.showErrorNotification({
					message: 'Removing Prospects has failed',
					description: 'Please try again.',
				});
			}
		},
		[csOrgId, organizationId]
	);

	//#endregion ContactConnectionsTable BatchActions Callbacks

	const connectionIds = useMemo(
		() => selectedRows.map((connection) => connection.connectionId),
		[selectedRows]
	);

	const connections = useSelector(ContactConnectionSelectors.getConnectionsById(connectionIds));

	const handleApplyTag = () => {
		const selectedConnections = selectedRows.map((row) => ({
			id: row.connectionId,
			name: row.name,
		}));
		drawerManager.openTagsApplyDrawer({
			connections: selectedConnections,
			onApplyTag: applybatchTag,
			filterRequest: batchActionFilters,
			totalCount: isSelectAllFilteredData
				? contactConnectionTotal
				: selectedConnections.length,
		});
	};

	const handleDeleteContact = () => {
		const contactConnectionDeleteRequest = selectedRows.reduce<
			ContactConnectionDeleteRequestModel[]
		>((arr, row) => {
			if (!row.connectionId || !row.contactId) return arr;
			return [...arr, { connectionId: row.connectionId, contactId: row.contactId }];
		}, []);

		removeContacts(contactConnectionDeleteRequest);
	};

	const onCellClick = useCallback(
		(connection: ContactConnectionDisplay) => {
			drawerManager.openContactDrawer({
				id: connection.contactId,
				memberId: connection.orgMemberId,
			});
			updateViewDrawerTracking({ buttonClicked: 'Connections Table Row' });
		},
		[updateViewDrawerTracking]
	);

	return (
		<BasicContainer>
			<BasicContainer.Header>
				<h2>Connections</h2>
				<Row justify="space-between">
					<SearchInput
						placeholder={SearchInputPlaceholder.Name}
						onSearch={setSearchName}
						data-tracking-id={CONNECTIONS_SEARCH_TRACKING_ID}
					/>
					<ChildMargin>
						{sorter}
						<FilterTemplateSelector
							filterTemplates={allFilterTemplates}
							applyTemplate={setSelectedTemplateId}
							onTrackingUpdate={handleTrackingUpdate}
						/>
					</ChildMargin>
				</Row>
			</BasicContainer.Header>
			<BasicContainer.Content style={{ padding: '0px' }}>
				<div
					style={{
						margin: '20px',
						alignItems: 'baseline',
					}}
				>
					<ConnectionsFilter
						setConnectionsFilter={setConnectionFilterRequest}
						setSelectedTemplate={setSelectedTemplateId}
						selectedFilterTemplate={selectedTemplate}
						isHideTeamMembers={!isTeamMembersVisible}
					/>
					<Popover
						content={filterTemplateForm}
						trigger="click"
						placement="bottomLeft"
						visible={isVisibleFilterTemplate}
						// necessary to allow close on mouse click outside
						onVisibleChange={setIsVisibleFilterTemplate}
					>
						<Button
							style={{ marginLeft: '7px' }}
							onClick={() => setIsVisibleFilterTemplate(true)}
							icon={<StarOutlined />}
							data-tracking-id={CONNECTIONS_SAVE_TEMPLATE_TRACKING_ID}
						>
							Save Filter Template
						</Button>
					</Popover>
				</div>

				<Content>
					<ContactConnectionsTable
						dataSource={displayedContactConnections}
						pagination={pagination}
						loading={multiConnectionsFetch.isFetching}
						onChange={handleContactConnectionsTableChange}
						childrenColumnName={getChildrenColumnName()}
						exportableColumns={exportCsvColumns}
						exportData={getContactCSV}
						isExporting={csvFetch.isFetching}
						columns={contactConnectionsTableColumns}
						rowSelection={rowSelection}
						onCellClick={onCellClick}
					/>
					{selectedRows.length > 0 && (
						<ConnectionPageBatchAction
							connections={connections}
							total={
								isSelectAllFilteredData
									? contactConnectionTotal
									: connections.length
							}
							hideRemove={isSelectAllFilteredData}
							hideMigrate={hideMigrateBatchAction}
							onApplyTag={handleApplyTag}
							onExportCSV={getContactCSV}
							onRemove={handleDeleteContact}
							onMigrate={migrateConnections}
							onMigrateTag={tagMigratedConnection}
						/>
					)}
				</Content>
			</BasicContainer.Content>
		</BasicContainer>
	);
};
export default ConnectionsPage;
