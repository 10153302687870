import { Config } from '@copilot/common/config';
import InboxEmailView from './page';
import withCleverlyFilter from './containers/withCleverlyFilter';
import withDefaultFilter from './containers/withDefaultFilter';
import { AgencyCode } from '@copilot/common/config/constant';

const getEmailPageByAgencyCode = (code: string | undefined) => {
	switch (code) {
		case AgencyCode.cleverly:
			return withCleverlyFilter(InboxEmailView);
		default:
			return withDefaultFilter(InboxEmailView);
	}
};

export const InboxEmailPage = getEmailPageByAgencyCode(Config.agencyCode);
