import BaseDataManager from './base';
import {
	OrganizationResponse,
	OrganizationMemberResponse,
	ServiceSchedule,
	RegisterOrganizationMembersResponse,
} from '../responses/interface';
import { IAddOrgMemberRequest } from '../requests/models';

class OrganizationManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get an organization with its id
	 * @param id The id of the organization we want to retrieve
	 */
	public getOrganization = (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ORGANIZATION.Default, id);
		return this.preventConcurrency(url, () =>
			this.RequestManager.get<OrganizationResponse>(url).then((response) => {
				const organization = response.data;
				return organization;
			})
		);
	};

	/**
	 * Update an organization
	 * @param id The id of the organization
	 * @param updates The updates we want to post
	 */
	public updateOrganization = (id: string, updates: Partial<OrganizationResponse>) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ORGANIZATION.Default, id);
		return this.RequestManager.put<OrganizationResponse>(url, updates).then(
			(response) => response.data
		);
	};

	/**
	 * Rename an organization
	 * @param {string} id The id of the organization
	 * @param {name} name The name we want to update to
	 */
	public renameOrganization = (id: string, name: string) => {
		const payload = { name };
		return this.updateOrganization(id, payload);
	};

	/**
	 * Upload a logo for an organization
	 * @param {string} id The id of the organization
	 * @param logo The image file of we want to upload
	 * @returns {string} Url of the uploaded logo
	 */
	public uploadLogo = (
		id: string,
		logo: string | Blob,
		config: Parameters<typeof OrganizationManager.RequestManager['put']>[2]
	) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ORGANIZATION.Default, id, 'logo');
		const formData = new FormData();
		formData.append('file', logo);
		return this.RequestManager.put<string>(url, formData, config).then((r) => r.data);
	};

	/**
	 * Remove a logo from an organization
	 * @param {string} id The id of the organization
	 */
	public removeLogo = (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ORGANIZATION.Default, id, 'logo');
		return this.RequestManager.delete(url);
	};

	/**
	 * Gets active members of an organization
	 * @param {string} id Id of the organization we want members for
	 * @returns {Promise<OrganizationMemberResponse[]} A promise resolving the
	 *                                                 members of an organization
	 */
	public getMembers = (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ORGANIZATION.Default, id, 'members');
		return this.RequestManager.get<OrganizationMemberResponse[]>(url).then((r) => r.data);
	};

	/**
	 * Get all members of an organization
	 * @param {string} orgId id of the organization we want to count members for
	 * @returns {Promise<number>} the number of enabled users in the org
	 */
	public getNumberOfEnabledUsers = (orgId: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION.Default,
			orgId,
			'members',
			'activeCount'
		);
		return this.RequestManager.get<number>(url).then((r) => r.data);
	};

	/**
	 * delete organization
	 * @param {string} id Id of the organization we want to delete
	 * @returns
	 */
	public deleteOrganization = (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ORGANIZATION.Default, id);
		return this.preventConcurrency(url, () =>
			this.RequestManager.delete(url)
				.then(() => true)
				.catch((err) => {
					throw err;
				})
		);
	};

	/**
	 * Add users to an organization
	 * @param {string} id The id of the organization
	 * @param {Partial<IAddOrgMemberRequest>[]} The members we want to add
	 */
	public addMembers = (id: string, members: Partial<IAddOrgMemberRequest>[]) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION.Default,
			id,
			'members',
			'register'
		);
		const users = members.map((m) => ({
			firstName: m.firstname,
			lastName: m.lastname,
			email: m.email,
			orgRoles: m.orgRoles ? m.orgRoles : [],
			isIndividualUser: true,
			blockMultipleOrgs: m.blockMultipleOrgs,
		}));
		return this.RequestManager.post<RegisterOrganizationMembersResponse>(url, users).then(
			(r) => r.data
		);
	};

	/**
	 * Create a service schedule for the organization
	 * @param {string} id The id of the organization
	 * @param {ServiceSchedule} schedule the new organization schedule
	 */
	public createSchedule = async (id: string, schedule: Omit<ServiceSchedule, 'id'>) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION.Default,
			id,
			'serviceschedule'
		);

		const response = await this.RequestManager.post<ServiceSchedule>(url, schedule);
		if (response) {
			return response.data;
		} else {
			return Promise.reject();
		}
	};

	/**
	 * Get the schedule for the given organization
	 * @param {string} id The id of the organization
	 */
	public getSchedule = async (id: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION.Default,
			id,
			'serviceschedule'
		);
		const response = await this.RequestManager.get<ServiceSchedule>(url);
		if (response) {
			return response.data;
		} else {
			return Promise.reject();
		}
	};

	/**
	 * Delete Organization Schedule for the given organization
	 * @param {string} id The id of the organization
	 */
	public deleteSchedule = async (id: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION.Default,
			id,
			'serviceschedule'
		);
		const response = await this.RequestManager.delete(url);
		return response.data;
	};
}

const instance = new OrganizationManager();
export default instance;
