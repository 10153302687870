import React from 'react';
import DrawerManager, { DrawerTypes } from '../../utils/drawerManager';
import ContactDrawer from './wrappers/contact/drawer';
// import SearchCriteriaDrawer from './wrappers/SearchCriteria';
import MemberAdditionDrawer from './wrappers/memberAddition';
import CampaignTeamDrawer from './wrappers/campaignTeam';
import CampaignMemberDrawer from './wrappers/campaignMember';
import SearchCriteriaDrawer from './wrappers/searchCriteria';
import TriggerEditDrawer from './wrappers/campaignSequence/triggerEdit';
import MessagesEditDrawer from './wrappers/campaignSequence/messagesEdit';
import TagsEditDrawer from './wrappers/campaignSequence/tagsEdit';
import NextNodesEditDrawer from './wrappers/campaignSequence/nextNodesEdit';
import CreateNodeDrawer from './wrappers/campaignSequence/createNode';
// import InboxMessageDrawer from './wrappers/InboxMessage';
import { useSelector } from 'react-redux';
import { DrawerSelectors } from '@copilot/common/store/selectors/drawer';
import WizardMessageEditDrawer from './wrappers/campaignSequence/wizardEdit/message';
import NotificationDrawer from './wrappers/notification';
import TagsApplyDrawer from './wrappers/batchTag';
import TagsApplyDrawerSaga from './wrappers/batchTag/applyTagsWithSaga';
import UsageLimitsDrawer from './wrappers/usageLimits';
import NewUserMessageWithTemplatesEditDrawer from './wrappers/campaignSequence/wizardEdit/newUserMessageWithTemplate';
import NewUserMessageBasicEditDrawer from './wrappers/campaignSequence/wizardEdit/newUserBasicMessage';
import CouponDrawer from '@copilot/cs/src/components/changeSubscription/coupon/couponDrawer';
import AdminMemberDrawer from './wrappers/adminMember/adminMemberDrawer';
import { TipsDrawer } from './tips';
import AccountCreationDrawer from './wrappers/accountDashboard/accountCreation/accountCreationDrawer';
import UserEditDrawer from './wrappers/accountDashboard/userEdit/userEditDrawer';
import AccountEditDrawer from '@copilot/common/components/drawer/wrappers/accountDashboard/accountEdit/acountEditDrawer';

interface DrawerContainerManagerProps {}

/**
 * Components Lookup Table
 */
const drawerComponentLookupTable: { [key in DrawerTypes]: any } = {
	[DrawerTypes.AccountCreation]: AccountCreationDrawer,
	[DrawerTypes.AccountEditDrawer]: AccountEditDrawer,
	[DrawerTypes.UserEditDrawer]: UserEditDrawer,
	[DrawerTypes.ContactDrawer]: ContactDrawer,
	[DrawerTypes.SearchCriteriaDrawer]: SearchCriteriaDrawer,
	[DrawerTypes.MemberAdditionDrawer]: MemberAdditionDrawer,
	[DrawerTypes.CampaignMemberDrawer]: CampaignMemberDrawer,
	[DrawerTypes.CampaignTeamDrawer]: CampaignTeamDrawer,
	[DrawerTypes.TriggerEditDrawer]: TriggerEditDrawer,
	[DrawerTypes.TagsEditDrawer]: TagsEditDrawer,
	[DrawerTypes.MessagesEditDrawer]: MessagesEditDrawer,
	[DrawerTypes.NewUserMessageWithTemplatesEditDrawer]: NewUserMessageWithTemplatesEditDrawer,
	[DrawerTypes.NewUserMessageBasicEditDrawer]: NewUserMessageBasicEditDrawer,
	[DrawerTypes.NextNodesEditDrawer]: NextNodesEditDrawer,
	[DrawerTypes.CreateNodeDrawer]: CreateNodeDrawer,
	[DrawerTypes.AdminMemberDrawer]: AdminMemberDrawer,
	// [DrawerTypes.InboxMessageDrawer]: InboxMessageDrawer,
	[DrawerTypes.WizardMessageEditDrawer]: WizardMessageEditDrawer,
	[DrawerTypes.NotificationDrawer]: NotificationDrawer,
	[DrawerTypes.TagsApplyDrawer]: TagsApplyDrawer,
	[DrawerTypes.TagsApplyDrawerSaga]: TagsApplyDrawerSaga,
	[DrawerTypes.UsageLimitsDrawer]: UsageLimitsDrawer,
	[DrawerTypes.CouponDrawer]: CouponDrawer,
	[DrawerTypes.TipsDrawer]: TipsDrawer,
};

const DrawerContainerManager: React.FC<DrawerContainerManagerProps> = () => {
	const currentDrawer = useSelector(DrawerSelectors.getCurrentDrawer);

	const onClose = React.useCallback((drawerContext: any) => {
		if (drawerContext?.props?.closeAlert) {
			const confirmClose = confirm('Leaving will discard all changes, are you sure?');
			if (confirmClose) DrawerManager.closeDrawer();
		} else {
			DrawerManager.closeDrawer();
		}
	}, []);

	let renderedDrawer = <></>;
	if (currentDrawer) {
		const { type, props: drawerProps = {} } = currentDrawer;
		if (type) {
			const DrawerComponent = drawerComponentLookupTable[type];
			renderedDrawer = (
				<DrawerComponent
					onClose={() => {
						onClose(currentDrawer);
					}}
					{...drawerProps}
				/>
			);
		}
	}
	return <span>{renderedDrawer}</span>;
};

export default DrawerContainerManager;
