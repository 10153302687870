import { PaginatedResponse } from '../interface';

export class PaginatedResultModel<T, C> implements PaginatedResponse<C> {
	public numPages: number;
	public pageIndex: number;
	public pageSize: number;
	public results: C[];
	public startingIndex: number;
	public totalCount: number;

	constructor(result: PaginatedResponse<T> | null, ClassModel: { new (...args: any[]): C }) {
		this.numPages = result ? result.numPages : 0;
		this.pageIndex = result ? result.pageIndex : 0;
		this.pageSize = result ? result.pageSize : 0;
		this.results = result?.results ? result.results.map((r) => new ClassModel(r)) : [];
		this.startingIndex = result ? result.startingIndex : 0;
		this.totalCount = result ? result.totalCount : 0;
	}
}
