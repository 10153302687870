import { FC } from 'react';
import { InboxMessagesPageProps } from '@copilot/common/pages/inbox/ui/types';
import BasicContainer from '@copilot/common/components/containers/basic';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';

const Content = styled(BasicContainer.Content)`
	background-color: ${(props) => props.theme['@layout-body-background']};
`;

/**
 * Presentation Component for Inbox Email page
 * @param props
 */
const InboxLoadingPage: FC<InboxMessagesPageProps> = () => (
	<>
		<BasicContainer>
			<BasicContainer.Header>
				<Row justify="space-between" align="middle">
					<Col span={1}>
						<h2>Inbox</h2>
						<br />
					</Col>
				</Row>
			</BasicContainer.Header>
			<Content>
				<Row justify="center">
					<Col>
						<LoadingIndicator isLoading />
					</Col>
				</Row>
			</Content>
		</BasicContainer>
	</>
);
export default InboxLoadingPage;
