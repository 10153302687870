import * as React from 'react';
import { TagProps } from 'antd/lib/tag';
import TagList from './list';

export interface TagsProps extends TagProps {
	tags: string[];
	allowAdditions?: boolean;
}

class Tags extends React.Component<TagsProps> {
	constructor(props: TagsProps) {
		super(props);
		this.handleTagClose = this.handleTagClose.bind(this);
	}

	handleTagClose(tag: string) {
		// Remove tag from contact
		console.log(tag);
	}

	render() {
		const { tags, allowAdditions: _, ...rest } = this.props;
		return (
			<div className="cmpt-tags">
				<TagList tags={tags} handleTagClose={this.handleTagClose} {...rest} />
				{this.props.children}
			</div>
		);
	}
}

export default Tags;
