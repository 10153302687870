import { InboxMessagesPageProps } from '@copilot/common/pages/inbox/ui/types';
import {
	MessageTemplateType,
	TemplateAndAutomationProps,
} from '@copilot/common/pages/inbox/ui/card/types';
import withReminderTabAndFilters from '@copilot/common/pages/inbox/ui/card/reminder/containers/withReminderTabAndFilters';
import withMessageTemplates from '@copilot/common/pages/settings/template/ui/container';
import { ComponentType } from 'react';
import InboxCardView from '@copilot/common/pages/inbox/ui/card/component';
import { useRedirectToTemplate } from '@copilot/common/hooks/common';
import { CampaignDashboardTabKeys } from '@copilot/common/utils/campaign/dashboardTabs';

type WithSetupMessageTemplateCapabilityProps = {
	messageTemplates: MessageTemplateType[];
} & InboxMessagesPageProps;

/**
 * [Smart] Inject callback to handle Set up Quick Response
 * @param Component
 */
function withSetupMessageTemplateCapability<T>(
	Component: ComponentType<T & TemplateAndAutomationProps>
) {
	return function messageTemplateWrapper(props: T & InboxMessagesPageProps) {
		const { showSetUpAutomation, isImpersonating } = props.config;
		return (
			<Component
				onSetupTemplate={useRedirectToTemplate(CampaignDashboardTabKeys.Template)}
				onSetupAutomation={
					isImpersonating
						? undefined
						: useRedirectToTemplate(CampaignDashboardTabKeys.Sequence)
				}
				showSetUpAutomation={showSetUpAutomation}
				{...props}
			/>
		);
	};
}

type InboxWithReminderTabProps = WithSetupMessageTemplateCapabilityProps &
	InboxMessagesPageProps &
	TemplateAndAutomationProps;

/**
 * Inbox Card View with Reminder Tab
 */
export const InboxCardViewByReminderPage = withMessageTemplates<InboxMessagesPageProps>(
	withSetupMessageTemplateCapability<
		WithSetupMessageTemplateCapabilityProps & InboxMessagesPageProps
	>(withReminderTabAndFilters<InboxWithReminderTabProps>(InboxCardView))
);
