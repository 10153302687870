import { green, red, orange } from '@ant-design/colors';
import {
	LAST_14_DAYS_OPTION,
	LAST_30_DAYS_OPTION,
	LAST_7_DAYS_OPTION,
} from '@copilot/common/components/dataInput/constants';
import { CUSTOM_OPTION } from '@copilot/common/components/dataInput/types';

/**
 * Colors used for conversion rate highlighting in the campaign funnel
 */
export const ABOVE_AVERAGE_COLOR = green[1];
export const BELOW_AVERAGE_COLOR = red[1];
export const AVERAGE_COLOR = orange[1];

/**
 * Dropdown options for date range for campaign funnel
 */
export const DATE_RANGE_OPTIONS = [
	LAST_7_DAYS_OPTION,
	LAST_14_DAYS_OPTION,
	LAST_30_DAYS_OPTION,
	CUSTOM_OPTION,
];

/**
 * Default date range option for campaign funnel
 */
export const DEFAULT_DATE_RANGE_OPTION = LAST_30_DAYS_OPTION;

/**
 * Connection rate threshold for performance highlighting in the campaign funnel
 */
export const CONNECTION_RATE_BELOW_AVERAGE_THRESHOLD = 20;

/**
 * Reply rate threshold for performance highlighting in the campaign funnel
 */
export const REPLY_RATE_BELOW_AVERAGE_THRESHOLD = 15;
