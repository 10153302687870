import React from 'react';
import { Row, Col, Button, notification, Spin } from 'antd';
import StepContainer from '@copilot/common/components/containers/step';
import CampaignOnboardWizardWelcome from './wizard/welcome';
import CampaignOnboardWizardSearch from './wizard/search';
import CampaignOnboardWizardFooter from './footer';
import CampaignOnboardComplete from './wizard/complete';
import {
	ILocation,
	LocationType,
} from '@copilot/common/components/linkedin/searchCriteria/location';
import notificationManager from '@copilot/common/utils/notificationManager';
import { DaySchedule, SearchCriteria, LinkedInSearchType } from '@copilot/data/responses/interface';
import Schedule from '@copilot/common/components/editors/schedule';
import BasicContainer from '@copilot/common/components/containers/basic';
import styled from 'styled-components';
import CampaignOnboardWizardMessaging from './wizard/message';
import { OrganizationMemberManager, CampaignManager } from '@copilot/data';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useSelector } from 'react-redux';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import modalManager from '@copilot/common/utils/modalManager';
import CampaignOnboardWizardBooking from './wizard/booking';
import { AppSelectors } from '@copilot/common/store/selectors';
import { AppActions } from '@copilot/common/store/actions/app';
import { useFetch } from '@copilot/common/hooks/common';
import { SYSTEM_DEFAULT_SCHEDULE, ScheduleHierarchyType } from '../../settings/schedule/constant';
import HeaderContentLayout from '../../layouts/headerContent';
import { CampaignOnboardDetailsActions } from '@copilot/common/store/actions/campaignOnboard';
import { CampaignOnboardSelectors } from '@copilot/common/store/selectors/campaignOnboard';
import { useSchedule } from '@copilot/common/hooks/serviceSchedule';
import { ScheduleUtility } from '@copilot/common/utils/schedule';
import { CampaignOnboardWizardSteps, ERROR_NOTIFICATION_KEY } from './constant';
import { OnboardMessage } from '@copilot/data/requests/models';
import { isSearchUrlValid } from '@copilot/common/utils';
import { useProspectCampaignOnboardTracking } from '../../onboard/wizard/tracking';
import { DEFAULT_SECOND_MESSAGE_TIMING } from '../../wizard/const';

const StyledCol = styled(Col)`
	h1 {
		font-size: 2em;
	}
`;

const Steps: React.ComponentProps<typeof StepContainer>['steps'] = [
	{
		key: CampaignOnboardWizardSteps.Welcome,
		label: 'Get started',
		description: <p>How would you like to set things up?</p>,
		status: 'finish',
	},
	{
		key: CampaignOnboardWizardSteps.Search,
		label: 'Select your search',
		description: <p>For targeting different location and people</p>,
		status: 'wait',
	},
	{
		key: CampaignOnboardWizardSteps.Messaging,
		label: 'Confirm messaging',
		description: <p>For testing out messaging or having a more specific messaging</p>,
		status: 'wait',
	},
	{
		key: CampaignOnboardWizardSteps.Timezone,
		label: 'Timezone',
		description: <p>For picking the time your campaign sends out at</p>,
		status: 'wait',
	},
	{
		key: CampaignOnboardWizardSteps.BookMeeting,
		label: 'Book meeting',
		description: <p>Your Account Strategist will review your search and messaging</p>,
		status: 'wait',
	},
	{
		key: CampaignOnboardWizardSteps.Complete,
		label: 'Setup Complete',
		description: <></>,
		status: 'wait',
	},
];
interface CampaignOnboardWizardProps {
	campaignId: string;
}
const CampaignOnboardWizard: React.FC<CampaignOnboardWizardProps> = (props) => {
	const [hideFooter, setHideFooter] = React.useState<boolean>(true);
	const [footerBackText] = React.useState<string>('Back');
	const [footerNextText] = React.useState<string>('Save & Next');
	const [footerNextEnable, setFooterNextEnable] = React.useState<boolean>(true);

	const trackEvent = useProspectCampaignOnboardTracking('Campaign Onboard Wizard');
	const [, fetchOnboardDetails] = useFetch(
		CampaignManager.getCampaignOnboardStateForIndividual,
		CampaignOnboardDetailsActions.loadCampaignOnboardDetails
	);
	const [, updateOnboardDetails] = useFetch(
		CampaignManager.updateCampaignOnboardStateForIndividual,
		CampaignOnboardDetailsActions.updateCampaignOnboardDetails
	);
	const onboardDetails = useSelector((state) =>
		CampaignOnboardSelectors.getOnboardDetails(state, props.campaignId)
	);

	const [steps, setSteps] = React.useState(Steps.slice(0, -1));
	const [currentStep, setCurrentStep] = React.useState(CampaignOnboardWizardSteps.Welcome);
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const appSettings = useSelector(AppSelectors.getSettings);

	React.useEffect(() => {
		const step = onboardDetails?.step ?? CampaignOnboardWizardSteps.Welcome;
		setCurrentStep(step);
		// Mark all previous steps to be finish
		setSteps(
			steps.map((s) => {
				if (s.key < step) return { ...s, status: 'finish' };
				else return s;
			})
		);
	}, [onboardDetails?.status]);

	/**
	 * 	Update handler for the create campaign wizzard steps
	 */
	const updateSteps = React.useCallback(
		(step: CampaignOnboardWizardSteps, update: Partial<ArrayElement<typeof steps>>) => {
			setSteps((stepsToItr) =>
				stepsToItr.map((stepToItr) => {
					if (stepToItr.key === step) return { ...stepToItr, ...update };
					else return stepToItr;
				})
			);
		},
		[]
	);

	/**
	 * Notification when the save fails for server reason.
	 * User can choose to try to save again, or discard
	 * their input and go toward the next step
	 */
	const showSaveError = React.useCallback(
		(retryHandler: () => Promise<void>, skipHandler: () => void) => {
			const description = (
				<>
					<Row>
						<p>Something went wrong and your setting was not saved.</p>
					</Row>
					<Row justify="space-between" align="middle">
						<Col>
							<Button
								onClick={() => {
									notification.destroy(ERROR_NOTIFICATION_KEY);
									skipHandler();
								}}
							>
								Continue anyway
							</Button>
						</Col>
						<Col>
							<Button
								type="primary"
								onClick={() => {
									notification.destroy(ERROR_NOTIFICATION_KEY);
									retryHandler();
								}}
							>
								Retry
							</Button>
						</Col>
					</Row>
				</>
			);
			return notificationManager.showErrorNotification({
				duration: 0,
				message: 'Save failed',
				description,
				key: ERROR_NOTIFICATION_KEY,
			});
		},
		[]
	);

	//#region Search
	const [searchView, setSearchView] = React.useState<LinkedInSearchType>(
		LinkedInSearchType.Unknown
	);

	React.useEffect(
		() => setSearchView(onboardDetails?.searchType ?? LinkedInSearchType.Unknown),
		[onboardDetails?.searchType]
	);

	//#region Sales Naviagtor search
	const [searchUrl, setSearchUrl] = React.useState<string>('');
	const [searchUrlValid, setSearchUrlValid] = React.useState<boolean | undefined>();
	const [, updateHideSearchModalPreference] = useFetch(
		OrganizationMemberManager.updateHideSearchModalPreference,
		AppActions.updateSettings,
		(r) => ({ hideLiSearchVideo: r })
	);

	React.useEffect(() => {
		setSearchUrl(onboardDetails?.searchUrl ?? '');
	}, [onboardDetails?.searchUrl]);

	React.useEffect(() => {
		// TODO update validated method
		isSearchUrlValid(searchUrl)
			? setSearchUrlValid(true)
			: searchUrl === ''
			? setSearchUrlValid(undefined)
			: setSearchUrlValid(false);
	}, [searchUrl]);

	/**
	 * Submit user preference on whether or not to hide the Search URL helper
	 * modal in the future
	 */
	const submitSkipHelpPreference = React.useCallback(async (preference: boolean) => {
		await updateHideSearchModalPreference(activeMember?.id ?? '', preference);
	}, []);

	const onVideoCommit = React.useCallback(
		(searchType: LinkedInSearchType, preference: boolean) => {
			setSearchView(searchType);
			submitSkipHelpPreference(preference);
		},
		[submitSkipHelpPreference]
	);

	/**
	 * Open the sales navigator search intro modal
	 */
	const openSalesNavSearchModal = React.useCallback(() => {
		notification.destroy(ERROR_NOTIFICATION_KEY);
		modalManager.openVideoModal({
			videoType: 'SearchVideo',
			width: 800,
			skipPreference: appSettings?.hideLiSearchVideo ?? false,
			onHandleCommit: onVideoCommit,
			centered: true,
		});
	}, [appSettings?.hideLiSearchVideo, onVideoCommit]);

	//#endregion Sales Navigator search

	//#region Copilot search
	const [location, setLocation] = React.useState<ILocation>({
		locations: [],
		zip: [],
		radius: '',
		locationTab: LocationType.City,
	});
	const [occupation, setOccupation] = React.useState<string[]>([]);
	const [extraOccupations, setExtraOccupations] = React.useState<string[]>([]);

	React.useEffect(() => {
		setLocation({
			locations: onboardDetails?.searchCriteria?.locations ?? [],
			zip: onboardDetails?.searchCriteria?.zip ?? [],
			radius: onboardDetails?.searchCriteria?.radius ?? '',
			locationTab: location.locationTab,
		});
	}, [
		onboardDetails?.searchCriteria?.locations,
		onboardDetails?.searchCriteria?.zip,
		onboardDetails?.searchCriteria?.radius,
	]);

	React.useEffect(() => {
		setOccupation(onboardDetails?.searchCriteria?.titles ?? []);
	}, [onboardDetails?.searchCriteria?.titles]);

	React.useEffect(() => {
		setExtraOccupations(onboardDetails?.searchCriteria?.extraTitles ?? []);
	}, [onboardDetails?.searchCriteria?.extraTitles]);
	//#endregion Copilot search

	/**
	 * Handler for when the user completes the Search Criteria step
	 */
	const handleSearchNext = React.useCallback((success: boolean) => {
		updateSteps(CampaignOnboardWizardSteps.Search, {
			status: success ? 'finish' : 'error',
		});
		setCurrentStep(CampaignOnboardWizardSteps.Messaging);
	}, []);

	/**
	 * Handler for submiting Search Criteria
	 */
	const submitSearchCriteria = React.useCallback(async () => {
		setIsSubmitting(true);
		notification.destroy(ERROR_NOTIFICATION_KEY);
		try {
			const searchCriteria: Partial<SearchCriteria> = {
				extraTitles: extraOccupations,
				titles: occupation,
				locations: location.locations,
				radius: location.radius,
				zip: location.zip,
			};
			if (onboardDetails?.searchCriteria?.id)
				searchCriteria.id = onboardDetails.searchCriteria?.id;
			await updateOnboardDetails(props.campaignId, {
				searchUrl,
				searchCriteria,
				searchType: searchView,
				step: CampaignOnboardWizardSteps.Messaging,
			});
			handleSearchNext(true);
		} catch {
			showSaveError(submitSearchCriteria, () => handleSearchNext(false));
		} finally {
			setIsSubmitting(false);
		}
	}, [
		searchView,
		location,
		occupation,
		extraOccupations,
		searchUrl,
		onboardDetails?.searchCriteria?.id,
	]);

	/**
	 * Handler for when the user is at the Search Criteria step
	 * and wants to go back to the prvious step
	 */
	const handleSearchBack = React.useCallback(() => {
		updateSteps(CampaignOnboardWizardSteps.Welcome, {
			status: 'wait',
		});
		setCurrentStep(CampaignOnboardWizardSteps.Welcome);
		setFooterNextEnable(true);
	}, []);
	//#endregion Search

	//#region Welcome
	/**
	 * Handler for when the user decides their prefered search method
	 */
	const handleWelcomeNext = React.useCallback((searchType: LinkedInSearchType) => {
		trackEvent({
			buttonClicked:
				searchType == LinkedInSearchType.Url ? 'Sales Navigator' : 'Copilot Search',
		});
		updateSteps(CampaignOnboardWizardSteps.Welcome, {
			status: 'finish',
		});
		setSearchView(searchType);
		setCurrentStep(CampaignOnboardWizardSteps.Search);
	}, []);
	//#endregion Welcome

	//#region Timezone
	const [timezone, setTimezone] = React.useState<string>(() =>
		ScheduleUtility.getLocaleTimezone()
	);
	const [weeklySchedule, setWeeklySchedule] = React.useState<DaySchedule[]>(
		SYSTEM_DEFAULT_SCHEDULE.weeklySchedule
	);
	const [syncSchedule, setSyncSchedule] = React.useState<boolean>(
		SYSTEM_DEFAULT_SCHEDULE.synchronization
	);

	const [{ schedule }, setSchedule] = useSchedule(
		activeMember?.organizationId ?? '',
		props.campaignId,
		ScheduleHierarchyType.Campaign,
		false
	);

	React.useEffect(() => {
		setTimezone(schedule.timezoneCode);
		setWeeklySchedule(schedule.weeklySchedule);
		setSyncSchedule(schedule.synchronization);
	}, [schedule]);

	/**
	 * Handler for when the user complete the Timezone Step
	 */
	const handleTimezoneNext = React.useCallback((success: boolean) => {
		updateSteps(CampaignOnboardWizardSteps.Timezone, {
			status: success ? 'finish' : 'error',
		});
		trackEvent({ buttonClicked: 'Book Meeting' });
		setCurrentStep(CampaignOnboardWizardSteps.BookMeeting);
	}, []);

	/**
	 * Submit Timezone and Weekly Schedule setting
	 */
	const submitSchedule = React.useCallback(async () => {
		notification.destroy(ERROR_NOTIFICATION_KEY);
		setIsSubmitting(true);
		try {
			setSchedule({
				...schedule,
				timezoneCode: timezone,
				synchronization: syncSchedule,
				weeklySchedule,
			});
			await updateOnboardDetails(props.campaignId, {
				step: CampaignOnboardWizardSteps.BookMeeting,
			});
			handleTimezoneNext(true);
		} catch {
			showSaveError(submitSchedule, () => handleTimezoneNext(false));
		} finally {
			setIsSubmitting(false);
		}
	}, [timezone, syncSchedule, weeklySchedule]);

	/**
	 * Handler for when the user is at the Timezone step
	 * and wants to go back to the prvious step
	 */
	const handleTimezoneBack = React.useCallback(() => {
		updateSteps(CampaignOnboardWizardSteps.Messaging, {
			status: 'wait',
		});
		setCurrentStep(CampaignOnboardWizardSteps.Messaging);
	}, []);

	const isScheduleInvalid = React.useMemo(() => {
		const error = ScheduleUtility.getScheduleError(weeklySchedule);
		return Object.values(error).reduce<boolean>((acc, err) => acc || err, false);
	}, [weeklySchedule]);

	React.useEffect(() => {
		setFooterNextEnable(!isScheduleInvalid);
	}, [isScheduleInvalid]);
	//#endregion Timezone

	//#region Messaging
	const [campaignId, setCampaignId] = React.useState<string>('');
	const campaign = useSelector(CampaignSelectors.getCampaign(campaignId));
	const [automatedMessages, setAutomatedMessages] = React.useState<OnboardMessage[]>([
		{
			nodeId: '',
			text: '',
			period: 0,
			time: 0,
		},
		{
			nodeId: '',
			text: '',
			period: 0,
			time: DEFAULT_SECOND_MESSAGE_TIMING,
		},
	]);
	const [followUpMessages, setFollowUpMessages] = React.useState<OnboardMessage[]>([]);

	React.useEffect(() => {
		if (onboardDetails?.messages) {
			setAutomatedMessages(onboardDetails.messages.slice(0, 2));
			setFollowUpMessages(onboardDetails.messages.slice(2));
		}
	}, [onboardDetails?.messages]);

	/**
	 * Handler for when the user completes the Messaging step
	 */
	const handleMessagingNext = React.useCallback(
		(success: boolean) => {
			updateSteps(CampaignOnboardWizardSteps.Messaging, {
				status: success ? 'finish' : 'error',
			});
			setCurrentStep(CampaignOnboardWizardSteps.Timezone);
			setFooterNextEnable(!isScheduleInvalid);
		},
		[isScheduleInvalid]
	);

	/**
	 * Submit Automated Messages setting
	 */
	const submitMessaging = React.useCallback(async () => {
		notification.destroy(ERROR_NOTIFICATION_KEY);
		setIsSubmitting(true);
		try {
			await updateOnboardDetails(props.campaignId, {
				messages: [...automatedMessages, ...followUpMessages],
				step: CampaignOnboardWizardSteps.Timezone,
			});

			handleMessagingNext(true);
		} catch {
			showSaveError(submitMessaging, () => handleMessagingNext(false));
		} finally {
			setIsSubmitting(false);
		}
	}, [automatedMessages, followUpMessages, handleMessagingNext]);

	/**
	 * Handler for when the user is at the Messaging step
	 * and wants to go back to the prvious step
	 */
	const handleMessagingBack = React.useCallback(() => {
		setFooterNextEnable(true);
		updateSteps(CampaignOnboardWizardSteps.Search, {
			status: 'wait',
		});
		setCurrentStep(CampaignOnboardWizardSteps.Search);
	}, []);

	React.useEffect(() => {
		if (currentStep === CampaignOnboardWizardSteps.Messaging) {
			// Disable Next button if either the first or second message is empty
			if (automatedMessages.find((message) => !message.text.trim())) {
				setFooterNextEnable(false);
			} else {
				setFooterNextEnable(true);
			}
		}
	}, [currentStep, automatedMessages]);
	//#endregion Messaging

	//#region BookMeeting

	/**
	 * Handler for when the user finishes booking a meeting with cs
	 */
	const handleBookMeetingNext = React.useCallback(() => {
		updateSteps(CampaignOnboardWizardSteps.BookMeeting, {
			status: 'finish',
		});

		updateOnboardDetails(props.campaignId, {
			step: CampaignOnboardWizardSteps.Complete,
		}).then(() => {
			setCurrentStep(CampaignOnboardWizardSteps.Complete);
		});
	}, []);
	/**
	 * Handler for when the user is booking a meeting with cs
	 * and wants to go back to the prvious step
	 */
	const handleBookMeetingBack = React.useCallback(() => {
		updateSteps(CampaignOnboardWizardSteps.Timezone, {
			status: 'wait',
		});
		setCurrentStep(CampaignOnboardWizardSteps.Timezone);
	}, []);
	//#endregion BookMeeting

	//#region Footer
	React.useEffect(() => {
		// Update footer based on whichever steps the
		// user is at
		switch (currentStep) {
			case CampaignOnboardWizardSteps.Welcome:
			case CampaignOnboardWizardSteps.Complete:
				setHideFooter(true);
				break;
			case CampaignOnboardWizardSteps.Search:
				if (searchView === LinkedInSearchType.Url) {
					setFooterNextEnable(!!searchUrlValid);
				} else {
					setFooterNextEnable(true);
				}
				setHideFooter(false);
				break;
			case CampaignOnboardWizardSteps.BookMeeting:
				setFooterNextEnable(false);
				setHideFooter(false);
				break;
			case CampaignOnboardWizardSteps.Messaging:
			case CampaignOnboardWizardSteps.Timezone:
				setFooterNextEnable(true);
				setHideFooter(false);
				break;
		}
	}, [currentStep, searchView, searchUrlValid]);

	/**
	 * 	Select which back handler to use based on the
	 * 	which steps the user is at.
	 */
	const handleFooterBack = React.useCallback(() => {
		switch (currentStep) {
			case CampaignOnboardWizardSteps.Welcome:
				return null;
			case CampaignOnboardWizardSteps.Search:
				return handleSearchBack();
			case CampaignOnboardWizardSteps.Messaging:
				return handleMessagingBack();
			case CampaignOnboardWizardSteps.Timezone:
				return handleTimezoneBack();
			case CampaignOnboardWizardSteps.BookMeeting:
				return handleBookMeetingBack();
			case CampaignOnboardWizardSteps.Complete:
			default:
				return null;
		}
	}, [currentStep]);

	/**
	 * 	Select which next handler to use based on the
	 * 	which steps the user is at.
	 */
	const handleFooterNext = React.useCallback(() => {
		switch (currentStep) {
			case CampaignOnboardWizardSteps.Welcome:
				return null;
			case CampaignOnboardWizardSteps.Search:
				return submitSearchCriteria();
			case CampaignOnboardWizardSteps.Messaging:
				return submitMessaging();
			case CampaignOnboardWizardSteps.Timezone:
				return submitSchedule();
			case CampaignOnboardWizardSteps.BookMeeting:
				return null;
			case CampaignOnboardWizardSteps.Complete:
			default:
				return null;
		}
	}, [currentStep, submitSearchCriteria, submitMessaging, submitSchedule]);
	//#endregion Footer

	React.useEffect(() => {
		// Close the error notification when user edit their input
		notification.destroy(ERROR_NOTIFICATION_KEY);
	}, [searchUrl, location, occupation, extraOccupations, timezone, syncSchedule, weeklySchedule]);

	React.useEffect(() => {
		fetchOnboardDetails(props.campaignId).then((details) => {
			if (!details.messages) {
				/*
					Fetch user's automated messages from exisiting campaign and use it as default if
					messages haven't been set yet
				*/
				OrganizationMemberManager.getLinkedInMessages(activeMember?.id ?? '').then(
					(messages) => {
						const campaigns = Object.keys(messages);
						const cId = campaigns[0];
						if (campaigns.length > 0) {
							setAutomatedMessages(messages[cId].slice(0, 2));
							setFollowUpMessages(messages[cId].slice(2));
							setCampaignId(cId);
						}
					}
				);
			}
		});
	}, []);
	/**
	 * Select which component to display based on the
	 * the currentStep
	 */
	const createCampaignComponent = () => {
		switch (currentStep) {
			case CampaignOnboardWizardSteps.Welcome:
				return <CampaignOnboardWizardWelcome handleNext={handleWelcomeNext} />;

			case CampaignOnboardWizardSteps.Search:
				return (
					<CampaignOnboardWizardSearch
						skipHelp={appSettings?.hideLiSearchVideo ?? false}
						view={searchView}
						updateView={setSearchView}
						searchUrl={searchUrl}
						updateSearchUrl={setSearchUrl}
						searchUrlValid={searchUrlValid}
						location={location}
						updateLocation={setLocation}
						occupation={occupation}
						updateOccupation={setOccupation}
						extraOccupations={extraOccupations}
						updateExtraOccupations={setExtraOccupations}
						openSalesNavSearchModal={openSalesNavSearchModal}
					/>
				);

			case CampaignOnboardWizardSteps.Messaging:
				return (
					<CampaignOnboardWizardMessaging
						campaignId={props.campaignId}
						portOverCampaignName={campaign?.name ?? ''}
						automatedMessages={automatedMessages}
						setAutomatedMessages={setAutomatedMessages}
						followUpMessages={followUpMessages}
						setFollowUpMessages={setFollowUpMessages}
					/>
				);

			case CampaignOnboardWizardSteps.Timezone:
				return (
					<BasicContainer style={{ padding: '15px' }}>
						<Schedule
							timezone={timezone}
							onTimezoneUpdate={setTimezone}
							sync={syncSchedule}
							onSyncUpdate={setSyncSchedule}
							weeklySchedule={weeklySchedule}
							onScheduleUpdate={setWeeklySchedule}
						/>
					</BasicContainer>
				);
			case CampaignOnboardWizardSteps.Complete:
				return <CampaignOnboardComplete />;

			case CampaignOnboardWizardSteps.BookMeeting:
				return (
					<CampaignOnboardWizardBooking
						onBooking={handleBookMeetingNext}
						campaignId={props.campaignId}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<HeaderContentLayout.Content style={{ paddingTop: '30px' }}>
			<Row justify="center">
				<Col offset={1}>
					<StepContainer
						steps={steps}
						current={currentStep}
						footer={
							<CampaignOnboardWizardFooter
								isLoading={isSubmitting}
								backText={footerBackText}
								nextText={footerNextText}
								onBack={handleFooterBack}
								onNext={() => {
									handleFooterNext();
								}}
								isHidden={hideFooter}
								isNextDisable={!footerNextEnable}
							/>
						}
						isAllDescriptionShown={false}
					>
						<Row justify="center" style={{ minHeight: '100vh' }}>
							<StyledCol span={20}>
								<Spin spinning={isSubmitting}>{createCampaignComponent()}</Spin>
							</StyledCol>
						</Row>
					</StepContainer>
				</Col>
			</Row>
		</HeaderContentLayout.Content>
	);
};
export default CampaignOnboardWizard;
