import { StringColumn } from './string';
import { ColumnDefinition } from '../../componentModels/columnDefinition';
import { CampaignStatusEnum } from '@copilot/data/requests/models';

const CampaignStatusEnumLabels: { [key: string]: string } = {
	[CampaignStatusEnum.Unknown]: 'Disabled',
	[CampaignStatusEnum.Disabled]: 'Disabled',
	[CampaignStatusEnum.Enabled]: 'Active',
	[CampaignStatusEnum.Running]: 'Active',
};

export class EnabledStatus<M> extends StringColumn<M> {
	constructor(c: ColumnDefinition) {
		super(c);
		this.render = (text: string, record: M) => {
			const { properties = [] } = c;
			const values = this.getValues<CampaignStatusEnum>(properties, record)
				.map((s: CampaignStatusEnum) =>
					CampaignStatusEnumLabels[s] ? CampaignStatusEnumLabels[s] : s
				)
				.join(' ');

			return <span>{values}</span>;
		};
	}
}
