import { createLoadActions, getLoadActionTypes } from '@copilot/common/store/actionCreators/list';
import { createFetchSaga } from '@copilot/common/store/saga';
import { createSagaWatcher } from '@copilot/common/store/sagaFactory';
import notificationManager from '@copilot/common/utils/notificationManager';
import { joinOrgMembersAndTheirLinkedIn } from '@copilot/common/utils/organizationMember';
import { OrganizationManager, LinkedInManager, OrganizationMemberManager } from '@copilot/data';
import { AdminManager } from '@copilot/data/managers/admin';
import {
	IAddOrgMemberRequest,
	OrganizationMemberRequestModel,
} from '@copilot/data/requests/models';
import { RegisterOrganizationMembersError } from '@copilot/data/responses/interface';
import createSagaMiddleware from 'redux-saga';
import {
	addMemberNotificationSuccess,
	deactivateNotificationSuccess,
	ORGANIZATION_MEMBERS,
	reactivateNotificationSuccess,
	removeMemberNotificationError,
	removeMemberNotificationSuccess,
	removeOrgAdminNotificationError,
} from './constant';
import { IOrganizationTeamMember } from './models';

// TODO: update these functions once we move to view model for organization member and their linedin profiles
/**
 * given an organizationId, gets and modifies orgMembers with all necessary fields populated
 * @param organizationId id of the organization to get orgMembers
 */
export const generateAllOrgMembers = async (
	organizationId: string
): Promise<IOrganizationTeamMember[]> => {
	const orgMembers = await AdminManager.getAllOrganizationMembers(organizationId);
	const orgMembersLinkedIn = await LinkedInManager.getUsersLinkedInProfiles(organizationId);
	return joinOrgMembersAndTheirLinkedIn(orgMembers, orgMembersLinkedIn);
};

/**
 * Adds organization members to the organization
 * @param organizationId id of the organization
 * @param members members to add
 * @param onSuccess method to call with the results of the registration
 * @param onComplete method to call upon completion
 */
export const addMembersAndGetLinkedIn = async (
	organizationId: string,
	members: Partial<IAddOrgMemberRequest>[],
	onSuccess?: (
		registered: IOrganizationTeamMember[],
		errors: RegisterOrganizationMembersError[]
	) => void,
	onComplete?: () => void
) => {
	const response = await OrganizationManager.addMembers(organizationId, members);
	const registered = response.successfulRegistrations;
	const membersLinkedIn = await LinkedInManager.getUsersLinkedInProfiles(organizationId);
	const membersWithLinkedIn = joinOrgMembersAndTheirLinkedIn(registered, membersLinkedIn);
	onSuccess?.(membersWithLinkedIn, response.errors);
	onComplete?.();
	return membersWithLinkedIn;
};

/**
 * Updates the organization member and gets and joins their linkedin info
 * @param member member to update
 * @param onSuccess method to call on success
 */
export const updateMemberAndGetLinkedIn = async (
	member: Partial<OrganizationMemberRequestModel>,
	onSuccess?: (updatedMembers: IOrganizationTeamMember) => void
) => {
	const updatedMember = await OrganizationMemberManager.updateMember(member);
	const memberLinkedIn = await LinkedInManager.getUserLinkedInProfile(
		updatedMember.organizationId,
		updatedMember.id
	);
	const [memberWithLinkedIn] = joinOrgMembersAndTheirLinkedIn(updatedMember, memberLinkedIn);
	onSuccess?.(memberWithLinkedIn);
	return memberWithLinkedIn;
};

/**
 * Updates the isActive status of organization member
 * @param organizationMemberId id of the organization member
 * @param isActive updated value to set for the organization member
 * @param onSuccess method to call on success
 */
export const updateIsActiveAndGetLinkedIn = async (
	organizationMemberId: string,
	isActive: boolean,
	onSuccess?: () => void
) => {
	const updatedMember = await AdminManager.updateIsActive(organizationMemberId, isActive);
	const memberLinkedIn = await LinkedInManager.getUserLinkedInProfile(
		updatedMember.organizationId,
		updatedMember.id
	);
	const [memberWithLinkedIn] = joinOrgMembersAndTheirLinkedIn(updatedMember, memberLinkedIn);
	onSuccess?.();
	return memberWithLinkedIn;
};

const types = getLoadActionTypes(ORGANIZATION_MEMBERS);

export const {
	oneWatcher: watchLoadOneTeamMember,
	listWatcher: watchLoadTeamMembers,
	upsertListWatcher: watchUpsertTeamMembers,
	deleteOneWatcher: watchDeleteOneTeamMember,
	loadListAction: LoadTeamMembersAction,
	loadOneAction: LoadOneTeamMemberAction,
	upsertListAction: UpsertTeamMembersAction,
	deleteOneAction: DeleteOneTeamMemberAction,
} = createFetchSaga(
	ORGANIZATION_MEMBERS,
	createLoadActions<IOrganizationTeamMember>(ORGANIZATION_MEMBERS)
);

export const AddTeamMembersAction = (
	organizationId: string,
	members: Partial<IAddOrgMemberRequest>[],
	onSuccess?: (
		registered: IOrganizationTeamMember[],
		errors: RegisterOrganizationMembersError[]
	) => void,
	onComplete?: () => void
) => {
	const wrappedOnSuccess = (
		registered: IOrganizationTeamMember[],
		errors: RegisterOrganizationMembersError[]
	) => {
		if (registered.length)
			notificationManager.showSuccessNotification(addMemberNotificationSuccess);
		onSuccess?.(registered, errors);
	};
	return UpsertTeamMembersAction(
		addMembersAndGetLinkedIn,
		organizationId,
		members,
		wrappedOnSuccess,
		onComplete
	);
};

export const UpdateActiveStatusAction = (organizationMemberId: string, isActive: boolean) => {
	const onSuccess = () =>
		notificationManager.showSuccessNotification(
			isActive ? reactivateNotificationSuccess : deactivateNotificationSuccess
		);
	return LoadOneTeamMemberAction(
		updateIsActiveAndGetLinkedIn,
		organizationMemberId,
		isActive,
		onSuccess
	);
};

export const notifyAdminDeleteError = () => {
	notificationManager.showErrorNotification(removeOrgAdminNotificationError);
};

const watchDeleteMemberSuccess = createSagaWatcher(
	types.deleteOneSuccessAction,
	notificationManager.showSuccessNotification,
	removeMemberNotificationSuccess
);

const watchDeleteMemberError = createSagaWatcher(
	types.deleteOneErrorAction,
	notificationManager.showErrorNotification,
	removeMemberNotificationError
);

/**
 * Apply Organization Team Members sagas to the saga middleware
 * @param sagaMiddleware
 */
export const registerTeamMembersSaga = (
	sagaMiddleware: ReturnType<typeof createSagaMiddleware>
): void => {
	sagaMiddleware.run(watchLoadOneTeamMember);
	sagaMiddleware.run(watchLoadTeamMembers);
	sagaMiddleware.run(watchUpsertTeamMembers);
	sagaMiddleware.run(watchDeleteOneTeamMember);
	sagaMiddleware.run(watchDeleteMemberSuccess);
	sagaMiddleware.run(watchDeleteMemberError);
};
