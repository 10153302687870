// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vtuDMe06DGvXBLAzOy4A label {\n  width: 240px;\n}\n.vtuDMe06DGvXBLAzOy4A .copilot-form-item {\n  margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/editors/orgMemberInfo/orgMemberInfo.module.less"],"names":[],"mappings":"AAAA;EAEE,YAAA;AAAF;AAFA;EAKE,gBAAA;AAAF","sourcesContent":[".orgMemberInfoWrapper {\n\tlabel {\n\t\twidth: 240px;\n\t}\n\t:global(.copilot-form-item) {\n\t\tmargin-bottom: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orgMemberInfoWrapper": "vtuDMe06DGvXBLAzOy4A"
};
export default ___CSS_LOADER_EXPORT___;
