import BaseDataManager from './base';
import { TagDTO, TagResponse } from '../responses/interface';

/** Data Manager for Tags */
class TagManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Helper to get endpoint for tags
	 * @param {string} id The id of the organization
	 */
	private getTagEndpoint = (id: string) => {
		return this.combineRoute(this.BACKEND_ROUTES.ORGANIZATION.Default, id, 'tags');
	};

	/**
	 * Grabs all the tags from organization
	 * @param {string} organizationId Id of organization we want to get tags from
	 * @param {boolean} showCustomOnly exclude any tags that applied by the system
	 */
	getTags = (organizationId: string, showCustomOnly = false) => {
		const url = this.getTagEndpoint(organizationId);
		return this.RequestManager.get(url, { params: { showCustomOnly } }).then((response) => {
			const { data } = response;
			const results = data.map((d: any) => d as TagResponse);
			return results;
		});
	};

	/**
	 * Adds tag to organization
	 * @param {string} organizationId Id of contact we want to get tags from
	 * @param {TagDTO} tag Object of tag
	 */
	public addTagToOrg = async (organizationId: string, tag: TagDTO) => {
		const url = this.getTagEndpoint(organizationId);
		const { data } = await this.RequestManager.post<TagResponse>(url, tag);
		return data;
	};

	/**
	 * Update a tag along with its references in the organization
	 * @param {string} organizationId The id of the organization
	 * @param {TagDTO} tag The updated tag
	 */
	public updateTag = async (organizationId: string, tag: TagDTO) => {
		const url = this.getTagEndpoint(organizationId);
		const { data } = await this.RequestManager.patch<TagResponse>(`${url}/${tag.id}`, tag);
		return data;
	};

	/**
	 * Delete a tag in the organization
	 * @param {string} id The id of the organization
	 * @param {string} tagId The id of the tag to be deleted
	 */
	public deleteTag = async (id: string, tagId: string) => {
		const url = this.getTagEndpoint(id);
		const response = await this.RequestManager.delete<{}>(`${url}/${tagId}`);
		return response;
	};
}

const instance = new TagManager();
export default instance;
