import type {
	AdminClientResponse,
	ClientCampaignResponse,
	AdminTeamClientResponse,
	ITeamClientMemberResponse,
} from '../interface';
import { ChangelogResponse } from '@copilot/data/managers/changelog/interface';

export class LinkedInInfo {
	public profileId: string;
	public vpnPort: number;
	public salesNavContractName: string;
	public monthlyInvites: string;
	public monthlyMessages: string;
	public withdrawExternalInvites: boolean;
	public pendingInvites: number;
	public weeksBeforeWithdrawal: number;
	constructor(info: any = {}) {
		this.profileId = info.profileId;
		this.vpnPort = info.vpnPort;
		this.salesNavContractName = info.salesNavContractName;
		this.monthlyInvites = info.monthlyInvites;
		this.monthlyMessages = info.monthlyMessages;
		this.withdrawExternalInvites = info.withdrawExternalInvites;
		this.pendingInvites = info.pendingInvites;
		this.weeksBeforeWithdrawal = info.weeksBeforeWithdrawal;
	}
}

export class CommonStats {
	public inviteSent: number;
	public connections: number;
	public replies: number;
	public replyPercentage: number;
	public connectionRate: number;
	public interestedReplies: number;
	public interestedResponseRate: number;
	public messageResponseRate: number;
	public nurtureReplies: number;
	public movedConnections: number;
	public sendMessage: number;
	public nurtureReplyRate: number;

	constructor(stats: any = {}) {
		this.inviteSent = stats.inviteSent;
		this.connections = stats.connections;
		this.replies = stats.replies;
		this.replyPercentage = stats.replyPercentage;
		this.connectionRate = stats.connectionRate;
		this.interestedReplies = stats.interestedReplies;
		this.interestedResponseRate = stats.interestedResponseRate;
		this.messageResponseRate = stats.messageResponseRate;
		this.nurtureReplies = stats.nurtureReplies;
		this.movedConnections = stats.movedConnections;
		this.sendMessage = stats.sendMessage;
		this.nurtureReplyRate = stats.nurtureReplyRate;
	}
}

export class ClientDetails {
	public linkedInInfo: LinkedInInfo;
	constructor(client: any = {}) {
		this.linkedInInfo = new LinkedInInfo(client.linkedInInfo);
	}
}

export class ClientStats {
	public today: CommonStats;
	public sevenDays: CommonStats;
	public fourteenDays: CommonStats;
	public thiryDays: CommonStats;
	public sixtyDays: CommonStats;
	public ninetyDays: CommonStats;
	public sinceSubscriptionStart: CommonStats;
	public allTime: CommonStats;

	constructor(stats: any = {}) {
		this.today = new CommonStats(stats.Today || stats.today);
		this.sevenDays = new CommonStats(stats.SevenDays || stats.sevenDays);
		this.fourteenDays = new CommonStats(stats.FourteenDays || stats.fourteenDays);
		// TODO Fix Typo in backend
		this.thiryDays = new CommonStats(stats.ThiryDays || stats.thiryDays);
		this.sixtyDays = new CommonStats(stats.SixtyDays || stats.sixtyDays);
		this.ninetyDays = new CommonStats(stats.NinetyDays || stats.ninetyDays);
		this.sinceSubscriptionStart = new CommonStats(
			stats.SinceSubscriptionStart || stats.sinceSubscriptionStart
		);
		this.allTime = new CommonStats(stats.AllTime || stats.allTime);
	}
}

export class ClientModel implements AdminClientResponse {
	public id: string;
	public name: string;
	public firstName: string;
	public lastName: string;
	public userName: string;
	public userId: string;
	public organizationId: string;
	public organizationMemberId: string;
	public campaigns: { [key: string]: ClientCampaignResponse };
	public isCampaignRunning: false;
	public daysLastSeen: number;
	public monthlyInvite: number;
	public monthlyMessage: number;
	public billingEndDate: string;
	public serviceStartDate: string;
	public serviceStopDate: string;
	public isSubscriptionActive: boolean;
	public inviteProgress: number;
	public customerSuccessName: string;
	public customerSuccessId: string;
	public status: string;
	public linkedInError: number;
	public isEnabled: boolean;
	public isPaused: boolean;
	public stats: any;
	public activationsRequired: number;
	public approvedCampaigns: number;
	public totalActiveCampaigns: number;
	public totalRunningCampaigns: number;
	public totalRunningNurtureCampaigns: number;
	public hasMessageTemplateAccess: false;
	public unpauseDate: string;
	public isAdvanced: boolean;

	constructor(client: any = {}) {
		this.id = client.id;
		this.name = client.name;
		this.firstName = client.firstName;
		this.lastName = client.lastName;
		this.userName = client.userName;
		this.userId = client.userId;
		this.organizationId = client.organizationId;
		this.organizationMemberId = client.organizationMemberId;
		this.campaigns = client.campaigns;
		this.isCampaignRunning = client.isCampaignRunning;
		this.daysLastSeen = client.daysLastSeen;
		this.monthlyInvite = client.monthlyInvite;
		this.monthlyMessage = client.monthlyMessage;
		this.billingEndDate = client.billingEndDate;
		this.isSubscriptionActive = client.isSubscriptionActive;
		this.inviteProgress = client.inviteProgress;
		this.customerSuccessName = client.customerSuccessName;
		this.customerSuccessId = client.customerSuccessId;
		this.status = client.status;
		this.linkedInError = client.linkedInError;
		this.serviceStartDate = client.serviceStartDate;
		this.serviceStopDate = client.serviceStopDate;
		this.isEnabled = client.isEnabled;
		this.isPaused = client.isPaused;
		this.stats = new ClientStats(client.stats);
		this.activationsRequired = client.activationsRequired;
		this.approvedCampaigns = client.approvedCampaigns;
		this.totalActiveCampaigns = client.totalActiveCampaigns;
		this.totalRunningCampaigns = client.totalRunningCampaigns;
		this.totalRunningNurtureCampaigns = client.totalRunningNurtureCampaigns;
		this.hasMessageTemplateAccess = client.hasMessageTemplateAccess;
		this.unpauseDate = client.unpauseDate;
		this.isAdvanced = client.isAdvanced;
	}
}

export class TeamClientModel implements AdminTeamClientResponse {
	public id: string;
	public name: string;
	public organizationId: string;
	public teamsCustomerId: string;
	public ownerId: string;
	public instanceId: string;
	public maxInvitesCount: number;
	public maxMessagesCount: number;
	public maxSeats?: number;
	public billingEndDate?: string;
	public customerSuccessName: string;
	public customerSuccessUserId: string;
	public totalActiveCampaigns: number;
	public totalRunningNurtureCampaigns: number;
	public members: ITeamClientMemberResponse[];
	public status: string;
	public seatsAllocated: number;
	public activeMemberCount: number;
	public maxSmartReplyCredits: number;
	public tenantId: string;

	constructor(client: any = {}) {
		this.id = client.id;
		this.name = client.name;
		this.organizationId = client.organizationId;
		this.teamsCustomerId = client.teamsCustomerId;
		this.instanceId = client.instanceId;
		this.maxSeats = client.maxSeats;
		// Billing End Date might be null since we removed from the team's cs dash feature scope.
		this.billingEndDate = client.billingEndDate;
		this.customerSuccessName = client.customerSuccessName;
		this.customerSuccessUserId = client.customerSuccessUserId;
		this.totalActiveCampaigns = client.totalActiveCampaigns;
		this.totalRunningNurtureCampaigns = client.totalRunningNurtureCampaigns;
		this.members = client.members;
		this.ownerId = client.ownerId;
		this.maxInvitesCount = client.maxInvitesCount;
		this.maxMessagesCount = client.maxMessagesCount;
		this.status = client.status;
		this.seatsAllocated = client.seatsAllocated;
		this.activeMemberCount = client.activeMemberCount;
		this.maxSmartReplyCredits = client.maxSmartReplyCredits;
		this.tenantId = client.tenantId;
	}
}

export class ChangeModelUser {
	public firstName: string;
	public lastName: string;
	public id: string;

	constructor(user: any = {}) {
		this.firstName = user.firstName || '';
		this.lastName = user.lastName || '';
		this.id = user.userId || user.id || '';
	}
}

export class ChangeModel {
	public timestamp: Date;
	public user: ChangeModelUser;
	public changes: Record<string, { oldValue: string; newValue: string }>;

	constructor(change: ChangelogResponse[0]) {
		this.timestamp = new Date(change.timestamp ?? undefined);
		this.user = new ChangeModelUser(change.user);
		this.changes = change.changes;
	}
}
