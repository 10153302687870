import { CampaignPercentageStatsWithReplyOverNurture } from '@copilot/common/components/statistics/type';
import { StatisticAdapter } from '@copilot/data/adapter/statistic';
import { StatsResponse } from '@copilot/data/responses/interface';
import { LinkedInStatLabelMap } from '@copilot/common/constant/enum';

export class SummaryStatsAdapter extends StatisticAdapter {
	public ratio: Record<string, CampaignPercentageStatsWithReplyOverNurture>;
	constructor(input: Record<string, StatsResponse>) {
		super(input);
		this.ratio = this.getRatioByOwner();
	}
	/**
	 * Calculate the totals from the parsed data
	 * @returns {Record<string, Record<string, number>>} total by stats label
	 */
	private calculateTotalsByOwner = () => {
		const totals: { [ownerId: string]: { [label: string]: number } } = {};
		for (const label in this.data) {
			for (const data of this.data[label]) {
				for (const ownerId in data) {
					if (ownerId == this.timestampKey) continue;
					if (!(ownerId in totals)) totals[ownerId] = {};
					if (label in totals[ownerId]) totals[ownerId][label] += data[ownerId];
					else totals[ownerId][label] = data[ownerId];
				}
			}
		}
		return totals;
	};

	/**
	 * Calculate the ratio with the given stats data.
	 * @param {string[]} [ownerIds] the owner Ids to include for calcualting the ratio. All owner Ids are used if not provided
	 */
	private getRatioByOwner = () => {
		const totals = this.calculateTotalsByOwner();
		const ratios: Record<string, CampaignPercentageStatsWithReplyOverNurture> = {};
		for (const ownerId in totals) {
			ratios[ownerId] = {
				ConnectionsOverInvites: [
					{
						name: LinkedInStatLabelMap.LinkedInConnected,
						value: totals[ownerId].LinkedInConnected,
					},
					{
						name: LinkedInStatLabelMap.LinkedInInvite,
						value: totals[ownerId].LinkedInInvite,
					},
				],
				ReplyOverConnections: [
					{
						name: LinkedInStatLabelMap.UniqueReplies,
						value: totals[ownerId].UniqueReplies,
					},
					{
						name: LinkedInStatLabelMap.LinkedInConnected,
						value: totals[ownerId].LinkedInConnected,
					},
				],
				InterestedOverReply: [
					{
						name: LinkedInStatLabelMap.ReplyInterested,
						value: totals[ownerId].ReplyInterested,
					},
					{
						name: LinkedInStatLabelMap.UniqueReplies,
						value: totals[ownerId].UniqueReplies,
					},
				],
				ReplyOverInvites: [
					{
						name: LinkedInStatLabelMap.UniqueReplies,
						value: totals[ownerId].UniqueReplies,
					},
					{
						name: LinkedInStatLabelMap.LinkedInInvite,
						value: totals[ownerId].LinkedInInvite,
					},
				],
				NurtureReplyOverSent: [
					{
						name: LinkedInStatLabelMap.NurtureUniqueReplies,
						value: totals[ownerId].NurtureUniqueReplies,
					},
					{
						name: LinkedInStatLabelMap.SendMessage,
						value: totals[ownerId].SendMessage,
					},
				],
				NurtureReplyOverNurtureSent: [
					{
						name: LinkedInStatLabelMap.NurtureUniqueReplies,
						value: totals[ownerId].NurtureUniqueReplies,
					},
					{
						name: LinkedInStatLabelMap.NurtureSendMessage,
						value: totals[ownerId].NurtureSendMessage,
					},
				],
			};
		}
		return ratios;
	};
}
