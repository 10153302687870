import { attr, fk, MutableQuerySet, SessionBoundModel } from 'redux-orm';
import { BaseModel } from './base';
import type { Campaign } from './campaign';
import { OrganizationMember } from './organizationMember';
import { MultipleInvite } from '@copilot/data/responses/interface';

export interface CampaignMemberFields {
	id: string;
	email: string;
	userId: string;
	campaignId: string;
	username: string;
	firstName: string;
	lastName: string;
	role: string[];
	dailyInvites: number;
	dailyMessages: number;
	dailyOpenInMailLimit: number;
	openInMailEnabled: boolean;
	status: any;
	orgMemberId: string;
	linkedInProfile: string;
	searchUrl: string;
	lastSearchUrlUpdate: string | undefined;
	multipleInvite: MultipleInvite;
}

interface InstanceFields {
	campaign: MutableQuerySet<Campaign>;
	organizationMember: OrganizationMember;
}

export type ICampaignMember = CampaignMemberFields & InstanceFields;

export class CampaignMember extends BaseModel<typeof CampaignMember, ICampaignMember> {
	static modelName = 'CampaignMember';
	static fields = {
		id: attr(),
		email: attr(),
		userId: attr(),
		campaignId: fk({ to: 'Campaign', as: 'Campaign' }),
		username: attr(),
		firstName: attr(),
		lastName: attr(),
		role: attr(),
		dailyInvites: attr(),
		dailyMessages: attr(),
		status: attr(),
		orgMemberId: fk({ to: 'OrganizationMember', as: 'organizationMember' }),
		linkedInProfile: fk('LinkedInProfile'),
		searchUrl: attr(),
		lastSearchUrlUpdate: attr(),
		multipleInvite: attr(),
	};

	/**
	 * Get the full name of the campaign member
	 */
	// @ts-expect-error disabling for 'get' and 'set' accessors cannot declare 'this' parameters
	public get name(this: SessionBoundModel<CampaignMember>) {
		const first = this.firstName || '';
		const last = this.lastName || '';
		return `${first}${last ? ' ' : ''}${last}`;
	}

	/**
	 * Check CampaignMember status
	 */
	// @ts-expect-error disabling for 'get' and 'set' accessors cannot declare 'this' parameters
	public get isEnabled(this: SessionBoundModel<CampaignMember>) {
		return !!+this.status;
	}
}
