import { FC, ReactNode, Children, ReactComponentElement, Fragment, CSSProperties } from 'react';
import { Row, Col, Card as AntdCard, Divider } from 'antd';
import { isReactElement } from '@copilot/common/utils';
import styled from 'styled-components';

const CardContainer = styled(AntdCard)`
	.${(props) => props.theme['@ant-prefix']}-card-body {
		padding: ${(props) => props.theme['@spacer-size-lg']};
	}
`;

const HeaderDivider = styled(Divider)`
	&&& {
		margin: ${(props) =>
			`${props.theme['@spacer-size-lg']} 0 ${props.theme['@spacer-size-sm']}`};
	}
`;

const InnerSectionDivider = styled(Divider)`
	&&& {
		margin: ${(props) => props.theme['@spacer-size-sm']} 0;
	}
`;

const ActionContainerWrapper = styled.div`
	&&& {
		margin-top: ${(props) => props.theme['@spacer-size-md']};
	}
`;

/**
 * Container for a section in the Inner Container
 */
const CardInnerContainerSection: FC<{}> = (props) => <>{props.children}</>;
export const isCardInnerContainerSection = (
	element: ReactNode
): element is ReactComponentElement<typeof CardInnerContainerSection> =>
	isReactElement(element) && element.type === CardInnerContainerSection;

const getCardInnerContainerSections = (children: ReactNode) => {
	const sections: ReactNode[] = [];
	Children.map(children, (child) => {
		if (isCardInnerContainerSection(child)) sections.push(child);
	});
	return sections;
};

/**
 * Inner (Content) Container for the Card Component
 */
const CardInnerContainer: FC<{}> & { Section: typeof CardInnerContainerSection } = (props) => {
	const sections = getCardInnerContainerSections(props.children);
	return (
		<>
			{sections.map((section, index) => (
				<Fragment key={index}>
					<Row>
						<Col span={24}>{section}</Col>
					</Row>
					{index < sections.length - 1 && <InnerSectionDivider />}
				</Fragment>
			))}
		</>
	);
};
CardInnerContainer.Section = CardInnerContainerSection;

/**
 * Head (Title) Container for the Card Component
 */
const CardHeadContainer: FC<{}> = (props) => <>{props.children}</>;

/**
 * Action Container for the Card Component
 */
const CardActionContainer: FC<{}> = (props) => (
	<ActionContainerWrapper>{props.children}</ActionContainerWrapper>
);

const getCardSubComponents = (children: ReactNode) => {
	let HeadContainer: ReactNode = null;
	let InnerContainer: ReactNode = null;
	let ActionContainer: ReactNode = null;
	Children.map(children, (child) => {
		if (isReactElement(child)) {
			switch (child.type) {
				case CardHeadContainer:
					HeadContainer = child;
					break;
				case CardInnerContainer:
					InnerContainer = child;
					break;
				case CardActionContainer:
					ActionContainer = child;
					break;
			}
		}
	});
	return { HeadContainer, InnerContainer, ActionContainer };
};

interface ICardStaticProps {
	HeadContainer: typeof CardHeadContainer;
	InnerContainer: typeof CardInnerContainer;
	ActionContainer: typeof CardActionContainer;
}

interface ICardProps {
	style?: CSSProperties;
}

/**
 * Base Card Component with a header, main content area that can be divided into sections, and an action section
 */
const Card: FC<ICardProps> & ICardStaticProps = (props) => {
	const { style, children } = props;
	const { HeadContainer, InnerContainer, ActionContainer } = getCardSubComponents(children);
	return (
		<CardContainer style={style}>
			{HeadContainer}
			{!!HeadContainer && !!InnerContainer && <HeaderDivider />}
			{InnerContainer}
			{ActionContainer}
		</CardContainer>
	);
};
Card.HeadContainer = CardHeadContainer;
Card.InnerContainer = CardInnerContainer;
Card.ActionContainer = CardActionContainer;

export default Card;
