import { PaginatedResponse } from '@copilot/data/responses/interface';

/**
 * Load List Action's Types
 */
export type LoadActionTypes = {
	loadAction: string;
	loadListSuccessAction: string;
	loadOneSuccessAction: string;
	upsertListSuccessAction: string;
	deleteOneSuccessAction: string;
	loadListErrorAction: string;
	loadOneErrorAction: string;
	upsertListErrorAction: string;
	deleteOneErrorAction: string;
	addToDirtyListAction: string;
	addToDirtyListSuccessAction: string;
};

/**
 * Get the Load List Action's Types
 * @param name State name
 */
export const getLoadActionTypes = (name: string): LoadActionTypes => ({
	loadAction: `LOAD_${name}`,
	loadListSuccessAction: `LIST_${name}_LOADED`,
	loadOneSuccessAction: `ONE_${name}_LOADED`,
	upsertListSuccessAction: `LIST_${name}_UPSERTED`,
	deleteOneSuccessAction: `ONE_${name}_DELETED`,
	loadListErrorAction: `LIST_${name}_LOAD_FAILED`,
	loadOneErrorAction: `ONE_${name}_LOAD_FAILED`,
	upsertListErrorAction: `LIST_${name}_UPSERT_FAILED`,
	deleteOneErrorAction: `ONE_${name}_DELETE_FAILED`,
	addToDirtyListAction: `ADD_${name}_TO_DIRTY_LIST`,
	addToDirtyListSuccessAction: `ADD_${name}_TO_DIRTY_LIST_SUCCESS`,
});

/**
 * Generic Type for Load List Actions
 */
export type LoadActions<T> = {
	load: (qualifier?: string) => () => { type: string };
	loadListSuccess: (
		qualifier?: string
	) => (payload: PaginatedResponse<T> | T[]) => {
		type: string;
		payload: PaginatedResponse<T> | T[];
		qualifier?: string;
	};
	loadOneSuccess: (
		qualifier?: string
	) => (payload: T) => { type: string; payload: T; qualifier?: string };
	upsertListSuccess: (
		qualifier?: string
	) => (payload: PaginatedResponse<T> | T[]) => {
		type: string;
		payload: PaginatedResponse<T> | T[];
		qualifier?: string;
	};
	deleteOneSuccess: (
		qualifier?: string
	) => (removalId: string) => { type: string; removalId: string; qualifier?: string };
	loadListError: (
		qualifier?: string
	) => (error: any) => { type: string; error: any; qualifier?: string };
	loadOneError: (
		qualifier?: string
	) => (error: any) => { type: string; error: any; qualifier?: string };
	upsertListError: (
		qualifier?: string
	) => (error: any) => { type: string; error: any; qualifier?: string };
	deleteOneError: (
		qualifier?: string
	) => (error: any) => { type: string; error: any; qualifier?: string };
	addToDirtyList: (
		qualifier?: string
	) => (dirtyModelIds: string[]) => { type: string; dirtyModelIds: string[]; qualifier?: string };
	addToDirtyListSuccess: (
		qualifier?: string
	) => (dirtyModelIds: string[]) => { type: string; dirtyModelIds: string[]; qualifier?: string };
};

/**
 * Create actions for Load List
 * @param name State name
 */
export const createLoadActions = <T>(name: string): LoadActions<T> => {
	const types = getLoadActionTypes(name);
	return {
		load: (qualifier?: string) => () => ({ type: types.loadAction, qualifier }),
		loadListSuccess: (qualifier?: string) => (payload) => ({
			payload,
			type: types.loadListSuccessAction,
			qualifier,
		}),
		loadOneSuccess: (qualifier?: string) => (payload) => ({
			payload,
			type: types.loadOneSuccessAction,
			qualifier,
		}),
		upsertListSuccess: (qualifier?: string) => (payload) => ({
			payload,
			type: types.upsertListSuccessAction,
			qualifier,
		}),
		deleteOneSuccess: (qualifier?: string) => (removalId) => ({
			removalId,
			type: types.deleteOneSuccessAction,
			qualifier,
		}),
		loadListError: (qualifier?: string) => (error: any) => ({
			error,
			type: types.loadListErrorAction,
			qualifier,
		}),
		loadOneError: (qualifier?: string) => (error: any) => ({
			error,
			type: types.loadOneErrorAction,
			qualifier,
		}),
		upsertListError: (qualifier?: string) => (error: any) => ({
			error,
			type: types.upsertListErrorAction,
			qualifier,
		}),
		deleteOneError: (qualifier?: string) => (error: any) => ({
			error,
			type: types.deleteOneErrorAction,
			qualifier,
		}),
		addToDirtyList: (qualifier?: string) => (dirtyModelIds: string[]) => ({
			dirtyModelIds,
			type: types.addToDirtyListAction,
			qualifier,
		}),
		addToDirtyListSuccess: (qualifier?: string) => (dirtyModelIds: string[]) => ({
			dirtyModelIds,
			type: types.addToDirtyListSuccessAction,
			qualifier,
		}),
	};
};
