import { IScheduledEntity } from '../responses/interface';
import BaseDataManager from './base';

class ActivityManager extends BaseDataManager {
	constructor() {
		super();
	}
	/**
	 * Submit note to linked in
	 * @param {string} contactId contact id
	 * @param {string} orgMemberId message content
	 * @param {string} note message content
	 */
	submitNoteToContact(contactId: string, orgMemberId: string, note: string): any {
		const url = `${this.BACKEND_ROUTES.CONTACT.Default}/${contactId}/notes`;
		const noteData = { orgMemberId, message: note };
		return this.RequestManager.post(url, noteData)
			.then((response) => response.data)
			.catch((error) => {
				throw error;
			});
	}

	/**
	 *Delete note from linked in
	 * @param {string} contactId contact id
	 * @param {any} note note content
	 */
	deleteNotesFromContact(contactId: string, note: any): any {
		const url = `${this.BACKEND_ROUTES.CONTACT.Default}/${contactId}/notes/delete/${note.id}`;
		return this.RequestManager.post(url, note)
			.then(() => true)
			.catch((error) => {
				console.log(error);
				throw error;
			});
	}

	/**
	 * Submit scheduled msg to linked in
	 * @param {string} contactId contact id
	 * @param {string} orgMemberId message content
	 * @param {string} message message content
	 * @param {string} date date to send reminder
	 */
	submitScheduledMsgToLinkedInProfile(
		contactId: string,
		orgMemberId: string,
		message: string,
		date: string,
		templateId?: string,
		campaignId?: string
	) {
		const url = `${this.BACKEND_ROUTES.CONTACT.Default}/${contactId}/reminders`;
		const messageData = {
			orgMemberId,
			message,
			triggerDateTime: date,
			templateId,
			campaignId,
		};
		return this.RequestManager.post<IScheduledEntity>(url, messageData)
			.then((response) => response.data)
			.catch((error) => {
				throw error;
			});
	}

	/**
	 * delete scheduled msg from linked in
	 * @param {string} contactId contact id for message
	 * @param {string} reminderId  reminder object id
	 */
	deleteScheduledMsgFromLinkedInProfile(contactId: string, reminderId: string): any {
		const url = `${this.BACKEND_ROUTES.CONTACT.Default}/${contactId}/reminders/delete/${reminderId}`;
		return this.RequestManager.post(url)
			.then(() => true)
			.catch((error) => {
				console.log(error);
				throw error;
			});
	}
}

const instance = new ActivityManager();
export default instance;
