import React, { useState } from 'react';
import { Input, Form, Typography, Spin } from 'antd';
import { SearchUrlType } from '@copilot/common/utils/constant';
import { SearchUrlValidator } from '@copilot/common/components/forms/common/validators';
import MultipleInviteToggle from '../multipleInvite';
import { MultipleInvite } from '@copilot/data/responses/interface';
import { isSearchUrlValid } from '@copilot/common/utils';
import styles from './update.module.less';
import { SEARCH_STATUSES, SearchStatus } from './consts';
import { ExclamationCircleFilled, LoadingOutlined, CheckCircleFilled } from '@ant-design/icons';
import isNil from 'lodash/isNil';

const { Link, Paragraph } = Typography;

type SearchUrlUpdateModuleProps = {
	searchType: SearchUrlType;
	isInitialSearch: boolean;
	searchStatus?: SearchStatus;
	showVideoLink: boolean;
	saveAndSearchButton: (searchUrl: string) => JSX.Element;
	onVideoLinkClicked: () => void;
	saveDisableWarning?: React.ReactNode;
	multipleInvite?: MultipleInvite;
	updateMultipleInvite?: (multipleInvite: MultipleInvite) => void;
};

/**
 * Update section for the search url module
 * @param {SearchUrlType} searchType search type of the search url module
 * @param {boolean} isInitialSearch whether the current search is the initial search for the user
 * @param {number} searchPercentage flag to show the saving state of the save button and prevent further clicks
 * @param {boolean} showVideoLink whether to show the video link
 * @param {function} saveAndSearchButton button that gets clicked for save and search
 * @param {function} onVideoLinkClicked handles video link clicks
 * @param {ReactNode} saveDisableWarning warning to be displayed when a user isn't be able to save & search
 * @param {MultipleInvite} multipleInvite multiple invite value, prop for optional multiple invite toggle
 * @param {function} updateMultipleInvite handles multiple invite changes, prop for optional multiple invite toggle
 */
function SearchUrlUpdateModule({
	searchType,
	isInitialSearch,
	searchStatus = SEARCH_STATUSES.NOT_SEARCHING,
	showVideoLink,
	saveAndSearchButton,
	onVideoLinkClicked,
	saveDisableWarning,
	multipleInvite,
	updateMultipleInvite,
}: SearchUrlUpdateModuleProps) {
	const isSearching =
		searchStatus === SEARCH_STATUSES.SEARCHING_CURRENT_CAMPAIGN ||
		searchStatus === SEARCH_STATUSES.SEARCHING_OTHER_CAMPAIGN;
	const [searchUrl, setSearchUrl] = useState<string>('');

	const isProspectingCampaign = searchType === SearchUrlType.Search;

	return (
		<>
			<div className={styles.submissionSectionWrapper}>
				<div className={styles.searchInputLabel}>
					{isProspectingCampaign
						? 'Enter search URL'
						: 'Add more connections from a Search URL'}
				</div>
				<Form>
					<Form.Item
						name="searchUrl"
						className={styles.searchBarWrapper}
						rules={[
							{
								validator: SearchUrlValidator,
							},
						]}
					>
						<Input
							className={styles.searchInput}
							type="text"
							value={searchUrl}
							placeholder="Search url..."
							onChange={(e) => setSearchUrl(e.target.value)}
							disabled={isSearching}
						/>
					</Form.Item>
				</Form>
				<div>{saveAndSearchButton(searchUrl)}</div>
				<div>{!isNil(saveDisableWarning) && saveDisableWarning}</div>
				{!isSearching && multipleInvite && updateMultipleInvite && (
					<div>
						<MultipleInviteToggle
							toggleLabel="Duplicate prospects"
							tooltipLabel="Include prospects who've been collected by other team members"
							multipleInvite={multipleInvite}
							disabled={!isSearchUrlValid(searchUrl)}
							onUpdate={updateMultipleInvite}
						/>
					</div>
				)}
			</div>

			<div className={styles.descriptionWrapper}>
				<SearchListDescriptionText
					searchType={searchType}
					isSearching={isSearching}
					showVideoLink={showVideoLink}
					onVideoLinkClicked={onVideoLinkClicked}
					isInitialSearch={isInitialSearch}
					searchStatus={searchStatus}
				/>
			</div>
		</>
	);
}
export default SearchUrlUpdateModule;

type SearchListDescriptionTextProps = {
	searchType: SearchUrlType;
	isSearching: boolean;
	searchStatus: SearchStatus;
	showVideoLink: boolean;
	onVideoLinkClicked: () => void;
	isInitialSearch: boolean;
};

function SearchListDescriptionText({
	searchType,
	isSearching,
	showVideoLink,
	onVideoLinkClicked,
	isInitialSearch,
	searchStatus,
}: SearchListDescriptionTextProps) {
	if (isSearching) {
		if (searchStatus === SEARCH_STATUSES.SEARCHING_CURRENT_CAMPAIGN) {
			return (
				<div className={styles.searchListStatusWrapper}>
					<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					<Paragraph>
						We're loading your search. This will take roughly 5 minutes to load. You can
						navigate away from this tab in the meantime while you wait and refresh it
						after 5-10 minutes.
					</Paragraph>
				</div>
			);
		} else {
			return (
				<div className={styles.searchListStatusWrapper}>
					<ExclamationCircleFilled className={styles.warningIcon} size={24} />
					<Paragraph>
						<b>Searchlist Queue:</b> You’re currently loading another searchlist in
						another campaign. Please wait until it’s complete before loading this one.
					</Paragraph>
				</div>
			);
		}
	}

	if (searchStatus === SEARCH_STATUSES.SEARCH_COMPLETE) {
		return (
			<div className={styles.searchListStatusWrapper}>
				<CheckCircleFilled className={styles.successIcon} size={24} />
				<Paragraph>
					Your search list has successfully loaded into your campaign. All set!
				</Paragraph>
			</div>
		);
	}

	if (searchStatus === SEARCH_STATUSES.SEARCH_ERROR) {
		return (
			<div className={styles.searchListStatusWrapper}>
				<ExclamationCircleFilled className={styles.errorIcon} size={24} />
				<Paragraph>
					Unable to complete the search due to a system error. Please try again or contact
					support.
				</Paragraph>
			</div>
		);
	}

	if (searchType === SearchUrlType.Nurture) {
		return (
			<>
				<Paragraph>
					{!isInitialSearch &&
						'Input a Sales Navigator URL above to import connections to this Nurture List. '}
					Please note, prospects are now going to be removed from their current campaign
					and sent to the selected campaign. All previous automations and scheduled
					message will <strong>NOT</strong> occur.
				</Paragraph>
				{showVideoLink && (
					<Paragraph>
						Be sure to follow our{' '}
						<Link onClick={onVideoLinkClicked}>BEST PRACTICES</Link> for creating the
						search URL.
					</Paragraph>
				)}
			</>
		);
	}

	return null;
}
