import { AntdTheme } from '@copilot/common/config';
import { Button, Popover, Typography } from 'antd';
import { PlayCircleFilled } from '@ant-design/icons';
import { useState } from 'react';
import { AutomationTemplate } from './types';
import styles from './resumeAutomationButton.module.less';
import { TextEditorContextActions, useTextEditorContext } from '../textEditor/context';
import { InboxPageCardViewTrackingParams } from '@copilot/common/pages/inbox/tracking/cardView';

type ResumeAutomationButtonProps = {
	automatedSteps: AutomationTemplate[];
	onCardTracking: ((params: InboxPageCardViewTrackingParams) => void) | undefined;
	disabled?: boolean;
};

/**
 * Button to resume automation, warns user about consequences
 * @param param0
 * @returns
 */
export default function ResumeAutomationButton({
	automatedSteps,
	onCardTracking,
	disabled,
}: ResumeAutomationButtonProps) {
	const { automatedStepId, dispatchUpdate } = useTextEditorContext();

	const [isResumeAutomationPopoverVisible, setIsResumeAutomationPopoverVisible] =
		useState<boolean>(false);

	function onPopulateAutomationData(automationStep: AutomationTemplate) {
		dispatchUpdate({
			type: TextEditorContextActions.setAutomatedStep,
			payload: { text: automationStep.message, automatedStepId: automationStep.id },
		});
		onCardTracking?.({ buttonClicked: 'Resume Automation' });
	}

	const isInResumeAutomationMode = automatedStepId !== undefined;

	if (isInResumeAutomationMode) {
		return null;
	}

	return (
		<Popover
			placement="topRight"
			content={
				<>
					<div className={styles.contentWrapper}>
						<PlayCircleFilled
							className={styles.contentIcon}
							style={{
								color: AntdTheme.token?.colorPrimary,
							}}
						/>
						<div>
							<Typography.Text className={styles.contentTitle}>
								Resume automation
							</Typography.Text>
							<Typography.Text className={styles.contentBody}>
								By continuing, you'll return to automation at{' '}
								{automatedSteps[0].name}. Follow-up messages will automatically send
								if this person doesn't reply.
							</Typography.Text>
						</div>
					</div>

					<div className={styles.buttonSection}>
						<Button
							onClick={() => setIsResumeAutomationPopoverVisible(false)}
							size="small"
						>
							Cancel
						</Button>
						<Button
							type="primary"
							size="small"
							onClick={() => {
								setIsResumeAutomationPopoverVisible(false);
								onPopulateAutomationData(automatedSteps[0]);
							}}
						>
							Continue
						</Button>
					</div>
				</>
			}
			trigger="click"
			open={isResumeAutomationPopoverVisible}
		>
			<Button
				icon={<PlayCircleFilled />}
				type="text"
				className={styles.resumeButton}
				disabled={disabled}
				onClick={() => setIsResumeAutomationPopoverVisible(true)}
			>
				Resume automation
			</Button>
		</Popover>
	);
}
