import { useTheme } from 'styled-components';
import { BLANK_STATE_STRING } from '@copilot/common/constant/campaignConst';
import { PercentageRingGraph } from '@copilot/common/components/charts/simple/percentageRingGraph/index';
import { ComponentProps } from 'react';

type DefaultPercentageRingGraphProps = Omit<
	ComponentProps<typeof PercentageRingGraph>,
	'renderText' | 'textColour' | 'value' | 'ringColour'
> & {
	/**
	 * Colour of percentage text
	 */
	textColour?: string;

	/**
	 * Renderer for text within ring
	 */
	renderText?: (value: number) => string;
};

/**
 * PercentageRingGraph with no value
 * @param props
 * @constructor
 */
export function DefaultPercentageRingGraph(props: DefaultPercentageRingGraphProps) {
	const theme = useTheme();

	const { renderText = () => BLANK_STATE_STRING, textColour = theme['@Neutral/30'] } = props;

	return (
		<PercentageRingGraph
			{...props}
			value={0}
			renderText={renderText}
			textColour={textColour}
			ringColour={'#000'}
		/>
	);
}
