import BaseModal, { BaseModalProps } from '@copilot/common/components/modals/wrappers/base';
import { Button, Checkbox, Space, Typography } from 'antd';
import { useState, MouseEvent } from 'react';
import { useTheme } from 'styled-components';
import { fontSizeMD } from '@copilot/common/constant/commonStyles';
import { useTermsOfUse } from '@copilot/common/hooks/termsOfUse';
import { TermsOfUse } from '@copilot/data/responses/interface';
import { AIFeatureManager } from '@copilot/data';

export type AIFeatureTermsOfUseModalProps = BaseModalProps & {
	/** Callback when the user accept the terms and conditions */
	onSubmit?: () => void;
};
function AIFeatureTermsOfUseModal(props: AIFeatureTermsOfUseModalProps) {
	const { onCancel, onSubmit } = props;
	const theme = useTheme();
	const [checked, setChecked] = useState(false);
	const { acceptTermsOfUse, isLoading } = useTermsOfUse(
		TermsOfUse.AIFeature,
		AIFeatureManager.acceptTermsOfUse
	);

	function handleSubmit(event: MouseEvent<HTMLButtonElement>) {
		acceptTermsOfUse().then(() => {
			onSubmit?.();
			onCancel?.(event);
		});
	}

	return (
		<BaseModal
			{...props}
			width={theme['@screen-sm-max']}
			title={'Terms of use'}
			footer={[
				<Button
					key="submit"
					type="primary"
					onClick={handleSubmit}
					loading={isLoading}
					disabled={!checked}
				>
					Submit
				</Button>,
				<Button key="cancel" onClick={onCancel} disabled={isLoading}>
					Cancel
				</Button>,
			]}
			styles={{
				body: { padding: `0px ${theme['@spacer-size-md']}`, fontSize: fontSizeMD },
				header: {
					padding: `${theme['@spacer-size-sm']} ${theme['@spacer-size-md']}`,
					fontSize: fontSizeMD,
				},
				footer: {
					padding: `${theme['@spacer-size-sm']} ${theme['@spacer-size-sm']}`,
					textAlign: 'center',
					fontSize: fontSizeMD,
				},
			}}
			centered
		>
			<Space direction={'vertical'} size={theme['@spacer-num-sm']}>
				<Typography.Text>
					We prioritize your data security and privacy. Your data will be handled in
					accordance with our privacy policy and OpenAI’s data usage policies. We don’t
					and won’t sell your data.
				</Typography.Text>
				<Typography.Text>
					To improve our product’s functionality and to provide you with the best
					recommendations, we may need to process some of your data. This includes but is
					not limited to LinkedIn profile data and interactions within the application.
				</Typography.Text>
				<Typography.Text>
					To enhance your experience, some of our offerings use AI models provided by
					OpenAI and other trusted third-party vendors.
				</Typography.Text>
				<Typography.Text>
					By checking the box below, you agree that your information may be shared with
					these third-party vendors for the purpose of delivering on our offerings.
				</Typography.Text>
			</Space>
			<div style={{ textAlign: 'center', paddingTop: theme['@spacer-size-md'] }}>
				<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
					I consent
				</Checkbox>
			</div>
		</BaseModal>
	);
}

export default AIFeatureTermsOfUseModal;
