import { IModal, ModalStatus } from '../models/redux/modal';
import { EntitiesActionTypes } from './types';

const itemType = 'Modal';

export namespace ModalActions {
	export const loadModal = (modal: IModal | IModal[]) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: modal,
	});

	export const closeModal = (modalId: IModal['id']) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: { id: modalId, status: ModalStatus.Closing },
	});

	export const removeModal = (modalId: IModal['id']) => ({
		type: EntitiesActionTypes.Delete,
		itemType,
		payload: { id: modalId },
	});
}
