import {
	BarChartOutlined,
	FileTextOutlined,
	ProfileOutlined,
	ScheduleOutlined,
	SearchOutlined,
	SettingOutlined,
	SolutionOutlined,
	TeamOutlined,
	ExclamationCircleFilled,
	CheckCircleFilled,
	LoadingOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';
import styles from './campaign.module.less';

export enum CampaignDashboardTabKeys {
	Summary = 'summary',
	TeamMembers = 'teammembers',
	SearchList = 'searchlist',
	NurtureList = 'nurturelist',
	Sequence = 'sequence',
	InMailSequence = 'inmailSequence',
	Template = 'template',
	Schedule = 'schedule',
	Settings = 'settings',
}

const CampaignDashboardTabIcons: Record<CampaignDashboardTabKeys, React.ReactNode> = {
	[CampaignDashboardTabKeys.Summary]: <BarChartOutlined />,
	[CampaignDashboardTabKeys.TeamMembers]: <TeamOutlined />,
	[CampaignDashboardTabKeys.SearchList]: <SearchOutlined />,
	[CampaignDashboardTabKeys.NurtureList]: <SolutionOutlined />,
	[CampaignDashboardTabKeys.Sequence]: <ProfileOutlined />,
	[CampaignDashboardTabKeys.InMailSequence]: <ProfileOutlined />,
	[CampaignDashboardTabKeys.Template]: <FileTextOutlined />,
	[CampaignDashboardTabKeys.Schedule]: <ScheduleOutlined />,
	[CampaignDashboardTabKeys.Settings]: <SettingOutlined />,
};

const CampaignDashboardTabTitles: Record<CampaignDashboardTabKeys, string> = {
	[CampaignDashboardTabKeys.Summary]: 'Summary',
	[CampaignDashboardTabKeys.TeamMembers]: 'Team Members',
	[CampaignDashboardTabKeys.SearchList]: 'Search List',
	[CampaignDashboardTabKeys.NurtureList]: 'Nurture List',
	[CampaignDashboardTabKeys.Sequence]: 'Automated Messaging',
	[CampaignDashboardTabKeys.InMailSequence]: 'InMail Messaging',
	[CampaignDashboardTabKeys.Template]: 'Quick Responses',
	[CampaignDashboardTabKeys.Schedule]: 'Timezone and Sending Hours',
	[CampaignDashboardTabKeys.Settings]: 'Settings',
};

const CampaignDashboardPluralTabTitles: Record<CampaignDashboardTabKeys, string> = {
	...CampaignDashboardTabTitles,
	[CampaignDashboardTabKeys.SearchList]: 'Search Lists',
	[CampaignDashboardTabKeys.NurtureList]: 'Nurture Lists',
};

type CampaignDashboardTabLabelProps = {
	/**
	 * key of the tab we want to get the label for	tabKey: CampaignDashboardTabKeys;
	 */
	tabKey: CampaignDashboardTabKeys;
	/**
	 * whether we want to show the plural version of the title
	 */
	usePlural?: boolean;
	hasError?: boolean;
	hasSuccess?: boolean;
	isLoading?: boolean;
};
/**
 * Displays the proper tab label given a tabkey
 * @param {CampaignDashboardTabLabelProps} props
 */
export function CampaignDashboardTabLabel({
	tabKey,
	usePlural = false,
	hasError = false,
	hasSuccess,
	isLoading,
}: CampaignDashboardTabLabelProps) {
	return (
		<div className={styles.tabWrapper}>
			{CampaignDashboardTabIcons[tabKey]}{' '}
			{usePlural
				? CampaignDashboardPluralTabTitles[tabKey]
				: CampaignDashboardTabTitles[tabKey]}
			<div className={styles.indicator}>
				{hasError && <ExclamationCircleFilled className={styles.errorIcon} />}
				{hasSuccess && <CheckCircleFilled className={styles.successIcon} />}
				{isLoading && (
					<Spin
						key="spinner"
						spinning
						indicator={<LoadingOutlined className={styles.spinner} />}
					/>
				)}
			</div>
		</div>
	);
}
