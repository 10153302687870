import { ReactElement } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import {
	ACCOUNT_CREATION_INPUT_ERROR_MESSAGE,
	COMPANY_INFORMATION_TOOLTIP,
	PRIMARY_CONTACT_DESCRIPTION,
} from '../copy';
import {
	ACCOUNT_CREATION_INPUT_FIELDS,
	AccountCreationInput,
	Permissions,
} from '@copilot/common/hooks/account/accountCreation/accountCreationTypes';
import { getContentByMember } from '@copilot/common/components/editors/orgMemberInfo/const';
import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import Section from '@copilot/common/components/drawer/wrappers/accountDashboard/section';
import AccountCreationFormPanel from './accountCreationFormPanel';
import { IAccountManager } from '@copilot/data/graphql/_generated';
import { formatName } from '@copilot/common/utils';
import styles from './accountCreationForm.module.less';

/**
 * Props used for the Account Creation form
 */
type AccountCreationFormProps = Readonly<{
	/* The form */
	form: FormInstance<AccountCreationInput>;
	/** List of account mangers */
	accountManagers?: Readonly<IAccountManager[]>;
	/** Callback when the form finishes */
	onFinish: (values: AccountCreationInput) => void;
}>;

/**
 * Component for the body of the Account Creation form
 * @param props
 */
export function AccountCreationForm({
	form,
	onFinish,
	accountManagers,
}: AccountCreationFormProps): ReactElement {
	return (
		<Form form={form} onFinish={onFinish} className={styles.form}>
			<Section
				title="Company Information"
				description={COMPANY_INFORMATION_TOOLTIP}
				body={
					<>
						<label htmlFor={ACCOUNT_CREATION_INPUT_FIELDS.accountName}>
							Account Name
						</label>
						<Form.Item
							name={ACCOUNT_CREATION_INPUT_FIELDS.accountName}
							style={{ marginBottom: 0 }}
							rules={[
								{
									required: true,
									whitespace: true,
									message: ACCOUNT_CREATION_INPUT_ERROR_MESSAGE,
								},
							]}
						>
							<Input />
						</Form.Item>
					</>
				}
			/>

			<Section
				title="Primary Contact Information"
				description={PRIMARY_CONTACT_DESCRIPTION}
				body={
					<>
						<Row>
							<Col span={12} className={styles.formItemFirstCol}>
								<label htmlFor={ACCOUNT_CREATION_INPUT_FIELDS.firstName}>
									First Name
								</label>
								<Form.Item
									name={ACCOUNT_CREATION_INPUT_FIELDS.firstName}
									style={{ marginBottom: 0 }}
									rules={[
										{
											required: true,
											whitespace: true,
											message: ACCOUNT_CREATION_INPUT_ERROR_MESSAGE,
										},
									]}
								>
									<Input />
								</Form.Item>
							</Col>

							<Col span={12}>
								<label htmlFor={ACCOUNT_CREATION_INPUT_FIELDS.lastName}>
									Last Name
								</label>
								<Form.Item
									name={ACCOUNT_CREATION_INPUT_FIELDS.lastName}
									style={{ marginBottom: 0 }}
									rules={[
										{
											required: true,
											whitespace: true,
											message: ACCOUNT_CREATION_INPUT_ERROR_MESSAGE,
										},
									]}
								>
									<Input />
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={12} className={styles.formItemFirstCol}>
								<label htmlFor={ACCOUNT_CREATION_INPUT_FIELDS.email}>Email</label>
								<Form.Item
									name={ACCOUNT_CREATION_INPUT_FIELDS.email}
									style={{ marginBottom: 0 }}
									rules={[
										{
											required: true,
											type: 'email',
											message: 'Please enter a valid email.',
										},
									]}
								>
									<Input />
								</Form.Item>
							</Col>

							<Col span={12}>
								<label htmlFor={ACCOUNT_CREATION_INPUT_FIELDS.permission}>
									Permission
								</label>
								<Form.Item
									name={ACCOUNT_CREATION_INPUT_FIELDS.permission}
									style={{ marginBottom: 0 }}
									rules={[{ required: true }]}
									initialValue={Permissions.Admin}
								>
									<Input disabled />
								</Form.Item>
							</Col>
						</Row>
					</>
				}
			/>
			{accountManagers && (
				<Section
					title="Account Management"
					description="Assign an Account Manager to quickly filter and find accounts they're responsible for managing."
					body={
						<>
							<label htmlFor={ACCOUNT_CREATION_INPUT_FIELDS.accountManagerId}>
								Assigned Account Manager <span>(optional)</span>
							</label>
							<Form.Item name={ACCOUNT_CREATION_INPUT_FIELDS.accountManagerId}>
								<Select
									placeholder={'None'}
									style={{ width: '100%' }}
									options={[
										{
											value: null,
											label: 'None',
										},
										...accountManagers.map((a) => ({
											label: formatName(a.firstName, a.lastName),
											value: a.id,
										})),
									]}
								/>
							</Form.Item>
						</>
					}
				/>
			)}
			{AccountCreationFormPanel(
				'Prospecting Tool Permissions',
				getContentByMember(OrgRoleTitles.Admin)
			)}
		</Form>
	);
}
