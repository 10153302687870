export enum RoleTitles {
	Admin = 'Admin',
	Member = 'Member',
	System = 'System',
	CustomerSuccess = 'CustomerSuccess',
	VirtualAssistant = 'VirtualAssistant',
	User = 'User',
}

export enum OrgRoleTitles {
	Owner = 'OrgOwner',
	Admin = 'OrgAdmin',
	User = 'OrgUser',
	Advanced = 'OrgAdvanced',
}

export enum AdminMemberSelectionTypes {
	CustomUser = 'Custom User',
	AdminUser = 'Admin User',
	BaseUser = 'Base User',
}

export enum CampaignRoleTitles {
	Admin = 'CampaignAdmin',
	Manager = 'CampaignManager',
	Member = 'CampaignMember',
}

const MemberRoles = Object.values(RoleTitles).filter((r) => r !== RoleTitles.System);
const OrganizationRoles = Object.values(OrgRoleTitles);
// const CampaignRoles = Object.values(CampaignRoleTitles);

export namespace RoleGroups {
	export const All = [undefined, ...OrganizationRoles];
	export const Guest = [undefined];
	export const Member = [...OrganizationRoles];
	export const Admin = [OrgRoleTitles.Admin];
}

export namespace UserRoleGroups {
	export const All = [undefined, ...MemberRoles];
	export const Guest = [undefined];
	export const Member = [...MemberRoles];
	export const Admin = [RoleTitles.Admin];
	export const CustomerSuccess = [...UserRoleGroups.Admin, RoleTitles.CustomerSuccess];
	export const VirtualAssistant = [
		...UserRoleGroups.CustomerSuccess,
		RoleTitles.VirtualAssistant,
	];
}
