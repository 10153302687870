import { ComponentProps, CSSProperties, useState } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import PerformanceChartInternal from './chart';
import { spacingMD, fontWeightMedium } from '@copilot/common/constant/commonStyles';

const Copy = {
	title: 'How recent changes have impacted your campaign',
};

const TimeRangeLabels: Record<keyof typeof TimeRanges, string> = {
	fourWeeks: 'Last 4 weeks',
} as const;

const TimeRanges = {
	/**
	 * 4 weeks
	 */
	fourWeeks: 'fourWeeks',
} as const;

/**
 * Default to be 4 weeks
 */
const DefaultTimeRange = TimeRanges.fourWeeks;

export type PerformanceChartPropsType = Pick<
	ComponentProps<typeof PerformanceChartInternal>,
	'data' | 'events' | 'dataKeys' | 'start' | 'end'
> & {
	/**
	 * Height of the graph
	 */
	height: CSSProperties['height'];
};

/**
 * [Presentational] Campaign performance tracking chart with campaign events.
 * @param props
 * @constructor
 */
function PerformanceChart(props: PerformanceChartPropsType) {
	const { height, events = [], ...rest } = props;
	const [timeRange] = useState<keyof typeof TimeRanges>(DefaultTimeRange);
	return (
		<div style={{ height, width: '100%', userSelect: 'none' }}>
			<Row justify={'space-between'}>
				<Col>
					<Typography.Text
						style={{ fontWeight: fontWeightMedium, paddingLeft: spacingMD }}
					>
						{Copy.title}
					</Typography.Text>
				</Col>
				<Col>
					<Space>
						<CalendarOutlined />
						<Typography.Text>{TimeRangeLabels[timeRange]}</Typography.Text>
					</Space>
				</Col>
			</Row>
			<PerformanceChartInternal {...rest} events={events} width={'100%'} height={'90%'} />
		</div>
	);
}

export default PerformanceChart;
