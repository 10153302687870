import React, { MouseEventHandler, useMemo, useRef, useState } from 'react';
import { Button, Carousel } from 'antd';
import CarouselItem from './carouselItem';
import styled from 'styled-components';
import { CarouselProps } from 'antd/lib/carousel';

type CarouselContents = {
	topText: string;
	titleText: string;
	bodyText: string;
	image: string;
	isSkipVisible: boolean;
};

export interface ICarouselWithButtonProps {
	carouselItems: CarouselContents[];
	finalButtonText: string;
	skipButtonText: string;
	onFinish?: MouseEventHandler<HTMLElement>;
	onSkip?: MouseEventHandler<HTMLElement>;
	afterChange?: CarouselProps['afterChange'];
}

// Adds visibility to the dots on the carousel (otherwise non-active slides are invisible)
const StyledCarousel = styled(Carousel)`
	${(props) => {
		const prefix = props.theme['@ant-prefix'];
		return `
        .${prefix}-carousel &.slick-slider .slick-dots li button {
            background: gray;
        }
        .${prefix}-carousel &.slick-slider .slick-dots li.slick-active button {
            background: black;
        }
        `;
	}}
`;

/**
 * [Presentational] Renders a carousel w/ identical item formats
 * @param props
 * @param carouselItems - a list of the content to populate each slide with
 * @param finalButtonText - the text displayed by the last button of the carousel
 * @param onFinish - function run upon finishing the carousel
 * @param onSkip callback for clicking skip
 * @returns a carousel w/ identical item formats
 */
const CarouselWithButton: React.FC<ICarouselWithButtonProps> = (props) => {
	const { carouselItems, finalButtonText, skipButtonText, onFinish, onSkip, afterChange } = props;
	const [currentSlide, setCurrentSlide] = useState(0);
	const [isSkipVisible, setSkipVisible] = useState(carouselItems[0].isSkipVisible);
	const carouselRef = useRef<any>(null);
	const lastSlide = carouselItems.length - 1;

	// Move the carousel one slide forward
	const nextSlide = () => {
		carouselRef.current.next();
	};

	// Update currentSlide & isSkipVisible based on current slide
	const updateSlideState = (current: number) => {
		setCurrentSlide(current);
		afterChange?.(current);
		setSkipVisible(carouselItems[current].isSkipVisible);
	};

	// Chose what buttons to render based off current slide
	const progressButton = useMemo(() => {
		const text = currentSlide === lastSlide ? finalButtonText : 'Next';
		const action = currentSlide === lastSlide ? onFinish : nextSlide;
		const isVisible = isSkipVisible ? 'visible' : 'hidden';

		return (
			<>
				<Button
					type="primary"
					size="large"
					onClick={action}
					block
					style={{ marginBottom: '15px' }}
				>
					{text}
				</Button>
				<Button
					type="text"
					style={{ float: 'right', padding: '0px', visibility: isVisible }}
					onClick={onSkip}
				>
					{skipButtonText}
				</Button>
			</>
		);
	}, [isSkipVisible, currentSlide, onFinish, onSkip, nextSlide]);

	// Create carousel populated with carouselItem
	const carousel = (
		<StyledCarousel dotPosition="bottom" ref={carouselRef} afterChange={updateSlideState}>
			{carouselItems.map((slide) => (
				<CarouselItem
					key={slide.titleText}
					topText={slide.topText}
					titleText={slide.titleText}
					bodyText={slide.bodyText}
					image={slide.image}
				/>
			))}
		</StyledCarousel>
	);

	return (
		<div style={{ margin: '20px', marginBottom: '40px' }}>
			{carousel}
			{progressButton}
		</div>
	);
};

export default CarouselWithButton;
export type { CarouselContents };
