import React, { MouseEventHandler } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export interface IFilterDropdownProps {
	isSelected: boolean;
	label: string;
	onClick?: MouseEventHandler<any>;
}

const FilterDropdown: React.FC<IFilterDropdownProps> = (props) => {
	const { isSelected, label, onClick } = props;
	return (
		<Button
			type={isSelected ? 'primary' : 'default'}
			ghost={isSelected}
			style={{ textAlign: 'left' }}
			onClick={onClick}
		>
			{label}{' '}
			<DownOutlined
				style={{ float: 'right', marginTop: '6px', color: '#bfbfbf', fontSize: '75%' }}
			/>
		</Button>
	);
};

export default FilterDropdown;
