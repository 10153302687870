import { useState } from 'react';
import { CampaignPerformanceContainer } from './campaignPerformance/campaignPerformanceContainer';
import PerformanceChartContainer from './performanceChartContainer';
import { ModelState } from '@copilot/common/store/models/fetch';
import TeamMemberDropdown from '../../../components/dropdown/teamMemberDropdown';
import { Space } from 'antd';
import { updateSearchListSelectedMemberId } from '../searchList/data/actions';
import { useDispatch, useSelector } from 'react-redux';
import { campaignSummaryMemberSelector } from '../searchList/data/selector';
import _ from 'lodash';
import AttentionRequiredContainer from './attentionRequiredContainer';
import {
	getStartOfDay,
	last30DaysOption,
	SelectedDateFilter,
} from '@copilot/common/pages/campaignDashboard/summaryV2/dataFilterUtils';
import moment from 'moment/moment';

const INITIAL_DATE_OPTION = last30DaysOption;
const TEAM_MEMBER_PAGE_ROUTE = '/?tab=teammembers' as const;
const LINKEDIN_SETTING_PAGE_ROUTE = '/settings/linkedin' as const;

type MemberName = {
	id: string;
	orgMemberId: string;
	firstName: string;
	lastName: string;
};

type CampaignDashboardSummaryV2Props = {
	/**
	 * Campaign id
	 */
	id: string;
	/**
	 * Current campaign team members
	 */
	members: ModelState<MemberName>;
	/**
	 * The current active member
	 */
	activeMember: {
		/**
		 * Member's id
		 */
		id: string;
		/**
		 * if the member is orgAdmin or above
		 */
		isOrgAdmin: boolean;
		/**
		 * organization Id
		 */
		organizationId: string;
	};
};

function CampaignDashboardSummaryV2({
	id,
	members,
	activeMember,
}: CampaignDashboardSummaryV2Props) {
	const dispatch = useDispatch();

	const updateSelectedMemberIdInCampaign = _.partial(updateSearchListSelectedMemberId, id);
	const updateSelectedMemberId = (memberId: string | undefined) =>
		dispatch(updateSelectedMemberIdInCampaign(memberId));
	const selectedMemberSelector = campaignSummaryMemberSelector(id);
	const selectedCampaignMember = useSelector(selectedMemberSelector);
	const activeMemberCampaignMemberId = members.data.find(
		(member) => member.orgMemberId == activeMember.id
	)?.id;
	const selectedCampaignMemberId = activeMember.isOrgAdmin
		? selectedCampaignMember?.campaignMemberId
		: activeMemberCampaignMemberId;
	const orgMemberId = activeMember.isOrgAdmin
		? selectedCampaignMember?.orgMemberId ?? ''
		: activeMember.id;

	const [ringsDateFilter, setRingsDateFilter] = useState<SelectedDateFilter>(() => ({
		dateOption: INITIAL_DATE_OPTION,
		selectedDates: {
			start: moment(getStartOfDay(INITIAL_DATE_OPTION.value.valueOf())),
		},
	}));

	return (
		<Space direction="vertical" size="small" style={{ width: '100%' }}>
			{activeMember.isOrgAdmin ? (
				<TeamMemberDropdown
					members={members}
					onSelect={updateSelectedMemberId}
					selectedCampaignMember={selectedCampaignMember}
				/>
			) : null}
			<AttentionRequiredContainer
				campaignId={id}
				campaignMemberId={selectedCampaignMemberId ?? ''}
				organizationId={activeMember.organizationId}
				memberName={selectedCampaignMember?.firstName ?? ''}
				orgMemberId={orgMemberId}
				linkedInReconnectRedirectPath={
					activeMember.isOrgAdmin ? TEAM_MEMBER_PAGE_ROUTE : LINKEDIN_SETTING_PAGE_ROUTE
				}
			/>
			<CampaignPerformanceContainer
				campaignMemberId={selectedCampaignMemberId ?? ''}
				dateFilter={ringsDateFilter}
				setDateFilter={setRingsDateFilter}
			/>
			<PerformanceChartContainer campaignMemberId={selectedCampaignMemberId ?? ''} />
		</Space>
	);
}

export default CampaignDashboardSummaryV2;
