import { SmartReply } from '../messageForm/smartReply/smartReply';
import {
	EditSmartReplyCallbackType,
	EditSmartReplyQueryType,
	WriteSmartReplyCallbackType,
	WriteSmartReplyQueryType,
} from '@copilot/common/hooks/smartReply/smartReplyTypes';
import {
	TextEditorContextActions,
	useTextEditorContext,
} from '@copilot/common/components/forms/textEditor/context';
import { useEffect } from 'react';
import { isNil } from 'lodash';
import { isEmptyString } from '@copilot/common/utils/common';
import { SmartReplyPromptCategory } from '../messageForm/smartReply/promptMenu/promptMenuTypes';

/** Props for Text Editor Smart Reply Component */
export interface ITextEditorSmartReplyProps {
	isActive: boolean;
	canRegenerate: boolean;
	onRegenerate: () => void;
	setIsActive: (isActive: boolean) => void;
	onWrite: WriteSmartReplyCallbackType;
	data: WriteSmartReplyQueryType | EditSmartReplyQueryType;
	onEdit: EditSmartReplyCallbackType;
	onOutOfCredit: () => void;
	isAvailable: boolean;
	onNotAvailable: () => void;
	smartReplyPromptCategory?: SmartReplyPromptCategory;
}

/**
 * SmartReply component for the Text Editor
 * @param props
 * @returns
 */
export function TextEditorSmartReply(props: ITextEditorSmartReplyProps): JSX.Element {
	const {
		isActive,
		canRegenerate,
		onRegenerate,
		setIsActive,
		onWrite,
		data,
		onEdit,
		onOutOfCredit,
		isAvailable,
		onNotAvailable,
		smartReplyPromptCategory,
	} = props;

	const { dispatchUpdate, text, disabled } = useTextEditorContext();

	useEffect(() => {
		if (isNil(data.data)) return;
		dispatchUpdate({ type: TextEditorContextActions.setText, payload: { text: data.data } });
	}, [data.data]);

	return (
		<SmartReply
			isActive={isActive}
			canRegenerate={canRegenerate}
			onRegenerate={onRegenerate}
			setIsActive={setIsActive}
			onWrite={onWrite}
			data={data}
			onEdit={(prompts: string, message?: string) => onEdit(text, prompts, message)}
			onOutOfCredit={onOutOfCredit}
			isAvailable={isAvailable}
			onNotAvailable={onNotAvailable}
			hasMessage={!isEmptyString(text)}
			disabled={disabled}
			smartReplyPromptCategory={smartReplyPromptCategory}
		/>
	);
}
