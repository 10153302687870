import React, { useMemo } from 'react';
import { Radio } from 'antd';
import {
	NodeTimerPeriodStringEnum,
	NodeTimerPeriodEnum,
} from '@copilot/common/store/models/const/enum';
import { NodeTimerPeriodNumber } from '@copilot/common/store/models/const';
import { RadioChangeEvent } from 'antd/lib/radio';

interface TimingOptionsProps {
	period: NodeTimerPeriodEnum;
	onPeriodUpdate: (event: RadioChangeEvent) => void;
	timeUnits: NodeTimerPeriodStringEnum[];
}

/**
 * Radio buttons for Timing Options
 *
 * @param {NodeTimerPeriodEnum} period currently selected period
 * @callback onPeriodUpdate called on period change
 * @param {NodeTimerPeriodStringEnum[]} timeUnits available time units options
 */

const TimingOptions: React.FC<TimingOptionsProps> = React.memo((props) => {
	const { period, onPeriodUpdate, timeUnits } = props;
	const periodOptions = useMemo(() => {
		const options = [];

		for (const timeUnit of timeUnits) {
			const timerPeriodValue = NodeTimerPeriodNumber[timeUnit];
			options.push(
				<Radio.Button key={timerPeriodValue} value={timerPeriodValue}>
					{timeUnit}
				</Radio.Button>
			);
		}
		return options;
	}, [timeUnits]);

	return (
		<>
			<Radio.Group name="period" value={period} onChange={onPeriodUpdate} buttonStyle="solid">
				{periodOptions}
			</Radio.Group>
		</>
	);
});

export default TimingOptions;
