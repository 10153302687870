import { EntitiesActionTypes } from './types';
import { ServiceSchedule, IServiceSchedule } from '../models/redux';

const itemType = ServiceSchedule.modelName;
export namespace ServiceScheduleAction {
	export const loadSerivceSchedule = (schedule: Partial<IServiceSchedule>) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: schedule,
	});
}
