import React, { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import BaseModal, { BaseModalWithIdProps } from '../base';
import { Button, Col, Result, Row } from 'antd';
import modalManager from '@copilot/common/utils/modalManager';
import notificationManager from '@copilot/common/utils/notificationManager';
import TagOptionSelector, {
	TagOptionSelectorValues,
} from '@copilot/common/components/forms/common/generics/tagOptionSelector';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import {
	MigrateApplyTags,
	MigrateConnections,
} from '@copilot/common/components/componentModels/activities/batchActions/type';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useFetch } from '@copilot/common/hooks/common';
import BasicWizardContainer from '@copilot/common/components/wizard/basic/wizardContainer';
import { useNurtureMigrationTracking } from './tracking';
import { getPluralizedEnding } from '@copilot/common/utils';
import { getAllOrgTags } from '@copilot/common/pages/organizationDashboard/tags/data/selectors';

enum MigrationWizardSteps {
	Warning,
	Success,
	Fail,
	ApplyTags,
	TagSuccess,
	NoNewConnections,
}
interface IMigrationContentProps {
	onClickNext: () => unknown;
	onClickCancel?: () => void;
	isNextDisabled?: boolean;
	isCancelDisabled?: boolean;
	nextButtonLabel?: string;
	cancelButtonLabel?: string;
}

const MigrationContent: React.FC<IMigrationContentProps> = (props) => {
	const {
		onClickNext,
		onClickCancel,
		isNextDisabled = false,
		isCancelDisabled = false,
		nextButtonLabel,
		cancelButtonLabel,
		children,
	} = props;
	return (
		<>
			{children}
			<Row justify="center" gutter={[8, 8]}>
				<Col span={8}>
					<Button type="primary" block onClick={onClickNext} disabled={isNextDisabled}>
						{nextButtonLabel ?? 'Confirm'}
					</Button>
				</Col>
			</Row>
			{onClickCancel && (
				<Row justify="center">
					<Col span={8}>
						<Button
							block
							type="link"
							onClick={onClickCancel}
							style={{ color: 'grey' }}
							disabled={isCancelDisabled}
						>
							{cancelButtonLabel ?? 'Cancel'}
						</Button>
					</Col>
				</Row>
			)}
		</>
	);
};
interface IConnectionMigrationWizardProps extends BaseModalWithIdProps {
	campaignId: string;
	campaignMemberId: string;
	connectionIds: string[];
	onMigrate: MigrateConnections;
	onMigrateTag: MigrateApplyTags;
	onComplete?: () => void;
}

/**
 * Wizard for Connection Migration
 */
const ConnectionMigrationWizard: React.FC<IConnectionMigrationWizardProps> = (props) => {
	const {
		campaignId,
		campaignMemberId,
		connectionIds,
		width = '800px',
		onMigrate,
		onMigrateTag,
		modalId,
		onComplete,
		...modalProps
	} = props;
	const updateTrackingParams = useNurtureMigrationTracking('Migration Wizard', null);

	const isAdmin = !!useSelector(OrganizationMemberSelectors.getAdminMember);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const omid = useMemo(
		() => (isAdmin && activeMember?.id ? activeMember.id : undefined),
		[isAdmin, activeMember?.id]
	);

	const [wizardStep, setWizardStep] = useState<MigrationWizardSteps>(
		connectionIds.length === 0
			? MigrationWizardSteps.NoNewConnections
			: MigrationWizardSteps.Warning
	);
	const availableTagNames = useSelector(getAllOrgTags).data.map((t) => t.name);
	const [tagSelectorValues, setTagSelectorValues] = useState<TagOptionSelectorValues>({
		selectedTags: [],
		createExtraTags: false,
	});
	const campaign = useSelector(CampaignSelectors.getCampaign(campaignId));
	const [failedConnectionIds, setFailedConnectionIds] = useState<string[]>([]);
	const [migratedConnectionIds, setMigratedConnectionIds] = useState<string[]>([]);
	const [migrateConnectionsFetch, migrateConnections] = useFetch(onMigrate);
	const [tagConnectionsFetch, tagConnections] = useFetch(onMigrateTag);

	const isFetching = useMemo(
		() => migrateConnectionsFetch.isFetching || tagConnectionsFetch.isFetching,
		[migrateConnectionsFetch.isFetching, tagConnectionsFetch.isFetching]
	);

	const onClose = () => {
		modalManager.closeModal(modalId);
		onComplete?.();
	};

	async function handleMigrateConnections() {
		if (!campaign) return;
		try {
			const result = await migrateConnections(
				campaign,
				campaignMemberId,
				connectionIds,
				omid
			);
			const failedIds = result.nonMigratableConnections.map((c) => c.id);
			const migratedIds = result.migratableConnections.map((c) => c.id);
			setFailedConnectionIds(failedIds);
			setMigratedConnectionIds(migratedIds);

			if (failedIds.length === 0 && migratedIds.length > 0) {
				updateTrackingParams({ failedNurtureMigration: 0, nurtureMigration: 1 });
				setWizardStep(MigrationWizardSteps.Success);
			} else {
				updateTrackingParams({ failedNurtureMigration: 1, nurtureMigration: 1 });
				setWizardStep(MigrationWizardSteps.Fail);
			}
		} catch {
			onClose();
			notificationManager.showErrorNotification({
				message: 'Failed to migrate. Please try again.',
			});
			updateTrackingParams({ failedNurtureMigration: 1, nurtureMigration: 1 });
		}
	}

	const handleApplySelectedTags = useCallback(async () => {
		if (!campaign) return;
		try {
			await tagConnections(
				tagSelectorValues.createExtraTags,
				tagSelectorValues.selectedTags,
				migratedConnectionIds,
				campaign,
				campaignMemberId
			);
			setWizardStep(MigrationWizardSteps.TagSuccess);
		} catch {
			notificationManager.showErrorNotification({
				message: 'Failed to apply tags. Please try again.',
			});
		}
	}, [tagConnections, migratedConnectionIds, tagSelectorValues, campaign, campaignMemberId]);

	function handleFailedPageNext() {
		migratedConnectionIds.length > 0
			? setWizardStep(MigrationWizardSteps.ApplyTags)
			: onClose();
	}

	const isTagsSelected = useMemo(
		() => tagSelectorValues.selectedTags.length > 0 || tagSelectorValues.createExtraTags,
		[tagSelectorValues]
	);

	return (
		<BaseModal {...modalProps} width={width} closable={false}>
			<BasicWizardContainer farthestNode={wizardStep}>
				<BasicWizardContainer.Step id={MigrationWizardSteps.Warning}>
					<BasicWizardContainer.Step.Content>
						<MigrationContent
							onClickNext={handleMigrateConnections}
							onClickCancel={onClose}
							isNextDisabled={isFetching}
							isCancelDisabled={isFetching}
						>
							<Result
								status="warning"
								title={
									<p>
										Please note, prospects are now going to be removed from
										their current campaign and sent to the selected campaign.
										All previous automations and scheduled message will{' '}
										<strong>NOT</strong> occur.
									</p>
								}
							/>
						</MigrationContent>
					</BasicWizardContainer.Step.Content>
				</BasicWizardContainer.Step>
				<BasicWizardContainer.Step id={MigrationWizardSteps.Success}>
					<BasicWizardContainer.Step.Content>
						<MigrationContent
							onClickNext={() => setWizardStep(MigrationWizardSteps.ApplyTags)}
							isNextDisabled={isFetching}
						>
							<Result
								status="success"
								title="All selected prospects were moved to the selected campaign."
							/>
						</MigrationContent>
					</BasicWizardContainer.Step.Content>
				</BasicWizardContainer.Step>
				<BasicWizardContainer.Step id={MigrationWizardSteps.Fail}>
					<BasicWizardContainer.Step.Content>
						<MigrationContent onClickNext={handleFailedPageNext}>
							<Result
								status="warning"
								title={`We were unable to move ${
									failedConnectionIds.length
								} Connection${getPluralizedEnding(
									failedConnectionIds
								)} to the selected campaign. All other connections were successfully moved.`}
							/>
						</MigrationContent>
					</BasicWizardContainer.Step.Content>
				</BasicWizardContainer.Step>
				<BasicWizardContainer.Step id={MigrationWizardSteps.ApplyTags}>
					<BasicWizardContainer.Step.Content>
						<MigrationContent
							onClickNext={handleApplySelectedTags}
							onClickCancel={onClose}
							isNextDisabled={!isTagsSelected || isFetching}
							isCancelDisabled={isFetching}
							nextButtonLabel="Apply Tags"
							cancelButtonLabel="Skip"
						>
							<Row style={{ marginBottom: '50px' }}>
								<TagOptionSelector
									header={`Would you like to add tags to the ${
										migratedConnectionIds.length
									} prospect${migratedConnectionIds.length > 1 ? 's' : ''} in ${
										campaign?.name
									}`}
									description="This will help you track the leads you have in the campaign."
									checkboxLabel="Also add the name of campaign lead is coming from as a tag"
									onSelect={setTagSelectorValues}
									availableTagNames={availableTagNames}
								/>
							</Row>
						</MigrationContent>
					</BasicWizardContainer.Step.Content>
				</BasicWizardContainer.Step>
				<BasicWizardContainer.Step id={MigrationWizardSteps.TagSuccess}>
					<BasicWizardContainer.Step.Content>
						<MigrationContent onClickNext={onClose}>
							<Result
								status="success"
								title="Tags successfully applied to prospects"
							/>
						</MigrationContent>
					</BasicWizardContainer.Step.Content>
				</BasicWizardContainer.Step>
				<BasicWizardContainer.Step id={MigrationWizardSteps.NoNewConnections}>
					<BasicWizardContainer.Step.Content>
						<MigrationContent onClickNext={onClose}>
							<Result
								status="success"
								title="All selected prospects are already in the selected campaign. Please select at least one new prospect to move."
							/>
						</MigrationContent>
					</BasicWizardContainer.Step.Content>
				</BasicWizardContainer.Step>
			</BasicWizardContainer>
		</BaseModal>
	);
};

export default ConnectionMigrationWizard;
