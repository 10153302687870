import React, { useMemo, useCallback, useState } from 'react';
import { Col } from 'antd';
import ModelTableModule, { IModelTableModuleProps } from '../..';
import { ProspectTableColumns, ProspectColumns, COLUMN_MAP } from './columns';
import BasicContainer from '@copilot/common/components/containers/basic';
import {
	ColumnDefinition,
	ColumnTypes,
	LinkActionColumnDefinition,
} from '@copilot/common/components/componentModels/columnDefinition';
import { CustomFieldActions } from '@copilot/common/components/componentModels/columnTypeDefinition';
import { URL } from '@copilot/common/data/urls';
import ProspectListPageBatchAction from './batchAction';
import SearchInput from '@copilot/common/components/search/input';
import { TablePaginationConfig } from 'antd/lib/table';

/**
 * Data structure for an entry in the Prospect Table
 * @param {string} id prospect id
 * @param {string} inviteUrl prospect invite url for linking to LinkedIn
 * @param {string} firstName prospect first name
 * @param {string} lastName prospect last name
 * @param {string} connectionStatus prospect connection status
 * @param {string} position prospect position
 * @param {string} company prospect company
 * @param {string} location prospect location
 * @param {Date} dateCreated date the prospect was created on
 * @param {boolean} isRemoveHidden whether the prospect remove button is hidden
 * @param {string} contactId prospect's contact id
 * @param {string} orgMemberId id of the org member the prospect is connected to, optional if it hasn't been populated and isn't required for any actions
 * @param {string} campaignMemberId id of the campaign member the prospect is connected to
 */
export interface ProspectTableData {
	id: string;
	inviteUrl: string;
	name: string;
	connectionStatus?: string;
	position?: string;
	company?: string;
	location?: string;
	dateCreated?: Date;
	isRemoveHidden?: boolean;
	contactId?: string;
	orgMemberId?: string;
	campaignMemberId: string;
}

interface ProspectTableProps extends Omit<IModelTableModuleProps, 'columns'> {
	tableTitle: string;
	data: ProspectTableData[];
	columns?: ProspectTableColumns[];
	initialColumns?: ProspectTableColumns[];
	showTeamAdminCols?: boolean;
	isLoading?: boolean;
	isRowSelectable: boolean;
	searchPlaceholder?: string;
	onSearch?: (value: string) => void;
	handleRowClick?: (record: ProspectTableData) => void;
	onBatchRemoveProspect?: (connectionIds: string[]) => void;
	onCreateLink: (actionType: CustomFieldActions, record: string[]) => JSX.Element | null;
}

/**
 * Table for organizing and checking prospect status
 * @param tableTitle title of the table
 * @param data table data
 * @param columns column types
 * @param visibleColumns default visible columns
 * @param showTeamAdminCols whether to show Team Admin exclusive columns or not
 * @param isLoading loading status
 * @param isRowSelectable whether the row is selectable
 * @param searchPlaceholder placeholder for search input
 * @callback onSearch called when searching
 * @callback handleRowClick called on row click
 * @callback onBatchRemoveProspect called on batch remove prospect
 * @callback onCreateLink called on creating action link
 */

const ProspectTable: React.FC<ProspectTableProps> = (props) => {
	const {
		tableTitle,
		data,
		columns,
		initialColumns,
		showTeamAdminCols = false,
		isLoading = false,
		isRowSelectable = false,
		searchPlaceholder,
		onSearch,
		handleRowClick,
		onBatchRemoveProspect,
		onCreateLink,
		...rest
	} = props;

	const [visibleColumns, setVisibleColumns] = useState(initialColumns);

	const updateColumns = useCallback((columnsToUpdate: ColumnDefinition[]) => {
		const update = columnsToUpdate
			.filter((c) => c.isVisible)
			.map((c) => c.key as ProspectTableColumns);
		setVisibleColumns(update);
	}, []);

	const [selectedRows, setSelectedRows] = useState<ProspectTableData[]>([]);

	const handleRowChange = useCallback(
		(selectedRowKeys, changedRows: ProspectTableData[]) => {
			setSelectedRows(changedRows);
		},
		[selectedRows]
	);
	const rowSelection = {
		selectedRowKeys: selectedRows.map((r) => r.id),
		onChange: handleRowChange,
	};

	const resetSelectedRows = () => {
		setSelectedRows([]);
	};

	const handleBatchTerminate = useCallback(() => {
		const connectionIds = selectedRows.map((r) => r.id);
		onBatchRemoveProspect?.(connectionIds);
		resetSelectedRows();
	}, [selectedRows]);

	const customizedColumns = useMemo(() => {
		const teamAdminExclusiveColumns: string[] = [ProspectTableColumns.TeamMember];
		const displayedColumns = columns?.map((c) => COLUMN_MAP[c]) ?? ProspectColumns;
		return displayedColumns
			.filter(
				(column) => showTeamAdminCols || !teamAdminExclusiveColumns.includes(column.key)
			)
			.map((column) => {
				if (
					visibleColumns?.length &&
					!visibleColumns.includes(column.key as ProspectTableColumns)
				) {
					column.isVisible = false;
				} else {
					column.isVisible = true;
				}
				if (column.type === ColumnTypes.LinkAction) {
					(column as LinkActionColumnDefinition).determineLinkDisplay = onCreateLink;
				}
				return column;
			});
	}, [columns, showTeamAdminCols, visibleColumns, onCreateLink]);

	const updatedData = useMemo(
		() =>
			data.map((entry: ProspectTableData) => ({
				...entry,
				contactUrl: `${URL.LINKEDIN_URL}${entry.inviteUrl}`,
			})),
		[data]
	);

	const handleOnRow = useCallback(
		(record: ProspectTableData) => {
			if (handleRowClick) {
				return {
					onClick: () => handleRowClick(record),
					style: { cursor: 'pointer' },
				};
			} else return {};
		},
		[handleRowClick]
	);

	function getTotalCount(pagination?: false | TablePaginationConfig): number | undefined {
		if (pagination === false) return undefined;
		return pagination?.total ?? undefined;
	}

	return (
		<BasicContainer bordered={false}>
			<BasicContainer.Header>
				<Col>
					<h2>
						{tableTitle}{' '}
						{getTotalCount(rest.pagination) !== undefined &&
							`(${getTotalCount(rest.pagination)})`}
					</h2>
				</Col>
			</BasicContainer.Header>
			<BasicContainer.Content style={{ padding: '0px' }}>
				<ModelTableModule
					{...rest}
					data={updatedData}
					loading={isLoading}
					columns={customizedColumns}
					updateColumns={updateColumns}
					onRow={handleOnRow}
					rowSelection={isRowSelectable ? rowSelection : undefined}
					headerContent={
						onSearch && (
							<SearchInput
								placeholder={searchPlaceholder}
								onSearch={onSearch}
								style={{ width: '250px' }}
							/>
						)
					}
				/>
				{selectedRows.length > 0 && (
					<ProspectListPageBatchAction
						selectedCount={selectedRows.length}
						onTerminate={handleBatchTerminate}
					/>
				)}
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default ProspectTable;
