import { useMemo, useState } from 'react';
import {
	IConnectionsByConnectionStatusQuery,
	IConnectionStatus,
	useConnectionsByConnectionStatusLazyQuery,
} from '@copilot/data/graphql/_generated';
import isNil from 'lodash/isNil';
import { EMPTY_PAGINATION_RESPONSE, PaginatedResponseV2 } from '@copilot/common/types/pagination';
import { useFetch } from '@copilot/common/hooks/common';
import { OrganizationMemberManager } from '@copilot/data';
import { LinkedInMetaActions } from '@copilot/common/store/actions/linkedInMeta';
import { CampaignMemberModel } from '@copilot/common/utils/campaignMember/models';
import {
	OPEN_PROFILE_TAB_KEY,
	PROSPECT_TAB_KEY,
	SearchListTableTabKeys,
} from '@copilot/common/pages/campaignDashboard/searchList/const';
import { ProspectingSearchListResult } from '@copilot/common/pages/campaignDashboard/searchList/types';

const DEFAULT_PAGE_SIZE = 10;

/**
 * Hooks to encapsulate logic for searching, pagination, filtering prospecting search list
 */
export function useProspectingSearchListV2() {
	const [pageSizeLookup, setPageSizeLookup] = useState<Record<SearchListTableTabKeys, number>>({
		[PROSPECT_TAB_KEY]: DEFAULT_PAGE_SIZE,
		[OPEN_PROFILE_TAB_KEY]: DEFAULT_PAGE_SIZE,
	});
	const [currentPageLookup, setCurrentPageLookup] = useState<
		Record<SearchListTableTabKeys, number>
	>({
		[PROSPECT_TAB_KEY]: 1,
		[OPEN_PROFILE_TAB_KEY]: 1,
	});
	const [totalCountLookup, setTotalCountLookup] = useState<
		Record<SearchListTableTabKeys, number>
	>({
		[PROSPECT_TAB_KEY]: 0,
		[OPEN_PROFILE_TAB_KEY]: 0,
	});

	const [searchTerm, setSearchTerm] = useState<string>('');
	const [campaignMemberId, setCampaignMemberId] = useState<string | undefined>(undefined);
	const [
		fetchProspectConnections,
		{ data: prospectData, previousData: prevProspectData, loading: isLoadingProspects },
	] = useConnectionsByConnectionStatusLazyQuery();
	const [
		fetchOpenProfileConnections,
		{
			data: openProfileData,
			previousData: prevOpenProfileData,
			loading: isLoadingOpenProfiles,
		},
	] = useConnectionsByConnectionStatusLazyQuery();
	const [fetchProspectCount] = useConnectionsByConnectionStatusLazyQuery();
	const [fetchOpenProfileCount] = useConnectionsByConnectionStatusLazyQuery();
	const [, fetchIsLoggedIn] = useFetch(
		OrganizationMemberManager.checkIsLoggedIn,
		LinkedInMetaActions.loadLinkedInMeta,
		(r, omid) => ({ orgMemberId: omid, isLoggedIn: r })
	);

	const prospectSearchList = useMemo<PaginatedResponseV2<ProspectingSearchListResult>>(
		() =>
			isNil(prospectData) ? EMPTY_PAGINATION_RESPONSE : toPaginatedSearchList(prospectData),
		[prospectData]
	);
	const prevProspectSearchList = useMemo<PaginatedResponseV2<ProspectingSearchListResult>>(
		() =>
			isNil(prevProspectData)
				? EMPTY_PAGINATION_RESPONSE
				: toPaginatedSearchList(prevProspectData),
		[prevProspectData]
	);
	const openProfileSearchList = useMemo<PaginatedResponseV2<ProspectingSearchListResult>>(
		() =>
			isNil(openProfileData)
				? EMPTY_PAGINATION_RESPONSE
				: toPaginatedSearchList(openProfileData),
		[openProfileData]
	);
	const prevOpenProfileSearchList = useMemo<PaginatedResponseV2<ProspectingSearchListResult>>(
		() =>
			isNil(prevOpenProfileData)
				? EMPTY_PAGINATION_RESPONSE
				: toPaginatedSearchList(prevOpenProfileData),
		[prevOpenProfileData]
	);

	/**
	 * Fetch prospect search list results
	 * @param cmid campaign member id
	 * @param newSearchTerm
	 * @param newPageSize
	 * @param newCurrentPage
	 */
	async function fetchProspectResults(
		cmid: string,
		newSearchTerm?: string,
		newCurrentPage?: number,
		newPageSize?: number
	) {
		return fetchProspectConnections({
			variables: {
				input: {
					campaignMemberIds: [cmid],
					status: [IConnectionStatus.Stranger],
					searchText: newSearchTerm ?? searchTerm,
					isOpenProfile: false,
					pagination: {
						page: newCurrentPage
							? newCurrentPage - 1
							: currentPageLookup[PROSPECT_TAB_KEY] - 1,
						pageSize: newPageSize ? newPageSize : pageSizeLookup[PROSPECT_TAB_KEY],
					},
				},
			},
		});
	}

	/**
	 * Fetch open profile search list results
	 * @param cmid campaign member id
	 * @param newSearchTerm
	 * @param newCurrentPage
	 * @param newPageSize
	 */
	async function fetchOpenProfileResults(
		cmid: string,
		newSearchTerm?: string,
		newCurrentPage?: number,
		newPageSize?: number
	) {
		return fetchOpenProfileConnections({
			variables: {
				input: {
					campaignMemberIds: [cmid],
					status: [IConnectionStatus.Stranger],
					searchText: newSearchTerm ?? searchTerm,
					isOpenProfile: true,
					pagination: {
						page: newCurrentPage
							? newCurrentPage - 1
							: currentPageLookup[OPEN_PROFILE_TAB_KEY] - 1,
						pageSize: newPageSize ? newPageSize : pageSizeLookup[OPEN_PROFILE_TAB_KEY],
					},
				},
			},
		});
	}

	/**
	 * Initialize search lists with a campaign member and default search term and pagination settings
	 * @param campaignMember
	 */
	async function initSearchLists(campaignMember: CampaignMemberModel | undefined) {
		if (!isNil(campaignMember) && !isNil(campaignMember.id)) {
			// reset query
			setSearchTerm('');
			setCurrentPageLookup({
				[PROSPECT_TAB_KEY]: 1,
				[OPEN_PROFILE_TAB_KEY]: 1,
			});
			setCampaignMemberId(campaignMember.id);

			const fetchProspects = fetchProspectResults(campaignMember.id);
			const fetchOpenProfiles = fetchOpenProfileResults(campaignMember.id);
			await Promise.all([fetchProspects, fetchOpenProfiles]).then(
				([prospectResults, openProfileResults]) => {
					setTotalCountLookup({
						[PROSPECT_TAB_KEY]:
							prospectResults?.data?.campaignConnectionsByConnectionStatus.count ?? 0,
						[OPEN_PROFILE_TAB_KEY]:
							openProfileResults?.data?.campaignConnectionsByConnectionStatus.count ??
							0,
					});
				}
			);
		}
		if (!isNil(campaignMember) && !isNil(campaignMember.orgMemberId)) {
			await fetchIsLoggedIn(campaignMember.orgMemberId);
		}
	}

	/**
	 * Refetch total counts for prospect and open profile search lists
	 */
	async function refetchTotalCounts() {
		if (!isNil(campaignMemberId)) {
			const input = {
				campaignMemberIds: [campaignMemberId],
				status: [IConnectionStatus.Stranger],
				pagination: {
					page: 0,
					pageSize: 1,
				},
			};
			const fetchProspects = fetchProspectCount({
				variables: { input: { ...input, isOpenProfile: false } },
			});
			const fetchOpenProfiles = fetchOpenProfileCount({
				variables: { input: { ...input, isOpenProfile: true } },
			});
			await Promise.all([fetchProspects, fetchOpenProfiles]).then(
				([prospectResults, openProfileResults]) => {
					setTotalCountLookup({
						[PROSPECT_TAB_KEY]:
							prospectResults?.data?.campaignConnectionsByConnectionStatus.count ?? 0,
						[OPEN_PROFILE_TAB_KEY]:
							openProfileResults?.data?.campaignConnectionsByConnectionStatus.count ??
							0,
					});
				}
			);
		}
	}

	/**
	 * Refetch search lists using the same search term and pagination settings
	 */
	async function refetchSearchLists() {
		if (!isNil(campaignMemberId)) {
			const fetchProspects = fetchProspectResults(campaignMemberId);
			const fetchOpenProfiles = fetchOpenProfileResults(campaignMemberId);
			await Promise.all([fetchProspects, fetchOpenProfiles]);
		}
	}

	/**
	 * Update search term and refetch search lists
	 * @param newSearchTerm
	 */
	async function onUpdateSearchTerm(newSearchTerm: string) {
		if (!isNil(campaignMemberId)) {
			setSearchTerm(newSearchTerm);
			// Reset current page to first page
			setCurrentPageLookup({
				[PROSPECT_TAB_KEY]: 1,
				[OPEN_PROFILE_TAB_KEY]: 1,
			});
			const fetchProspects = fetchProspectResults(campaignMemberId, newSearchTerm, 1);
			const fetchOpenProfiles = fetchOpenProfileResults(campaignMemberId, newSearchTerm, 1);
			await Promise.all([fetchProspects, fetchOpenProfiles]);
		}
	}

	/**
	 * Update pagination settings and refetch search lists
	 * @param tabKey
	 * @param newPageSize
	 * @param newCurrentPage
	 */
	async function onUpdatePagination(
		tabKey: string,
		newPageSize?: number,
		newCurrentPage?: number
	) {
		if (!isNil(campaignMemberId)) {
			if (newPageSize) {
				setPageSizeLookup((prev) => ({
					...prev,
					[tabKey]: newPageSize,
				}));
			}
			if (newCurrentPage) {
				setCurrentPageLookup((prev) => ({
					...prev,
					[tabKey]: newCurrentPage,
				}));
			}
			if (tabKey === PROSPECT_TAB_KEY) {
				await fetchProspectResults(
					campaignMemberId,
					undefined,
					newCurrentPage,
					newPageSize
				);
			} else {
				await fetchOpenProfileResults(
					campaignMemberId,
					undefined,
					newCurrentPage,
					newPageSize
				);
			}
		}
	}

	return {
		//data
		prospectResults: isLoadingProspects ? prevProspectSearchList : prospectSearchList,
		openProfileResults: isLoadingOpenProfiles
			? prevOpenProfileSearchList
			: openProfileSearchList,
		prospectTotalCounts: totalCountLookup[PROSPECT_TAB_KEY],
		openProfileTotalCounts: totalCountLookup[OPEN_PROFILE_TAB_KEY],
		// loading states
		isLoadingProspects,
		isLoadingOpenProfiles,
		// search
		searchTerm,
		// pagination
		prospectsPageSize: pageSizeLookup[PROSPECT_TAB_KEY],
		openProfilesPageSize: pageSizeLookup[OPEN_PROFILE_TAB_KEY],
		// handlers
		initSearchLists,
		refetchSearchLists,
		refetchTotalCounts,
		onUpdateSearchTerm,
		onUpdatePagination,
	};
}

function toPaginatedSearchList(
	data: IConnectionsByConnectionStatusQuery | undefined
): PaginatedResponseV2<ProspectingSearchListResult> {
	if (isNil(data)) {
		return EMPTY_PAGINATION_RESPONSE;
	}
	return {
		count: data.campaignConnectionsByConnectionStatus.count,
		pages: {
			current: data.campaignConnectionsByConnectionStatus.pages.current + 1,
			total: data.campaignConnectionsByConnectionStatus.pages.total,
		},
		content: toSearchListResults(data.campaignConnectionsByConnectionStatus.content),
	};
}

function toSearchListResults(
	content: IConnectionsByConnectionStatusQuery['campaignConnectionsByConnectionStatus']['content']
): ProspectingSearchListResult[] {
	return content.map(
		({
			id,
			name,
			contactInfo,
			title,
			company,
			location,
			campaignMember,
			campaignId,
			status,
			profileId,
			inviteUrl,
			replyPrediction,
			dateCreated,
		}) => ({
			id,
			contactId: contactInfo?.id ?? '',
			campaignMemberId: campaignMember.id,
			name,
			company: company ?? undefined,
			position: title ?? undefined,
			location: location ?? undefined,
			campaignId,
			profileId,
			inviteUrl,
			matchScore: replyPrediction ?? undefined,
			connectionStatus: status,
			dateCreated: new Date(dateCreated),
		})
	);
}
