export class FilterDefinition {
	public key: string;
	public label: string;
	public displayName?: JSX.Element;
	public width?: string;
	public className?: string;
	public properties: string[];
	public type: string;
	//isVisible == is applied
	public isVisible: boolean;
	public filter?: boolean;
	public sort?: boolean;
	public hideable?: boolean;
	public hideForRecord: (record: any) => boolean;
	public isExclude?: boolean;

	constructor(filter: any = {}) {
		this.key = filter.key || filter.Key;
		this.label = filter.label || filter.Label;
		this.displayName = filter.displayName;
		this.width = filter.width;
		this.className = filter.className;
		this.properties = filter.properties ?? filter.Properties ?? [];
		this.type = filter.type;
		this.isVisible = filter.isVisible ?? filter.IsVisible ?? false;
		this.filter = filter.filter ?? filter.Filter ?? false;
		this.sort = filter.sort ?? filter.Sort ?? true;
		this.hideable = filter.hideable ?? true;
		this.hideForRecord = filter.hideForRecord
			? filter.hideForRecord
			: function () {
					return false;
			  };
		this.isExclude = filter.isExclude ?? false;
	}
}
