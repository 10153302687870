import { Button, Flex, Typography } from 'antd';
import { Icon } from '@iconify/react';
import { aiIcon } from '@copilot/common/constant/icons';
import { PersonalizedInsightsButtonProps } from '@copilot/common/components/drawer/wrappers/contact/informationPanel/types';
import styles from './infoPanel.module.less';
import { PERSONALIZED_INSIGHTS_BUTTON_TEST_ID } from '../../../contactDrawerV2/infoPanelV2/constants';
import { DRAWER_VIEW_INSIGHTS_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';

const { Title, Text } = Typography;

/**
 * The personalized insight section of prospect info panel
 * @param props
 * @constructor
 */
export default function PersonalizedInsightsDrawerSection({
	onClick,
	onNotAvailable,
	isEnabled,
	isLoadingData,
}: PersonalizedInsightsButtonProps) {
	return (
		<Flex vertical gap="small" className={styles.personalizedInsightsWrapper}>
			<Title className={styles.infoPanelTitle}>Personalized Insights</Title>
			<Text className={styles.insightsDescription}>
				Get profile insights about this prospect
			</Text>
			<Button
				data-testid={PERSONALIZED_INSIGHTS_BUTTON_TEST_ID}
				className={styles.viewInsightsButton}
				size={'large'}
				loading={isLoadingData}
				block={true}
				icon={<Icon inline icon={aiIcon} />}
				onClick={() => {
					isEnabled ? onClick() : onNotAvailable();
				}}
				data-tracking-id={DRAWER_VIEW_INSIGHTS_TRACKING_ID}
			>
				View insights
			</Button>
		</Flex>
	);
}
