import React from 'react';
import CampaignOnboardWizardSalesNavSearch from './salesNavSearch';
import CampaignOnboardWizardCopilotSearch from './copilotSearch';
import { ILocation } from '@copilot/common/components/linkedin/searchCriteria/location';
import { LinkedInSearchType } from '@copilot/data/responses/interface';

interface CampaignOnboardWizardSearchProps {
	skipHelp: boolean;
	view: LinkedInSearchType;
	updateView: (view: LinkedInSearchType) => void;

	searchUrl: string;
	updateSearchUrl: (url: string) => void;
	searchUrlValid?: boolean;

	location: ILocation;
	updateLocation: (location: ILocation) => void;
	occupation: string[];
	updateOccupation: (occupation: string[]) => void;
	extraOccupations: string[];
	updateExtraOccupations: (extraOccupation: string[]) => void;

	openSalesNavSearchModal: () => void;
}
const CampaignOnboardWizardSearch: React.FC<CampaignOnboardWizardSearchProps> = (props) => {
	const content = (view: LinkedInSearchType) => {
		switch (view) {
			case LinkedInSearchType.Url:
				return (
					<CampaignOnboardWizardSalesNavSearch
						skipHelp={props.skipHelp}
						searchUrl={props.searchUrl}
						updateSearchUrl={props.updateSearchUrl}
						searchUrlValid={props.searchUrlValid}
						openModal={props.openSalesNavSearchModal}
					/>
				);

			case LinkedInSearchType.Criteria:
				return (
					<CampaignOnboardWizardCopilotSearch
						location={props.location}
						updateLocation={props.updateLocation}
						occupation={props.occupation}
						updateOccupation={props.updateOccupation}
						extraOccupations={props.extraOccupations}
						updateExtraOccupations={props.updateExtraOccupations}
					/>
				);
			default:
				return null;
		}
	};

	return <>{content(props.view)}</>;
};

export default CampaignOnboardWizardSearch;
