import { ConnectionResponse, ContactConnectionsResponse } from '../interface';

export class ContactConnectionModel implements ContactConnectionsResponse {
	public id: string;
	public name: string;
	public contactId: string;
	public position: string;
	public company: string;
	public email: string;
	public phoneNumber: string;
	public location: string;
	public connections: ConnectionResponse[];

	constructor(connection: ContactConnectionsResponse) {
		const processedConnections = connection.connections.map((c) => {
			const temp = { ...c };
			temp.name = connection.name;
			temp.contactId = connection.contactId;
			return temp;
		});
		this.id = connection.contactId;
		this.name = connection.name;
		this.contactId = connection.contactId;
		this.position = connection.position;
		this.company = connection.company;
		this.email = connection.email;
		this.phoneNumber = connection.phoneNumber;
		this.location = connection.location;
		this.connections = processedConnections;
	}
}
