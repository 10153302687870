import BaseDataManager, { QueryObject, PaginatedResults } from './base';
import {
	CampaignMemberResponse,
	EmptyResponse,
	LinkedInCampaignMemberResponse,
	ConnectionResponse,
} from '../responses/interface';
import { ConnectionStatusType } from '@copilot/common/utils/constant';
import { CampaignStatusEnum } from '../requests/models';
import { CampaignMemberModel } from '@copilot/common/utils/campaignMember/models';

class CampaignMemberManager extends BaseDataManager {
	constructor() {
		super();
		this.getSearchListResultsByMember = this.getSearchListResultsByMember.bind(this);
	}

	/**
	 * Download the connections CSV
	 * @param campaignId The id of the campaign we want to download the connections for
	 * @param campaignMemberId The id of the campaign member we want to download the connections for
	 */
	public getConnectionsCSV = (campaignId: string, campaignMemberId: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.Default,
			campaignId,
			'members',
			campaignMemberId,
			'connections',
			'list'
		);
		return this.RequestManager.get(url).then((response) => {
			const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = objectUrl;
			const dateString = new Date().toDateString().replace(/ /gi, '-');
			link.setAttribute('download', `connections-csv-${dateString}.csv`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		});
	};

	/**
	 * Get Linkedin Information for all members of a campaign
	 * @param {string} campaignId The id of the campaign
	 */
	public getCampaignMembersLinkedInInformation = (campaignId: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.LINKED_IN.MemberInfo,
			campaignId,
			'members'
		);
		return this.RequestManager.get<CampaignMemberModel[]>(url).then((r) =>
			r.data.map((data) => ({
				...data,
				campaignId,
			}))
		);
	};

	/**
	 * Get Linkedin Information for a member of a campaign
	 * @param {string} campaignId The id of the campaign
	 * @param {string} memberId The id of the campaign member
	 */
	public getCampaignMemberLinkedInInformation = (campaignId: string, memberId: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.LINKED_IN.MemberInfo,
			campaignId,
			'members',
			memberId
		);
		return this.RequestManager.get<CampaignMemberModel>(url).then((r) => ({
			...r.data,
			campaignId,
		}));
	};

	/**
	 * Remove a campaign member from a campaign
	 * @param {string} campaignId The id of the campaign we want to remove from
	 * @param {string} memberId The id of the campaign member id we want to remove
	 */
	public removeCampaignMember = (campaignId: string, memberId: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.Default,
			campaignId,
			'members',
			memberId
		);
		return this.RequestManager.delete(url);
	};

	/**
	 * Add campaign members to a campaign
	 * @param {string} campaignId The id of the campaign we want to add
	 * @param {string[]} orgMemberIds The ids of the organizatin members we want to add
	 * @param {string[]} roles The roles we want associated with each member
	 */
	public addCampaignMembers = (campaignId: string, orgMemberIds: string[], roles: string[]) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, campaignId, 'members');
		const data = { orgMemberIds, roles };
		return this.RequestManager.post<CampaignMemberResponse[]>(url, data).then((r) => r.data);
	};

	/**
	 * Update the status flag of a campaign member
	 * @param {string} campaignId The id of the campaign the campaign member is associated with
	 * @param {string} memberId The id of the campaign member
	 * @param {CampaignStatusEnum} status The status we want to update to
	 */
	public updateCampaignMemberStatus = (
		campaignId: string,
		memberId: string,
		status: CampaignStatusEnum
	) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.UpdateStatus,
			campaignId,
			'members',
			memberId,
			'enable'
		);
		const data = { enable: status !== CampaignStatusEnum.Disabled };
		return this.RequestManager.post<EmptyResponse>(url, null, { params: data }).then(
			(r) => r.data
		);
	};

	/**
	 * Update the linkedin information for a campaign member
	 * @param {string} campaignId The id of the campaign
	 * @param {string} memberId The id of the campaign member
	 * @param {LinkedInCampaignMemberResponse} linkedInSettings The new settings we want to update to
	 */
	public updateCampaignMemberLinkedInInformation = (
		campaignId: string,
		memberId: string,
		linkedInSettings: Partial<LinkedInCampaignMemberResponse>
	) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.LINKED_IN.MemberInfo,
			campaignId,
			'members',
			memberId
		);
		return this.RequestManager.put<EmptyResponse>(url, linkedInSettings).then((r) => r.data);
	};

	/**
	 * Get search list results for all members of the campaign
	 * @param {string} campaignId The id of the campaign
	 * @param {ConnectionStatusType} qstatus The status of the connections we want to get
	 * @param {string | null} omid org member id (needed for impersonation - only for cs admin)
	 * @param {QueryObject} query Query we want to use
	 */
	public getSearchListResultsAllMembers = (
		campaignId: string,
		qstatus: ConnectionStatusType,
		omid: string | null = null,
		searchTerm: string | null = null,
		query: QueryObject = new QueryObject()
	) => {
		const route = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignId,
			'searchlist'
		);
		const url = this.paginate(route, query);
		return this.RequestManager.get<PaginatedResults<ConnectionResponse>>(url, {
			params: { qstatus, searchTerm, omid },
		}).then((r) => r.data);
	};

	/**
	 * Get search list results by member id
	 * @param {string} campaignId The id of the campaign
	 * @param {string} memberId The id of the campaign member
	 * @param {ConnectionStatusType} qstatus The status of the connections we want to get
	 * @param {string | null} omid org member id (needed for impersonation - only for cs admin)
	 * @param {QueryObject} query Query we want to use
	 */
	public async getSearchListResultsByMember(params: {
		campaignId: string;
		campaignMemberId: string;
		connectionStatus: ConnectionStatusType;
		orgMemberId?: string;
		searchTerm?: string | null;
		query?: QueryObject;
		isOpenProfile?: boolean;
	}) {
		const {
			campaignId,
			campaignMemberId,
			connectionStatus,
			searchTerm = null,
			orgMemberId = null,
			isOpenProfile = null,
			query = new QueryObject(),
		} = params;

		const route = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.LinkedIn,
			campaignId,
			'member',
			campaignMemberId,
			'searchlist'
		);
		const url = this.paginate(route, query);
		const response = await this.RequestManager.get<PaginatedResults<ConnectionResponse>>(url, {
			params: { qstatus: connectionStatus, searchTerm, omid: orgMemberId, isOpenProfile },
		});
		return response.data;
	}
}

const instance = new CampaignMemberManager();
export default instance;
