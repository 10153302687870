import React, { useCallback, useState, useMemo } from 'react';
import { Row, Col, Spin, Tag } from 'antd';
import { CampaignFields } from '@copilot/common/store/models/redux';
import { useFetch } from '@copilot/common/hooks/common';
import { useDispatch, useSelector } from 'react-redux';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import EditableTextDisplay from '@copilot/common/components/forms/common/inputs/editableTextDisplay';
import { CampaignApprovalStatus } from '@copilot/data/responses/interface';
import { AdminManager } from '@copilot/data/managers/admin';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useCampaignActiveToggle } from '@copilot/common/hooks/campaign';
import { getCampaignIcon } from '@copilot/common/utils/campaign';
import CampaignStatusDisplay from '@copilot/common/components/campaignStatusDisplay';
import { CampaignMemberSelectors } from '@copilot/common/store/selectors/campaignMember';

interface CampaignSummaryHeaderProps {
	campaign: CampaignFields;
	//TODO: CPD-2267
	campaignNameUpdateHandler: (id: string, name: string) => Promise<void>;
}

const stopEventPropagation = (event: any) => event.stopPropagation();

const CampaignSummaryHeader: React.FC<CampaignSummaryHeaderProps> = (props) => {
	const { campaign, campaignNameUpdateHandler } = props;
	const dispatch = useDispatch();
	const [, handleToggle] = useCampaignActiveToggle(campaign.id);
	const [, updateApproval] = useFetch(AdminManager.updateApprovalCampaign);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const campaignMembers = useSelector(CampaignMemberSelectors.getByCampaign(campaign.id));
	const [isUpdating, setIsUpdating] = useState<boolean>(false);

	const handleNameUpdate = useCallback(
		async (name: string) => {
			if (campaign.id) {
				try {
					setIsUpdating(true);
					await campaignNameUpdateHandler(campaign.id, name);
				} catch (err) {
					console.error(err);
				} finally {
					setIsUpdating(false);
				}
			}
		},
		[campaign.id, campaignNameUpdateHandler]
	);

	const handleApproveCampaign = useCallback(() => {
		if (activeMember?.id) {
			updateApproval(campaign.id, activeMember.id, CampaignApprovalStatus.Approved).then(() =>
				dispatch(
					CampaignActions.updateCampaign({
						id: campaign.id,
						...{ approvalStatus: CampaignApprovalStatus.Approved },
					})
				)
			);
		}
	}, [campaign.id, activeMember?.id]);

	const showActivateCampaignButton = useMemo(
		() =>
			campaign.approvalStatus === CampaignApprovalStatus.Waiting ||
			campaign.approvalStatus === CampaignApprovalStatus.Incomplete,
		[campaign.approvalStatus]
	);

	return (
		<Row justify="space-between" align="middle">
			<Col>
				<Spin spinning={isUpdating}>
					<Row align="top">
						<Tag
							color={campaign.primaryColour}
							style={{
								lineHeight: 0,
								padding: '2px',
								fontSize: '12px',
								marginTop: '6px',
								marginRight: '6px',
							}}
						>
							{getCampaignIcon(campaign.type)}
						</Tag>
						<Col>
							<h3>
								<strong>
									<EditableTextDisplay
										value={campaign.name}
										confirmHandler={(name) => {
											handleNameUpdate(name);
										}}
										confirmOnBlur
									>
										{campaign.name}
									</EditableTextDisplay>
								</strong>
							</h3>
							<span>{`Team Members: ${campaignMembers.length}`}</span>
						</Col>
					</Row>
				</Spin>
			</Col>
			<Col style={{ textAlign: 'center' }} onClick={stopEventPropagation}>
				<h4>Campaign Status:</h4>
				<CampaignStatusDisplay
					approvalStatus={campaign.approvalStatus}
					isEnabled={!!+campaign.status}
					isPaused={!!activeMember?.unpauseDate}
					onToggleChange={handleToggle}
					onActivateCampaign={
						showActivateCampaignButton ? handleApproveCampaign : undefined
					}
					campaignType={campaign.type}
				/>
			</Col>
		</Row>
	);
};

export default CampaignSummaryHeader;
