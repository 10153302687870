/**
 * check if user has an access
 * @param id id we want to check for an access
 * @param ownerId id of ownership
 * @param isAdmin whether user is an admin
 */

export function checkHasAccess(id: string, ownerId: string, isAdmin: boolean) {
	return id === ownerId || isAdmin;
}
