import { NodeTimerPeriodEnum, NodeTimerPeriodStringEnum } from './enum';

//matches with NodeTimerPeriodStringEnum
export const NodeTimerPeriodStrings: Record<string, string> = {
	Minutes: 'Minutes',
	Hours: 'Hours',
	Days: 'Days',
	Weeks: 'Weeks',
	Months: 'Months',
};

/**
 * array to convert from NodeTimerPeriodEnum to NodeTimerPeriodStringEnum or NodeTimerPeriodStrings
 */
export const nodeTimerPeriodEnumToString = [
	NodeTimerPeriodStringEnum.Minutes,
	NodeTimerPeriodStringEnum.Hours,
	NodeTimerPeriodStringEnum.Days,
	NodeTimerPeriodStringEnum.Weeks,
	NodeTimerPeriodStringEnum.Months,
];

export const NodeTimerPeriodNumber: Record<string, NodeTimerPeriodEnum> = {
	Minutes: 0,
	Hours: 1,
	Days: 2,
	Weeks: 3,
	Months: 4,
};
