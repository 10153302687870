import { ProspectDrawerClicks } from '../tracking-types';

/**
 * Enum for representing tab ids in the contact drawer
 */
export const ContactDrawerTabsConstants: { [key: string]: ContactDrawerTabs } = {
	Conversation: '0',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	Notes: '1',
	Schedules: '2',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	ProspectData: '3',
};

export type ContactDrawerTabs = '0' | '1' | '2' | '3';

/**
 * Lookup for tracking the tab clicks in the contact drawer
 */
export const ContactDrawerTabTracking: Record<string, ProspectDrawerClicks> = {
	[ContactDrawerTabsConstants.Conversation]: 'Conversation Tab',
	[ContactDrawerTabsConstants.Notes]: 'Notes Tab',
	[ContactDrawerTabsConstants.Schedules]: 'Schedule Message Tab',
	[ContactDrawerTabsConstants.ProspectData]: 'Prospect Info Tab',
};
