export const ERROR_NOTIFICATION_KEY = 'updateFailedKey';

export enum CampaignOnboardWizardSteps {
	Welcome,
	Search,
	Messaging,
	Timezone,
	BookMeeting,
	Complete,
}
