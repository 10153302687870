import React from 'react';
import { Col, Row, Tag, Typography } from 'antd';
import { IDiscount } from '@copilot/data/responses/models/billing';
import { getFormattedDiscountTag } from '@copilot/cs/src/components/billing/utils/discountFormat';

const { Title, Text } = Typography;

/**
 * Properties to display the Discount Table
 */
interface DiscountTableProps {
	items: IDiscount[];
	/**
	 * True if the discount table should show a disabled UI (with "N/A").
	 */
	disabled?: boolean;
}

/**
 * A summary of discounts for a specific invoice
 *
 * @param props
 * @returns
 */
const DiscountTable: React.FC<DiscountTableProps> = ({ items, disabled = false }) => (
	<>
		<Title type={disabled ? 'secondary' : undefined} level={5}>
			Coupons
		</Title>
		{!items.length || disabled ? (
			<Text type="secondary">N/A</Text>
		) : (
			items.map((discount: IDiscount) => (
				<Row
					wrap={false}
					data-testid="discount-row"
					justify="space-between"
					key={discount.id}
				>
					<Col flex="auto">{discount.name}</Col>
					<Col flex="none" className="text-right">
						{discount.formattedDiscount}
						{' Off '}
						<Tag>
							{getFormattedDiscountTag(discount.duration, discount.durationInMonths)}
						</Tag>
					</Col>
				</Row>
			))
		)}
	</>
);

export default DiscountTable;
