import { Campaign, CampaignFields, ICampaignMember } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = Campaign.modelName;
type ICampaignFields = CampaignFields & { members: Partial<ICampaignMember>[] };
export namespace CampaignActions {
	export const loadCampaign = (
		campaign: Partial<ICampaignFields> | Partial<ICampaignFields>[]
	) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: campaign,
	});

	export const loadCampaigns = (campaigns: Partial<ICampaignFields>[]) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: campaigns,
	});

	export const updateCampaign = (campaign: Partial<ICampaignFields>) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: campaign,
	});

	export const deleteCampaign = (campaign: Partial<ICampaignFields>) => ({
		type: EntitiesActionTypes.Delete,
		itemType,
		payload: campaign,
	});

	export const resetCampaigns = () => ({
		type: EntitiesActionTypes.Reset,
		itemType,
	});
}
