export const SIGNAL_R_OPTIONS = {
	config: {
		iceServers: [
			{
				urls: 'stun:turn.copilotai.com:80',
			},
			{
				urls: 'stun:turn.copilotai.com:3478',
			},
			{
				urls: 'turn:turn.copilotai.com:80',
				credential: 'fKXq66HHa2W2aRy',
				username: 'copilotai',
			},
			{
				urls: 'turns:turn.copilotai.com:443',
				credential: 'fKXq66HHa2W2aRy',
				username: 'copilotai',
			},
			{
				urls: 'turns:turn.copilotai.com:443?transport=tcp',
				credential: 'fKXq66HHa2W2aRy',
				username: 'copilotai',
			},
			{
				urls: 'turns:turn.copilotai.com:3478',
				credential: 'fKXq66HHa2W2aRy',
				username: 'copilotai',
			},
		],
	},
};
