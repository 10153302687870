import { IContact, Contact } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = Contact.modelName;
export namespace ContactActions {
	export const loadContact = (contact: Partial<IContact> | Partial<IContact>[]) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: contact,
	});

	export const updateContact = (contact: Partial<IContact> | Partial<IContact>[]) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: contact,
	});

	export const deleteContact = (contact: Partial<IContact> | Partial<IContact>[]) => ({
		type: EntitiesActionTypes.Delete,
		itemType,
		payload: contact,
	});
}
