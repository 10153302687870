import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';

interface WarningProps {
	onNext: () => void;
}

const WarningWrapper: React.FC<WarningProps> = (props) => (
	<>
		<Row style={{ display: 'block' }}>
			<Col style={{ textAlign: 'center' }}>
				<CheckCircleOutlined style={{ color: '#0075e2', fontSize: '8em' }} />

				<h2>Success! Customer Created</h2>
				<Col>You can now set up and kickoff customer campaigns</Col>
			</Col>
		</Row>
		<Row style={{ display: 'block' }}>
			<Col style={{ display: 'flex', justifyContent: 'center', margin: '2em 0 0 0' }}>
				<Button type="primary" onClick={props.onNext}>
					Finish
				</Button>
			</Col>
		</Row>
	</>
);

export default WarningWrapper;
