import { attr } from 'redux-orm';
import { TaskStatus } from '@copilot/data/responses/interface';
import { BaseModel } from './base';

interface TaskMessage {
	code: string;
	payload: string;
	timeStamp: string;
}

interface Fields {
	taskId: string;
	taskCode: string;
	status: TaskStatus;
	lastMessage: TaskMessage;
	payload: string;
}

export type ITask = Fields;

export class Task extends BaseModel<typeof Task, ITask> {
	static modelName = 'Task';
	static options = {
		idAttribute: 'taskId',
	};
	static fields = {
		taskId: attr(),
		taskCode: attr(),
		status: attr(),
		lastMessage: attr(),
		payload: attr(),
	};
}
