/**
 * Campaign Performance keys
 */
const PerformanceProperties = {
	connectionRate: 'connectionRate',
	replyRate: 'replyRate',
} as const;

/**
 * Timestamp
 */
const Timestamp = 'timestamp' as const;

/**
 * A type has timestamp
 */
type HasTimestamp = {
	[Timestamp]: number;
};

export type { HasTimestamp };

export { PerformanceProperties, Timestamp };
