import React from 'react';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import TimezoneSelector from '@copilot/common/components/selector/timezone';
import WeeklyScheduleSelector from '@copilot/common/components/selector/weeklyScheduleSelector';
import { DaySchedule } from '@copilot/data/responses/interface';
import BasicContainer from '@copilot/common/components/containers/basic';
import NewUserOnboardTitle from './newUserTitle';

interface INewUserScheduleProps {
	weeklySchedule: DaySchedule[];
	timezone: string;
	sync: boolean;
	onSyncUpdate: (state: boolean) => void;
	onTimezoneUpdate: (timezone: string) => void;
	onScheduleUpdate: (schedule: DaySchedule[]) => void;
}

/**
 * [Presentational] Returns component containing a title for the form and the form
 * @param props
 * @param weeklySchedule - the selected weeekly schedule
 * @param timezone - the selected timezone
 * @param sync - boolean value stating whether syncronization of days is true or false
 * @param onSyncUpdate - function performed upon sync button being toggled
 * @param onTimezoneUpdate - function performed upon timezone being changed
 * @param onScheduleUpdate - function performed upon schedule being adjusted
 * @returns Presentation component containing OnboardTitle, TimezoneSelector, and WeeklyScheduleSelector components.
 */
const NewUserSchedule: React.FC<INewUserScheduleProps> = (props) => {
	const { weeklySchedule, timezone, sync, onSyncUpdate, onTimezoneUpdate, onScheduleUpdate } =
		props;

	return (
		<Row justify="center">
			<Col xxl={20}>
				<NewUserOnboardTitle title="Timezone and Active Hours">
					<Text>
						Invites, follow-ups and automated messages will all be sent during the
						selected times.
					</Text>
				</NewUserOnboardTitle>
				<BasicContainer>
					<BasicContainer.Content>
						<Row gutter={[0, 24]}>
							<Col>
								<TimezoneSelector
									onTimezoneChange={onTimezoneUpdate}
									editable
									timezoneId={timezone}
								/>
							</Col>
						</Row>
						<h3>Active Hours</h3>
						<WeeklyScheduleSelector
							schedule={weeklySchedule}
							isSync={sync}
							onSyncUpdate={onSyncUpdate}
							onUpdate={onScheduleUpdate}
							descriptionText="We'll put your LinkedIn profile on autopilot and keep it naturally active within the hours you set."
							alertMessage="Campaigns can be active for a maximum of five days a week. It is recommended that you keep your campaign running for at least six hours a day."
							hideFooter
						/>
					</BasicContainer.Content>
				</BasicContainer>
			</Col>
		</Row>
	);
};

export default NewUserSchedule;
