import { Typography } from 'antd';
import { WarningOutlined, SyncOutlined } from '@ant-design/icons';

export function LoggedOutLIWarning(isRetrying = true) {
	return isRetrying ? (
		<Typography.Text type="warning">
			<SyncOutlined /> Wait for your LinkedIn account to finish syncing before proceeding
		</Typography.Text>
	) : (
		<Typography.Text type="danger">
			<WarningOutlined /> Reconnect your LinkedIn account
		</Typography.Text>
	);
}
