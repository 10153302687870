import { TaskResponse, TaskStatus } from '../interface';

export class TaskModel implements TaskResponse {
	public taskId: TaskResponse['taskId'];
	public taskCode: TaskResponse['taskCode'];
	public status: TaskResponse['status'];
	public lastMessage: TaskResponse['lastMessage'];
	public payload: TaskResponse['payload'];

	constructor(task: TaskResponse) {
		this.taskId = task.taskId;
		this.taskCode = task.taskCode;
		this.status = task.status;
		this.lastMessage = task.lastMessage;
		this.payload = task.payload;
	}

	/**
	 * Get whether the task is complete
	 */
	public get isComplete() {
		return [TaskStatus.Complete, TaskStatus.Aborted, TaskStatus.Failed].includes(this.status);
	}

	/**
	 * Get whether the task is unsuccesful
	 */
	public get isUnsuccessful() {
		return [TaskStatus.Aborted, TaskStatus.Failed].includes(this.status);
	}

	/**
	 * Deserialize and return the payload
	 */
	public getPayload<P>(): P | null {
		try {
			return JSON.parse(this.payload);
		} catch (err) {
			return null;
		}
	}
}
