import React, { useMemo } from 'react';
import { Row, Col, Input, Button, Select, Form } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ProxySelectors } from '@copilot/common/store/selectors/proxy';
import { Config } from '@copilot/common/config';
import SecurePasswordField from '../common/inputs/password';
import { getSelectOptionsFromInstances } from '@copilot/cs/src/components/dropdown/instanceData';
import { InstanceDataSelectors } from '@copilot/common/store/selectors/instance';
import { ITenant } from '@copilot/data/graphql/_generated';
import { isUndefined } from 'lodash';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';

export interface ITeamInfoFormValues {
	instanceId: string | null;
	tenantId: string | null;
	customerOrganizationName: string;
	customerFirstName: string;
	customerLastName: string;
	customerStripeId: string;
	customerEmail: string;
	newPassword: string;
	customerConfirmPassword: string;
	proxy: string;
}
interface ITeamInfoFormProps {
	onNext: (values: ITeamInfoFormValues) => Promise<any> | void;
	isError: boolean;
	isSubmitting: boolean;
	defaultInstanceId: string;
	nodeTenantIdMap: Map<string, ITenant> | undefined;
}

/**
 * Form used to create a new Teams Organization
 * @param onNext callback that is called when the form is submitted
 * @param isError is there an error in the user input?
 * @param isSubmitting is the form currently submitting?
 */
const TeamInfoForm: React.FC<ITeamInfoFormProps> = (props) => {
	const { onNext, isError, isSubmitting, nodeTenantIdMap } = props;
	const [form] = Form.useForm();
	const isCleverlyOnTeamsFeatureEnabled = useFeatureToggle(Features.CleverlyOnTeamsFeature);
	const instances = useSelector(InstanceDataSelectors.getInstances);
	const proxyMapping = useSelector(ProxySelectors.getProxies);

	const handleFinish = React.useCallback(
		(values: ITeamInfoFormValues) => {
			const copy = { ...values };
			onNext(copy);
		},
		[onNext]
	);

	const vpnOptions = useMemo(
		() =>
			proxyMapping.map((proxy: any) => (
				<Select.Option key={proxy.value} value={proxy.value}>
					{proxy.name}
				</Select.Option>
			)),
		[proxyMapping]
	);

	const showTenantField = !isUndefined(nodeTenantIdMap);
	let tenantOptions: JSX.Element[] = [];
	if (showTenantField) {
		tenantOptions = Array.from(nodeTenantIdMap).map(([id, tenant]) => (
			<Select.Option key={id} value={id}>
				{tenant.name}
			</Select.Option>
		));
	}
	const tenantField = (
		<BlockFormItem
			name="tenantId"
			label="Master Account"
			style={{ fontWeight: 'bold' }}
			rules={[{ required: true }]}
		>
			<Select style={{ minWidth: '150px' }}>{tenantOptions}</Select>
		</BlockFormItem>
	);

	const instanceOptions = useMemo(() => getSelectOptionsFromInstances(instances), [instances]);
	const instanceField = (
		<BlockFormItem
			name="instanceId"
			label="Domain"
			style={{ fontWeight: 'bold' }}
			rules={[{ required: true }]}
		>
			<Select style={{ minWidth: '150px' }}>{instanceOptions}</Select>
		</BlockFormItem>
	);

	const organizationNameField = (
		<BlockFormItem
			name="customerOrganizationName"
			label={
				<span>
					<strong>Organization Name</strong>
				</span>
			}
			rules={[
				{
					required: true,
					message: 'Organization Name required',
				},
			]}
		>
			<Input />
		</BlockFormItem>
	);

	const firstNameField = (
		<BlockFormItem
			name="customerFirstName"
			label={
				<span>
					<strong>Customer First Name</strong>
				</span>
			}
			rules={[
				{
					required: true,
					message: 'Name required',
				},
			]}
		>
			<Input />
		</BlockFormItem>
	);

	const lastNameField = (
		<BlockFormItem
			name="customerLastName"
			label={
				<span>
					<strong>Customer Last Name</strong>
				</span>
			}
			rules={[
				{
					required: true,
					message: 'Name required',
				},
			]}
		>
			<Input />
		</BlockFormItem>
	);

	const loginEmailField = (
		<BlockFormItem
			name="customerEmail"
			label={
				<span>
					<strong>Login Email</strong>
				</span>
			}
			rules={[
				{
					required: true,
					type: 'email',
					message: 'Please enter a valid E-mail.',
				},
			]}
		>
			<Input type="email" />
		</BlockFormItem>
	);

	const proxyField = (
		<BlockFormItem
			name="proxy"
			label="Proxy Location"
			style={{ fontWeight: 'bold' }}
			rules={[{ required: true }]}
		>
			<Select style={{ minWidth: '150px' }}>{vpnOptions}</Select>
		</BlockFormItem>
	);

	const stripeField = (
		<BlockFormItem
			name="customerStripeId"
			label={
				<span>
					<strong>Stripe Id</strong>
				</span>
			}
		>
			<Input />
		</BlockFormItem>
	);

	return (
		<Form
			form={form}
			onFinish={handleFinish}
			scrollToFirstError
			initialValues={{
				tenantId: '',
				instanceId: props.defaultInstanceId,
				customerOrganizationName: '',
				customerFirstName: '',
				customerLastName: '',
				customerEmail: '',
				customerStripeId: '',
			}}
		>
			<Row style={{ display: 'block' }}>
				<Col style={{ textAlign: 'center' }}>
					<h2>Create a new Organization</h2>
				</Col>
			</Row>
			<Row style={{ display: 'block' }}>
				<Col style={{ padding: '10px 0' }}>
					This will be used to access the customers dashboard in the future. Organization
					name and Password can be changed at any time in the future.
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{showTenantField ? tenantField : null}
					{!isCleverlyOnTeamsFeatureEnabled ? instanceField : null}
					{organizationNameField}
					{firstNameField}
					{lastNameField}
					{loginEmailField}
					<SecurePasswordField isWithConfirm form={form} />
					{proxyField}
					{!Config.isAgency && stripeField}
				</Col>
			</Row>
			<Col style={{ display: 'flex', justifyContent: 'center' }}>
				<Button type="primary" htmlType="submit" disabled={isSubmitting}>
					Create
				</Button>
			</Col>
			<br />
			{isError && (
				<div style={{ color: 'red' }}>
					Customer creation failed. Customer exists or you have used up all your seats
					allocated.
				</div>
			)}
		</Form>
	);
};

const BlockFormItem = styled(Form.Item)`
	display: block;
`;

export default TeamInfoForm;
