import { useCallback, useContext, useMemo } from 'react';
import { CSVColumnDefinition } from '@copilot/common/components/componentModels/filterTypeDefinition';
import ColumnSelector from '@copilot/common/components/tables/columnSelector';
import { TableContextAny } from './types';

/**
 * [Presentational] Table module column selector component
 */
export const TableModuleColumnSelector = (): JSX.Element => {
	const { state, actions } = useContext(TableContextAny);
	const columns = useMemo(
		() =>
			state.columnContextList.map<CSVColumnDefinition>((column) => ({
				...column,
				isVisible: !state.hiddenColumnMap[column.key],
				label: typeof column.title === 'string' ? column.title : column.key,
			})),
		[state.columnContextList, state.hiddenColumnMap]
	);

	const handleUpdate = useCallback(
		(updatedColumns: any[]) => {
			actions.updateHiddenColumns(
				updatedColumns.map((columnToUpdate) => ({
					columnKey: columnToUpdate.key,
					isVisible: columnToUpdate.isVisible,
				}))
			);
		},
		[actions.updateColumnContextList]
	);
	return <ColumnSelector columns={columns} updateColumns={handleUpdate} />;
};
