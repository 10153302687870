import { CAMPAIGN_STOP_AUTOMATION_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';
import { Button, Popconfirm, Row } from 'antd';

interface StopAutomationActionProps {
	/**
	 * campaign connection id
	 */
	connectionId: string;
	/**
	 * campaign member id
	 */
	campaignMemberId: string;
	/**
	 * whether or not the stop automation action is hidden
	 */
	isRemoveHidden: boolean;
	/**
	 * Callback for stopping automation
	 */
	removeProspect: (connectionId: string, campaignMemberId: string) => unknown;
}

/**
 * [Presentation] Button for stopping automation action
 * @constructor
 */
export function StopAutomationAction({
	removeProspect,
	connectionId,
	campaignMemberId,
	isRemoveHidden,
}: StopAutomationActionProps) {
	if (!connectionId || isRemoveHidden) return null;
	return (
		<Popconfirm
			title={
				<Row style={{ maxWidth: '250px' }}>
					Are you sure you wish to stop automation for this prospect?
				</Row>
			}
			onConfirm={(e) => {
				e?.stopPropagation();
				removeProspect(connectionId, campaignMemberId);
			}}
			onCancel={(e) => e?.stopPropagation()}
			okText="Yes"
			cancelText="No"
		>
			<Button
				type="link"
				style={{ padding: 0 }}
				onClick={(e: React.MouseEvent) => e.stopPropagation()}
				data-tracking-id={CAMPAIGN_STOP_AUTOMATION_TRACKING_ID}
			>
				Stop automation
			</Button>
		</Popconfirm>
	);
}
