/* eslint-disable react/jsx-indent */
import { SIDEBAR_WIDTH } from '@copilot/common/pages/layouts/base';
import { Card } from 'antd';
import { PropsWithChildren, useContext } from 'react';
import { CSSProperties, ThemeContext } from 'styled-components';

interface IBottomPanelProps {
	style?: CSSProperties;
}

/**
 * Container placed at the bottom of the viewport
 * @param {CSSProperties} [style] style to apply on the container
 */
const BottomPanel = (props: PropsWithChildren<IBottomPanelProps>) => {
	const themeContext = useContext(ThemeContext);
	return (
		<div
			style={{
				position: 'fixed',
				bottom: '0px',
				width: `calc(100% - ${SIDEBAR_WIDTH} - ${themeContext['@card-padding-base']} - ${themeContext['@card-padding-base']})`,
				zIndex: 10,
				...props.style,
			}}
		>
			<Card>{props.children}</Card>
		</div>
	);
};

export default BottomPanel;
