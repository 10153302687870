import { FC, useState } from 'react';
import NameEditorComponent from './tagEditor';
import BaseModal from '@copilot/common/components/modals/wrappers/base';
import { ModalType } from '@copilot/common/store/models/redux/modal';
import { useCloseModal } from '@copilot/common/hooks/modal';
import { TagModalProps } from './interface';
import { Tag } from '@copilot/common/pages/organizationDashboard/tags/data/models';
import NotificationManager from '@copilot/common/utils/notificationManager';

/**
 * @param {Tag} [oldTag] the existing tag
 * @param {NameValidator} nameEditorValidator validates the name in the name editor
 * @callback onContinue Function called when user completes tag update/creation
 */
const TagCreationModal: FC<TagModalProps> = (props) => {
	const { oldTag, nameEditorValidator, onContinue, ...rest } = props;
	const width = 400;
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const closeModal = useCloseModal(ModalType.TagsModal);

	const onClickContinue = (name: string) => {
		if (name) {
			const tag: Tag = {
				name: name,
				description: name,
				id: oldTag?.id ?? '',
				level: oldTag?.level ?? 0,
			};

			setIsLoading(true);

			try {
				onContinue(tag);
			} catch (err) {
				NotificationManager.showErrorNotification({
					message: 'Update failed. Please try again later.',
				});
			} finally {
				setIsLoading(false);
			}

			closeModal();
		}
	};

	return (
		<BaseModal
			width={width}
			centered
			style={{ textAlign: 'center' }}
			{...rest}
			onCancel={closeModal}
		>
			<NameEditorComponent
				description={oldTag ? 'Rename Selected Tag' : 'Tag Name'}
				continueText={oldTag ? 'Rename' : 'Create'}
				nameValidator={nameEditorValidator}
				onContinue={onClickContinue}
				isLoading={isLoading}
			/>
		</BaseModal>
	);
};

export default TagCreationModal;
