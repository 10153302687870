import React from 'react';
import { Tag, Row, theme } from 'antd';
import styled from 'styled-components';

const { useToken } = theme;
const TagStyle = styled(Tag)`
	&& {
		font-size: 0.85em;
		margin-right: 0;
	}
`;

export interface IBooleanItemProps {
	label: string;
	isVisible: boolean;
	onClose: () => void;
}

const BooleanItem: React.FC<IBooleanItemProps> = (props) => {
	const { label, isVisible, onClose } = props;
	const { token } = useToken();
	return (
		<Row>
			<TagStyle visible={isVisible} closable onClose={onClose} color={token.colorPrimary}>
				{label}
			</TagStyle>
		</Row>
	);
};

export default BooleanItem;
