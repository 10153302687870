// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Lh3mWORLQxKR34hJ5Erw .copilot-popover-inner {\n  padding: 8px;\n  max-height: 450px;\n  border-radius: 8px;\n  overflow-y: auto;\n}\n.Lh3mWORLQxKR34hJ5Erw .copilot-popover-inner .copilot-menu {\n  border-inline-end: 0;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/forms/messageForm/smartReply/smartReply.module.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;AALA;EAQM,oBAAA;AAAN","sourcesContent":[".smartReplyPromptContainer {\n  :global(.copilot-popover-inner) {\n    padding: 8px;\n    max-height: 450px;\n    border-radius: 8px;\n    overflow-y: auto;\n    \n    :global(.copilot-menu) {\n      border-inline-end: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smartReplyPromptContainer": "Lh3mWORLQxKR34hJ5Erw"
};
export default ___CSS_LOADER_EXPORT___;
