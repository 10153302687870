import { IMemberLinkedInProfile, MemberLinkedInProfile } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = MemberLinkedInProfile.modelName;
export namespace LinkedinActions {
	export const loadMemberLinkedinProfile = (profile: Partial<IMemberLinkedInProfile>) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: profile,
	});

	export const resetMemberLinkedinProfile = () => ({
		type: EntitiesActionTypes.Reset,
		itemType,
	});
}
