import { CONNECTIONS } from './constant';
import { PaginatedResponse } from '@copilot/data/responses/interface';
import { ConnectionViewModel } from './types';

export const ConnectionsLoadActionType = `LOAD_${CONNECTIONS}` as const;

/**
 * The action to load connections page
 */
export type ConnectionsLoadAction = {
	type: typeof ConnectionsLoadActionType;
	organizationId: string;
};

/**
 * load connections action
 * @param organizationId
 * @returns
 */
export const loadConnections = (organizationId: string): ConnectionsLoadAction => ({
	type: ConnectionsLoadActionType,
	organizationId,
});

export const ConnectionsLoadSuccessActionType = `LOAD_${CONNECTIONS}_SUCCESS` as const;

export type ConnectionsLoadSuccessAction = {
	type: typeof ConnectionsLoadSuccessActionType;
	payload: PaginatedResponse<ConnectionViewModel>;
};

/**
 * load connections success action
 * @param payload
 */
export const loadConnectionsSuccess = (
	payload: PaginatedResponse<ConnectionViewModel>
): ConnectionsLoadSuccessAction => ({
	type: ConnectionsLoadSuccessActionType,
	payload,
});

export const ConnectionsLoadFailedActionType = `LOAD_${CONNECTIONS}_FAILED` as const;

export type ConnectionsLoadFailedAction = {
	type: typeof ConnectionsLoadFailedActionType;
};

/**
 * load connections failed action
 */
export const loadConnectionsFailed = (): ConnectionsLoadFailedAction => ({
	type: ConnectionsLoadFailedActionType,
});

export type ConnectionsActions =
	| ConnectionsLoadAction
	| ConnectionsLoadSuccessAction
	| ConnectionsLoadFailedAction;
