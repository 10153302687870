import {
	INITIATE_NODE,
	NodeToStageNameMap,
	NurtureAutomationStepLabels,
	StepTriggerLabelMap,
	TERMINATE_NODE,
} from './consts';
import { ConnectionResponse } from '@copilot/data/responses/interface';
import { convertToOrdinalAbbr } from '@copilot/common/utils';
import isNil from 'lodash/isNil';
import { NurtureSearchListResult } from '@copilot/common/pages/campaignDashboard/nurtureList/types';

/**
 *
 * @param d Get the connection status based on the current step
 * @returns
 */
export function getConnectionStatus(d: ConnectionResponse) {
	switch (d.currentStep) {
		case INITIATE_NODE:
			return NodeToStageNameMap[d.currentStep];
		case TERMINATE_NODE: {
			if (d.currentStepTrigger)
				return (
					StepTriggerLabelMap[d.currentStepTrigger] ?? NodeToStageNameMap[d.currentStep]
				);
			else return NodeToStageNameMap[d.currentStep];
		}
		default:
			return `Awaiting ${getNextNode(d.currentStep)} Message`;
	}
}

/**
 * Get the ordinal abbreviation for the next node in a sequence
 * @param nodeIdx
 */
export function getNextNode(nodeIdx: number) {
	return convertToOrdinalAbbr(nodeIdx + 1);
}

/**
 * Get the connection status based on next node and termination reason
 * @param isManualTermination
 * @param nextAutomationNode
 */
export function getConnectionStatusV2({
	isManualTermination,
	nextAutomationNode,
}: NurtureSearchListResult) {
	if (!isNil(isManualTermination) && isManualTermination) {
		return NurtureAutomationStepLabels.AUTOMATION_STOPPED;
	} else if (isNil(nextAutomationNode)) {
		return NurtureAutomationStepLabels.AUTOMATION_DONE;
	} else {
		return `Awaiting ${nextAutomationNode.name}`;
	}
}
