import { createContext } from 'react';

interface IWizardContext {
	currentNode: number;
	onNodeChange: (node: number, modifications?: { [k: string]: any }) => void;
}

export const WizardContext = createContext<IWizardContext>({
	currentNode: 0,
	onNodeChange: () => {},
});

interface IFullPageWizardContext {
	isSaved: boolean;
	onSave: (status: boolean) => void;
}

export const FullPageWizardContext = createContext<IFullPageWizardContext>({
	isSaved: true,
	onSave: () => {},
});
