// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p8ROKGKATL_C9vBDMVOv {\n  margin-top: 24px;\n}\n.p8ROKGKATL_C9vBDMVOv span {\n  font-size: 14px;\n  vertical-align: middle;\n  color: #8899b1;\n}\n.cv9xJF8kNkkXh_UXHLmu {\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/campaignDashboard/summaryV2/campaignPerformance/campaignPerformance.module.less"],"names":[],"mappings":"AAGA;EACE,gBAAA;AAFF;AACA;EAII,eAAA;EACA,sBAAA;EACA,cAAA;AAFJ;AAMA;EACE,gBAAA;AAJF","sourcesContent":["@import '../../../../../styles/antd-variables.less';\n@import '../../../../../styles/common-variables';\n\n.toggleWrapper {\n  margin-top: @spacingLG;\n  \n  span {\n    font-size: @fontSizeMD;\n    vertical-align: middle;\n    color: @Neutral-50;\n  }\n}\n\n.title {\n  font-weight: @fontWeightMedium;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleWrapper": "p8ROKGKATL_C9vBDMVOv",
	"title": "cv9xJF8kNkkXh_UXHLmu"
};
export default ___CSS_LOADER_EXPORT___;
