import { EntitiesActionTypes } from './actions/types';

/**
 * Base actions class with the basic functionality for updating the redux store
 */
export class Actions<T extends { id: string }> {
	/** String that indicate which collection to update */
	private itemType: string;

	constructor(type: string) {
		this.itemType = type;
	}

	/**
	 * Load an item, or a list of items to the store
	 * @param payload item or items to load
	 */
	public load = (payload: Partial<T> | Partial<T>[]) => ({
		payload,
		type: EntitiesActionTypes.Create,
		itemType: this.itemType,
	});

	/**
	 * Create or Update an entry based on the id
	 * @param payload the updated item
	 */
	public updateOne = (payload: PartialPick<T, 'id'>) => ({
		payload,
		type: EntitiesActionTypes.Update,
		itemType: this.itemType,
	});

	/**
	 * Create or Update multiple entries based on the id
	 * @param payload the updated item
	 */
	public updateMany = (payload: PartialPick<T, 'id'>[]) => ({
		payload,
		type: EntitiesActionTypes.Update,
		itemType: this.itemType,
	});

	/**
	 * Delete an item based on the payload's id
	 * @param payload item that conatins an id
	 */
	public deleteOne = (payload: PartialPick<T, 'id'>) => ({
		payload,
		type: EntitiesActionTypes.Delete,
		itemType: this.itemType,
	});

	/**
	 * Delete multiple items based on the payload's id
	 * @param payload item that conatins an id
	 */
	public deleteMany = (payload: PartialPick<T, 'id'>[]) => ({
		payload,
		type: EntitiesActionTypes.Delete,
		itemType: this.itemType,
	});

	/**
	 * Drop all entries from the store
	 */
	public resetAll = () => ({
		type: EntitiesActionTypes.Reset,
		itemType: this.itemType,
	});
}
