import { getFormattedDate, LONG_DATE_FORMAT } from '@copilot/common/utils/dateFormat';
import { Row, Typography, Radio, Col, Tag, Space } from 'antd';

import { RadioChangeEvent } from 'antd/lib/radio';

const { Title, Text } = Typography;

enum EffectiveDate {
	Next = 'next',
	Immediately = 'immediately',
}

/**
 * Properties to display effective date summary.
 */
interface EffectiveDateSummaryProps {
	/**
	 * True if we effective date is immediately. False if effective date is next billing cycle.
	 */
	updateNow?: boolean;
	/**
	 * The next billing date to charge if effective date is next billing cycle.
	 */
	nextBillingDate?: string;
	/**
	 * Formatted prorated amount to display if effective date is immediately.
	 */
	formattedProratedAmount?: string;
	/**
	 * The trial to paid end date.
	 */
	trialToPaidEndDate?: string;
	/**
	 * Callback for effective date selected.
	 */
	onUpdateNowToggle: (updateNow: boolean) => void;
	/**
	 * If a customer’s product does not have a change in frequency
	 */
	isTermUpdate: boolean;
}

/**
 * [Presentational] Effective date summary with next billing cycle & immediately options.
 */
const EffectiveDateSummary: React.FC<EffectiveDateSummaryProps> = ({
	isTermUpdate,
	updateNow,
	nextBillingDate,
	formattedProratedAmount,
	trialToPaidEndDate,
	onUpdateNowToggle,
}) => {
	const nextBillingDateString = nextBillingDate
		? getFormattedDate(nextBillingDate, LONG_DATE_FORMAT)
		: 'next billing cycle';
	const effectiveDateText = updateNow
		? (trialToPaidEndDate
				? 'Subscription and billing will start '
				: 'Charges will be prorated, and billed ') +
		  (isTermUpdate ? 'immediately' : nextBillingDateString)
		: trialToPaidEndDate
		? `Subscription will be applied on Trial end date (${getFormattedDate(
				trialToPaidEndDate,
				LONG_DATE_FORMAT
		  )})`
		: `Charges will be applied on ${nextBillingDateString}${
				nextBillingDate ? ' (next billing cycle)' : ''
		  }`;

	const onRadioButtonChange = (event: RadioChangeEvent) => {
		onUpdateNowToggle(event.target.value === EffectiveDate.Immediately);
	};

	return (
		<>
			<Title level={5}>
				<Space>
					{trialToPaidEndDate ? (
						<>
							Subscription Start Date
							<Tag>Trial to Paid</Tag>
						</>
					) : (
						<>Effective Date</>
					)}
				</Space>
			</Title>
			<Row justify="space-between">
				<Col>
					<Radio.Group
						onChange={onRadioButtonChange}
						defaultValue={updateNow ? EffectiveDate.Immediately : EffectiveDate.Next}
						buttonStyle="solid"
					>
						<Radio.Button value={EffectiveDate.Next}>
							{trialToPaidEndDate ? 'Trial End Date' : 'Next Billing Cycle'}
						</Radio.Button>
						<Radio.Button value={EffectiveDate.Immediately}>Immediately</Radio.Button>
					</Radio.Group>
				</Col>
				<Col style={{ textAlign: 'right', maxWidth: '300px' }}>
					<Text type="secondary">{effectiveDateText}</Text>
				</Col>
			</Row>
			{!!formattedProratedAmount && updateNow && (
				<Row style={{ marginTop: '20px' }} justify="space-between">
					<Col>
						<Text>Carry over from previous billing cycle</Text>
					</Col>
					<Col>
						<Text>{formattedProratedAmount}</Text>
					</Col>
				</Row>
			)}
		</>
	);
};

export default EffectiveDateSummary;
