import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Typography, Button, Space } from 'antd';
import { SentimentMap } from '@copilot/common/store/models/const/enum';
import BasicContainer from '@copilot/common/components/containers/basic';
import { InboxManager } from '@copilot/data';
import { useFetch } from '@copilot/common/hooks/common';
import { InboxActions } from '@copilot/common/store/actions/inbox';
import { InboxMessageSelectors } from '@copilot/common/store/selectors/inbox';
import { IInboxMessage } from '../../../../common/store/models/redux';

import HeaderContentLayout from '../../../../common/pages/layouts/headerContent';
import { INTERESTED, MAYBE, NOT_INTERESTED } from '../../../../common/pages/inbox/ui/constant';
import LoadingIndicator from '../../../../common/components/loadingIndicator/spinner';
import { SentimentNameMap } from '../../../../common/constant/enum';
import { getFormattedDate } from '../../../../common/utils/dateFormat';
import {
	SlideRightThenShrinkAnimation,
	SlideRightThenShrinkAnimationGroup,
} from '../../../../common/animation/slideRightThenShrink';

interface MessageCategorizerContainerProps {
	/**
	 * Messages we're categorizing
	 */
	messages: IInboxMessage[];
	/**
	 * Behavior for categorization
	 */
	onCategorize: CategorizationHandler;
}

/**
 * Container for list of messages to categorize
 * @param {MessageCategorizerContainerProps} props
 * @returns
 */
const MessageCategorizerContainer: FC<MessageCategorizerContainerProps> = ({
	messages,
	onCategorize,
}) => (
	<SlideRightThenShrinkAnimationGroup>
		{messages.map((message) => (
			<SlideRightThenShrinkAnimation timeout={800} key={message.id}>
				<div style={{ marginBottom: '16px' }}>
					<MessageCategorizer message={message} onCategorize={onCategorize} />
				</div>
			</SlideRightThenShrinkAnimation>
		))}
	</SlideRightThenShrinkAnimationGroup>
);

type AiResult = Record<string, { confidence: number; value: string }>;
type CategorizationHandler = (
	id: string,
	threadId: string,
	categorization: SentimentMap
) => unknown;

interface MessageCategorizerButtonsProps {
	/**
	 * AI results
	 */
	aiResult: AiResult;
	/**
	 * Id of the message item
	 */
	id: string;
	/**
	 * Thread Id for the message
	 */
	threadId: string;
	/**
	 * Handler for categorization behavior
	 */
	onCategorize: CategorizationHandler;
}

/**
 * Messages for categorizing messages
 * @param {MessageCategorizerButtonsProps} props
 * @returns
 */
const MessageCategorizerButtons: FC<MessageCategorizerButtonsProps> = ({
	aiResult,
	id,
	threadId,
	onCategorize,
}) => (
	<Row gutter={16}>
		{ALLOWED_CATEGORIZATIONS.map((cat) => {
			const sentiment = SentimentMap[cat];
			const confidence = aiResult[sentiment]
				? Math.round(aiResult[sentiment].confidence * 100)
				: 0;
			return (
				<Col key={cat}>
					<Button type="primary" onClick={() => onCategorize(id, threadId, sentiment)}>
						{`${confidence} ${SentimentNameMap[sentiment]}`}
					</Button>
				</Col>
			);
		})}
	</Row>
);

interface MessageCategorizerProps {
	/**
	 * Message to categorize
	 */
	message: IInboxMessage;
	/**
	 * Categorization handler
	 */
	onCategorize: CategorizationHandler;
}

const MessageCategorizer: FC<MessageCategorizerProps> = ({ message, onCategorize }) => {
	const timestamp = message.lastReceived.timestamp
		? getFormattedDate(message.lastReceived.timestamp, 'MMM DD, YYYY')
		: 'Unknown';
	return (
		<BasicContainer>
			<BasicContainer.Header>
				<Typography.Title
					level={5}
				>{`To: ${message.orgMemberName} on ${timestamp}`}
    </Typography.Title>
			</BasicContainer.Header>
			<BasicContainer.Content>
				<Space direction="vertical" size="middle">
					<Typography.Text style={{ fontSize: '1.5em' }}>
						{message.lastReceived.message}
					</Typography.Text>
					<MessageCategorizerButtons
						id={message.id}
						aiResult={message.aiResult}
						threadId={message.threadId}
						onCategorize={onCategorize}
					/>
				</Space>
			</BasicContainer.Content>
		</BasicContainer>
	);
};

interface CategorizePageProps {}

const ALLOWED_TABS = [INTERESTED, NOT_INTERESTED, MAYBE] as const;
const ALLOWED_CATEGORIZATIONS = ALLOWED_TABS;

/**
 * Categorization page to categorize messages sentiment
 * @param props
 * @returns
 */
const CategorizePage: FC<CategorizePageProps> = () => {
	const pageSize = 10;
	const [inboxFetch, fetchInboxMessages] = useFetch(
		InboxManager.getMessagesByCategoryVA,
		InboxActions.loadInboxMessages,
		(r) => r.results
	);
	const [, categorizeThread] = useFetch(InboxManager.categorizeThread);
	const messages = useSelector(InboxMessageSelectors.getInboxMessages);
	const inboxTotal = inboxFetch.data?.totalCount ?? 0;
	const canLoadMore = inboxFetch.data
		? inboxFetch.data.pageIndex < inboxFetch.data.numPages
		: !!inboxFetch.error; // Can load more as long as we haven't hit the last page. Don't allow loading more if we received an error from the request
	const dispatch = useDispatch();

	const [tab, setTab] = useState<string>(ALLOWED_TABS[0]);

	useEffect(() => {
		// TODO: COPILOT-3054 update to use Inbox with Redux Saga
		dispatch(InboxActions.resetInboxMessages());
		fetchInboxMessages(tab);
	}, [tab]);

	const handleCategorization: CategorizationHandler = (id, threadId, categorization) => {
		categorizeThread(categorization, threadId);
		dispatch(InboxActions.deleteInboxMessage({ id }));
	};

	const loadMore = () => {
		const page = Math.round((messages.length + 1) / pageSize);
		fetchInboxMessages(tab, { page, pageSize });
	};

	return (
		<HeaderContentLayout>
			<HeaderContentLayout.Header>
				<Typography.Title level={3}>Categorization Page</Typography.Title>
				<Typography.Title level={5}>{`Total: ${inboxTotal}`}</Typography.Title>
			</HeaderContentLayout.Header>
			<HeaderContentLayout.Tabs
				activeKey={tab}
				onTabClick={(key) => {
					setTab(key);
				}}
			>
				{ALLOWED_TABS.map((tabKey) => (
					<HeaderContentLayout.TabPanes tab={SentimentMap[tabKey]} key={tabKey}>
						<LoadingIndicator isLoading={inboxFetch.isFetching}>
							<Space direction="vertical" size="middle" style={{ width: '100%' }}>
								<MessageCategorizerContainer
									messages={messages}
									onCategorize={handleCategorization}
								/>
								{canLoadMore && (
									<Button
										block
										onClick={loadMore}
										disabled={inboxFetch.isFetching}
									>
										Load More
									</Button>
								)}
							</Space>
						</LoadingIndicator>
					</HeaderContentLayout.TabPanes>
				))}
			</HeaderContentLayout.Tabs>
		</HeaderContentLayout>
	);
};

export default CategorizePage;
