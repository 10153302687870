import { LimitsEditView } from '@copilot/common/components/editors/allocateInvite';
import { useUsageLimitsHelper } from '@copilot/common/components/editors/allocateInvite/hooks';
import { Allocation } from '@copilot/common/components/editors/allocateInvite/interface';
import {
	getAllocations,
	createProspectingAllocation,
	createNurtureAllocation,
} from '@copilot/common/components/editors/allocateInvite/utils';
import { useEffectAsync, useFetch } from '@copilot/common/hooks/common';
import { RECOMMENDED_DAILY_INVITE } from '@copilot/common/pages/dashboard/const';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { TitleWithEnlargedNumber } from '@copilot/common/components/message';
import { DailyAllocationType } from '@copilot/common/utils/constant';
import drawerManager from '@copilot/common/utils/drawerManager';
import { CampaignManager, LinkedInManager } from '@copilot/data';
import { CampaignType } from '@copilot/data/responses/interface';
import { Alert, Button, Col, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import BaseDrawer from '../base';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';

const WarningCol = styled(Col)`
	${(props) => {
		const prefix = props.theme['@ant-prefix'];
		return `
		.${prefix}-alert-message {
			color: red;
        }`;
	}}
`;

const Summary: { [k: string]: string } = {
	[DailyAllocationType.Invites]: 'Total Daily Invites used',
	[DailyAllocationType.Messages]: 'Total Daily Nurture Messages used',
};
const Title: { [k: string]: string } = {
	[DailyAllocationType.Invites]: 'Change Invite Allocation',
	[DailyAllocationType.Messages]: 'Change Nurture Message Allocation',
};
const LimitDescription: { [k: string]: string } = {
	[DailyAllocationType.Invites]: 'TOTAL ALLOWABLE DAILY INVITES:',
	[DailyAllocationType.Messages]: 'TOTAL ALLOWABLE DAILY NURTURE MESSAGES:',
};
const AllocationTypeDisplay: { [k: string]: string } = {
	[DailyAllocationType.Invites]: 'Invites',
	[DailyAllocationType.Messages]: 'Messages',
};
interface IUsageLimitsDrawerProps extends React.ComponentProps<typeof BaseDrawer> {
	campaignType: CampaignType;
}

type DrawerContent = {
	allocationType: DailyAllocationType;
	description: string;
	recommendedValue?: number;
};

const getDrawerContentByCampaignType = (campaignType: CampaignType): DrawerContent => {
	switch (campaignType) {
		case CampaignType.Prospecting:
			return {
				allocationType: DailyAllocationType.Invites,
				description: `We recommend you start at ${RECOMMENDED_DAILY_INVITE} invites per day to get started`,
				recommendedValue: RECOMMENDED_DAILY_INVITE,
			};
		case CampaignType.Nurture:
			return {
				allocationType: DailyAllocationType.Messages,
				description: '',
			};
		default:
			return UtilityFunctions.assertUnreachable(campaignType);
	}
};

/**
 * Drawer for updating usage limits
 */

const UsageLimitsDrawer: React.FC<IUsageLimitsDrawerProps> = (props) => {
	const { campaignType, ...baseDrawerProps } = props;
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const saveUsageLimits = useUsageLimitsHelper(activeMember?.id);
	const [campaignsFetch, fetchCampaigns] = useFetch(
		CampaignManager.getLinkedInCampaignsByOrganization,
		CampaignActions.loadCampaigns,
		(r) => r.results
	);
	const [orgLinkedInSettings, fetchOrgLinkedInSettings] = useFetch(
		LinkedInManager.getLinkedInOrganizationSettings
	);

	const isLoading = useMemo(
		() => campaignsFetch.isFetching || orgLinkedInSettings.isFetching,
		[campaignsFetch.isFetching, orgLinkedInSettings.isFetching]
	);

	useEffectAsync(async () => {
		if (activeMember?.organizationId) {
			await fetchCampaigns(activeMember.organizationId);
			await fetchOrgLinkedInSettings(activeMember.organizationId);
		}
	}, [activeMember?.organizationId]);

	const [allocations, setAllocations] = useState<Allocation[]>([]);
	const maxAllocations = useMemo(() => {
		const maxAllocationType =
			campaignType === CampaignType.Prospecting
				? orgLinkedInSettings.data?.dailyInvitesPerUser
				: orgLinkedInSettings.data?.dailyMessagesPerUser;
		return maxAllocationType;
	}, [orgLinkedInSettings.data]);
	const totalAllocated = useMemo(
		() => allocations.reduce<number>((acc, campaign) => (acc += campaign.allocation), 0),
		[allocations]
	);
	const isOverAllocated = useMemo(
		() => (maxAllocations ? totalAllocated > maxAllocations : false),
		[totalAllocated, maxAllocations]
	);

	const campaigns = useSelector(CampaignSelectors.getApprovedCampaignModels);
	const filteredCampaigns = useMemo(
		() => campaigns.filter((c) => c.type === campaignType),
		[campaigns, campaignType]
	);
	const campaignContent = getDrawerContentByCampaignType(campaignType);

	useEffect(() => {
		if (!activeMember?.id) return;
		setAllocations(
			getAllocations(
				filteredCampaigns,
				activeMember.id,
				campaignType === CampaignType.Prospecting
					? createProspectingAllocation
					: createNurtureAllocation
			)
		);
	}, [activeMember?.id, filteredCampaigns, campaignType]);

	const onAllocationChange = useCallback(
		(v: number, index: number) => {
			if (isNaN(v)) return;
			const newAllocation = [...allocations];
			newAllocation[index] = { ...newAllocation[index], allocation: Math.round(v) };
			setAllocations(newAllocation);
		},
		[allocations]
	);

	const handleSave = useCallback(() => {
		saveUsageLimits(allocations, campaignContent.allocationType);
		drawerManager.closeDrawer();
	}, [allocations, campaignContent.allocationType]);

	return (
		<BaseDrawer closable {...baseDrawerProps}>
			<LoadingIndicator isLoading={isLoading}>
				<Row>
					<Col>
						<h3>
							<strong>{Title[campaignContent.allocationType]}</strong>
						</h3>
					</Col>
				</Row>
				<Row gutter={[0, 16]}>
					<Col>{campaignContent.description}</Col>
				</Row>
				<Row gutter={[0, 16]}>
					<Col>
						<Alert
							message={
								<TitleWithEnlargedNumber
									title={LimitDescription[campaignContent.allocationType]}
									count={maxAllocations ?? 0}
								/>
							}
							type="warning"
						/>
					</Col>
				</Row>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<LimitsEditView
							items={allocations}
							max={maxAllocations ?? 0}
							onValueChange={onAllocationChange}
							recommendValue={campaignContent.recommendedValue}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 16]} style={{ justifyContent: 'space-between' }}>
					<Col>{Summary[campaignContent.allocationType]}</Col>
					<Col>
						{`${totalAllocated} ${
							AllocationTypeDisplay[campaignContent.allocationType]
						}`}
					</Col>
				</Row>
				{isOverAllocated && (
					<Row gutter={[0, 16]}>
						<WarningCol span={24}>
							<Alert
								message="You are over your usage limit, please update to save changes"
								type="error"
								showIcon
							/>
						</WarningCol>
					</Row>
				)}

				<Row
					style={{
						position: 'relative',
						float: 'right',
						paddingBottom: '24px',
					}}
				>
					<Col style={{ marginRight: '15px' }}>
						<Button onClick={() => drawerManager.closeDrawer()}>Discard</Button>
					</Col>
					<Col>
						<Button type="primary" onClick={handleSave} disabled={isOverAllocated}>
							Save
						</Button>
					</Col>
				</Row>
			</LoadingIndicator>
		</BaseDrawer>
	);
};

export default UsageLimitsDrawer;
