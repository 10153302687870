import { ContactSelectors } from '@copilot/common/store/selectors/contact';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Button, Select, Tag, Skeleton } from 'antd';
import Summary from '../summary';
import ContactActivities from '../activity';
import TagSelector from '@copilot/common/components/forms/common/generics/tagselector';
import Tags from '../tags';
import { spacingMD } from '@copilot/common/constant/commonStyles';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { getAllOrgTags } from '@copilot/common/pages/organizationDashboard/tags/data/selectors';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { ProspectDrawerTrackingParams, useProspectDrawerTracking } from '../tracking';
import { dispatchContactUpdate } from './util';
import { UpdateContactWithNotification } from './constants';
import { OrmState, SessionBoundModel } from 'redux-orm';
import {
	Contact,
	ContactConnection,
	ConversationThread,
	IOrganizationMember,
} from '@copilot/common/store/models/redux';
import {
	ConnectionResponse,
	MemberLinkedInProfileResponse,
} from '@copilot/data/responses/interface';
import moment from 'moment';
import isNil from 'lodash/isNil';
import styles from './conversationPanel.module.less';
import { ContactDrawerTabs } from '../activity/constants';
import { AutomatedStep } from '@copilot/common/pages/inbox/ui/component/card/messageCard/types';

type ConversationPanelProps = {
	contactTags: string[];
	isMessagesLoading: boolean;
	contactId: string;
	updateProspectDrawerTracking: (newParams: ProspectDrawerTrackingParams) => void;
	contactConnection: SessionBoundModel<ContactConnection, {}> | null;
	isNewProspectDrawerEnabled: boolean;
	conversation: SessionBoundModel<ConversationThread, {}> | null;
	orgId: string;
	selectedConnection: ConnectionResponse | undefined;
	contactProfile: MemberLinkedInProfileResponse | undefined;
	tab: ContactDrawerTabs | undefined;
	conversationId: string;
	setContactTags: (tags: string[]) => void;
	orgMember: IOrganizationMember | null;
	setSelectedMemberId: (id: string) => void;
	isOwner: boolean;
	onSaveTags: (tags: string[]) => void;
	automatedSteps: AutomatedStep[] | undefined;
	onMessageSent: () => void;
};
/**
 * Panel containing the conversation info for the contact panel
 * @param param0
 * @returns
 */
export default function ConversationPanel({
	contactTags,
	isMessagesLoading,
	contactId,
	updateProspectDrawerTracking,
	contactConnection,
	isNewProspectDrawerEnabled,
	conversation,
	orgId,
	selectedConnection,
	contactProfile,
	tab,
	conversationId,
	setContactTags,
	orgMember,
	setSelectedMemberId,
	onSaveTags,
	isOwner,
	automatedSteps,
	onMessageSent,
}: ConversationPanelProps) {
	const [inputVisible, setInputVisibility] = useState<boolean>(false);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const orgTags = useSelector(getAllOrgTags);
	const availableTags = useMemo(() => orgTags.data.map((t) => t.name), [orgTags]);
	const contact = useSelector((state: { entities: OrmState<any> }) =>
		ContactSelectors.getContact(state, contactId)
	);
	const handleTracking = useProspectDrawerTracking('Prospect Drawer');

	const numConnections = contactConnection?.connections.length ?? 0;

	const canShowProspectingDrawer =
		!isNewProspectDrawerEnabled && activeMember?.isOrgAdmin && numConnections > 1;

	function onContactUpdate(contactToUpdate: Partial<SessionBoundModel<Contact>>) {
		//remove tags because that uses a different route
		const { tags: _, ...rest } = contactToUpdate;
		UpdateContactWithNotification({ ...rest }).then(() => {
			dispatchContactUpdate({
				id: contactToUpdate.id ?? '',
				position: contactToUpdate.position,
				company: contactToUpdate.company,
				email: contactToUpdate.email,
				phoneNumber: contactToUpdate.phoneNumber,
			});
		});
	}

	return (
		<div
			className={[
				styles.panelWrapper,
				isNewProspectDrawerEnabled ? styles.meetingBooked : '',
			].join(' ')}
		>
			{!isNewProspectDrawerEnabled ? (
				<Skeleton loading={isNil(selectedConnection)} active>
					<Summary
						contact={contact}
						contactUrl={contactProfile?.alias}
						conversationId={conversationId}
						reminderDate={
							conversation?.snoozedDateTime
								? moment(conversation.snoozedDateTime)
								: undefined
						}
						handleContactUpdate={onContactUpdate}
						handleProspectDrawerTracking={updateProspectDrawerTracking}
					/>
				</Skeleton>
			) : null}
			{contact?.tags && !isNewProspectDrawerEnabled && (
				<Row>
					<Col span={24} className={styles.tagsSection}>
						{isOwner && !inputVisible && contactConnection && (
							<Tags tags={contactTags} className={styles.tagsWrapper}>
								<Tag
									onClick={() => setInputVisibility(true)}
									className={styles.tagsButton}
								>
									<EditOutlined />
									Add / Remove Tags
								</Tag>
							</Tags>
						)}
						{isOwner && inputVisible && (
							<>
								<TagSelector
									availableTags={availableTags}
									selectedTags={contactTags}
									onTagUpdate={setContactTags}
									placeholder="Enter tags"
								/>
								<Button
									onClick={() => {
										setInputVisibility(false);
										onSaveTags(contactTags);
									}}
									size="small"
									className={styles.saveTagButton}
								>
									<SaveOutlined />
									Save
								</Button>
							</>
						)}
					</Col>
				</Row>
			)}
			{canShowProspectingDrawer && (
				<>
					<Row>
						<Col span={24}>
							<Divider className={styles.divider} />
						</Col>
					</Row>
					<Row>
						<Col className={styles.prospectingSelectorWrapper}>
							<p className={styles.title}>
								You are viewing prospect information from
							</p>
							<Select
								className={styles.selector}
								defaultValue={orgMember?.name}
								onChange={setSelectedMemberId}
							>
								{contactConnection?.connections.map((c) => (
									<Select.Option value={c.orgMemberId} key={c.orgMemberId}>
										{c.orgMemberName}
									</Select.Option>
								))}
							</Select>
						</Col>
					</Row>
				</>
			)}
			{contactConnection && conversation && contact ? (
				<ContactActivities
					contact={contact}
					selectedConnection={selectedConnection}
					conversation={conversation}
					organizationId={orgId}
					contactProfile={contactProfile}
					isOwner={isOwner}
					isMessagesLoading={isMessagesLoading}
					tab={tab}
					onTracking={handleTracking}
					contactConnection={contactConnection}
					tabBarStyle={{ margin: 0 }}
					automatedSteps={automatedSteps}
					onMessageSent={onMessageSent}
				/>
			) : (
				<Skeleton active loading style={{ padding: spacingMD }} />
			)}
		</div>
	);
}
