import { withShadow } from '@copilot/common/hoc/components/withShadow';
import { PropsWithChildren } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSProperties } from 'styled-components';

import styles from './hidableBottomPanel.module.less';

type HidableBottomPanelProps = {
	hidden: boolean;
	style?: CSSProperties;
};

const AnimatedClassName = 'slide-from-bottom';

/**
 * Container placed at the bottom of the viewport
 * @param {CSSProperties} [style] style to apply on the container
 */
const HidableBottomPanel = (props: PropsWithChildren<HidableBottomPanelProps>) => {
	return (
		<>
			{!props.hidden && <div className={styles.panelBackground} />}
			<div className={styles.panelWrapper}>
				<CSSTransition
					in={!props.hidden}
					timeout={1000}
					classNames={AnimatedClassName}
					appear={false}
				>
					<div className={styles.panelContent} style={{ ...props.style }}>
						{props.children}
					</div>
				</CSSTransition>
			</div>
		</>
	);
};

export default withShadow(HidableBottomPanel);
