import type { IQueryProperties } from '@copilot/common/model/filters';

const DefaultQueryValues: Partial<IQueryProperties> = {
	CustomerSuccessUserId: 'all',
	Status: 'all',
	IsCampaignRunning: 'all',
	LinkedInError: '',
	Order: '',
	IsPaused: 'all',
	ActivationsRequired: '-1',
};

export const DefaultClientQueryValues: Partial<IQueryProperties> = {
	...DefaultQueryValues,
	Sort: 'daysLastSeen',
	Name: '',
};

export const DefaultTeamsQueryValues: Partial<IQueryProperties> = {
	...DefaultQueryValues,
	Sort: '',
	search: '',
};
