import React from 'react';
import { QuestionCircleOutlined, LinkOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import { Col, Row, Card, Tooltip } from 'antd';
import { LinkedInSearchType } from '@copilot/data/responses/interface';

interface CampaignOnboardWizardWelcomeProps {
	handleNext: (view: LinkedInSearchType) => void;
}

const IconCss = `
	svg {
		height: 6em;
		width: 6em;
		fill: #0075e2;
	}
`;

const CampaignOnboardWizardWelcome = React.memo<CampaignOnboardWizardWelcomeProps>((props) => {
	const salesNavSearchHandler = React.useCallback(() => {
		props.handleNext(LinkedInSearchType.Url);
	}, [props.handleNext]);

	const copilotSearchHandler = React.useCallback(() => {
		props.handleNext(LinkedInSearchType.Criteria);
	}, [props.handleNext]);

	return (
		<Row justify="center">
			<Col span={24}>
				<Row justify="center">
					<Col span={24}>
						<h1>Get Started</h1>
						<h3>
							Choose from the two options below to create the search criteria for your
							new CoPilot AI campaign. Your search criteria will be saved if you
							choose to leave the page before completing it.
						</h3>
					</Col>
				</Row>
				<br />
				<Row justify="center">
					<Col span={24}>
						<Card
							style={{
								borderRadius: '1em',
								border: '5px solid #0075e2',
								width: '80%',
							}}
							hoverable
							onClick={salesNavSearchHandler}
						>
							<Row justify="center" align="middle">
								<Col span={6} style={{ minWidth: '10em' }}>
									<LinkOutlined css={IconCss} />
								</Col>
								<Col span={14} style={{ padding: '1em 0', display: 'flex' }}>
									<h3>
										<strong> Input a LinkedIn Sales Navigator URL</strong>
									</h3>
									<Tooltip
										placement="top"
										title="Input the URL you made in Sales Navigator directly into CoPilot AI"
									>
										<QuestionCircleOutlined
											style={{
												fontSize: '1.25em',
												color: 'black',
												padding: '3px 5px 0 5px',
											}}
										/>
									</Tooltip>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<br />
				<Row justify="center">
					<Col span={24}>
						<Card
							style={{
								borderRadius: '1em',
								border: '5px solid #0075e2',
								width: '80%',
							}}
							hoverable
							onClick={copilotSearchHandler}
						>
							<Row justify="center" align="middle">
								<Col span={6} style={{ minWidth: '10em' }}>
									<CustomerServiceOutlined css={IconCss} />
								</Col>
								<Col span={14} style={{ padding: '1em 0', display: 'flex' }}>
									<h3>
										<strong>Create a search from CoPilot AI</strong>
									</h3>
									<Tooltip
										placement="top"
										title="Our in-platform user-friendly tool. Create a search without using Sales Navigator"
									>
										<QuestionCircleOutlined
											style={{
												fontSize: '1.25em',
												color: 'black',
												padding: '3px 5px 0 5px',
											}}
										/>
									</Tooltip>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<br />
				<Row justify="center">
					<Col span={24}>
						<p>
							*Please note, your Account Strategist will need to review your criteria
							before launching your campaign.
						</p>
					</Col>
				</Row>
			</Col>
		</Row>
	);
});

export default CampaignOnboardWizardWelcome;
