import * as React from 'react';
import { Col, Input, Row } from 'antd';
import { TextAreaProps as AntTextAreaProps } from 'antd/lib/input';
import { KeyCodes } from '@copilot/common/data/constant';

export interface TextAreaProps extends AntTextAreaProps {
	charLimit: number;
	style?: any;
	onCtrlEnter?: (
		event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>
	) => void;
}

interface TextAreaState {
	isFocused: boolean;
	value: TextAreaProps['value'];
}

class TextArea extends React.Component<TextAreaProps, TextAreaState> {
	static defaultProps = {
		charLimit: 0,
	};

	state: TextAreaState = { isFocused: false, value: '' };

	constructor(props: TextAreaProps) {
		super(props);

		this.handleBlur = this.handleBlur.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleValueChange = this.handleValueChange.bind(this);
		this.triggerChange = this.triggerChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	componentDidUpdate(prevProps: TextAreaProps) {
		if ('value' in this.props && prevProps.value != this.props.value) {
			this.setState({ value: this.props.value });
		}
	}

	handleBlur() {
		this.setState({ isFocused: false });
	}

	handleFocus() {
		this.setState({ isFocused: true });
	}

	handleValueChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
		const { value } = event.target;
		if (this.props.charLimit === 0 || value.length <= this.props.charLimit) {
			if (!('value' in this.props)) {
				this.setState({ value });
			}
			this.triggerChange(event);
		}
	}

	triggerChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
		if (this.props.onChange) {
			this.props.onChange(event);
		}
	}

	handleKeyPress(event: React.KeyboardEvent<HTMLTextAreaElement>) {
		if (this.props.onCtrlEnter && event.ctrlKey && event.charCode === KeyCodes.ENTER_KEY) {
			this.props.onCtrlEnter(event);
		}
	}

	render() {
		const { charLimit, value, onCtrlEnter: _onCtrlEnter, ...rest } = this.props;
		if (charLimit <= 0)
			return <Input.TextArea value={value} {...rest} onKeyPress={this.handleKeyPress} />;

		const { style = {}, rows = 2, onChange: _onChange, ...options } = rest;
		let classNames = 'cmpt-form-inputs-textarea';
		if (this.state.isFocused) classNames += 'focused';

		const charLen = ((value ?? this.state.value ?? '') as string | string[]).length;

		return (
			<Row className={classNames}>
				<Col span={24}>
					<Input.TextArea
						{...options}
						rows={rows - 1}
						style={style}
						onFocus={this.handleFocus}
						onBlur={this.handleBlur}
						onChange={this.handleValueChange}
						onKeyPress={this.handleKeyPress}
						value={this.state.value}
					/>
				</Col>
				<Col span={24} className="count-display text-right">
					<span>
						<span className={charLen >= charLimit ? 'text-danger' : ''}>{charLen}</span>
						/ {charLimit}
					</span>
				</Col>
			</Row>
		);
	}
}

export default TextArea;
