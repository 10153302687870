import { BarChartOutlined, ControlOutlined, FileTextOutlined } from '@ant-design/icons';

export const DAY_IN_MILLISECOND = 24 * 60 * 60 * 1000;

export const BrowserVendor = {
	Chrome: 'Google Inc.',
	Safari: 'Apple Computer, Inc.',
};

export const DashboardTabKeys = {
	Template: 'template',
	Overview: 'overview',
	Invites: 'invites',
};

export const DashboardTabLabels = {
	Overview: (
		<span>
			<BarChartOutlined /> Overview
		</span>
	),
	Invites: (
		<span>
			<ControlOutlined /> Usage Limits
		</span>
	),
	Template: (
		<span>
			<FileTextOutlined /> Quick Responses
		</span>
	),
};

export const OrganizationDashboardTabKeys = {
	Summary: 'summary',
	Inbox: 'inbox',
	TeamMembers: 'teammembers',
	Tags: 'tags',
	Template: 'template',
	Settings: 'settings',
	Notification: 'notification',
	Connection: 'connection',
	CRMConfiguration: 'crmconfiguration',
	Blocklist: 'blocklist',
};

export const OrganizationDashboardTabLabels = {
	Summary: <span>Summary</span>,
	Inbox: <span>Inbox</span>,
	TeamMembers: <span>Team Members</span>,
	Tags: <span>Tags</span>,
	Settings: <span>Settings</span>,
	Template: <span>Quick Responses</span>,
	Notification: <span>Email Notification</span>,
	Connection: <span>Connections</span>,
	CRMConfiguration: <span>Integrations</span>,
	Blocklist: <span>Blocklist</span>,
};

export const AvailableFilters = {
	TagName: 'TagName',
	CampaignId: 'CampaignId',
	OrgMemberId: 'OrgMemberId',
	Sentiment: 'Sentiment',
	Location: 'Location',
	Email: 'Email',
	Phone: 'Phone',
	LastThreadActivity: 'LastThreadActivity',
	/** Filter for toggling connections with / without meeting booked */
	MeetingBooked: 'MeetingBooked',
};

export const CommonFilters = ['TagName', 'CampaignId', 'OrgMemberId'];

export const ExcludableFilter = {
	TagName: true,
	CampaignId: false,
	OrgMemberId: false,
	Sentiment: false,
};

export enum InboxMessageCategorizationType {
	Reminder,
}

export const MessageEditorNameTokens = [
	{ label: 'First name', value: '{firstname}' },
	{ label: 'Last name', value: '{lastname}' },
];

// Updated Message Name Token Labels for testing. MessageEditorNameTokens and NewUserMessageEditorNameTokens will be merged into one after the testing.
export const NewUserMessageEditorNameTokens = [
	{ label: "Prospect's first name", value: '{firstname}' },
	{ label: "Prospect's last name", value: '{lastname}' },
];

export const CampaignDefaultName = {
	Prospecting: 'Prospecting Campaign',
	Nurture: 'Nurture Campaign',
	NewCampaign: 'New Campaign',
};

export enum SearchUrlType {
	Search,
	Nurture,
}

export enum DailyAllocationType {
	Invites = 'DailyInvites',
	Messages = 'DailyMessages',
}

export enum ConnectionStatusType {
	Stranger = 'Stranger',
	Connected = 'Connected',
}

export const Colors = {
	WizardBackground: '#F9F9F9',
};

export enum TaskMonitorStatus {
	FetchingTask,
	GeneratingTask,
	ExistingTaskRunning,
	NewTaskRunning,
	Available,
}
