import {
	IConnectionsByConnectionStatusQuery,
	IConnectionStatus,
	useConnectionsByConnectionStatusLazyQuery,
} from '@copilot/data/graphql/_generated';
import { CampaignMemberModel } from '@copilot/common/utils/campaignMember/models';
import isNil from 'lodash/isNil';
import { useMemo, useState } from 'react';
import { useFetch } from '@copilot/common/hooks/common';
import { OrganizationMemberManager } from '@copilot/data';
import { LinkedInMetaActions } from '@copilot/common/store/actions/linkedInMeta';
import { EMPTY_PAGINATION_RESPONSE, PaginatedResponseV2 } from '@copilot/common/types/pagination';
import { NurtureSearchListResult } from '@copilot/common/pages/campaignDashboard/nurtureList/types';
import { NURTURE_TABLE_DEFAULT_PAGE_SIZE } from '@copilot/common/pages/campaignDashboard/nurtureList/consts';

/**
 * Hooks to encapsulate logic for searching, pagination, filtering nurture search list
 */
export function useNurtureSearchListV2() {
	const [fetchNurtureConnections, { data, previousData, loading }] =
		useConnectionsByConnectionStatusLazyQuery();
	const [pageSize, setPageSize] = useState<number>(NURTURE_TABLE_DEFAULT_PAGE_SIZE);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [campaignMemberId, setCampaignMemberId] = useState<string | undefined>(undefined);
	const [, fetchIsLoggedIn] = useFetch(
		OrganizationMemberManager.checkIsLoggedIn,
		LinkedInMetaActions.loadLinkedInMeta,
		(r, omid) => ({ orgMemberId: omid, isLoggedIn: r })
	);
	const nurtureSearchList = useMemo<PaginatedResponseV2<NurtureSearchListResult>>(
		() => (isNil(data) ? EMPTY_PAGINATION_RESPONSE : toPaginatedSearchList(data)),
		[data]
	);
	const prevNurtureSearchList = useMemo<PaginatedResponseV2<NurtureSearchListResult>>(
		() =>
			isNil(previousData) ? EMPTY_PAGINATION_RESPONSE : toPaginatedSearchList(previousData),
		[previousData]
	);

	async function fetchSearchList(
		cmid: string,
		newSearchTerm?: string,
		newPageSize?: number,
		newCurrentPage?: number
	) {
		return fetchNurtureConnections({
			variables: {
				input: {
					campaignMemberIds: [cmid],
					status: [IConnectionStatus.Connected],
					searchText: newSearchTerm ?? searchTerm,
					pagination: {
						page: newCurrentPage ? newCurrentPage - 1 : currentPage - 1,
						pageSize: newPageSize ?? pageSize,
					},
				},
			},
		});
	}

	async function initSearchList(campaignMember: CampaignMemberModel | undefined) {
		if (!isNil(campaignMember) && !isNil(campaignMember.id)) {
			setCampaignMemberId(campaignMember.id);
			await fetchSearchList(campaignMember.id);
		}
		if (!isNil(campaignMember) && !isNil(campaignMember.orgMemberId)) {
			await fetchIsLoggedIn(campaignMember.orgMemberId);
		}
	}

	async function refetchSearchList() {
		if (!isNil(campaignMemberId)) {
			await fetchSearchList(campaignMemberId);
		}
	}

	async function onUpdateSearchTerm(newSearchTerm: string) {
		if (!isNil(campaignMemberId)) {
			setSearchTerm(newSearchTerm);
			// Reset current page to first page
			setCurrentPage(1);
			await fetchSearchList(campaignMemberId, newSearchTerm, undefined, 1);
		}
	}

	async function onUpdatePagination(newPageSize?: number, newCurrentPage?: number) {
		if (!isNil(campaignMemberId)) {
			if (!isNil(newCurrentPage)) setCurrentPage(newCurrentPage);
			if (!isNil(newPageSize)) setPageSize(newPageSize);
			await fetchSearchList(campaignMemberId, undefined, newPageSize, newCurrentPage);
		}
	}

	return {
		data: loading ? prevNurtureSearchList : nurtureSearchList,
		loading,
		// handlers
		initSearchList,
		refetchSearchList,
		onUpdateSearchTerm,
		onUpdatePagination,
	};
}

function toPaginatedSearchList(
	data: IConnectionsByConnectionStatusQuery | undefined
): PaginatedResponseV2<NurtureSearchListResult> {
	if (isNil(data)) {
		return EMPTY_PAGINATION_RESPONSE;
	}
	return {
		count: data.campaignConnectionsByConnectionStatus.count,
		pages: {
			current: data.campaignConnectionsByConnectionStatus.pages.current + 1,
			total: data.campaignConnectionsByConnectionStatus.pages.total,
		},
		content: toNurtureSearchListResults(data.campaignConnectionsByConnectionStatus.content),
	};
}

function toNurtureSearchListResults(
	content: IConnectionsByConnectionStatusQuery['campaignConnectionsByConnectionStatus']['content']
): NurtureSearchListResult[] {
	return content.map(
		({
			id,
			name,
			contactInfo,
			title,
			company,
			location,
			campaignMember,
			campaignId,
			status,
			profileId,
			replyPrediction,
			inviteUrl,
			dateCreated,
			isManualTermination,
			nextAutomationNode,
		}) => ({
			id,
			contactId: contactInfo?.id ?? '',
			campaignMemberId: campaignMember.id,
			name,
			company: company ?? undefined,
			position: title ?? undefined,
			location: location ?? undefined,
			campaignId,
			profileId,
			matchScore: replyPrediction ?? undefined,
			connectionStatus: status,
			inviteUrl: inviteUrl ?? '',
			dateCreated: new Date(dateCreated),
			nextAutomationNode: nextAutomationNode ?? undefined,
			isManualTermination: isManualTermination ?? undefined,
		})
	);
}
