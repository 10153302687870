import { BaseModel } from './base';
import { attr } from 'redux-orm';
import type { ScheduleHierarchyType } from '@copilot/common/pages/settings/schedule/constant';
import type { DaySchedule } from '@copilot/data/responses/interface';

export interface IServiceSchedule {
	id: string;
	ownerId: string;
	hierarchyType: ScheduleHierarchyType;
	timezoneCode: string;
	synchronization: boolean;
	weeklySchedule: DaySchedule[];
}

export class ServiceSchedule extends BaseModel<typeof ServiceSchedule, IServiceSchedule> {
	static modelName = 'ServiceSchedule';
	static fields = {
		id: attr(),
		ownerId: attr(),
		hierarchyType: attr(),
		timezoneCode: attr(),
		synchronization: attr(),
		weeklySchedule: attr(),
	};
}
