export const ALL_LOCATIONS = ['All States', 'All cities', 'Whole State', 'Whole Province'];

export default {
	us_states: [
		'All States',
		'Alaska',
		'Alabama',
		'Arkansas',
		'Arizona',
		'California',
		'Colorado',
		'Connecticut',
		'District of Columbia',
		'Delaware',
		'Florida',
		'Georgia',
		'Hawaii',
		'Iowa',
		'Idaho',
		'Illinois',
		'Indiana',
		'Kansas',
		'Kentucky',
		'Louisiana',
		'Massachusetts',
		'Maryland',
		'Maine',
		'Michigan',
		'Minnesota',
		'Missouri',
		'Mississippi',
		'Montana',
		'North Carolina',
		'North Dakota',
		'Nebraska',
		'New Hampshire',
		'New Jersey',
		'New Mexico',
		'Nevada',
		'New York',
		'Ohio',
		'Oklahoma',
		'Oregon',
		'Pennsylvania',
		'Rhode Island',
		'South Carolina',
		'South Dakota',
		'Tennessee',
		'Texas',
		'Utah',
		'Virginia',
		'Vermont',
		'Washington',
		'Wisconsin',
		'West Virginia',
		'Wyoming',
		'British Columbia',
		'Alberta',
		'Saskatchewan',
		'Manitoba',
		'Ontario',
		'Quebec',
		'Prince Edward Island',
		'Nova Scotia',
		'New Brunswick',
		'Newfoundland and Labrador',
	],
	us_cities: {
		'All States': ['All cities'],
		Alabama: [
			'Whole State',
			'Birmingham',
			'Mobile',
			'Huntsville',
			'Montgomery',
			'Tuscaloosa',
			'Florence',
			'Dothan',
			'Auburn',
			'Decatur',
			'Anniston',
			'Gadsden',
			'Madison',
		],
		Alaska: [
			'Whole State',

			'Anchorage',
			'Fairbanks',
			'Wasilla',
			'Juneau',
			'Eagle River',
			'Palmer',
			'North Pole',
			'Soldotna',
			'Ketchikan',
			'Kenai',
			'Homer',
			'Fort Wainwright',
			'Kodiak',
			'Elmendorf',
			'Sitka',
			'Fort Richardson',
			'Chugiak',
			'Bethel',
		],
		Arizona: [
			'Whole State',

			'Phoenix',
			'Tucson',
			'Scottsdale',
			'Mesa',
			'Flagstaff',
			'Tempe',
			'Chandler',
			'Gilbert',
			'Glendale',
			'Peoria',
			'Surprise',
			'Yuma',
			'Goodyear',
			'Prescott',
			'Avondale',
		],
		Arkansas: [
			'Whole State',

			'Little rock',
			'Fayetteville',
			'Jonesboro',
			'Fort Smith',
			'Pine Bluff',
			'Bentonville',
			'Conway',
			'Rogers',
			'Springdale',
			'Russellville',
			'Cabot',
			'Searcy',
		],
		California: [
			'Whole State',

			'Orange County',
			'Los Angeles',
			'Sacramento',
			'San Francisco',
			'San Diego',
			'San Jose',
			'Mountain View',
			'Fresno',
			'Beverly Hills',
			'Irvine',
			'Oakland',
			'Long Beach',
			'Bakersfield',
			'Stockton',
			'Fremont',
			'Santa Barbara',
		],
		Colorado: [
			'Whole State',

			'Denver',
			'Colorado Springs',
			'Fort Collins',
			'Littleton',
			'Aurora',
			'Grand Junction',
			'Boulder',
			'Englewood',
			'Pueblo',
			'Broomfield',
			'Arvada',
			'Longmont',
			'Parker',
			'Golden',
			'Castle Rock',
		],
		Connecticut: [
			'Whole State',

			'Hartford',
			'New London',
			'Stamford',
			'New Haven',
			'Bridgeport',
			'Norwalk',
			'Danbury',
			'Waterbury',
			'Fairfield',
			'Greenwich',
			'Manchester',
			'Milford',
			'Bristol',
			'Hamden',
			'Middletown',
			'New Britain',
			'Stratford',
		],
		Delaware: [
			'Whole State',

			'Dover',
			'Wilmington',
			'Newark',
			'New Castle',
			'Bear',
			'Middletown',
			'Lewes',
			'Hockessin',
			'Smyrna',
			'Millsboro',
			'Rehoboth',
			'Claymont',
			'Seaford',
			'Milford',
			'Camden',
			'Georgetown',
			'Milton',
		],
		'District of Columbia': ['Whole State', 'Washington'],
		Florida: [
			'Whole State',

			'Tampa',
			'Orlando',
			'Miami',
			'Jacksonville',
			'West Palm Beach',
			'Fort Lauderdale',
			'Sarasota',
			'Melbourne',
			'Fort Myers',
			'Tallahassee',
			'Lakeland',
			'Daytona Beach',
			'Hollywood',
			'Pompano',
			'Gainesville',
		],
		Georgia: [
			'Whole State',

			'Atlanta',
			'Savannah',
			'Augusta',
			'Marietta',
			'Macon',
			'Alpharetta',
			'Columbus',
			'Athens',
			'Lawrenceville',
			'Decatur',
			'Albany',
			'Kennesaw',
			'Duluth',
			'Cumming',
		],
		Hawaii: [
			'Whole State',

			'Honolulu',
			'Kailua',
			'Ewa Beach',
			'Hilo',
			'Kaneohe',
			'Mililani',
			'Waipahu',
			'Kapolei',
			'Kihei',
			'Aiea',
			'Wahiawa',
			'Waianae',
			'Wailuku',
			'Pearl City',
			'Lahaina',
			'Kahului',
			'Laie',
		],
		Idaho: [
			'Whole State',

			'Boise',
			'Pocatello',
			'Meridian',
			'Idaho falls',
			'Nampa',
			'Rexburg',
			"Coeur D'Alene",
			'Twin Falls',
			'Caldwell',
			'Moscow',
			'Eagle',
			'Post Falls',
			'Garden City',
			'Lewiston',
			'Hayden',
			'Sandpoint',
			'Kuna',
		],
		Illinois: [
			'Whole State',

			'Chicago',
			'Rockford',
			'Peoria',
			'Urbana-Champaign',
			'Naperville',
			'Springfield',
			'Bloomington',
			'Aurora',
			'Schaumburg',
			'Evanston',
			'Champaign',
			'Decatur',
			'Arlington Heights',
			'Des Plaines',
			'Elgin',
			'Belleville',
		],
		Indiana: [
			'Whole State',

			'Indianapolis',
			'Fort Wayne',
			'Evansville',
			'South Bend',
			'Lafayette',
			'Bloomington',
			'Terre Haute',
			'Elkhart',
			'Muncie',
			'Carmel',
			'Kokomo',
			'Fishers',
			'Greenwood',
		],
		Iowa: [
			'Whole State',

			'Des Moines',
			'Davenport',
			'Cedar Rapids',
			'Iowa City',
			'Waterloo',
			'Sioux City',
			'Dubuque',
			'Ames',
			'Ankeny',
			'Council Bluffs',
			'Urbandale',
		],
		Kansas: [
			'Whole State',

			'Kansas City',
			'Wichita',
			'Haven',
			'Potwin',
			'Topeka',
			'Esbon',
			'Overland Park',
			'Lawrence',
			'Olathe',
			'Shawnee',
			'Lenexa',
			'Manhattan',
			'Leawood',
			'Mission',
		],
		Kentucky: [
			'Whole State',

			'Louisville',
			'Lexington',
			'Owensboro',
			'Bowling Green',
			'Florence',
			'Richmond',
			'Paducah',
			'Ft Mitchell',
			'Frankfort',
			'Elizabethtown',
			'Newport',
			'Covington',
			'Georgetown',
			'Nicholasville',
			'Erlanger',
			'Murray',
		],
		Louisiana: [
			'Whole State',
			'Baton Rouge',
			'New Orleans',
			'Lafayette',
			'Shreveport',
			'Alexandria',
			'Monroe',
			'Lake Charles',
			'Metairie',
			'Houma',
			'Covington',
			'Denham Springs',
		],
		Maine: [
			'Whole State',
			'Portland',
			'Bangor',
			'Lewiston',
			'Auburn',
			'Augusta',
			'Brunswick',
			'Scarborough',
			'Waterville',
			'Biddeford',
			'Westbrook',
			'Saco',
			'Maineville',
			'Gorham',
			'Windham',
			'Falmouth',
		],
		Maryland: [
			'Whole State',
			'Baltimore',
			'Silver Spring',
			'Rockville',
			'Gaithersburg',
			'Bethesda',
			'Columbia',
			'Frederick',
			'Annapolis',
			'Hyattsville',
			'Laurel',
			'Bowie',
			'Marlboro',
			'Germantown',
			'Ellicott City',
			'Cumberland',
			'Waldorf',
			'Owings Mills',
			'College Park',
		],
		Massachusetts: [
			'Whole State',
			'Boston',
			'Springfield',
			'Cambridge',
			'Barnstable',
			'Worcester',
			'Waltham',
			'Somerville',
			'Quincy',
			'Pittsfield',
			'Lowell',
			'Framingham',
			'Brookline',
			'Medford',
			'Brighton',
			'Brockton',
			'Malden',
			'Woburn',
			'Lynn',
		],
		Michigan: [
			'Whole State',
			'Grand Rapids',
			'Saginaw',
			'Detroit',
			'Lansing',
			'Kalamazoo',
			'Ann Arbor',
			'Troy',
			'Farmington',
			'Southfield',
			'Benton Harbor',
			'Rochester',
			'Livonia',
			'Sterling Heights',
			'Warren',
			'Jackson',
		],
		Minnesota: [
			'Whole State',
			'Minneapolis',
			'Saint Paul',
			'St. Cloud',
			'Duluth',
			'Rochester',
			'Eden Prairie',
			'Osseo',
			'Mankato',
			'Hopkins',
			'Burnsville',
			'Lakeville',
			'Anoka',
			'Shakopee',
			'Andover',
			'Stillwater',
			'Moorhead',
		],
		Mississippi: [
			'Whole State',
			'Jackson',
			'Biloxi',
			'Hattiesburg',
			'Gulfport',
			'Brandon',
			'Oxford',
			'Madison',
			'Olive Branch',
			'Southaven',
			'Tupelo',
			'Starkville',
			'Meridian',
			'Ocean Springs',
			'Ridgeland',
			'Columbus',
			'Vicksburg',
		],
		Missouri: [
			'Whole State',
			'Kansas City',
			'Saint Louis',
			'Springfield',
			'Columbia',
			'Joplin',
			'Saint Charles',
			'St Joseph',
			'Lees Summit',
			'Ballwin',
			'Missouri City',
			'Florissant',
			'Independence',
			'O Fallon',
			'Chesterfield',
			'Saint Peters',
			'Jefferson City',
		],
		Montana: [
			'Whole State',
			'Missoula',
			'Great Falls',
			'Billings',
			'Bozeman',
			'Helena',
			'Kalispell',
			'Butte',
			'Whitefish',
			'Belgrade',
			'Hamilton',
			'Livingston',
			'Columbia Falls',
			'Polson',
			'Havre',
		],
		Nebraska: [
			'Whole State',
			'Lincoln',
			'Omaha',
			'Bellevue',
			'Papillion',
			'Kearney',
			'Grand Island',
			'Elkhorn',
			'Fremont',
			'Norfolk',
			'North Platte',
			'La Vista',
			'Hastings',
			'Columbus',
			'Scottsbluff',
			'Gretna',
			'Blair',
			'Bennington',
			'Beatrice',
		],
		Nevada: [
			'Whole State',
			'Las Vegas',
			'Reno',
			'Henderson',
			'Sparks',
			'Carson City',
			'Pahrump',
			'Elko',
			'Gardnerville',
			'Nevada City',
			'Fallon',
			'Fernley',
			'Boulder City',
			'Incline Village',
			'Minden',
			'Mesquite',
			'Dayton',
		],
		'New Hampshire': [
			'Whole State',
			'Manchester',
			'Nashua',
			'Concord',
			'Portsmouth',
			'Dover',
			'Derry',
			'Salem',
			'Merrimack',
			'Bedford',
			'Keene',
			'Londonderry',
			'Hudson',
			'Exeter',
			'Rochester',
			'Hanover',
			'Durham',
			'Hampton',
			'Lebanon',
			'Amherst',
		],
		'New Jersey': [
			'Whole State',
			'Jersey City',
			'Newark',
			'Trenton',
			'Edison',
			'Princeton',
			'Hoboken',
			'Piscataway',
			'Toms River',
			'Cherry Hill',
			'Clifton',
			'Paterson',
			'Morristown',
			'Elizabeth',
			'Wayne',
			'Somerset',
			'Parsippany',
			'Mount Laurel',
			'New Brunswick',
			'Montclair',
		],
		'New Mexico': [
			'Whole State',
			'Albuquerque',
			'Santa Fe',
			'Las Cruces',
			'Rio Rancho',
			'Farmington',
			'Roswell',
			'Clovis',
			'Los Alamos',
			'Hobbs',
			'Alamogordo',
			'Carlsbad',
			'Los Lunas',
			'Gallup',
			'Taos',
			'Silver City',
			'Artesia',
		],
		'New York': [
			'Whole State',
			'New York',
			'Brooklyn',
			'Albany',
			'Rochester',
			'Buffalo',
			'Niagara',
			'Bronx',
			'Syracuse',
			'Staten Island',
			'Ithaca',
			'York',
			'Utica',
			'Schenectady',
			'Glen Falls',
			'Jamaica',
		],
		'North Carolina': [
			'Whole State',
			'Charlotte',
			'Raleigh',
			'Durham',
			'Greensboro',
			'Winston Salem',
			'Fayetteville',
			'Asheville',
			'Wilmington',
			'Hickory/Lenoir',
			'Greenville',
			'Jacksonville',
			'Cary',
			'Rocky Mount',
		],
		'North Dakota': [
			'Whole State',
			'Fargo',
			'Bismarck',
			'Grand Forks',
			'Minot',
			'Williston',
			'Dickinson',
			'Mandan',
			'Jamestown',
			'Watford City',
			'Devils Lake',
			'Wahpeton',
			'Valley City',
			'New Town',
			'Belcourt',
		],
		Ohio: [
			'Whole State',
			'Cleveland',
			'Columbus',
			'Cincinnati',
			'Dayton',
			'Toledo',
			'Canton',
			'Youngstown',
			'Akron',
			'Lima',
			'Mansfield',
			'Westerville',
			'Dublin',
			'Hamilton',
		],
		Oklahoma: [
			'Whole State',
			'Oklahoma City',
			'Tulsa',
			'Lawton',
			'Edmond',
			'Norman',
			'Broken arrow',
			'Enid',
			'Stillwater',
			'Yukon',
			'Owasso',
			'Bartlesville',
			'Claremore',
			'Shawnee',
			'Muskogee',
			'Ardmore',
		],
		Oregon: [
			'Whole State',
			'Portland',
			'Eugene',
			'Medford',
			'Beaverton',
			'Salem',
			'Bend',
			'Hillsboro',
			'Corvallis',
			'Lake Oswego',
			'Gresham',
			'Springfield',
			'Oregon City',
			'Albany',
			'Grants Pass',
		],
		Pennsylvania: [
			'Whole State',
			'Philadelphia',
			'Pittsburgh',
			'Harrisburg',
			'Allentown',
			'Scranton',
			'Lancaster',
			'York',
			'Reading',
			'Erie',
			'Johnstown',
			'State College',
			'Williamsport',
			'West Chester',
		],
		'Rhode Island': [
			'Whole State',
			'Providence',
			'Warwick',
			'Cranston',
			'Pawtucket',
			'Newport',
			'Woonsocket',
			'Cumberland',
			'Coventry',
			'North Kingstown',
			'Johnston',
			'Greenwich',
			'Bristol',
			'Wakefield',
			'Westerly',
			'Lincoln',
			'Barrington',
		],
		'South Carolina': [
			'Whole State',
			'Greenville',
			'Charleston',
			'Columbia',
			'Myrtle Beach',
			'Florence',
			'Mount Pleasant',
			'Summerville',
			'Rock Hill',
			'Sumter',
			'Spartanburg',
			'Lexington',
			'North Charleston',
			'Simpsonville',
			'Greer',
		],
		'South Dakota': [
			'Whole State',
			'Sioux Falls',
			'Rapid City',
			'Brookings',
			'Aberdeen',
			'Watertown',
			'Mitchell',
			'Spearfish',
			'Pierre',
			'Vermillion',
			'Yankton',
			'Brandon',
			'Madison',
			'Huron',
			'Box Elder',
			'Hartford',
			'Sturgis',
		],
		Tennessee: [
			'Whole State',
			'Knoxville',
			'Nashville',
			'Memphis',
			'Chattanooga',
			'Johnson City',
			'Clarksville',
			'Jackson',
			'Murfreesboro',
			'Franklin',
			'Brentwood',
			'Cordova',
			'Antioch',
			'Hendersonville',
			'Cleveland',
		],
		Texas: [
			'Whole State',
			'Houston',
			'Austin',
			'San Antonio',
			'Dallas',
			'Fort Worth',
			'El Paso',
			'Plano',
			'Arlington',
			'Spring',
			'Killeen',
			'Temple',
			'Irving',
			'Odessa',
			'Midland',
			'Corpus Christi',
			'Tyler',
			'Lubbock',
		],
		Utah: [
			'Whole State',
			'Salt Lake City',
			'Provo',
			'Ogden',
			'Sandy',
			'Orem',
			'Logan',
			'Saint George',
			'Jordan',
			'Layton',
			'Draper',
			'Lehi',
			'Clearfield',
			'Park City',
			'Bountiful',
			'American Fork',
			'Cedar City',
			'Midvale',
		],
		Vermont: [
			'Whole State',
			'Burlington',
			'Essex Junction',
			'Rutland',
			'Montpelier',
			'Colchester',
			'Brattleboro',
			'Williston',
			'Saint Albans',
			'Barre',
			'Bennington',
			'Middlebury',
			'Milton',
			'Shelburne',
			'White River Junction',
			'Stowe',
			'Saint Johnsbury',
		],
		Virginia: [
			'Whole State',
			'Norfolk',
			'Richmond',
			'Virginia Beach',
			'Alexandria',
			'Roanoke',
			'Arlington',
			'Charlottesville',
			'Huntington',
			'Charleston',
			'Fairfax',
			'Lynchburg',
			'Chesapeake',
			'Woodbridge',
			'Parkersburg',
			'Manassas',
			'Newport News',
		],
		Washington: [
			'Whole State',
			'Seattle',
			'Spokane',
			'Richland',
			'Kennewick',
			'Pasco',
			'Tacoma',
			'Vancouver',
			'Bellevue',
			'Bellingham',
			'Yakima',
			'Redmond',
			'Olympia',
			'Everett',
			'Renton',
			'Kent',
			'Bothell',
			'Kirkland',
		],
		'West Virginia': [
			'Whole State',
			'Huntington',
			'Charleston',
			'Parkersburg',
			'Wheeling',
			'Morgantown',
			'Martinsburg',
			'Fairmont',
			'Beckley',
			'Clarksburg',
			'Hurricane',
			'Charles Town',
			'Saint Albans',
			'Bridgeport',
			'Weirton',
			'Princeton',
		],
		Wisconsin: [
			'Whole State',
			'Milwaukee',
			'Madison',
			'Oshkosh',
			'Green Bay',
			'Wausau',
			'La Crosse',
			'Eau Claire',
			'Janesville',
			'Beloit',
			'Sheboygan',
			'Appleton',
			'Waukesha',
			'Racine',
			'Kenosha',
			'Brookfield',
		],
		Wyoming: [
			'Whole State',
			'Cheyenne',
			'Casper',
			'Laramie',
			'Gillette',
			'Jackson',
			'Rock Springs',
			'Sheridan',
			'Fort Warren',
			'Cody',
			'Riverton',
			'Evanston',
			'Camden',
			'Lander',
			'Green River',
			'Powell',
		],
		'British Columbia': [
			'Whole Province',
			'Vancouver',
			'Surrey',
			'Victoria',
			'Burnaby',
			'Richmond',
			'North Vancouver',
			'Kelowna',
			'Coquitlam',
			'Langley',
			'Abbotsford',
			'Delta',
			'Kamloops',
			'Nanaimo',
			'New Westminster',
			'Prince George',
			'Port Coquitlam',
			'Maple Ridge',
			'Chilliwack',
		],

		Alberta: [
			'Whole Province',
			'Calgary',
			'Edmonton',
			'Red Deer',
			'Sherwood Park',
			'Lethbridge',
			'Fort Mcmurray',
			'St Albert',
			'Grand Prairie',
			'Medicine Hat',
			'Airdrie',
			'Spruce Grove',
			'Okotoks',
			'Leduc',
			'Cochrane',
			'Fort Saskatchewan',
			'Stony Plain',
			'Loydminister',
			'Canmore',
		],

		Saskatchewan: [
			'Whole Province',
			'Saskatoon',
			'Regina',
			'Prince Albert',
			'Moose Jaw',
			'Swift Current',
			'Yorkton',
			'Lloydminster',
			'North Battleford',
			'Estevan',
			'Weyburn',
			'Warman',
			'Humboldt',
			'Meadow Lake',
			'Melfort',
			'Kindersley',
			'Martensville',
			'La Ronge',
		],

		Manitoba: [
			'Whole Province',
			'Winnipeg',
			'Brandon',
			'Steinbach',
			'Portage La Prairie',
			'Thompson',
			'Selkirk',
			'East St Paul',
			'Winkler',
			'Dauphin',
			'Morden',
			'St Andrews',
			'The Pas',
			'Stonewall',
			'West St Paul',
			'Flin Flon',
			'Oakbank',
			'Lorette',
			'Beausejour',
		],

		Ontario: [
			'Whole Province',
			'Toronto',
			'Mississauga',
			'North York',
			'Scarborough',
			'Ottawa',
			'Brampton',
			'Etobicoke',
			'London',
			'Hamilton',
			'Markham',
			'Oakville',
			'Kitchener',
			'Windsor',
			'Richmond Hill',
			'Burlington',
			'Nepean',
			'Waterloo',
			'York',
		],

		Quebec: [
			'Whole Province',
			'Montreal',
			'Laval',
			'Gatineau',
			'Saint-Laurent',
			'Sherbrooke',
			'Longueuil',
			'Verdun',
			'Trois-Rivieres',
			'Brossard',
			'Lasalle',
			'Saint-Leonard',
			'Saint-Jean-Sur-Richelieu',
			'Pierrefonds',
			'Saint-Hubert',
			'Dollard-Des-Ormeaux',
			'Boucherville',
		],

		'Prince Edward Island': [
			'Whole Province',
			'Charlottetown',
			'Summerside',
			'Stratford',
			'Montague',
			'Cornwall',
			'Kensington',
			'Souris',
			'Hunter River',
			'York',
			'Mount Stewart',
			'Tyne Valley',
			'North Wiltshire',
			'Alberton',
			'Tignish',
			'Cardigan',
			'Wellington Station',
			'Miscouche',
		],

		'Nova Scotia': [
			'Whole Province',
			'Halifax',
			'Dartmouth',
			'Sydney',
			'Bedford',
			'Lower Sackville',
			'Truro',
			'Antigonish',
			'New Glasgow',
			'Kentville',
			'Hammonds Plains',
			'Wolfville',
			'Bridgewater',
			'Amherst',
			'Glace Bay',
			'Yarmouth',
			'Eastern Passage',
			'Fall River',
			'Windsor',
		],

		'New Brunswick': [
			'Whole Province',
			'Moncton',
			'Fredericton',
			'Saint John',
			'Dieppe',
			'Riverview',
			'Quispamsis',
			'Miramichi',
			'Rothesay',
			'Bathurst',
			'Beaver Dam',
			'Edmundston',
			'Oromocto',
			'Sackville',
			'Campbellton',
			'Woodstock',
			'Shediac',
			'Sussex',
		],

		'Newfoundland and Labrador': [
			'Whole Province',
			"St. John's",
			'Mount Pearl',
			'Corner Brook',
			'Conception Bay South',
			'Paradise',
			'Grand Falls-Windsor',
			'Gander',
			'Torbay',
			'Happy Valley-Goose Bay',
			'Labrador City',
			'Portugal Cove-St Philips',
			'Clarenville',
			'Stephenville',
			'Goulds',
			'Carbonear',
			'Marystown',
			'Deer Lake',
			'Bay Roberts',
		],
	},
};
