import React from 'react';
import { FrownOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import BasicContainer from '../basic';

export interface GeneralErrorProps {
	message?: string;
}

/**
 * General Error component for any unhandled errors.
 * @param props Props we want to use for Component
 */
const GeneralError: React.FC<GeneralErrorProps> = (props) => {
	const { message } = props;
	return (
		<BasicContainer>
			<BasicContainer.Content>
				<Row align="middle" style={{ textAlign: 'center' }}>
					<Col span={24}>
						<FrownOutlined style={{ fontSize: '10em' }} />
					</Col>
					<Col span={24}>
						<h1>{message ?? 'Sorry! Looks like this module failed to load...'}</h1>
					</Col>
				</Row>
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default GeneralError;
