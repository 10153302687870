import { IContactConnection, IContactTag } from '@copilot/common/store/models/redux';
import { ConnectionResponse } from '@copilot/data/responses/interface';
import moment from 'moment';
import { map, some } from 'lodash';

/**
 * IContactConnection with optional connections & filteredTags field.
 */
export type ContactConnectionDisplay = PartialBy<IContactConnection, 'connections'> & {
	/**
	 * Tags that have been selected from the tag filter.
	 */
	filteredTags?: string[];
};

const consolidateTag = (connections: ConnectionResponse[]) => {
	const tagMap = new Map();
	const consolidatedTags: IContactTag[] = [];
	connections.forEach((c) => {
		c.tags.forEach((t) => {
			const tag = tagMap.get(t.name);
			if (!tag) {
				tagMap.set(t.name, t);
				consolidatedTags.push(t);
			}
		});
	});
	return consolidatedTags;
};

/**
 * Gets connections for ContactConnectionDisplayConnection.
 * When there is only one connection, connections to be undefined to prevent having duplicate rows when displaying multi-connections.
 * @param connections
 * @param contactId
 * @returns {{connection: string, contactId: string}} | undefined
 */
const getContactConnectionDisplayConnections = (
	connections: ConnectionResponse[],
	contactId: string
) =>
	connections.length === 1
		? undefined
		: connections.map((connection) => ({
				...connection,
				contactId,
		  }));

/**
 * Creates ContactConnectionDisplay from IContactConnection.
 * @param contacts
 * @param filteredTags
 * @returns {ContactConnectionDisplay[]}
 */
export const createContactConnections = (
	contacts: readonly IContactConnection[],
	filteredTags: string[] = []
): ContactConnectionDisplay[] => {
	const contactConnectionDisplays: ContactConnectionDisplay[] = [];
	contacts.forEach((c) => {
		const contactConnection: ContactConnectionDisplay = {
			id: c.contactId,
			contactId: c.contactId,
			name: c.name,
			campaignMemberId: c.connections[0].campaignMemberId,
			orgMemberId: c.connections[0].orgMemberId,
			connectionId: c.connections[0].id,
			campaignId: c.connections[0].campaignId,
			orgMemberProfileId: c.connections[0].orgMemberProfileId,
			profileId: c.connections[0].profileId,
			dateCreated: c.connections[0].dateCreated,
			connections: getContactConnectionDisplayConnections(c.connections, c.contactId),
			tags: c.connections[0].tags,
			campaignName: c.connections[0].campaignName,
			campaignType: c.connections[0].campaignType,
			orgMemberName: c.connections[0].orgMemberName,
			lastThreadActivity: c.connections[0].lastThreadActivity,
			location: c.connections[0].location,
			position: c.position,
			company: c.company,
			email: c.email,
			phoneNumber: c.phoneNumber,
			history: c.history,
			meeting: {
				booked: some(map(c.connections, (connection) => connection.meeting.booked)),
			},
		};
		if (c.connections.length > 1) {
			const multiple = `Multiple (${c.connections.length})`;
			contactConnection.orgMemberName = multiple;
			contactConnection.lastThreadActivity = moment
				.max(c.connections.map((conn) => moment(conn.lastThreadActivity)))
				.format();
			contactConnection.campaignName = multiple;
			contactConnection.campaignType = '';
			contactConnection.tags = consolidateTag(c.connections);
		}
		contactConnection.filteredTags = filteredTags;

		contactConnectionDisplays.push(contactConnection);
	});
	return contactConnectionDisplays;
};
