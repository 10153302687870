import { OrmState, SessionBoundModel } from 'redux-orm';
import Store from '..';
import { ScheduledMessage, ScheduledMessageModel } from '../models/redux';

export namespace ScheduledMessageSelectors {
	// export const getScheduledMessagesByThreadId = (
	// 	state: ReturnType<typeof Store['ReduxStore']['getState']>,
	// 	threadId: string
	// ) =>
	// 	Store.ORM.session(state.entities)
	// 		.OutboxMessage.all()
	// 		.toModelArray()
	// 		.filter((m) => {
	// 			return m.threadId == threadId;
	// 		});

	export const getScheduledMessages = (state: { entities: OrmState<any> }) =>
		Store.ORM.session(state.entities)
			.ScheduledMessageModel.all()
			.toRefArray()
			.map((m) => m as ScheduledMessage);

	export const getScheduledMessagesByContactId = (
		state: { entities: OrmState<any> },
		contactId: string
	) =>
		Store.ORM.session(state.entities)
			.ScheduledMessageModel.all()
			.toRefArray()
			.filter((m) => m.contactId === contactId)
			.map((m) => m as ScheduledMessage);

	export const getScheduledMessagesByIds =
		(ids: string[]) => (state: { entities: OrmState<any> }) => {
			const messages = Store.ORM.session(state.entities).ScheduledMessageModel;
			return ids
				.map((id) => messages.withId(id))
				.filter((c): c is SessionBoundModel<ScheduledMessageModel, {}> => c !== null);
		};
}
