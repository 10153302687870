import { useTheme } from 'styled-components';
import { Icon } from '@iconify/react';
import { aiIcon } from '@copilot/common/constant/icons';
import { Space } from 'antd';

interface ReplyPredictionProps {
	/**
	 * How likely the prospect will response in percentage
	 */
	percent: number;
}

/**
 * [Presentation] Component to display on how likely the prospect will response
 * @param percent
 * @constructor
 */
export function ReplyPrediction({ percent }: ReplyPredictionProps) {
	const theme = useTheme();
	const primaryColor = theme['@primary-color'];
	return (
		<Space size={'small'}>
			<Icon
				icon={aiIcon}
				style={{
					verticalAlign: 'middle',
				}}
				color={primaryColor}
				height="1.2em"
			/>
			{`${percent}% LIKELY`}
		</Space>
	);
}
