import * as React from 'react';
import { DrawerProps } from 'antd/lib/drawer';
import { Button } from 'antd';
import BaseDrawer from '../../base';
import TagSelector from '@copilot/common/components/forms/common/generics/tagselector';
import drawerManager from '@copilot/common/utils/drawerManager';

export interface TagsEditDrawerProps extends DrawerProps {
	tags: string[];
	saveTags: (tags: string[]) => void;
	closeAlert: boolean;
}
export interface TagsEditDrawerState {
	tags: string[];
}

class TagsEditDrawer extends React.Component<TagsEditDrawerProps, TagsEditDrawerState> {
	state: TagsEditDrawerState;

	constructor(props: TagsEditDrawerProps) {
		super(props);
		this.saveTags = this.saveTags.bind(this);
		this.onTagUpdate = this.onTagUpdate.bind(this);
		this.state = {
			tags: props.tags,
		};
	}

	saveTags() {
		this.props.saveTags(this.state.tags);
		drawerManager.closeDrawer();
	}

	onTagUpdate(tags: string[]) {
		this.setState({ tags });
	}

	render() {
		const { saveTags: _saveTags, closeAlert: _closeAlert, ...rest } = this.props;

		return (
			<BaseDrawer {...rest}>
				<h3>Manage Tags</h3>
				<TagSelector
					selectedTags={this.state.tags}
					onTagUpdate={this.onTagUpdate}
					placeholder="Enter tags"
				/>
				<div className="drawer-function-buttons">
					<br />
					<Button onClick={this.saveTags} type="primary">
						Update Tags
					</Button>
				</div>
			</BaseDrawer>
		);
	}
}

export default TagsEditDrawer;
