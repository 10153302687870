import { useHistory, useLocation } from 'react-router';
import BaseModal from '@copilot/common/components/modals/wrappers/base';
import { Button, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import styles from './linkedInAuthResults.module.less';
import NotificationManager from '@copilot/common/utils/notificationManager';
import { Userpilot } from 'userpilot';
import {
	HOSTED_AUTH_FAILED_HASH,
	HOSTED_AUTH_SUCCESS_HASH,
} from '@copilot/common/tracking/hostedAuthConsts';
import { USER_AUTHENTICATED } from '@copilot/common/tracking/userpilotEventConsts';

type HostedAuthModalProps = {
	/**
	 * If the user is trying to login for another account
	 */
	isOtherAccount?: boolean;
};

/**
 * Modal to show Hosted Auth Success or Failure Notification
 */
export function HostedAuthCompletionModal({ isOtherAccount = false }: HostedAuthModalProps) {
	const location = useLocation();
	const history = useHistory();
	const [isModalVisible, setIsModalVisible] = useState(false);

	function onCancel() {
		setIsModalVisible(false);
	}

	useEffect(() => {
		if (location.hash === HOSTED_AUTH_FAILED_HASH) {
			NotificationManager.showErrorNotification({
				type: 'error',
				message: `Something went wrong while we were trying to sync ${
					isOtherAccount ? 'this' : 'your'
				} LinkedIn account. Please try
				again in a moment, or contact support if this problem persists.`,
				duration: null,
			});
			onCancel();
		} else if (location.hash === HOSTED_AUTH_SUCCESS_HASH) {
			history.replace({ ...location, hash: '' });
			setIsModalVisible(true);
			Userpilot.track(USER_AUTHENTICATED); //this is an approximation of auth success/there is a chance it could still fail on the BE
		}
	}, [location.hash]);

	return (
		<BaseModal
			width={520}
			open={isModalVisible}
			centered
			onOk={onCancel}
			onCancel={onCancel}
			footer={[
				<Button key="submit" type="primary" onClick={onCancel}>
					Continue
				</Button>,
			]}
		>
			<div style={{ textAlign: 'center' }}>
				<CheckCircleFilled className={styles.successIcon} />

				<Typography.Title level={4} className={styles.successTitle}>
					Your login was successful!
				</Typography.Title>

				<Typography.Text className={styles.successDescription}>
					It may take a couple minutes to fully sync. If the status doesn't update, please
					refresh the app.
				</Typography.Text>
			</div>
		</BaseModal>
	);
}
