import React from 'react';
import { useFetch } from '@copilot/common/hooks/common';
import {
	OrganizationManager,
	CampaignManager,
	TemplateManager,
	OrganizationMemberManager,
} from '@copilot/data';
import { OrganizationActions } from '@copilot/common/store/actions/organization';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useSelector } from 'react-redux';
import { LoadMessageTemplatesAction } from '@copilot/common/pages/settings/template/data/saga';
import { AppActions } from '@copilot/common/store/actions/app';
import Store from '../../store';
import { RouteComponentProps } from 'react-router';
import { MessageTemplateActions } from '@copilot/common/store/actions';

const withImpersonation =
	<T,>(Component: React.FC<T>) =>
	(props: T & RouteComponentProps<{ omid: string; oid: string; campaignId: string }>) => {
		const { oid = '', omid = '', campaignId = '' } = props.match.params;
		const [, fetchOrganization] = useFetch(
			OrganizationManager.getOrganization,
			OrganizationActions.loadOrganization
		);
		const [, fetchCampaigns] = useFetch(
			CampaignManager.getLinkedInCampaignsByOrganization,
			CampaignActions.loadCampaigns,
			(r) => r.results
		);
		const [, fetchOrganizationMember] = useFetch(
			OrganizationMemberManager.getMember,
			OrganizationMemberActions.loadOrganizationMember
		);

		const [, fetchOrganizationMembers] = useFetch(
			OrganizationManager.getMembers,
			OrganizationMemberActions.loadOrganizationMember
		);

		const activeMember = useSelector((state) =>
			OrganizationMemberSelectors.getOrgMember(state, omid)
		);

		React.useEffect(() => {
			Store.Dispatch(CampaignActions.resetCampaigns());
			Store.Dispatch(MessageTemplateActions.resetAll());
			if (oid) {
				fetchOrganization(oid);
				fetchCampaigns(oid, 0, 50);
				fetchOrganizationMembers(oid);
				Store.Dispatch(AppActions.loadActiveOrganization(oid));
				Store.Dispatch(
					LoadMessageTemplatesAction(TemplateManager.getTemplateMessagesByOrg, oid)
				);
			}
			if (omid) {
				fetchOrganizationMember(omid);
			}
		}, [oid, omid]);

		// Waiting to load active user once the orgmember exists in the store
		React.useEffect(() => {
			if (activeMember) {
				Store.Dispatch(AppActions.loadActiveUser(omid));
			}
		}, [activeMember?.id]);

		return <Component {...props} campaignId={campaignId} orgMemberId={omid} orgId={oid} />;
	};

export default withImpersonation;
