import { ReactElement } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;
import styles from './accountCreationFormPanel.module.less';

/**
 * Component displaying information to the user
 * @param title
 * @param items
 */
export default function AccountCreationFormPanel(
	title: string,
	items: readonly string[]
): ReactElement {
	return (
		<div className={styles.formPanel}>
			<Text className={styles.header}>{title}</Text>
			<ul>
				{items.map((entry) => (
					<li key={entry}>{entry}</li>
				))}
			</ul>
		</div>
	);
}
