import useTracking from '@copilot/common/hooks/tracking';

/**
 * Tracking for the inbox page list view (email view)
 */
type InboxPageListViewTrackingType =
	| 'Switch to Card View'
	| 'Set Reminder Clicked'
	| 'Set Reminder Specified'
	| 'Edit Reminder'
	| 'Cancel Reminder'
	| 'Dismiss Conversation'
	| 'Row Clicked';

export type InboxPageListViewTrackingParams = {
	readonly buttonClicked: InboxPageListViewTrackingType;
	/**
	 * For Set Reminder Specified and Edit Reminder - the time span from now in ms
	 */
	readonly interval?: number;
};

export const useInboxPageListViewTracking = (
	componentName: string,
	params: InboxPageListViewTrackingParams | null = null
): ((newParams: InboxPageListViewTrackingParams) => void) =>
	useTracking(componentName, 'inbox_page_list_view', params);
