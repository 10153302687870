// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yfv658xxEpJeQa5FzwGF {\n  display: flex;\n  column-gap: 8px;\n  justify-content: flex-end;\n  margin-top: 8px;\n}\n.JkXvFmA3fegN5kQ8oFxC {\n  margin-left: auto;\n}\n.nubRgBkgxQ4uKKwkf041 {\n  display: flex;\n  column-gap: 10px;\n  align-items: top;\n}\n.nubRgBkgxQ4uKKwkf041 .M8lCgJcLMrocitTEvr41 {\n  height: fit-content;\n  margin-top: 4px;\n}\n.nubRgBkgxQ4uKKwkf041 .cIWj2Wx6LzJIW5oEShfj {\n  display: block;\n  margin-top: 0;\n  color: #141414;\n  font-weight: 400;\n}\n.nubRgBkgxQ4uKKwkf041 .YPv9necYHvVNvSMj1pOV {\n  max-width: 320px;\n  overflow: hidden;\n  display: block;\n  color: rgba(0, 0, 0, 0.65);\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/forms/messageForm/resumeAutomationButton.module.less"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,eAAA;EACA,yBAAA;EACA,eAAA;AACD;AAEA;EACC,iBAAA;AAAD;AAGA;EACC,aAAA;EACA,gBAAA;EACA,gBAAA;AADD;AAFA;EAKE,mBAAA;EACA,eAAA;AAAF;AANA;EAUE,cAAA;EACA,aAAA;EACA,cAAA;EACA,gBAAA;AADF;AAZA;EAiBE,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,0BAAA;AAFF","sourcesContent":[".buttonSection {\n\tdisplay: flex;\n\tcolumn-gap: 8px;\n\tjustify-content: flex-end;\n\tmargin-top: 8px;\n}\n\n.resumeButton {\n\tmargin-left: auto;\n}\n\n.contentWrapper {\n\tdisplay: flex;\n\tcolumn-gap: 10px;\n\talign-items: top;\n\t.contentIcon {\n\t\theight: fit-content;\n\t\tmargin-top: 4px;\n\t}\n\n\t.contentTitle {\n\t\tdisplay: block;\n\t\tmargin-top: 0;\n\t\tcolor: #141414;\n\t\tfont-weight: 400;\n\t}\n\n\t.contentBody {\n\t\tmax-width: 320px;\n\t\toverflow: hidden;\n\t\tdisplay: block;\n\t\tcolor: rgba(0, 0, 0, 0.65);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonSection": "Yfv658xxEpJeQa5FzwGF",
	"resumeButton": "JkXvFmA3fegN5kQ8oFxC",
	"contentWrapper": "nubRgBkgxQ4uKKwkf041",
	"contentIcon": "M8lCgJcLMrocitTEvr41",
	"contentTitle": "cIWj2Wx6LzJIW5oEShfj",
	"contentBody": "YPv9necYHvVNvSMj1pOV"
};
export default ___CSS_LOADER_EXPORT___;
