import { Row, Col, Button } from 'antd';
import { FC } from 'react';

interface IBasicDrawerFooterProps {
	onSave: () => void;
	onCancel: () => void;
	saveLabel?: string;
	cancelLabel?: string;
	isSaveDisabled?: boolean;
}

const BasicDrawerFooter: FC<IBasicDrawerFooterProps> = (props) => {
	const { onSave, onCancel, saveLabel = 'Save', cancelLabel = 'Cancel', isSaveDisabled } = props;

	return (
		<Row justify="space-between">
			<Col>
				<Button onClick={onCancel}>{cancelLabel}</Button>
			</Col>
			<Col>
				<Button type="primary" onClick={onSave} disabled={isSaveDisabled}>
					{saveLabel}
				</Button>
			</Col>
		</Row>
	);
};

export default BasicDrawerFooter;
