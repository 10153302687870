import React from 'react';
import { Alert } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LoginForm from '@copilot/common/components/forms/login';
import { AuthenticationManager } from '@copilot/data';
import { AppActions } from '@copilot/common/store/actions/app';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';

interface LoginPageProps {
	hidePwdRecovery?: boolean;
}

const LoginPage: React.FC<LoginPageProps> = (props) => {
	const { hidePwdRecovery } = props;
	const [errorMessage, setErrorMessage] = React.useState<string>('');
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const storeDispatch = useDispatch();

	const handleErrorClose = React.useCallback(() => setErrorMessage(''), []);

	const handleLoginSubmit: React.ComponentProps<typeof LoginForm>['onSubmit'] = React.useCallback(
		(username, password) => {
			setIsSubmitting(true);
			return AuthenticationManager.login(username, password)
				.then((response) => {
					document.cookie = `token=${response.access_token}; path=/`;
					storeDispatch(
						OrganizationMemberActions.loadOrganizationMemberFromToken(response.payload)
					);
					storeDispatch(AppActions.loadActiveUser(response.payload.omid));
					storeDispatch(AppActions.loadActiveUserHash(response.payload.userHash));
					storeDispatch(AppActions.loadActiveTenant(response.payload.tid));
					if (response.payload.iDisplayName)
						storeDispatch(
							AppActions.loadActiveImpersonator({
								name: response.payload.iDisplayName,
								id: response.payload.iid,
							})
						);
					storeDispatch(AppActions.loadActiveOrganization(response.payload.oid));
					return true;
				})
				.catch(() => {
					setErrorMessage('Sorry, you entered an incorrect email or password.');
					return false;
				})
				.finally(() => setIsSubmitting(false));
		},
		[]
	);

	return (
		<>
			{errorMessage && (
				<Alert
					message={errorMessage}
					type="error"
					showIcon
					closable
					onClose={handleErrorClose}
					style={{ marginBottom: '20px', paddingLeft: '44px', textAlign: 'left' }}
				/>
			)}
			<LoginForm onSubmit={handleLoginSubmit} status={{ isSubmitting }} />
			{!hidePwdRecovery && (
				<div>
					<Link to="/passwordrecovery">
						<span>Forgot your password?</span>
					</Link>
				</div>
			)}
		</>
	);
};

export default LoginPage;
