import { createContext, ReactNode } from 'react';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { CSVColumnDefinition } from '@copilot/common/components/componentModels/filterTypeDefinition';
import { DataIndex } from 'rc-table/lib/interface';

export type TableContextColumn = {
	key: string;
	dataIndex?: DataIndex;
	title: ReactNode;
	hideable: boolean;
};
export interface TableContextState<T = Record<string, unknown>> {
	/**
	 * To internally keep track of columns available in the table
	 */
	columnContextList: TableContextColumn[];
	/**
	 * Map of column to visibility
	 */
	hiddenColumnMap: Record<string, boolean>;
	/**
	 * Rows currently selected
	 */
	selectedRows: T[];
}
export interface TableContextActions<T = Record<string, unknown>> {
	updateColumnContextList: (columns: TableContextColumn[]) => void;
	updateSelectedRows: (selectedRows: T[]) => void;
	updateHiddenColumns: (columns: { columnKey: string; isVisible: true }[]) => void;
}
export interface TableContext<T> {
	state: TableContextState<T>;
	actions: TableContextActions<T>;
}
export const DEFAULT_TABLE_CONTEXT = {
	state: { columnContextList: [], hiddenColumnMap: {}, selectedRows: [] },
	actions: {
		updateColumnContextList: () => {},
		updateSelectedRows: () => {},
		updateHiddenColumns: () => {},
	},
};
export const TableContextAny = createContext<TableContext<any>>(DEFAULT_TABLE_CONTEXT);

/**
 * Necessary props to handle load more pagination
 */
export type LoadMorePaginationProps = {
	onLoadMore?: (offset: number) => void;
	hasMore?: boolean;
};
/**
 * [Presentational] Table module column
 */
export interface ITableModuleColumnProps<T> extends ColumnProps<T> {
	columnKey: string;
	hideable?: boolean;
	title?: ReactNode;
}

/**
 * [Presentational] Table module table component
 */
export type TableModuleTableProps<T> = TableProps<T> & {
	useLoadMore?: boolean;
	onLoadMore?: () => void;
	hasMore?: boolean;
};

/**
 * [Presentational] Table module CSV exporter component
 */
export interface ITableModuleCSVExporterProps<T> {
	onExport: (columns: CSVColumnDefinition[], selectedRows: T[]) => void;
	render?: (props: { selectedRows: T[] }) => JSX.Element;
}

/**
 * [Presentational] Table module context connected component
 */
export interface ITableModuleItemProps<T> {
	children: (context: TableContext<T>) => ReactNode;
}

export const INITIAL_CONTEXT_STATE = {
	columnContextList: [],
	selectedRows: [],
	hiddenColumnMap: {},
};

export type UpdateColumnContextListAction = {
	type: 'update_column_context_list';
	payload: TableContextState['columnContextList'];
};

export type UpdateSelectedRowsAction = {
	type: 'update_selected_rows';
	payload: TableContextState['selectedRows'];
};

export type UpdateHiddenColumnsAction = {
	type: 'update_hidden_columns';
	payload: { columnKey: string; isVisible: boolean }[];
};
