import Store from '@copilot/common/store';
import { StyledCloseDrawerButton } from './styles';
import { ContactActions } from '@copilot/common/store/actions/contact';
import { ContactConnection, IContact } from '@copilot/common/store/models/redux';
import { ContactConnectionActions } from '@copilot/common/store/actions';
import { ContactUpdatableFields } from './types';
import DrawerManager from '@copilot/common/utils/drawerManager';
import { RightOutlined } from '@ant-design/icons';
import { ContactManager } from '@copilot/data';
import { upsertOrgTagAction } from '@copilot/common/pages/organizationDashboard/tags/data/saga';
import { InboxActions } from '@copilot/common/store/actions/inbox';
import { modifyMessageTags } from '@copilot/common/pages/inbox/data/actionCreators';
import { ConnectionResponse } from '@copilot/data/responses/interface';
import { SessionBoundModel } from 'redux-orm';
import { Dispatch } from 'react';
import { ContactTagModel } from '@copilot/common/pages/connections/data/types';
import { Tag } from '@copilot/common/pages/organizationDashboard/tags/data/models';
import isNil from 'lodash/isNil';

/**
 * Helper to dispatch all store action required when the contact is updated
 * @param contact
 */
export function dispatchContactUpdate(
	contact: PartialBy<Pick<IContact, 'id' | ContactUpdatableFields>, ContactUpdatableFields>
) {
	Store.Dispatch(ContactActions.updateContact(contact));
	Store.Dispatch(ContactConnectionActions.updateOne(contact));
}

/**
 * The button for closing the drawer.
 */
export function CloseDrawerButton({ onClose }: { onClose?: () => void }) {
	return (
		<StyledCloseDrawerButton
			onClick={() => {
				if (!isNil(onClose)) {
					onClose();
				} else {
					DrawerManager.closeDrawer();
				}
			}}
		>
			<RightOutlined width={16} height={16} />
		</StyledCloseDrawerButton>
	);
}

type BaseSaveTagsInput = {
	contactTags: string[];
	contactConnection: SessionBoundModel<ContactConnection, {}>;
	dispatch: Dispatch<any>;
	convertContactTags: (contactTags: Partial<ContactTagModel>[]) => Promise<Tag[]>;
	threadId?: string;
	csOrgId: string | null;
};

type SaveTagsAsAdminInput = {
	selectedConnection: ConnectionResponse;
	isSagaInbox?: boolean;
} & BaseSaveTagsInput;

/**
 * Save tags for a selected connecton as an orgAdmin
 * @param param0
 */
export async function saveTagsAsAdmin({
	dispatch,
	csOrgId,
	selectedConnection,
	contactTags,
	isSagaInbox,
	threadId,
	convertContactTags,
	contactConnection,
}: SaveTagsAsAdminInput) {
	const response = await ContactManager.updateConnectionTags(
		[selectedConnection.id],
		contactTags,
		csOrgId,
		true
	);
	const updatedConnections = contactConnection.connections;
	updatedConnections.forEach((c) => {
		if (c.id === selectedConnection.id) c.tags = response;
	});

	const { connections: _, ...rest } = contactConnection.ref;

	Store.Dispatch(
		ContactConnectionActions.updateOne({
			...rest,
			connections: updatedConnections,
		})
	);

	dispatch(upsertOrgTagAction(convertContactTags, response));

	// Updates for Teams & CS
	if (threadId) {
		Store.Dispatch(InboxActions.updateInboxMessage({ id: threadId, tags: response }));
	}

	// Updates for individual (new Redux Saga flow)
	if (isSagaInbox && threadId) {
		dispatch(modifyMessageTags(threadId, response));
	}
}

type SaveTagsAsOrgMemberInput = {
	memberId: string;
} & BaseSaveTagsInput;

/**
 * Saves tags for a selected connection as an orgMember (non admin)
 * @param param0
 */
export async function saveConnectionTagsAsOrgMember({
	dispatch,
	csOrgId,
	contactTags,
	threadId,
	convertContactTags,
	contactConnection,
	memberId,
}: SaveTagsAsOrgMemberInput) {
	const response = await ContactManager.updateConnectionTags(
		[contactConnection.connections[0].id],
		contactTags,
		csOrgId,
		true
	);
	const updatedConnections = contactConnection.connections;
	updatedConnections.forEach((c) => {
		if (c.orgMemberId == memberId) c.tags = response;
	});
	const { connections: _, ...rest } = contactConnection.ref;
	Store.Dispatch(
		ContactConnectionActions.updateOne({
			...rest,
			connections: updatedConnections,
		})
	);
	dispatch(upsertOrgTagAction(convertContactTags, response));

	if (threadId) {
		Store.Dispatch(InboxActions.updateInboxMessage({ id: threadId, tags: response }));
	}
}
