import { List, Skeleton, Typography } from 'antd';
import { CampaignType } from '../../../../../../core/data/responses/interface';
import { getPluralizedEnding } from '../../../../utils/stringFormat';

type CampaignInviteAllocation = Readonly<{
	campaignId: string;
	campaignName: string;
	campaignType: CampaignType;
	allocation: number;
}>;

type InviteAllocationsListProps = Readonly<{
	allocations: CampaignInviteAllocation[];
	campaignType: CampaignType;
	isLoading: boolean;
}>;

function renderAllocationItem(item: CampaignInviteAllocation): JSX.Element {
	return (
		<List.Item>
			<Typography>
				{item.campaignName} ({item.allocation}{' '}
				{item.campaignType === CampaignType.Prospecting ? 'invite' : 'message'}
				{getPluralizedEnding(item.allocation)})
			</Typography>
		</List.Item>
	);
}

/**
 * Renders a list of Campaigns with their invite allocations
 * @param param0
 * @returns
 */
export default function InviteAllocationsList({
	allocations,
	campaignType,
	isLoading,
}: InviteAllocationsListProps): JSX.Element {
	const title =
		campaignType === CampaignType.Prospecting
			? 'Connection Request Invite Allocation'
			: 'Daily Message Allocation';
	return (
		<List
			header={<Typography.Title level={5}>{title}</Typography.Title>}
			rowKey="campaignId"
			dataSource={isLoading ? [] : allocations}
			renderItem={renderAllocationItem}
			split={false}
			grid={{ gutter: 8, column: 1 }}
			children={isLoading ? <Skeleton active /> : null}
		/>
	);
}
