import type { Store as ReduxStore } from 'redux';
import { applyMiddleware, compose, createStore, ReducersMapObject } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory, History } from 'history';
import { logger } from './middleware';
import { createEntitiesReducer } from './reducers/entities';
import { createORM } from './orm';
import { createReducer } from './reducers';
import campaignsReducers from '@copilot/common/pages/campaigns/data/reducer';
import {
	nurtureOnboardReducer,
	watchFetchNurtureOnboard,
} from '@copilot/common/pages/wizard/nurtureOnboard/data';
// We need to import Store to inbox saga to retrieve items from redux orm
// eslint-disable-next-line import/no-cycle
import { registerInboxSaga } from '@copilot/common/pages/inbox/data/saga';
import { registerCampaignsSaga } from '@copilot/common/pages/campaigns/data/sagas';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import SentMessagesReducer from '@copilot/common/pages/sent/data/reducers';
import InboxMessageReducer from '@copilot/common/pages/inbox/data/reducers';
import OrganizationTeamMemberReducer from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/reducers';
import CampaignMemberReducer from '@copilot/common/utils/campaignMember/reducers';
import { watchLoadSentMessages } from '@copilot/common/pages/sent/data/saga';
import { watchShowNotification } from '@copilot/common/store/sagas/notification';
import { registerTeamMembersSaga } from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/saga';
import { watchLoadMessageTemplates } from '@copilot/common/pages/settings/template/data/saga';
import MessageTemplateReducer from '@copilot/common/pages/settings/template/data/reducers';
import { registerCampaignMembersSaga } from '@copilot/common/utils/campaignMember/saga';
import { watchLoadAttentionItems } from '@copilot/common/utils/attentionItem/saga';
import { registerBillingSaga } from '@copilot/cs/src/pages/customerDetails/data/saga';
import {
	newUserOnboardReducer,
	watchFetchNewUserOnboard,
} from '../pages/campaignDashboard/newUserOnboard/data';
import {
	billingReducer,
	couponReducer,
	invoicePreviewReducer,
	orgMemberReducer,
	productReducer,
} from '@copilot/cs/src/pages/customerDetails/data/reducers';
import { attentionItemsReducer } from '../utils/attentionItem/reducers';
import campaignSearchListReducer from '@copilot/common/pages/campaignDashboard/searchList/data/reducers';
import { registerConnectionsSaga } from '../pages/connections/data/saga';
import { watchLoadLinkedinProfiles } from '../utils/linkedinProfile/saga';
import { linkedinProfileReducer } from '@copilot/common/utils/linkedinProfile/reducer';
import { interestsReducer, watchFetchUserInterests } from '../utils/userInterests/saga';
import { registerTagsSaga } from '../pages/organizationDashboard/tags/data/saga';
import orgTagsReducer from '../pages/organizationDashboard/tags/data/reducers';

interface StoreClassParams<T> {
	history: History;
	reducer?: ReducersMapObject<T>;
	initialState?: T;
}
export class StoreClass<T> {
	private ReduxORM: ReturnType<typeof createORM>;
	private Store: ReduxStore;
	private BrowserHistory: History;
	private SagaMiddleware: SagaMiddleware<Record<string, unknown>>;

	constructor(params: StoreClassParams<T>) {
		this.SagaMiddleware = createSagaMiddleware();
		let middleware = compose(applyMiddleware(logger), applyMiddleware(this.SagaMiddleware));
		if (process.env.NODE_ENV !== 'production') middleware = composeWithDevTools(middleware);
		this.BrowserHistory = params.history;
		this.ReduxORM = createORM();
		const entitiesReducer = createEntitiesReducer(this.ReduxORM);
		const reducer = createReducer(this.BrowserHistory, {
			entities: entitiesReducer,
			...params.reducer,
		});
		this.Store = createStore(reducer, undefined, middleware);

		this.SagaMiddleware.run(watchShowNotification);
		this.SagaMiddleware.run(watchLoadMessageTemplates);
		this.SagaMiddleware.run(watchLoadSentMessages);
		this.SagaMiddleware.run(watchFetchNurtureOnboard);
		this.SagaMiddleware.run(watchFetchUserInterests);
		this.SagaMiddleware.run(watchFetchNewUserOnboard);
		this.SagaMiddleware.run(watchLoadAttentionItems);
		this.SagaMiddleware.run(watchLoadLinkedinProfiles);
		registerInboxSaga(this.SagaMiddleware);
		registerCampaignsSaga(this.SagaMiddleware);
		registerTeamMembersSaga(this.SagaMiddleware);
		registerCampaignMembersSaga(this.SagaMiddleware);
		registerBillingSaga(this.SagaMiddleware);
		registerConnectionsSaga(this.SagaMiddleware);
		registerTagsSaga(this.SagaMiddleware);
	}

	public get ORM() {
		return this.ReduxORM;
	}

	public get RegisterModel() {
		return this.ReduxORM.register;
	}

	public get History() {
		return this.BrowserHistory;
	}

	public get Dispatch() {
		return this.Store.dispatch;
	}

	public get ReduxStore() {
		return this.Store;
	}

	public get State() {
		return this.Store.getState();
	}

	public RegisterMiddleware = (
		register: (sagaMiddleware: ReturnType<typeof createSagaMiddleware>) => void
	) => {
		register(this.SagaMiddleware);
	};
}

const browserHistory = createBrowserHistory();

export const createCopilotStore = (history: History) =>
	new StoreClass({
		history,
		reducer: {
			...campaignsReducers,
			...nurtureOnboardReducer,
			...newUserOnboardReducer,
			...SentMessagesReducer,
			...InboxMessageReducer,
			...MessageTemplateReducer,
			...OrganizationTeamMemberReducer,
			...CampaignMemberReducer,
			...billingReducer,
			...orgMemberReducer,
			...productReducer,
			...couponReducer,
			...invoicePreviewReducer,
			...interestsReducer,
			...attentionItemsReducer,
			...campaignSearchListReducer,
			...linkedinProfileReducer,
			...orgTagsReducer,
		},
	});

const Store = createCopilotStore(browserHistory);

export default Store;
