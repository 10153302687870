export const Regex = {
	Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	PhoneNumber: /^[\-.,*+#\d\ ]*(\({1}[\d]+\){1})?[\-.,*+#\d\ ]*$/,
	UsZip: /^[0-9]{5}(?:-[0-9]{4})?$/,
	CanadianPostalCode: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
};

/**
 * Validates the given string using regex and returns the validity
 * @param {string} value string that needs validation
 * @param {RegExp} regex regular expression to test value against
 */
export const validateWithRegex = (value: string, regex: RegExp) => regex.test(value.toLowerCase());
