// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MI93gfjNqCtiBq13niOL {\n  margin-bottom: 24px;\n}\n.MI93gfjNqCtiBq13niOL header {\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n}\n.MI93gfjNqCtiBq13niOL h3 {\n  margin: 0;\n  margin-right: 8px;\n  font-size: 16px;\n  font-weight: 500;\n}\n.MI93gfjNqCtiBq13niOL label {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/drawer/wrappers/accountDashboard/section.module.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;AAAA;EAII,aAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AALA;EAUI,SAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AAFJ;AAXA;EAiBI,aAAA;AAHJ","sourcesContent":["@import '../../../../../styles/antd-variables.less';\n@import '../../../../../styles/common-variables.less';\n.section {\n  margin-bottom: @spacer-size-md;\n  \n  header {\n    display: flex;\n    align-items: center;\n    margin-bottom: @spacer-num-xs;\n  }\n  \n  h3 {\n    margin: 0;\n    margin-right: @spacer-num-xs;\n    font-size: @font-size-base;\n    font-weight: @fontWeightMedium,\n  }\n\n  label {\n    display: flex;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "MI93gfjNqCtiBq13niOL"
};
export default ___CSS_LOADER_EXPORT___;
