import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { DrawerProps } from 'antd/lib/drawer';
import BaseDrawer from '../base';
import { PlusOutlined } from '@ant-design/icons';
import { Card, Row, Col, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useFetch } from '@copilot/common/hooks/common';
import { OrganizationManager, CampaignMemberManager } from '@copilot/data';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';
import styled from 'styled-components';
import { CampaignRoleTitles } from '@copilot/common/store/models/const/enum';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import {
	AddCampaignMemberToDirtyListAction,
	AutoUpdateCampaignMemberAction,
} from '@copilot/common/utils/campaignMember/saga';
import { IOrganizationMember } from '@copilot/common/store/models/redux';

interface CampaignTeamDrawerProps extends DrawerProps {
	id: string;
	campaignMembers: { id: string; orgMemberId: string; firstName: string; lastName: string }[];
}

const StyledRow = styled(Row)`
	margin: 5px 0;
	padding: 5px;

	&:hover {
		background: #fafafa;
	}
`;

const CampaignTeamDrawer: React.FC<CampaignTeamDrawerProps> = (props) => {
	const { id, campaignMembers: defaultCampaignMembers = [], ...drawerProps } = props;
	const storeDispatch = useDispatch();
	const [campaignMembers, setCampaignMembers] = useState(defaultCampaignMembers);
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const [orgMembersFetch, fetchOrgMembers] = useFetch(
		OrganizationManager.getMembers,
		OrganizationMemberActions.loadOrganizationMember
	);
	const [displayedOrgMemberIds, setDisplayedOrgMemberIds] = useState<string[]>([]);

	useEffect(() => {
		activeOrganization && fetchOrgMembers(activeOrganization.id);
	}, [activeOrganization?.id]);

	useEffect(() => {
		const ids = Array.isArray(orgMembersFetch.data)
			? orgMembersFetch.data.map((d) => d.id!)
			: [];
		setDisplayedOrgMemberIds(ids);
	}, [orgMembersFetch.data]);

	const orgMembers = useSelector(
		OrganizationMemberSelectors.getOrgMembers(displayedOrgMemberIds)
	) as any as IOrganizationMember[];
	const handleAddToCampaign = useCallback(
		(organizationMemberId: string) => {
			CampaignMemberManager.addCampaignMembers(
				id,
				[organizationMemberId],
				[CampaignRoleTitles.Member]
			).then((m) => {
				const newMembers = [...campaignMembers, ...m];
				const newCampaignMemberIds = m.map((newMember) => newMember.id);
				storeDispatch(AddCampaignMemberToDirtyListAction(id)(newCampaignMemberIds));
				storeDispatch(AutoUpdateCampaignMemberAction(newCampaignMemberIds));
				setCampaignMembers(newMembers);
				storeDispatch(
					CampaignActions.updateCampaign({
						id,
						members: newMembers,
					})
				);
				setDisplayedOrgMemberIds((ids) => ids.filter((i) => i !== organizationMemberId));
			});
		},
		[id, campaignMembers]
	);

	const campaignMemberElements = useMemo(
		() =>
			campaignMembers.map((c) => (
				<StyledRow key={c.id} align="middle">
					<Col span={20}>
						{c.firstName} {c.lastName}
					</Col>
				</StyledRow>
			)),
		[campaignMembers]
	);

	const organizationMemberElements = useMemo(() => {
		if (!Array.isArray(orgMembers)) return undefined;
		const existingMembers = campaignMembers.reduce((acc, c) => {
			acc[c.orgMemberId] = true;
			return acc;
		}, {} as { [k: string]: boolean });
		return orgMembers
			.filter((o) => !existingMembers[o.id])
			.map((o) => (
				<StyledRow key={o.id} align="middle">
					<Col span={20}>{o.name}</Col>
					<Col span={4}>
						<Button
							onClick={handleAddToCampaign.bind(undefined, o.id)}
							type="primary"
							shape="circle"
						>
							<PlusOutlined />
						</Button>
					</Col>
				</StyledRow>
			));
	}, [orgMembers, campaignMembers]);
	return (
		<BaseDrawer {...drawerProps}>
			<Card title="Current Campaign Members">{campaignMemberElements}</Card>
			<br />
			<Card title="Organization Members">{organizationMemberElements}</Card>
			<br />
		</BaseDrawer>
	);
};

export default CampaignTeamDrawer;
