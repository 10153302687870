import { createLoadActions } from '@copilot/common/store/actionCreators/list';
import { TagLevel } from '@copilot/common/store/models/const/enum';
import { createFetchSaga } from '@copilot/common/store/saga';
import { TagManager } from '@copilot/data';
import createSagaMiddleware from 'redux-saga';
import { TAGS } from './constant';
import { Tag } from './models';

export const {
	listWatcher: watchLoadOrgTags,
	upsertListWatcher: watchUpsertOrgTags,
	deleteOneWatcher: watchDeleteOrgTag,
	loadListAction: loadOrgTagsAction,
	upsertListAction: upsertOrgTagAction,
	deleteOneAction: deleteOrgTagAction,
} = createFetchSaga(TAGS, createLoadActions<Tag>(TAGS));

export const createTagAction = async (orgId: string, newTag: Tag) => {
	const tagResponse = await TagManager.addTagToOrg(orgId, newTag);
	const updatedTag: Tag = {
		id: tagResponse.id,
		name: tagResponse.name,
		description: tagResponse.description,
		level: tagResponse.isSystem ? TagLevel.System : TagLevel.Organization,
	};
	return [updatedTag];
};

export const updateTagAction = async (orgId: string, newTag: Tag) => {
	await TagManager.updateTag(orgId, newTag);
	return [newTag];
};

/**
 * Apply Tag sagas to the saga middleware
 * @param sagaMiddleware
 */
export const registerTagsSaga = (sagaMiddleware: ReturnType<typeof createSagaMiddleware>): void => {
	sagaMiddleware.run(watchLoadOrgTags);
	sagaMiddleware.run(watchUpsertOrgTags);
	sagaMiddleware.run(watchDeleteOrgTag);
};
