import React from 'react';
import { Button, Form } from 'antd';
import drawerManager from '@copilot/common/utils/drawerManager';
import DynamicAdditions, {
	DynamicAdditionsValue,
	DA_DEFAULT_KEY,
} from '@copilot/common/components/forms/common/inputs/dynamicAdditions';

export interface MessageEditFormProps {
	message: string[];
	saveMessage: (message: string[]) => void;
	nodeId: string;
}

const MessageEditForm: React.FC<MessageEditFormProps> = (props) => {
	const { message, saveMessage } = props;
	const [form] = Form.useForm();

	const handleSaveMessage = (values: DynamicAdditionsValue<string>) => {
		const messages = values[DA_DEFAULT_KEY];
		saveMessage(messages);
		drawerManager.closeDrawer();
	};

	return (
		<Form
			form={form}
			onFinish={handleSaveMessage}
			style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}
		>
			<label>
				Write the message you would like to send if this step is triggered. This message
				will also be available in your inbox when categorizing
			</label>
			<DynamicAdditions
				defaultLabel="Enter a message here"
				form={form}
				initialValues={message}
			/>
			<div className="drawer-function-buttons">
				<Button type="primary" htmlType="submit">
					Update Message
				</Button>
			</div>
		</Form>
	);
};

export default MessageEditForm;
