import * as React from 'react';
import { batch } from 'react-redux';
import {
	LinkedinFilled,
	MailOutlined,
	ShopOutlined,
	PhoneOutlined,
	CrownOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Contact } from '@copilot/common/store/models/redux';
import EditableTextDisplay from '@copilot/common/components/forms/common/inputs/editableTextDisplay';
import { SessionBoundModel } from 'redux-orm';
import Store from '@copilot/common/store';
import styled, { withTheme, DefaultTheme } from 'styled-components';
import moment from 'moment';
import { URL } from '@copilot/common/data/urls';
import { InboxManager } from '@copilot/data';
import { ActivityActions } from '@copilot/common/store/actions/activity';
import { InboxActions } from '@copilot/common/store/actions/inbox';
import notificationManager from '@copilot/common/utils/notificationManager';
import { DEFAULT_DATE_FORMAT } from '@copilot/common/utils/dateFormat';
import { Regex, validateWithRegex } from '@copilot/data/utils/index';
import { ProspectDrawerTrackingParams } from '@copilot/common/components/drawer/wrappers/contact/tracking';
import DropdownReminderSelector from '@copilot/common/components/selector/reminder/dropdownSelector/dropdownReminderSelector';
import { getMillisecondsFromNow } from '@copilot/common/utils/dateUtil';

export type ContactDrawerSummaryProps = {
	theme: DefaultTheme;
	contact: SessionBoundModel<Contact> | null;
	contactUrl?: string;
	conversationId: string;
	reminderDate?: moment.Moment;
	handleContactUpdate: (contact: Partial<SessionBoundModel<Contact>>) => void;
	handleProspectDrawerTracking: (params: ProspectDrawerTrackingParams) => void;
};

interface ContactDrawerSummaryState {
	openDatePicker: boolean;
}

const ContactItemWrapper = styled.span`
	display: flex;
	align-items: center;
`;

const ValidationAlertMessages = {
	Email: 'Please enter a valid e-mail',
	PhoneNumber: 'Please enter a valid phone number',
};

class ContactDrawerSummary extends React.Component<
	ContactDrawerSummaryProps,
	ContactDrawerSummaryState
> {
	iconCss = `
		font-size: 1.35em;
		color: ${this.props.theme['@primary-color']};
		margin: 5px;
	`;

	constructor(props: ContactDrawerSummaryProps) {
		super(props);

		this.state = {
			openDatePicker: false,
		};
	}

	setReminderClick = () => {
		this.props.handleProspectDrawerTracking({ buttonClicked: 'Set Reminder Clicked' });
	};

	setReminder = (date: moment.Moment, isNewReminder: boolean) => {
		this.updateReminder(date);
		const interval = getMillisecondsFromNow(date);
		this.props.handleProspectDrawerTracking({
			buttonClicked: isNewReminder ? 'Set Reminder' : 'Edit Reminder',
			interval,
		});
	};

	cancelReminder = () => {
		this.updateReminder();
		this.props.handleProspectDrawerTracking({ buttonClicked: 'Cancel Reminder' });
	};

	updateReminder = (date?: moment.Moment) => {
		const remindOn = date?.toISOString();
		InboxManager.snoozeThread(this.props.conversationId, remindOn)
			.then(() => {
				notificationManager.showSuccessNotification({
					message: date
						? `Reminder set for ${date.format(DEFAULT_DATE_FORMAT)}`
						: 'Reminder Cancelled',
					description: '',
				});
				batch(() => {
					Store.Dispatch(
						ActivityActions.updateConversation({
							id: this.props.conversationId,
							isSnoozed: !!date,
							snoozedDateTime: remindOn,
						})
					);
					Store.Dispatch(
						InboxActions.updateInboxMessage({
							id: this.props.conversationId,
							isSnoozed: !!date,
							isRead: !!date,
						})
					);
				});
			})
			.catch(() => {
				notificationManager.showErrorNotification({
					message: date ? 'Failed to set Reminder' : 'Failed to cancel Reminder',
					description: 'Please try again.',
				});
			});
	};

	toggleDatePicker = (isOpen?: boolean) => {
		this.setState((prevState) => ({
			openDatePicker: isOpen ?? !prevState.openDatePicker,
		}));
	};

	validateContactUpdate = (input: string, regex: RegExp, msg: string) =>
		!validateWithRegex(input, regex) ? msg : undefined;

	render() {
		if (!this.props.contact) return null;
		const { organization: _, ...rest } = this.props.contact.ref;
		return (
			<div className="contact-information">
				<Row justify="space-between" align="middle">
					<Col span={24}>
						<h2>
							{`${this.props.contact.firstName} ${this.props.contact.lastName}`}{' '}
							<span style={{ verticalAlign: 'middle' }}>
								{this.props.contactUrl?.trim() != '' && (
									<a
										href={`${URL.LINKEDIN_URL}/in/${this.props.contactUrl}`}
										target="_blank"
										onClick={() =>
											this.props.handleProspectDrawerTracking({
												buttonClicked: 'LinkedIn Icon',
											})
										}
									>
										<LinkedinFilled style={{ fontSize: '1.4em' }} />
									</a>
								)}
							</span>{' '}
							<span style={{ position: 'relative' }}>
								<DropdownReminderSelector
									selectedDate={this.props.reminderDate}
									onUpdate={this.setReminder}
									onCancel={this.cancelReminder}
									onSetReminderClick={this.setReminderClick}
									isReminderSet={!!this.props.reminderDate}
									buttonStyle={{ width: '160px', verticalAlign: 'text-bottom' }}
								/>
							</span>
						</h2>
					</Col>
				</Row>
				<Row justify="space-between" align="middle">
					<Col span={12} className="meta-data">
						<ContactItemWrapper>
							<MailOutlined className="icon" title="Email" css={this.iconCss} />
							<EditableTextDisplay
								value={this.props.contact.email || ''}
								allowEmpty
								type="email"
								inputValidator={(input) =>
									input
										? this.validateContactUpdate(
												input,
												Regex.Email,
												ValidationAlertMessages.Email
										  )
										: undefined
								}
								confirmHandler={(input) => {
									if (this.props.contact) {
										this.props.handleContactUpdate({
											...rest,
											email: input,
										});
									}
								}}
								confirmOnBlur
								onEditClick={() =>
									this.props.handleProspectDrawerTracking({
										buttonClicked: 'Edit Contact Info',
									})
								}
							>
								{this.props.contact.email}
							</EditableTextDisplay>
						</ContactItemWrapper>
					</Col>
					<Col span={12} className="meta-data">
						<ContactItemWrapper>
							<ShopOutlined
								className="icon"
								title="Company Name"
								css={this.iconCss}
							/>
							<EditableTextDisplay
								value={this.props.contact.company || ''}
								allowEmpty
								confirmHandler={(input) => {
									if (this.props.contact) {
										this.props.handleContactUpdate({
											...rest,
											company: input,
										});
									}
								}}
								confirmOnBlur
								onEditClick={() =>
									this.props.handleProspectDrawerTracking({
										buttonClicked: 'Edit Contact Info',
									})
								}
							>
								{this.props.contact.company}
							</EditableTextDisplay>
						</ContactItemWrapper>
					</Col>
					<Col span={12} className="meta-data">
						<ContactItemWrapper>
							<PhoneOutlined className="icon" title="Phone" css={this.iconCss} />
							<EditableTextDisplay
								value={this.props.contact.phoneNumber || ''}
								allowEmpty
								inputValidator={(input) =>
									this.validateContactUpdate(
										input.trim(),
										Regex.PhoneNumber,
										ValidationAlertMessages.PhoneNumber
									)
								}
								confirmHandler={(input) => {
									if (this.props.contact) {
										this.props.handleContactUpdate({
											...rest,
											phoneNumber: input.trim(),
										});
									}
								}}
								confirmOnBlur
								onEditClick={() =>
									this.props.handleProspectDrawerTracking({
										buttonClicked: 'Edit Contact Info',
									})
								}
							>
								{this.props.contact.phoneNumber}
							</EditableTextDisplay>
						</ContactItemWrapper>
					</Col>
					<Col span={12} className="meta-data">
						<ContactItemWrapper>
							<CrownOutlined className="icon" title="Job Title" css={this.iconCss} />
							<EditableTextDisplay
								value={this.props.contact.position || ''}
								allowEmpty
								confirmHandler={(input) => {
									if (this.props.contact) {
										this.props.handleContactUpdate({
											...rest,
											position: input,
										});
									}
								}}
								confirmOnBlur
								onEditClick={() =>
									this.props.handleProspectDrawerTracking({
										buttonClicked: 'Edit Contact Info',
									})
								}
							>
								{this.props.contact.position}
							</EditableTextDisplay>
						</ContactItemWrapper>
					</Col>
				</Row>
			</div>
		);
	}
}

export default withTheme(ContactDrawerSummary);
