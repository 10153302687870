import { ScheduledMessageModel, ScheduledMessage } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = ScheduledMessageModel.modelName;
export namespace ScheduledMessageActions {
	export const loadScheduledMessages = (scheduledMessages: Partial<ScheduledMessage[]>) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: scheduledMessages,
	});

	export const deleteScheduledMessage = (scheduledMessage: Partial<ScheduledMessage>) => ({
		type: EntitiesActionTypes.Delete,
		itemType,
		payload: scheduledMessage,
	});

	export const resetScheduledMessages = () => ({
		type: EntitiesActionTypes.Reset,
		itemType,
	});
}
