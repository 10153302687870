// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V0kT_hj9GU5r3Iold8sV {\n  row-gap: 8px;\n}\n.LRV1YCV6rIjwNhFuzyV9 {\n  margin-right: auto;\n  padding: 5px 10px 25px;\n}\n.ZaNZ5vTjoVLPEDnjQ7Vg {\n  background: #fff;\n  border-style: dashed;\n  margin-right: auto;\n}\n.TYqYzzsPJvQF5s0wo6jh {\n  margin-bottom: 5px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/drawer/wrappers/contact/tags/tagsRow.module.less"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAIA;EACE,kBAAA;EACA,sBAAA;AAFF;AAKA;EACE,gBAAA;EACA,oBAAA;EACA,kBAAA;AAHF;AAMA;EACE,kBAAA;AAJF","sourcesContent":["@import '../../../../../../styles/antd-variables.less';\n\n.tagsRowWrapper {\n  row-gap: @spacer-size-xs;\n}\n\n.saveTagsButton {\n  margin-right: auto;\n  padding: 5px 10px 25px;\n}\n\n.editTag {\n  background: #fff;\n  border-style: dashed;\n  margin-right: auto;\n}\n\n.tagList {\n  margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagsRowWrapper": "V0kT_hj9GU5r3Iold8sV",
	"saveTagsButton": "LRV1YCV6rIjwNhFuzyV9",
	"editTag": "ZaNZ5vTjoVLPEDnjQ7Vg",
	"tagList": "TYqYzzsPJvQF5s0wo6jh"
};
export default ___CSS_LOADER_EXPORT___;
