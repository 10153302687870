export const HEADER_LOADER_TEST_ID = 'header-loader';
export const INFO_PANEL_WRAPPER_TEST_ID = 'info-panel-wrapper';
export const HEADER_SECTION_WRAPPER_TEST_ID = 'header-section-wrapper';

export const HEADER_LINKEDIN_BUTTON_TEST_ID = 'header-linkedin-button';
export const HEADER_CONNECTION_SELECTOR_TEST_ID = 'header-connection-selector';

export const PERSONALIZED_INSIGHTS_BUTTON_TEST_ID = 'personalized-insights-button';

export const PROSPECT_INFO_BUTTON_TEXT = 'Prospect Info';
export const PROSPECT_INFO_LOADER_TEST_ID = 'prospect-info-loader';
export const PROSPECT_INFO_WRAPPER_TEST_ID = 'prospect-info-wrapper';

export const SET_REMINDER_BUTTON_TEXT = 'Set Reminder';

export const TAGS_BUTTON_TEXT = 'Tags';
export const TAGS_LOADER_TEST_ID = 'tags-loader';
export const TAGS_WRAPPER_TEST_ID = 'tags-wrapper';
export const TAGS_EDIT_BUTTON_TEST_ID = 'tags-edit-button';
export const TAGS_SAVE_BUTTON_TEST_ID = 'tags-save-button';

export const CAMPAIGN_HISTORY_BUTTON_TEXT = 'Campaign History';
export const CAMPAIGN_HISTORY_LOADER_TEST_ID = 'campaign-history-loader';
export const CAMPAIGN_HISTORY_WRAPPER_TEST_ID = 'campaign-history-wrapper';

export const MESSAGED_STEP_TEST_ID = 'messaged-step';
export const REPLIED_STEP_TEST_ID = 'replied-step';
export const BOOKED_STEP_TEST_ID = 'booked-step';
