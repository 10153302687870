import { attr, fk } from 'redux-orm';
import { BaseModel } from './base';
import { SchedulingStatus, ThreadStatus } from '../const/enum';

export type ScheduledMessage = {
	threadId: string;
	message: string;
	timestamp: string;
	orgMemberId: string;
	contactId: string;
	dateCreated: string;
	status: ThreadStatus;
	error: SchedulingStatus;
	id: string;
	ownerOrgMemberId: string;
};

export class ScheduledMessageModel extends BaseModel<
	typeof ScheduledMessageModel,
	ScheduledMessage
> {
	static modelName = 'ScheduledMessageModel';
	static fields = {
		id: attr(),
		threadId: attr(),
		message: attr(),
		contactId: attr(),
		dateCreated: attr(),
		timestamp: attr(),
		orgMemberId: fk({ to: 'OrganizationMember', as: 'organizationMember' }),
		status: attr(),
		error: attr(),
		ownerOrgMemberId: attr(),
	};
}
