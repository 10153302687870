import { attr, many } from 'redux-orm';
import { BaseModel } from './base';
import { OrganizationType } from '../const/enum';

export interface IOrganization {
	id: string;
	name: string;
	members: string[];
	logoUrl?: string;
	actionStats: string[];
	tagStats: string[];
	orgType: OrganizationType;
	/** MaxSeats === null when EnhancedBillingFeature turned on. */
	maxSeats?: number;
	seatsAllocated: number;
	/**
	 * Whether the organization is in free trial mode
	 */
	isFreeTrial: boolean;
	tenantCreatedAt: string;
}

export class Organization extends BaseModel<typeof Organization, IOrganization> {
	static modelName = 'Organization';
	static fields = {
		id: attr(),
		name: attr(),
		members: many('OrganizationMember'),
		logoUrl: attr(),
		actionStats: attr(),
		tagStats: attr(),
		orgType: attr(),
		/** MaxSeats === null when EnhancedBillingFeature turned on. */
		maxSeats: attr(),
		seatsAllocated: attr(),
		isFreeTrial: attr(),
		instanceId: attr(),
		tenantCreatedAt: attr(),
	};
}
