import { ColumnProps } from 'antd/lib/table';

import BaseColumn from './base';
import { IconColumnDefinition } from '../../componentModels/columnDefinition';

export class TextWithIconColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];
	constructor(c: IconColumnDefinition) {
		super(c);

		this.render = (_: string, record: M) => {
			const { properties = [] } = c;
			const values = this.getValues<M>(properties, record);
			const textValue = values[0];
			const iconValue = values[1];
			let iconHtml = '';
			if (c.determineIconFunction && iconValue) {
				iconHtml = c.determineIconFunction(iconValue);
			}
			return (
				<span>
					{iconHtml} {textValue}
				</span>
			);
		};
	}
}
