// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D4NynuNyVUsjiEC_7crQ div.copilot-tabs-nav-operations.copilot-tabs-nav-operations {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/filters/dateRange.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;AAAJ","sourcesContent":[".tabsWithHiddenDropdown {\n  :global(div.copilot-tabs-nav-operations.copilot-tabs-nav-operations) {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsWithHiddenDropdown": "D4NynuNyVUsjiEC_7crQ"
};
export default ___CSS_LOADER_EXPORT___;
