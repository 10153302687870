import type { Stats, StatsResponse } from '@copilot/data/responses/interface';
import { StatisticData, StatisticTotal, TimestampKeyType } from './type';

/**
 * Data Adpater for stats
 */
export class StatisticAdapter {
	protected timestampKey: TimestampKeyType = 'timestamp';
	public tagLabels: Set<string>;
	public actionLabels: Set<string>;
	public totals: StatisticTotal;
	public data: StatisticData;

	constructor(input: Record<string, StatsResponse>) {
		this.tagLabels = new Set();
		this.actionLabels = new Set();
		this.totals = {};
		this.data = {};
		this.parseData(input);
	}

	//#region Helper
	/**
	 * Add the label present in the row to the tagLabels and actionLabels
	 * @param row
	 */
	private parseLabels = (row: Stats) => {
		for (const label in row.actionStats) {
			this.actionLabels.add(label);
		}
		for (const label in row.tagStats) {
			this.tagLabels.add(label);
		}
	};

	/**
	 * Calculate the totals from the parsed data
	 * @param {Record<string, Record<string, Record<string, number>>>} datas
	 * @returns {Record<string, Record<string, number>>} total by stats label
	 */
	private calculateTotalsByLabels = () => {
		const totals: { [label: string]: { [ownerId: string]: number } } = {};
		for (const label in this.data) {
			const total: { [ownerId: string]: number } = {};
			for (const data of this.data[label]) {
				for (const ownerId in data) {
					if (ownerId == this.timestampKey) continue;
					if (ownerId in total) total[ownerId] += data[ownerId];
					else total[ownerId] = data[ownerId];
				}
			}
			totals[label] = total;
		}
		return totals;
	};

	/**
	 * Parse for the available stats label
	 */
	private parseDataLabels = (data: Record<string, StatsResponse>) => {
		for (const owner in data) {
			// parse for the available stats label
			data[owner].forEach(this.parseLabels);
		}
	};

	/**
	 * Helper function to parse stats data
	 */
	private parseData = (data: Record<string, StatsResponse>) => {
		// Pre-parse action
		this.parseDataLabels(data);

		const parsedData: {
			[label: string]: { [timestamp: string]: { [ownerId: string]: number } };
		} = {};
		this.tagLabels.forEach((l) => (parsedData[l] = {}));
		this.actionLabels.forEach((l) => (parsedData[l] = {}));
		for (const ownerId in data) {
			for (const dataPoint of data[ownerId]) {
				for (const label in dataPoint.actionStats) {
					if (!(dataPoint.timestamp in parsedData[label]))
						parsedData[label][dataPoint.timestamp] = {};
					parsedData[label][dataPoint.timestamp][ownerId] = dataPoint.actionStats[label];
				}
				for (const label in dataPoint.tagStats) {
					if (!(dataPoint.timestamp in parsedData[label]))
						parsedData[label][dataPoint.timestamp] = {};
					parsedData[label][dataPoint.timestamp][ownerId] = dataPoint.tagStats[label];
				}
			}
		}

		// Convert the parsed data to the proper format
		for (const label in parsedData) {
			this.data[label] = [];
			for (const timestamp in parsedData[label]) {
				this.data[label].push({
					[this.timestampKey]: Date.parse(timestamp),
					...parsedData[label][timestamp],
				});
			}
		}
		// Make sure the parsed data are sorted by timestamp
		for (const label in this.data) {
			this.data[label].sort((a, b) => a.timestamp - b.timestamp);
		}
		this.totals = this.calculateTotalsByLabels();
	};
	//#endregion
}
