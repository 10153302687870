// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EFAyJWqC4n8icET6pufQ {\n  margin: 16px 24px;\n}\n.EFAyJWqC4n8icET6pufQ h2 {\n  font-size: 16px;\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/drawer/wrappers/accountDashboard/drawerHeader.module.less"],"names":[],"mappings":"AAGA;EACE,iBAAA;AAFF;AACA;EAII,eAAA;EACA,gBAAA;AAFJ","sourcesContent":["@import '../../../../../styles/antd-variables.less';\n@import '../../../../../styles/common-variables.less';\n\n.header {\n  margin: @spacer-size-sm @spacer-size-md;\n  \n  h2 {\n    font-size: @font-size-base;\n    font-weight: @fontWeightSemiBold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "EFAyJWqC4n8icET6pufQ"
};
export default ___CSS_LOADER_EXPORT___;
