import { getFormattedDate, LONG_DATE_FORMAT } from '@copilot/common/utils/dateFormat';
import { Alert } from 'antd';
import Link from 'antd/lib/typography/Link';
import { createStripeSubscriptionLink, createStripeUpcomingInvoiceLink } from './utils/stripe';

interface AlertWithLinkProps {
	/**
	 *  Message to be displayed in the alert.
	 */
	message: string;
	/**
	 * Link URL.
	 */
	linkUrl: string;
	/**
	 * Text for link.
	 */
	linkText: string;

	/**
	 * Callback for click of the link
	 */
	onClick?: () => void;
}

const AlertWithLink: React.FC<AlertWithLinkProps> = ({ message, linkUrl, linkText, onClick }) => {
	const alertMessage = (
		<div style={{ fontSize: '14px' }}>
			{message}
			<br />
			<Link target="_blank" href={linkUrl} onClick={onClick}>
				{linkText}
			</Link>
		</div>
	);

	return <Alert style={{ marginBottom: '20px' }} message={alertMessage} type="warning" />;
};

/**
 * Properties required to display an invoice-specific alert.
 */
interface InvoiceAlertProps {
	/**
	 * Date to display in the alert.
	 */
	date: string;
	/**
	 * Customer name to be displayed in the alert.
	 */
	name: string;
	/**
	 * SubscriptionId for view stripe link.
	 */
	subscriptionId: string;

	/**
	 * Callback for click of the link
	 */
	onClick?: () => void;
}

/**
 * Alert for update scheduled in next invoice.
 */
export const UpdateScheduleAlert: React.FC<InvoiceAlertProps> = ({
	date,
	name,
	subscriptionId,
	onClick,
}) => {
	const updateScheduledLongFormat = getFormattedDate(date, LONG_DATE_FORMAT);
	const message = `${name}'s billing is updating on ${updateScheduledLongFormat}`;

	return (
		<AlertWithLink
			message={message}
			linkUrl={createStripeSubscriptionLink(subscriptionId)}
			linkText="View changes on Stripe."
			onClick={onClick}
		/>
	);
};

/**
 * Alert for account cancelling in next invoice.
 */
export const CancellingAlert: React.FC<InvoiceAlertProps> = ({
	date,
	name,
	subscriptionId,
	onClick,
}) => {
	const cancelAtDateLongFormat = getFormattedDate(date, LONG_DATE_FORMAT);
	const message = `${name} is cancelling on ${cancelAtDateLongFormat}. Account may include credit or unpaid balance.`;

	return (
		<AlertWithLink
			message={message}
			linkUrl={createStripeSubscriptionLink(subscriptionId)}
			linkText="View on Stripe."
			onClick={onClick}
		/>
	);
};

/**
 * Alert for prorated charges in next invoice.
 */
export const ProratedChargesAlert: React.FC<{
	customerId: string;
	subscriptionId: string;
	onClick?: () => void;
}> = ({ customerId, subscriptionId, onClick }) => {
	const message = 'Invoice has prorated charges.';

	return (
		<AlertWithLink
			message={message}
			linkUrl={createStripeUpcomingInvoiceLink(customerId, subscriptionId)}
			linkText="View full invoice on Stripe."
			onClick={onClick}
		/>
	);
};

export const PreviouslyCancelledSubscriptionAlert: React.FC<{
	name: string;
	subscriptionId: string;
}> = ({ name, subscriptionId }) => {
	const message = `${name} has a previously cancelled subscription.`;

	return (
		<AlertWithLink
			message={message}
			linkUrl={createStripeSubscriptionLink(subscriptionId)}
			linkText="View on Stripe."
		/>
	);
};
