// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MteRXWNE7duVBwtWkHz8 {\n  width: 300px;\n}\n.cmvJqVuECeVte8Ci5SMZ {\n  display: flex;\n  justify-content: space-between;\n}\n.gO00GfkceJHFYYLDxowW {\n  display: flex;\n  justify-content: space-between;\n  font-size: 12px;\n  margin-bottom: 5px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/componentModels/activities/notes/notes.module.less"],"names":[],"mappings":"AAAA;EACC,YAAA;AACD;AACA;EACC,aAAA;EACA,8BAAA;AACD;AACA;EACC,aAAA;EACA,8BAAA;EACA,eAAA;EACA,kBAAA;AACD","sourcesContent":[".disabledTooltip {\n\twidth: 300px;\n}\n.noteItemBody {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n.noteItemHeader {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tfont-size: 12px;\n\tmargin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabledTooltip": "MteRXWNE7duVBwtWkHz8",
	"noteItemBody": "cmvJqVuECeVte8Ci5SMZ",
	"noteItemHeader": "gO00GfkceJHFYYLDxowW"
};
export default ___CSS_LOADER_EXPORT___;
