import { CampaignFields, ICampaign, ICampaignMember } from '@copilot/common/store/models/redux';
import { CampaignStatusEnum } from '@copilot/data/requests/models';
import { Allocation } from './interface';

type AllocationCreatorFunction<R> = (campaign: CampaignFields, member: ICampaignMember) => R;
/**
 * Factory to create different kinds of Allocation entities
 * @param {AllocationCreatorFunction<number>} allocationGetter Function to get the allocation
 * @returns {AllocationCreatorFunction<Allocation>} Returns the created function
 */
const allocationCreatorFactory =
	(allocationGetter: AllocationCreatorFunction<number>) =>
	(campaign: CampaignFields, member: ICampaignMember): Allocation => ({
		id: campaign.id,
		label: campaign.name,
		isEnabled: campaign.status === CampaignStatusEnum.Enabled,
		allocation: allocationGetter(campaign, member),
		campaignMemberId: member.id,
		color: campaign.primaryColour,
	});

// Function to create Allocation objects for prospecting
export const createProspectingAllocation = allocationCreatorFactory(
	(_, member) => member.dailyInvites ?? 0
);

// Function to create Allocation objects for nurture
export const createNurtureAllocation = allocationCreatorFactory(
	(_, member) => member.dailyMessages ?? 0
);

// Utility to convert Campaigns to Allocations
export const getAllocations = (
	campaigns: readonly ICampaign[],
	memberId: string,
	allocationCreator: AllocationCreatorFunction<Allocation>
): Allocation[] => {
	const allocations: Allocation[] = [];
	for (const campaign of campaigns) {
		const member = getCampaignMemberForOrgMember(campaign, memberId);
		if (!member) continue;
		allocations.push(allocationCreator(campaign, member));
	}
	return allocations;
};
// Get the first campaign member of a campaign for an orgmember
const getCampaignMemberForOrgMember = (campaign: ICampaign, orgMemberId: string) =>
	campaign.members.filter((m) => m.orgMemberId === orgMemberId).first();
