import { PureComponent } from 'react';
import { Moment } from 'moment';
import {
	CopilotDatePicker,
	CopilotDateType,
	CopilotRangePickerProps,
} from '@copilot/common/components/input/dates';

export interface DateRangeSelectorProps {
	datePickerProps?: CopilotRangePickerProps;
	onChange?: (startDate?: Date, endDate?: Date) => void;
	disabledDate?: (currentDate: Moment) => boolean;
}

class DateRangeSelector extends PureComponent<DateRangeSelectorProps> {
	constructor(props: DateRangeSelectorProps) {
		super(props);
	}

	handleDatePickerChange: CopilotRangePickerProps['onChange'] = (dates) => {
		if (!dates) {
			this.props.onChange?.(undefined, undefined);
		} else if (dates.length < 2) {
		} else {
			const startDate = dates[0] === undefined ? undefined : (dates[0] as Moment).toDate();
			const endDate = dates[1] === undefined ? undefined : (dates[1] as Moment).toDate();
			if (startDate) startDate.setHours(0, 0, 0, 0);
			if (endDate) endDate.setHours(23, 59, 59, 999);
			this.props.onChange?.(startDate, endDate);
		}
	};

	handleDisabledDate: CopilotRangePickerProps['disabledDate'] = (
		currentDate: CopilotDateType
	) => {
		if (!(this.props.disabledDate && currentDate)) {
			return false;
		}
		return this.props.disabledDate(currentDate);
	};

	render() {
		return (
			<>
				<CopilotDatePicker.RangePicker
					{...this.props.datePickerProps}
					onChange={this.handleDatePickerChange}
					disabledDate={this.handleDisabledDate}
				/>
			</>
		);
	}
}

export default DateRangeSelector;
