export namespace BitwiseUtilityFunctions {
	/**
	 * creates an array of int to represent possible bit flag combination with selected errors
	 * @param selected number that represent the selected bit fields
	 * @param numOfFields the total number of bit fields
	 */
	export const generateBitflagCombination = (selected: number, numOfFields: number) => {
		if (selected < 0 || numOfFields < 0) return [];
		const maxErrorCodeValue = 2 ** numOfFields;
		const combination: number[] = [];
		for (let errorCode = 0; errorCode < maxErrorCodeValue; errorCode++) {
			if (errorCode & selected) combination.push(errorCode);
		}
		return combination;
	};
}
