import { CampaignMember, ICampaignMember } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = CampaignMember.modelName;

export namespace CampaignMemberActions {
	export const loadCampaignMember = (
		member: Partial<ICampaignMember> | Partial<ICampaignMember>[]
	) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: member,
	});

	export const updateCampaignMember = (
		member: Partial<ICampaignMember> | Partial<ICampaignMember>[]
	) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: member,
	});
}
