import { ReactElement } from 'react';
import { Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import {
	IAccountManager,
	IUpdateAccountManagerByAccountIdInput,
	IUpdateAccountNameInput,
} from '@copilot/data/graphql/_generated';
import Section from '@copilot/common/components/drawer/wrappers/accountDashboard/section';
import { ACCOUNT_CREATION_INPUT_ERROR_MESSAGE, COMPANY_INFORMATION_TOOLTIP } from '../copy';
import { Account } from '@copilot/common/pages/accountDashboard/types';
import { formatName } from '@copilot/common/utils';
import isEmpty from 'lodash/isEmpty';

export type FormValues = IUpdateAccountNameInput & IUpdateAccountManagerByAccountIdInput;

/**
 * Props used for the account edit form
 */
type AccountEditFormProps = Readonly<{
	/** The form */
	form: FormInstance<FormValues>;
	/** The initial values */
	initialValues: Account;
	/** List of account mangers */
	accountManagers: Readonly<IAccountManager[]>;
	/** Callback when the form finishes */
	onFinish: (values: FormValues) => void;
}>;

/**
 * Component for the body of the account edit form
 * @param props
 */
export default function AccountEditForm({
	initialValues,
	accountManagers,
	form,
	onFinish,
}: AccountEditFormProps): ReactElement {
	return (
		<Form form={form} onFinish={onFinish}>
			<Form.Item hidden={true} name={'accountId'} initialValue={initialValues.id} />
			<Section
				title="Company Information"
				description={COMPANY_INFORMATION_TOOLTIP}
				body={
					<>
						<label htmlFor={'accountName'}>Account Name</label>
						<Form.Item
							required={true}
							initialValue={initialValues.name}
							name={'accountName'}
							style={{ marginBottom: 0 }}
							rules={[
								{
									required: true,
									whitespace: true,
									message: ACCOUNT_CREATION_INPUT_ERROR_MESSAGE,
								},
							]}
						>
							<Input />
						</Form.Item>
					</>
				}
			/>
			{isEmpty(accountManagers) ? undefined : (
				<Section
					title="Account Management"
					description="Assign an Account Manager to quickly filter and find accounts they're responsible for managing."
					body={
						<>
							<label htmlFor={'accountManagerUserId'}>
								Assigned Account Manager <span>(optional)</span>
							</label>
							<Form.Item
								initialValue={initialValues.accountManager?.id}
								name={'accountManagerUserId'}
							>
								<Select
									placeholder={'None'}
									style={{ width: '100%' }}
									options={[
										{
											value: null,
											label: 'None',
										},
										...accountManagers.map((a) => ({
											label: formatName(a.firstName, a.lastName),
											value: a.id,
										})),
									]}
								/>
							</Form.Item>
						</>
					}
				/>
			)}
		</Form>
	);
}
