// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pVRXrMnaxOeDEiIwgrBV .copilot-drawer-body {\n  display: flex;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/drawer/wrappers/contact/drawer/drawer.module.less"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,sBAAA;AAAF","sourcesContent":[".drawerBody {\n\t:global(.copilot-drawer-body) {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawerBody": "pVRXrMnaxOeDEiIwgrBV"
};
export default ___CSS_LOADER_EXPORT___;
