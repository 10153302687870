import {
	SEARCH_STATUSES,
	SearchStatus,
} from '@copilot/common/components/editors/searchUrl/update/consts';
import notificationManager from '@copilot/common/utils/notificationManager';

/**
 * Getter function for the common aspects of the search monitor config shared between nurture and prospecting lists.
 * @param fetchSearchList
 * @param onSearchListStatusChange
 * @returns
 */
export function getCommonSearchMonitorConfig(
	fetchSearchList: () => void,
	onSearchListStatusChange?: (status: SearchStatus) => void
) {
	return {
		onPollingStart: () => {
			onSearchListStatusChange?.(SEARCH_STATUSES.SEARCHING_CURRENT_CAMPAIGN);
		},
		onTaskComplete: () => {
			onSearchListStatusChange?.(SEARCH_STATUSES.SEARCH_COMPLETE);
			notificationManager.showSuccessNotification({
				message: 'Search complete',
				description:
					'Your search list has successfully loaded into your campaign. All set!',
			});
			fetchSearchList();
		},
		onTaskError: () => {
			onSearchListStatusChange?.(SEARCH_STATUSES.SEARCH_ERROR);
			notificationManager.showErrorNotification({
				message: 'Search error',
				description:
					'Your search was unable to complete. Please try again or contact support.',
			});
		},
	};
}
