// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JdNm3OCK9dc7eXGINGDj {\n  color: #52c41a;\n}\n.OEXPSzVZjAG1jnVgWERz {\n  color: #eb2f96;\n}\n.eWNqC2iOdzt6F_zuJABt {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.ySeSZoUfH6ajaBYc8Dr4 {\n  margin-left: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.ySeSZoUfH6ajaBYc8Dr4 .FAYNmUYbzKPX8huiNauj {\n  font-size: 14px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/utils/campaign/campaign.module.less"],"names":[],"mappings":"AAEA;EACC,cAAA;AADD;AAIA;EACC,cAAA;AAFD;AAKA;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;AAHD;AAMA;EACC,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAJD;AAAA;EAOE,eAAA;AAJF","sourcesContent":["@import '../../../styles/antd-variables.less';\n\n.successIcon {\n\tcolor: @success-color;\n}\n\n.errorIcon {\n\tcolor: @error-color;\n}\n\n.tabWrapper {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.indicator {\n\tmargin-left: 10px;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\n\t.spinner {\n\t\tfont-size: 14px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successIcon": "JdNm3OCK9dc7eXGINGDj",
	"errorIcon": "OEXPSzVZjAG1jnVgWERz",
	"tabWrapper": "eWNqC2iOdzt6F_zuJABt",
	"indicator": "ySeSZoUfH6ajaBYc8Dr4",
	"spinner": "FAYNmUYbzKPX8huiNauj"
};
export default ___CSS_LOADER_EXPORT___;
