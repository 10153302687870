import { Button, Dropdown, Popover, Typography, Menu } from 'antd';

import { MoreOutlined } from '@ant-design/icons';
import {
	CSCustomerBillingButtonDisabledReasons,
	useCSCustomerBillingTracking,
} from '../../pages/customerDetails/billing/tracking';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';
import { createStripeCustomerLink, createStripeSubscriptionLink } from './utils/stripe';

const { Text, Link } = Typography;

interface CurrentSubscriptionActionProps {
	/**
	 * The subscription id for the customer
	 */
	orgMemberId: string;
	/**
	 * The organization member id
	 */
	subscriptionId: string;
	/**
	 * The stripe customer id
	 */
	stripeCustomerId: string;
	/**
	 * If the user can change subscription for this organization member and the reason
	 */
	disabledChangeReason?: CSCustomerBillingButtonDisabledReasons;
}

/**
 * [Presentational] component for current subscription actions.
 *
 *  @param {string} subscriptionId The subscription id for the customer
 *  @param {string} orgMemberId The organization member id
 *  @param {string} stripeCustomerId The stripe customer id
 *  @param {CSCustomerBillingButtonDisabledReasons} disabledChangeReason  If the user can change subscription for this organization member and the reason
 */
const CurrentSubscriptionActions: React.FC<CurrentSubscriptionActionProps> = ({
	orgMemberId,
	subscriptionId,
	stripeCustomerId,
	disabledChangeReason,
}) => {
	const trackEvent = useCSCustomerBillingTracking('Current Subscription Actions');
	const isChangeSubscriptionEnabled = useFeatureToggle(Features.BillingChangeSubscriptionFeature);
	return (
		<div>
			{isChangeSubscriptionEnabled && (
				<Popover
					trigger={disabledChangeReason ? 'click' : undefined}
					onOpenChange={(clicked) => {
						if (clicked) {
							trackEvent({
								buttonClicked: 'Change Subscription',
								reason: disabledChangeReason,
							});
						}
					}}
					overlayStyle={{ maxWidth: 300 }}
					title={
						<Text strong>
							Please{' '}
							<Link
								target="_blank"
								onClick={() =>
									trackEvent({
										buttonClicked: 'View Stripe',
										reason: disabledChangeReason,
									})
								}
								href={createStripeSubscriptionLink(subscriptionId)}
							>
								visit Stripe
							</Link>{' '}
							to make updates to this subscription plan
						</Text>
					}
					content={
						<Text style={{ fontSize: 14 }}>
							<p>
								This customer has a condition that cannot currently be modified in
								the billing module.
							</p>
							<p>Conditions can or may include:</p>
							<ul>
								<li>Add-ons</li>
								<li>Unpaid invoice</li>
								<li>Upcoming update schedule</li>
								<li>Upcoming prorations</li>
								<li>Account cancelled</li>
								<li>Account cancelling</li>
								<li>Unsupported Subscription Status</li>
							</ul>
						</Text>
					}
				>
					<Button
						disabled={!!disabledChangeReason}
						href={`/details/${orgMemberId}/billing/edit`}
						onClick={() => trackEvent({ buttonClicked: 'Change Subscription' })}
					>
						Change Subscription
					</Button>
				</Popover>
			)}
			<Dropdown
				trigger={['click']}
				overlay={
					<Menu>
						<Menu.Item>
							<a
								target="_blank"
								href={createStripeCustomerLink(stripeCustomerId)}
								onClick={() =>
									trackEvent({ buttonClicked: "Go to Customer's Stripe" })
								}
							>
								Go to Customer's Stripe
							</a>
						</Menu.Item>
					</Menu>
				}
				arrow
				placement="bottomRight"
			>
				<Button
					shape="circle"
					type="text"
					icon={<MoreOutlined />}
					onClick={() => trackEvent({ buttonClicked: 'Expand Current Subscription' })}
				/>
			</Dropdown>
		</div>
	);
};

export default CurrentSubscriptionActions;
