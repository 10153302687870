import React, { useState, useMemo, useCallback } from 'react';
import BaseDrawer from '../base';
import { Button, Alert, Row, Spin } from 'antd';
import TagSelector from '@copilot/common/components/forms/common/generics/tagselector';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import drawerManager from '@copilot/common/utils/drawerManager';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useFetch } from '@copilot/common/hooks/common';
import ExpandableTagList from '@copilot/common/components/lists/tags/ExpandableTagList';
import { ITagsApplyDrawerProps } from './const';
import { getAllOrgTags } from '@copilot/common/pages/organizationDashboard/tags/data/selectors';

const StyledRow = styled(Row)`
	display: block;
	padding: 5px;
	width: 90%;
`;

/**
 * Drawer for batch applying tags
 * @param connections a list of connections to apply tags to
 * @param onApplyTag called on apply tag
 * @param totalCount total number of selected contacts
 * @param filterRequest selected filters to apply tags to everyone under
 */

const TagsApplyDrawer: React.FC<ITagsApplyDrawerProps> = (props) => {
	const {
		connections,
		totalCount = connections.length,
		filterRequest = {},
		onApplyTag,
		...rest
	} = props;
	const [selectedConnections, setSelectedConnections] =
		useState<{ id: string; name: string }[]>(connections);
	const orgTags = useSelector(getAllOrgTags);
	const [{ isFetching, error }, applyTag] = useFetch(onApplyTag);
	const [tags, setTags] = useState<string[]>([]);

	// true if "Tag All" is selected. We cannot unselect selected connections
	// in this case as we apply tags by filters applied
	const tagAllConnections = useMemo(() => Object.keys(filterRequest).length > 0, [filterRequest]);

	const unselectConnection = useCallback((connectionId: string) => {
		setSelectedConnections((selectedConns) =>
			selectedConns.filter((selectedConnection) => selectedConnection.id !== connectionId)
		);
	}, []);

	const applyTags = useCallback(async () => {
		const selectedConnectionIds = selectedConnections.map((c) => c.id);
		try {
			await applyTag(selectedConnectionIds, tags, false, filterRequest);
			drawerManager.closeDrawer();
			notificationManager.showSuccessNotification({
				message: 'Tags Added',
				description: 'Tags are successfully added to the prospects',
			});
		} catch (err) {
			if (!err)
				notificationManager.showErrorNotification({ message: 'Failed to Apply Tags' });
		}
	}, [selectedConnections, tags, filterRequest, applyTag]);

	return (
		<BaseDrawer {...rest}>
			<Spin spinning={isFetching}>
				<h2>Apply Tags</h2>
				<StyledRow>
					<ExpandableTagList
						title="Prospects: "
						tagInfo={selectedConnections}
						totalCount={totalCount}
						onClose={unselectConnection}
						isClosable={!tagAllConnections}
					/>
				</StyledRow>
				<StyledRow>
					<h4>Tags</h4>
					<TagSelector
						availableTags={orgTags.data.map((t) => t.name)}
						selectedTags={tags}
						onTagUpdate={setTags}
						placeholder="Enter tags"
					/>
				</StyledRow>
				<StyledRow>
					<Button
						type="primary"
						onClick={applyTags as () => void}
						disabled={!tags.length}
					>
						Apply Tags
					</Button>
					{error && (
						<Alert
							style={{ marginTop: '20px', width: '90%' }}
							message="Applying tags has failed. Please try again"
							type="error"
							banner
						/>
					)}
				</StyledRow>
			</Spin>
		</BaseDrawer>
	);
};

export default TagsApplyDrawer;
