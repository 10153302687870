import { Steps } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { fontSizeMD, fontWeightNormal } from '@copilot/common/constant/commonStyles';
import Theme from '@copilot/common/config/antd.theme.js';
import { PipelineProps } from './types';
import {
	BOOKED_STEP_TEST_ID,
	MESSAGED_STEP_TEST_ID,
	REPLIED_STEP_TEST_ID,
} from '../../../contactDrawerV2/infoPanelV2/constants';

const { Step } = Steps;
const doneIcon = <CheckCircleFilled style={{ color: Theme['@PolarGreen/5'] }} />;
const notDoneIcon = <CheckCircleFilled style={{ color: Theme['@Gray'] }} />;
const skippedIcon = <CloseCircleFilled style={{ color: Theme['@Red'] }} />;

const StyledDiv = styled.div`
	padding-right: 10px;

	.${(props) => props.theme['@ant-prefix']}-steps-label-vertical.${(props) =>
			props.theme['@ant-prefix']}-steps-small:not(.${(props) =>
			props.theme['@ant-prefix']}-steps-dot)
		.${(props) => props.theme['@ant-prefix']}-steps-item-icon {
		margin-left: 12px;
	}

	.${(props) => props.theme['@ant-prefix']}-steps-label-vertical {
		.${(props) => props.theme['@ant-prefix']}-steps-item-tail {
			margin-left: 20px;
			padding: 3.5px 12px;
		}

		.${(props) => props.theme['@ant-prefix']}-steps-item-content {
			width: 0px;
			margin-top: 0px;
		}
	}

	.${(props) => props.theme['@ant-prefix']}-steps-item-finish
		> .${(props) => props.theme['@ant-prefix']}-steps-item-container
		> .${(props) => props.theme['@ant-prefix']}-steps-item-tail::after {
		background-color: ${(props) => props.theme['@PolarGreen/5']};
	}

	.${(props) => props.theme['@ant-prefix']}-steps-small {
		.${(props) => props.theme['@ant-prefix']}-steps-item-title {
			font-size: 12px;
			line-height: 20px;
			font-weight: ${fontWeightNormal};
		}

		.${(props) => props.theme['@ant-prefix']}-steps-item-custom
			.${(props) => props.theme['@ant-prefix']}-steps-item-icon
			> .${(props) => props.theme['@ant-prefix']}-steps-icon {
			font-size: ${fontSizeMD};
		}
	}

	.${(props) => props.theme['@ant-prefix']}-steps-item-wait
		> .${(props) => props.theme['@ant-prefix']}-steps-item-container
		> .${(props) => props.theme['@ant-prefix']}-steps-item-content
		> .${(props) => props.theme['@ant-prefix']}-steps-item-title {
		color: inherit;
	}
`;

/**
 * calculates most recent icon that is true to use for status
 * @param pipelineBools
 * @returns index of current icon
 */
export const calculateCurrent = (pipelineBools: [boolean, boolean, boolean]) => {
	if (!(pipelineBools[0] && pipelineBools[1])) return 0;
	return !pipelineBools[2] ? 1 : 2;
};

/**
 * Calculate which icon each column should have
 * @param pipelineBools
 * @returns array of icons
 */
const correctIconCalculate = (pipelineBools: [boolean, boolean, boolean]) => {
	const correctIcon: any[] = [false, false, false];

	let laterFound = false;

	for (let i = pipelineBools.length - 1; i >= 0; i--) {
		if (pipelineBools[i]) {
			laterFound = true;
			correctIcon[i] = doneIcon;
		} else {
			if (laterFound) {
				correctIcon[i] = skippedIcon;
			} else {
				correctIcon[i] = notDoneIcon;
			}
		}
	}

	return correctIcon;
};

/**
 * Presentational component for contact info pipeline
 * @returns
 */
export function Pipeline({ hasBooked, hasMessaged, hasReplied }: PipelineProps) {
	const pipelineBools: [boolean, boolean, boolean] = [hasMessaged, hasReplied, hasBooked];
	const correctIcon = correctIconCalculate(pipelineBools);

	return (
		<StyledDiv>
			<Steps size="small" labelPlacement="vertical" current={calculateCurrent(pipelineBools)}>
				<Step title="Messaged" icon={correctIcon[0]} data-testid={MESSAGED_STEP_TEST_ID} />
				<Step title="Replied" icon={correctIcon[1]} data-testid={REPLIED_STEP_TEST_ID} />
				<Step title="Booked" icon={correctIcon[2]} data-testid={BOOKED_STEP_TEST_ID} />
			</Steps>
		</StyledDiv>
	);
}
