import { Reducer, ReducersMapObject } from 'redux';
import { CampaignSearchListTabActionTypes, CAMPAIGN_SEARCH_LIST_TAB } from './const';
import { CampaignSearchListTabObjectType, CampaignSearchListTabAction } from './actions';

/**
 * Type of the campaign id
 */
type CampaignId = string;

/**
 * Type of the Campaign Dashboard Search List Tab store object
 */
type CampaignSearchListTabType = Record<CampaignId, CampaignSearchListTabObjectType | undefined>;

/**
 * Base state for the CampaignSearchListTab
 */
const BASE_STATE: CampaignSearchListTabType = {};

/**
 * Reducer for the CampaignSearchListTab object
 * @param state current state of the redux store
 * @param action the action that is dispatched
 */
const reducer: Reducer<CampaignSearchListTabType, CampaignSearchListTabAction> | undefined = (
	state,
	action
) => {
	switch (action.type) {
		case CampaignSearchListTabActionTypes.UpdateOne:
			return {
				...state,
				[action.campaignId]: {
					...state?.[action.campaignId],
					...action.payload,
				},
			};
		case CampaignSearchListTabActionTypes.ResetOne: {
			const newState: CampaignSearchListTabType = {};
			Object.assign(newState, state);
			delete newState[action.campaignId];
			return newState;
		}
		case CampaignSearchListTabActionTypes.ResetAll:
			return BASE_STATE;
		default:
			return state ?? BASE_STATE;
	}
};

export type State = {
	[CAMPAIGN_SEARCH_LIST_TAB]: CampaignSearchListTabType;
};

const reducers: ReducersMapObject<State> = {
	[CAMPAIGN_SEARCH_LIST_TAB]: reducer,
};

export default reducers;
