import { MutationResult } from '@apollo/client';
import { ActivityActions } from '@copilot/common/store/actions/activity';
import { InboxActions } from '@copilot/common/store/actions/inbox';
import { DEFAULT_DATE_FORMAT } from '@copilot/common/utils/dateFormat';
import notificationManager from '@copilot/common/utils/notificationManager';
import { getMillisecondsFromNow } from '@copilot/common/utils/dateUtil';
import { InboxManager } from '@copilot/data';
import {
	IUpdateConnectionMeetingMutation,
	useUpdateConnectionMeetingMutation,
} from '@copilot/data/graphql/_generated';
import { MeetingDetails } from '@copilot/data/responses/interface';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { ProspectDrawerTrackingParams } from './tracking';

/**
 * Hook for managing meeting booked
 * @param isFeatureEnabled Whether the feature is enabled
 * @param connectionId The connection id we want to manage.
 * @returns Function to update a connection's meeting booked state along with the state
 */
export const useBookMeeting = (
	isFeatureEnabled: boolean,
	connectionId?: string,
	meetingBookedCallback?: (id: string, bookedResult: MeetingDetails) => void
): [(isBooked: boolean) => void, MutationResult<IUpdateConnectionMeetingMutation> | undefined] => {
	if (!isFeatureEnabled) return [() => {}, undefined];

	const [bookMeetingMutation, bookMeetingMutationResult] = useUpdateConnectionMeetingMutation();
	// [COPILOT-5790] Temporarily use meeting state until we migrate full data to be from campaign service
	const handleMeetingUpdate = async (isBooked: boolean) => {
		if (isUndefined(connectionId)) return;
		const result = await bookMeetingMutation({ variables: { connectionId, isBooked } });
		const bookResult: boolean | undefined = result.data?.updateConnectionMeeting.meeting.booked;
		if (isUndefined(bookResult)) return;
		if (!isUndefined(meetingBookedCallback)) {
			const bookedResponse = { booked: bookResult };
			meetingBookedCallback(connectionId, bookedResponse);
		}
	};
	return [handleMeetingUpdate, bookMeetingMutationResult];
};

/**
 * Hook for use reminders funcitonality
 * @param conversationId: the id of the converstaion
 * @param handleTracking: tracking callback for analytics
 * @returns functions to set and cancel reminders
 */
export const useReminders = (
	conversationId: string,
	handleTracking: (newParams: ProspectDrawerTrackingParams) => void
): {
	setReminderClick: () => void;
	setReminder: (date: moment.Moment, isNewReminder: boolean) => void;
	cancelReminder: () => void;
	loading: boolean;
} => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);

	const setReminderClick: () => void = () => {
		handleTracking({ buttonClicked: 'Set Reminder Clicked' });
	};

	const setReminder: (date: moment.Moment, isNewReminder: boolean) => void = (
		date: moment.Moment,
		isNewReminder: boolean
	) => {
		updateReminder(date);
		const interval = getMillisecondsFromNow(date);
		handleTracking({
			buttonClicked: isNewReminder ? 'Set Reminder' : 'Edit Reminder',
			interval,
		});
	};

	const cancelReminder: () => void = () => {
		updateReminder();
		handleTracking({ buttonClicked: 'Cancel Reminder' });
	};

	const updateReminder = (date?: moment.Moment) => {
		const remindOn = date?.toISOString();
		setLoading(true);
		InboxManager.snoozeThread(conversationId, remindOn)
			.then(() => {
				notificationManager.showSuccessNotification({
					message: date
						? `Reminder set for ${date.format(DEFAULT_DATE_FORMAT)}`
						: 'Reminder Cancelled',
					description: '',
				});
				batch(() => {
					dispatch(
						ActivityActions.updateConversation({
							id: conversationId,
							isSnoozed: !!date,
							snoozedDateTime: remindOn,
						})
					);
					dispatch(
						InboxActions.updateInboxMessage({
							id: conversationId,
							isSnoozed: !!date,
							isRead: !!date,
						})
					);
				});
			})
			.catch(() => {
				notificationManager.showErrorNotification({
					message: date ? 'Failed to set Reminder' : 'Failed to cancel Reminder',
					description: 'Please try again.',
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return { setReminderClick, setReminder, cancelReminder, loading };
};
