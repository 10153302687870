import { Button, Col, Input, Row } from 'antd';
import { ComponentProps } from 'react';
import styled from 'styled-components';

const FilterRow = styled(Row)`
	background: #ffffff;
	border-radius: 8px;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
	padding: 8px;
`;

type SearchBarProps = {
	onSearch: () => void;
	onReset: () => void;
};

const SearchBar = (props: SearchBarProps & Omit<ComponentProps<typeof Input>, 'onPressEnter'>) => {
	const { onSearch, onReset, ...rest } = props;
	return (
		<FilterRow gutter={16}>
			<Col>
				<Input {...rest} onPressEnter={onSearch} />
			</Col>
			<Col>
				<Button type="primary" onClick={onSearch}>
					Search
				</Button>
			</Col>
			<Col>
				<Button onClick={onReset}>Reset</Button>
			</Col>
		</FilterRow>
	);
};

export default SearchBar;
