import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';
import HeaderContentLayout from '../layouts/headerContent';
import CampaignDashboardTeamMembers from './teamMembers';
import CampaignDashboardSequence from './sequence';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import BasicContainer from '@copilot/common/components/containers/basic';
import TemplateMessages from '../settings/template';
import ScheduleSetting from '../settings/schedule';
import CampaignDashboardSettings from './settings';
import { useHistory } from 'react-router';
import { useSearchParams } from '@copilot/common/hooks/common';
import {
	useCanEditPermission,
	useCanViewPermission,
	usePermission,
	validateIsVisible,
} from '@copilot/common/hooks/permission';
import {
	CampaignDashboardTabKeys,
	CampaignDashboardTabLabel,
} from '@copilot/common/utils/campaign/dashboardTabs';
import ProspectingList from './searchList/campaignDashboardSearchList/prospectingList';
import NurtureList from './nurtureList/nurtureList';
import { CampaignType, Features } from '@copilot/data/responses/interface';
import { ScheduleHierarchyType } from '../settings/schedule/constant';
import { CampaignFields } from '@copilot/common/store/models/redux';
import { CampaignStatusEnum } from '@copilot/data/requests/models';
import { PermissionName } from '@copilot/common/hooks/permission/interface';
import { LoadAttentionItemsAction } from '@copilot/common/utils/attentionItem/saga';
import { AttentionItemManager } from '@copilot/data';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { selectCampaignMembers } from '@copilot/common/utils/campaignMember/selector';
import { useCampaignNodeActions } from './helpers';
import CampaignDashboardSummaryV2 from './summaryV2';
import _, { isEmpty } from 'lodash';
import InMailSequenceTab from './sequence/inmail/inmailSequenceTab';
import { useInMailSequence } from './sequence/data/inmail';
import {
	SEARCH_STATUSES,
	SearchStatus,
} from '@copilot/common/components/editors/searchUrl/update/consts';

/**
 * props passed down from campaign dashboard
 * @param {CampaignFields} campaign target campaign to render
 * @param {string} organizationId  Id of the organization we want members for
 * @param {string} organiaztionMemberId The org member's id
 * @param {boolean} isTeamUser whether the user is a team user
 * @param {Record<CampaignDashboardTabKeys, boolean>} tabVisibility describes which tabs are visible
 * @param {string} teamMembersRedirectMemberId id of the campaign member upon redirect to the team members tab, if redirect occurred
 * @param {string} searchListRedirectMemberId id of the campaign member upon redirect to the search list / nurture list tab, if redirect occurred
 * @param {function} clearRedirectedMemberIds clears the redirected member ids
 */
interface CampaignDashboardContentProps {
	campaign: CampaignFields;
	organizationId?: string;
	organiaztionMemberId?: string;
	isTeamUser?: boolean;
	tabVisibility: Record<CampaignDashboardTabKeys, boolean>;
	teamMembersRedirectMemberId?: string;
	searchListRedirectMemberId?: string;
	clearRedirectedMemberIds?: () => void;
}

interface CampaignDashboardSearchParams {
	tab: string;
}

/**
 * Component for campaign dashboard
 *
 * @param {ICampaign} campaign target campaign to render
 * @param {boolean} isTeamUser whether the user is a team user
 * @param {Record<CampaignDashboardTabKeys, boolean>} tabVisibility describes which tabs are visible
 * @param {string} teamMembersRedirectMemberId id of the campaign member upon redirect to the team members tab, if redirect occurred
 * @param {string} searchListRedirectMemberId id of the campaign member upon redirect to the search list / nurture list tab, if redirect occurred
 * @param {function} clearRedirectedMemberIds clears the redirected member ids
 */
function CampaignDashboardContent(props: CampaignDashboardContentProps) {
	const {
		campaign,
		isTeamUser = true,
		tabVisibility,
		teamMembersRedirectMemberId,
		searchListRedirectMemberId,
		clearRedirectedMemberIds,
	} = props;
	const dispatch = useDispatch();
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const isTeamMembersVisible = useCanViewPermission(PermissionName.TeamMembers);
	const isMessagingEditable = useCanEditPermission(PermissionName.Messaging);
	const isSendingHoursEditable = useCanEditPermission(PermissionName.SendingHours);

	const [searchListStatus, setSearchListStatus] = useState<SearchStatus>(
		SEARCH_STATUSES.NOT_SEARCHING
	);
	const isTeamsAttentionRequiredFeatureEnabled = useFeatureToggle(
		Features.TeamsAttentionRequiredFeature
	);

	const [searchParams, setSearchParams] = useSearchParams<CampaignDashboardSearchParams>();
	const [tab, setTab] = useState<string>('');
	const showEditCampaignWarning = useCallback((state: boolean) => {
		const sequenceNotificationKey = 'sequenceNotificationKey';
		state
			? notificationManager.showWarnNotification({
					duration: null,
					message: 'Please disable campaign before editing.',
					key: sequenceNotificationKey,
			  })
			: notification.destroy(sequenceNotificationKey);
	}, []);

	useEffect(() => {
		setTab(searchParams.tab ?? CampaignDashboardTabKeys.Summary);
	}, [searchParams]);

	useEffect(() => () => showEditCampaignWarning(false), []);

	const handleTabChange = useCallback(
		(key: string) => {
			setSearchParams({ tab: key });
			clearRedirectedMemberIds?.();
		},
		[setSearchParams, clearRedirectedMemberIds]
	);

	const isCampaignEnabled = useMemo(
		() => campaign.status === CampaignStatusEnum.Enabled,
		[campaign]
	);

	const history = useHistory();
	const deleteCampaignCallback = useCallback(() => {
		history.replace('/');
	}, [history]);

	useEffect(() => {
		showEditCampaignWarning(
			isCampaignEnabled &&
				((tab === CampaignDashboardTabKeys.Sequence && isMessagingEditable) ||
					(tab === CampaignDashboardTabKeys.Schedule && isSendingHoursEditable))
		);
	}, [
		showEditCampaignWarning,
		isCampaignEnabled,
		tab,
		isMessagingEditable,
		isSendingHoursEditable,
	]);

	const fetchAttentionItems = useCallback(() => {
		if (isTeamsAttentionRequiredFeatureEnabled && isTeamUser && !!activeMember) {
			dispatch(
				LoadAttentionItemsAction(
					AttentionItemManager.getOrgAttentionItems,
					activeMember.organizationId,
					isTeamMembersVisible ? null : activeMember.id
				)
			);
		}
	}, [
		isTeamsAttentionRequiredFeatureEnabled,
		isTeamUser,
		isTeamMembersVisible,
		activeMember?.organizationId,
		activeMember?.id,
	]);

	useEffect(() => {
		// fetch attention items when switching to the summary tab
		if (tab === CampaignDashboardTabKeys.Summary) fetchAttentionItems();
	}, [tab, fetchAttentionItems]);

	// Generate search list error
	const campaignMembers = useSelector(selectCampaignMembers(campaign.id));
	const hasNoSearchLists = useMemo(
		() =>
			campaign.type === CampaignType.Prospecting &&
			!campaignMembers.loading &&
			!campaignMembers.data.find((m) => !!m.searchUrl),
		[campaign.type, campaignMembers.loading, campaignMembers.data]
	);
	// Any members with open inmail enabled
	const hasInMailEnabledMember =
		campaignMembers.data?.some((member) => member.openInMailEnabled) === true;
	// Generate campaign sequence error
	const campaignNodeActions = useCampaignNodeActions(campaign.id, campaign.type);
	const hasNoCampaignSequence = useMemo(
		() =>
			campaign.type === CampaignType.Prospecting &&
			!campaignNodeActions.isLoading &&
			!campaignNodeActions.messages.find((m) => m.text),
		[campaign.type, campaignNodeActions.isLoading, campaignNodeActions.messages]
	);

	const inMailSequenceHookData = useInMailSequence();
	const {
		data: openInMailSequence,
		fetchState: { isFetching: isLoadingOpenInMailSequence },
		fetchSequence: fetchOpenInMailSequence,
	} = inMailSequenceHookData;

	useEffect(() => {
		fetchOpenInMailSequenceData();
	}, [campaign.id]);

	async function fetchOpenInMailSequenceData() {
		await fetchOpenInMailSequence(campaign.id);
	}

	const isMissingInMailSequence =
		campaign.type === CampaignType.Prospecting &&
		!isLoadingOpenInMailSequence &&
		isEmpty(openInMailSequence);

	if (!activeMember) return null;
	return (
		<HeaderContentLayout.Tabs activeKey={tab} onChange={handleTabChange}>
			<HeaderContentLayout.TabPanes
				key={CampaignDashboardTabKeys.Summary}
				tab={<CampaignDashboardTabLabel tabKey={CampaignDashboardTabKeys.Summary} />}
			>
				<CampaignDashboardSummaryV2
					id={campaign.id}
					members={campaignMembers}
					activeMember={activeMember}
				/>
			</HeaderContentLayout.TabPanes>
			{tabVisibility[CampaignDashboardTabKeys.TeamMembers] && (
				<HeaderContentLayout.TabPanes
					key={CampaignDashboardTabKeys.TeamMembers}
					tab={
						<CampaignDashboardTabLabel tabKey={CampaignDashboardTabKeys.TeamMembers} />
					}
				>
					<CampaignDashboardTeamMembers
						campaign={campaign}
						members={campaignMembers}
						activeMemberId={activeMember.id}
						initialCampaignMemberId={teamMembersRedirectMemberId}
						onInitialDrawerOpened={clearRedirectedMemberIds}
					/>
				</HeaderContentLayout.TabPanes>
			)}
			{tabVisibility[CampaignDashboardTabKeys.SearchList] && (
				<HeaderContentLayout.TabPanes
					key={CampaignDashboardTabKeys.SearchList}
					tab={
						<CampaignDashboardTabLabel
							tabKey={CampaignDashboardTabKeys.SearchList}
							usePlural={isTeamUser}
							isLoading={
								searchListStatus === SEARCH_STATUSES.SEARCHING_CURRENT_CAMPAIGN
							}
							hasError={
								(isTeamUser && hasNoSearchLists) ||
								searchListStatus === SEARCH_STATUSES.SEARCH_ERROR
							}
							hasSuccess={searchListStatus === SEARCH_STATUSES.SEARCH_COMPLETE}
						/>
					}
				>
					<ProspectingList
						onSetupInMailSequence={() =>
							setSearchParams({ tab: CampaignDashboardTabKeys.InMailSequence })
						}
						campaignId={campaign.id}
						isTeamUser={isTeamUser}
						onSearchListStatusChange={setSearchListStatus}
						initialSelectedMemberId={searchListRedirectMemberId}
						isMissingInMailSequence={isMissingInMailSequence}
					/>
				</HeaderContentLayout.TabPanes>
			)}
			{tabVisibility[CampaignDashboardTabKeys.NurtureList] && (
				<HeaderContentLayout.TabPanes
					key={CampaignDashboardTabKeys.NurtureList}
					tab={
						<CampaignDashboardTabLabel
							tabKey={CampaignDashboardTabKeys.NurtureList}
							usePlural={isTeamUser}
							isLoading={
								searchListStatus === SEARCH_STATUSES.SEARCHING_CURRENT_CAMPAIGN
							}
							hasError={
								(isTeamUser && hasNoSearchLists) ||
								searchListStatus === SEARCH_STATUSES.SEARCH_ERROR
							}
							hasSuccess={searchListStatus === SEARCH_STATUSES.SEARCH_COMPLETE}
						/>
					}
				>
					<NurtureList
						campaignId={campaign.id}
						isTeamUser={isTeamUser}
						onSearchListStatusChange={setSearchListStatus}
						initialSelectedMemberId={searchListRedirectMemberId}
						isMissingInMailSequence={isMissingInMailSequence}
					/>
				</HeaderContentLayout.TabPanes>
			)}
			{tabVisibility[CampaignDashboardTabKeys.Sequence] && (
				<HeaderContentLayout.TabPanes
					key={CampaignDashboardTabKeys.Sequence}
					tab={
						<CampaignDashboardTabLabel
							tabKey={CampaignDashboardTabKeys.Sequence}
							hasError={isTeamUser && hasNoCampaignSequence}
						/>
					}
				>
					<CampaignDashboardSequence
						campaign={campaign}
						isCampaignEnable={isCampaignEnabled}
						onSaveCallback={campaignNodeActions.resync}
						updateMessages={campaignNodeActions.setMessages}
						campaignMessages={campaignNodeActions.messages}
					/>
				</HeaderContentLayout.TabPanes>
			)}
			{tabVisibility[CampaignDashboardTabKeys.InMailSequence] && (
				<HeaderContentLayout.TabPanes
					key={CampaignDashboardTabKeys.InMailSequence}
					tab={
						<CampaignDashboardTabLabel
							tabKey={CampaignDashboardTabKeys.InMailSequence}
							hasError={isTeamUser && isMissingInMailSequence}
						/>
					}
				>
					<InMailSequenceTab
						campaignId={campaign.id}
						showEnableAlert={!hasInMailEnabledMember}
						readonly={!isCampaignEnabled}
						inMailSequenceHookData={inMailSequenceHookData}
					/>
				</HeaderContentLayout.TabPanes>
			)}
			<HeaderContentLayout.TabPanes
				key={CampaignDashboardTabKeys.Template}
				tab={<CampaignDashboardTabLabel tabKey={CampaignDashboardTabKeys.Template} />}
			>
				<BasicContainer>
					<BasicContainer.Header>Quick Responses</BasicContainer.Header>
					<BasicContainer.Content>
						<TemplateMessages
							user={activeMember}
							campaignId={campaign.id}
							showCampaigns
							hideHeader
						/>
					</BasicContainer.Content>
				</BasicContainer>
			</HeaderContentLayout.TabPanes>
			<HeaderContentLayout.TabPanes
				key={CampaignDashboardTabKeys.Schedule}
				tab={<CampaignDashboardTabLabel tabKey={CampaignDashboardTabKeys.Schedule} />}
			>
				<BasicContainer>
					<BasicContainer.Content>
						<ScheduleSetting
							ownerId={campaign.id}
							ownerType={ScheduleHierarchyType.Campaign}
							orgId={activeMember.organizationId}
							isEditable={!isCampaignEnabled && isSendingHoursEditable}
						/>
					</BasicContainer.Content>
				</BasicContainer>
			</HeaderContentLayout.TabPanes>
			{tabVisibility[CampaignDashboardTabKeys.Settings] && (
				<HeaderContentLayout.TabPanes
					key={CampaignDashboardTabKeys.Settings}
					tab={<CampaignDashboardTabLabel tabKey={CampaignDashboardTabKeys.Settings} />}
				>
					<CampaignDashboardSettings
						campaign={campaign}
						deleteCampaignCallback={deleteCampaignCallback}
					/>
				</HeaderContentLayout.TabPanes>
			)}
		</HeaderContentLayout.Tabs>
	);
}

const withTabVisibility =
	<T extends CampaignDashboardContentProps>(Component: React.FC<T>) =>
	(props: Omit<T, 'tabVisibility'>) => {
		const { campaign, isTeamUser } = props;
		const [teamMembersPermission] = usePermission(PermissionName.TeamMembers);
		const [ownTeamMembersPermission] = usePermission(PermissionName.OwnTeamMembers);
		const isMessagingVisible = useCanViewPermission(PermissionName.Messaging);
		const isSettingsVisible = useCanViewPermission(PermissionName.Settings);
		const isInMailSequenceVisible =
			isMessagingVisible && campaign.type !== CampaignType.Nurture;

		const tabVisibility: Record<CampaignDashboardTabKeys, boolean> = useMemo(
			() => ({
				[CampaignDashboardTabKeys.Summary]: true,
				[CampaignDashboardTabKeys.TeamMembers]:
					!!isTeamUser &&
					validateIsVisible(teamMembersPermission | ownTeamMembersPermission),
				[CampaignDashboardTabKeys.SearchList]: campaign.type === CampaignType.Prospecting,
				[CampaignDashboardTabKeys.NurtureList]: campaign.type === CampaignType.Nurture,
				[CampaignDashboardTabKeys.Sequence]: isMessagingVisible,
				[CampaignDashboardTabKeys.InMailSequence]: isInMailSequenceVisible,
				[CampaignDashboardTabKeys.Template]: true,
				[CampaignDashboardTabKeys.Schedule]: true,
				[CampaignDashboardTabKeys.Settings]: isSettingsVisible,
			}),
			[
				campaign.type,
				isTeamUser,
				teamMembersPermission,
				ownTeamMembersPermission,
				isMessagingVisible,
				isSettingsVisible,
				isInMailSequenceVisible,
			]
		);

		return <Component {...(props as T)} tabVisibility={tabVisibility} />;
	};

export default withTabVisibility(CampaignDashboardContent);
