import { Col, Row, InputNumber, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { NodeTimerPeriodEnum } from '@copilot/common/store/models/const/enum';
import { isNil } from 'lodash';

export const DEFAULT_DAYS = 14;

interface InvitesEditorTimingProps {
	period: NodeTimerPeriodEnum;
	time: number;
	onPeriodUpdate: (value: number) => void;
	onTimeUpdate: (value: number) => void;
	disabled?: boolean;
}

function InvitesEditorTiming({
	period,
	time,
	onPeriodUpdate,
	onTimeUpdate,
	disabled,
}: InvitesEditorTimingProps) {
	function handleTimeUpdate(value: number | null) {
		if (!isNil(value)) onTimeUpdate?.(value);
	}
	function handlePeriodUpdate(event: RadioChangeEvent) {
		const value: number = event?.target?.value;
		if (value !== undefined) {
			onPeriodUpdate?.(value);
		}
	}

	return (
		<Row justify="start" gutter={16}>
			<Col>
				<InputNumber
					disabled={disabled}
					name="time"
					min={0}
					value={time}
					onChange={handleTimeUpdate}
				/>
			</Col>
			<Col>
				<Radio.Group
					disabled={disabled}
					name="period"
					value={period}
					onChange={handlePeriodUpdate}
					buttonStyle="solid"
				>
					<Radio.Button value={NodeTimerPeriodEnum.Days}>Days</Radio.Button>
					<Radio.Button value={NodeTimerPeriodEnum.Weeks}>Weeks</Radio.Button>
				</Radio.Group>
			</Col>
		</Row>
	);
}

export default InvitesEditorTiming;
