import React from 'react';
import { QueryObject } from '@copilot/data/managers/base';
import { useFetch } from '@copilot/common/hooks/common';
import { StatsResponse } from '@copilot/data/responses/interface';
import { SummaryStatsAdapter } from '@copilot/common/adapter/statistic';

interface StatsLoaderProps {
	id: string;
	defaultStartDate?: Date;
	defaultEndDate?: Date;
	filterCallback?: (filters: QueryObject) => void;
}

interface BaseComponentProps {
	stats: any;
	statsLoader: any;
	isLoading: any;
}

// const StatsLoader = <
//    P extends StatsLoaderProps & Omit<CP, 'stats' | 'statsLoader' | 'isLoading'>
// >
const StatsLoader =
	<CP extends StatsLoaderProps & Omit<P, keyof BaseComponentProps>, P>(
		Component: React.ComponentType<P>,
		statsLoader: (
			id: string,
			startDate?: Date,
			endDate?: Date
		) => Promise<{ [k: string]: StatsResponse }>
	): React.FC<CP> =>
	(props) => {
		const { ...rest } = props;
		const [statsFetch, fetchStats] = useFetch(statsLoader);
		const [stats, setStats] = React.useState<SummaryStatsAdapter>();
		const loadStats = (startDate?: Date, endDate?: Date) => {
			fetchStats(props.id, startDate, endDate).then((s) => {
				setStats(new SummaryStatsAdapter(s));
			});
		};
		React.useEffect(() => {
			const defaultStartDate =
				props.defaultStartDate ??
				(() => {
					const startDate = new Date();
					startDate.setHours(0, 0, 0, 0);
					startDate.setDate(startDate.getDate() - 7);
					return startDate;
				})();
			if (props.id) loadStats(defaultStartDate, props.defaultEndDate);
		}, [props.id]);
		// Had to do this since P constraint could be different subtype of {}
		const TComponent = Component as unknown as React.ComponentType<CP>;
		return (
			<TComponent
				stats={stats}
				statsLoader={loadStats}
				isLoading={statsFetch.isFetching}
				{...rest}
			/>
		);
	};

StatsLoader.defaultProps = {
	defaultStartDate: (() => {
		const startDate = new Date();
		startDate.setHours(0, 0, 0, 0);
		startDate.setDate(startDate.getDate() - 7);
		return startDate;
	})(),
};

export default StatsLoader;
