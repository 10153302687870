// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dKKyY2CVOkq4ovPxGB_D {\n  flex-grow: 1;\n  height: 100%;\n  overflow: hidden;\n}\n.dKKyY2CVOkq4ovPxGB_D .copilot-tabs-content-holder {\n  display: flex;\n}\n.dKKyY2CVOkq4ovPxGB_D .copilot-tabs-content {\n  align-self: stretch;\n}\n.dKKyY2CVOkq4ovPxGB_D .copilot-tabs-tabpane {\n  height: 100%;\n}\n.dKKyY2CVOkq4ovPxGB_D .copilot-tabs-nav-wrap {\n  padding: 0 16px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/drawer/wrappers/contact/activity/contactActivity.module.less"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,YAAA;EACA,gBAAA;AACD;AAJA;EAKE,aAAA;AAEF;AAPA;EAQE,mBAAA;AAEF;AAVA;EAWE,YAAA;AAEF;AAbA;EAcE,eAAA;AAEF","sourcesContent":[".activityTabs {\n\tflex-grow: 1;\n\theight: 100%;\n\toverflow: hidden;\n\t:global(.copilot-tabs-content-holder) {\n\t\tdisplay: flex;\n\t}\n\t:global(.copilot-tabs-content) {\n\t\talign-self: stretch;\n\t}\n\t:global(.copilot-tabs-tabpane) {\n\t\theight: 100%;\n\t}\n\t:global(.copilot-tabs-nav-wrap) {\n\t\tpadding: 0 16px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activityTabs": "dKKyY2CVOkq4ovPxGB_D"
};
export default ___CSS_LOADER_EXPORT___;
