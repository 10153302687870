import { attr } from 'redux-orm';
import { TagLevel } from '../const/enum';
import { BaseModel } from './base';
import { IContactTag } from './contactTag';

export interface ITag {
	description: string;
	id: string;
	level: TagLevel;
	name: string;
}

export class Tag extends BaseModel<typeof Tag, ITag> {
	static modelName = 'Tag';
	static fields = {
		description: attr(),
		id: attr(),
		level: attr(),
		name: attr(),
	};

	/**
	 * Converts IContactTag object to Tag
	 * @param tag IContactTag
	 * @param tagLevel Tag level, defaults to organization level
	 */
	public static convertFromIContactTags(tag: Partial<IContactTag>, tagLevel?: TagLevel) {
		return {
			id: tag.tagId ?? '',
			name: tag.name ?? '',
			description: this.name ?? '',
			level: tagLevel ?? TagLevel.Organization,
		} as ITag;
	}
}
