import { EntitiesActionTypes } from './types';
import { InstanceData } from '../models/redux';
import { IInstanceData } from '@copilot/data/responses/models/instances';

const itemType = InstanceData.modelName;
export namespace InstanceDataActions {
	export const loadInstances = (instances: IInstanceData[]) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: instances,
	});
}
