/**
 * Whether certain types of filters are visible
 * @param {boolean} campaigns whether the campaigns filter is visible
 * @param {boolean} teamMembers whether the team members filter is visible
 */
export type FilterVisibilityType = {
	campaigns: boolean;
	teamMembers: boolean;
};

/**
 * Inbox Filters when the Sentiment Tabs are visible
 */
export const FiltersForSentimentTabs: FilterVisibilityType = {
	campaigns: true,
	teamMembers: false,
};

/**
 * Inbox Filters when the Campaign Tabs are visible
 */
export const FiltersForCampaignTabs: FilterVisibilityType = {
	campaigns: false,
	teamMembers: true,
};

/**
 * Inbox Filters when the Reminder Tab is visible
 */
export const FiltersForReminderTab: FilterVisibilityType = {
	campaigns: true,
	teamMembers: true,
};
