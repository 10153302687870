// Reducer to manage table context
import { Reducer } from 'redux';
import {
	INITIAL_CONTEXT_STATE,
	TableContextState,
	UpdateColumnContextListAction,
	UpdateHiddenColumnsAction,
	UpdateSelectedRowsAction,
} from './types';

export const tableReducer: Reducer<
	TableContextState,
	UpdateColumnContextListAction | UpdateSelectedRowsAction | UpdateHiddenColumnsAction
> = (state, action) => {
	state = state ?? INITIAL_CONTEXT_STATE;

	switch (action.type) {
		case 'update_column_context_list':
			return { ...state, columnContextList: [...action.payload] };
		case 'update_selected_rows':
			return { ...state, selectedRows: [...action.payload] };
		case 'update_hidden_columns':
			return {
				...state,
				hiddenColumnMap: action.payload.reduce<typeof state.hiddenColumnMap>(
					(acc, column) => {
						acc[column.columnKey] = !column.isVisible;
						return acc;
					},
					{}
				),
			};
		default:
			return state;
	}
};
