/**
 * Returns the pluralized ending ('s') depending on length has multiple entries
 * @param length number of items
 */
export const getPluralizedEnding = (length: number): 's' | '' => (length !== 1 ? 's' : '');

/**
 * Returns a pluralized word with ('s') depending on length length
 * @param word the word to be pluralized
 * @param length number of items
 */
export const pluralString = (word: string, length: number): string =>
	`${word}${getPluralizedEnding(length)}`;
