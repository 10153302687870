import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';

export const useActiveUser = () => {};

/**
 * Check whether a user has the org roles required
 * @param allowedRoles Roles allowed
 */
export const useIsPermitted = (allowedRoles: (string | undefined)[]) => {
	const user = useSelector(OrganizationMemberSelectors.getActiveMember);
	const admin = useSelector(OrganizationMemberSelectors.getAdminMember);

	if (admin) return true;
	else if (!user || !user.orgRoles || !user.userRoles) return allowedRoles.includes(undefined);
	else
		return (
			user.orgRoles.filter((r) => allowedRoles.includes(r)).length > 0 ||
			user.userRoles.filter((r) => allowedRoles.includes(r)).length > 0
		);
};
