import Store from '..';

import { IInboxMessage, InboxMessage } from '../models/redux';
import { createSelector, OrmState, SessionBoundModel } from 'redux-orm';

export namespace InboxMessageSelectors {
	export const getInboxMessages = createSelector(
		Store.ORM,
		(session): IInboxMessage[] => session.InboxMessage.all().toRefArray() as IInboxMessage[]
	);

	export const getInboxMessagesByCategory = (
		state: { entities: OrmState<any> },
		category: string
	) => {
		const a = Store.ORM.session(state.entities)
			.InboxMessage.all()
			.toRefArray()
			.map((m) => m as IInboxMessage)
			.filter((m) => m.polarity == category);
		return a;
	};

	export const getInboxMessagesByCampaignId = (
		state: { entities: OrmState<any> },
		id: string
	) => {
		const a = Store.ORM.session(state.entities)
			.InboxMessage.all()
			.toRefArray()
			.map((m) => m as IInboxMessage)
			.filter((m) => m.campaignId == id);
		return a;
	};

	export const getInboxMessagesByThreadIds =
		(ids: string[]) => (state: { entities: OrmState<any> }) => {
			const inboxMessages = Store.ORM.session(state.entities).InboxMessage;
			return ids
				.map(
					(id) =>
						inboxMessages.withId(id) as NonNullable<SessionBoundModel<InboxMessage, {}>>
				)
				.filter((c) => c !== null);
		};
}
