import React, { useContext } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { ThemeContext } from 'styled-components';

export interface PasswordUpdateFormValues {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
}

interface PasswordUpdateFormProps {
	onSubmit: (values: PasswordUpdateFormValues) => Promise<any> | void;
}

const validatePassword = (rule: any, value: string, callback: any) => {
	if (!value) callback('Please enter a password.');
	else if (value.length < 6) callback('Password must be at least 6 characters.');
	else if (!/[a-z]/.test(value))
		callback("Passwords must have at least one lowercase ('a'-'z').");
	else if (!/[A-Z]/.test(value))
		callback("Passwords must have at least one uppercase ('A'-'Z').");
	else if (!/[0-9]/.test(value)) callback("Password must have at least one digit ('0'-'9').");
	else if (!/[!@#$%^&*().]/.test(value))
		callback("Passwords must have at least one non alphanumeric character ('!@#$%^&*()').");
	else callback();
};

const PasswordUpdateForm: React.FC<PasswordUpdateFormProps> = (props) => {
	const { onSubmit } = props;
	const [form] = Form.useForm();
	const compareConfirmationPassword = React.useCallback(
		(rule: any, value: any, callback: any) => {
			if (value !== form.getFieldValue('newPassword')) callback('Your passwords must match.');
			else callback();
		},
		[]
	);
	const handleFinish = React.useCallback(
		(values: PasswordUpdateFormValues) => {
			onSubmit?.(values);
		},
		[onSubmit]
	);

	const themeContext = useContext(ThemeContext);
	return (
		<Form form={form} onFinish={handleFinish} scrollToFirstError>
			<Form.Item
				name="oldPassword"
				label="Old Password"
				style={{
					fontWeight: themeContext['@password-text-weight'] as any,
					display: 'block',
				}}
				rules={[{ required: true, validator: validatePassword }]}
			>
				<Input type="password" />
			</Form.Item>
			<Form.Item
				name="newPassword"
				label="New Password"
				style={{
					fontWeight: themeContext['@password-text-weight'] as any,
					display: 'block',
				}}
				rules={[{ required: true, validator: validatePassword }]}
			>
				<Input type="password" />
			</Form.Item>
			<Form.Item
				name="confirmPassword"
				label="Confirm Password"
				style={{
					fontWeight: themeContext['@password-text-weight'] as any,
					display: 'block',
				}}
				rules={[{ required: true, validator: compareConfirmationPassword }]}
			>
				<Input type="password" />
			</Form.Item>
			<Form.Item style={{ fontWeight: 'bold', display: 'block' }}>
				<Row justify="end">
					<Col>
						<Button type="primary" htmlType="submit">
							Save
						</Button>
					</Col>
				</Row>
			</Form.Item>
		</Form>
	);
};

export default PasswordUpdateForm;
