import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';
import { StatsTypeName } from '@copilot/common/constant/campaignConstant';
import { toRate } from '@copilot/common/utils';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { UnitOfTime } from '@copilot/common/utils/time';
import {
	ICampaignMemberEventsAndStatsQuery,
	ICampaignUsageAction,
	INurtureCampaignStats,
	IProspectingCampaignStats,
	ITimeInterval,
	useCampaignMemberEventsAndStatsQuery,
} from '@copilot/data/graphql/_generated';
import _, { first, isNil, isNumber } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { isNurtureCampaignStats, isProspectingCampaignStats } from '../helpers';
import PerformanceChart, { PerformanceChartPropsType } from './performanceChart';
import { Timestamp } from './performanceChart/constant';
import { EventNames, EventNamesType } from './performanceChart/event';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';
import { Card } from 'antd';

const ChartModelProperties = {
	connectionRate: 'connectionRate',
	replyRate: 'replyRate',
} as const;

/**
 * Transform data from dto to model
 */
const toChartModel = (
	element: ArrayElement<ICampaignMemberEventsAndStatsQuery['campaignStats']>
) => {
	if (!isProspectingCampaignStats(element) && !isNurtureCampaignStats(element))
		throw new Error('Element is not ProspectingCampaignStats nor NurtureCampaignStats');

	if (!isNumber(element.start))
		throw new Error('Stats does not have a valid timestamp associate with it');

	return {
		// Data from Dec 5 (utc) - Dec 11 (utc) are plotted at Dec 12 (local time) on the chart
		// Therefore the chart's timestamp is 1 week after the start of the DTO start time
		[Timestamp]: moment.utc(element.start).add(1, UnitOfTime.week).local(true).valueOf(),
		[ChartModelProperties.connectionRate]:
			isProspectingCampaignStats(element) && !isNil(element.percentConnected)
				? toRate(element.percentConnected)
				: 0,
		[ChartModelProperties.replyRate]: !isNil(element.percentReplied)
			? toRate(element.percentReplied)
			: 0,
	};
};

/**
 * Get event type from an usage action
 * @param action The action we want the event for
 * @returns The event type to be consumed by performance chart
 */
const getEventType = (action: ICampaignUsageAction): EventNamesType => {
	switch (action) {
		case ICampaignUsageAction.MessagingChange:
			return EventNames.AutomatedMessagesUpdate;
		case ICampaignUsageAction.SearchListChange:
			return EventNames.SearchListUpdate;
		case ICampaignUsageAction.SearchListAndMessagingChange:
			return EventNames.AutomatedMessagesAndSearchListUpdate;
		default:
			return UtilityFunctions.assertUnreachable(action);
	}
};

/**
 * Convert usage events to performance chart events
 * @param event
 */
function toChartEvent(
	event: ArrayElement<ICampaignMemberEventsAndStatsQuery['campaignMemberEvents']>
): ArrayElement<PerformanceChartPropsType['events']> {
	return {
		key: `${event.dateCreated.valueOf()}-${event.action}`,
		type: getEventType(event.action),
		timestamp: event.dateCreated,
	};
}

type PerformanceChartContainerPropsType = {
	/**
	 * id of current Campaign member
	 */
	campaignMemberId: string | undefined;
};

/**
 * Maximum number of week
 */
const MaxNumOfWeek = 4;

/**
 * Get dataKeys by stats type
 * @param type
 * @returns
 */
function getDataKeysByType(
	type: NonNullable<INurtureCampaignStats['__typename'] | IProspectingCampaignStats['__typename']>
) {
	switch (type) {
		case StatsTypeName.prospectingCampaignStats:
			return [ChartModelProperties.connectionRate, ChartModelProperties.replyRate];
		case StatsTypeName.nurtureCampaignStats:
			return [ChartModelProperties.replyRate];
		default:
			return UtilityFunctions.assertUnreachable(type);
	}
}

/**
 * [Smart] Container for Campaign Performance Tracking Chart
 * @param props
 * @returns
 */
function PerformanceChartContainer({ campaignMemberId }: PerformanceChartContainerPropsType) {
	const [{ utcStart, utcEnd, startDomain, endDomain, daysDifference }] = useState(() => {
		const startDate = moment
			.utc()
			.subtract(MaxNumOfWeek, UnitOfTime.week)
			.startOf(UnitOfTime.isoWeek);
		const endDate = moment
			.utc()
			.subtract(1, UnitOfTime.week)
			.endOf(UnitOfTime.isoWeek)
			.endOf(UnitOfTime.day);
		const difference = endDate.diff(startDate, UnitOfTime.day);
		return {
			utcStart: startDate.valueOf(),
			utcEnd: endDate.valueOf(),
			daysDifference: difference,
			startDomain: startDate.add(1, UnitOfTime.week).local(true).valueOf(),
			endDomain: moment.utc().startOf(UnitOfTime.isoWeek).local(true).valueOf(),
		};
	});

	const { data, error, loading } = useCampaignMemberEventsAndStatsQuery({
		variables: {
			campaignMemberId: campaignMemberId ?? '',
			end: utcEnd,
			numDays: daysDifference,
			interval: ITimeInterval.Week,
			start: utcStart,
		},
		skip: !campaignMemberId,
	});

	if (!isNil(error)) throw new Error(JSON.stringify(error));

	const performances = data?.campaignStats.map(toChartModel) ?? [];
	const campaignStatsType = first(data?.campaignStats)?.__typename;
	const performanceKeys = isNil(campaignStatsType) ? [] : getDataKeysByType(campaignStatsType);
	// Events outside of the startDomain cutoff will not be rendered in the chart
	const events = data?.campaignMemberEvents.map(toChartEvent) ?? [];
	const isLoading = loading || _.isUndefined(data);
	return (
		<ErrorBoundary>
			<Card>
				<LoadingIndicator isLoading={isLoading}>
					<PerformanceChart
						start={startDomain}
						end={endDomain}
						data={performances}
						events={events}
						dataKeys={performanceKeys}
						height={'400px'}
					/>
				</LoadingIndicator>
			</Card>
		</ErrorBoundary>
	);
}

export default PerformanceChartContainer;
