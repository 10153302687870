import * as React from 'react';
import { Collapse } from 'antd';
import { CollapseProps } from 'antd/lib/collapse';
import ErrorBoundary from '../errorBoundary';
import styled from 'styled-components';
import { isReactNodeArray, isReactElement } from '@copilot/common/utils';

const StyledCollapse = styled(Collapse)`
	.${(props) => props.theme['@ant-prefix']}-collapse-expand-icon {
		top: 50%;
		position: absolute;
	}

	.${(props) => props.theme['@ant-prefix']}-collapse-header-text {
		left: 20px;
		position: relative;
		padding-right: 20px;
	}
`;

const StyledCollapsePanel = styled(Collapse.Panel)`
	.${(props) => props.theme['@ant-prefix']}-collapse-expand-icon {
		top: 50% !important;
		transform: translateY(-50%);
	}

	h3 {
		margin-bottom: 0;
	}
`;

interface CollapsibleContainerHeaderProps {
	children?: React.ReactNode;
}

class CollapsibleContainerHeader extends React.PureComponent<CollapsibleContainerHeaderProps> {
	render() {
		return <>{this.props.children}</>;
	}
}
const CollapsibleContainerHeaderType = React.createElement(CollapsibleContainerHeader).type;

interface CollapsibleContainerContentProps {
	children?: React.ReactNode;
}

class CollapsibleContainerContent extends React.PureComponent<CollapsibleContainerContentProps> {
	render() {
		return <ErrorBoundary>{this.props.children}</ErrorBoundary>;
	}
}
const CollapsibleContainerContentType = React.createElement(CollapsibleContainerContent).type;

export interface CollapsibleContainerProps extends CollapseProps {
	onToggle?: () => void;
}

interface CollapsibleContainerState {
	activeKey: string[];
}

class CollapsibleContainer extends React.PureComponent<
	CollapsibleContainerProps,
	CollapsibleContainerState
> {
	state: CollapsibleContainerState;
	static Header: typeof CollapsibleContainerHeader = CollapsibleContainerHeader;
	static Content: typeof CollapsibleContainerContent = CollapsibleContainerContent;

	constructor(props: CollapsibleContainerProps) {
		super(props);
		this.state = { activeKey: [] };
		this.toggleCurrentKey = this.toggleCurrentKey.bind(this);
	}

	getHeaderAndContent(children: React.ReactNode) {
		let header = null;
		let content = null;
		if (!isReactNodeArray(children)) throw new Error('ReactNodeArray expected');
		React.Children.forEach(children, (child: React.ReactNode) => {
			if (typeof child === 'string' || typeof child === 'number') content = child;
			else if (isReactElement(child)) {
				switch (child.type) {
					case CollapsibleContainerHeaderType:
						header = child;
						break;
					case CollapsibleContainerContentType:
						content = child;
						break;
					default:
						content = child;
						break;
				}
			} else {
				throw new Error('ReactChild expected');
			}
		});
		return { header, content };
	}

	toggleCurrentKey(e: any) {
		this.setState({ activeKey: e });
		if (this.props.onToggle && e.length > 0) {
			this.props.onToggle();
		}
	}

	render() {
		const { header, content } = this.getHeaderAndContent(this.props.children);
		return (
			<StyledCollapse
				{...this.props}
				onChange={this.toggleCurrentKey}
				activeKey={this.state.activeKey}
			>
				<StyledCollapsePanel header={header} key="1">
					{content}
				</StyledCollapsePanel>
			</StyledCollapse>
		);
	}
}

export default CollapsibleContainer;
