import { ReactElement } from 'react';
import { Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './section.module.less';

type SectionProps = {
	title: string;
	description?: string;
	body: ReactElement;
};

/**
 * Component for styling and grouping a section of the drawer forms
 * @param title
 * @param description
 * @param body
 */
export default function Section({ title, description, body }: SectionProps): ReactElement {
	return (
		<div className={styles.section}>
			<header>
				<Typography.Title level={3}>{title}</Typography.Title>
				{description ? (
					<Tooltip overlay={description}>
						<InfoCircleOutlined />
					</Tooltip>
				) : null}
			</header>
			{body}
		</div>
	);
}
