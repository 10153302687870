import { ReactElement } from 'react';
import { Button, Form, Space } from 'antd';
import BaseDrawer from '../../base/index';
import drawerManager from '@copilot/common/utils/drawerManager/index';
import NotificationManager from '@copilot/common/utils/notificationManager/index';
import {
	IAccountManager,
	useUpdateAccountManagerMutation,
	useUpdateAccountNameMutation,
} from '@copilot/data/graphql/_generated';
import isError from 'lodash/isError';
import AccountEditForm, { FormValues } from './accountEditForm';
import DrawerHeader from '@copilot/common/components/drawer/wrappers/accountDashboard/drawerHeader';
import { Account } from '@copilot/common/pages/accountDashboard/types';
import isNil from 'lodash/isNil';

/* The width of the account edit drawer */
const DRAWER_WIDTH = 495;

/**
 * Footer for the team account edit drawer
 */
function AccountEditDrawerFooter({
	onSave,
	onCancel,
	isLoading,
	isInvalid,
}: {
	onCancel: () => void;
	onSave: () => void;
	isLoading: boolean;
	isInvalid: boolean;
}): ReactElement {
	return (
		<footer style={{ textAlign: 'right' }}>
			<Space>
				<Button type="default" onClick={onCancel}>
					Cancel
				</Button>
				<Button type="primary" onClick={onSave} disabled={isInvalid} loading={isLoading}>
					Save
				</Button>
			</Space>
		</footer>
	);
}

/**
 * Props for Team User Edit drawer
 */
export type AccountEditDrawerProps = {
	/**
	 * Callback function for when a account is edited
	 * The id is the id of the account that was edited
	 */
	onAccountEdited?: (id: string) => unknown;
	/**
	 * The account being edited
	 */
	account: Account;
	/**
	 * List of account managers
	 * If there are no account managers in this scope don't allow
	 */
	accountManagers?: Readonly<IAccountManager[]>;
};

/**
 * Smart Component for the account edit Drawer
 */
export default function AccountEditDrawer({
	account,
	accountManagers,
	onAccountEdited,
}: AccountEditDrawerProps): ReactElement {
	const [updateAccountName, { loading: namesLoading }] = useUpdateAccountNameMutation({
		refetchQueries: ['accounts', 'account'],
	});
	const [updateAccountManager, { loading: accountManagerLoading }] =
		useUpdateAccountManagerMutation({
			refetchQueries: ['accounts', 'account'],
		});

	const [form] = Form.useForm<FormValues>();

	const isLoading = namesLoading || accountManagerLoading;
	async function onFormFinish({ accountId, accountName, accountManagerUserId }: FormValues) {
		try {
			if (accountName != account.name)
				await updateAccountName({ variables: { input: { accountId, accountName } } });
			if (accountManagerUserId != account.accountManager?.id)
				await updateAccountManager({
					variables: {
						input: {
							accountId,
							accountManagerUserId: isNil(accountManagerUserId)
								? ''
								: accountManagerUserId,
						},
					},
				});
			if (onAccountEdited) onAccountEdited(accountId);
			NotificationManager.showSuccessNotification({
				message: 'Account successfully updated',
			});
			drawerManager.closeDrawer();
		} catch (error) {
			NotificationManager.showErrorNotification({
				message: 'Server Error updating account',
				description: isError(error) ? error.message : undefined,
			});
		}
	}

	return (
		<BaseDrawer
			width={DRAWER_WIDTH}
			title={DrawerHeader({ title: 'Edit Account Settings' })}
			footer={AccountEditDrawerFooter({
				onSave: form.submit,
				onCancel: drawerManager.closeDrawer,
				isLoading: isLoading,
				isInvalid: false,
			})}
			onClose={drawerManager.closeDrawer}
		>
			<AccountEditForm
				accountManagers={accountManagers ?? []}
				initialValues={account}
				form={form}
				onFinish={(values) => void onFormFinish(values)}
			/>
		</BaseDrawer>
	);
}
