import React from 'react';
import { Row, Col } from 'antd';

interface FailProps {
	onNext: () => void;
}

const FailWrapper: React.FC<FailProps> = () => (
	<>
		<Row>
			<Col>WARNING</Col>
		</Row>
	</>
);

export default FailWrapper;
