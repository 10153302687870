import LargeTag from '@copilot/common/components/tags';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { CSSProperties, ReactNode } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import { AttentionRequiredLevels } from './constant';

/**
 * Get tag level specific styling
 * @param level
 * @param theme
 * @returns
 */
function getTagStylesByLevel(
	level: keyof typeof AttentionRequiredLevels,
	theme: DefaultTheme
): CSSProperties {
	switch (level) {
		case AttentionRequiredLevels.warning:
			return {
				backgroundColor: theme['@Warning/50'],
				color: theme['@Neutral/80'],
			};
		case AttentionRequiredLevels.blocker:
			return {
				backgroundColor: theme['@Error/70'],
				color: theme['@Neutral/10'],
			};
		default:
			return UtilityFunctions.assertUnreachable(level);
	}
}

type AttentionRequiredTagPropsType = {
	/**
	 * Level of severity
	 */
	level: keyof typeof AttentionRequiredLevels;
	/**
	 * Tag's content
	 */
	children: ReactNode;
};

/**
 * [Persentational] Attention Required Level Tag
 * Please do not use this component directly.
 * @param props
 * @returns
 */
function AttentionRequiredTagInternal(props: AttentionRequiredTagPropsType): JSX.Element {
	const { level, children } = props;
	const theme = useTheme();
	const styleByLevel = getTagStylesByLevel(level, theme);
	return <LargeTag style={{ ...styleByLevel }}>{children}</LargeTag>;
}

export default AttentionRequiredTagInternal;
