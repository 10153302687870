import moment from 'moment';
import { ConversationProps } from './types';
import { IMessage, OrganizationMember } from '@copilot/common/store/models/redux';
import { Divider, List } from 'antd';
import ConversationItem from './item';
import { useMemo } from 'react';
import { SessionBoundModel } from 'redux-orm';
import { ProspectDrawerTrackingParams } from '@copilot/common/components/drawer/wrappers/contact/tracking';

type ConversationListProps = {
	contact: ConversationProps['contact'];
	conversation: ConversationProps['conversation'];
	orgMember: ConversationProps['orgMember'];
	tempOutgoing: IMessage[];
	activeMember: SessionBoundModel<OrganizationMember, {}> | null;
	threadLink: string | undefined;
	onTracking: ((params: ProspectDrawerTrackingParams) => void) | undefined;
};

/**
 * Renderer for the conversation between orgMember and contact displayed in the contact drawer
 * @param param0
 * @returns
 */
export default function ConversationList({
	contact,
	conversation,
	orgMember,
	tempOutgoing,
	activeMember,
	threadLink,
	onTracking,
}: ConversationListProps) {
	const { nonPendingMessages, pendingMessages } = useMemo(() => {
		const internalNonPendingMessages: (IMessage | string)[] = [];
		const internalPendingMessagesQueued: IMessage[] = [];

		if (conversation.messages) {
			let lastMessageDay = undefined;
			for (let i = 0; i < conversation.messages.length; i++) {
				const newMessage: IMessage = conversation.messages[i];
				if (newMessage?.pending) {
					internalPendingMessagesQueued.push(newMessage);
				} else if (newMessage) {
					const formattedDate = moment(newMessage.timestamp).format('dddd MMM Do YYYY');
					if (lastMessageDay != formattedDate) {
						lastMessageDay = formattedDate;
						internalNonPendingMessages.push(lastMessageDay);
					}
					internalNonPendingMessages.push(newMessage);
				}
			}
		}
		return {
			nonPendingMessages: internalNonPendingMessages,
			pendingMessages: internalPendingMessagesQueued.concat(tempOutgoing),
		};
	}, [tempOutgoing]);

	return (
		<>
			<List
				css={`
					${(properties: { theme: { [k: string]: string } }) => {
						const prefix = properties.theme['@ant-prefix'];
						return `
                            li.${prefix}-list-item {
                                &:last-child{
                                    border-bottom: 1px solid #e8e8e8;
                                }
                            }
                
                        `;
					}}
				`}
				dataSource={nonPendingMessages}
				renderItem={(item: IMessage | string, idx: number) => {
					return typeof item === 'string' ? (
						<Divider
							orientation="left"
							style={{
								fontSize: '14px',
								fontWeight: 'normal',
								color: 'rgba(0, 0, 0, 0.88)',
							}}
						>
							{item}
						</Divider>
					) : (
						<ConversationItem
							key={`${idx}`}
							className={item.isSelf ? 'self' : 'contact'}
							orgMember={orgMember}
							activeMember={activeMember}
							message={item}
							contact={contact}
							sourceName={conversation.sourceName}
							targetName={conversation.targetName}
							threadUrl={threadLink}
							onTracking={onTracking}
						/>
					);
				}}
			/>
			{pendingMessages.length > 0 && (
				<List
					dataSource={pendingMessages}
					renderItem={(item: IMessage, idx: number) => (
						<ConversationItem
							key={`${idx}`}
							className={item.isSelf ? 'self' : 'contact'}
							orgMember={orgMember}
							activeMember={activeMember}
							message={item}
							contact={contact}
							sourceName={conversation.sourceName}
							targetName={conversation.targetName}
							threadUrl={threadLink}
							onTracking={onTracking}
						/>
					)}
				/>
			)}
		</>
	);
}
