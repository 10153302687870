import modalManager from '@copilot/common/utils/modalManager';
import React from 'react';
import BaseModal, { BaseModalWithIdProps } from '../base';
import TeamInfoWrapper from './teamInfoWrapper';
import { ITenant } from '@copilot/data/graphql/_generated';

interface ICustomerWizardProps extends BaseModalWithIdProps {
	onSuccess: () => void;
	defaultInstanceId: string;
	nodeTenantIdMap: Map<string, ITenant> | undefined;
}

const TeamCustomerWizard: React.FC<ICustomerWizardProps> = (props) => {
	const { onSuccess, ...modalProps } = props;

	const onCreationSuccess = () => {
		onSuccess();
		modalManager.closeModal(props.modalId);
	};
	return (
		<BaseModal {...modalProps}>
			<TeamInfoWrapper
				onSuccess={onCreationSuccess}
				defaultInstanceId={props.defaultInstanceId}
				nodeTenantIdMap={props.nodeTenantIdMap}
			/>
		</BaseModal>
	);
};

export default TeamCustomerWizard;
