import { PercentageRingGraph } from '@copilot/common/components/charts/simple/percentageRingGraph';
import { DefaultPercentageRingGraph } from '@copilot/common/components/charts/simple/percentageRingGraph/default';
import { fontSizeMD, spacingMD, fontWeightMedium } from '@copilot/common/constant/commonStyles';
import { Button, Divider, Typography } from 'antd';
import { isUndefined } from 'lodash';
import { useTheme } from 'styled-components';

export type PercentageRingAndDescriptionProps = {
	/**
	 * Whether to show a normal ring or a default blank ring
	 * If true, display the ring normally
	 * If false, display a blank state
	 */
	isValid: boolean;
	/**
	 * The text identifying the metric
	 */
	metricName: string;
	/**
	 * The numeric value of the metric
	 */
	metricValue: number;
	/**
	 * The colour of the ring
	 */
	ringColour: string;
	/**
	 * The colour of the text
	 */
	textColour: string;
	/**
	 * Whether or not to show calculation text
	 */
	showCalculations: boolean;
	/**
	 * Text describing how calculations are done
	 */
	calculationDescription: string;
	/**
	 * Text shown to help interpret the metric's rate
	 * If undefined, no text will show
	 */
	supportingText?: string;
	/**
	 * Callback triggered when users clicks 'view tips'
	 * If undefined, button will not show to trigger this callback
	 */
	onClickTips?: () => void;
};

/**
 * Internal component to show a percentage ring with its supporting description (and calculations)
 * @param props
 * @returns
 */
export function PercentageRingAndDescription({
	isValid,
	metricName,
	metricValue,
	ringColour,
	textColour,
	showCalculations,
	calculationDescription,
	supportingText,
	onClickTips,
}: PercentageRingAndDescriptionProps) {
	const theme = useTheme();

	return (
		<div style={{ textAlign: 'center' }}>
			{isValid ? (
				<PercentageRingGraph
					value={metricValue}
					ringColour={ringColour}
					textColour={textColour}
					renderText={(value) => `${value}%`}
				/>
			) : (
				<DefaultPercentageRingGraph />
			)}
			<div>
				<div>
					<Typography.Text
						style={{ fontWeight: fontWeightMedium, color: theme['@Neutral/70'] }}
					>
						{metricName}
					</Typography.Text>
					{/** TODO: [COPILOT-5436] Extract common css classes from inline styles */}
				</div>
				{showCalculations ? (
					<>
						<br />
						<Typography.Text
							style={{ fontSize: fontSizeMD, color: theme['@Neutral/70'] }}
						>
							{calculationDescription}
						</Typography.Text>
					</>
				) : null}
				{isValid && !isUndefined(supportingText) ? (
					<>
						<br />
						<Typography.Text
							style={{ fontSize: fontSizeMD, color: theme['@Neutral/50'] }}
						>
							{supportingText}
						</Typography.Text>
					</>
				) : null}
				{!isUndefined(onClickTips) ? (
					<>
						<Divider type="horizontal" style={{ margin: `${spacingMD} 0` }} />
						{/** TODO: [COPILOT-5436] Extract common css classes from inline styles */}
						<Button
							type="primary"
							style={{ fontSize: fontSizeMD }} // TODO: [COPILOT-5436] Extract common css classes from inline styles
							onClick={onClickTips}
						>
							View tips
						</Button>
					</>
				) : null}
			</div>
		</div>
	);
}
