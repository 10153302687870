import { createFetchSaga } from '@copilot/common/store/saga';
import { LinkedInManager } from '@copilot/data';
import { MemberLinkedInProfileResponse } from '@copilot/data/responses/interface';
import { linkedinProfileLoadAction } from './actionCreator';
import { LINKEDIN_PROFILE } from './constant';

/**
 * Extracts linkedin profile
 * @param orgId organization id of contacts
 * @param contactIds a list of contactIds to fetch linked in profiles for
 */
export const getLinkedInProfiles = async (
	orgId: string,
	contactIds: string[]
): Promise<MemberLinkedInProfileResponse[]> => {
	const results = await LinkedInManager.getContactsLinkedInProfiles(orgId, contactIds);
	return results.map((result) => result.linkedInProfile);
};

export const {
	listWatcher: watchLoadLinkedinProfiles,
	loadListAction: loadLinkedInProfilesAction,
} = createFetchSaga(LINKEDIN_PROFILE, linkedinProfileLoadAction);
