import {
	MESSAGE_ELLIPSISIS_CHAR_COUNT_CONDENSED,
	MESSAGE_ELLIPSIS_CHAR_COUNT,
	SENT_MESSAGE_PREFIX,
} from './constants';

/**
 * Calculate the display message based on message area and expansion state
 * @param messageToDisplay
 * @param isMessageAreaCondensed
 * @param isExpandedMessageVisible
 * @returns
 */
export function calcDisplayMessage(
	messageToDisplay: string,
	isMessageAreaCondensed: boolean,
	isExpandedMessageVisible: boolean
) {
	if (isExpandedMessageVisible) return messageToDisplay;
	if (
		isMessageAreaCondensed &&
		messageToDisplay.length > MESSAGE_ELLIPSISIS_CHAR_COUNT_CONDENSED
	) {
		return messageToDisplay.substring(0, MESSAGE_ELLIPSISIS_CHAR_COUNT_CONDENSED - 3) + '...';
	} else if (messageToDisplay.length > MESSAGE_ELLIPSIS_CHAR_COUNT) {
		return messageToDisplay.substring(0, MESSAGE_ELLIPSIS_CHAR_COUNT - 3) + '...';
	}
	return messageToDisplay;
}

/**
 * Calc if we need to display the expand message button or not
 * @param messageToDisplay
 * @param isMessageAreaCondensed
 * @param isLastMessageReceived
 * @returns
 */
export function isExpandButtonVisible(
	messageToDisplay: string,
	isMessageAreaCondensed: boolean,
	isLastMessageReceived: boolean
) {
	const fullMessageLength =
		messageToDisplay.length + (isLastMessageReceived ? 0 : SENT_MESSAGE_PREFIX.length);
	return isMessageAreaCondensed
		? fullMessageLength > MESSAGE_ELLIPSISIS_CHAR_COUNT_CONDENSED
		: fullMessageLength > MESSAGE_ELLIPSIS_CHAR_COUNT;
}
