import BaseDataManager from './base';
import {
	CrmAccountResponseDto,
	CrmAccountDeleteResponseDto,
	CrmSsoConnectionResponseDto,
} from '../responses/interface';
import { isNil } from 'lodash';
import getAuthToken from '@copilot/common/utils/authToken';
import { TAuthTokenPayload } from '@copilot/common/utils/oauth/types';

class CRMAccountApiManager extends BaseDataManager {
	constructor() {
		super();
	}

	//#region PUBLIC INTERFACE
	/**
	 * Gets CRM account information given an account id
	 * @param {string} accountId CoPilot Account ID (Tenant Id)
	 * @returns {Promise<CrmAccountResponseDto>}
	 */
	public async getAccount(accountId: string) {
		const url = `${this.BACKEND_ROUTES.CRM_ACCOUNT_API.Default}?accountId=${accountId}`;
		const copilotHeader = await this.getCoPilotHeaderToken();
		const response = await this.RequestManager.get<CrmAccountResponseDto>(url, copilotHeader);
		return response.data;
	}

	/**
	 * Creates a new CRM account for a given an account id
	 * @param {string} accountId CoPilot Account ID (Tenant Id)
	 * @param {number} timezoneOffset TimeZone offset in minutes from UTC
	 * @returns {Promise<CrmAccountResponseDto>}
	 */
	public async createAccount(accountId: string, timezoneOffset: number) {
		const data = {
			id: accountId,
			timezoneOffset: timezoneOffset,
		};
		const copilotHeader = await this.getCoPilotHeaderToken();
		const response = await this.RequestManager.post<CrmAccountResponseDto>(
			this.BACKEND_ROUTES.CRM_ACCOUNT_API.Default,
			data,
			copilotHeader
		);
		const payload = response.data;
		this.storeAccountToken(payload.data.sessionToken, payload.data.sessionTokenValidUntil);
		return payload;
	}

	/**
	 * Deletes a CRM account for a given an account id
	 * @param {string} accountId CoPilot Account ID (Tenant Id)
	 * @returns {Promise<CrmAccountDeleteResponseDto>}
	 */
	public async deleteAccount(accountId: string) {
		const url = `${this.BACKEND_ROUTES.CRM_ACCOUNT_API.Default}?accountId=${accountId}`;
		const copilotHeader = await this.getCoPilotHeaderToken();
		const response = await this.RequestManager.delete<CrmAccountDeleteResponseDto>(
			url,
			copilotHeader
		);
		return response.data;
	}

	/**
	 * Gets an existing configured connection from the CRM account
	 * @returns {Promise<CrmSsoConnectionResponseDto>}
	 */
	public async getSsoConnection() {
		const url = `${this.BACKEND_ROUTES.CRM_ACCOUNT_API.SsoMethods}`;
		const userSessionHeader = await this.getCrmAccountHeaderToken();
		const response = await this.RequestManager.get<CrmSsoConnectionResponseDto>(
			url,
			userSessionHeader
		);
		return response.data;
	}

	/**
	 * Creates a new SSO connection for the CRM account
	 * @param accountId
	 * @param access_token
	 * @param refresh_token
	 * @returns {Promise<CrmSsoConnectionResponseDto>}
	 */
	public async createSsoConnection(
		accountId: string,
		access_token: string,
		refresh_token: string
	) {
		const url = `${this.BACKEND_ROUTES.CRM_ACCOUNT_API.SsoMethods}`;
		const data = {
			needsUpdate: true,
			fields: {
				accessToken_2_7794: access_token,
				refreshToken_2_7794: refresh_token,
				accountId_2_7794: accountId,
			},
		};
		const userSessionHeader = await this.getCrmAccountHeaderToken();
		const response = await this.RequestManager.post<CrmSsoConnectionResponseDto>(
			url,
			data,
			userSessionHeader
		);
		return response.data;
	}

	/**
	 * Returns the access token for CRM SSO
	 * @param code auth code after login prompt
	 * @returns {Promise<TAuthTokenPayload>}
	 */
	public async getOAuthSsoToken(code: string) {
		const url = `${this.BACKEND_ROUTES.CRM_ACCOUNT_API.GetAccessToken}?code=${code}`;
		const response = await this.RequestManager.post<TAuthTokenPayload>(url);
		return response.data;
	}

	/**
	 * Requests for a new CRM token for a given CoPilot account and saves to a cookie
	 * @param {string} accountId CoPilot Account ID (Tenant Id)
	 * @returns {Promise<EmptyResponse>}
	 */
	public async refreshAccountToken(accountId: string) {
		const data = {
			id: accountId,
		};
		const copilotHeader = await this.getCoPilotHeaderToken();
		const response = await this.RequestManager.post<CrmAccountResponseDto>(
			this.BACKEND_ROUTES.CRM_ACCOUNT_API.GetCustomerToken,
			data,
			copilotHeader
		);
		const payload = response.data;
		this.storeAccountToken(payload.data.sessionToken, payload.data.sessionTokenValidUntil);
		return payload;
	}

	/**
	 * Retrieves crm account token
	 * @returns token
	 */
	public async getAccountToken(): Promise<string> {
		return Promise.resolve(
			document.cookie.replace(/(?:(?:^|.*;\s*)crm_token\s*\=\s*([^;]*).*$)|^.*$/, '$1')
		);
	}

	/**
	 * Store a crm token into the cookies
	 * @param {string} token Token we want to store
	 */
	private storeAccountToken(token: string | null, validUntil: number | null): void {
		if (isNil(token) || isNil(validUntil)) return;
		const date = new Date(validUntil * 1000);
		if (token !== null) {
			document.cookie = `crm_token=${token}; expires=${date.toUTCString()}; secure`;
		} else document.cookie = "crm_token=''";
	}
	//#endregion PUBLIC INTERFACE

	/**
	 * Gets the CoPilot token in header form
	 * @returns token header
	 */
	private async getCoPilotHeaderToken() {
		const token = await getAuthToken();
		return {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
	}

	/**
	 * Gets the CRM user session token in header form
	 * @returns token header
	 */
	private async getCrmAccountHeaderToken() {
		const token = await this.getAccountToken();
		return {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
	}
}
const instance = new CRMAccountApiManager();
export default instance;
