import { NewUserOnboardModel } from '@copilot/data/responses/models/newUserOnboard';
import { FC, useEffect } from 'react';
import useTracking from '../../../hooks/tracking';

interface NewOnboardWizardTrackingParams {
	wizardStep: string;
	buttonClicked: string;
	selfServe: boolean;
}

/**
 * Tracking hook for new user onboard
 * @param {string} componentName The flow we're tracking
 * @param {NewOnboardWizardTrackingParams} params Parameters for the tracker
 * @returns
 */
export const useNewOnboardWizardTracking = (
	componentName: string,
	params?: Partial<NewOnboardWizardTrackingParams>
): ((newParams: Partial<NewOnboardWizardTrackingParams>) => void) =>
	useTracking(componentName, 'new_user_onboard', params);

interface TrackingProps {
	trackEvent?: <T>(params: T) => void;
	onboard: NewUserOnboardModel;
}

interface PropsSelfServe {
	selfServe?: boolean;
}

type PropsWithTracking<T> = Omit<T, 'trackEvent'> & PropsSelfServe;

export const withOnboardTracking =
	<T extends TrackingProps>(Component: FC<T>, stepMap: Record<number, string>) =>
	(props: PropsWithTracking<T>): JSX.Element => {
		const { onboard, selfServe = false } = props;
		const updateTrackingParams = useNewOnboardWizardTracking('New User Onboard Wizard', {
			selfServe,
		});

		useEffect(() => {
			updateTrackingParams({ wizardStep: `New Onboard ${stepMap[onboard.step]}` });
		}, [onboard.step]);

		return <Component {...(props as T)} trackEvent={updateTrackingParams} />;
	};

export const SALES_NAV_URL_SELECTED_EVENT = 'sales_nav_url_selected';
export const SEARCH_CRITERIA_SELECTED_EVENT = 'search_criteria_selected';
export const WELCOME_SKIP_EVENT = 'welcome_skip_event';
export const LOGIN_SKIP_EVENT = 'login_skip_event';
export const WATCH_TUTORIAL_SELECTED_EVENT = 'watch_tutorial_selected';
