import { FC } from 'react';
import styled from 'styled-components';

/**
 * Animation showing a loading state
 */
export const InProgressIcon: FC = () => {
	return (
		<StyledDiv className="spinner">
			<div className="bounce1"></div>
			<div className="bounce2"></div>
			<div className="bounce3"></div>
		</StyledDiv>
	);
};

const StyledDiv = styled.div`
	&.spinner {
		margin: 0 auto;
		width: 30px;
		text-align: center;

		> div {
			width: 10px;
			height: 10px;
			background-color: ${(props) => props.theme['@PRIMARY-6']};
			border-radius: 100%;
			display: inline-block;
			animation: sk-bouncedelay 1.2s infinite ease-in-out both;
		}

		.bounce1 {
			animation-delay: -0.32s;
		}

		.bounce2 {
			animation-delay: -0.16s;
		}
	}

	@-webkit-keyframes sk-bouncedelay {
		0%,
		80%,
		100% {
			transform: scale(0);
		}
		40% {
			transform: scale(0.9);
		}
	}

	@keyframes sk-bouncedelay {
		0%,
		80%,
		100% {
			transform: scale(0);
		}
		40% {
			transform: scale(0.9);
		}
	}
`;
