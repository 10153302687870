import Store from '..';
import { ContactConnection } from '../models/redux';
import { createSelector, OrmState, SessionBoundModel } from 'redux-orm';
import { ConnectionResponse } from '@copilot/data/responses/interface';

export namespace ContactConnectionSelectors {
	export const getContactConnections = createSelector(Store.ORM, (session) =>
		session.ContactConnection.all().toRefArray()
	);

	export const getContactConnectionById = (state: { entities: OrmState<any> }, id: string) =>
		Store.ORM.session(state.entities).ContactConnection.withId(id);

	export const getContactConnectionByIds =
		(ids: string[]) => (state: { entities: OrmState<any> }) => {
			const contactConnections = Store.ORM.session(state.entities).ContactConnection;
			return ids
				.map(
					(id) =>
						contactConnections.withId(id) as NonNullable<
							SessionBoundModel<ContactConnection, {}>
						>
				)
				.filter((c) => c !== null);
		};

	export const getConnectionsById = (ids: string[]) => (state: { entities: OrmState<any> }) => {
		const idSet = new Set(ids);
		const connections: ConnectionResponse[] = [];
		Store.ORM.session(state.entities)
			.ContactConnection.all()
			.toModelArray()
			.map((c) => connections.push(...c.connections));
		return connections.filter((c) => idSet.has(c.id));
	};

	export const getConnections = () => (state: { entities: OrmState<any> }) => {
		const connections: ConnectionResponse[] = [];
		Store.ORM.session(state.entities)
			.ContactConnection.all()
			.toModelArray()
			.map((c) => connections.push(...c.connections));
		return connections;
	};
}
