import { InboxResponse } from '@copilot/data/responses/interface';
import { InboxModel } from '@copilot/data/responses/models/inbox';
import { PaginatedResultModel } from '@copilot/data/responses/models/paginatedResult';
import { IInboxMessage, InboxMessage } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = InboxMessage.modelName;
export namespace InboxActions {
	export const loadInboxMessagesPaginated = (
		inboxMessages: PaginatedResultModel<InboxResponse | unknown, InboxModel>
	) => {
		const inboxMessagesPayload = inboxMessages.results;
		return {
			type: EntitiesActionTypes.Create,
			itemType,
			payload: inboxMessagesPayload,
		};
	};

	export const loadInboxMessages = (inboxMessages: Partial<IInboxMessage[]>) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: inboxMessages,
	});

	export const updateInboxMessage = (
		inboxMessage: Partial<IInboxMessage> | Partial<IInboxMessage>[]
	) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: inboxMessage,
	});

	export const deleteInboxMessage = (
		inboxMessage: Partial<IInboxMessage> | Partial<IInboxMessage>[]
	) => ({
		type: EntitiesActionTypes.Delete,
		itemType,
		payload: inboxMessage,
	});

	export const resetInboxMessages = () => ({
		type: EntitiesActionTypes.Reset,
		itemType,
	});
}
