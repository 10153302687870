import { createLoadActions } from '@copilot/common/store/actionCreators/list';
import { createFetchSaga } from '@copilot/common/store/saga';
import { ATTENTION_ITEMS } from './constant';
import { OrgMemberAttentionItemModel } from './models';

export const { listWatcher: watchLoadAttentionItems, loadListAction: LoadAttentionItemsAction } =
	createFetchSaga(
		ATTENTION_ITEMS,
		createLoadActions<OrgMemberAttentionItemModel>(ATTENTION_ITEMS)
	);
