import { MessageTypeEnum } from '@copilot/common/pages/onboard/wizard/messaging/const';
import { DEFAULT_SECOND_MESSAGE_TIMING } from '@copilot/common/pages/wizard/const';
import { NodeTimerPeriodNumber } from '@copilot/common/store/models/const';
import { OnboardMessage } from '@copilot/data/requests/models';
import { LinkedInSearchType, SearchCriteria, ServiceSchedule } from '../interface';

export interface NewUserOnboardModel {
	id: string;
	searchCriteria?: Partial<SearchCriteria>;
	searchType: LinkedInSearchType;
	searchUrl: string;
	step: number;
	status: number;
	messages: OnboardMessage[];
	messagingStrategy: MessageTypeEnum;
	messagingStrategyVersion: number;
	messagingStrategyGroupId: string;
	firstMessageExampleKey: string;
	secondMessageExampleKey: string;
	serviceSchedule: ServiceSchedule;
	isFirstLogin: boolean;
}

export const emptyMessages: OnboardMessage[] = [
	{
		nodeId: '',
		text: '',
		period: 0,
		time: 0,
	},
	{
		nodeId: '',
		text: '',
		period: NodeTimerPeriodNumber.Minutes,
		time: DEFAULT_SECOND_MESSAGE_TIMING,
	},
];
