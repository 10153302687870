import { Moment } from 'moment';
import ModalManager from '@copilot/common/utils/modalManager';
import {
	EditSmartReplyCallbackType,
	WriteSmartReplyCallbackType,
	WriteSmartReplyQueryType,
} from '@copilot/common/hooks/smartReply/smartReplyTypes';
import { InboxPageCardViewTrackingParams } from '@copilot/common/pages/inbox/tracking/cardView';
import { SmartReplyPromptCategory } from './smartReply/promptMenu/promptMenuTypes';

/**
 * Props for the message form
 */
export const MessageFormMessageTemplatePropNames = {
	id: 'id',
	name: 'name',
	message: 'message',
	campaignIds: 'campaignIds',
} as const;

export type MessageFormMessageTemplate = {
	[MessageFormMessageTemplatePropNames.id]: string;
	[MessageFormMessageTemplatePropNames.name]: string;
	[MessageFormMessageTemplatePropNames.message]: string;
	[MessageFormMessageTemplatePropNames.campaignIds]: string[];
};

/**
 * Template for automated steps
 */
export interface AutomationTemplate
	extends Omit<
		MessageFormMessageTemplate,
		typeof MessageFormMessageTemplatePropNames.campaignIds
	> {}

/**
 * Values for the message form
 */
export type MessageFormValues = {
	message?: string;
	automationNodeId?: string;
	templateId?: string;
	dateSelected?: Moment;
};

/**
 * Handle when the user runs out of credits for smart reply
 */
export function handleSmartReplyOutOfCredit() {
	ModalManager.openOutOfCreditModal({});
}

export type MessageFormProps = {
	/** Message templates the user can select from */
	messageTemplates: MessageFormMessageTemplate[];
	/** Automated Steps the user can select from */
	automatedSteps?: AutomationTemplate[];
	/** Whether or not the card is disabled, default false */
	isDisabled?: boolean;
	/** Indicates if current message form is sent now or scheduled. Default false */
	isScheduled?: boolean;
	/**
	 * Whether or not to use message template dropdown or cells
	 * default false
	 */
	isUsingTemplateDropdown?: boolean;
	/** (optional) placeholder message override  */
	placeholder?: string;
	/** Callback to set up template messages */
	onSetupTemplate: (formMessage: string) => void | (() => void);
	/** Callback when the user submit a response to message */
	onSubmit?: (
		reply: string,
		removeReminder: boolean,
		automationNodeId?: string,
		templateId?: string,
		date?: Moment
	) => unknown;
	/** Callback when the user submits a scheduled message */
	onScheduledSubmit?: (reply: string, date: Moment, templateId?: string) => unknown;
	/**
	 * callback when the user sets a reminder
	 * nullable because scheduled messages won't use it
	 */
	onSetReminder?: (date: Moment) => unknown;
	/** callback on tracking for inbox card */
	onCardTracking?: (params: InboxPageCardViewTrackingParams) => void;
	/** Callback to retry AI query */
	chatGPTWriteRetryCallback: () => void;
	/** Callback to send AI query */
	chatGPTWriteCallback: WriteSmartReplyCallbackType;
	/** Callback to send edit AI query */
	chatGPTEditCallback: EditSmartReplyCallbackType;
	/** Result from ChatGPT query */
	chatGPTQueryResult: WriteSmartReplyQueryType;
	/** Whether to show the submitting state (sending or scheduling) */
	isSubmitting?: boolean;
	/** Whether the thread's communication is restricted */
	isThreadRestricted?: boolean;
	/** The conversation category that currently applies to the conversation */
	smartReplyPromptCategory?: SmartReplyPromptCategory;
	/** Whether the video in app validation enabled */
	videoInAppValidationEnabled: boolean;
	/** Whether or not the user has accepted the ai terms of service */
	hasAcceptedAITermsOfUse: boolean;
};
