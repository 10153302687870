import * as React from 'react';
import { DrawerProps } from 'antd/lib/drawer';
import { Button, Checkbox, Row, Col } from 'antd';
import drawerManager from '@copilot/common/utils/drawerManager';
import BaseDrawer from '../../base';

export interface NextNodesEditDrawerProps extends DrawerProps {
	availableNodes: { id: string; name: string }[];
	checkedNodes: string[];
	saveNextNode: (tags: string[]) => void;
	closeAlert?: boolean;
}
export interface NextNodesEditDrawerState {
	checkedNodes: string[];
}

class NextNodesEditDrawer extends React.Component<
	NextNodesEditDrawerProps,
	NextNodesEditDrawerState
> {
	state: NextNodesEditDrawerState;

	constructor(props: NextNodesEditDrawerProps) {
		super(props);
		this.saveNextNodes = this.saveNextNodes.bind(this);
		this.state = {
			checkedNodes: props.checkedNodes,
		};
		this.selectChange = this.selectChange.bind(this);
	}

	saveNextNodes() {
		this.props.saveNextNode(this.state.checkedNodes);
		drawerManager.closeDrawer();
	}

	selectChange(e: any) {
		this.setState({
			checkedNodes: e,
		});
	}

	render() {
		const {
			saveNextNode: _saveNextNode,
			checkedNodes: _checkedNodes,
			availableNodes: _availableNodes,
			closeAlert: _closeAlert,
			...rest
		} = this.props;
		const nodeList = Object.values(this.props.availableNodes).map((node) => {
			if (node.id == 'LINKEDIN_PROSPECTING_INVITE_NODE') return undefined;
			return (
				<Row key={node.id}>
					<Col span={24}>
						<Checkbox value={node.id}>{node.name}</Checkbox>
					</Col>
				</Row>
			);
		});

		return (
			<BaseDrawer {...rest}>
				<h3>Select next steps:</h3>
				<Checkbox.Group
					style={{ width: '100%', lineHeight: '35px', marginBottom: '25px' }}
					onChange={this.selectChange}
					value={this.state.checkedNodes}
				>
					{nodeList}
				</Checkbox.Group>

				<div className="drawer-function-buttons">
					<Button onClick={this.saveNextNodes} type="primary">
						Update next steps
					</Button>
				</div>
			</BaseDrawer>
		);
	}
}

export default NextNodesEditDrawer;
