/**
 * State Name
 */
export const ORGANIZATION_MEMBERS = 'ORGANIZATION_MEMBERS';

/**
 * Notification messages for organization member removal
 */
export const removeMemberNotificationSuccess = {
	message: 'Team Member Removed',
};
export const removeMemberNotificationError = {
	message: 'Failed to remove',
	description: 'Please try again.',
};
export const removeOrgAdminNotificationError = {
	message: 'Cannot delete admins!',
};

export const addMemberNotificationSuccess = {
	message: 'Saved',
	description: 'New members were successfully added.',
};

export const reactivateNotificationSuccess = {
	message: 'User Reactivated',
	description: 'User successfully reactivated',
};
export const deactivateNotificationSuccess = {
	message: 'User Deactivated',
	description: 'User successfully deactivated',
};
