import { CSSProperties, ReactNode } from 'react';
import { getChildByType } from '@copilot/common/utils/common';
import { useTheme } from 'styled-components';
import { isUndefined } from 'lodash';

/**
 * Creates a LeftPanel component that renders its children.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children to be rendered within the LeftPanel component.
 *
 * @return {JSX.Element} The rendered LeftPanel component.
 */
function LeftPanel({ children }: { children: ReactNode }) {
	return <>{children}</>;
}

/**
 * Renders a right panel component.
 *
 * @param {Object} props - The props object for the RightPanel component.
 * @param {ReactNode} props.children - The children to be rendered inside the RightPanel component.
 *
 * @return {ReactNode} - The rendered right panel component.
 */
function RightPanel({ children }: { children: ReactNode }) {
	return <>{children}</>;
}

type ContactDrawerLayoutProps = {
	style?: CSSProperties;
	children: Array<ReactNode>;
};

/**
 * Renders a contact drawer layout.
 *
 * @param {ContactDrawerLayoutProps} props - The props for the contact drawer layout.
 * @returns {JSX.Element} - The rendered contact drawer layout.
 */
function ContactDrawerLayout(props: ContactDrawerLayoutProps): JSX.Element {
	const { children, style } = props;
	const LeftPanelComponent = getChildByType(children, LeftPanel);
	const RightPanelComponent = getChildByType(children, RightPanel);
	const theme = useTheme();
	return (
		<div
			style={{
				...style,
				display: 'flex',
				height: '100%',
				flexDirection: 'row',
				minHeight: 0,
			}}
		>
			{isUndefined(LeftPanelComponent) ? null : (
				<div
					style={{
						flex: 1,
						float: 'left',
						borderRightWidth: 1,
						borderRightStyle: 'solid',
						borderColor: theme['@Neutral/20'],
						display: 'flex',
						flexDirection: 'column',
						overflow: 'auto',
					}}
				>
					{LeftPanelComponent}
				</div>
			)}
			<div
				style={{
					flex: 2,
					float: 'right',
					display: 'flex',
					flexDirection: 'column',
					overflow: 'auto',
				}}
			>
				{RightPanelComponent}
			</div>
		</div>
	);
}

ContactDrawerLayout.LeftPanel = LeftPanel;
ContactDrawerLayout.RightPanel = RightPanel;
export default ContactDrawerLayout;
