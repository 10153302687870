// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YZF2U1UrykTwodNgojkE .copilot-modal-content,\n.sIKwS20LJg6iQbffz1v1 .copilot-modal-content {\n  padding: 32px 32px 24px 32px;\n}\n.sIKwS20LJg6iQbffz1v1 .copilot-modal-close {\n  top: 32px;\n  inset-inline-end: 32px;\n}\n.sIKwS20LJg6iQbffz1v1 ul {\n  list-style-type: none;\n}\n.sIKwS20LJg6iQbffz1v1 ul li {\n  margin-inline: 0px;\n  padding-inline: 0px;\n}\n.sIKwS20LJg6iQbffz1v1 ul li:not(:last-child) {\n  margin-bottom: 8px;\n}\n.lBwNLkRK0E0AirTQfr31 {\n  height: 250px;\n  overflow-y: auto;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/campaignDashboard/searchList/campaignDashboardSearchList/confirm-modal.module.less"],"names":[],"mappings":"AAAA;;EAEE,4BAAA;AACF;AAGA;EAIE,SAAA;EACA,sBAAA;AAJF;AADA;EASE,qBAAA;AALF;AAJA;EAYG,kBAAA;EACA,mBAAA;AALH;AARA;EAiBG,kBAAA;AANH;AAWA;EACC,aAAA;EACA,gBAAA;AATD","sourcesContent":[".inMailSequenceModal {\n\t:global(.copilot-modal-content) {\n\t\tpadding: 32px 32px 24px 32px;\n\t}\n}\n\n.inMailAllocation {\n\t&:extend(.inMailSequenceModal all);\n\t\n\t:global(.copilot-modal-close) {\n\t\ttop: 32px;\n\t\tinset-inline-end: 32px;\n\t}\n\t\n\tul {\n\t\tlist-style-type: none;\n\t\t\n\t\tli {\n\t\t\tmargin-inline: 0px;\n\t\t\tpadding-inline: 0px;\n\t\t}\n\t\t\n\t\tli:not(:last-child) {\n\t\t\tmargin-bottom: 8px;\n\t\t}\n\t}\n}\n\n.campaignList {\n\theight: 250px;\n\toverflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inMailSequenceModal": "YZF2U1UrykTwodNgojkE",
	"inMailAllocation": "sIKwS20LJg6iQbffz1v1",
	"campaignList": "lBwNLkRK0E0AirTQfr31"
};
export default ___CSS_LOADER_EXPORT___;
