import {
	ITagsApplyDrawerProps,
	ITagsApplyDrawerSagaProps,
} from '@copilot/common/components/drawer/wrappers/batchTag/const';
import {
	IBasicNewUserMessageEditDrawerProps,
	INewUserMessageWithTemplatesEditDrawerProps,
} from '@copilot/common/components/drawer/wrappers/campaignSequence/wizardEdit/const';
import Store from '@copilot/common/store';
import { AppActions } from '@copilot/common/store/actions/app';
import { DrawerProps } from 'antd/lib/drawer';
import { DrawerAction, IDrawerAction } from './action';
import { UserEditDrawerProps } from '@copilot/common/components/drawer/wrappers/accountDashboard/userEdit/userEditDrawer';
import { AccountEditDrawerProps } from '@copilot/common/components/drawer/wrappers/accountDashboard/accountEdit/acountEditDrawer';
import { AccountCreationDrawerProps } from '@copilot/common/components/drawer/wrappers/accountDashboard/accountCreation/accountCreationDrawer';

// import { AppActions } from 'app/actions/app';
// import { store } from '../../store';

export enum DrawerTypes {
	ContactDrawer = 'ContactDrawer',
	SearchCriteriaDrawer = 'SearchCriteriaDrawer',
	MemberAdditionDrawer = 'MemberAdditionDrawer',
	CampaignTeamDrawer = 'CampaignTeamDrawer',
	CampaignMemberDrawer = 'CampaignMemberDrawer',
	TriggerEditDrawer = 'TriggerEditDrawer',
	MessagesEditDrawer = 'MessagesEditDrawer',
	NewUserMessageWithTemplatesEditDrawer = 'NewUserMessageWithTemplatesEditDrawer',
	NewUserMessageBasicEditDrawer = 'NewUserMessageBasicEditDrawer',
	TagsEditDrawer = 'TagsEditDrawer',
	NextNodesEditDrawer = 'NextNodesEditDrawer',
	CreateNodeDrawer = 'CreateNodeDrawer',
	AdminMemberDrawer = 'AdminMemberDrawer',
	// InboxMessageDrawer = 'InboxMessageDrawer',
	WizardMessageEditDrawer = 'WizardMessageEditDrawer',
	NotificationDrawer = 'NotificationDrawer',
	TagsApplyDrawer = 'TagsApplyDrawer',
	TagsApplyDrawerSaga = 'TagsApplyDrawerSaga',
	UsageLimitsDrawer = 'UsageLimitsDrawer',
	CouponDrawer = 'CouponDrawer',
	TipsDrawer = 'TipsDrawer',
	AccountCreation = 'AccountCreation',
	UserEditDrawer = 'UserEditDrawer',
	AccountEditDrawer = 'AccountEditDrawer',
}

/** Manager for opening and closing of drawers */
class DrawerManager {
	constructor() {}

	/**
	 * Opens the drawer to display the provided component
	 * @param {IDrawerAction['type']} type The type of drawer we want to display
	 * @param {IDrawerAction['props']} props The props we want to pass to the associated drawer
	 */
	openDrawer(type: IDrawerAction['type'], props: IDrawerAction['props']) {
		//TODO UPDATE TO USE REDUX
		// store.dispatch(AppActions.openDrawer(new DrawerAction(type, props)));
		Store.Dispatch(AppActions.openDrawer(new DrawerAction(type, props)));
	}

	openAccountCreationDrawer(props: AccountCreationDrawerProps & IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.AccountCreation, props);
	}

	openAccountEditDrawer(props: AccountEditDrawerProps & IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.AccountEditDrawer, props);
	}

	openUserEditDrawer(props: UserEditDrawerProps & IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.UserEditDrawer, props);
	}

	openContactDrawer(props: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.ContactDrawer, props);
	}

	openSearchCriteriaDrawer(props: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.SearchCriteriaDrawer, props);
	}

	openAddMemberDrawer(props: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.MemberAdditionDrawer, props);
	}

	openCampaignTeamDrawer(props: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.CampaignTeamDrawer, props);
	}

	openCampaignMemberDrawer(props: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.CampaignMemberDrawer, props);
	}

	openTriggerEditDrawer(props?: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.TriggerEditDrawer, props);
	}

	openMessagesEditDrawer(props?: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.MessagesEditDrawer, props);
	}

	openNewUserMessageEditWithTemplateDrawer(
		props: INewUserMessageWithTemplatesEditDrawerProps & DrawerProps
	) {
		this.openDrawer(DrawerTypes.NewUserMessageWithTemplatesEditDrawer, props);
	}

	openNewUserMessageEditDrawer(props: IBasicNewUserMessageEditDrawerProps & DrawerProps) {
		this.openDrawer(DrawerTypes.NewUserMessageBasicEditDrawer, props);
	}

	openTagsEditDrawer(props?: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.TagsEditDrawer, props);
	}

	openNextNodesEditDrawer(props?: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.NextNodesEditDrawer, props);
	}

	addNodeDrawer(props?: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.CreateNodeDrawer, props);
	}

	openAdminMemberDrawer(props?: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.AdminMemberDrawer, props);
	}

	openWizardMessageEditDrawer(props?: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.WizardMessageEditDrawer, props);
	}

	openNotificationDrawer(props?: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.NotificationDrawer, props);
	}

	openTagsApplyDrawer(props?: ITagsApplyDrawerProps) {
		this.openDrawer(DrawerTypes.TagsApplyDrawer, props);
	}

	openTagsApplyDrawerSaga(props?: ITagsApplyDrawerSagaProps) {
		this.openDrawer(DrawerTypes.TagsApplyDrawerSaga, props);
	}

	openUsageLimitsDrawer(props?: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.UsageLimitsDrawer, props);
	}

	openCouponDrawer(props: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.CouponDrawer, props);
	}

	openTipsDrawer(props: IDrawerAction['props']) {
		this.openDrawer(DrawerTypes.TipsDrawer, props);
	}

	// openInboxMessageSelection(props?: IDrawerAction['props']) {
	// 	this.openDrawer(DrawerTypes.InboxMessageDrawer, props);
	// }

	/**
	 * Closes the drawer by removing drawer details from store
	 */
	closeDrawer() {
		//UPDATE TO USE ORM
		// store.dispatch(AppActions.closeDrawer());
		Store.Dispatch(AppActions.closeDrawer());
	}
}

export default new DrawerManager();
