import BaseDataManager, { QueryObject } from './base';
import {
	OrganizationResponse,
	OrganizationAdminDetailsResponse,
	PaginatedResponse,
	AdminClientResponse,
	CSMemberResponse,
	AdminClientCreateModel,
	ClientPersonalDataModel,
	AdminMemberResponse,
	AdminTeamClientResponse,
	EmptyResponse,
	OrganizationMemberResponse,
	BillingInfoResponse,
} from '../responses/interface';

import { PaginatedResultModel } from '../responses/models/paginatedResult';
import { ClientModel, ClientDetails, TeamClientModel } from '../responses/models/client';
import { DailyAllocationType } from '@copilot/common/utils/constant';
import { UpdateEventModel } from '../requests/models';
import { PAYMENT_PRODUCT_URL, PAYMENT_URL } from '../config/routes';
import { IBillingInfo, ICoupon, IInvoice, IProduct } from '../responses/models/billing';
import { IInstanceData } from '../responses/models/instances';

/** Data Manager for System Admin */
class AdminManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get the organizations
	 * @returns A promise resolving the organization models
	 */
	getOrganizations = (query: QueryObject = new QueryObject({ pageSize: 5000 })) => {
		const url = this.paginate(this.BACKEND_ROUTES.ORGANIZATION.Default, query);
		if (!(url in this.currentRequests))
			this.currentRequests[url] = this.RequestManager.get<OrganizationResponse[]>(url)
				.then((r) => r.data)
				.finally(() => delete this.currentRequests[url]);
		return this.currentRequests[url];
	};

	/**
	 * Cloned Oganization
	 * @param {string} id organization's id to clone
	 */
	cloneOrganization = async (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.Organizations, id, 'clone');
		const response = await this.RequestManager.post<OrganizationResponse>(url, null);
		return response.data;
	};

	/**
	 * Updates admin level organization details
	 * @param id organization to update
	 * @param updates The updates we want to post
	 * @returns A promise resolving the organization models
	 */
	updateOrganizationAdminDetails = (
		id: string,
		updates: Partial<OrganizationAdminDetailsResponse>
	) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.Organizations, id);
		return this.RequestManager.put<OrganizationAdminDetailsResponse>(url, updates).then(
			(response) => response.data
		);
	};

	/**
	 * Get clients
	 * @param query QueryObject Filters
	 */
	public getClients = (query: QueryObject = new QueryObject()) => {
		const url = this.paginate(this.BACKEND_ROUTES.ADMIN.Client, query);
		return this.RequestManager.get<PaginatedResponse<AdminClientResponse>>(url, {
			params: query.toQueryParam(),
		}).then((response) => new PaginatedResultModel(response.data, ClientModel));
	};

	/**
	 * Get Team clients
	 * @param query QueryObject Filters
	 */
	public getTeamClients = (query: QueryObject = new QueryObject()) => {
		const url = this.paginate(this.BACKEND_ROUTES.ADMIN.Teams, query);
		return this.RequestManager.get<PaginatedResponse<AdminTeamClientResponse>>(url, {
			params: query.toQueryParam(),
		}).then((response) => new PaginatedResultModel(response.data, TeamClientModel));
	};

	/**
	 * Get all members of an organization, active and inactive
	 * @param organizationId Id of the organization we want members for
	 * @returns {Promise<OrganizationMemberResponse[]} A promise resolving the
	 *                                                 members of an organization
	 */
	public getAllOrganizationMembers = async (organizationId: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ADMIN.Organizations,
			organizationId,
			'members'
		);
		const orgMembers = await this.RequestManager.get<OrganizationMemberResponse[]>(url);
		return orgMembers.data;
	};

	/**
	 * Register user with first campaign
	 * @param model customer model
	 */
	createCustomer(model: AdminClientCreateModel) {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.Client);
		return this.RequestManager.post(url, model)
			.then((response) => {
				const { data } = response;
				return data;
			})
			.catch((error: any) => {
				throw error;
			});
	}

	/**
	 * Register Team with Org Owner
	 * @param model customer model
	 */
	createTeam = (model: Partial<AdminClientCreateModel>) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.Teams);
		return this.RequestManager.post<AdminTeamClientResponse>(url, model).then(
			(response) => response.data
		);
	};

	/**
	 * Disconnect user
	 * @param orgMemberId Org member id of the client
	 */
	public disconnectUser = (orgMemberId: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.Client, orgMemberId, 'disconnect');
		return this.RequestManager.post(url)
			.then((response) => {
				const { data } = response;
				return data;
			})
			.catch((error: any) => {
				throw error;
			});
	};

	/**
	 * Reenable user
	 * @param orgMemberId Org member id of the client
	 */
	public connectUser = (orgMemberId: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.Client, orgMemberId, 'enable');
		return this.RequestManager.post(url)
			.then((response) => {
				const { data } = response;
				return data;
			})
			.catch((error: any) => {
				throw error;
			});
	};

	/**
	 * Update model.PropertyName with model.UpdatedValue
	 * @param organizationMemberId The org member's id
	 * @param model the model used to update the PropertyName's value
	 * @param url endpoint to hit
	 * @template T return type
	 * @template K type of UpdateValue in UpdateEventModel
	 */
	private updateOne<T, K>(url: string, data: UpdateEventModel<K>) {
		if (!url) return Promise.reject(new Error('No endpoint URL provided'));
		return this.RequestManager.put<T>(url, data).then((r) => r.data);
	}

	/**
	 * Update whether an organization member is 'active' and billable
	 * Use for only in Teams
	 * @param organizationMemberId The org member's id
	 * @param isActive boolean to set isActive for the org member
	 */
	public updateIsActive = (organizationMemberId: string, isActive: boolean) => {
		if (!organizationMemberId) return Promise.reject(new Error('Member with id not provided'));
		const requestModel: UpdateEventModel<boolean> = {
			PropertyName: 'IsActive',
			UpdateValue: isActive,
		};
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.OrgMembers, organizationMemberId);
		return this.updateOne<OrganizationMemberResponse, boolean>(url, requestModel);
	};

	/**
	 * Update whether the client is active or enabled
	 * Use for only in CS Dash
	 * @param organizationMemberId The org member's id of the client
	 * @param isActive boolean to set isActive for the org member
	 */
	public updateIsActiveCS = (organizationMemberId: string, isActive: boolean) => {
		if (!organizationMemberId) return Promise.reject(new Error('Member with id not provided'));
		const requestModel: UpdateEventModel<boolean> = {
			PropertyName: 'IsActive',
			UpdateValue: isActive,
		};
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ADMIN.Client,
			'members',
			organizationMemberId
		);
		return this.updateOne<AdminTeamClientResponse, boolean>(url, requestModel);
	};

	/**
	 * Get list of cs owners
	 **/
	public getCSMembers = () => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.CS, 'list');

		return this.RequestManager.get<CSMemberResponse[]>(url).then((response) => response.data);
	};

	/**
	 * Get admin information
	 * @param userId Org member id of the client
	 */
	public getAdminMember = (userId: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.AdminMemberDetails, userId);
		return this.RequestManager.get<AdminMemberResponse>(url).then((response) => {
			const { data } = response;
			return data;
		});
	};

	/**
	 * Set admin information
	 * @param userId  user id of the client
	 * @param calendlyUrl calendly url
	 */
	public setAdminMemberUrl = (userId: string, calendlyUrl: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.AdminMember, userId);
		return this.RequestManager.post(url, {
			CalendlyUrl: calendlyUrl,
		}).then((response) => response.data);
	};

	/**
	 * Reset admin information
	 * @param userId user id of the client
	 */
	public unsetAdminMemberUrl = (userId: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.AdminMemberDetails, userId);
		return this.RequestManager.delete(url).then((response) => response.data);
	};

	/**
	 * Get a client's information
	 * @param orgMemberId Org member id of the client
	 */
	public getClientDetails(orgMemberId: string) {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.Client, orgMemberId, 'details');
		return this.RequestManager.get(url).then((response) => {
			const { data } = response;
			const clientDetails = new ClientDetails(data);
			return clientDetails;
		});
	}

	/**
	 * Update monthly invites/messages per user
	 * @param {string} orgId Organization Id
	 * @param {number} monthlyAllocation number of invites/messages
	 * @param {DailyAllocationType} allocationType
	 */
	updateLinkedinOrganizationAllocations(
		orgId: string,
		monthlyAllocation: number,
		allocationType: DailyAllocationType
	): Promise<any> {
		const url = `${this.BACKEND_ROUTES.LINKED_IN.OrgInfo}/${orgId}`;
		const requestObject: { [k: string]: number } = {};
		switch (allocationType) {
			case DailyAllocationType.Invites:
				requestObject.monthlyInvitePerUser = monthlyAllocation;
				break;
			case DailyAllocationType.Messages:
				requestObject.monthlyMessagePerUser = monthlyAllocation;
				break;
		}
		return this.RequestManager.put(url, requestObject).then((response) => {
			const { data } = response;
			return data;
		});
	}

	/**
	 * Update Client Personal Details
	 * @param orgMemberId orgMember Id
	 * @param updates client details updates we want
	 */
	updateClientDetails(orgMemberId: string, updates: Partial<ClientPersonalDataModel>) {
		const url = `${this.BACKEND_ROUTES.ADMIN.Client}/${orgMemberId}`;
		return this.RequestManager.put(url, updates).then(
			(response) => response.data,
			(err) => Promise.reject(err.response.data)
		);
	}
	/**
	 * Reconnect or disconnect a campaign
	 * @param {string} orgMemberId The id of the orgMember
	 * @param {string} campaignId The id of the campaign we want to reconnect or disconnect
	 * @param {boolean} connect true for reconnect, false for disconnect
	 */
	public connectCampaign = (orgMemberId: string, campaignId: string, connect: boolean) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ADMIN.Client,
			orgMemberId,
			'campaign',
			campaignId,
			'connect'
		);

		const params = { connect };
		return this.RequestManager.post(url, null, { params })
			.then((response) => {
				const { data } = response;
				return data;
			})
			.catch((error: any) => {
				throw error;
			});
	};

	/**
	 * update a campaign approval status
	 * @param {string} orgMemberId The id of the orgMember
	 * @param {string} campaignId The id of the campaign we want to reconnect or disconnect
	 * @param {number} status approval status to update the campaign to
	 */
	public updateApprovalCampaign = (campaignId: string, orgMemberId: string, status: number) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ADMIN.Client,
			orgMemberId,
			'campaign',
			campaignId,
			'status'
		);

		return this.RequestManager.post(url, null, { params: { status } })
			.then((response) => {
				const { data } = response;
				return data;
			})
			.catch((error: any) => {
				throw error;
			});
	};

	/**
	 * Update an org member's advanced mode toggle
	 * @param orgMemberId The id of the org member we want to update
	 * @param isAdvanced Whether that org member should have advanced mode enabled
	 */
	public updateAdvancedMode = async (orgMemberId: string, isAdvanced: boolean) => {
		if (!orgMemberId) throw new Error('Org Member Id not set');
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.Client, orgMemberId, 'advanced');
		const result = await this.RequestManager.post<EmptyResponse>(url, null, {
			params: { isAdvanced },
		});
		return result.data;
	};

	/**
	 * Gets all WL InstanceData config objects
	 */
	public getAllInstanceData = async () =>
		this.RequestManager.get<IInstanceData[]>(this.BACKEND_ROUTES.ADMIN.Instance).then(
			(r) => r.data
		);

	/**
	 * Get a client's billing info
	 * @param organizationId orgId of teams customer
	 */
	public getBillingInfo = async (organizationId: string) => {
		if (!PAYMENT_URL) return Promise.reject('Payment URL does not exist');
		if (!organizationId) return Promise.reject('Organization Id not set');
		const url = this.combineRoute(this.BACKEND_ROUTES.BILLING.Info, organizationId);
		const billingInfo = await this.RequestManager.get<BillingInfoResponse>(url);
		return billingInfo.data;
	};

	/**
	 * Get a individual's billing info by stripe customer id
	 * @param customerId stripe customer id
	 */
	public getIndividualBillingInfo = async (customerId: string) => {
		if (!PAYMENT_URL) return Promise.reject('Payment URL does not exist');
		if (!customerId) return Promise.reject('Customer Id not set');
		const url = this.combineRoute(this.BACKEND_ROUTES.BILLING.IndividualInfo, customerId);
		const billingInfo = await this.RequestManager.get<IBillingInfo>(url);
		return billingInfo.data;
	};

	/**
	 * Gets a list of available products for client subscription
	 * @param customerId stripe id
	 * @returns list of products
	 */
	public getProducts = async (customerId: string) => {
		if (!PAYMENT_PRODUCT_URL) return Promise.reject('Payment URL does not exist');
		const url = this.BACKEND_ROUTES.BILLING.Products;
		return this.RequestManager.get<IProduct[]>(url, {
			params: { customerId },
		}).then((r) => r.data);
	};

	/**
	 * Gets a list of available coupons to apply to a product
	 * @param customerId the customers stripe id
	 * @returns list of coupons
	 */
	public getCoupons = async (customerId: string) => {
		if (!PAYMENT_PRODUCT_URL) return Promise.reject('Payment URL does not exist');
		const url = this.BACKEND_ROUTES.BILLING.Coupons;
		return this.RequestManager.get<ICoupon[]>(url, {
			params: { customerId },
		}).then((r) => r.data);
	};

	/**
	 * Gets an invoice preview given specified pricing, update schedule, and coupon
	 * @param subscriptionId the subscription id to update
	 * @param pricingId the pricing id
	 * @param updateNow whether to update now or update next billing date
	 * @param [couponId] the coupon id
	 * @returns IInvoice invoice preview
	 */
	public getInvoicePreview = async (
		subscriptionId: string,
		pricingId: string,
		updateNow: boolean,
		couponId?: string
	) => {
		if (!PAYMENT_PRODUCT_URL) return Promise.reject('Payment URL does not exist');
		const url = this.BACKEND_ROUTES.BILLING.PreviewSubscription;
		const data = {
			subscriptionId,
			items: [pricingId],
			couponId,
			updateNow,
		};
		return this.RequestManager.post<IInvoice>(url, data).then((r) => r.data);
	};

	/**
	 * Update the subscription given specified pricing, update schedule, and coupon
	 * @param subscriptionId the subscription id to update
	 * @param pricingId the pricing id
	 * @param updateNow whether to update now or update next billing date
	 * @param [couponId] the coupon id
	 * @returns IInvoice
	 */
	public updateSubscription = async (
		subscriptionId: string,
		pricingId: string,
		updateNow: boolean,
		couponId?: string
	) => {
		if (!PAYMENT_PRODUCT_URL) return Promise.reject('Payment URL does not exist');
		const url = this.combineRoute(this.BACKEND_ROUTES.BILLING.Subscription, subscriptionId);
		const data = {
			items: [pricingId],
			couponId,
			updateNow,
		};

		return this.RequestManager.post<IInvoice>(url, data).then((r) => r.data);
	};
	/**
	 * Migrates an Individual Customer to a Teams Customer
	 * @param orgMemberId
	 */

	public migrateIndividualToTeamCustomer = async (orgMemberId: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.ADMIN.Client, orgMemberId, 'migrate');
		const data = {
			orgMemberId,
		};

		await this.RequestManager.post<EmptyResponse>(url, data);
	};
}

const instance = new AdminManager();
export { instance as AdminManager };
