import { BaseModel } from './base';
import { attr } from 'redux-orm';
import { SearchCriteria, LinkedInSearchType } from '@copilot/data/responses/interface';
import { OnboardMessage } from '@copilot/data/requests/models';
import { CampaignOnboardWizardSteps } from '@copilot/common/pages/campaignDashboard/onboard/constant';

export interface ICampaignOnboardDetails {
	id: string;
	searchCriteria: Partial<SearchCriteria>;
	searchType: LinkedInSearchType;
	searchUrl: string;
	calendlyEventId: string;
	meetingDate: string;
	step: CampaignOnboardWizardSteps;
	status: number;
	messages: OnboardMessage[];
}

export class CampaignOnboardDetails extends BaseModel<
	typeof CampaignOnboardDetails,
	ICampaignOnboardDetails
> {
	static modelName = 'CampaignOnboardDetails';
	static fields = {
		id: attr(),
		searchCriteria: attr(),
		searchType: attr(),
		searchUrl: attr(),
		calendlyEventId: attr(),
		meetingDate: attr(),
		status: attr(),
		step: attr(),
		messages: attr(),
	};
}
