import { FC } from 'react';
import { ALL, REMINDER } from '@copilot/common/pages/inbox/ui/constant';
import HeaderContentLayout from '@copilot/common/pages/layouts/headerContent';
import styled from 'styled-components';

const StyledTabs = styled(HeaderContentLayout.Tabs)`
	&&&& > .${(props) => props.theme['@ant-prefix']}-tabs-nav {
		margin: 0px;
	}
`;

const StyledTabPane = styled(HeaderContentLayout.TabPanes)`
	background-color: ${(props) => props.theme['@layout-body-background']};
`;

interface IInboxCardViewByReminderTabProp {
	/** Inbox Message Counts */
	counts: Record<string, number>;
	/** Name of the tab that is currently selected */
	reminderTabName: string;
	/** Callback when the user switches to another tab */
	onReminderTabUpdate: (key: string) => void;
}

/**
 * Inbox Card View Reminder Tab
 * @param {Record<string, number>} counts inbox message counts
 * @param {string} tabName tab name that is currently selected
 * @param {function} onTabNameUpdate callback when the user switches to another tab
 */
const InboxCardViewByReminderTab: FC<IInboxCardViewByReminderTabProp> = (props) => {
	const { counts, reminderTabName, onReminderTabUpdate } = props;
	return (
		<StyledTabs activeKey={reminderTabName} onChange={onReminderTabUpdate}>
			<StyledTabPane tab={`${ALL} (${counts.total ?? 0})`} key={ALL} />
			<StyledTabPane tab={`Reminders (${counts[REMINDER] ?? 0})`} key={REMINDER} />
		</StyledTabs>
	);
};
export default InboxCardViewByReminderTab;
