import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalSelectors } from '@copilot/common/store/selectors/modal';
import { ModalActions } from '@copilot/common/store/actions/modal';
import { ModalStatus, ModalType } from '@copilot/common/store/models/redux/modal';
import Calendly from './wrappers/calendly';
import CampaignCreationAlertModal from './wrappers/campaignCreation/alert';
import VideoModal from '@copilot/common/components/modals/wrappers/video';
import SalesNavTutorialModal from '@copilot/common/components/modals/wrappers/salesNavTutorial';
import CampaignCreationModal from './wrappers/campaignCreation';
import CustomerCreationWizard from './wrappers/customerCreation';
import ChangePassword from './wrappers/changePassword';
import TeamCustomerWizard from './wrappers/teamCustomerCreation';
import ConnectionMigrationWizard from './wrappers/connectionMigration';
import OrganizationMemberActivation from './wrappers/memberActivation';
import RestrictMemberDeactivation from './wrappers/restrictMemberDeactivation';
import InitialLinkedinSyncModal from './wrappers/linkedinSync/initialSync';
import CarouselModal from './wrappers/carousel';
import ChangesNotSaved from './wrappers/changesNotSaved';
import ReconnectLinkedInAlertModal from './wrappers/linkedinSync/alert';
import MigrationModal from './wrappers/migration/index';
import TeamCustomerInstanceDataUpdateModal from './wrappers/teamCustomerInstanceDataUpdate';
import CampaignLaunchErrorModal from './wrappers/campaignLaunchError';
import TagsModal from './wrappers/tags/tagsModal';
import OutOfCreditModal from './wrappers/outOfCredit';
import CreateQuickResponseModal from './wrappers/quickResponse';
import SmartReplyTermsOfUseModal from '@copilot/common/components/modals/wrappers/smartReplyTermsOfUse/SmartReplyTermsOfUse';
import { BaseModalWithIdProps } from './wrappers/base';
import AIFeatureTermsOfUseModal from '@copilot/common/components/modals/wrappers/aiFeatureTermsOfUse';

interface ModalManagerContainerProps {}

const ModalComponentLookupTable: {
	[key in ModalType]: React.ComponentType<any> | React.FC<any>;
} = {
	[ModalType.CampaignCreationAlertModal]: CampaignCreationAlertModal,
	[ModalType.VideoModal]: VideoModal,
	[ModalType.SalesNavTutorialModal]: SalesNavTutorialModal,
	[ModalType.Calendly]: Calendly,
	[ModalType.CampaignCreationModal]: CampaignCreationModal,
	[ModalType.CustomerAddition]: CustomerCreationWizard,
	[ModalType.TeamAddition]: TeamCustomerWizard,
	[ModalType.InitialSyncLinkedIn]: InitialLinkedinSyncModal,
	[ModalType.Password]: ChangePassword,
	[ModalType.ProspectsMigration]: ConnectionMigrationWizard,
	[ModalType.OrganizationMemberActivation]: OrganizationMemberActivation,
	[ModalType.RestrictMemberDeactivation]: RestrictMemberDeactivation,
	[ModalType.CarouselModal]: CarouselModal,
	[ModalType.ChangesNotSavedModal]: ChangesNotSaved,
	[ModalType.ReconnectLinkedInModal]: ReconnectLinkedInAlertModal,
	[ModalType.MigrationModal]: MigrationModal,
	[ModalType.TeamCustomerInstanceDataModal]: TeamCustomerInstanceDataUpdateModal,
	[ModalType.CampaignLaunchErrorModal]: CampaignLaunchErrorModal,
	[ModalType.TagsModal]: TagsModal,
	[ModalType.OutOfCredit]: OutOfCreditModal,
	[ModalType.CreateQuickResponseModal]: CreateQuickResponseModal,
	[ModalType.SmartReplyTermsOfUse]: SmartReplyTermsOfUseModal,
	[ModalType.AIFeatureTermsOfUseModal]: AIFeatureTermsOfUseModal,
};

const ModalManagerContainer: React.FC<ModalManagerContainerProps> = () => {
	const modals = useSelector(ModalSelectors.getModals);
	const dispatch = useDispatch();

	const handleClose = useCallback((id: string) => {
		dispatch(ModalActions.closeModal(id));
	}, []);

	const handleExited = useCallback((id: string) => {
		dispatch(ModalActions.removeModal(id));
	}, []);

	const modalElements = useMemo(
		() =>
			modals.map((m) => {
				const baseProps: BaseModalWithIdProps = {
					modalId: m.id,
					open: m.status === ModalStatus.Open,
					onCancel: handleClose.bind(undefined, m.id),
					// afterClose does not seem to be fired by ant.design modals... faze out as we move to hooks
					afterClose: handleExited.bind(undefined, m.id),
					footer: null,
				};
				const ModalComponent = ModalComponentLookupTable[m.type] ?? React.Fragment;
				return <ModalComponent key={`${m.id}-${m.status}`} {...baseProps} {...m.props} />;
			}),
		[modals]
	);

	return <>{modalElements}</>;
};

export default ModalManagerContainer;
