import { useEffect } from 'react';
import { useFetch } from '@copilot/common/hooks/common';
import { LinkedInManager } from '@copilot/data';
import { isNil } from 'lodash';
import { QueryResponseType } from '@copilot/common/hooks/types';

/**
 * Hook for retrieving daily limits for Open InMail
 * @param orgId
 * @param memberId
 */
export function useDailyOpenInMailLimit(
	orgId: string | undefined,
	memberId: string | undefined
): QueryResponseType<{ allocated: number; limit: number }> {
	const [orgSetting, fetchOrgSetting] = useFetch(LinkedInManager.getLinkedInOrganizationSettings);
	const [memberProfile, fetchMemberProfile] = useFetch(LinkedInManager.getUserLinkedInProfile);
	useEffect(() => {
		if (isNil(orgId) || isNil(memberId)) return;
		Promise.all([fetchOrgSetting(orgId), fetchMemberProfile(orgId, memberId)]);
	}, [orgId, memberId]);

	const isLoading = orgSetting.isFetching || memberProfile.isFetching;
	const error = orgSetting.error ?? memberProfile.error;

	if (isLoading) return { loading: true, error: undefined, data: undefined };
	if (error) return { loading: false, error, data: undefined };
	return {
		loading: false,
		error: undefined,
		data: {
			allocated: memberProfile.data?.dailyOpenInMailAllocated ?? 0,
			limit: orgSetting.data?.dailyOpenInMailPerUser ?? 0,
		},
	};
}
