import BaseDataManager from '@copilot/data/managers/base';
import { AccountDto } from '@copilot/data/responses/models/account';

class AccountManager extends BaseDataManager {
	constructor() {
		super();
		this.getCurrentAccount = this.getCurrentAccount.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.deleteAccount = this.deleteAccount.bind(this);
	}

	/**
	 * Get current account
	 */
	public async getCurrentAccount() {
		const url = this.combineRoute(this.BACKEND_ROUTES.ACCOUNT_FEATURE.Default, 'current');
		const response = await this.RequestManager.get<AccountDto>(url);
		return response.data;
	}

	/**
	 * Delete Account
	 * @param {string} id The id of the account
	 */
	public async deleteAccount(id: string) {
		const url = this.combineRoute(this.BACKEND_ROUTES.ACCOUNT_FEATURE.Default, id);
		const response = await this.RequestManager.delete(url);
		return response.data;
	}

	/**
	 * Delete User
	 * @param {string} id The id of the user
	 */
	public async deleteUser(id: string) {
		const url = this.combineRoute(this.BACKEND_ROUTES.ACCOUNT_FEATURE.Default, 'user', id);
		const response = await this.RequestManager.delete(url);
		return response.data;
	}
}

const instance = new AccountManager();
export default instance;
