import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Row, Popover, Cascader, Tag, theme } from 'antd';
import styled from 'styled-components';
import { ConnectionsFilterRequestModel, FilterModel } from '@copilot/data/requests/models';
import {
	createBooleanFilterRequest,
	createBooleanFilterButton,
	getFilterModel,
	initAllLocations,
	addVisibilty,
	createBooleanExcludeFilterRequest,
} from '@copilot/common/utils/filters';
import DateRangeSelector from '@copilot/common/components/forms/common/generics/daterangeselector';
import locations from '@copilot/common/data/locations';
import { CascadeOptionWrapper } from '@copilot/common/components/forms/common/generics/locationcascader';
import { AvailableFilters, CommonFilters } from '@copilot/common/utils/constant';
import BooleanItem from '@copilot/common/components/filters/booleanItem';
import { disableFutureDates, locationSearch } from '@copilot/common/utils';
import { StyledFilterColumn } from '@copilot/common/components/filters/tables';
import FilterDropdown from '@copilot/common/components/filters/filterDropdown';
import moment from 'moment';
import { IFilterTemplate } from '@copilot/common/store/models/redux';
import FilterList from '@copilot/common/components/filters/list';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import FilterItem, { IFilterItemProps } from '@copilot/common/components/filters/item';
import cloneDeep from 'lodash/cloneDeep';

const { useToken } = theme;
const TagStyle = styled(Tag)`
	&& {
		font-size: 0.85em;
	}
`;

const DATE_FORMAT = 'MMM D YYYY';

//default date range of past 1 month
const DEFAULT_DATE_RANGE = [
	moment().subtract(1, 'month').format(DATE_FORMAT),
	moment().format(DATE_FORMAT),
];
export interface ConnectionFiltersProps {
	setConnectionsFilter: (
		value: React.SetStateAction<Partial<ConnectionsFilterRequestModel>>
	) => void;
	setSelectedTemplate?: (value: React.SetStateAction<string>) => void;
	extraFilterRequest?: Partial<ConnectionsFilterRequestModel>;
	selectedFilterTemplate?: IFilterTemplate | null;
	isResetFiltersOn?: boolean;
}

const locationOptions: CascadeOptionWrapper[] = [];
for (const state of locations.us_states) {
	const stateObj = new CascadeOptionWrapper(state);
	const cityObject = [];
	const cities = (locations.us_cities as TypedObject<string[]>)[stateObj.value];

	for (const city of cities) {
		cityObject.push(new CascadeOptionWrapper(city));
	}
	stateObj.children = cityObject;
	locationOptions.push(stateObj);
}

const createMeetingBookedFilter = (isExclude: boolean, isApplied: boolean) => {
	return new FilterDefinition({
		key: 'meetingBooked',
		label: 'Meetings',
		isExclude,
		isVisible: isApplied,
	});
};

/**
 * Formats the date
 * @param date date to parse and return with DATE_FORMAT
 */
const formatDate = (date: string) => moment(date).format(DATE_FORMAT);

const ConnectionFilters: React.FC<ConnectionFiltersProps> = (props) => {
	const {
		setConnectionsFilter,
		selectedFilterTemplate,
		extraFilterRequest = {},
		isResetFiltersOn = false,
		setSelectedTemplate,
	} = props;
	const { token } = useToken();
	const [showHasPhoneOnly, setShowHasPhoneOnly] = useState<boolean>(false);
	const [showHasEmailOnly, setShowHasEmailOnly] = useState<boolean>(false);
	const [meetingBookedFilter, setMeetingBookedFilter] = useState<FilterDefinition>(
		createMeetingBookedFilter(false, false)
	);
	const [dateRange, setDateRange] = useState<FilterModel>({
		include: [...DEFAULT_DATE_RANGE],
		exclude: [],
	});
	const [location, setLocation] = useState<FilterDefinition[]>(initAllLocations());

	const toggleHasPhone = useCallback(() => {
		setShowHasPhoneOnly((x) => !x);
	}, [showHasPhoneOnly]);

	const toggleHasEmail = useCallback(() => {
		setShowHasEmailOnly((x) => !x);
	}, [showHasEmailOnly]);

	const updateMeetingBookedFilter: IFilterItemProps['updateFilterItem'] = (
		filter,
		isExclude = false,
		isVisible = meetingBookedFilter.isVisible
	) => {
		setMeetingBookedFilter(createMeetingBookedFilter(isExclude, isVisible));
	};

	const [isLocationSelected, setIsLocationSelected] = useState<boolean>(false);

	const isDateRangeSelected = useMemo(() => dateRange?.include.length === 2, [dateRange]);

	React.useEffect(() => {
		if (location === undefined || location.filter((x) => x.isVisible).length === 0) {
			setIsLocationSelected(false);
		} else setIsLocationSelected(true);
	}, [location]);

	React.useEffect(() => {
		const LastThreadActivity: FilterModel = {
			...dateRange,
			include: dateRange.include,
		};
		const connectionFilter: Partial<ConnectionsFilterRequestModel> = {
			Phone: createBooleanFilterRequest(showHasPhoneOnly),
			Email: createBooleanFilterRequest(showHasEmailOnly),
			LastThreadActivity,
			Location: getFilterModel(AvailableFilters.Location, location),
			MeetingBooked: createBooleanExcludeFilterRequest(meetingBookedFilter),
		};
		setConnectionsFilter({
			...extraFilterRequest,
			...connectionFilter,
		});
	}, [
		extraFilterRequest,
		showHasPhoneOnly,
		showHasEmailOnly,
		meetingBookedFilter,
		dateRange,
		location,
	]);

	const resetFilters = useCallback(() => {
		setShowHasEmailOnly(false);
		setShowHasPhoneOnly(false);
		setMeetingBookedFilter(createMeetingBookedFilter(false, false));
		setLocation(initAllLocations());
		setDateRange({ include: [], exclude: [] });
	}, []);

	useEffect(() => {
		if (!isResetFiltersOn) return;
		resetFilters();
	}, [isResetFiltersOn]);

	const handleDateRange = (startDate?: Date, endDate?: Date) => {
		let includeDateRange: string[] = [];
		if (startDate && endDate) {
			includeDateRange = [
				moment(startDate.toDateString()).startOf('day').toISOString(),
				moment(endDate.toDateString()).endOf('day').toISOString(),
			];
		}

		setDateRange({
			include: includeDateRange,
			exclude: [],
		});
	};

	type MomentOrNull = moment.Moment | null;
	const dateRangeIncludeInMoment: [MomentOrNull, MomentOrNull] = useMemo(
		() =>
			dateRange.include.length === 2
				? [moment(dateRange.include[0]), moment(dateRange.include[1])]
				: [null, null],
		[dateRange]
	);

	const dateRangeSelector = (
		<DateRangeSelector
			datePickerProps={{ value: dateRangeIncludeInMoment }}
			onChange={handleDateRange}
			disabledDate={disableFutureDates}
		/>
	);

	const handleLocationAdd = (addedLocation: string[]) => {
		if (addedLocation.length != 2 || !addedLocation[0] || !addedLocation[1]) return;

		const state = addedLocation[0];
		const city = addedLocation[1];
		const updatedLocation = addVisibilty(location, state, city);
		setLocation(updatedLocation);
	};

	const locationSelector = (
		<Cascader
			options={locationOptions}
			placeholder="Please Select State and City"
			onChange={(values) => handleLocationAdd(values.map((value) => value.toString()))}
			style={{ width: '100%' }}
			showSearch={{ filter: locationSearch }}
			allowClear={false}
		/>
	);

	const applyFiltersFromTemplate = (
		filterKey: string,
		filterModel: { include: string[]; exclude: string[] } | undefined
	) => {
		if (!filterModel) return;
		const includeFilter = filterModel.include;
		switch (filterKey) {
			case AvailableFilters.Phone: {
				const hasPhoneOnly = !!includeFilter[0];
				setShowHasPhoneOnly(hasPhoneOnly);
				break;
			}

			case AvailableFilters.Email: {
				const hasEmailOnly = !!includeFilter[0];
				setShowHasEmailOnly(hasEmailOnly);
				break;
			}
			case AvailableFilters.Location: {
				const newLocation = cloneDeep(location);
				newLocation.forEach((f) => {
					f.isVisible = includeFilter.includes(f.label);
				});
				setLocation(newLocation);
				break;
			}
			case AvailableFilters.LastThreadActivity: {
				setDateRange({ include: includeFilter, exclude: [] });
				break;
			}
			case AvailableFilters.MeetingBooked: {
				const isInclude = includeFilter[0] === 'True';
				const isExclude = filterModel.exclude[0] === 'True';
				const isApplied = isInclude || isExclude;
				setMeetingBookedFilter(createMeetingBookedFilter(isExclude, isApplied));
				break;
			}
			default:
				throw new Error(`Failed to apply ${filterKey}`);
		}
	};

	React.useEffect(() => {
		if (!selectedFilterTemplate?.filters || !setSelectedTemplate) return;
		resetFilters();
		const properties = Object.values(selectedFilterTemplate.filters);
		let pointer = 0;
		for (const filterKey in selectedFilterTemplate.filters) {
			if (!CommonFilters.includes(filterKey))
				applyFiltersFromTemplate(filterKey, properties[pointer]);
			pointer++;
		}
		setSelectedTemplate('');
	}, [selectedFilterTemplate?.filters]);

	return (
		<>
			<Row>
				<StyledFilterColumn>
					<Popover
						content={locationSelector}
						trigger="click"
						placement="bottomLeft"
						autoAdjustOverflow
						getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
						overlayStyle={{ minWidth: '300px' }}
					>
						<FilterDropdown isSelected={isLocationSelected} label="Location" />
					</Popover>
					<FilterList filters={location} updateFilterSelector={setLocation} />
				</StyledFilterColumn>
				<StyledFilterColumn>
					<Popover
						content={dateRangeSelector}
						trigger="click"
						placement="bottomLeft"
						autoAdjustOverflow
						overlayStyle={{ minWidth: '300px' }}
					>
						<FilterDropdown isSelected={isDateRangeSelected} label="Date Range" />
					</Popover>
					<Row>
						{isDateRangeSelected && (
							<TagStyle
								color={token.colorPrimary}
								closable
								onClose={() => setDateRange({ include: [], exclude: [] })}
							>
								{`${formatDate(dateRange?.include[0])} to ${formatDate(
									dateRange?.include[1]
								)}`}
							</TagStyle>
						)}
					</Row>
				</StyledFilterColumn>
				<StyledFilterColumn>
					{createBooleanFilterButton(toggleHasEmail, showHasEmailOnly, 'Email')}
					<BooleanItem
						label="Has Email"
						isVisible={showHasEmailOnly}
						onClose={toggleHasEmail}
					/>
				</StyledFilterColumn>
				<StyledFilterColumn>
					{createBooleanFilterButton(toggleHasPhone, showHasPhoneOnly, 'Phone Number')}
					<BooleanItem
						label="Has Phone Number"
						isVisible={showHasPhoneOnly}
						onClose={toggleHasPhone}
					/>
				</StyledFilterColumn>
				<StyledFilterColumn>
					{createBooleanFilterButton(
						() => {
							updateMeetingBookedFilter(
								meetingBookedFilter,
								meetingBookedFilter.isExclude,
								true
							);
						},
						meetingBookedFilter.isVisible,
						'Meetings'
					)}
					{meetingBookedFilter.isVisible ? (
						<FilterItem
							selectedFilter={meetingBookedFilter}
							isExcludable={true}
							updateFilterItem={updateMeetingBookedFilter}
							isVisible={meetingBookedFilter.isVisible}
						/>
					) : null}
				</StyledFilterColumn>
			</Row>
		</>
	);
};

export default ConnectionFilters;
