import { useState, useEffect, useCallback, useMemo, useContext, ComponentProps } from 'react';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import { useDispatch, useSelector } from 'react-redux';
import {
	ScheduleHierarchyType,
	SYSTEM_DEFAULT_SCHEDULE,
} from '@copilot/common/pages/settings/schedule/constant';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { CampaignMemberSelectors } from '@copilot/common/store/selectors/campaignMember';
import { CampaignApprovalStatus, CampaignType } from '@copilot/data/responses/interface';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useHistory } from 'react-router';
import { History } from 'history';
import { ThemeContext } from 'styled-components';
import StatusFormatter, {
	StatusFormatIconType,
} from '@copilot/common/components/common/statusFormat';
import { ServiceScheduleAction } from '@copilot/common/store/actions/serviceSchedule';
import { ScheduleUtility } from '@copilot/common/utils/schedule';
import { useFetch } from '../common';
import {
	CampaignManager,
	LinkedInManager,
	NurtureOnboardManager,
} from '../../../../core/data/managers';
import { CampaignStatusEnum } from '@copilot/data/requests/models';
import { withNotification } from '@copilot/common/hoc/utils';
import { NotificationContentType } from '@copilot/common/constant/notificationContent';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { OrganizationType } from '@copilot/common/store/models/const/enum';
import { showNotification } from '@copilot/common/store/actionCreators/notification';
import { getCampaignToggleNotification } from '@copilot/common/components/notificationDescription/const/campaignToggle';
import { useOpenUsageLimitsDrawerTracking } from '@copilot/common/components/drawer/wrappers/usageLimits/tracking';
import modalManager from '@copilot/common/utils/modalManager';
import { ConflictStatusCode } from '@copilot/data/responses/statusCodes';
import { getCampaignLaunchErrorElement } from '@copilot/common/components/modals/wrappers/campaignLaunchError/errorElement';
import { Campaign, CampaignStatus, CampaignStatuses } from './campaignTypes';

function campaignStatusEnumToConst(status: CampaignStatusEnum): CampaignStatus {
	switch (status) {
		case CampaignStatusEnum.Enabled:
			return CampaignStatuses.Enabled;
		case CampaignStatusEnum.Disabled:
			return CampaignStatuses.Disabled;
		default:
			return CampaignStatuses.Unknown;
	}
}

/**
 * Fetches campaigns
 */
export function useCampaigns(): ReadonlyArray<Campaign> {
	const campaigns = useSelector(CampaignSelectors.getCampaigns);
	return campaigns.map((campaign) => ({
		id: campaign.id,
		name: campaign.name,
		type: campaign.type,
		status: campaignStatusEnumToConst(campaign.status),
	}));
}

export function useCreateCampaign() {
	const [, createCampaign] = useFetch(
		CampaignManager.createCampaign,
		CampaignActions.loadCampaigns,
		(r) => [r]
	);

	const history = useHistory();

	return useCallback(
		async (name: string, type: CampaignType) => {
			try {
				const campaign = await createCampaign(name, type);
				notificationManager.showSuccessNotification({
					message: 'Campaign created',
				});
				try {
					await CampaignManager.createSchedule(campaign.id, {
						...SYSTEM_DEFAULT_SCHEDULE,
						timezoneCode: ScheduleUtility.getLocaleTimezone(),
						ownerId: campaign.id,
						hierarchyType: ScheduleHierarchyType.Campaign,
					});
				} catch {
					console.warn('Failed to create schedule when creating campaign');
				}
				history.push(`/campaign/${campaign.id}`);
			} catch {
				notificationManager.showErrorNotification({
					message: 'Failed to create campaign',
					description: 'Please refresh the page and try again',
				});
			}
		},
		[createCampaign]
	);
}

export function useCreateCampaignForIndividual(redirect = true) {
	const [, createCampaign] = useFetch(
		CampaignManager.createCampaignForIndividual,
		CampaignActions.loadCampaigns,
		(r) => [r]
	);
	const [, createSchedule] = useFetch(
		CampaignManager.createSchedule,
		ServiceScheduleAction.loadSerivceSchedule
	);
	const history = useHistory();
	return useCallback(
		async (name: string, type: CampaignType, orgMemberId?: string, isOnboarded?: boolean) => {
			try {
				isOnboarded = isOnboarded ?? true;
				const campaign = await createCampaign(name, type, orgMemberId, isOnboarded);
				notificationManager.showSuccessNotification({
					message: 'Campaign created',
				});
				await createSchedule(campaign.id, {
					...SYSTEM_DEFAULT_SCHEDULE,
					timezoneCode: ScheduleUtility.getLocaleTimezone(),
					ownerId: campaign.id,
					hierarchyType: ScheduleHierarchyType.Campaign,
				}).catch(() => console.warn('Failed to create schedule when creating campaign'));
				if (redirect) history.push(`/campaign/${campaign.id}`);
			} catch {
				notificationManager.showErrorNotification({
					message: 'Failed to create campaign',
					description: 'Please refresh the page and try again',
				});
			}
		},
		[createCampaign]
	);
}

export const useCampaignRedirectToAllocate = () => {
	const history: History = useHistory();
	const updateOpenUsageLimitsDrawerTracking = useOpenUsageLimitsDrawerTracking(
		'Open Usage Limits Drawer',
		null
	);

	return (campaignId: string, campaignType: CampaignType) => {
		updateOpenUsageLimitsDrawerTracking({ buttonClicked: 'Campaign Toggle Notification' });
		history.push(`/campaign/${campaignId}`, { usageLimitsDrawer: campaignType });
	};
};

export function useCampaignActiveToggle(
	campaignId: string
): [CampaignStatusEnum, (value: boolean) => Promise<void>] {
	const dispatch = useDispatch();
	const [campaignStatus, setCampaignStatus] = useState<CampaignStatusEnum>(
		CampaignStatusEnum.Disabled
	);
	const campaign = useSelector(CampaignSelectors.getCampaign(campaignId));
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	const activeCampaignMember = useSelector(
		CampaignMemberSelectors.getByCampaignAndOrgMember(activeMember?.id ?? '', campaignId)
	);
	const redirectToAllocate = useCampaignRedirectToAllocate();
	const [, toggleActive] = useFetch(CampaignManager.toggleCampaignStatus);

	useEffect(() => {
		setCampaignStatus(campaign?.status ?? CampaignStatusEnum.Disabled);
	}, []);

	const handleToggle = useCallback(
		async (event: boolean) => {
			if (!campaign || !activeOrganization) return;
			const status = CampaignManager.convertBooltoStatus(event);
			try {
				await toggleActive(campaignId, status);
				dispatch(CampaignActions.updateCampaign({ id: campaignId, status }));
				dispatch(
					showNotification(
						// use OrganizationType.Enterprise Notifications for CS Dash (hides CTA for allocations)
						getCampaignToggleNotification(
							'SUCCESS',
							true,
							status,
							campaign.type,
							activeAdmin ? OrganizationType.Enterprise : activeOrganization.orgType
						),
						() => redirectToAllocate(campaignId, campaign.type)
					)
				);
				if (
					!!activeAdmin &&
					!!activeCampaignMember?.id &&
					activeOrganization.orgType === OrganizationType.Individual &&
					campaign.type === CampaignType.Nurture &&
					status === CampaignStatusEnum.Enabled
				) {
					await NurtureOnboardManager.setIsOnboardingComplete(activeCampaignMember.id);
				}
			} catch (error: any) {
				if (error.status === ConflictStatusCode) {
					const reasons = error.data.map(getCampaignLaunchErrorElement);
					modalManager.openCampaignLaunchErrorModal({ issues: reasons });
				} else {
					dispatch(
						showNotification(
							// use OrganizationType.Enterprise Notifications for CS Dash (hides CTA for allocations)
							getCampaignToggleNotification(
								'ERROR',
								false,
								status,
								campaign.type,
								activeAdmin
									? OrganizationType.Enterprise
									: activeOrganization.orgType
							),
							() => redirectToAllocate(campaignId, campaign.type)
						)
					);
				}
			}
		},
		[campaignId, campaign, activeOrganization, activeAdmin, activeCampaignMember]
	);

	return [campaignStatus, handleToggle];
}
/**
 * Format campaign approval status from enum to customer facing string with an associated icon
 */
export function useApprovalStatusFormatter(
	iconSize: string,
	status: CampaignApprovalStatus,
	customText?: string | null,
	alignment?: ComponentProps<typeof StatusFormatter>['alignment']
) {
	const themeContext = useContext(ThemeContext);

	const formatter = useMemo(() => {
		switch (status) {
			case CampaignApprovalStatus.Disconnected:
				return (
					<StatusFormatter
						iconSize={iconSize}
						iconType={StatusFormatIconType.MinusCircle}
						color={themeContext['@info-color']}
						text={customText ?? 'Deactivated'}
						alignment={alignment}
					/>
				);
			case CampaignApprovalStatus.Rejected:
				return (
					<StatusFormatter
						iconSize={iconSize}
						iconType={StatusFormatIconType.CloseCircle}
						color={themeContext['@error-color']}
						text={customText ?? 'Rejected'}
						alignment={alignment}
					/>
				);
			case CampaignApprovalStatus.Waiting:
				return (
					<StatusFormatter
						iconSize={iconSize}
						iconType={StatusFormatIconType.Lock}
						color={themeContext['@info-color']}
						text={customText ?? 'Meeting Booked'}
						alignment={alignment}
					/>
				);
			case CampaignApprovalStatus.Incomplete:
				return (
					<StatusFormatter
						iconSize={iconSize}
						iconType={StatusFormatIconType.Edit}
						color={themeContext['@info-color']}
						text={customText ?? 'Incomplete'}
						alignment={alignment}
					/>
				);
			default:
			case CampaignApprovalStatus.Approved:
				return (
					<StatusFormatter
						iconSize={iconSize}
						iconType={StatusFormatIconType.CheckCircle}
						color={themeContext['@success-color']}
						text={customText ?? 'Approved'}
						alignment={alignment}
					/>
				);
		}
	}, [status, iconSize, customText]);
	return formatter;
}
/**
 * Format campaign status from enum to customer facing string with an associated icon
 */
export function useStatusFormatter(iconSize: string, status: CampaignStatusEnum) {
	const themeContext = useContext(ThemeContext);

	const formatter = useMemo(() => {
		switch (status) {
			case CampaignStatusEnum.Disabled:
				return (
					<StatusFormatter
						iconSize={iconSize}
						iconType={StatusFormatIconType.CloseCircle}
						color={themeContext['@error-color']}
						text="Disabled"
					/>
				);
			default:
			case CampaignStatusEnum.Enabled:
				return (
					<StatusFormatter
						iconSize={iconSize}
						iconType={StatusFormatIconType.CheckCircle}
						color={themeContext['@success-color']}
						text="Enabled"
					/>
				);
		}
	}, [status, iconSize]);

	return formatter;
}

/**
 * Terminate Automation on connections in campaign
 * @param campaignId campaignId of connections
 * @param notificationContent notification content to display after termination
 * @param campaignMemberId campaignMemberId connections belong to
 */
export function useTerminateAutomation(
	campaignId: string,
	notificationContent: NotificationContentType,
	campaignMemberId?: string
) {
	const isAdmin = !!useSelector(OrganizationMemberSelectors.getAdminMember);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const orgMemberId = activeMember?.id;
	const isTeamsOrgAdmin =
		activeMember?.orgType === OrganizationType.Enterprise && activeMember.isOrgAdmin;
	const omid = useMemo(
		() => (isAdmin && orgMemberId ? orgMemberId : null),
		[isAdmin, orgMemberId]
	);

	const terminateConnectionWithNotification = withNotification(
		LinkedInManager.terminateMessageAutomationByConnections,
		notificationContent.Success,
		notificationContent.Fail
	);

	const adminTerminateConnectionWithNotification = withNotification(
		LinkedInManager.terminateMessageAutomationByAdmin,
		notificationContent.Success,
		notificationContent.Fail
	);

	const fetchTerminate = async (connectionsIds: string[]) => {
		if (isAdmin || isTeamsOrgAdmin) {
			await adminTerminateConnectionWithNotification(campaignId, connectionsIds, omid);
		} else if (campaignMemberId)
			await terminateConnectionWithNotification(
				campaignId,
				campaignMemberId,
				connectionsIds,
				omid
			);
	};

	return fetchTerminate;
}
