//Generic type for PaginatedResponses
export type PaginatedResponseV2<T> = {
	content: T[];
} & PageInfo;

export type PageInfo = {
	count: number;
	pages: {
		current: number;
		total: number;
	};
};

export const EMPTY_PAGE_INFO: PageInfo = {
	pages: { current: 0, total: 0 },
	count: 0,
};

export const EMPTY_PAGINATION_RESPONSE: PaginatedResponseV2<any> = {
	content: [],
	...EMPTY_PAGE_INFO,
};
