import { IPermissions, PermissionName } from '../interface';
import { FullPermission, PermissionFlag } from '../flags';

export const BasePermissions: IPermissions = {
	[PermissionName.TeamMembers]: PermissionFlag.None,
	[PermissionName.OwnTeamMembers]: PermissionFlag.None,
	[PermissionName.SearchList]: PermissionFlag.None,
	[PermissionName.OwnSearchList]: PermissionFlag.None,
	[PermissionName.Messaging]: PermissionFlag.None,
	[PermissionName.MessagingSequence]: PermissionFlag.None,
	[PermissionName.SendingHours]: PermissionFlag.None,
	[PermissionName.Settings]: PermissionFlag.None,
};

export const IndividualUserPermissions: IPermissions = {
	...BasePermissions,
	[PermissionName.OwnSearchList]: PermissionFlag.View,
	[PermissionName.Messaging]: FullPermission,
	[PermissionName.SendingHours]: FullPermission,
	[PermissionName.Settings]: FullPermission,
};

export const AdvancedIndividualUserPermissions: IPermissions = {
	...IndividualUserPermissions,
	[PermissionName.OwnSearchList]: FullPermission,
};

export const BaseTeamUserPermissions: IPermissions = {
	...BasePermissions,
	[PermissionName.OwnSearchList]: PermissionFlag.View,
	[PermissionName.SendingHours]: PermissionFlag.View,
};

export const AdvancedTeamUserPermissions: IPermissions = {
	...BaseTeamUserPermissions,
	[PermissionName.OwnTeamMembers]: FullPermission,
	[PermissionName.OwnSearchList]: FullPermission,
	[PermissionName.Messaging]: FullPermission,
	[PermissionName.SendingHours]: FullPermission,
	[PermissionName.Settings]: FullPermission,
};

export const AdminTeamUserPermissions: IPermissions = {
	...AdvancedTeamUserPermissions,
	[PermissionName.TeamMembers]: FullPermission,
	[PermissionName.SearchList]: FullPermission,
	[PermissionName.MessagingSequence]: FullPermission,
};
