import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { useFetch, useSearchParams } from '@copilot/common/hooks/common';
import { OrganizationManager } from '@copilot/data';
import { OrganizationActions } from '@copilot/common/store/actions/organization';

import BasicContainer from '@copilot/common/components/containers/basic';
import {
	OrganizationDashboardTabKeys,
	OrganizationDashboardTabLabels,
} from '@copilot/common/utils/constant';
import ConnectionsPage from '@copilot/common/pages/connections';
import OrganizationNotificationSettings from '@copilot/common/pages/organizationDashboard/notification';
import TemplateMessages from '@copilot/common/pages/settings/template';
import OrganizationDashboardSettings from '@copilot/common/pages/organizationDashboard/settings';
import OrganizationDashboardAllTeamMembers from '@copilot/common/pages/organizationDashboard/orgTeamMembers';
import OrganizationDashboardSummary from '@copilot/common/pages/organizationDashboard/summary';
import HeaderContentLayout from '@copilot/common/pages/layouts/headerContent';
import OrganizationDashboardHeader from '@copilot/common/pages/organizationDashboard/header';
import { useFetchOrgDashAttentionItems } from '@copilot/common/hooks/attentionItem';
import { CSTeamsInboxContainer } from '@copilot/common/pages/inbox/ui/container';

interface OrganizationDashboardSearchParams {
	tab: string;
}

const TeamDashboard: React.FC<{}> = () => {
	const [, renameOrganization] = useFetch(
		OrganizationManager.renameOrganization,
		OrganizationActions.loadOrganization
	);
	const [searchParams, setSearchParams] = useSearchParams<OrganizationDashboardSearchParams>({
		tab: OrganizationDashboardTabKeys.Summary,
	});
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	if (!activeMember) return null;
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const organizationId = activeOrganization ? activeOrganization.id : '';
	const handleTabChange = React.useCallback(
		(key: string) => {
			setSearchParams({ tab: key });
		},
		[setSearchParams]
	);

	useFetchOrgDashAttentionItems(organizationId, searchParams.tab);

	const handleNameUpdate = useCallback(
		(name: string) => activeOrganization?.id && renameOrganization(activeOrganization.id, name),
		[organizationId]
	);
	if (!organizationId) return null;
	return (
		<HeaderContentLayout>
			<HeaderContentLayout.Header>
				<h2>
					<OrganizationDashboardHeader
						organizationName={activeOrganization ? activeOrganization.name : ''}
						onUpdate={handleNameUpdate}
					/>
				</h2>
			</HeaderContentLayout.Header>
			<HeaderContentLayout.Tabs activeKey={searchParams.tab} onChange={handleTabChange}>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.Summary}
					key={OrganizationDashboardTabKeys.Summary}
				>
					<OrganizationDashboardSummary organizationId={organizationId} />
				</HeaderContentLayout.TabPanes>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.Inbox}
					key={OrganizationDashboardTabKeys.Inbox}
				>
					<CSTeamsInboxContainer />
				</HeaderContentLayout.TabPanes>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.TeamMembers}
					key={OrganizationDashboardTabKeys.TeamMembers}
				>
					<OrganizationDashboardAllTeamMembers organizationId={organizationId} />
				</HeaderContentLayout.TabPanes>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.Connection}
					key={OrganizationDashboardTabKeys.Connection}
				>
					<ConnectionsPage />
				</HeaderContentLayout.TabPanes>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.Settings}
					key={OrganizationDashboardTabKeys.Settings}
				>
					<OrganizationDashboardSettings organizationId={organizationId} />
				</HeaderContentLayout.TabPanes>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.Template}
					key={OrganizationDashboardTabKeys.Template}
				>
					<BasicContainer>
						<BasicContainer.Header>Quick Responses</BasicContainer.Header>
						<BasicContainer.Content>
							<TemplateMessages user={activeMember} showCampaigns hideHeader />
						</BasicContainer.Content>
					</BasicContainer>
				</HeaderContentLayout.TabPanes>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.Notification}
					key={OrganizationDashboardTabKeys.Notification}
				>
					<OrganizationNotificationSettings organizationId={organizationId} />
				</HeaderContentLayout.TabPanes>
			</HeaderContentLayout.Tabs>
		</HeaderContentLayout>
	);
};

export default TeamDashboard;
