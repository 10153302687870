import { LoadMorePaginatedResponse } from '../interface';

export class LoadMorePaginatedResultModel<T, C> {
	public offset: number;
	public pageSize: number;
	public results: C[];
	public hasMore: boolean;

	constructor(
		result: LoadMorePaginatedResponse<T> | null,
		ClassModel: { new (...args: any[]): C }
	) {
		this.offset = result ? result.offset : 0;
		this.pageSize = result ? result.pageSize : 0;
		this.results = result?.results ? result.results.map((r) => new ClassModel(r)) : [];
		this.hasMore = result ? result.hasMore : false;
	}
}
