import React from 'react';
import PasswordUpdateForm from '@copilot/common/components/forms/user/passwordUpdate';
import BaseModal, { BaseModalProps } from '../base';
import { AuthenticationManager } from '@copilot/data';
import notificationManager from '@copilot/common/utils/notificationManager';

interface ChangePasswordProps extends BaseModalProps {}

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
	const { ...modalProps } = props;

	const updatePassword = React.useCallback<
		React.ComponentProps<typeof PasswordUpdateForm>['onSubmit']
	>((values) => {
		AuthenticationManager.setPassword(
			values.oldPassword,
			values.newPassword,
			values.confirmPassword
		)
			.then(() =>
				notificationManager.showSuccessNotification({
					message: 'Saved',
					description: 'Your password has been updated.',
				})
			)
			.catch(() => {
				notificationManager.showErrorNotification({
					message: 'Failed',
					description: 'Please refresh the page and try again',
				});
			});
	}, []);
	return (
		<BaseModal {...modalProps}>
			<PasswordUpdateForm onSubmit={updatePassword} />
		</BaseModal>
	);
};

export default ChangePassword;
