import * as React from 'react';
import { Col, Row, Input, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import styled from 'styled-components';

import BaseColumn from './base';
import { ColumnDefinition } from '../../componentModels/columnDefinition';

const convertToValue = (values: string[]) => values.join(' ');

const FilterRow = styled(Row)`
	background: #ffffff;
	border-radius: 8px;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
	padding: 8px;
`;

export class StringColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];
	public sorter: ColumnProps<M>['sorter'];
	public filterDropdown: ColumnProps<M>['filterDropdown'];
	public filterIcon: ColumnProps<M>['filterIcon'];
	public onFilter: ColumnProps<M>['onFilter'];
	public onFilterDropdownVisibleChange: ColumnProps<M>['onFilterDropdownVisibleChange'];
	private searchInput: React.RefObject<any> = React.createRef();

	constructor(c: ColumnDefinition) {
		super(c);
		this.render = (text: string, record: M) => {
			const { properties = [] } = c;
			const values = convertToValue(this.getValues(properties, record));

			return <span>{values}</span>;
		};
		// this.sorter = (a: M, b: M) => {
		// 	let { properties = [] } = c;
		// 	let aString = properties.map((p) => (a as any)[p]).join(' ');
		// 	let bString = properties.map((p) => (b as any)[p]).join(' ');
		// 	return aString.localeCompare(bString);
		// };
		this.sorter = c.sort;

		if (c.filter) {
			this.filterDropdown = ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}: any) => (
				<FilterRow gutter={16}>
					<Col>
						<Input
							ref={this.searchInput}
							placeholder={`Search ${this.title}`}
							value={selectedKeys[0]}
							onChange={(e) =>
								setSelectedKeys(e.target.value ? [e.target.value] : [])
							}
							onPressEnter={confirm}
						/>
					</Col>
					<Col>
						<Button type="primary" onClick={confirm}>
							Search
						</Button>
					</Col>
					<Col>
						<Button onClick={clearFilters}>Reset</Button>
					</Col>
				</FilterRow>
			);
			this.onFilter = (value: string | number | boolean, record: M) => {
				if (typeof value !== 'string') return false;
				else
					return convertToValue(this.getValues(c.properties, record))
						.toLowerCase()
						.includes(value.toLowerCase());
			};
			this.onFilterDropdownVisibleChange = (visible: boolean) => {
				visible && setTimeout(() => this.searchInput.current.focus());
			};
		}
	}
}
