import Store from '..';
import { createSelector, Ref, SessionBoundModel, Selector, OrmState } from 'redux-orm';
import { CampaignApprovalStatus, CampaignType } from '@copilot/data/responses/interface';
import { Campaign } from '../models/redux';

export type CampaignModel = SessionBoundModel<Campaign, {}>;
type CampaignRef = Ref<SessionBoundModel<Campaign, {}>>;
export namespace CampaignSelectors {
	export const getCampaign = (id: string): Selector<any, CampaignRef | undefined> =>
		createSelector(Store.ORM, (session) => session.Campaign.withId(id)?.ref);

	export const getCampaigns = createSelector(Store.ORM, (session) =>
		session.Campaign.all().toRefArray()
	);

	export const getCampaignsWithMembers = createSelector(
		Store.ORM,
		(state: typeof Store.State) => state.entities.CampaignMember,
		(session) => session.Campaign.all().toModelArray()
	);

	export const getCampaignsByOrgId = createSelector(
		Store.ORM,
		(_, organizationId) => organizationId,
		(session, organizationId) => session.Campaign.filter({ organizationId }).toRefArray()
	);

	export const getApprovedCampaigns = createSelector(Store.ORM, (session) =>
		session.Campaign.all()
			.exclude({ approvalStatus: CampaignApprovalStatus.Disconnected })
			.toRefArray()
	);

	/**
	 * Select campaigns by campaign type and orgMember
	 * @param {CampaignType} type campaign type
	 * @param {string} omid orgMemberId that is part of a campaign
	 */
	export const getCampaignsByTypeAndOrgMember =
		(type: CampaignType, omid: string) =>
		(state: { entities: OrmState<any> }): CampaignModel[] => {
			const campaigns = Store.ORM.session(state.entities).Campaign;
			return campaigns
				.all()
				.toModelArray()
				.filter(
					(c) =>
						c.type === type &&
						c.members.toModelArray().filter((member) => member.orgMemberId === omid)
							.length != 0
				);
		};

	/**
	 * Select campaigns by campaign types
	 * @param {CampaignType[]} type campaign types
	 */
	export const getCampaignsByType =
		(types: CampaignType[]) =>
		(state: { entities: OrmState<any> }): CampaignModel[] => {
			const campaigns = Store.ORM.session(state.entities).Campaign;
			const campaignMap = new Set(types);
			return campaigns
				.all()
				.toModelArray()
				.filter((c) => campaignMap.has(c.type));
		};

	/**
	 * Select approved prospecting campaigns
	 */
	export const getApprovedCampaignModels = createSelector(
		Store.ORM,
		(state: typeof Store.State) => state.entities.CampaignMember,
		(session) =>
			session.Campaign.all()
				.filter({
					approvalStatus: CampaignApprovalStatus.Approved,
				})
				.toModelArray()
	);

	/**
	 * Select first incomplete campaign
	 */
	export const getFirstIncompleteCampaign = createSelector(
		Store.ORM,
		(state: typeof Store.State) => state.entities.CampaignMember,
		(session) =>
			session.Campaign.all()
				.filter({
					approvalStatus: CampaignApprovalStatus.Incomplete,
				})
				.first()
	);
}
