import { BaseModel } from './base';
import { attr } from 'redux-orm';
import { ConnectionsFilterRequestModel } from '@copilot/data/requests/models';

export interface IFilterTemplate {
	id: string;
	ownerId: string;
	name: string;
	filters: Partial<ConnectionsFilterRequestModel>;
}

export class FilterTemplate extends BaseModel<typeof FilterTemplate, IFilterTemplate> {
	static modelName = 'FilterTemplate';
	static fields = {
		id: attr(),
		ownerId: attr(),
		name: attr(),
		filters: attr(),
	};
}
