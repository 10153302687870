import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Popover } from 'antd';
import Selector from '../selector';
import { FilterDefinition } from '../componentModels/filterDefinition';
import FilterDropdown from '@copilot/common/components/filters/filterDropdown';

export interface DataFilterProps {
	filters: FilterDefinition[];
	updateFilters: (filters: FilterDefinition[]) => void;
	title: string;
}

const DataFilter: React.FC<DataFilterProps> = (props) => {
	const { filters, updateFilters, title } = props;
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const selectorRef = useRef<any>(null);
	const [isFilterOn, setIsFilterOn] = useState<boolean>(false);

	const enabledFilters = useMemo(() => filters.filter((f) => f.isVisible), [filters]);
	useEffect(() => {
		if (enabledFilters.length === 0) setIsFilterOn(false);
	}, [enabledFilters]);

	useEffect(() => {
		if (selectorRef.current) selectorRef.current.resetFields();
	}, [filters]);

	const handleCancel = () => {
		setIsVisible(false);
		selectorRef.current.resetFields();
	};

	const handleVisibleChange = (visible = true) => {
		setIsVisible(visible);
		if (!visible) selectorRef.current.resetFields();
	};

	const updateData = (updatedfilters?: DataFilterProps['filters']) => {
		if (!updatedfilters) return;
		setIsFilterOn(true);
		setIsVisible(false);
		updateFilters(updatedfilters);
		selectorRef.current.resetFields();
	};

	const selector = (
		<Selector
			ref={selectorRef}
			columns={[]}
			filters={filters}
			updateData={updateData}
			cancel={handleCancel}
			showSelectAll
		/>
	);
	return (
		<>
			<Popover
				content={selector}
				trigger="click"
				onVisibleChange={handleVisibleChange}
				placement="bottomLeft"
				visible={isVisible}
				autoAdjustOverflow={false}
			>
				<FilterDropdown
					isSelected={isFilterOn}
					label={title}
					onClick={() => setIsVisible(true)}
				/>
			</Popover>
		</>
	);
};

export default DataFilter;
