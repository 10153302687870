import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Form, InputNumber, Button, Alert } from 'antd';
import ActiveToggle from '@copilot/common/components/toggles/campaign/active';
import {
	DRAWER_SAVE_USER_INVITES_ALLOCATION_TRACKING_ID,
	DRAWER_SAVE_USER_MESSAGES_ALLOCATION_TRACKING_ID,
} from '@copilot/common/tracking/userpilotEventConsts';

interface CampaignMemberSettingsProps {
	dailySendItem: string;
	initialStatus: boolean;
	initDailySendValue: number;
	campaignMemberMaxDailySend: number;
	isSendSettingDisabled: boolean;
	alertMessage?: string;
	handleSettingsSave: (status: number, dailySend: number) => void;
}

const CampaignMemberSettings: React.FC<CampaignMemberSettingsProps> = (props) => {
	const {
		dailySendItem,
		initialStatus,
		initDailySendValue,
		campaignMemberMaxDailySend,
		isSendSettingDisabled,
		alertMessage,
		handleSettingsSave,
	} = props;

	const [campaignMemberDailySend, setCampaignMemberDailySend] = useState<number>(0);
	const [form] = Form.useForm();
	useEffect(() => {
		setCampaignMemberDailySend(initDailySendValue);
		form.setFieldsValue({
			[`number-of-${dailySendItem}`]: initDailySendValue,
			'campaign-status': initialStatus,
		});
	}, [initDailySendValue, initialStatus, dailySendItem]);

	const handleFinish = useCallback(
		(values) => {
			const status = ~~values['campaign-status'];
			const newDailySend = values[`number-of-${dailySendItem}`] as number;
			handleSettingsSave(status, newDailySend);
		},
		[handleSettingsSave, dailySendItem]
	);

	const dailySendInputLabel = useMemo(() => {
		const dailySendsRemaining = campaignMemberMaxDailySend - campaignMemberDailySend;
		return (
			<span>
				{`Number of ${dailySendItem} a day `}
				<strong>
					({dailySendsRemaining > 0 ? dailySendsRemaining : 0}
					{` ${dailySendItem} `}
					left for allocation)
				</strong>
			</span>
		);
	}, [dailySendItem, campaignMemberDailySend, campaignMemberMaxDailySend]);

	const maxDailySendModified = useMemo(
		() => (campaignMemberMaxDailySend < 0 ? 0 : campaignMemberMaxDailySend),
		[campaignMemberMaxDailySend]
	);

	function getDataTrackingID() {
		switch (dailySendItem) {
			case 'messages':
				return DRAWER_SAVE_USER_MESSAGES_ALLOCATION_TRACKING_ID;
			case 'invites':
				return DRAWER_SAVE_USER_INVITES_ALLOCATION_TRACKING_ID;
			default:
				return '';
		}
	}

	return (
		<Form form={form} onFinish={handleFinish}>
			{alertMessage && (
				<Alert
					message={alertMessage}
					type="warning"
					showIcon
					style={{ marginBottom: '1em' }}
				/>
			)}
			<Form.Item
				name={`number-of-${dailySendItem}`}
				label={dailySendInputLabel}
				style={{ display: 'block' }}
				rules={[
					{
						type: 'integer',
						min: 0,
						max: maxDailySendModified,
						message: `Must be a number between 0 and ${maxDailySendModified}`,
					},
				]}
			>
				<InputNumber
					onChange={(event) => {
						const messages = (typeof event === 'string' ? parseInt(event) : event) ?? 0;
						if (messages >= 0) {
							setCampaignMemberDailySend(messages);
						}
					}}
					disabled={isSendSettingDisabled}
				/>
			</Form.Item>
			<Form.Item
				name="campaign-status"
				label="Team Member Status"
				style={{ display: 'block' }}
				rules={[]}
				valuePropName="checked"
			>
				<ActiveToggle />
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit" data-tracking-id={getDataTrackingID()}>
					Save
				</Button>
			</Form.Item>
		</Form>
	);
};

export default CampaignMemberSettings;
