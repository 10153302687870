import BaseModal, { BaseModalProps } from '../base';
import {
	fontSizeMD,
	fontWeightNormal,
	spacingLG,
	spacingMD,
} from '@copilot/common/constant/commonStyles';
import { LockFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import moment from 'moment';
import { UnitOfTime } from '@copilot/common/utils/time';

function OutOfCreditModal(props: BaseModalProps) {
	const beginningOfNextMonth = moment()
		.add(1, UnitOfTime.month)
		.startOf(UnitOfTime.month)
		.format('MMMM D');

	return (
		<BaseModal {...props} width={536}>
			<div style={{ textAlign: 'center' }}>
				<LockFilled
					style={{ fontSize: '50px', marginTop: spacingLG, marginBottom: spacingLG }}
				/>

				<Typography.Title level={4} style={{ marginBottom: spacingMD }}>
					You're out of credits for this month
				</Typography.Title>

				<Typography.Text
					style={{
						fontWeight: fontWeightNormal,
						fontSize: fontSizeMD,
						marginBottom: spacingLG,
					}}
				>
					Your credits will automatically renew on{' '}
					<strong>{beginningOfNextMonth}.</strong>
				</Typography.Text>
			</div>
		</BaseModal>
	);
}

export default OutOfCreditModal;
