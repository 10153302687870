import { InboxManager } from '@copilot/data';
import {
	FilterModel,
	InboxDisplayType,
	InboxFilterRequestModel,
} from '@copilot/data/requests/models';
import { LinkedInMessageType } from '@copilot/data/responses/interface';
import isEmpty from 'lodash/isEmpty';
import { InboxMessageFilters } from '../ui/types';
import { filterThenMapElements } from '@copilot/common/utils/common/array';
import { InboxVersion } from '@copilot/common/pages/inbox/data/types';
import { INBOX_VERSIONS, SORT_BY_FIELDS } from '@copilot/common/pages/inbox/data/constant';

/**
 * Get the inbox counts based on filters, viewType and orgId
 * @param orgId
 * @param viewType
 * @param filters
 * @returns
 */
export function getInboxCount(
	orgId: string | undefined,
	viewType: InboxDisplayType,
	filters: Partial<InboxFilterRequestModel>
) {
	if (isEmpty(filters.ReminderStatus?.include))
		return InboxManager.getMessageCountByCategory(orgId, viewType, filters);
	return InboxManager.getMessageCounts(filters);
}

/**
 * Build the InboxFilterModel from filters
 * @param filter
 */
export const convertFilterToRequestModel = (
	filter: InboxMessageFilters,
	viewType: InboxDisplayType
): Partial<InboxFilterRequestModel> => {
	const defaultLastMessageType =
		viewType === InboxDisplayType.Cards && !filter.showReminderOnly && !filter.newMessagesOnly
			? [LinkedInMessageType.Received.toString()]
			: [];

	const result: Partial<InboxFilterRequestModel> = {
		NewMessagesOnly: {
			include: filter.newMessagesOnly ? ['True'] : [],
			exclude: [],
		},
		ShowSnoozed: {
			include: filter.showSnoozedOnly ? ['True'] : [],
			exclude: [],
		},
		ShowReminder: {
			include: filter.showReminderOnly ? ['True'] : [],
			exclude: [],
		},
		LastMessageType: {
			include: isEmpty(filter.lastMessageTypes)
				? defaultLastMessageType
				: filter.lastMessageTypes.map((t) => t.toString()),
			exclude: [],
		},
		Sentiment: {
			include: filterThenMapElements(
				filter.sentiment,
				(f) => f.isVisible,
				(f) => f.key
			),
			exclude: [],
		},
		TagName: {
			include: filterThenMapElements(
				filter.tags,
				(f) => f.isVisible && !f.isExclude,
				(f) => f.label
			),
			exclude: filterThenMapElements(
				filter.tags,
				(f) => f.isVisible && !!f.isExclude,
				(f) => f.label
			),
		},
		CampaignId: {
			include: filterThenMapElements(
				filter.campaigns,
				(f) => f.isVisible,
				(f) => f.key
			),
			exclude: [],
		},
		OrgMemberId: {
			include: filterThenMapElements(
				filter.teamMembers,
				(f) => f.isVisible,
				(f) => f.key
			),
			exclude: [],
		},
	};
	// Conditional until webapi changes for reminderStatuses are deployed
	if (!isEmpty(filter.reminderStatuses)) {
		result.ReminderStatus = {
			include: filter.reminderStatuses,
			exclude: [],
		};
	}
	return result;
};

/**
 * Determine sort field and direction based on inbox version and reminder filter
 * @param version
 * @param reminder
 */
export function getSortByField(version: InboxVersion, reminder?: FilterModel): string | undefined {
	if (version === INBOX_VERSIONS.V4) {
		return !isEmpty(reminder?.include ?? [])
			? SORT_BY_FIELDS.REMINDER_TIMESTAMP_ASCENDING
			: SORT_BY_FIELDS.LAST_MESSAGE_DESCENDING;
	} else {
		return undefined;
	}
}
