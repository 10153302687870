import { Reducer } from 'redux';
import { getLoadSingleActionTypes } from '@copilot/common/store/actionCreators/singleEntity';
import { ModelState } from '@copilot/common/store/models/singleEntity';

/**
 * Generic type of actions
 */
type ActionType<T extends { id: string }> = { type: string; payload?: T; error?: any };

/**
 * Create Reducer for fetching a single entity
 * @param name State name
 */
export const createFetchSingleReducer = <T extends { id: string }>(name: string) => {
	const types = getLoadSingleActionTypes(name);
	const stateBase: ModelState<T> = {
		data: undefined,
		loading: false,
		error: false,
	};
	const reducer: Reducer<ModelState<T>, ActionType<T>> | undefined = (
		state = stateBase,
		action = { type: '' }
	) => {
		switch (action?.type) {
			case types.loadSingleAction:
				return {
					...state,
					loading: true,
				};
			case types.loadSingleSuccessAction:
				return {
					...stateBase,
					data: action.payload,
				};
			case types.loadSingleErrorAction:
				return {
					...stateBase,
					error: true,
				};
			default:
				return state;
		}
	};
	return {
		[name.toLowerCase()]: reducer,
	};
};
