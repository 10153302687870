import { ConversationThread, IConversationThread } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = ConversationThread.modelName;
export namespace ActivityActions {
	export const loadConversation = (conversation: Partial<IConversationThread>) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: conversation,
	});

	export const updateConversation = (
		conversation: Partial<IConversationThread> | Partial<IConversationThread>[]
	) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: conversation,
	});
}
