import React, { HTMLProps } from 'react';

enum WistiaScripts {
	Media = 'wistiaMediaScript',
	External = 'wistiaExternalScript',
}
// Keep track of the total of wistia player that is mounted
let semaphore = 0;

interface WistiaPlayerProps extends HTMLProps<HTMLDivElement> {
	videoHash: string;
}
const WistiaPlayer = React.memo<WistiaPlayerProps>((props) => {
	const { videoHash, ...rest } = props;
	React.useEffect(() => {
		semaphore += 1;
		const jsonpScript = document.createElement('script');
		jsonpScript.src = `https://fast.wistia.com/embed/medias/${videoHash}.jsonp`;
		jsonpScript.async = true;
		jsonpScript.setAttribute('id', WistiaScripts.Media);

		const jsScript = document.createElement('script');
		jsScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
		jsScript.async = true;
		jsScript.setAttribute('id', WistiaScripts.External);

		if (!document.getElementById(WistiaScripts.Media)) {
			document.body.appendChild(jsonpScript);
		}
		if (!document.getElementById(WistiaScripts.External)) {
			document.body.appendChild(jsScript);
		}

		return () => {
			semaphore -= 1;
			// only remove scripts if all wistia player are dismounted
			if (semaphore <= 0) {
				document.body.removeChild(jsonpScript);
				document.body.removeChild(jsScript);
			}
		};
	}, []);

	return <div {...rest} className={`wistia_embed wistia_async_${videoHash} videoFoam=true`} />;
});
export default WistiaPlayer;
