// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aibsbGjKHVWySmZ_Gmvn .copilot-menu-item {\n  padding-left: 12px;\n}\n.pgRMDpoDv5pPtXp0hCK6 {\n  padding-left: 12px;\n  color: rgba(0, 0, 0, 0.45);\n}\n.WSbZOfJQ0hB_NJjw6ifG {\n  width: 16px;\n  font-size: 16px;\n  display: flex;\n}\n.gnhLquSIyY94ZCMnqItA {\n  padding: 0;\n  margin: 4px 0;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/forms/messageForm/smartReply/promptMenu/smartReplyPromptMenu.module.less"],"names":[],"mappings":"AAEA;EAEE,kBAAA;AAFF;AAMA;EACC,kBAAA;EACA,0BAAA;AAJD;AAOA;EACC,WAAA;EACA,eAAA;EACA,aAAA;AALD;AAQA;EACC,UAAA;EACA,aAAA;AAND","sourcesContent":["@import '../../../../../../styles/common-variables.less';\n\n.smartReplyMenu {\n\t:global(.copilot-menu-item) {\n\t\tpadding-left: 12px;\n\t}\n}\n\n.smartReplyMenuHeader {\n\tpadding-left: 12px;\n\tcolor: @textColorSecondary;\n}\n\n.smartReplyMenuIcon {\n\twidth: 16px;\n\tfont-size: 16px;\n\tdisplay: flex;\n}\n\n.smartReplyDivider {\n\tpadding: 0;\n\tmargin: @spacingXSS 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smartReplyMenu": "aibsbGjKHVWySmZ_Gmvn",
	"smartReplyMenuHeader": "pgRMDpoDv5pPtXp0hCK6",
	"smartReplyMenuIcon": "WSbZOfJQ0hB_NJjw6ifG",
	"smartReplyDivider": "gnhLquSIyY94ZCMnqItA"
};
export default ___CSS_LOADER_EXPORT___;
