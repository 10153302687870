import { BaseModel } from './base';
import { attr } from 'redux-orm';

export interface ILinkedInProfile {
	alias: string;
	company: string;
	id: string;
	name: string;
	title: string;
	urn: string;
}

export class LinkedInProfile extends BaseModel<typeof LinkedInProfile, ILinkedInProfile> {
	static modelName = 'LinkedInProfile';
	static fields = {
		alias: attr(),
		company: attr(),
		id: attr(),
		name: attr(),
		title: attr(),
		urn: attr(),
	};
}
