import { Flex } from 'antd';
import { PhoneOutlined, MailOutlined, CrownOutlined, ShopOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import ProspectInfoBox from './prospectInfoBox';
import { ProspectInfoProps } from './prospectInfoTypes';

/**
 * The Prospect Info panel.
 * @param props
 * @constructor
 */
export default function ProspectInfo(props: ProspectInfoProps) {
	return (
		<Flex vertical gap={'small'}>
			<ProspectInfoBox
				icon={<ShopOutlined />}
				placeholder="Enter company"
				text={!isEmpty(props.contactCompany.value) ? props.contactCompany.value : undefined}
				isLoading={props.contactCompany.isLoading}
				onUpdate={props.contactCompany.onUpdate}
			/>
			<ProspectInfoBox
				icon={<CrownOutlined />}
				placeholder="Enter title"
				text={!isEmpty(props.contactTitle.value) ? props.contactTitle.value : undefined}
				isLoading={props.contactTitle.isLoading}
				onUpdate={props.contactTitle.onUpdate}
			/>
			<ProspectInfoBox
				icon={<MailOutlined />}
				placeholder="Enter email"
				text={!isEmpty(props.contactEmail.value) ? props.contactEmail.value : undefined}
				isLoading={props.contactEmail.isLoading}
				onUpdate={props.contactEmail.onUpdate}
			/>
			<ProspectInfoBox
				icon={<PhoneOutlined />}
				placeholder="Enter phone number"
				text={
					!isEmpty(props.contactPhoneNumber.value)
						? props.contactPhoneNumber.value
						: undefined
				}
				isLoading={props.contactPhoneNumber.isLoading}
				onUpdate={props.contactPhoneNumber.onUpdate}
			/>
		</Flex>
	);
}
