import React from 'react';
import { Button, Card, Col, Dropdown, Menu, Row, Typography } from 'antd';
import { IInvoice, InvoiceStatus } from '@copilot/data/responses/models/billing';
import PaymentErrorCodeTag from './paymentErrorCodeTag';
import { getFormattedDate, LONG_DATE_FORMAT } from '@copilot/common/utils/dateFormat';
import { MoreOutlined } from '@ant-design/icons';
import { useCSCustomerBillingTracking } from '../../pages/customerDetails/billing/tracking';
import { createStripeInvoiceLink } from './utils/stripe';

const { Title } = Typography;

/**
 * Props required to display the most recent invoice summary
 */
interface RecentInvoiceSummaryProps {
	invoice: IInvoice;
}

/**
 * The most recent invoice summary to be displayed on the
 * billing page
 *
 * @param props props for the recent invoice
 * @returns
 */
const RecentInvoiceSummary: React.FC<RecentInvoiceSummaryProps> = ({ invoice }) => {
	const trackEvent = useCSCustomerBillingTracking('Recent Invoice Summary');
	const createdDate = getFormattedDate(invoice.createdDate, LONG_DATE_FORMAT);

	const menu = (
		<Menu>
			<Menu.Item>
				<a
					target="_blank"
					href={createStripeInvoiceLink(invoice.id)}
					onClick={() => trackEvent({ buttonClicked: 'View Invoice' })}
				>
					View Invoice
				</a>
			</Menu.Item>
			<Menu.Item>
				<a
					href={invoice.invoicePdf}
					download
					onClick={() => trackEvent({ buttonClicked: 'Download Invoice' })}
				>
					Download Invoice
				</a>
			</Menu.Item>
		</Menu>
	);

	return (
		<Card style={{ marginTop: '14px' }}>
			<Title level={4}>Most Recent Invoice</Title>
			<Row justify="space-between">
				<Col>
					{createdDate}
					{invoice.status === InvoiceStatus.open && invoice.paymentStatus && (
						<>
							{' '}
							<PaymentErrorCodeTag status={invoice.paymentStatus} />
						</>
					)}
				</Col>
				<Col className="text-right">
					{invoice.formattedAmountDue}
					<Dropdown trigger={['click']} overlay={menu} arrow placement="bottomRight">
						<Button
							shape="circle"
							type="text"
							icon={<MoreOutlined />}
							onClick={() => trackEvent({ buttonClicked: 'Expand Recent Invoice' })}
						/>
					</Dropdown>
				</Col>
			</Row>
		</Card>
	);
};

export default RecentInvoiceSummary;
