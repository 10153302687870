import { ReactNode } from 'react';
import { Button, ButtonProps, Tooltip } from 'antd';
import { CSSProperties } from 'styled-components';
import { ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type ButtonWithTooltipProps = {
	label: string;
	isIconOnly: boolean;
	type: ButtonType;
	style?: CSSProperties;
	onClick?: () => void;
	icon?: ReactNode;
	ghost?: boolean;
	size: SizeType;
	loading?: boolean;
	className?: string;
} & ButtonProps;

/**
 * Component to Wrap a Button with a Tooltip if it is icon only
 * @param label {string}
 * @param isIconOnly {boolean}
 * @param type {ButtonType}
 * @param style {CSSProperties}
 * @param onClick
 * @param icon {ReactNode}
 * @param ghost {boolean}
 */
export default function ButtonWithTooltip({
	icon,
	type,
	style,
	className,
	onClick,
	label,
	isIconOnly,
	ghost,
	size,
	loading,
	...rest
}: ButtonWithTooltipProps) {
	return isIconOnly ? (
		<Tooltip title={label}>
			<Button
				icon={icon}
				type={type}
				ghost={ghost}
				onClick={onClick}
				style={style}
				size={size}
				loading={loading}
				className={className}
				{...rest}
			/>
		</Tooltip>
	) : (
		<Button
			icon={icon}
			type={type}
			ghost={ghost}
			onClick={onClick}
			style={style}
			size={size}
			loading={loading}
			className={className}
			{...rest}
		>
			{label}
		</Button>
	);
}
