import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { isError, isNil, isUndefined } from 'lodash';
import { Features } from '@copilot/data/responses/interface';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import {
	IEditSmartReplyInput,
	IEditSmartReplyQuery,
	ISmartReplyErrorTypes,
	IWriteSmartReplyQuery,
	Scalars,
	useEditSmartReplyLazyQuery,
	useWriteSmartReplyLazyQuery,
} from '@copilot/data/graphql/_generated';
import {
	CUSTOM_PROMPT_KEY,
	EditSmartReplyHookType,
	EditSmartReplyPromptInput,
	SmartReplyHookType,
	WriteSmartReplyErrors,
	WriteSmartReplyHookType,
	WriteSmartReplyMessageInput,
	WriteSmartReplyPromptInput,
} from '@copilot/common/hooks/smartReply/smartReplyTypes';
import {
	getSmartReplyConversationContext,
	isSmartReply,
	isSmartReplyError,
} from '@copilot/common/hooks/smartReply/utils';
import { QueryResponseType } from '../types';

/**
 * Maps from GraphQL's error DTOs to Smart Reply errors
 */
const ErrorMap: Record<string, string | undefined> = {
	[ISmartReplyErrorTypes.Moderation]: WriteSmartReplyErrors.moderation,
	[ISmartReplyErrorTypes.Credit]: WriteSmartReplyErrors.outOfCredit,
};

/**
 * Callback function returned when ChatGPT is not enabled
 */
function handleFeatureFlagOff() {
	return Promise.reject(new Error('The ChatGPT Feature is off'));
}

/**
 * Hook determining if the ChatGPT feature is enabled & the user is not CS.
 */
export function useChatGptFeature() {
	const isChatGptFeatureEnabled = useFeatureToggle(Features.ChatGPTFeature);
	const impersonatingMember = useSelector(OrganizationMemberSelectors.getAdminMember);
	/// CS should not see the chatGpt feature when impersonating
	return isChatGptFeatureEnabled && isNil(impersonatingMember);
}

/**
 * [Smart] Hook for writing or editing a message with Smart Reply
 * @param orgId
 * @param memberId
 * @param contactId
 */
export const useSmartReply = (
	orgId: string,
	memberId: string,
	contactId: string
): SmartReplyHookType => {
	// If ChatGPT is not enabled, return immediately
	const isChatGptFeatureEnabled = useChatGptFeature();
	if (!isChatGptFeatureEnabled) {
		return [
			{ loading: false, data: undefined, error: new Error(WriteSmartReplyErrors.disabled) },
			{
				writeSmartReply: handleFeatureFlagOff,
				regenerateSmartReply: () => {
					handleFeatureFlagOff();
				},
				editSmartReply: handleFeatureFlagOff,
			},
		];
	}

	const [writeData, { writeSmartReply, regenerateSmartReply }] = useWriteSmartReply(
		orgId,
		memberId,
		contactId
	);
	const [editData, { editSmartReply }] = useEditSmartReply();
	const [data, setData] = useState<QueryResponseType<string>>(writeData);

	// TODO COPILOT-6627: improve method of combining data from two queries
	// Currently we are using whether writeData or editData is loading to determine which data to use and showcase to consumer
	useEffect(() => {
		setData(writeData);
	}, [writeData.loading]);

	useEffect(() => {
		setData(editData);
	}, [editData.loading]);

	return [data, { writeSmartReply, regenerateSmartReply, editSmartReply }];
};

/**
 * [Smart] Hook for writing a message with Smart Reply
 * @param orgId
 * @param memberId
 * @param contactId
 */
const useWriteSmartReply = (
	orgId: string,
	memberId: string,
	contactId: string
): WriteSmartReplyHookType => {
	return useWriteSmartReplyInternal(orgId, memberId, contactId);
};

/**
 * [Smart] Hook for writing a Smart Reply using the AI Chat Service
 * @param orgId
 * @param memberId
 * @param contactId
 */
const useWriteSmartReplyInternal = (
	orgId: string,
	memberId: string,
	contactId: string
): WriteSmartReplyHookType => {
	const [writeSmartReplyQuery, { data, refetch }] = useWriteSmartReplyLazyQuery({
		notifyOnNetworkStatusChange: true,
	});
	const [error, setError] = useState<Error | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	/**
	 * Callback for writing a Smart Reply given a prompt
	 * @param promptId
	 * @param message
	 */
	const writeSmartReply = async (promptId: string, message?: string) => {
		const prompt = { promptId, message };
		const context = await getSmartReplyConversationContext(orgId, memberId, contactId);
		const variables = createWriteSmartReplyVariables(prompt, context);
		return writeSmartReplyQuery(variables);
	};

	/**
	 * Wrap fetches with state management
	 * @param fetchFn The function we want to wrap
	 */
	function prepFetch<T extends (...args: any[]) => Promise<{ data?: IWriteSmartReplyQuery }>>(
		fetchFn: T
	) {
		return async function (...args: Parameters<T>) {
			setLoading(true);
			setError(undefined);
			try {
				const result = await fetchFn(...args);
				if (!isNil(result?.data) && isSmartReplyError(result?.data.writeSmartReply)) {
					const errorMessage =
						ErrorMap[result.data.writeSmartReply.type] ??
						WriteSmartReplyErrors.generation;
					throw new Error(errorMessage);
				}
			} catch (err: unknown) {
				if (isError(err)) setError(err);
				else throw err;
			} finally {
				setLoading(false);
			}
		};
	}

	const regenerateSmartReply = prepFetch(refetch);

	const callbacks = {
		writeSmartReply: prepFetch(writeSmartReply),
		regenerateSmartReply: () => regenerateSmartReply(),
	};

	if (loading) return [{ loading: true, data: undefined, error: undefined }, callbacks];

	if (error) return [{ loading: false, data: undefined, error: error }, callbacks];

	// Return the message if it can be parsed
	if (!isNil(data) && isSmartReply(data.writeSmartReply)) {
		return [{ loading, error: undefined, data: data?.writeSmartReply.message }, callbacks];
	}

	// Return an error if no previous condition was met
	return [
		{ loading, data: undefined, error: new Error(WriteSmartReplyErrors.unexpectedFormat) },
		callbacks,
	];
};

function createWriteSmartReplyVariables(
	prompt: WriteSmartReplyPromptInput,
	context: Readonly<{
		sourceName: string;
		targetName: string;
		messageThread: ReadonlyArray<WriteSmartReplyMessageInput>;
	}>
): Record<string, unknown> {
	return {
		variables: {
			input: {
				sender: { name: context.sourceName },
				recipient: { name: context.targetName },
				messageThread: context.messageThread.map((m) => ({
					message: m.message,
					outbound: m.outbound,
				})),
				prompt: {
					// TODO - COPILOT-6513: Update to use promptId with no conditional.
					promptId: isUndefined(prompt.message) ? prompt.promptId : CUSTOM_PROMPT_KEY,
					message: prompt.message,
				},
			},
		},
	};
}

/**
 * [Smart] Hook for editing a message with Smart Reply
 * @param orgId
 * @param memberId
 * @param contactId
 */
export const useEditSmartReply = (): EditSmartReplyHookType => {
	// If ChatGPT is not enabled, return immediately
	const isChatGptFeatureEnabled = useChatGptFeature();
	if (!isChatGptFeatureEnabled) {
		return [
			{ loading: false, data: undefined, error: new Error(WriteSmartReplyErrors.disabled) },
			{ editSmartReply: handleFeatureFlagOff },
		];
	}

	const [editSmartReplyQuery, { data }] = useEditSmartReplyLazyQuery({
		notifyOnNetworkStatusChange: true,
	});
	const [error, setError] = useState<Error | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	/**
	 * Callback for Editing a Smart Reply given a prompt
	 * @param messageToBeEdited
	 * @param promptId
	 * @param message
	 */
	const editSmartReply = async (
		messageToBeEdited: string,
		promptId: string,
		message?: string
	) => {
		const prompt = { promptId, message };
		const variables = createEditSmartReplyVariables(prompt, messageToBeEdited);
		return editSmartReplyQuery(variables);
	};

	/**
	 * Wrap fetches with state management
	 * @param fetchFn The function we want to wrap
	 */
	const prepFetch = <T extends (...args: any[]) => Promise<{ data?: IEditSmartReplyQuery }>>(
		fetchFn: T
	) => {
		return async function (...args: Parameters<T>) {
			setLoading(true);
			setError(undefined);
			try {
				const result = await fetchFn(...args);
				if (!isNil(result?.data) && isSmartReplyError(result?.data.editSmartReply)) {
					const errorMessage =
						ErrorMap[result.data.editSmartReply.type] ??
						WriteSmartReplyErrors.generation;
					throw new Error(errorMessage);
				}
			} catch (err: unknown) {
				if (isError(err)) setError(err);
				else throw err;
			} finally {
				setLoading(false);
			}
		};
	};

	const callbacks = { editSmartReply: prepFetch(editSmartReply) };

	if (loading) return [{ loading: true, data: undefined, error: undefined }, callbacks];

	if (error) return [{ loading: false, data: undefined, error: error }, callbacks];

	// Return the message if it can be parsed
	if (!isNil(data) && isSmartReply(data.editSmartReply)) {
		return [{ loading, error: undefined, data: data?.editSmartReply.message }, callbacks];
	}

	// Return an error if no previous condition was met
	return [
		{ loading, data: undefined, error: new Error(WriteSmartReplyErrors.unexpectedFormat) },
		callbacks,
	];
};

function createEditSmartReplyVariables(
	prompt: EditSmartReplyPromptInput,
	messageToBeEdited: Scalars['String']
): {
	variables: {
		input: IEditSmartReplyInput;
	};
} {
	return {
		variables: {
			input: {
				message: messageToBeEdited,
				prompt: {
					// TODO - COPILOT-6513: Update to use promptId with no conditional.
					promptId: isUndefined(prompt.message) ? prompt.promptId : CUSTOM_PROMPT_KEY,
					message: prompt.message,
				},
			},
		},
	};
}
