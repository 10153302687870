import { Button, Dropdown } from 'antd';
import { ComponentProps, FC } from 'react';
import { UpOutlined } from '@ant-design/icons';

const MarkAsButton: FC<ComponentProps<typeof Button>> = (props) => {
	const { children, ...rest } = props;
	return (
		<Dropdown
			overlay={<>{children}</>}
			placement="topRight"
			getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
		>
			<Button type="primary" ghost {...rest}>
				Mark As <UpOutlined />
			</Button>
		</Dropdown>
	);
};

export default MarkAsButton;
