import { Config } from '@copilot/common/config';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { StatsUtilityFunctions } from '@copilot/common/utils/common/stats';
import { ReactNode } from 'react';
import SimpleChart from '../../charts/simple';
import PercentageGraph from '../../charts/simple/percentage';
import {
	LinkedInCalculatedStats,
	LinkedInCalculatedStatsLabel,
	LinkedInCalculatedStatsType,
	StatFilterDefinition,
} from '../../componentModels/stat';
import {
	CampaignPercentageStatsWithoutReplyOverNurture,
	OrganizationPercentageStats,
	PercentageTypeExtract,
} from '../type';
import { LinkedInStatLabelMap } from '@copilot/common/constant/enum';

const isStatsAvailable = (
	options: StatFilterDefinition[],
	statLabelA: string,
	statLabelB: string
) =>
	!!options.find((option) => option.label === statLabelA && option.isVisible) &&
	!!options.find((option) => option.label === statLabelB && option.isVisible);

/**
 * determine whether a percentage chart should be displayed or not
 * by checking for existence of numerator and denominator values
 * @param type type of percentage chart
 * @param options statistics options
 */
const shouldPercentageChartDisplayed = (
	type: LinkedInCalculatedStatsType,
	options: StatFilterDefinition[]
) => {
	switch (type) {
		case LinkedInCalculatedStatsType.ConnectionsOverInvites:
			return isStatsAvailable(
				options,
				LinkedInStatLabelMap.LinkedInConnected,
				LinkedInStatLabelMap.LinkedInInvite
			);

		case LinkedInCalculatedStatsType.ReplyOverConnections:
			return isStatsAvailable(
				options,
				LinkedInStatLabelMap.UniqueReplies,
				LinkedInStatLabelMap.LinkedInConnected
			);

		case LinkedInCalculatedStatsType.InterestedOverReply:
			return isStatsAvailable(
				options,
				LinkedInStatLabelMap.ReplyInterested,
				LinkedInStatLabelMap.UniqueReplies
			);

		case LinkedInCalculatedStatsType.ReplyOverInvites:
			return isStatsAvailable(
				options,
				LinkedInStatLabelMap.UniqueReplies,
				LinkedInStatLabelMap.LinkedInInvite
			);

		case LinkedInCalculatedStatsType.NurtureReplyOverSent:
			return isStatsAvailable(
				options,
				LinkedInStatLabelMap.NurtureUniqueReplies,
				LinkedInStatLabelMap.SendMessage
			);

		case LinkedInCalculatedStatsType.NurtureReplyOverNurtureSent:
			return isStatsAvailable(
				options,
				LinkedInStatLabelMap.NurtureUniqueReplies,
				LinkedInStatLabelMap.NurtureSendMessage
			);

		default:
			return UtilityFunctions.assertUnreachable(type);
	}
};

/**
 * Consolidate percentage stats across selected campaigns
 * @param campaignRatios percentage stats values by campaign
 * @param campaignFilters selected state of campaigns
 */
export const getPercentageStats: PercentageTypeExtract = (
	campaignRatios: TypedObject<CampaignPercentageStatsWithoutReplyOverNurture>,
	campaignFilters: TypedObject<boolean>
): CampaignPercentageStatsWithoutReplyOverNurture =>
	Object.keys(campaignRatios).reduce<CampaignPercentageStatsWithoutReplyOverNurture>(
		(acc, campaign) => {
			if (campaignFilters[campaign]) {
				Object.keys(acc).forEach((label) => {
					acc[label as keyof typeof acc] = StatsUtilityFunctions.combinePercentageStats(
						acc[label as keyof typeof acc],
						campaignRatios[campaign][
							label as keyof CampaignPercentageStatsWithoutReplyOverNurture
						]
					);
				});
			}
			return acc;
		},
		{
			[LinkedInCalculatedStatsType.ConnectionsOverInvites]: [
				{ name: LinkedInStatLabelMap.LinkedInConnected, value: 0 },
				{ name: LinkedInStatLabelMap.LinkedInInvite, value: 0 },
			],
			[LinkedInCalculatedStatsType.ReplyOverConnections]: [
				{ name: LinkedInStatLabelMap.UniqueReplies, value: 0 },
				{ name: LinkedInStatLabelMap.LinkedInConnected, value: 0 },
			],
			[LinkedInCalculatedStatsType.InterestedOverReply]: [
				{ name: LinkedInStatLabelMap.ReplyInterested, value: 0 },
				{ name: LinkedInStatLabelMap.UniqueReplies, value: 0 },
			],
			[LinkedInCalculatedStatsType.ReplyOverInvites]: [
				{ name: LinkedInStatLabelMap.UniqueReplies, value: 0 },
				{ name: 'Invites Sent', value: 0 },
			],
			[LinkedInCalculatedStatsType.NurtureReplyOverSent]: [
				{ name: LinkedInStatLabelMap.NurtureUniqueReplies, value: 0 },
				{ name: LinkedInStatLabelMap.SendMessage, value: 0 },
			],
		}
	);

/**
 * Create percentage charts
 * @param percentageStats consolidated percentage stats across campaigns
 * @param displayCharts charts to be displayed
 * @param statsOptions statistics options
 * @param isSingleDay whether time range selected is single day or not
 * @param campaignColorLegends campaign colors
 * @param id id of stat owner
 */
export const getPercentageCharts = (
	percentageStats: CampaignPercentageStatsWithoutReplyOverNurture | OrganizationPercentageStats,
	displayCharts: Record<string, boolean>,
	statsOptions: StatFilterDefinition[],
	isSingleDay: boolean,
	campaignColorLegends: TypedObject<string>,
	id: string
): ReactNode[] =>
	Object.keys(percentageStats).reduce<ReactNode[]>((acc, label) => {
		if (!displayCharts[label]) return acc;
		if (!shouldPercentageChartDisplayed(label as LinkedInCalculatedStatsType, statsOptions))
			return acc;
		const chart =
			Config.isAgency || isSingleDay ? (
				<SimpleChart
					title={UtilityFunctions.convertDecimalToPercentage(
						percentageStats[label as keyof typeof percentageStats][0].value /
							percentageStats[label as keyof typeof percentageStats][1].value
					)}
					description={
						LinkedInCalculatedStats[label as keyof typeof LinkedInCalculatedStatsLabel]
					}
				/>
			) : (
				<SimpleChart
					title={
						LinkedInCalculatedStatsLabel[
							label as keyof typeof LinkedInCalculatedStatsLabel
						]
					}
					description={
						LinkedInCalculatedStats[label as keyof typeof LinkedInCalculatedStatsLabel]
					}
					showDescription={false}
				>
					<PercentageGraph
						data={percentageStats[label as keyof typeof percentageStats]}
						color={campaignColorLegends[id]}
					/>
				</SimpleChart>
			);

		return [...acc, chart];
	}, []);
