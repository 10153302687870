/**
 * Return the first element of an array
 * @param arr
 */
export const getFirstElement = <T>(arr: T[]) => arr.find((_) => true);

/**
 * Filter array and transform
 * @param arr array to filter then transform
 * @param filterCondition Func to filter elements by. Same as array.filter()
 * @param transformationFunc Func to transform elements by. Same as array.map()
 */
export const filterThenMapElements = <T, U>(
	arr: T[],
	filterCondition: (value: T, index: number, array: T[]) => boolean,
	transformationFunc: (value: T, index: number, array: T[]) => U
) =>
	arr.reduce<U[]>(
		(result, item, index, initialArr) =>
			filterCondition(item, index, initialArr)
				? [...result, transformationFunc(item, index, initialArr)]
				: result,
		[]
	);
