import React from 'react';
import { Row, Button, Alert, Col } from 'antd';
import BaseModal, { BaseModalProps } from '../base';

export interface IMigrationModalProps extends BaseModalProps {
	orgMemberId: string;
	handleMigrateCustomer: (orgMemberId: string) => unknown;
}

const MigrationModal = React.memo<IMigrationModalProps>((props) => {
	const { onCancel, orgMemberId, handleMigrateCustomer } = props;

	const migrateCustomer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		onCancel?.(event);
		handleMigrateCustomer(orgMemberId);
	};

	return (
		<BaseModal {...props} width={800}>
			<Row
				gutter={[16, 16]}
				style={{ marginRight: '15%', marginLeft: '15%', marginTop: '5%' }}
			>
				<Col span={24}>
					<h3>
						<strong>
							Are you sure you want to move this customer from Individual to Teams?
						</strong>
					</h3>
				</Col>
				<Col span={24}>
					<Alert
						message="This action cannot be reverted"
						type="error"
						style={{
							marginTop: '2%',
							marginBottom: '2%',
						}}
					/>
				</Col>
				<Col span={24}>
					<p>
						Once migrated, customers will become owners of their new team account with
						their current connections and campaign data. Their team name will be the
						individual org name, which can be changed later.
					</p>
				</Col>
				<Col span={24}>
					<p>
						Once completed, the customer will be moved from the individual to teams on
						CS dash.
					</p>
				</Col>
				<Col span={24}>
					<p>Do not use this for individuals moving into an existing team</p>
				</Col>
			</Row>
			<Row justify="center" style={{ marginBottom: '1%' }}>
				<Col style={{ justifyItems: 'center' }} span={12}>
					<Button type="primary" onClick={migrateCustomer} block>
						Migrate
					</Button>
				</Col>
			</Row>
			<Row justify="center" style={{ marginBottom: '5%' }}>
				<Col style={{ justifyItems: 'center' }} span={12}>
					<Button type="text" onClick={onCancel} block>
						Do not migrate
					</Button>
				</Col>
			</Row>
		</BaseModal>
	);
});

export default MigrationModal;
