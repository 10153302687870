import { FC, ReactNode } from 'react';
import {
	MessageName,
	MessageSentenceNewUser,
	MessageTypeEnum,
} from '@copilot/common/pages/onboard/wizard/messaging/const';
import { Template } from '@copilot/common/pages/settings/message/const';
import { OnboardMessage } from '@copilot/data/requests/models';
import { Alert, Col, Divider, Radio, Row, Typography } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import NewUserMessageEditor from './messageEditor';

const { Text, Title } = Typography;

interface INewUserMessageEditPageProps {
	title: string;
	description: ReactNode;
	selectedStrategy: MessageTypeEnum;
	strategies: MessageTypeEnum[];
	isStrategySelectable?: boolean;
	selectedTemplate: string;
	templates: Template[];
	isTemplateSelectable?: boolean;
	message: OnboardMessage;
	maxLength?: number;
	hideTiming?: boolean;
	timeDescription?: string;
	onStrategyUpdate: (e: RadioChangeEvent) => void;
	onTemplateUpdate: (key: string) => void;
	onMessageUpdate: (message: OnboardMessage) => void;
	isOnboardMessageImprovementFeatureEnabled: boolean;
}

interface IStrategyOptionsProps {
	strategyTypes: MessageTypeEnum[];
}

/**
 * Radio options for message strategy
 * @param strategyTypes strategy types
 */
const StrategyOptions: FC<IStrategyOptionsProps> = (props) => (
	<>
		{props.strategyTypes.map((type) => (
			<Radio.Button value={type} style={{ padding: '0 4em' }}>
				{MessageName[type]}
			</Radio.Button>
		))}
	</>
);

interface IStrategySelectorProps {
	selectedStrategy: MessageTypeEnum;
	onStrategyUpdate: (e: RadioChangeEvent) => void;
	strategies: MessageTypeEnum[];
}

/**
 * Selector for message strategy
 * @param selectedStrategy selected strategy
 * @param onStrategyUpdate callback on strategy update
 * @param strategies list of strategy options
 */
const StrategySelector: FC<IStrategySelectorProps> = (props) => {
	const { selectedStrategy, onStrategyUpdate, strategies } = props;
	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Text strong>Choose a messaging approach</Text>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Radio.Group
						value={selectedStrategy}
						onChange={onStrategyUpdate}
						buttonStyle="solid"
						size="large"
					>
						<StrategyOptions strategyTypes={strategies} />
					</Radio.Group>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Text>{MessageSentenceNewUser[selectedStrategy]}</Text>
				</Col>
			</Row>
		</>
	);
};

const SelectedStrategyBanner: FC<{ selectedStrategy: string }> = (props) => (
	<Row gutter={[16, 16]}>
		<Col span={24}>
			<Alert
				message={`You've previously selected a ${props.selectedStrategy.toLowerCase()} approach.`}
				type="info"
				showIcon={false}
			/>
		</Col>
	</Row>
);

/**
 * Message edit page with template
 * For new user onboard
 * @param title title of the page
 * @param description description of the page
 * @param selectedStrategy selected message strategy
 * @param strategies list of strategy options
 * @param isStrategySelectable if strategy is selectable
 * @param selectedTemplate selected template
 * @param templates list of templates
 * @param message message to edit
 * @param onStrategyUpdate callback on strategy update
 * @param onTemplateUpdate callback on template update
 * @param onMessageUpdate callback on message update
 * @param maxLength max length for message text
 * @param hideTiming if we want to hide message timing editor
 * @param isOnboardMessageImprovementFeatureEnabled is the feature flag enabled?
 */
const NewUserMessageEditPageWithTemplate: FC<INewUserMessageEditPageProps> = (props) => {
	const {
		title,
		description,
		selectedStrategy,
		strategies,
		isStrategySelectable,
		selectedTemplate,
		templates,
		message,
		onStrategyUpdate,
		onTemplateUpdate,
		onMessageUpdate,
		maxLength,
		hideTiming = false,
		timeDescription,
		isOnboardMessageImprovementFeatureEnabled,
	} = props;

	return (
		<>
			<Row>
				<Col>
					<Title level={5}>{title}</Title>
					{description}
				</Col>
			</Row>
			<Divider />
			{!isOnboardMessageImprovementFeatureEnabled &&
				(isStrategySelectable ? (
					<StrategySelector
						strategies={strategies}
						selectedStrategy={selectedStrategy}
						onStrategyUpdate={onStrategyUpdate}
					/>
				) : (
					<SelectedStrategyBanner selectedStrategy={MessageName[selectedStrategy]} />
				))}
			<Row>
				<Col span={24}>
					<NewUserMessageEditor
						message={message}
						maxLength={maxLength}
						onMessageUpdate={onMessageUpdate}
						selectedTemplate={selectedTemplate}
						templates={templates}
						onTemplateUpdate={onTemplateUpdate}
						hideTiming={hideTiming}
						timeDescription={timeDescription}
					/>
				</Col>
			</Row>
		</>
	);
};

export default NewUserMessageEditPageWithTemplate;
