import { useState, FC } from 'react';
import { Alert, Button, Col, Space, Typography, Card, Divider, Row, Popover, Skeleton } from 'antd';

import { CloseCircleOutlined } from '@ant-design/icons';
import ProductSummary from './productSummary';
import CouponSummary from './coupon';
import EffectiveDateSummary from './effectiveDateSummary';
import AmountDueSummary from './amountDueSummary';
import { ICoupon, IInvoice } from '@copilot/data/responses/models/billing';
import {
	BillingEditWizardSteps,
	PricingSummaryContext,
} from '../../pages/customerDetails/billing/const';
import { LoadingPane } from '@copilot/common/components/loadingPane/loadingPane';
import { useCSCustomerBillingEditTracking } from '../../pages/customerDetails/billing/tracking';
import notificationManager from '@copilot/common/utils/notificationManager';
import styled from 'styled-components';
import { createStripeSubscriptionLink } from '../billing/utils/stripe';

const { Title, Text, Link } = Typography;

const AlertHeading = styled(Title)`
	&& {
		font-size: 16px;
		font-weight: normal;
		margin-bottom: 0;
	}
`;

const AlertBody = styled(Text)`
	font-size: 14px;
`;

const ButtonFooter = styled.div`
	text-align: right;
	margin-top: 50px;
`;

/**
 * [Presentational] Formatted alert container for the ConfirmStep component
 */
const ConfirmStepAlert: FC = ({ children }) => (
	<Alert
		style={{ marginTop: 20 }}
		icon={<CloseCircleOutlined />}
		showIcon
		type="error"
		message={children}
	/>
);

interface ConfirmStepProps {
	/**
	 * The preview of the invoice.
	 */
	invoicePreview?: IInvoice;
	/**
	 * The subscription id of the customer.
	 */
	subscriptionId?: string;
	/**
	 * The pricing summary model to display.
	 */
	pricingSummaryContext?: PricingSummaryContext;
	/**
	 * The selected coupon to display.
	 */
	selectedCoupon?: ICoupon;
	/**
	 * True if effective date is immediately. False if effective date is next billing cycle.
	 */
	updateNow: boolean;
	/**
	 * The trial to paid end date.
	 */
	trialToPaidEndDate?: string;
	/**
	 * True if the component is in loading state.
	 */
	isLoading: boolean;
	/**
	 * True if the component is in loading invoice state.
	 */
	isLoadingInvoice: boolean;
	/**
	 * True if update subscription button should be disabled.
	 */
	disableUpdateSubscription: boolean;
	/**
	 * Callback for when update now is toggled.
	 */
	updateNowToggled: (updateNow: boolean) => void;
	/**
	 * Emits when delete coupon is clicked.
	 */
	deleteCouponClicked: () => void;
	/**
	 * Open coupon drawer
	 */
	openCouponDrawer: () => void;
	/**
	 * Emits when cancel is clicked.
	 */
	cancelClicked?: () => void;
	/**
	 * Emits when continue is clicked.
	 */
	continueClicked?: () => Promise<void> | undefined;
	/**
	 * Called to update the current step, populated by Wizard.Step.MainContent
	 */
	setCurrentStep?: (node: number, modifications?: { [k: string]: any }) => void;
	/**
	 * If there is a change in price and if there is an increase or a decrease
	 */
	isPriceIncrease?: boolean;
	/**
	 * If there is a change in term and if there is an increase or a decrease
	 */
	isTermIncrease?: boolean;
	/**
	 * The next billing date to charge if effective date is next billing cycle.
	 */
	nextBillingDate?: string;
}

/**
 * [Presentational] component for confirm step in the ChangeSubscriptionWizard
 */
const ConfirmStep: FC<ConfirmStepProps> = ({
	invoicePreview,
	subscriptionId,
	pricingSummaryContext,
	disableUpdateSubscription,
	selectedCoupon,
	nextBillingDate,
	updateNow,
	trialToPaidEndDate,
	updateNowToggled,
	deleteCouponClicked,
	openCouponDrawer,
	cancelClicked,
	continueClicked,
	setCurrentStep,
	isLoading,
	isLoadingInvoice,
	isPriceIncrease,
	isTermIncrease,
}) => {
	const trackEvent = useCSCustomerBillingEditTracking('Confirm Step', {
		wizardStep: 'Subscription Confirmation',
	});
	const [isUpdating, setIsUpdating] = useState<boolean>(false);
	const [lastError, setLastError] = useState<any>();
	const stripeSubscriptionLink = createStripeSubscriptionLink(subscriptionId); // Push to main subscriptions page if we don't have a subscription id

	return (
		<>
			<Title level={2} style={{ fontSize: '24px' }}>
				Confirm New Subscription
			</Title>
			<Alert
				style={{ marginBottom: '16px' }}
				type="warning"
				message={
					<Text>
						If you need to manage add-ons with this subscription, please make these
						changes in
						<Link target="_blank" href={stripeSubscriptionLink}>
							{' '}
							Stripe.
						</Link>
					</Text>
				}
			/>
			<LoadingPane isLoading={isLoading}>
				<Card style={{ padding: '28px' }}>
					{pricingSummaryContext && (
						<ProductSummary
							productName={pricingSummaryContext.productName}
							productInterval={pricingSummaryContext.formattedInterval}
							formattedAmount={pricingSummaryContext.formattedAmount}
						/>
					)}
					<Divider />
					<CouponSummary
						selectedCoupon={selectedCoupon}
						onDeleteCouponClicked={deleteCouponClicked}
						onEditCouponClicked={openCouponDrawer}
					/>
					<Divider />
					<EffectiveDateSummary
						updateNow={updateNow}
						isTermUpdate={isTermIncrease !== undefined}
						nextBillingDate={nextBillingDate}
						onUpdateNowToggle={updateNowToggled}
						trialToPaidEndDate={trialToPaidEndDate}
						formattedProratedAmount={invoicePreview?.formattedProratedAmount}
					/>
					<Divider />
					<LoadingPane isLoading={isLoadingInvoice}>
						<LoadingPane.LoadingSection>
							<Row>
								<Col span={10} offset={14}>
									<Skeleton active title={false} paragraph={{ width: '100%' }} />
								</Col>
							</Row>
							<Divider />
							<Row>
								<Col span={10} offset={14}>
									<Skeleton active paragraph={false} title={{ width: '100%' }} />
								</Col>
							</Row>
							<ButtonFooter>
								<Space>
									<Skeleton.Button active />
									<Skeleton.Button active />
								</Space>
							</ButtonFooter>
						</LoadingPane.LoadingSection>
						{invoicePreview && (
							<AmountDueSummary
								subtotal={invoicePreview.formattedSubtotal}
								tax={invoicePreview.formattedTaxAmount}
								taxRate={invoicePreview.formattedTaxRate}
								amountDue={invoicePreview.formattedAmountDue}
								creditBalance={
									invoicePreview.credit
										? invoicePreview.formattedCredit
										: undefined
								}
							/>
						)}
						<ButtonFooter>
							<Space>
								<Button
									onClick={() => {
										trackEvent({ buttonClicked: 'Back' });
										cancelClicked?.();
										setCurrentStep?.(BillingEditWizardSteps.Product);
									}}
								>
									Back
								</Button>
								<Popover
									trigger={disableUpdateSubscription ? 'click' : undefined}
									content={
										<Text style={{ fontSize: 14 }}>
											No changes have been made
										</Text>
									}
								>
									<Button
										loading={isUpdating}
										disabled={disableUpdateSubscription}
										type="primary"
										onClick={() => {
											trackEvent({
												buttonClicked: trialToPaidEndDate
													? 'Start Subscription'
													: 'Update Subscription',
												isPriceIncrease,
												isTermIncrease,
												coupon: selectedCoupon?.name,
												isUpdateNow: updateNow,
											});
											setIsUpdating(true);
											setLastError(undefined);
											continueClicked?.()
												?.then(
													() => {
														notificationManager.showSuccessNotification(
															{
																message:
																	'Subscription updated successfully.',
															}
														);
													},
													(e) => {
														const status = e.response
															? e.response.status
															: 500;
														setLastError(status);
													}
												)
												.finally(() => {
													setIsUpdating(false);
												});
										}}
									>
										{trialToPaidEndDate
											? 'Start Subscription'
											: 'Update Subscription'}
									</Button>
								</Popover>
							</Space>
						</ButtonFooter>
					</LoadingPane>
					{lastError === 500 && (
						<ConfirmStepAlert>
							<AlertHeading level={4}>Unknown Stripe error</AlertHeading>
							<AlertBody>
								Please try again or{' '}
								<Link target="_blank" href={stripeSubscriptionLink}>
									visit Stripe
								</Link>{' '}
								to make updates to this subscription plan.
							</AlertBody>
						</ConfirmStepAlert>
					)}
					{lastError === 401 && (
						<ConfirmStepAlert>
							<AlertHeading level={4}>User unauthorized</AlertHeading>
							<AlertBody>
								Please re-login into the CS Dash to update this subscription
							</AlertBody>
						</ConfirmStepAlert>
					)}
					{lastError === 400 && (
						<ConfirmStepAlert>
							<AlertHeading level={4}>Subscription change not allowed</AlertHeading>
							<AlertBody>
								You are unable to make the current subscription change on CoPilot
								AI. Please{' '}
								<Link target="_blank" href={stripeSubscriptionLink}>
									visit Stripe
								</Link>{' '}
								to make updates to this subscription plan.
							</AlertBody>
						</ConfirmStepAlert>
					)}
					{lastError === 404 && (
						<ConfirmStepAlert>
							<AlertHeading level={4}>Stripe account not found</AlertHeading>
							<AlertBody>
								We cannot find the user’s Stripe account. Please visit
								<Link target="_blank" href={stripeSubscriptionLink}>
									customer’s Stripe
								</Link>{' '}
								for more details
							</AlertBody>
						</ConfirmStepAlert>
					)}
				</Card>
			</LoadingPane>
		</>
	);
};

export default ConfirmStep;
