import {
	EventMessage,
	EventType,
	PublicClientApplication,
	RedirectRequest,
	SilentRequest,
} from '@azure/msal-browser';
import { B2CCustomPolicyRoutes } from '@copilot/data/config/routes';
import { AuthorizationManager } from './authorization';

/**
 * Failure codes returned by b2c during login
 */
const LoginFailureCodes = {
	Cancelled: 'AADB2C90091', // User triggered cancellation
} as const;

export interface B2COptions {
	clientId: string;
	authorityDomain: string;
	authorityUrl: string;
	scopes: Record<string, string[]>;
	templateName: string;
}

/**
 * Authorization manager to communicate with Azure B2C
 */
export class B2CAuthorizationManager implements AuthorizationManager {
	public pca: PublicClientApplication;
	private scopes: Record<string, string[]>;
	private templateName: string;
	// Dictionary to track whether the token has been refreshed.
	// Used to ensure we have the right token loaded for all page reloads
	// in case the id token had been updated
	private tokenCacheRefreshTracker: Record<string, boolean> = {};
	constructor(options: B2COptions) {
		const { authorityDomain, authorityUrl, clientId, scopes, templateName } = options;
		this.scopes = scopes;
		this.templateName = templateName;
		this.pca = new PublicClientApplication({
			auth: {
				clientId,
				authority: authorityUrl,
				knownAuthorities: [authorityDomain],
				redirectUri: '/',
			},
			cache: {
				cacheLocation: 'sessionStorage',
				storeAuthStateInCookie: false,
			},
		});

		this.addEvents();
	}

	/**
	 * Get an authenticated token
	 * @returns An authorized token
	 */
	public getAuthenticatedToken = async (url: string) => {
		await this.pca.handleRedirectPromise();
		const account = this.getActiveAccount();

		if (account) {
			let requiresTokenRefresh = false;
			const cacheRefreshKey = `${account.localAccountId}-${url}`;
			if (this.tokenCacheRefreshTracker[cacheRefreshKey] !== true) {
				requiresTokenRefresh = true; // Require access token refresh since this is the first time we are loading the token
				this.tokenCacheRefreshTracker[cacheRefreshKey] = true;
			}

			const request: SilentRequest = {
				scopes: this.getScopes(url),
				account,
				forceRefresh: requiresTokenRefresh,
			};

			// TODO COPILOT-4887
			// This is currently required because we use a separate policy for signup
			// and we need to use the right policy to acquire access tokens
			const authority = this.getAuthority(account.homeAccountId);
			if (authority) request.authority = authority;

			try {
				const authResult = await this.pca.acquireTokenSilent(request);
				return authResult.accessToken;
			} catch (error) {
				console.log(error);
				return '';
			}
		} else {
			return '';
		}
	};

	/**
	 * Returns the headers for authorizign a request
	 * @returns Authorization Header
	 */
	public getAuthorizationHeader = async (url: string) => {
		const token = await this.getAuthenticatedToken(url);
		return { headers: { Authorization: `Bearer ${token}` } };
	};

	/**
	 * Returns the currently logged in account
	 * @returns The currently logged in account
	 */
	private getActiveAccount = () => {
		const activeAccount = this.pca.getActiveAccount();
		const accounts = this.pca.getAllAccounts();
		const impersonationAccount = accounts.find((account) =>
			account.homeAccountId.includes('impersonation')
		);
		return impersonationAccount ?? activeAccount ?? accounts[0];
	};

	/**
	 * Get scopes for a url
	 * @param url The url we want scopes for
	 * @returns
	 */
	private getScopes = (url: string): string[] => {
		return this.scopes[url] ?? [];
	};

	/**
	 * Add events handler for pca instance
	 */
	private addEvents = () => {
		// Callback to handle and redirect promises to set the active account
		this.pca.handleRedirectPromise().then((response) => {
			if (response) this.pca.setActiveAccount(response.account);
		});
		this.pca.addEventCallback((event: EventMessage) => {
			switch (event.eventType) {
				case EventType.LOGIN_FAILURE: {
					if (
						event.error &&
						event.error.message.indexOf(LoginFailureCodes.Cancelled) > -1
					) {
						this.pca.loginRedirect(
							this.getAuthRedirectRequestOptions({
								scopes: [],
							})
						);
					}
					break;
				}
				default:
					break;
			}
		});
	};

	/**
	 * Get the right authority policy for a given home account
	 * @param homeAccountId The account we want to grab the policy for
	 * @returns The authority or null
	 */
	private getAuthority = (homeAccountId: string): string | null => {
		if (
			homeAccountId.includes('signup_invitation') ||
			homeAccountId.includes('signup_magic_link')
		)
			return B2CCustomPolicyRoutes.SignUp;
		else if (homeAccountId.includes('changetoken')) return B2CCustomPolicyRoutes.ChangeToken;
		else if (homeAccountId.includes('impersonation'))
			return B2CCustomPolicyRoutes.Impersonation;
		else return null;
	};

	/**
	 * Merge Auth Redirect Options with required values
	 * @param requestOptions Options we want to merge with
	 * @returns Prepped Auth Redirect Options
	 */
	private getAuthRedirectRequestOptions = (requestOptions: RedirectRequest) => {
		const { extraQueryParameters, ...rest } = requestOptions;
		return {
			...rest,
			extraQueryParameters: {
				template: this.templateName,
				...extraQueryParameters,
			},
		};
	};
}
