import { FC } from 'react';
import { Row, Col, Typography } from 'antd';
import styled from 'styled-components';

const StyledRow = styled(Row)`
	text-align: center;
	flex-direction: column;
	padding: 24px 0 36px;

	&&& a {
		text-decoration: underline;
	}
`;

interface INewUserOnboardTitleProps {
	title: string;
}

/**
 * Title component for New User Onboard Wizard Steps
 * @param {string} title title for the onboard wizard step
 */
const NewUserOnboardTitle: FC<INewUserOnboardTitleProps> = (props) => {
	const { title, children } = props;
	return (
		<StyledRow>
			<Col>
				<Typography.Title level={4} style={{ marginBottom: '12px' }}>
					{title}
				</Typography.Title>
			</Col>
			<Col>
				<Row style={{ flexDirection: 'column' }}>{children}</Row>
			</Col>
		</StyledRow>
	);
};

export default NewUserOnboardTitle;
