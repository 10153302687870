import * as React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Row, Button, Radio, Tag, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
	SequenceNodeTrigger,
	SequenceNodeTriggerCondition,
} from '@copilot/data/responses/interface';
import {
	NodeConditionTypeEnum,
	AIThresholdValueEnum,
	NodeConditionResponseTypeEnum,
	NodeConditionTimeTypeEnum,
} from '@copilot/common/store/models/const/enum';
import drawerManager from '@copilot/common/utils/drawerManager';
import EditableTextDisplay from '@copilot/common/components/forms/common/inputs/editableTextDisplay';
import TimeTrigger from './timeTrigger';
import ResponseTrigger from './responseTrigger';
import {
	DynamicAdditionsValue,
	DA_DEFAULT_KEY,
} from '@copilot/common/components/forms/common/inputs/dynamicAdditions';

export interface TriggerEditFormProps {
	saveTrigger: (
		triggerIndex: number,
		conditions: SequenceNodeTriggerCondition[],
		name: string
	) => void;
	triggerIndex: number;
	trigger?: SequenceNodeTrigger;
}

interface TriggerEditDrawerState {
	triggerName: string;
	conditions: SequenceNodeTriggerCondition[];
	currentConditionLoaded: number;
	addCondition: boolean;
	currentConditionType: NodeConditionTypeEnum;
	currentConditionSubType: string;
	currentConditionTimeAmount: number;
	currentConditionTimeUnit: string;
	currentConditionResponseTexts: string[];
	AIThreshold: string[];
}

class TriggerEditForm extends React.Component<TriggerEditFormProps, TriggerEditDrawerState> {
	state: TriggerEditDrawerState;
	formRef: React.RefObject<FormInstance> = React.createRef();

	constructor(props: TriggerEditFormProps) {
		super(props);
		this.state = {
			triggerName: props.trigger?.name ?? 'Trigger Name',
			conditions: props.trigger?.conditions ?? [],
			addCondition: false,
			currentConditionLoaded: -1,
			currentConditionType: NodeConditionTypeEnum.Timer,
			currentConditionSubType: 'After',
			currentConditionTimeAmount: 1,
			currentConditionTimeUnit: 'Hours',
			currentConditionResponseTexts: [],
			AIThreshold: [AIThresholdValueEnum.Med],
		};
		this.changeCurrentType = this.changeCurrentType.bind(this);
		this.changeCurrentSubType = this.changeCurrentSubType.bind(this);
		this.changeCurrentTimeAmount = this.changeCurrentTimeAmount.bind(this);
		this.changeCurrentTimeUnit = this.changeCurrentTimeUnit.bind(this);
		this.changeCurrentResponseExample = this.changeCurrentResponseExample.bind(this);
		this.addCondition = this.addCondition.bind(this);
		this.saveCondition = this.saveCondition.bind(this);
		this.cancelEdit = this.cancelEdit.bind(this);
		this.saveTrigger = this.saveTrigger.bind(this);
		this.updateName = this.updateName.bind(this);
		this.removeCondition = this.removeCondition.bind(this);
		this.changeAIThreshold = this.changeAIThreshold.bind(this);
	}

	changeCurrentType(e: any) {
		this.setState({ currentConditionType: e.target.value });
	}

	changeCurrentSubType(e: any) {
		this.setState({ currentConditionSubType: e });
	}

	changeCurrentTimeAmount(e: any) {
		this.setState({ currentConditionTimeAmount: e });
	}

	changeCurrentTimeUnit(e: any) {
		this.setState({ currentConditionTimeUnit: e });
	}

	changeCurrentResponseExample(e: any) {
		this.setState({ currentConditionResponseTexts: e });
	}

	changeAIThreshold(e: any) {
		this.setState({ AIThreshold: [e] });
	}

	addCondition() {
		this.setState({ addCondition: true });
	}

	createCondition(): SequenceNodeTriggerCondition {
		const values = this.state;
		const conditionModel: SequenceNodeTriggerCondition = {
			name: '',
			conditionType: NodeConditionTypeEnum.Response,
			parameters: [],
		};

		if (values.currentConditionType == NodeConditionTypeEnum.Timer) {
			const timeMeta = [
				[values.currentConditionSubType],
				[String(values.currentConditionTimeAmount)],
				[values.currentConditionTimeUnit],
			];
			conditionModel.conditionType = NodeConditionTypeEnum.Timer;
			conditionModel.parameters = timeMeta;
		} else if (values.currentConditionType == NodeConditionTypeEnum.Response) {
			// Setting this default value until AI is used
			const AITemplateName = ['TBD'];
			let AIconfidence = [];
			//default any and no response confidence levels to 100
			if (
				values.currentConditionSubType == NodeConditionResponseTypeEnum.AnyResponse ||
				values.currentConditionSubType == NodeConditionResponseTypeEnum.NoResponse
			) {
				AIconfidence.push('100');
			} else {
				AIconfidence = this.state.AIThreshold;
			}
			const responseMeta = [
				[values.currentConditionSubType],
				values.currentConditionResponseTexts,
				AIconfidence,
				AITemplateName,
			];
			conditionModel.conditionType = NodeConditionTypeEnum.Response;
			conditionModel.parameters = responseMeta;
		}

		const stringName = this.stringifyCondition(conditionModel);
		conditionModel.name = stringName;

		return conditionModel;
	}

	saveCondition(values: DynamicAdditionsValue<string>) {
		// TODO Refactor this component to get everything working.
		this.setState({ currentConditionResponseTexts: values[DA_DEFAULT_KEY] }, () => {
			const conditionModel = this.createCondition();
			let { conditions } = this.state;
			if (this.state.currentConditionLoaded > -1) {
				conditions[this.state.currentConditionLoaded] = conditionModel;
			} else {
				conditions = [...this.state.conditions, conditionModel];
			}
			this.setState({
				conditions,
			});
			this.cancelEdit();
		});
	}

	removeCondition() {
		const indx = this.state.currentConditionLoaded;
		const { conditions } = this.state;
		if (indx > -1 && conditions.length > 0) {
			conditions.splice(indx, 1);
			this.setState({ conditions });
			this.cancelEdit();
		}
	}

	saveTrigger() {
		this.props.saveTrigger(
			this.props.triggerIndex,
			this.state.conditions,
			this.state.triggerName
		);
		drawerManager.closeDrawer();
	}

	cancelEdit() {
		this.setState({
			addCondition: false,
			currentConditionLoaded: -1,
			currentConditionType: NodeConditionTypeEnum.Response,
			currentConditionSubType: 'After',
			currentConditionTimeAmount: 0,
			currentConditionTimeUnit: 'Hours',
			currentConditionResponseTexts: [],
			AIThreshold: [AIThresholdValueEnum.Med],
		});
	}

	stringifyCondition(model: SequenceNodeTriggerCondition): string {
		let stringified = '';
		if (model.conditionType == NodeConditionTypeEnum.Timer) {
			if (model.parameters[0][0] == NodeConditionTimeTypeEnum.Before) {
				stringified += 'Before ';
			} else if (model.parameters[0][0] == NodeConditionTimeTypeEnum.After) {
				stringified += 'After ';
			}

			stringified += `${model.parameters[1][0]} ${model.parameters[2][0]}`;
		} else if (model.conditionType == NodeConditionTypeEnum.Response) {
			if (model.parameters[0][0] == NodeConditionResponseTypeEnum.AnyResponse) {
				stringified += 'Any Response';
			} else if (model.parameters[0][0] == NodeConditionResponseTypeEnum.EvaluateResponse) {
				stringified += 'Evaluate Messages';
			} else if (model.parameters[0][0] == NodeConditionResponseTypeEnum.NoResponse) {
				stringified += 'No Response';
			}

			//todo add string to evalute to
		}

		return stringified;
	}

	loadCondition(index: number) {
		const condition = this.state.conditions[index];
		this.setState({
			currentConditionType: condition.conditionType,
			currentConditionSubType: condition.parameters[0][0],
			addCondition: true,
			currentConditionLoaded: index,
		});
		if (condition.conditionType == NodeConditionTypeEnum.Timer) {
			this.setState({
				currentConditionTimeAmount: parseInt(condition.parameters[1][0]),
				currentConditionTimeUnit: condition.parameters[2][0],
			});
		} else if (condition.conditionType == NodeConditionTypeEnum.Response) {
			this.setState({
				currentConditionResponseTexts: condition.parameters[1]
					? condition.parameters[1]
					: [],
				AIThreshold:
					condition.parameters[2] && condition.parameters[2]
						? condition.parameters[2]
						: [AIThresholdValueEnum.Med],
			});
		}
	}

	updateName(name: string) {
		this.setState({ triggerName: name });
	}

	getConditionStrings() {
		const conditionStringified = this.state.conditions.map((condition, index) => {
			const tagElem = (
				<Tag key={condition.name} onClick={() => this.loadCondition(index)}>
					{condition.name}
				</Tag>
			);

			return tagElem;
		});
		return conditionStringified;
	}

	render() {
		const conditions = this.getConditionStrings();
		const { triggerName } = this.state;
		return (
			<Form
				ref={this.formRef}
				onFinish={this.saveCondition}
				style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}
			>
				<Row style={{ display: 'block' }}>
					<EditableTextDisplay
						value={triggerName || ''}
						confirmHandler={this.updateName}
						confirmOnBlur
					>
						{triggerName}
					</EditableTextDisplay>
					<hr />
					<br />
					<Col>
						<h3>Conditions</h3>
					</Col>
				</Row>
				<Row>
					<Col>{conditions}</Col>
				</Row>
				{!this.state.addCondition && (
					<>
						<Row style={{ flexGrow: 1 }}>
							<Col>
								<Tag
									onClick={this.addCondition}
									style={{ background: '#FFF', borderStyle: 'dashed' }}
								>
									<PlusOutlined /> Add Condition
								</Tag>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button onClick={this.saveTrigger} type="primary">
									Update Trigger
								</Button>
							</Col>
						</Row>
					</>
				)}
				{this.state.addCondition && (
					<>
						<Row style={{ marginBottom: '16px' }}>
							<Col>
								<Radio.Group
									value={this.state.currentConditionType}
									buttonStyle="solid"
									onChange={this.changeCurrentType}
								>
									<Radio.Button value={NodeConditionTypeEnum.Timer}>
										Time condition
									</Radio.Button>
									<Radio.Button value={NodeConditionTypeEnum.Response}>
										Response condition
									</Radio.Button>
								</Radio.Group>
							</Col>
						</Row>
						<Row style={{ flexGrow: 1 }}>
							<Col style={{ flexGrow: 1, width: '100%' }}>
								<br />
								{this.state.currentConditionType == NodeConditionTypeEnum.Timer && (
									<TimeTrigger
										selectPeriod={this.changeCurrentSubType}
										selectAmount={this.changeCurrentTimeAmount}
										selectUnit={this.changeCurrentTimeUnit}
										currentUnit={this.state.currentConditionTimeUnit}
										currentAmount={this.state.currentConditionTimeAmount}
										currentSubType={
											this.state
												.currentConditionSubType as NodeConditionTimeTypeEnum
										}
									/>
								)}
								{this.state.currentConditionType ==
									NodeConditionTypeEnum.Response && (
									<ResponseTrigger
										form={this.formRef.current}
										currentConditionResponseTexts={
											this.state.currentConditionResponseTexts
										}
										changeCurrentSubType={this.changeCurrentSubType}
										changeCurrentResponseExample={
											this.changeCurrentResponseExample
										}
										currentSubType={
											this.state
												.currentConditionSubType as NodeConditionResponseTypeEnum
										}
										changeAIThreshold={this.changeAIThreshold}
										AIThreshold={this.state.AIThreshold}
									/>
								)}
							</Col>
						</Row>
						<Row gutter={16} style={{ flexShrink: 0 }}>
							<Col>
								<Button htmlType="submit" type="primary">
									Update Condition
								</Button>
							</Col>
							<Col>
								<Button onClick={this.cancelEdit} type="primary">
									Cancel
								</Button>
							</Col>
							<Col>
								<Button onClick={this.removeCondition} danger type="primary">
									Delete
								</Button>
							</Col>
						</Row>
					</>
				)}
			</Form>
		);
	}
}

export default TriggerEditForm;
