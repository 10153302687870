// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nxNpSVU5L3_bsA9pSvvy {\n  margin-right: 24px;\n}\n.nxNpSVU5L3_bsA9pSvvy.ant-typography {\n  margin: 0;\n  font-weight: 400;\n  font-size: 14px;\n}\n.CuyMOn_B9evpNZJ8niAA.ant-typography.ant-typography {\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  /* 157.143% */\n  margin: 0;\n}\n.XNkFR5gCB43vh58Ch5Zh {\n  min-width: 250px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.R1yM78erFiLc7_2wi9t9 {\n  margin-top: 16px;\n  display: flex;\n  align-items: center;\n  column-gap: 12px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/campaignDashboard/teamMemberDropDown/dropdownWithTitle.module.less"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;AAAC;EACC,SAAA;EACA,gBAAA;EACA,eAAA;AAEF;AAGC;EACC,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EADA,aAAa;EAEb,SAAA;AAAF;AAIA;EACC,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAFD;AAKA;EACC,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AAHD","sourcesContent":[".description {\n\tmargin-right: 24px;\n\t&:global(.ant-typography) {\n\t\tmargin: 0;\n\t\tfont-weight: 400;\n\t\tfont-size: 14px;\n\t}\n}\n\n.title {\n\t&:global(.ant-typography.ant-typography) {\n\t\tfont-size: 14px;\n\t\tfont-style: normal;\n\t\tfont-weight: 500;\n\t\tline-height: 22px; /* 157.143% */\n\t\tmargin: 0;\n\t}\n}\n\n.dropDownButton {\n\tmin-width: 250px;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n}\n\n.dropDownWrapper {\n\tmargin-top: 16px;\n\tdisplay: flex;\n\talign-items: center;\n\tcolumn-gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "nxNpSVU5L3_bsA9pSvvy",
	"title": "CuyMOn_B9evpNZJ8niAA",
	"dropDownButton": "XNkFR5gCB43vh58Ch5Zh",
	"dropDownWrapper": "R1yM78erFiLc7_2wi9t9"
};
export default ___CSS_LOADER_EXPORT___;
