import {
	InboxResponse,
	SequenceNodeResponse,
	InboxMessageDetailResponse,
	InboxMessageTagModel,
} from '../interface';
import { SentimentNameMap } from '@copilot/common/constant/enum';
import { InMailStatus } from '../../../../web/common/constant/inMailConstant';
import { ReminderStatus } from '@copilot/common/pages/inbox/data/types';

export class InboxModel implements InboxResponse {
	id: string;
	campaignId: string;
	campaignName: string;
	campaignType: string;
	orgMemberId: string;
	orgMemberName: string;
	campaignMemberId: string;
	contactId: string;
	contactName: string;
	connectionId: string;
	currentNode: SequenceNodeResponse;
	nextNodes: SequenceNodeResponse[];
	lastReceived: InboxMessageDetailResponse;
	lastSent: InboxMessageDetailResponse | undefined;
	lastMessage: InboxMessageDetailResponse;
	primaryCampaignColour: string;
	threadId: string;
	linkedInThreadId: string;
	aiResult: { [s: string]: { confidence: number; value: any } };
	polarity: string;
	status: any;
	inProgress: boolean;
	company: string;
	position: string;
	phoneNumber: string;
	email: string;
	isRead: boolean;
	isConversationRestricted: boolean;
	isLoggedIn: boolean;
	isLiSessionInRetryState: boolean;
	isSnoozed: boolean | undefined;
	tags: InboxMessageTagModel[];
	messageCount: number;
	linkedInProfileId: string;
	inMail?: {
		status: InMailStatus;
	};
	reminder?: {
		hasReminder: boolean;
		reminderTimestamp: string;
		reminderType: ReminderStatus;
	};

	constructor(message: InboxResponse) {
		this.id = message.id;
		this.orgMemberId = message.orgMemberId;
		this.contactId = message.contactId;
		this.campaignId = message.campaignId;
		this.threadId = message.threadId;
		this.campaignName = message.campaignName;
		this.campaignType = message.campaignType;
		this.connectionId = message.connectionId;
		this.orgMemberName = message.orgMemberName;
		this.status = message.status;
		this.campaignMemberId = message.campaignMemberId;
		this.contactName = message.contactName;
		this.currentNode = message.currentNode;
		this.nextNodes = message.nextNodes;
		this.lastReceived = message.lastReceived;
		this.lastSent = message.lastSent;
		this.lastMessage = message.lastMessage;
		this.primaryCampaignColour = message.primaryCampaignColour;
		this.linkedInThreadId = message.linkedInThreadId;
		this.inProgress = message.inProgress;
		this.aiResult = message.aiResult;
		this.polarity = SentimentNameMap[message.polarity as keyof typeof SentimentNameMap];
		this.company = message.company;
		this.position = message.position;
		this.phoneNumber = message.phoneNumber;
		this.email = message.email;
		this.isRead = message.isRead;
		this.isSnoozed = message.isSnoozed;
		this.isConversationRestricted = message.isConversationRestricted;
		this.isLoggedIn = message.isLoggedIn;
		this.isLiSessionInRetryState = message.isLiSessionInRetryState;
		this.tags = message.tags;
		this.messageCount = message.messageCount;
		this.linkedInProfileId = message.linkedInProfileId;
		this.inMail = message.inMail;
		this.reminder = message.reminder;
	}
}
