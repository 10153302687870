import { VerticalDivider } from '@copilot/common/components/verticalDivider';
import { Col, Row } from 'antd';
import { isNil } from 'lodash';
import { useTheme } from 'styled-components';
import { formatPercentage, getCalculationString } from '../helpers';
import { InMailStats } from '../types';
import { PercentageRingAndDescription } from '@copilot/common/pages/campaignDashboard/summaryV2/campaignPerformance/percentageRingAndDescription/percentageRingAndDescription';
import { throwError } from '@copilot/common/utils';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';

type OpenInMailPercentageRingAndDescriptionRowProps = {
	/**
	 * The statistics of the Open InMail
	 */
	campaignStats?: InMailStats;
	/**
	 * Whether or not the stats is loading
	 */
	isLoading: boolean;
	/**
	 * Whether or not calculations should be shown
	 */
	isShowingCalculations: boolean;
};

/**
 * Internal component for Open InMail campaigns
 * Displays a row of conditionally coloured percentage rings with descriptions
 */
export function OpenInMailPercentageRingAndDescriptionRow({
	isLoading,
	campaignStats,
	isShowingCalculations,
}: OpenInMailPercentageRingAndDescriptionRowProps) {
	const theme = useTheme();

	if (isLoading) return <LoadingIndicator isLoading style={{ width: '100%' }} />;
	if (isNil(campaignStats)) throwError('Campaign Stats not provided');

	const {
		percentInterestedInMailReplied,
		numInterestedInMailReplies,
		numInMailSent,
		percentInMailReplied,
		numInMailReplies,
	} = campaignStats;

	const isReplyRateValid = !isNil(percentInMailReplied);
	const replyRate = isReplyRateValid ? formatPercentage(percentInMailReplied) : 0;

	const isInterestedRateValid = !isNil(percentInterestedInMailReplied);
	const interestedRate = isInterestedRateValid
		? formatPercentage(percentInterestedInMailReplied)
		: 0;

	return (
		<Row justify={'space-evenly'} gutter={[theme['@spacer-num-xs'], 0]}>
			<Col span={7}>
				<PercentageRingAndDescription
					isValid={isReplyRateValid}
					textColour={theme['@info-color']}
					metricName="Reply rate"
					metricValue={replyRate}
					ringColour={theme['@info-color']}
					showCalculations={isShowingCalculations}
					calculationDescription={getCalculationString(
						'replies',
						'messages',
						numInMailReplies,
						numInMailSent,
						replyRate
					)}
				/>
			</Col>
			<Col>
				<VerticalDivider />
			</Col>
			<Col span={7}>
				<PercentageRingAndDescription
					isValid={isInterestedRateValid}
					textColour={theme['@info-color']}
					metricName="Interested rate"
					metricValue={interestedRate}
					ringColour={theme['@info-color']}
					showCalculations={isShowingCalculations}
					calculationDescription={getCalculationString(
						'interested replies',
						'replies',
						numInterestedInMailReplies,
						numInMailReplies,
						interestedRate
					)}
				/>
			</Col>
		</Row>
	);
}
