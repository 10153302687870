import React, { useState } from 'react';
import { Col, Row, Radio, Select, Alert, Divider } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import LocationCascadeSelection from '@copilot/common/components/forms/common/generics/locationcascader';
import { Regex } from '@copilot/data/utils/index';

export enum LocationType {
	City = 'City',
	Zip = 'Zip',
}

export interface ILocation {
	locations: string[];
	zip: string[];
	radius: string;
	locationTab: LocationType;
}

interface LinkedInSearchLocationProps {
	location?: ILocation;
	onUpdate: (location: ILocation) => unknown;
}

const LinkedInSearchLocation: React.FC<LinkedInSearchLocationProps> = (props) => {
	const {
		location = {
			locations: [],
			zip: [],
			radius: '25',
			locationTab: LocationType.City,
		},
		onUpdate,
	} = props;

	const [errorMessage, setErrorMessage] = useState('');

	const handleTabChange = (e: RadioChangeEvent) => {
		const { value } = e.target;
		onUpdate?.({ ...location, locationTab: value });
	};

	const handleLocationAdd = (addedLocation: string) => {
		onUpdate?.({ ...location, locations: [...location.locations, addedLocation] });
	};
	const handleLocationRemove = (removedLocation: string) => {
		const newLocations = location.locations.filter((l) => l !== removedLocation);
		onUpdate?.({ ...location, locations: newLocations });
	};

	const handleZipChange = (zips: string[]) => {
		for (const zip of zips) {
			if (!Regex.UsZip.test(zip) && !Regex.CanadianPostalCode.test(zip)) {
				setErrorMessage('Please enter a valid US or Canadian zip code.');
				return false;
			}
		}
		setErrorMessage('');
		onUpdate?.({ ...location, zip: zips });
		return true;
	};

	const handleRadiusChange = (radius: string) => {
		onUpdate?.({ ...location, radius });
	};

	return (
		<Row>
			<Col span={24}>
				<Row>
					<Col>
						<h1>Location</h1>
						<p>
							Select below to search by City OR Postal/Zip Code for your ideal
							prospects.
						</p>
					</Col>
				</Row>
				<Row>
					<Col style={{ width: '100%', maxWidth: '650px' }}>
						<Radio.Group
							defaultValue={location.locationTab}
							buttonStyle="solid"
							onChange={handleTabChange}
							style={{ width: '100%' }}
						>
							<Radio.Button
								style={{ width: '50%', textAlign: 'center' }}
								value={LocationType.City}
							>
								Search by City
							</Radio.Button>
							<Radio.Button
								style={{ width: '50%', textAlign: 'center' }}
								value={LocationType.Zip}
							>
								Search by Postal/Zip Code
							</Radio.Button>
						</Radio.Group>
					</Col>
				</Row>
				<Divider />
				{location.locationTab === LocationType.City && (
					<Row>
						<Col style={{ width: '100%' }}>
							<LocationCascadeSelection
								selectedLocationStrings={location.locations}
								title="Search Prospects by City"
								additionCallback={handleLocationAdd}
								removeCallback={handleLocationRemove}
							/>
						</Col>
					</Row>
				)}
				{location.locationTab === LocationType.Zip && (
					<>
						<Row>
							<Col>
								<h3>Search prospects by Zip or Postal Code</h3>
								<p style={{ fontSize: '14px' }}>
									Separate multiple codes with commas or press Enter.
								</p>
							</Col>
						</Row>
						<Row style={{ display: 'block' }}>
							<Col>
								<Select
									mode="tags"
									style={{ width: '50%', minWidth: '400px' }}
									maxTagCount={25}
									value={location.zip}
									showArrow={false}
									tokenSeparators={[',']}
									placeholder="Input zip or postal codes (ex. 01951, V7L 4X8)"
									onChange={handleZipChange}
									open={false}
								/>
							</Col>
						</Row>
						<br />
						<Row>
							<Col>
								{errorMessage && <Alert message={errorMessage} type="error" />}
							</Col>
						</Row>
						<br />
						<Row>
							<Col>
								<h3>
									Apply a radius to each of the Zip/Postal Codes inputted above.
								</h3>
								<p style={{ fontSize: '14px' }}>
									If you don't choose a radius, the system defaults to a 25 mile
									radius.
								</p>
							</Col>
						</Row>
						<Row>
							<Col style={{ width: '50%', minWidth: '400px' }}>
								<Select
									showArrow
									placeholder="Select Search Radius"
									onChange={handleRadiusChange}
									value={location.radius}
									defaultValue="25"
									style={{ width: '100%' }}
								>
									<Select.Option value="25">25 miles</Select.Option>
									<Select.Option value="35">35 miles</Select.Option>
									<Select.Option value="50">50 miles</Select.Option>
									<Select.Option value="75">75 miles</Select.Option>
									<Select.Option value="100">100 miles</Select.Option>
								</Select>
							</Col>
						</Row>
					</>
				)}
			</Col>
		</Row>
	);
};

export default LinkedInSearchLocation;
