import { attr, fk, many } from 'redux-orm';
import { Organization } from './organization';
import { OrganizationMember } from './organizationMember';
import { Campaign } from './campaign';
import { BaseModel } from './base';
import { IContactTag } from './contactTag';

export type Note = {
	id: string;
	orgMemberId: string;
	contactId: string;
	connectionId: string;
	campaignMemberId: string;
	timestamp: Date;
	message: string;
	metaData: { [k: string]: string };
};

export interface IReminder {
	id: string;
	orgMemberId: string;
	contactId: string;
	connectionId: string;
	campaignMemberId: string;
	timestamp: Date;
	entityType: number;
	message: string;
	targetNodeId: string;
	triggerDateTime: Date;
	metaData: { [k: string]: string };
}

interface Fields {
	addressLine: string;
	city: string;
	company: string;
	country: string;
	dateCreated: Date;
	email: string;
	firstName: string;
	id: string;
	lastName: string;
	notes: Note[];
	reminders: IReminder[];
	lastThreadActivity: Date;
	organizationId: string;
	phoneNumber: string;
	position: string;
	tags: IContactTag[];
	campaignIds: string[];
	zipCode: string;
	connectedMemberIds: string[];
	connectedMembers: string[];
	campaignName: string[];
}

interface InstanceFields {
	organization: Organization;
	campaigns: Campaign[];
	organizationmembers: OrganizationMember[];
}

export type IContact = Fields & InstanceFields;

export class Contact extends BaseModel<typeof Contact, IContact> {
	static parseModels = {};
	static modelName = 'Contact';
	static fields = {
		addressLine: attr(),
		name: attr(),
		city: attr(),
		company: attr(),
		country: attr(),
		dateCreated: attr(),
		email: attr(),
		firstName: attr(),
		id: attr(),
		lastName: attr(),
		notes: attr({ getDefault: () => [] }),
		reminders: attr({ getDefault: () => [] }),
		lastThreadActivity: attr(),
		organizationId: fk({ to: 'Organization' }),
		phoneNumber: attr(),
		position: attr(),
		tags: attr({ getDefault: () => [] }),
		zipCode: attr(),
		campaignIds: many({ to: 'Campaign', as: 'campaigns' }),
		connectedMemberIds: many({ to: 'OrganizationMember', as: 'organizationmembers' }),
		connectedMembers: attr(),
		campaignName: attr(),
	};
}
