import { AxiosResponse } from 'axios';
import { BACKEND_ROUTES } from '../config/routes';
import { CreditsDescriptor, PersonalityInsightsResponse } from '../responses/interface';
import BaseDataManager, { QueryObject } from './base';
import { isNil } from 'lodash';

interface Interest {
	Count: number;
	Share: number;
	'Sub Interests': Interest[];
	Names: string;
}

interface LinkedInWorkEntry {
	organization: string;
	start_date: string;
	title: string;
}

interface EmailPersonalization {
	'Bullet Points': string;
	'Closing Line': string;
	'Complimentary Close': string;
	'Emojis/GIFs': string;
	Greeting: string;
	'Length Of Mail': string;
	'Overall Messaging': string;
	Salutation: string;
	Subject: string;
	'Tone Of Words': string;
}

interface Persona {
	interests?: Interest[];
	email_personalization: {
		advice: EmailPersonalization;
		definitions: EmailPersonalization;
		examples: EmailPersonalization;
	};
	communication_advice: {
		adjectives: string[];
		description: string[];
		key_traits: {
			[key: string]: string;
		};
		what_to_avoid: string[];
		what_to_say: string[];
	};
	behavioural_factors?: {
		[key: string]: {
			score: number;
			level: string;
		};
	};
	conversation_starters?: string[];
}

interface Assessment {
	description: string[];
	label: string[];
}

interface OceanAssessmentQuality {
	score: number;
	level: string;
}

export interface OceanAssessmentType {
	agreeableness: OceanAssessmentQuality;
	conscientiousness: OceanAssessmentQuality;
	emotional_stability: OceanAssessmentQuality;
	extraversion: OceanAssessmentQuality;
	openness: OceanAssessmentQuality;
}

interface PersonalityAnalysis {
	summary: {
		disc: Assessment;
		ocean: Assessment;
	};
	ocean_assessment: OceanAssessmentType;
}

export interface InsightAnalysis {
	display_name: string;
	user_name: string;
	user_description: string;
	user_profile_image: string;
	location: string;
	latest_title: string;
	work_history: LinkedInWorkEntry[];
	persona: {
		[key: string]: Persona;
	};
	personality_analysis: PersonalityAnalysis;
}

/**
 * DTO for the CommunicationStyle analysis
 */
export type CommunicationStyleAnalysis = Readonly<{
	// Details about the analyzed profile
	profileDetails: Readonly<{
		id: string;
		company: string;
		name: string;
		title: string;
		alias: string;
		isOpenProfile: boolean;
	}>;
	// The communication style of the profile
	analysis: Readonly<{
		communicationStyles: string[];
	}>;
	analysisStatus: string;
	// The credits used for the analysis
	credits: CreditsDescriptor;
}>;

class PersonalizedInsightsManager extends BaseDataManager {
	private analysisRoute: string;
	private communicationStyleRoute: string;

	constructor() {
		super();
		this.analysisRoute = BACKEND_ROUTES.PERSONALITY_INSIGHTS.Analysis;
		this.communicationStyleRoute = BACKEND_ROUTES.PERSONALITY_INSIGHTS.CommunicationStyle;
		this.fetchCommunicationStyle = this.fetchCommunicationStyle.bind(this);
		this.createCommunicationStyle = this.createCommunicationStyle.bind(this);
	}

	public getRemainingCredits = (): Promise<AxiosResponse<CreditsDescriptor, any>> => {
		return this.RequestManager.get<CreditsDescriptor>(
			BACKEND_ROUTES.PERSONALITY_INSIGHTS.Credits
		);
	};

	public fetchAnalysis = (
		profileId: string
	): Promise<AxiosResponse<PersonalityInsightsResponse, any>> => {
		const query = new QueryObject();
		query.addParameter('profileId', profileId);
		return this.RequestManager.get<PersonalityInsightsResponse>(this.analysisRoute, {
			params: query.toQueryParam(),
		});
	};

	public createAnalysis = (
		profileId: string
	): Promise<AxiosResponse<PersonalityInsightsResponse, any>> => {
		return this.RequestManager.put<PersonalityInsightsResponse>(this.analysisRoute, {
			profileId: profileId,
		});
	};

	/**
	 * Create a communication style analysis for a given profile
	 * @param profileId The profile we want to create a communication analysis for
	 * @returns
	 */
	public async createCommunicationStyle(profileId: string): Promise<CommunicationStyleAnalysis> {
		const result = await this.RequestManager.put<CommunicationStyleAnalysis>(
			this.communicationStyleRoute,
			{
				profileId: profileId,
			}
		);
		return result.data;
	}

	/**
	 * Get the communication style analysis for a given profile
	 * @param profileId The profile we want to get the communication style analysis for
	 * @returns
	 */
	public async fetchCommunicationStyle(
		profileId: string
	): Promise<CommunicationStyleAnalysis | null> {
		try {
			if (isNil(profileId)) {
				throw new Error('Invalid profileId');
			}

			const response = await this.RequestManager.get<CommunicationStyleAnalysis>(
				this.communicationStyleRoute,
				{
					params: {
						profileId: profileId,
					},
				}
			);
			return response.data;
		} catch (error: any) {
			if (error.status === 404) {
				return null;
			} else {
				return Promise.reject(error);
			}
		}
	}
}

const instance = new PersonalizedInsightsManager();
export { instance as PersonalizedInsightsManager };
