import { useCallback } from 'react';
import useTracking from '@copilot/common/hooks/tracking';
import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';

type AdvancedTeamUserTrackingType =
	| 'Advanced Team User - Messaging Updated'
	| 'Org Member Demoted from Admin to Advanced'
	| 'Org Member Promoted from Base to Advanced';

interface AdvancedTeamUserTrackingParams {
	buttonClicked: AdvancedTeamUserTrackingType;
}

export const useAdvancedTeamUserTracking = (
	componentName: string,
	params: AdvancedTeamUserTrackingParams | null = null
): ((newParams: AdvancedTeamUserTrackingParams) => void) =>
	useTracking(componentName, 'advanced_team_user', params);

/**
 * Determine the correct tracking and update tracking params when an org member gets updated
 */
export const useTeamUserRoleChangeTracking = (componentName: string) => {
	const updateTracking = useAdvancedTeamUserTracking(componentName);
	const updateTeamUserRoleTracking = useCallback(
		(prevRole: string, newRole: string) => {
			if (newRole === OrgRoleTitles.Advanced) {
				switch (prevRole) {
					case OrgRoleTitles.Owner:
					case OrgRoleTitles.Admin:
						updateTracking({
							buttonClicked: 'Org Member Demoted from Admin to Advanced',
						});
						break;
					case OrgRoleTitles.User:
						updateTracking({
							buttonClicked: 'Org Member Promoted from Base to Advanced',
						});
						break;
				}
			}
		},
		[updateTracking]
	);

	return updateTeamUserRoleTracking;
};
