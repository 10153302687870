export const APPLY_FILTERS_TRACKING_ID = 'apply-filters';
export const CAMPAIGN_ATTENTION_REQUIRED_CTA_TRACKING_ID = 'campaign-attention-required-cta';
export const CAMPAIGN_ATTENTION_REQUIRED_TOGGLE_TRACKING_ID = 'campaign-attention-required-toggle';
export const CAMPAIGN_CREATE_SAVE_TRACKING_ID = 'campaign-create-save';
export const CAMPAIGN_DUPLICATE_PROSPECT_TOGGLE_TRACKING_ID = 'campaign-duplicate-prospect-toggle';
export const CAMPAIGN_INMAIL_TOGGLE_TRACKING_ID = 'campaign-inmail-toggle';
export const CAMPAIGN_LIST_CREATE_NURTURE_TRACKING_ID = 'campaign-list-create-nurture';
export const CAMPAIGN_LIST_CREATE_PROSPECTING_TRACKING_ID = 'campaign-list-create-prospecting';
export const CAMPAIGN_LIST_SEARCH_TRACKING_ID = 'campaign-list-search';
export const CAMPAIGN_MEMBER_ADD_TRACKING_ID = 'campaign-member-add';
export const CAMPAIGN_MESSAGE_ADD_TRACKING_ID = 'campaign-message-add';
export const CAMPAIGN_MESSAGE_DELETE_TRACKING_ID = 'campaign-message-delete';
export const CAMPAIGN_MESSAGE_EDIT_TRACKING_ID = 'campaign-message-edit';
export const CAMPAIGN_MESSAGE_SAVE_TRACKING_ID = 'campaign-message-save';
export const CAMPAIGN_NURTURE_MESSAGE_ADD_TRACKING_ID = 'campaign-nurture-message-add';
export const CAMPAIGN_NURTURE_TIMING_SAVE_TRACKING_ID = 'campaign-nurture-timing-save';
export const CAMPAIGN_NURTURE_TOGGLE_TRACKING_ID = 'campaign-nurture-toggle';
export const CAMPAIGN_PROSPECTING_TOGGLE_TRACKING_ID = 'campaign-prospecting-toggle';
export const CAMPAIGN_RUN_SEARCH_TRACKING_ID = 'campaign-run-search';
export const CAMPAIGN_STOP_AUTOMATION_TRACKING_ID = 'campaign-stop-automation';
export const CAMPAIGN_VIEW_LI_PROFILE_TRACKING_ID = 'campaign-view-li-profile';
export const CONNECTIONS_SAVE_TEMPLATE_TRACKING_ID = 'connections-save-template';
export const CONNECTIONS_SEARCH_TRACKING_ID = 'connections-search';
export const CONNECTIONS_VIEW_TEMPLATE_TRACKING_ID = 'connections-view-template';
export const CONVERSATION_ITEM_VIEW_LI_PROFILE_TRACKING_ID = 'conversation-item-view-li-profile';
export const DRAWER_CONTAINER_TRACKING_ID = 'drawer-container';
export const DRAWER_CONVERSATION_NAV_TRACKING_ID = 'drawer-conversation-nav';
export const DRAWER_MARK_MEETING_BOOKED_TRACKING_ID = 'drawer-mark-meeting-booked';
export const DRAWER_PROSPECT_INFO_TOGGLE_TRACKING_ID = 'drawer-prospect-info-toggle';
export const DRAWER_SAVE_NOTE_TRACKING_ID = 'drawer-save-note';
export const DRAWER_SAVE_USER_INVITES_ALLOCATION_TRACKING_ID = 'drawer-save-user-invite-allocation';
export const DRAWER_SAVE_USER_MESSAGES_ALLOCATION_TRACKING_ID =
	'drawer-save-user-message-allocation';
export const DRAWER_TAGS_SAVE_TRACKING_ID = 'drawer-tags-save';
export const DRAWER_TAGS_TOGGLE_TRACKING_ID = 'drawer-tags-toggle';
export const DRAWER_VIEW_INSIGHTS_TRACKING_ID = 'drawer-view-insights';
export const DRAWER_VIEW_LI_PROFILE_TRACKING_ID = 'drawer-view-li-profile';
export const EXPORT_CSV_TRACKING_ID = 'export-csv';
export const INBOX_CARD_MARK_READ_TRACKING_ID = 'inbox-card-mark-read';
export const INBOX_CARD_SENTIMENT_FILTER_TRACKING_ID = 'inbox-card-sentiment-filter';
export const INBOX_CARD_SET_REMINDER_TRACKING_ID = 'inbox-card-set-reminder';
export const INBOX_CARD_VIEW_CONVERSATION_TRACKING_ID = 'inbox-card-view-conversation';
export const INBOX_CARD_VIEW_LI_PROFILE_TRACKING_ID = 'inbox-card-view-li-profile';
export const INBOX_DELETE_TRACKING_ID = 'inbox-delete';
export const INBOX_OPEN_DRAWER_TRACKING_ID = 'inbox-open-drawer';
export const INBOX_SEARCH_TRACKING_ID = 'inbox-search';
export const INBOX_SEND_MESSAGE_TRACKING_ID = 'inbox-send-message';
export const INBOX_SET_REMINDER_TRACKING_ID = 'inbox-set-reminder';
export const INBOX_UNREPLIED_TOGGLE_TRACKING_ID = 'inbox-unreplied-toggle';
export const INBOX_VIEW_LI_PROFILE_TRACKING_ID = 'inbox-view-li-profile';
export const INMAIL_MESSAGE_SAVE_TRACKING_ID = 'inmail-message-save';
export const LINKEDIN_SETTINGS_LINKEDIN_CONNECT_TRACKING_ID = 'linkedin-settings-linkedin-connect';
export const MEETINGS_BOOKED_AI_CONFIRM_TRACKING_ID = 'meetings-booked-ai-confirm';
export const MEETINGS_BOOKED_AI_REJECT_TRACKING_ID = 'meetings-booked-ai-reject';
export const MEETINGS_BOOKED_VIEW_INSIGHTS_TRACKING_ID = 'meetings-booked-view-insights';
export const MESSAGE_LIST_CONTAINER_TRACKING_ID = 'message-list-container';
export const ORG_MEMBER_ADD_TRACKING_ID = 'org-member-add';
export const ORG_MEMBER_INVITE_DRAWER_SAVE_TRACKING_ID = 'org-member-invite-drawer-save';
export const OUTBOX_DISMISS_TRACKING_ID = 'outbox-dismiss';
export const OUTBOX_RETRY_TRACKING_ID = 'outbox-retry';
export const OUTBOX_SCHEDULED_CANCEL_TRACKING_ID = 'outbox-scheduled-cancel';
export const OUTBOX_SCHEDULED_EDIT_TRACKING_ID = 'outbox-scheduled-edit';
export const QUICK_RESPONSE_CREATE_TRACKING_ID = 'quick-response-create';
export const QUICK_RESPONSE_DELETE_TRACKING_ID = 'quick-response-delete';
export const QUICK_RESPONSE_EDIT_TRACKING_ID = 'quick-response-edit';
export const QUICK_RESPONSE_SAVE_TRACKING_ID = 'quick-response-save';
export const SAVE_SCHEDULE_BUTTON_TRACKING_ID = 'campaign-schedule-save';
export const SET_REMINDER_TRACKING_ID = 'set-reminder';
export const SIDE_NAV_CAMPAIGN_CREATE_NURTURE_TRACKING_ID = 'side-nav-campaign-create-nurture';
export const SIDE_NAV_CAMPAIGN_CREATE_PROSPECTING_TRACKING_ID =
	'side-nav-campaign-create-prospecting';
export const SMART_REPLY_TRACKING_ID = 'smart-reply';
export const TAGS_CREATE_TRACKING_ID = 'tags-create';
export const TAGS_SAVE_TRACKING_ID = 'tags-save';
export const TEAM_SETTINGS_DRAWER_LINKEDIN_CONNECT_TRACKING_ID =
	'team-settings-drawer-linkedin-connect';
export const TEAM_SETTINGS_DRAWER_SAVE_TRACKING_ID = 'team-settings-drawer-save';
export const TEXT_EDITOR_AUTOMATION_TRACKING_ID = 'text-editor-resume-automation';
export const TEXT_EDITOR_DROPDOWN_TRACKING_ID = 'text-editor-dropdown';
export const TEXT_EDITOR_SAVE_QUICK_RESPONSE_TRACKING_ID = 'text-editor-save-quick-response';
export const TEXT_EDITOR_SCHEDULE_MESSAGE_TRACKING_ID = 'text-editor-schedule-message';
export const TEXT_EDITOR_SEND_MESSAGE_TRACKING_ID = 'text-editor-send-message';
export const UNRESPOND_REMINDER_TOGGLE_TRACKING_ID = 'unrespond-reminder-toggle';
export const USER_AUTHENTICATED = 'user_linked_in_authenticate';
export const USER_NOTIFICATIONS_SAVE_TRACKING_ID = 'user-notifications-save';
export const USER_SETTINGS_LINKEDIN_CONNECT_TRACKING_ID = 'user-settings-linkedin-connect';
