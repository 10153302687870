import { ActionFactory } from '../factory';
import {
	Client,
	ContactConnection,
	IClient,
	IContactConnection,
	IMessageTemplateFields,
	IPushNotification,
	ITeamClient,
	MessageTemplate,
	PushNotification,
	TeamClient,
} from '../models/redux';

export const ClientActions = ActionFactory<IClient>(Client.modelName);
export const TeamClientActions = ActionFactory<ITeamClient>(TeamClient.modelName);
export const PushNotificationActions = ActionFactory<IPushNotification>(PushNotification.modelName);
export const MessageTemplateActions = ActionFactory<IMessageTemplateFields>(
	MessageTemplate.modelName
);
export const ContactConnectionActions = ActionFactory<IContactConnection>(
	ContactConnection.modelName
);
