import { NotificationModel } from '@copilot/common/store/models/notification';

/**
 * The action type for showing a notification
 */
export const ShowNotificationActionType = 'SHOW_NOTIFICATION';

/**
 * The action for showing a notification
 */
export type ShowNotificationAction = {
	type: typeof ShowNotificationActionType;
	notification: NotificationModel;
	onButtonClick?: () => void;
};

/**
 * Triggers showing a notification
 */
export const showNotification = (notification: NotificationModel, onButtonClick?: () => void) => ({
	type: ShowNotificationActionType,
	notification,
	onButtonClick,
});
