import { ActionTypes } from './types';
import { DrawerAction } from '@copilot/common/utils/drawerManager/action';

export namespace AppActions {
	export const loadAdminUser = (adminUserId: string) => ({
		type: ActionTypes.SetAdminUser,
		payload: adminUserId,
	});

	export const loadActiveUser = (userId: string) => ({
		type: ActionTypes.SetActiveUser,
		payload: userId,
	});

	export const loadActiveTenant = (tenantId: string) => ({
		type: ActionTypes.SetActiveTenant,
		payload: tenantId,
	});

	export const loadActiveImpersonator = ({ id, name }: { id: string; name: string }) => ({
		type: ActionTypes.SetActiveImpersonator,
		payload: { impersonator: name, impersonatorId: id },
	});

	export const loadActiveUserHash = (userHash: string) => ({
		type: ActionTypes.SetActiveUserHash,
		payload: userHash,
	});

	export const removeActiveUser = () => ({ type: ActionTypes.RemoveActiveUser });

	export const loadSettings = (settings: Record<string, any>) => ({
		type: ActionTypes.LoadSettings,
		payload: settings,
	});

	export const updateSettings = (settings: Record<string, any>) => ({
		type: ActionTypes.UpdateSettings,
		payload: settings,
	});

	export const openDrawer = (drawerProps: DrawerAction) => ({
		type: ActionTypes.OpenDrawer,
		payload: drawerProps,
	});

	export const closeDrawer = () => ({
		type: ActionTypes.CloseDrawer,
	});

	export const loadActiveOrganization = (orgId: string) => ({
		type: ActionTypes.SetActiveOrganization,
		payload: orgId,
	});

	export const removeActiveOrganization = () => {
		document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		document.cookie = 'crm_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		return { type: ActionTypes.RemoveActiveOrganization };
	};

	export const setFetchingDataState = (state: boolean) => ({
		type: ActionTypes.IsFetchingData,
		payload: state,
	});
}
