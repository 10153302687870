import styled from 'styled-components';
import { List } from 'antd';

export const InMailCampaignFooterStyle = {
	background: 'rgba(0, 0, 0, 0.02)',
	borderTop: '0.5px solid rgba(0, 0, 0, 0.06)',
	width: 'calc(100% + 8px)',
	marginLeft: '-12px',
	marginRight: '-12px',
	marginBottom: '-12px',
	padding: '8px',
};

export const MessageListItem = styled(List.Item)`
	background-color: white;
	margin: 16px 0px;
	flex-direction: column;
	.item-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	&.activity-item {
		padding: 12px;
		border-radius: 6px;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02),
			0px 1px 2px 0px rgba(0, 0, 0, 0.03);
		align-items: flex-start;
		justify-content: flex-start;
		svg {
			flex-shrink: 0;
		}
	}
	.item-content {
		opacity: 0.65;
		white-space: break-spaces;
	}
	.message-item-wrapper {
		flex-grow: 1;
	}
`;

export function OrgMemberAvatar() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
				fill="#FFB801"
			/>
			<path
				d="M16.7373 15.4398C16.4794 14.8289 16.1052 14.274 15.6354 13.8061C15.167 13.3367 14.6122 12.9625 14.0016 12.7041C13.9961 12.7014 13.9907 12.7 13.9852 12.6973C14.8369 12.082 15.3907 11.0799 15.3907 9.94922C15.3907 8.07617 13.8731 6.55859 12 6.55859C10.127 6.55859 8.60941 8.07617 8.60941 9.94922C8.60941 11.0799 9.16312 12.082 10.0149 12.6986C10.0094 12.7014 10.0039 12.7027 9.99847 12.7055C9.38597 12.9639 8.83636 13.3344 8.36468 13.8074C7.89534 14.2758 7.52113 14.8306 7.26273 15.4412C7.00887 16.039 6.87196 16.6799 6.85941 17.3293C6.85904 17.3439 6.8616 17.3584 6.86694 17.372C6.87227 17.3856 6.88027 17.398 6.89046 17.4084C6.90065 17.4189 6.91284 17.4272 6.92629 17.4328C6.93974 17.4385 6.95419 17.4414 6.96878 17.4414H7.7891C7.84925 17.4414 7.8971 17.3936 7.89847 17.3348C7.92582 16.2793 8.34964 15.2908 9.09886 14.5416C9.87406 13.7664 10.9035 13.3398 12 13.3398C13.0965 13.3398 14.126 13.7664 14.9012 14.5416C15.6504 15.2908 16.0743 16.2793 16.1016 17.3348C16.103 17.3949 16.1508 17.4414 16.211 17.4414H17.0313C17.0459 17.4414 17.0603 17.4385 17.0738 17.4328C17.0872 17.4272 17.0994 17.4189 17.1096 17.4084C17.1198 17.398 17.1278 17.3856 17.1331 17.372C17.1385 17.3584 17.141 17.3439 17.1407 17.3293C17.127 16.6758 16.9916 16.04 16.7373 15.4398ZM12 12.3008C11.3725 12.3008 10.7819 12.0561 10.3375 11.6117C9.8932 11.1674 9.64847 10.5768 9.64847 9.94922C9.64847 9.32168 9.8932 8.73105 10.3375 8.28672C10.7819 7.84238 11.3725 7.59766 12 7.59766C12.6276 7.59766 13.2182 7.84238 13.6625 8.28672C14.1069 8.73105 14.3516 9.32168 14.3516 9.94922C14.3516 10.5768 14.1069 11.1674 13.6625 11.6117C13.2182 12.0561 12.6276 12.3008 12 12.3008Z"
				fill="black"
				fill-opacity="0.65"
			/>
		</svg>
	);
}
