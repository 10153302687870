import React from 'react';
import { Row, Col, Button } from 'antd';

interface NotificationDescriptionProps {
	descriptionText?: string;
	buttonText?: string;
	buttonType?: React.ComponentProps<typeof Button>['type'];
	onButtonClick?: () => void;
}

/**
 * Layout for notification description, with optional text and an optional button
 * @param {string} descriptionText description text (if we want text displayed)
 * @param {string} buttonText text for the button (if we want the button to be displayed)
 * @param {ButtonType} buttonType type of button (primary, default, etc.)
 * @param {function} onButtonClick callback for button
 */
const NotificationDescription: React.FC<NotificationDescriptionProps> = (props) => {
	const { descriptionText, buttonText, buttonType = 'default', onButtonClick } = props;
	return (
		<Row>
			<Col>
				{descriptionText && <p style={{ marginBottom: '8px' }}>{descriptionText}</p>}
				{buttonText && onButtonClick && (
					<Button type={buttonType} onClick={onButtonClick}>
						{buttonText}
					</Button>
				)}
			</Col>
		</Row>
	);
};

export default NotificationDescription;
