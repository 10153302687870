// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xZi6EacSkK0AHtkAsrZt .p6bQXYPZQ8CgHFPKx_ZG {\n  padding-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/drawer/wrappers/accountDashboard/accountCreation/accountCreationForm.module.less"],"names":[],"mappings":"AAGA;EAEI,kBAAA;AAHJ","sourcesContent":["@import '../../../../../../styles/antd-variables.less';\n@import '../../../../../../styles/common-variables.less';\n\n.form {\n  .formItemFirstCol {\n    padding-right: @spacer-num-xxs;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "xZi6EacSkK0AHtkAsrZt",
	"formItemFirstCol": "p6bQXYPZQ8CgHFPKx_ZG"
};
export default ___CSS_LOADER_EXPORT___;
