// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.Ma_Chw_m35nFeAuj_sbH {\n  padding: 0;\n  margin-bottom: 15px;\n  max-width: 1152px;\n  margin-left: auto;\n  margin-right: auto;\n}\ndiv.Ma_Chw_m35nFeAuj_sbH .content.UktRA507ja6iDx3kQ8GD {\n  padding: 0;\n}\n.ORPHj7ZhDOFAUnsD_xBG {\n  background: #f9f9f9;\n  padding: 24px;\n}\n.oVkr_hYzs9pFmy4j0xrt {\n  padding: 24px;\n}\n.FkibRUOKSBVaiEEQ3JVY {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  column-gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/card/messageCard/messageCard.module.less"],"names":[],"mappings":"AAAA;EACC,UAAA;EACA,mBAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AACD;AANA;EAQE,UAAA;AACF;AAGA;EACC,mBAAA;EACA,aAAA;AADD;AAIA;EACC,aAAA;AAFD;AAKA;EACC,aAAA;EACA,uBAAA;EACA,8BAAA;EACA,eAAA;AAHD","sourcesContent":["div.messageCardContainer {\n\tpadding: 0;\n\tmargin-bottom: 15px;\n\tmax-width: 1152px;\n\tmargin-left: auto;\n\tmargin-right: auto;\n\n\t:global(.content).messageCardContainerContent {\n\t\tpadding: 0;\n\t}\n}\n\n.messageCardSenderContainer {\n\tbackground: #f9f9f9;\n\tpadding: 24px;\n}\n\n.messageCardMainContainer {\n\tpadding: 24px;\n}\n\n.messageCardHeaderContent {\n\tdisplay: flex;\n\talign-items: flex-start;\n\tjustify-content: space-between;\n\tcolumn-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageCardContainer": "Ma_Chw_m35nFeAuj_sbH",
	"messageCardContainerContent": "UktRA507ja6iDx3kQ8GD",
	"messageCardSenderContainer": "ORPHj7ZhDOFAUnsD_xBG",
	"messageCardMainContainer": "oVkr_hYzs9pFmy4j0xrt",
	"messageCardHeaderContent": "FkibRUOKSBVaiEEQ3JVY"
};
export default ___CSS_LOADER_EXPORT___;
