import React from 'react';
import { Col, Divider, Row } from 'antd';
import TimezoneSelector from '@copilot/common/components/selector/timezone';
import WeeklyScheduleSelector from '@copilot/common/components/selector/weeklyScheduleSelector';
import { DaySchedule } from '@copilot/data/responses/interface';

interface ScheduleProps {
	weeklySchedule: DaySchedule[];
	timezone: string;
	sync: boolean;
	onSyncUpdate: (state: boolean) => void;
	onTimezoneUpdate: (timezone: string) => void;
	onScheduleUpdate: (schedule: DaySchedule[]) => void;
}

const Schedule: React.FC<ScheduleProps> = (props) => (
	<Row>
		<Col span={24}>
			<Row>
				<Col>
					<h1>Timezone and Sending Hours</h1>
				</Col>
			</Row>
			<Row>
				<Col>
					<p style={{ margin: 0 }}>
						You can set the ideal timezone and sending hours for your business using the
						scales below. Your invites, follow-ups and automated messages will all be
						sent during the selected times.
					</p>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col>
					<TimezoneSelector
						onTimezoneChange={props.onTimezoneUpdate}
						editable
						timezoneId={props.timezone}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<h3>Sending Hours</h3>
				</Col>
			</Row>
			<Row>
				<Col>
					<WeeklyScheduleSelector
						schedule={props.weeklySchedule}
						isSync={props.sync}
						onSyncUpdate={props.onSyncUpdate}
						onUpdate={props.onScheduleUpdate}
						hideFooter
					/>
				</Col>
			</Row>
		</Col>
	</Row>
);

export default Schedule;
