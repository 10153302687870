import { InboxMessageFilters } from '../ui/types';
import { INBOX_MESSAGES } from './constant';
import { InboxModelState } from './reducers';
import { createSelector } from 'reselect';

const selector = (state: Record<string, InboxModelState<InboxMessageFilters>>) =>
	state?.[INBOX_MESSAGES.toLowerCase()];
const inboxSelector = createSelector([selector], (inbox) => inbox);

export default inboxSelector;
