import { IContactTag } from '@copilot/common/store/models/redux';
import { ContactManager } from '@copilot/data';
import { replaceNonAlphaNumerics } from '@copilot/common/utils';
import { ContactTagResponse } from '@copilot/data/responses/interface';
import * as _ from 'lodash';

/**
 * parse updated data to connection-to-data map
 * @param {T[]} data a list of data to get processed into a map
 */
export const parseConnectionToData = <T extends { connectionId: string }>(dataList: T[]) => {
	const connectionMap: Record<string, T[]> = {};
	dataList.forEach((data: T) => {
		if (!connectionMap[data.connectionId]) connectionMap[data.connectionId] = [];
		connectionMap[data.connectionId] = [...connectionMap[data.connectionId], data];
	});
	return connectionMap;
};

/**
 * Get unique list of tags
 * @param tags list of tags to remove duplicates
 */

export const getUniqueTags = (tags: IContactTag[]) =>
	tags.filter((tag, index, self) => index === self.findIndex((t) => t.tagId === tag.tagId));

/**
 * Updates the given connections with the given tags after migration, can specify connection-specific tags to tag the name of the campaign the contact was previously in
 * @param connectionIds ids of connections
 * @param tags list of tags to apply to all connections
 * @param dataMap Mapping between connection-specific tags to apply and connectionIds
 * @param csOrgId organization id, defined only for CS Admins
 */
export const updateConnectionTagsForMigration = async (
	connectionIds: string[],
	tags: string[],
	dataMap: { [k: string]: string[] } | null,
	csOrgId: string | null
): Promise<ContactTagResponse[]> => {
	if (!dataMap) return ContactManager.updateConnectionTags(connectionIds, tags, csOrgId);

	const creators = [];
	for (const tagName in dataMap) {
		const ids = dataMap[tagName];
		creators.push(ContactManager.updateConnectionTags(ids, [...tags, tagName], csOrgId));
	}
	return _.flatten(await Promise.all(creators));
};

/**
 * Create a campaignTag to connectionIds map. Used for creating a tag representing the current campaign name of the
 * connection
 * @param messages list of messages
 */
export const getCampaignTagToConnectionIdsMap = (
	messages: { connectionId: string; campaignName: string }[]
) => {
	const campaignTagToConnectionIdsMap: { [campaignName: string]: string[] } = {};

	messages.forEach((message) => {
		const campaignTagName = replaceNonAlphaNumerics(message.campaignName, ' ');
		campaignTagToConnectionIdsMap[campaignTagName] = campaignTagToConnectionIdsMap[
			campaignTagName
		]
			? [...campaignTagToConnectionIdsMap[campaignTagName], message.connectionId]
			: [message.connectionId];
	});
	return campaignTagToConnectionIdsMap;
};
