import NewUserMessageEditPage from '@copilot/common/components/editors/newUserMessage';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { MessageTypeEnum } from '@copilot/common/pages/onboard/wizard/messaging/const';
import drawerManager from '@copilot/common/utils/drawerManager';
import { OnboardMessage } from '@copilot/data/requests/models';
import { Features } from '@copilot/data/responses/interface';
import { Radio } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import BaseDrawer from '../../base';
import BasicDrawerFooter from '../../base/footer';
import { INewUserMessageWithTemplatesEditDrawerProps } from './const';

/**
 * Message Edit Drawer with templates
 * For new user onboarding
 * @param INewUserMessageWithTemplatesEditDrawerProps
 */
const NewUserMessageWithTemplatesEditDrawer: FC<INewUserMessageWithTemplatesEditDrawerProps> = (
	props
) => {
	const {
		title,
		description,
		selectedStrategy,
		strategies,
		isStrategySelectable,
		selectedTemplateKey,
		templates,
		message,
		maxLength,
		hideTimingEditor,
		timeDescription,
		onSaveMessage,
		closeAlert: _,
		...rest
	} = props;

	const [unsavedMessage, setUnsavedMessage] = useState<OnboardMessage>(message);
	const [unsavedStrategy, setUnsavedStrategy] = useState<MessageTypeEnum>(selectedStrategy);
	const [unsavedTemplateKey, setUnsavedTemplateKey] = useState<string>(selectedTemplateKey);
	const isOnboardMessageImprovementFeatureEnabled = useFeatureToggle(
		Features.OnboardMessageImprovementFeature
	);

	const availableTemplates = useMemo(() => {
		if (isOnboardMessageImprovementFeatureEnabled || unsavedStrategy >= MessageTypeEnum.Sales)
			return [];

		const messageGroup = templates[MessageTypeEnum[unsavedStrategy]];
		return Object.keys(messageGroup).map((k) => ({
			key: `${unsavedStrategy}-${k}`,
			label: k,
			value: messageGroup[k],
		}));
	}, [templates, unsavedStrategy]);

	// Display default template on message editor when there is no message text
	useEffect(() => {
		if (!message.text) {
			const template = availableTemplates.find((t) => t.key === selectedTemplateKey);
			if (!template?.value) return;
			setUnsavedMessage({ ...unsavedMessage, text: template.value });
		}
	}, [message.text, availableTemplates, selectedTemplateKey]);

	const handleUpdateMessage = (msg: OnboardMessage) => {
		setUnsavedMessage(msg);
	};

	const handleUpdateStrategy = useCallback<
		NonNullable<React.ComponentProps<typeof Radio.Group>['onChange']>
	>((event) => {
		setUnsavedStrategy(event.target.value as MessageTypeEnum);
	}, []);

	const handleUpdateTemplate = useCallback((key: string) => {
		setUnsavedTemplateKey(key);
	}, []);

	const handleSave = useCallback(() => {
		onSaveMessage(unsavedMessage, unsavedStrategy, unsavedTemplateKey);
	}, [onSaveMessage, unsavedMessage, unsavedStrategy, unsavedTemplateKey]);

	return (
		<BaseDrawer
			footer={
				<BasicDrawerFooter
					onSave={handleSave}
					onCancel={drawerManager.closeDrawer}
					saveLabel="Save draft"
					cancelLabel="Discard"
					isSaveDisabled={!unsavedMessage.text}
				/>
			}
			{...rest}
		>
			<NewUserMessageEditPage
				title={title}
				description={description}
				selectedStrategy={unsavedStrategy}
				strategies={strategies}
				selectedTemplate={unsavedTemplateKey}
				templates={availableTemplates}
				message={unsavedMessage}
				isStrategySelectable={isStrategySelectable}
				onStrategyUpdate={handleUpdateStrategy}
				onTemplateUpdate={handleUpdateTemplate}
				onMessageUpdate={handleUpdateMessage}
				maxLength={maxLength}
				hideTiming={hideTimingEditor}
				timeDescription={timeDescription}
				isOnboardMessageImprovementFeatureEnabled={
					isOnboardMessageImprovementFeatureEnabled
				}
			/>
		</BaseDrawer>
	);
};

export default NewUserMessageWithTemplatesEditDrawer;
