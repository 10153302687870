import { ComponentProps, FC, isValidElement, ReactElement, ReactNode, useContext } from 'react';
import { Table, Typography } from 'antd';
import BasicContainer from '@copilot/common/components/containers/basic';
import {
	ITableModuleColumnProps,
	ITableModuleItemProps,
	TableContext,
	TableContextAny,
	TableContextState,
	UpdateColumnContextListAction,
	UpdateHiddenColumnsAction,
	UpdateSelectedRowsAction,
} from './types';

/**
 * [Presentational] Table module header
 */
export const TableModuleHeader: FC<ComponentProps<typeof BasicContainer.Header>> = (props) => (
	<BasicContainer.Header {...props} />
);

export const TableModuleColumn = <T,>({
	columnKey,
	...props
}: ITableModuleColumnProps<T>): JSX.Element => <Table.Column<T> key={columnKey} {...props} />;

export const isTableModuleColumn = <T,>(
	element: ReactNode
): element is ReactElement<ITableModuleColumnProps<T>> =>
	isValidElement(element) && element.type === TableModuleColumn;

/**
 * [Presentational] Table module title component
 */
export const TableModuleTitle: FC = (props) => (
	<Typography style={{ fontSize: '1.3em' }}>{props.children}</Typography>
);

/**
 * [Presentational] Table module with styling consistent with rest of table
 */
export const TableModuleStandardTitle: FC = (props) => (
	<Typography className="copilot-table">{props.children}</Typography>
);

export const TableModuleItem = <T,>(props: ITableModuleItemProps<T>): JSX.Element => {
	const { children } = props;
	const context = useContext(TableContextAny) as TableContext<T>;
	return <>{children(context)}</>;
};

export const createUpdateColumnContextListAction = (
	columns: TableContextState['columnContextList']
): UpdateColumnContextListAction => ({ type: 'update_column_context_list', payload: columns });

export const createUpdateSelectedRowsAction = (
	selectedRows: TableContextState['selectedRows']
): UpdateSelectedRowsAction => ({ type: 'update_selected_rows', payload: selectedRows });

export const createUpdateHiddenColumnsAction = (
	columns: { columnKey: string; isVisible: boolean }[]
): UpdateHiddenColumnsAction => ({ type: 'update_hidden_columns', payload: columns });
