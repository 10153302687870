import { Row, Col, Select, Typography, Space, Switch, Flex, Card } from 'antd';
import { useEffect, useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import DateRangeSelector from '@copilot/common/components/forms/common/generics/daterangeselector';
import { disableFutureDates } from '@copilot/common/utils';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { isArray } from 'lodash';
import PercentageRingAndDescriptionRowWithLoading from './percentageRingAndDescription/percentageRingAndDescriptionRow';
import {
	campaignPerformanceDateOptions,
	CampaignPerformanceDateOptions,
	customKey,
	DateFilters,
	getStartOfDay,
	isAllTime,
	last30DaysOption,
	SelectedDateFilter,
} from '@copilot/common/pages/campaignDashboard/summaryV2/dataFilterUtils';
import { CampaignStats } from './percentageRingAndDescription/types';
import styles from './campaignPerformance.module.less';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';
import { OpenInMailPercentageRingAndDescriptionRow } from '@copilot/common/pages/campaignDashboard/summaryV2/campaignPerformance/percentageRingAndDescription/openInMail';
import { isInMailStats } from '@copilot/common/pages/campaignDashboard/helpers';

// Determines whether a given date option value is a preset option (non-custom)
function isPresetDateValue(dateOptionValue: number): boolean {
	return isFinite(dateOptionValue);
}

type CampaignPerformanceProps = {
	/**
	 * Whether or not campaign stats data is loading
	 */
	isLoading: boolean;
	/**
	 * Whether or not InMail stats should be included
	 */
	shouldIncludeInMail: boolean;
	/**
	 * The stats of the campaign
	 * Undefined while loading
	 */
	campaignStats?: CampaignStats;
	/**
	 * Callback to call when selected dates change
	 */
	onChange: (selectedDates: DateFilters) => void;
	/**
	 * The date filter specifying the time range the stats exist within.
	 */
	dateFilter: SelectedDateFilter;
	/**
	 * Function used to update the date filter.
	 */
	setDateFilter: (
		update: SelectedDateFilter | ((update: SelectedDateFilter) => SelectedDateFilter)
	) => void;
};

/**
 * Typing for the state for the show calculation toggle
 */
type IsShowingCalculationState = {
	automatedMessaging: boolean;
	inMail: boolean;
};

/**
 * Date filterable performance section of campaign dashboard
 */
export function CampaignPerformanceInternal({
	isLoading,
	campaignStats,
	dateFilter,
	setDateFilter,
	onChange,
	shouldIncludeInMail,
}: CampaignPerformanceProps) {
	const [isShowingCalculations, setIsShowingCalculations] = useState<IsShowingCalculationState>({
		automatedMessaging: true,
		inMail: true,
	});

	const theme = useTheme();

	useEffect(() => {
		// Cases for requerying:
		// 1. dateOption is changed to a non-custom option
		// 2. custom dates change (not when initially changing to the custom date option)

		// Requerying with undefined custom dates only occurs when explicitly clearing the date range selector
		// and not when initially switching to custom dates. This is because having an undefined custom start
		// and end date would result in fetching all-time stats which is usually not the initial intention of
		// users and is expensive for the BE.

		const { start, end } = dateFilter.selectedDates;
		onChange({
			startMs: start?.valueOf() ?? 0,
			endMs: end?.valueOf(),
		});
	}, [dateFilter.selectedDates]);

	function handleSelectDateOption(
		dateOption: CampaignPerformanceDateOptions | CampaignPerformanceDateOptions[]
	) {
		if (isArray(dateOption)) {
			// Antd Select requires an array as a possible type for the change handler
			// We shouldn't reach this because we do not specify 'mode=multiple' in Select's props
			throw Error('Multiple dateOptions were selected');
		}

		const newValue = dateOption.value;
		if (isPresetDateValue(newValue)) {
			setDateFilter({
				dateOption,
				selectedDates: {
					start: moment(getStartOfDay(newValue)),
				},
			});
		} else if (isAllTime(dateOption.key)) {
			setDateFilter({
				dateOption,
				selectedDates: {
					start: moment(0),
				},
			});
		} else {
			setDateFilter((selectedDateFilters: SelectedDateFilter) => ({
				...selectedDateFilters,
				dateOption,
			}));
		}
	}

	function handleSelectCustomDateRange(startDate?: Date, endDate?: Date) {
		setDateFilter((selectedDateFilters: SelectedDateFilter) => ({
			dateOption: selectedDateFilters.dateOption,
			selectedDates: {
				start: startDate ? moment(startDate) : startDate,
				end: endDate ? moment(endDate) : endDate,
			},
		}));
	}

	return (
		<Flex gap="small" vertical>
			<ErrorBoundary>
				<Card data-testid={'automatedMessagingSection'}>
					<Row justify={'space-between'}>
						<Col>
							<Typography.Text className={styles.title}>
								{shouldIncludeInMail
									? 'Automated Messaging Performance'
									: "This campaign's performance"}
							</Typography.Text>
						</Col>
						<Col>
							<CalendarOutlined style={{ color: theme['@Neutral/70'] }} />
							<Select<number, CampaignPerformanceDateOptions>
								defaultValue={last30DaysOption.value}
								value={dateFilter.dateOption?.value}
								onChange={(value, option) => handleSelectDateOption(option)}
								bordered={false}
							>
								{campaignPerformanceDateOptions.map((option) => (
									<Select.Option key={option.key} value={option.value}>
										{option.label}
									</Select.Option>
								))}
							</Select>
							{dateFilter.dateOption.key === customKey ? (
								<DateRangeSelector
									datePickerProps={{
										value: [
											dateFilter.selectedDates.start ?? null,
											dateFilter.selectedDates.end ?? null,
										],
									}}
									onChange={handleSelectCustomDateRange}
									disabledDate={disableFutureDates}
								/>
							) : null}
						</Col>
					</Row>
					<PercentageRingAndDescriptionRowWithLoading
						isLoading={isLoading}
						campaignStats={campaignStats}
						isShowingCalculations={isShowingCalculations.automatedMessaging}
					/>
					<Row justify={'center'} className={styles.toggleWrapper}>
						<Col>
							<Space>
								<Switch
									data-testid="campaign-perf-calculation-toggle"
									checked={isShowingCalculations.automatedMessaging}
									onChange={(isShowing) =>
										setIsShowingCalculations((prevState) => ({
											...prevState,
											automatedMessaging: isShowing,
										}))
									}
								/>
								<span>Show calculations</span>
							</Space>
						</Col>
					</Row>
				</Card>
			</ErrorBoundary>
			{shouldIncludeInMail ? (
				<ErrorBoundary>
					<Card data-testid={'openInMailSection'}>
						<Row>
							<Col>
								<Typography.Text className={styles.title}>
									InMail Sequence Performance
								</Typography.Text>
							</Col>
						</Row>
						<OpenInMailPercentageRingAndDescriptionRow
							isLoading={isLoading}
							campaignStats={isInMailStats(campaignStats) ? campaignStats : undefined}
							isShowingCalculations={isShowingCalculations.inMail}
						/>
						<Row justify={'center'} className={styles.toggleWrapper}>
							<Col>
								<Space>
									<Switch
										data-testid="campaign-perf-calculation-toggle"
										checked={isShowingCalculations.inMail}
										onChange={(isShowing) =>
											setIsShowingCalculations((prevState) => ({
												...prevState,
												inMail: isShowing,
											}))
										}
									/>
									<span>Show calculations</span>
								</Space>
							</Col>
						</Row>
					</Card>
				</ErrorBoundary>
			) : null}
		</Flex>
	);
}
