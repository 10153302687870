import { BACKEND_ROUTES, B2CCustomPolicyRoutes } from '../config/routes';
import UtilsRequestManager from '../utils/requestManager';
import { AxiosRequestConfig } from 'axios';
import { Config } from '@copilot/common/config';

const RequestManager: UtilsRequestManager = new UtilsRequestManager({
	useB2c: Config.isB2CEnabled,
	b2cOptions: {
		clientId: Config.b2cClientId,
		authorityDomain: Config.b2cLoginDomainName,
		authorityUrl: B2CCustomPolicyRoutes.SignIn,
		scopes: Config.b2cScopes,
		templateName: Config.b2cTemplateName,
	},
});

export class PaginationObject {
	public page: number;
	public pageSize: number;
	constructor(pagination: Partial<PaginationObject> = {}) {
		this.page = pagination.page === undefined ? 0 : pagination.page;
		this.pageSize = pagination.pageSize === undefined ? 10 : pagination.pageSize;
	}
}
export class LoadMorePaginationObject {
	public offset: number;
	public pageSize: number;
	constructor(pagination: Partial<LoadMorePaginationObject> = {}) {
		this.offset = pagination.offset === undefined ? 0 : pagination.offset;
		this.pageSize = pagination.pageSize === undefined ? 10 : pagination.pageSize;
	}
}

class FilterObject {
	public key: string | string[];
	public evaluator: string;
	public value: string;
	constructor(filter: FilterObject) {
		this.key = filter.key;
		this.evaluator = filter.evaluator;
		this.value = filter.value;
	}
}

class SortObject {
	public key: string;
	public order: string;
	constructor(sort: SortObject) {
		this.key = sort.key;
		this.order = sort.order;
	}
}

export class QueryObject extends PaginationObject {
	static FilterObject = FilterObject;
	static SortObject = SortObject;
	public filter: FilterObject[];
	public sort?: SortObject;
	public params: { [k: string]: string };

	constructor(query: Partial<QueryObject> = {}) {
		super(query);
		this.filter = query.filter ?? [];
		this.sort = query.sort;
		this.params = {};
	}

	/**
	 * Add a query parameter
	 * @param key The backend key we want to add
	 * @param value The value we want to use
	 */
	public addParameter = (key: string, value: string) => {
		if (key) {
			this.params[key] = value;
		}
	};

	/**
	 * Remove a query parameter
	 * @param key The key we want to remove
	 */
	public removeParameter = (key: string) => {
		if (this.params[key]) delete this.params[key];
	};

	/**
	 * Add a filter to the query object
	 * @param key The backend key we want to add
	 * @param evaluator The evaluation comparator we want to use
	 * @param value The value we would like to compare with
	 */
	public addFilter = (
		key: FilterObject['key'],
		evaluator: FilterObject['evaluator'],
		value: FilterObject['value']
	) => {
		if (key && value) this.filter.push(new FilterObject({ key, evaluator, value }));
	};

	/**
	 * Remove a filter from the query object
	 * @param key The backend key we want to remove
	 */
	public removeFilter = (key: FilterObject['key']) => {
		if (key) {
			const filtered = this.filter.filter((definition) => definition.key != key);
			this.filter = filtered;
		}
	};

	/**
	 * Add a sorter to the query object
	 * @param {SortObject['key']} key The backend key we want to add
	 * @param {SortObject['order']} order The order we want to sort it with
	 */
	public addSorter = (key: SortObject['key'], order: SortObject['order']) => {
		if (key) this.sort = new SortObject({ key, order });
	};

	/**
	 * Convert this object into an AxiosRequestConfig to use with requests
	 * @returns {AxiosRequestConfig} The AxiosRequestConfig
	 */
	public toQueryParam = (defaultConfig: AxiosRequestConfig = {}): AxiosRequestConfig => {
		const params: AxiosRequestConfig['params'] = { ...defaultConfig, ...this.params };
		if (Array.isArray(this.filter) && this.filter.length > 0) {
			params.filters = this.filter.map((f) => `${f.key}${f.evaluator}${f.value}`).join(',');
		}
		if (this.sort) {
			params.sortby = `${this.sort.order}${this.sort.key}`;
		}
		return params;
	};
}

export interface PaginatedResults<R> {
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	results: R[];
}

abstract class BaseDataManager {
	static QueryObject = QueryObject;
	static RequestManager = RequestManager;
	protected BACKEND_ROUTES = BACKEND_ROUTES;
	protected RequestManager = RequestManager;
	protected currentRequests: { [x: string]: Promise<any> } = {};
	constructor() {}

	/**
	 * Create a url given a bunch of terms
	 * @param {string} url Create a url given a bunch of terms
	 * @param {string[]} args All terms we want to append
	 * @returns {string} The combined URL
	 */
	protected combineRoute(url: string, ...args: string[]): string {
		return encodeURI(`${url}/${args.join('/')}`);
	}

	/**
	 * Paginate a url
	 * @param url The url we want to paginate
	 * @param paginationObject The pagination object
	 */
	protected paginate(
		url: string,
		paginationObject: PaginationObject | LoadMorePaginationObject
	): string;
	protected paginate(url: string, page: number, pageSize: number): string;
	protected paginate(
		url: string,
		paginationObjectOrPage: PaginationObject | number | LoadMorePaginationObject,
		pageSize?: number
	) {
		let pageOrOffset = paginationObjectOrPage;
		if (typeof paginationObjectOrPage !== 'number') {
			pageOrOffset = this.isLoadMorePaginationObject(paginationObjectOrPage)
				? paginationObjectOrPage.offset
				: paginationObjectOrPage.page;
			pageSize = paginationObjectOrPage.pageSize;
		}
		return `${url}/${pageOrOffset}/${pageSize}`;
	}

	protected isLoadMorePaginationObject(
		paginationObj: PaginationObject | LoadMorePaginationObject
	): paginationObj is LoadMorePaginationObject {
		return 'offset' in paginationObj;
	}

	/**
	 * Prevent multiple calls to the same route
	 * @param {string} url The url of the request
	 * @param {() => Promise<any>} request The actual request
	 * @returns {Promise<any>} Return the request
	 */
	protected preventConcurrency<R = any>(url: string, request: () => Promise<R>): Promise<R> {
		if (!(url in this.currentRequests)) this.currentRequests[url] = request();
		return this.currentRequests[url].finally(() => delete this.currentRequests[url]);
	}
}

export default BaseDataManager;
