import React, { useMemo, useState } from 'react';
import { FormProps } from 'antd/lib/form';
import { Input, Button, Form } from 'antd';
import { Validators } from '../../common/validators';
import { UtilityFunctions } from '@copilot/common/utils/common';
import styled from 'styled-components';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { ButtonSize } from 'antd/es/button';

interface LinkedInLoginFormProps {
	isSubmitting: boolean;
	onSubmit: (username: string, password: string) => Promise<void>;
	onResetPassword?: () => void;
	showSkipButton?: boolean;
	colLayout?: Partial<{
		labelCol: FormProps['labelCol'];
		wrapperCol: FormProps['wrapperCol'];
	}>;
	onSkip?: () => void;
	emailLabel?: string;
	emailPlaceholder?: string;
	passwordLabel?: string;
	passwordPlaceholder?: string;
	resetPwdLabel?: string;
	resetPwdCTALabel?: string;
	isButtonFullWidth?: boolean;
	buttonSize?: ButtonSize;
}

const ChromeInput = styled(Input)`
	&.hidePassword {
		-webkit-text-security: disc;
	}
	&.showPassword {
		-webkit-text-security: none;
	}
`;

const LinkedInLoginForm: React.FC<LinkedInLoginFormProps> = (props) => {
	const {
		isSubmitting,
		onSubmit,
		colLayout,
		onSkip,
		onResetPassword,
		showSkipButton = false,
		emailLabel,
		emailPlaceholder,
		passwordLabel,
		passwordPlaceholder,
		resetPwdLabel = 'Having Trouble Remembering?',
		resetPwdCTALabel = 'Reset LinkedIn Password',
		isButtonFullWidth = false,
		buttonSize,
	} = props;
	const [form] = Form.useForm();
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const handleFinish = (values: { username: string; password: string }) => {
		onSubmit?.(values.username, values.password).catch(() => form.resetFields(['password']));
	};
	const isChrome = useMemo(() => UtilityFunctions.isClientChrome(), []);

	const showPasswordIcon = useMemo(
		() =>
			showPassword ? (
				<EyeOutlined onClick={() => setShowPassword(false)} />
			) : (
				<EyeInvisibleOutlined onClick={() => setShowPassword(true)} />
			),
		[showPassword]
	);

	return (
		<Form
			form={form}
			autoComplete="off"
			onFinish={handleFinish}
			scrollToFirstError
			layout="vertical"
			{...colLayout}
		>
			<Form.Item
				name="username"
				key="linkedinEmail"
				label={emailLabel}
				rules={[
					{ type: 'email', message: 'Please enter a valid E-mail.' },
					{ required: true, message: 'Please input your E-mail.' },
				]}
				hasFeedback
			>
				<Input
					type="email"
					name="e-search"
					aria-label="linkedinEmail"
					data-lpignore="true"
					autoComplete="off"
					disabled={isSubmitting}
					placeholder={emailPlaceholder}
				/>
			</Form.Item>
			<Form.Item
				name="password"
				label={passwordLabel}
				hasFeedback
				rules={[{ required: true, validator: Validators.validatePassword }]}
			>
				{isChrome ? (
					<ChromeInput
						type="text"
						name="p-search"
						aria-label="linkedinPassword"
						data-lpignore="true"
						autoComplete="off"
						disabled={isSubmitting}
						onCopy={(e) => e.preventDefault()}
						onCut={(e) => e.preventDefault()}
						suffix={showPasswordIcon}
						className={showPassword ? 'showPassword' : 'hidePassword'}
						placeholder={passwordPlaceholder}
					/>
				) : (
					<Input.Password
						name="p-search"
						aria-label="linkedinPassword"
						data-lpignore="true"
						autoComplete="off"
						disabled={isSubmitting}
						placeholder={passwordPlaceholder}
					/>
				)}
			</Form.Item>

			<Form.Item>
				<label>
					{resetPwdLabel}{' '}
					<a
						href="https://www.linkedin.com/uas/request-password-reset"
						target="_blank"
						onClick={onResetPassword}
					>
						{resetPwdCTALabel}
					</a>
				</label>
			</Form.Item>

			<Form.Item style={{ textAlign: 'right' }}>
				{showSkipButton && onSkip && (
					<Button type="link" htmlType="button" onClick={onSkip}>
						Skip for now
					</Button>
				)}
				<Button
					type="primary"
					htmlType="submit"
					loading={isSubmitting}
					size={buttonSize}
					block={!!isButtonFullWidth}
				>
					Connect
				</Button>
			</Form.Item>
		</Form>
	);
};

export default LinkedInLoginForm;
