import { useRef, useState } from 'react';
import BaseModal, { BaseModalProps } from '../base';
import { Button, Form, Input, Select, Typography } from 'antd';
import MessageEditorTokens, { IToken } from '@copilot/common/components/editors/message/tokens';
import { MessageEditorNameTokens } from '@copilot/common/utils/constant';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { inputAndFocusAtCaret } from '@copilot/common/utils/textArea/textAreaInsert';
import { fontWeightMedium, spacingXS } from '@copilot/common/constant/commonStyles';
import { useCloseModal } from '@copilot/common/hooks/modal';
import { ModalType } from '@copilot/common/store/models/redux/modal';
import Title from 'antd/lib/typography/Title';
import styled from 'styled-components';
import { QUICK_RESPONSE_SAVE_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';

export interface ICreateQuickResponseModalProps extends Omit<BaseModalProps, 'title'> {
	/** Initial Title */
	title?: string;

	/** Initial Message */
	message?: string;

	/** List of IDs & names of campaigns assignable to the Quick Response */
	campaignOptions: { label: string; value: string }[];

	/** Campaigns already assigned to the Quick Response */
	selectedCampaigns: string[];

	/** Callback for when the user clicks save */
	onSaveCallback: (title: string, message: string, campaignOptions: string[]) => void;
}

const FieldName = {
	Title: 'title',
	Message: 'message',
	CampaignOptions: 'campaignOptions',
} as const;
type FormValues = {
	[FieldName.Title]: string;
	[FieldName.Message]: string;
	[FieldName.CampaignOptions]: string[];
};

const SELECT_ALL_CAMPAIGNS_KEY = 'SELECT_ALL_CAMPAIGNS';

/**
 * Smart reply save as quick response modal
 * @param props
 */
function CreateQuickResponseModal(props: ICreateQuickResponseModalProps): JSX.Element {
	const { title, message, campaignOptions, onSaveCallback, selectedCampaigns, ...rest } = props;

	const textAreaRef = useRef<TextAreaRef>(null);
	const closeModal = useCloseModal(ModalType.CreateQuickResponseModal);
	const [allSelectorLabel, setAllSelectorLabel] = useState<string>(
		selectedCampaigns.length === campaignOptions.length ? 'Deselect All' : 'Select All'
	);

	const allSelectableOptions = campaignOptions.map((option) => option.value);

	//includes the select/deselect all option
	const allShownOptions =
		allSelectableOptions.length > 0
			? [{ label: allSelectorLabel, value: SELECT_ALL_CAMPAIGNS_KEY }, ...campaignOptions]
			: [];

	const handleTokenClick = (token: IToken) =>
		inputAndFocusAtCaret(token.value, textAreaRef.current as HTMLAreaElement);

	const onFinish = ({
		[FieldName.Title]: titleField,
		[FieldName.Message]: messageField,
		[FieldName.CampaignOptions]: campaignOptionsField,
	}: FormValues) => {
		onSaveCallback(titleField, messageField, campaignOptionsField);
		closeModal();
	};

	return (
		<BaseModal {...rest} width={613}>
			<div style={{ marginBottom: '16px' }}>
				<Title level={4} style={{ fontSize: '20px', marginBottom: '0px' }}>
					Create a quick response
				</Title>

				<Typography.Text type="secondary">
					Save your frequently used messages as Quick Responses for easy access
				</Typography.Text>
			</div>

			<StyledForm
				onFinish={onFinish}
				layout="vertical"
				initialValues={{
					[FieldName.Title]: title,
					[FieldName.Message]: message,
					[FieldName.CampaignOptions]: selectedCampaigns,
				}}
			>
				<Form.Item
					style={{ fontWeight: fontWeightMedium }}
					label="Title"
					name={FieldName.Title}
					rules={[
						{
							required: true,
							message: 'You will need to give your quick response a title.',
						},
					]}
				>
					<Input style={{ width: '100%' }} placeholder="Name your quick response" />
				</Form.Item>

				<Form.Item
					style={{ fontWeight: fontWeightMedium }}
					label="Message"
					name={FieldName.Message}
					rules={[
						{ required: true, message: 'Your quick response must contain a message' },
					]}
				>
					<Input.TextArea
						style={{ width: '100%', padding: '5 10 5 10' }}
						placeholder="Write a message you want to save"
						autoSize={{ minRows: 3, maxRows: 5 }}
						ref={textAreaRef}
					/>
				</Form.Item>

				<Form.Item style={{ fontWeight: fontWeightMedium }} label="Personalization tokens">
					<MessageEditorTokens
						tokens={MessageEditorNameTokens}
						onTokenClick={handleTokenClick}
					/>
				</Form.Item>

				<Form.Item
					style={{ fontWeight: fontWeightMedium }}
					label="Assign to Campaigns"
					name={FieldName.CampaignOptions}
					rules={[
						{
							required: true,
							message: 'You must assign your quick response to a campaign',
						},
					]}
					normalize={(values: string[]) => {
						let result = values;
						if (values.includes(SELECT_ALL_CAMPAIGNS_KEY)) {
							if (values.length === allShownOptions.length) result = [];
							else result = allSelectableOptions;
						}
						setAllSelectorLabel(
							result.length === allSelectableOptions.length
								? 'Deselect All'
								: 'Select All'
						);
						return result;
					}}
				>
					<Select
						style={{ width: '100%' }}
						mode="multiple"
						placeholder="Assign a campaign"
						options={allShownOptions}
						filterOption={(filter, option) =>
							option?.label
								?.toString()
								.toLowerCase()
								.includes(filter.toLowerCase()) ?? false
						}
						showArrow
						maxTagCount={10}
					/>
				</Form.Item>

				<Form.Item style={{ fontWeight: fontWeightMedium, marginBottom: '0px' }}>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button style={{ marginRight: spacingXS }} onClick={closeModal}>
							Cancel
						</Button>

						<Button
							htmlType="submit"
							type="primary"
							data-tracking-id={QUICK_RESPONSE_SAVE_TRACKING_ID}
						>
							Save
						</Button>
					</div>
				</Form.Item>
			</StyledForm>
		</BaseModal>
	);
}

const StyledForm = styled((props) => <Form {...props} />)`
	.copilot-form-item-label
		> label.copilot-form-item-required:not(.copilot-form-item-required-mark-optional)::before {
		color: red;
	}
`;

export default CreateQuickResponseModal;
