import { List, Popconfirm, Button, Tooltip, Typography } from 'antd';

import { ListItemProps } from 'antd/lib/list';
import { ScheduledMessage } from '@copilot/common/store/models/redux';
import styles from './scheduledMessages.module.less';
import { useState } from 'react';

type ScheduledMessageItemProps = ListItemProps & {
	scheduledMessage: ScheduledMessage;
	onDeleteScheduledMessage: (msg: ScheduledMessage) => unknown;
	isDeletable: boolean;
};

export default function ScheduledMessageItem({
	scheduledMessage,
	onDeleteScheduledMessage: handleDeleteScheduledMessage,
	isDeletable,
}: ScheduledMessageItemProps) {
	const dateString = new Date(scheduledMessage.dateCreated).toLocaleDateString();
	const triggerDate = new Date(scheduledMessage.timestamp).toLocaleString();
	const [isDeleting, setIsDeleting] = useState(false);

	async function onDeleteScheduledMessage() {
		setIsDeleting(true);
		await handleDeleteScheduledMessage(scheduledMessage);
		setIsDeleting(false);
	}

	return (
		<List.Item>
			<div className={styles.scheduledMessageHeader}>
				<div>Message Scheduled for {triggerDate}</div>
				<div>Created on {dateString}</div>
			</div>
			<div className={styles.scheduledMessageBody}>
				<Typography.Text>{scheduledMessage.message}</Typography.Text>
				{isDeletable ? (
					<Popconfirm
						title="Are you sure you want to cancel this scheduled message?"
						onConfirm={() => {
							void onDeleteScheduledMessage();
						}}
						okText="Yes"
						cancelText="No"
						placement="rightBottom"
					>
						<Button loading={isDeleting}>Cancel</Button>
					</Popconfirm>
				) : (
					<Tooltip
						placement="topRight"
						title="This message was not created by you. Please contact admin to cancel the message."
						trigger="hover"
						overlayStyle={{ width: 300 }}
					>
						<Button disabled style={{ pointerEvents: 'all' }}>
							Cancel
						</Button>
					</Tooltip>
				)}
			</div>
		</List.Item>
	);
}
