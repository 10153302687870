import { Drawer, List, Typography } from 'antd';
import { BulbOutlined, VerticalLeftOutlined, CheckCircleOutlined } from '@ant-design/icons';
import styled, { useTheme } from 'styled-components';
import Image from '../../assets/images/spiralTriangles.png';

/**
 * Internal component displaying the header for the tips drawer
 */
const TipsHeaderTitle = () => (
	<>
		<BulbOutlined />
		<Typography.Title level={4} style={{ display: 'inline', color: '#fff' }}>
			{' '}
			Tips
		</Typography.Title>
	</>
);

const StyledDrawer = styled(Drawer)`
	.${(props) => props.theme['@ant-prefix']}-drawer-header-title {
		display: block;
	}

	.${(props) => props.theme['@ant-prefix']}-drawer-close {
		padding: 0;
		margin: 0 0 24px 0;
	}

	.${(props) => props.theme['@ant-prefix']}-drawer-close,
		.${(props) => props.theme['@ant-prefix']}-drawer-title,
		h3 {
		color: white;
	}
`;

interface TipsDrawerProps {
	onClose: () => void;
	tips: string[];
}

/**
 * A drawer displaying a list of tips for the user
 * @param onClose callback called when closing drawer
 * @param tips list of tips
 */
export const TipsDrawer: React.FC<TipsDrawerProps> = ({ onClose, tips }) => {
	const theme = useTheme();

	return (
		<StyledDrawer
			width="370px"
			visible
			closable
			onClose={onClose}
			closeIcon={<VerticalLeftOutlined />}
			headerStyle={{ position: 'relative', backgroundImage: `url(${Image})` }}
			title={<TipsHeaderTitle />}
		>
			<Typography.Title level={5}>Connection is what we're all about.</Typography.Title>
			<List>
				{tips.map((tip) => (
					<List.Item key={tip}>
						<List.Item.Meta
							avatar={<CheckCircleOutlined style={{ color: theme['@Neutral/90'] }} />}
							description={
								<Typography.Text style={{ color: theme['@Neutral/70'] }}>
									{tip}
								</Typography.Text>
							}
						/>
					</List.Item>
				))}
			</List>
		</StyledDrawer>
	);
};
