import NewUserMessageBasicEditPage from '@copilot/common/components/editors/newUserMessage/newUserMessageBasicEditPage';
import drawerManager from '@copilot/common/utils/drawerManager';
import { OnboardMessage } from '@copilot/data/requests/models';
import { FC, useState } from 'react';
import BaseDrawer from '../../base';
import BasicDrawerFooter from '../../base/footer';
import { IBasicNewUserMessageEditDrawerProps } from './const';

/**
 * Message Edit Drawer with basic edit capability
 * For new user onboarding
 * @param IBasicNewUserMessageEditDrawerProps
 */
const NewUserMessageBasicEditDrawer: FC<IBasicNewUserMessageEditDrawerProps> = (props) => {
	const {
		title,
		description,
		message,
		onSaveMessage,
		timeDescription,
		closeAlert: _,
		...rest
	} = props;

	const [unsavedMessage, setUnsavedMessage] = useState<OnboardMessage>(message);

	const handleUpdateMessage = (msg: OnboardMessage) => {
		setUnsavedMessage(msg);
	};

	const handleSaveMessage = () => {
		onSaveMessage(unsavedMessage);
	};

	return (
		<BaseDrawer
			closable
			footer={
				<BasicDrawerFooter
					onSave={handleSaveMessage}
					onCancel={drawerManager.closeDrawer}
					saveLabel="Save draft"
					cancelLabel="Discard"
					isSaveDisabled={!unsavedMessage.text}
				/>
			}
			{...rest}
		>
			<NewUserMessageBasicEditPage
				title={title}
				description={description}
				message={unsavedMessage}
				onMessageUpdate={handleUpdateMessage}
				timeDescription={timeDescription}
			/>
		</BaseDrawer>
	);
};

export default NewUserMessageBasicEditDrawer;
