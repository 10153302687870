import { ColumnProps } from 'antd/lib/table';

import BaseColumn from './base';
import { IconColumnDefinition } from '../../componentModels/columnDefinition';

export class IconColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];
	public sorter: ColumnProps<M>['sorter'];

	constructor(c: IconColumnDefinition) {
		super(c);
		this.render = (text: string, record: M) => {
			let iconHtml = '';
			if (c.determineIconFunction) {
				iconHtml = c.determineIconFunction(record);
			}

			return <span>{iconHtml}</span>;
		};
	}
}
