import { VerticalDivider } from '@copilot/common/components/verticalDivider';
import { Col, Row } from 'antd';
import { isNil } from 'lodash';
import { useTheme } from 'styled-components';
import { formatPercentage, getCalculationString } from './helpers';
import { PercentageRingAndDescription } from './percentageRingAndDescription';
import { NurtureCampaignStats } from './types';

type NurturePercentageRingAndDescriptionProps = {
	/**
	 * The statistics of the nurture campaign
	 */
	campaignStats: NurtureCampaignStats;
	/**
	 * Whether or not calculations should be shown
	 */
	isShowingCalculations: boolean;
};

/**
 * Internal component for nurture campaigns
 * Displays a row of percentage rings with descriptions
 */
export function NurturePercentageRingAndDescriptionRow({
	campaignStats,
	isShowingCalculations,
}: NurturePercentageRingAndDescriptionProps) {
	const {
		numInterestedReplies,
		numUserReplies,
		numSendMessages,
		percentReplied,
		percentInterestedReplied,
	} = campaignStats;

	const isReplyRateValid = !isNil(percentReplied);
	const replyRate = isReplyRateValid ? formatPercentage(percentReplied) : 0;

	const isInterestedRateValid = !isNil(percentInterestedReplied);
	const interestedRate = isInterestedRateValid ? formatPercentage(percentInterestedReplied) : 0;

	const theme = useTheme();

	return (
		<Row justify={'space-evenly'} gutter={[theme['@spacer-num-xs'], 0]}>
			<Col span={7}>
				<PercentageRingAndDescription
					isValid={isReplyRateValid}
					metricName="Reply rate"
					metricValue={replyRate}
					ringColour={theme['@Primary/20']}
					textColour={theme['@Primary/60']}
					showCalculations={isShowingCalculations}
					calculationDescription={getCalculationString(
						'replies',
						'messages sent',
						numUserReplies,
						numSendMessages,
						replyRate
					)}
				/>
			</Col>
			<Col>
				<VerticalDivider />
			</Col>
			<Col span={7}>
				<PercentageRingAndDescription
					isValid={isInterestedRateValid}
					metricName="Interested rate"
					metricValue={interestedRate}
					ringColour={theme['@Primary/20']}
					textColour={theme['@Primary/60']}
					showCalculations={isShowingCalculations}
					calculationDescription={getCalculationString(
						'interested replies',
						'replies',
						numInterestedReplies,
						numUserReplies,
						interestedRate
					)}
				/>
			</Col>
		</Row>
	);
}
