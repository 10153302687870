import { useFetch } from '@copilot/common/hooks/common';
import { useEffect, useRef, useState } from 'react';
import isNil from 'lodash/isNil';
import { LinkedInTaskManager } from '@copilot/data/managers/tasks';
import { ASYNC_TASK_STATUS, CampaignTaskStatus } from '@copilot/data/managers/tasks/types';
import {
	SEARCH_STATUSES,
	SearchStatus,
} from '@copilot/common/components/editors/searchUrl/update/consts';

type UseSearchTaskMonitorProps = {
	orgMemberId?: string; // orgMemberId is the id of hhe  campaign member we are monitoring
	timeoutSeconds?: number; // Max running time of polling in seconds
	campaignId: string; // The id of the campaign we are monitoring
	refreshSeconds?: number; // Poll interval in seconds
	onTimeout?: () => void; // Callback for when the task times out
	onTaskComplete?: () => void; // Callback for when the task completes
	onTaskError?: () => void; // Callback for when the task errors
	onPollingStart?: () => void; // Callback for when the task starts polling
};

/**
 * Poll for a task's status until it's completed or timedout
 * @param taskId The id of the task
 * @param timeoutSeconds Max running time of polling
 * @param refreshSeconds Poll interval
 */
export default function useSearchTaskMonitor({
	orgMemberId,
	timeoutSeconds = 20 * 60,
	refreshSeconds = 10,
	onTimeout,
	onTaskComplete,
	onTaskError,
	onPollingStart,
	campaignId,
}: UseSearchTaskMonitorProps) {
	const [currentIteration, setCurrentIteration] = useState<number>(0);
	const [task, setTask] = useState<CampaignTaskStatus>();
	const [searchStatus, setSearchStatus] = useState<SearchStatus>(SEARCH_STATUSES.NOT_SEARCHING);
	const [pollingStarted, setPollingStarted] = useState<boolean>(false);
	/**
	 * Base fetch task used by the fetchTask wrapper to include interval clears and task payload updates.
	 */
	const [, _baseFetchTask] = useFetch(LinkedInTaskManager.getSearchTaskStatus);
	const interval = useRef<number>();

	useEffect(() => {
		onReset();
		function cleanUp() {
			if (interval.current) window.clearInterval(interval.current);
			interval.current = 0;
		}

		if (isNil(orgMemberId)) cleanUp();
		else {
			startMonitoringTaskStatus();
		}
		return cleanUp;
	}, [orgMemberId]);

	useEffect(() => {
		if (currentIteration > timeoutSeconds) {
			window.clearInterval(interval.current);
			onTimeout?.();
			resetTimeoutTimer();
		}
	}, [currentIteration]);

	useEffect(() => {
		if (isNil(task)) return;
		if (
			!pollingStarted &&
			task?.hasAssociatedRunningTask &&
			task.taskData?.campaignId === campaignId
		) {
			onPollingStart?.();
			setPollingStarted(true);
		}
		setSearchStatus(calcSearchStatus());
		if (!task.hasAssociatedRunningTask) {
			window.clearInterval(interval.current);
			if (
				!isNil(task.taskData) &&
				campaignId == task.taskData.campaignId &&
				currentIteration > refreshSeconds
			) {
				task.taskData.status === ASYNC_TASK_STATUS.Complete
					? onTaskComplete?.()
					: onTaskError?.();
			}
		}
	}, [task]);

	function onReset() {
		interval.current = 0;
		resetTimeoutTimer();
		setSearchStatus(SEARCH_STATUSES.NOT_SEARCHING);
		setPollingStarted(false);
	}

	function resetTimeoutTimer() {
		setCurrentIteration(0);
	}

	function incrementCurrentIteration() {
		setCurrentIteration((prevInterval) => prevInterval + refreshSeconds);
	}

	async function fetchTask(id: string) {
		const fetchedTask = await _baseFetchTask(id);
		setTask(fetchedTask);
	}

	function startMonitoringTaskStatus() {
		if (isNil(orgMemberId)) return;
		resetTimeoutTimer();
		fetchTask(orgMemberId);
		interval.current = window.setInterval(() => {
			fetchTask(orgMemberId);
			incrementCurrentIteration();
		}, refreshSeconds * 1000);
	}

	function calcSearchStatus(): SearchStatus {
		if (isNil(task)) return SEARCH_STATUSES.NOT_SEARCHING;
		const orgMemberHasSearchTaskRunning = task.hasAssociatedRunningTask;
		const currentCampaignMemberHasSearchTaskRunning =
			orgMemberHasSearchTaskRunning && task?.taskData?.campaignId === campaignId;
		if (currentCampaignMemberHasSearchTaskRunning) {
			return SEARCH_STATUSES.SEARCHING_CURRENT_CAMPAIGN;
		} else if (orgMemberHasSearchTaskRunning) {
			return SEARCH_STATUSES.SEARCHING_OTHER_CAMPAIGN;
		}
		//if we have been polling and task is no longer running, display a success or error message
		if (currentIteration > refreshSeconds && task.taskData?.campaignId === campaignId) {
			return task.taskData.status === ASYNC_TASK_STATUS.Complete
				? SEARCH_STATUSES.SEARCH_COMPLETE
				: SEARCH_STATUSES.SEARCH_ERROR;
		}
		return SEARCH_STATUSES.NOT_SEARCHING;
	}

	return {
		task,
		resetTimeoutTimer,
		startMonitoringTaskStatus,
		searchStatus,
		hasSearchListStatusBeenChecked: !isNil(task),
	};
}
