import React from 'react';
import { CSSProperties } from 'styled-components';

/**
 * Apply shadow on component
 * @param Component
 */
export const withShadow =
	<T extends { style?: CSSProperties }>(Component: React.FC<T>) =>
	(props: React.PropsWithChildren<T>) =>
		(
			<Component
				{...props}
				style={{
					...props.style,
					boxShadow: `0 1px 2px -2px rgba(0,0,0,.16), 
				0 3px 6px 0 rgba(0,0,0,.12), 
				0 5px 12px 4px rgba(0,0,0,.09)`,
				}}
			/>
		);
