import { FC } from 'react';
import { InboxMessagesPageProps } from '../../types';
import InboxPageEmailViewFilters from '../filters/default';
import { InboxEmailViewProps } from '../page';

function withDefaultFilter(Component: FC<InboxEmailViewProps>): FC<InboxMessagesPageProps> {
	return (props: InboxMessagesPageProps) => {
		const { filters, filterUpdateCallbacks, isTeamMembersFilterVisible } = props;
		return (
			<Component {...props}>
				<InboxPageEmailViewFilters
					filters={filters}
					filterUpdateCallbacks={filterUpdateCallbacks}
					isTeamMembersFilterVisible={isTeamMembersFilterVisible}
				/>
			</Component>
		);
	};
}

export default withDefaultFilter;
