import React from 'react';
import { useSelector } from 'react-redux';
import BasicContainer from '@copilot/common/components/containers/basic';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import NotificationOption from './option';
import { useFetch } from '@copilot/common/hooks/common';
import { NotificationManager } from '@copilot/data';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { PushNotificationActions } from '@copilot/common/store/actions';
import { NotificationOptions } from '@copilot/data/responses/interface';

interface IOrganizationNotificationSettingsProps {
	organizationId: string;
}

const OrganizationNotificationSettings: React.FC<IOrganizationNotificationSettingsProps> = (
	props
) => {
	const { organizationId } = props;
	const organization = useSelector(OrganizationSelectors.getOrganization(organizationId));
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const [, fetchSettings] = useFetch(
		NotificationManager.getNotificationOptions,
		PushNotificationActions.load
	);

	React.useEffect(() => {
		if (!activeMember?.id) return;
		fetchSettings();
	}, [activeMember?.id]);

	if (!organization) return null;
	return (
		<BasicContainer>
			<BasicContainer.Header>Email Settings</BasicContainer.Header>
			<BasicContainer.Content>
				<h4>
					<b>Team Members</b>
				</h4>
				<NotificationOption
					title="Logged Out / Low Search List"
					notificationCode={NotificationOptions.ACCOUNTALERTS}
					organizationId={organizationId}
					isOnByDefault
				/>
				<NotificationOption
					title="Weekly Summary"
					notificationCode={NotificationOptions.WEEKLY}
					organizationId={organizationId}
				/>
				<NotificationOption
					title="Monthly Summary"
					notificationCode={NotificationOptions.MONTHLY}
					organizationId={organizationId}
				/>
				<NotificationOption
					title="Scheduled Message Notification"
					notificationCode={NotificationOptions.SENDRECEIPT}
					organizationId={organizationId}
				/>
				<NotificationOption
					title="Reply Notification"
					notificationCode={NotificationOptions.INSTANT}
					organizationId={organizationId}
				/>
				<br />
				<h4>
					<b>Administrators</b>
				</h4>
				<NotificationOption
					title="Logged Out List Weekly"
					notificationCode={NotificationOptions.TEAMS_SUMMARYALERTS}
					organizationId={organizationId}
					isAdmin
					isOnByDefault
				/>
				<NotificationOption
					title="Weekly Organization Summary"
					notificationCode={NotificationOptions.TEAMS_WEEKLY}
					organizationId={organizationId}
					isAdmin
				/>
				<NotificationOption
					title="Monthly Organization Summary"
					notificationCode={NotificationOptions.TEAMS_MONTHLY}
					organizationId={organizationId}
					isAdmin
					isOnByDefault
				/>
			</BasicContainer.Content>
		</BasicContainer>
	);
};

export default OrganizationNotificationSettings;
