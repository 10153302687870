import {
	ICampaignAllocationsByUserQuery,
	useCampaignAllocationsByUserQuery,
} from '../../../../../../core/data/graphql/_generated';
import { getExpectedOne } from '../../../../utils/index';
import { CampaignType } from '../../../../../../core/data/responses/interface';

const campaignTypes = {
	Nurture: 'NURTURE',
	Prospecting: 'PROSPECTING',
} as const;

type useCampaignAllocationsResponse = [
	isLoading: boolean,
	data: Array<{
		campaignId: string;
		campaignName: string;
		campaignType: CampaignType;
		allocation: number;
	}>
];

/**
 * Get a list of campaigns and their associated allocations for a user
 * @returns
 */
export function useCampaignAllocations(
	orgMemberId: string,
	type: CampaignType
): useCampaignAllocationsResponse {
	const { data, loading } = useCampaignAllocationsByUserQuery({
		variables: {
			input: {
				orgMemberId,
			},
		},
	});

	if (loading) return [true, []];

	const campaignAllocations =
		type === CampaignType.Prospecting
			? getProspectingAllocations(data)
			: getNurtureAllocations(data);

	return [false, campaignAllocations ?? []];
}

function getProspectingAllocations(data?: ICampaignAllocationsByUserQuery) {
	return data?.campaigns
		.filter(
			(campaign) =>
				getExpectedOne(campaign.members).dailyInvitesAllocated > 0 &&
				campaign.type === campaignTypes.Prospecting
		)
		.map((campaign) => ({
			campaignId: campaign.id,
			campaignName: campaign.name ?? '',
			campaignType: CampaignType.Prospecting,
			allocation: getExpectedOne(campaign.members).dailyInvitesAllocated,
		}));
}

function getNurtureAllocations(data?: ICampaignAllocationsByUserQuery) {
	return data?.campaigns
		.filter(
			(campaign) =>
				getExpectedOne(campaign.members).dailyMessagesAllocated > 0 &&
				campaign.type === campaignTypes.Nurture
		)
		.map((campaign) => ({
			campaignId: campaign.id,
			campaignName: campaign.name ?? '',
			campaignType: CampaignType.Nurture,
			allocation: getExpectedOne(campaign.members).dailyMessagesAllocated,
		}));
}
