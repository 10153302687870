import * as React from 'react';
import { Button, Popover } from 'antd';
import Selector from '../../selector';
import { StatFilterDefinition } from '../../componentModels/stat';

export interface StatSelectorProps {
	stats: StatFilterDefinition[];
	updateStats?: (stats: StatSelectorProps['stats']) => void;
}

interface StatSelectorState {
	visible: boolean;
}

class StatSelector extends React.Component<StatSelectorProps, StatSelectorState> {
	state: StatSelectorState = {
		visible: false,
	};

	private selectorRef: React.RefObject<any> = React.createRef();
	constructor(props: StatSelectorProps) {
		super(props);

		this.handleVisibleChange = this.handleVisibleChange.bind(this);
		this.toggleVisibility = this.toggleVisibility.bind(this);
		this.updateStats = this.updateStats.bind(this);
	}

	handleVisibleChange(visible: boolean) {
		this.setState({ visible });
	}

	toggleVisibility(visible?: boolean, reset?: boolean) {
		const isVisible = visible === undefined ? !this.state.visible : visible;
		this.setState({ visible: isVisible });
		if (reset) this.selectorRef.current.resetFields();
	}

	updateStats(stats?: StatSelectorProps['stats']) {
		if (stats && this.props.updateStats) this.props.updateStats(stats);
		this.toggleVisibility(false);
	}

	render() {
		const selector = (
			<Selector
				ref={this.selectorRef}
				stats={this.props.stats}
				updateStats={this.updateStats}
				cancel={() => this.toggleVisibility(false, true)}
				showSelectAll
			/>
		);
		return (
			<Popover
				content={selector}
				trigger="click"
				onVisibleChange={this.handleVisibleChange}
				placement="bottomRight"
				visible={this.state.visible}
				autoAdjustOverflow={false}
			>
				<Button type="primary" onClick={() => this.toggleVisibility()}>
					Select Statistic Types
				</Button>
			</Popover>
		);
	}
}

export default StatSelector;
