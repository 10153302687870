import { IModal, ModalStatus, ModalType } from '@copilot/common/store/models/redux/modal';
import Store from '@copilot/common/store';
import { ModalActions } from '@copilot/common/store/actions/modal';
import { ICampaignCreationModalProps } from '@copilot/common/components/modals/wrappers/campaignCreation/const';
import { IInitialLinkedinSyncModalProps } from '@copilot/common/components/modals/wrappers/linkedinSync/const';
import { ISalesNavTutorialModalProps } from '@copilot/common/components/modals/wrappers/salesNavTutorial/const';
import { ICarouselModalProps } from '../../components/modals/wrappers/carousel';
import { ChangesNotSavedProps } from '@copilot/common/components/modals/wrappers/changesNotSaved';
import { IMigrationModalProps } from '../../components/modals/wrappers/migration';
import { IInstanceDataUpdateModalProps } from '@copilot/common/components/modals/wrappers/teamCustomerInstanceDataUpdate/props';
import { ICampaignLaunchErrorModalProps } from '@copilot/common/components/modals/wrappers/campaignLaunchError';
import { TagModalProps } from '@copilot/common/components/modals/wrappers/tags/interface';
import { ICreateQuickResponseModalProps } from '@copilot/common/components/modals/wrappers/quickResponse';
import { SmartReplyTermsOfUseModalProps } from '@copilot/common/components/modals/wrappers/smartReplyTermsOfUse/SmartReplyTermsOfUse';
import { AIFeatureTermsOfUseModalProps } from '@copilot/common/components/modals/wrappers/aiFeatureTermsOfUse';

class ModalManager {
	private idCount = 0;
	constructor() {}

	private openModal = (type: ModalType, props: IModal['props']) => {
		const id = `${this.idCount++}`;
		Store.Dispatch(
			ModalActions.loadModal({
				id,
				status: ModalStatus.Open,
				props,
				type,
			})
		);
		return id;
	};

	public openCampaignCreationAlert = (props: IModal<any>['props']) =>
		this.openModal(ModalType.CampaignCreationAlertModal, props);

	public openVideoModal = (props: IModal<any>['props']) =>
		this.openModal(ModalType.VideoModal, props);

	public openSalesNavTutorialModal = (props: ISalesNavTutorialModalProps) =>
		this.openModal(ModalType.SalesNavTutorialModal, props);

	public openCalendlySettingsModal = (props: IModal<any>['props']) =>
		this.openModal(ModalType.Calendly, props);

	public openCampaignCreationModal = (props: ICampaignCreationModalProps) =>
		this.openModal(ModalType.CampaignCreationModal, props);

	public openCustomerCreationModal = (props: IModal<any>['props']) =>
		this.openModal(ModalType.CustomerAddition, props);

	public openTeamCreationModal = (props: IModal<any>['props']) =>
		this.openModal(ModalType.TeamAddition, props);

	public openInitialLinkedInModal = (props: IInitialLinkedinSyncModalProps) =>
		this.openModal(ModalType.InitialSyncLinkedIn, props);

	public openPasswordSettingsModal = (props: IModal<any>['props']) =>
		this.openModal(ModalType.Password, props);

	public openProspectsMigrationModal = (props: IModal<any>['props']) =>
		this.openModal(ModalType.ProspectsMigration, props);

	public openOrganizationMemberActivationModal = (props: IModal<any>['props']) =>
		this.openModal(ModalType.OrganizationMemberActivation, props);

	public openRestrictMemberDeactivationModal = (props: IModal<any>['props']) =>
		this.openModal(ModalType.RestrictMemberDeactivation, props);

	public openCarouselModal = (props: ICarouselModalProps) =>
		this.openModal(ModalType.CarouselModal, props);

	public openChangesNotSavedModal = (props: ChangesNotSavedProps) =>
		this.openModal(ModalType.ChangesNotSavedModal, props);

	public openReconnectLinkedInAlert = (props: IModal<any>['props']) =>
		this.openModal(ModalType.ReconnectLinkedInModal, props);

	public openMigrationModal = (props: IMigrationModalProps) =>
		this.openModal(ModalType.MigrationModal, props);

	public openTeamCustomerInstanceDataUpdateModal = (props: IInstanceDataUpdateModalProps) =>
		this.openModal(ModalType.TeamCustomerInstanceDataModal, props);

	public openCampaignLaunchErrorModal = (props: ICampaignLaunchErrorModalProps) =>
		this.openModal(ModalType.CampaignLaunchErrorModal, props);

	public openTagsModal = (props: TagModalProps) => this.openModal(ModalType.TagsModal, props);
	public openOutOfCreditModal = (props: IModal<any>['props']) =>
		this.openModal(ModalType.OutOfCredit, props);
	public openCreateQuickResponseModal = (props: ICreateQuickResponseModalProps) =>
		this.openModal(ModalType.CreateQuickResponseModal, props);
	public openSmartReplyTermsAndConditions = (props: SmartReplyTermsOfUseModalProps) =>
		this.openModal(ModalType.SmartReplyTermsOfUse, props);
	public openAIFeatureTermsOfUseModal = (props: AIFeatureTermsOfUseModalProps) =>
		this.openModal(ModalType.AIFeatureTermsOfUseModal, props);
	public closeModal = (id: string) => {
		Store.Dispatch(ModalActions.closeModal(id));
	};
}

export default new ModalManager();
