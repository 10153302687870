import { ICampaignOnboardDetails, CampaignOnboardDetails } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = CampaignOnboardDetails.modelName;
export namespace CampaignOnboardDetailsActions {
	export const loadCampaignOnboardDetails = (details: Partial<ICampaignOnboardDetails>) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: details,
	});

	export const updateCampaignOnboardDetails = (details: Partial<ICampaignOnboardDetails>) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: details,
	});

	export const deleteCampaignOnboardDetails = (details: Partial<ICampaignOnboardDetails>) => ({
		type: EntitiesActionTypes.Delete,
		itemType,
		payload: details,
	});
}
