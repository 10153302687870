import { Button, Divider, Flex, InputNumber, Space, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined, MailOutlined } from '@ant-design/icons';
import { pluralString } from '@copilot/common/utils/stringFormat';
import { isNil, isString } from 'lodash';
import Spinner from '@copilot/common/components/loadingIndicator/spinner';
import styles from './openProfileSearchListOverview.module.less';

type OpenProfileSearchListOverviewProps = {
	/**
	 * Search list count
	 */
	count: number;
	/**
	 * the maximum outreach limit
	 */
	allocationLimit: number;
	/**
	 * number of outreach available to allocate
	 */
	numAvailableToAllocate: number;
	/**
	 * number of outreach remaining
	 */
	allocated: number;
	/**
	 * Callback for updating daily limit on Open InMails
	 * @param limit
	 */
	onUpdateDailyLimit: (limit: number) => void;
	/**
	 * Whether or not the limit is updating
	 */
	isLimitUpdating: boolean;
	/**
	 * Whether or not the limit updating request has failed
	 */
	hasLimitUpdateFailed: boolean;
	/**
	 * whether or not the limit can be edited
	 */
	canEdit?: boolean;
	/**
	 * Callback to display all allocations
	 */
	onShowAllocations: () => void;
	/**
	 * Is the allocations loading
	 */
	isLoadingAllocations: boolean;
	/**
	 * Is Open InMail being enabled
	 */
	isInMailEnabled: boolean;
};

/**
 * Overview of the list of open profiles
 * @param props
 * @constructor
 */
export function OpenProfileSearchListOverview(props: OpenProfileSearchListOverviewProps) {
	const {
		allocationLimit,
		numAvailableToAllocate,
		allocated,
		count,
		onUpdateDailyLimit,
		isInMailEnabled,
		isLimitUpdating,
		hasLimitUpdateFailed,
		onShowAllocations,
		isLoadingAllocations,
		canEdit = false,
	} = props;

	const remainingAllocation = numAvailableToAllocate - allocated;
	function handleAllocationUpdate(value: string | number | null) {
		if (isString(value) || isNil(value)) return;
		onUpdateDailyLimit(value);
	}

	return (
		<Flex gap={'small'} className={styles.overview} align={'center'} wrap={'wrap'}>
			<MailOutlined />
			<Typography.Text>
				{`${count} ${pluralString('prospect', count)} are waiting ${pluralString(
					'InMail',
					count
				)}`}
			</Typography.Text>
			{canEdit && (
				<>
					<Divider type={'vertical'} />
					<Typography.Text>Number of InMails a day</Typography.Text>
					<InputNumber
						width={'57px'}
						min={0}
						max={numAvailableToAllocate}
						value={allocated}
						onChange={handleAllocationUpdate}
						disabled={isLimitUpdating || !isInMailEnabled}
						suffix={isLimitUpdating ? <Spinner isLoading /> : null}
						status={hasLimitUpdateFailed ? 'error' : undefined}
					/>
					<Divider type={'vertical'} />
					<Space size={'small'}>
						<Typography.Text>
							Invites left for allocation {remainingAllocation} / {allocationLimit}
						</Typography.Text>
						<Tooltip
							title={
								'This is the total number of invitations allocated to your various campaigns now.'
							}
							trigger="click"
							color="#1b2635"
							placement={'bottomRight'}
							arrow={{ pointAtCenter: true }}
						>
							<InfoCircleOutlined />
						</Tooltip>
						<Button
							onClick={onShowAllocations}
							ghost
							type={'primary'}
							size={'small'}
							loading={isLoadingAllocations}
						>
							View invite allocation
						</Button>
					</Space>
				</>
			)}
		</Flex>
	);
}
