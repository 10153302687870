import { ContactResponse } from '../interface';

export class ContactModel implements ContactResponse {
	public id: string;
	public organizationId: string;
	public position: string;
	public firstName: string;
	public lastName: string;
	public name: string;
	public contactId: string;
	public email: string;
	public phoneNumber: string;
	public company: string;
	public addressLine: string;
	public city: string;
	public country: string;
	public state: string;
	public zipCode: string;
	public connectedMemberIds: string[];
	public campaignIds: string[];
	public notes: {
		id: string;
		orgMemberId: string;
		contactId: string;
		connectionId: string;
		campaignMemberId: string;
		timestamp: Date;
		message: string;
		metaData: { [k: string]: string };
	}[];
	public reminders: {
		id: string;
		orgMemberId: string;
		contactId: string;
		connectionId: string;
		campaignMemberId: string;
		timestamp: Date;
		entityType: number;
		message: string;
		targetNodeId: string;
		triggerDateTime: Date;
		metaData: { [k: string]: string };
	}[];
	public tags: {
		id: string;
		name: string;
		tagId: string;
		contactId: string;
		connectionId: string;
	}[];
	public dateCreated: Date;
	public lastThreadActivity: Date;
	constructor(contact: ContactResponse) {
		this.id = contact.id;
		this.organizationId = contact.organizationId;
		this.position = contact.position;
		this.firstName = contact.firstName;
		this.lastName = contact.lastName;
		this.name = `${contact.firstName} ${contact.lastName}`;
		this.contactId = contact.id;
		this.email = contact.email;
		this.phoneNumber = contact.phoneNumber;
		this.company = contact.company;
		this.addressLine = contact.addressLine;
		this.city = contact.city;
		this.country = contact.country;
		this.state = contact.state;
		this.zipCode = contact.zipCode;
		this.connectedMemberIds = contact.connectedMemberIds || [];
		this.campaignIds = contact.campaignIds || [];
		this.notes = contact.notes || [];
		this.reminders = contact.reminders || [];
		this.tags = contact.tags || [];
		this.dateCreated = new Date(contact.dateCreated);
		this.lastThreadActivity = new Date(contact.lastThreadActivity);
	}
}
