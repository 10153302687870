import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { PaginatedResults, QueryObject } from '@copilot/data/managers/base';
import { FetchState, useFetch } from '@copilot/common/hooks/common';
import {
	Filters,
	Pagination,
	Sort,
	PropertyKey as UtilPropertyKey,
} from '@copilot/data/utils/converter';

interface KeyMap {
	[k: string]: string;
}

interface PaginationState {
	current: number;
	total: number;
}

export const useTableChangeQueryConverter = (
	dataKeyMap: KeyMap = {},
	evaluatorKeyMap: KeyMap = {}
): [
	QueryObject | undefined,
	Dispatch<SetStateAction<QueryObject | undefined>>,
	(pagination: any, filters: any, sorter: any) => void
] => {
	const [query, setQuery] = useState<QueryObject>();
	const handleChangeFunc = useCallback(
		(pagination: Pagination, filters: Filters, sorter: Sort) => {
			const localQuery = new QueryObject({
				page: (pagination.current || 1) - 1,
				pageSize: pagination.pageSize,
			});

			const isPropertyKeyString = (columnKey: UtilPropertyKey): columnKey is string => {
				return typeof columnKey === 'string';
			};

			const isPropertyKeyArrayString = (item: UtilPropertyKey[]): item is string[] => {
				return typeof item[0] === 'string';
			};

			Object.keys(filters).forEach((k) => {
				const key = dataKeyMap[k] || k;
				const evaluator = evaluatorKeyMap[key] || '^*=';
				const filterItem = filters[k];
				if (filterItem !== null && isPropertyKeyArrayString(filterItem)) {
					localQuery.addFilter(key, evaluator, filterItem.join('|'));
				}
			});
			if (sorter.columnKey && isPropertyKeyString(sorter.columnKey))
				localQuery.addSorter(sorter.columnKey, sorter.order === 'ascend' ? '' : '-');

			setQuery(localQuery);
		},
		[]
	);
	return [query, setQuery, handleChangeFunc];
};

/**
 * Hook that provides states and callback functions for ant design table searching, pagination, filtering, sorting
 * @param {function} searchHandler function that fetches results from a
 * 										searchQuery parameter
 * 												this function will take a null searchQuery initially until searchTerm is set
 * 												a fetch with null or "" searchQuery should return all results
 * 										newQuery parameter
 * 												that resets the pagination of the returned PaginatedResults
 * 										searchFunc parameter
 * 												a function from useFetch that gets the search results
 * @param {function} fetchFunc the getter function passed into useFetch to generate a result state and fetching function
 * @returns {string | null} the searchTerm stateful value, may be null at initialization before being set
 * @returns {Dispatch<SetStateAction<string | null>>} function that updates searchTerm
 * @returns {(pagination: any, filters: any, sorter: any) => void} function for ant design table that handles table changes (pagination, filters, sorting)
 * @returns {PaginationState} a memoized state of pagination
 * @returns {FetchState<PaginatedResults<T>>} the table results
 * @returns {function} a function that updates the table results
 * @returns {function} a function that update which page the user is at
 */
export const useTableSearchWithTableChange = <G, T>(
	searchHandler: (
		searchQuery: string | null,
		newQuery: QueryObject | undefined,
		searchFunc: (...args: Parameters<typeof fetchFunc>) => Promise<PaginatedResults<T>>
	) => Promise<void>,
	fetchFunc: G extends (...args: any[]) => Promise<PaginatedResults<T>>
		? G
		: (...args: any[]) => Promise<PaginatedResults<T>>
): [
	setSearchTerm: (term: string) => void,
	searchTerm: string | null,
	handleTableChange: (pagination: any, filters: any, sorter: any) => void,
	pagination: PaginationState,
	fetchSearchListResults: () => void,
	searchListResults: FetchState<PaginatedResults<T>>
] => {
	const [searchTerm, setSearchTerm] = useState<string | null>(null);
	const [query, setQuery, handleTableChange] = useTableChangeQueryConverter();
	const [searchListResults, fetchSearchListResults] = useFetch(fetchFunc);
	const pagination = useMemo(
		() => ({
			current: (query?.page ?? 0) + 1,
			total: searchListResults.data?.totalCount ?? 0,
		}),
		[query, searchListResults]
	);

	function forceFetch() {
		searchHandler(searchTerm, query, fetchSearchListResults);
	}

	useEffect(() => {
		// Reset page to 0 when fetching search list from search term
		const newQuery = query
			? {
					...query,
					page: 0,
			  }
			: undefined;
		forceFetch();
		setQuery(newQuery);
	}, [searchTerm]);

	//TODO-https://cassia.atlassian.net/browse/COPILOT-8360 remove this, it is super fragile and dependant on a bunch of external state assumptions
	/**
	 *  Used alongside searchHandler callback
	 *  when the searchHandler is memoized by one of its dependencies, or when the pagination query changes
	 *  this useEffect will be triggered and call searchHandler to update the searchListResults
	 *
	 */
	useEffect(() => {
		forceFetch();
	}, [query, searchHandler]);

	return [
		setSearchTerm,
		searchTerm,
		handleTableChange,
		pagination,
		forceFetch,
		searchListResults,
	];
};
