import { ICoupon } from '@copilot/data/responses/models/billing';
import { Button, Col, Row, Space, Tag, Tooltip, Typography } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getFormattedDiscountTag } from '@copilot/cs/src/components/billing/utils/discountFormat';

const { Title, Text } = Typography;

/**
 * Properties to display coupon summary.
 */
interface CouponSummaryProps {
	/**
	 * Selected coupon to display in coupon summary.
	 */
	selectedCoupon?: ICoupon;
	/**
	 * Callback for when delete coupon is clicked.
	 */
	onDeleteCouponClicked?: () => void;
	/**
	 * Callback for when add/edit coupon is clicked.
	 */
	onEditCouponClicked?: () => void;
}

/**
 * [Presentational] Coupon summary with edit (pulls out coupon drawer) and delete coupon buttons.
 */
const CouponSummary: React.FC<CouponSummaryProps> = ({
	selectedCoupon,
	onDeleteCouponClicked,
	onEditCouponClicked,
}) => (
	<>
		<Title level={5}>Coupon</Title>
		<Row justify="space-between">
			<Col style={{ lineHeight: '40px' }}>
				{selectedCoupon ? selectedCoupon.name : 'No coupon applied'}
			</Col>
			<Col className="text-right">
				{selectedCoupon ? (
					<Space>
						<Text>{`${selectedCoupon.formattedDiscount} Off`}</Text>
						<Tag>
							{getFormattedDiscountTag(
								selectedCoupon.duration,
								selectedCoupon.durationInMonths
							)}
						</Tag>
						<Tooltip title="Change coupon">
							<Button
								onClick={onEditCouponClicked}
								icon={<EditOutlined />}
								type="link"
								size="large"
								style={{ color: '#0075e2' }}
							/>
						</Tooltip>
						<Tooltip title="Remove coupon">
							<Button
								onClick={onDeleteCouponClicked}
								icon={<DeleteOutlined />}
								type="link"
								size="large"
								style={{ color: '#eb2f96' }}
							/>
						</Tooltip>
					</Space>
				) : (
					<Button onClick={onEditCouponClicked}>
						<PlusOutlined />
						Add a Coupon
					</Button>
				)}
			</Col>
		</Row>
	</>
);

export default CouponSummary;
