import React, { useCallback, useMemo } from 'react';
import { Row, Col, Slider, InputNumber } from 'antd';
import styled from 'styled-components';
import { isReactElement } from '@copilot/common/utils';
import { isNumber } from 'lodash';

const StyledCol = styled(Col)`
	${(props) => {
		const prefix = props.theme['@ant-prefix'];
		return `
		.${prefix}-tooltip {
			z-index: 0;
		}
		.${prefix}-tooltip-inner {
			background-color: white;
			color: black;
			box-shadow: none;
			white-space: nowrap;
		}
		.${prefix}-slider-track,
		.${prefix}-slider-rail {
			height: 10px;
		}

		.${prefix}-slider-handle {
			width: 20px;
			height: 20px;
        }

        .${prefix}-slider-dot {
            margin-left: -7px;
            width: 15px;
			height: 15px;

			&:last-child, &:first-child {
				margin-left: -7px;
			}
        }

	`;
	}}
`;

interface IAllocateEditorHeaderProps {}

const AllocateEditorHeader: React.FC<IAllocateEditorHeaderProps> = ({ children }) => (
	<>{children}</>
);

const getHeader = (children: React.ReactNode) => {
	let header: React.ReactNode = null;
	React.Children.map(children, (child) => {
		if (isReactElement(child)) {
			switch (child.type) {
				case AllocateEditorHeader:
					header = child;
					break;
			}
		}
	});
	return header;
};

interface IAllocationEditorProps {
	value: number;
	maxValue: number;
	onValueChange: (value: number) => void;
	recommendedValue?: number;
}
interface IAllocationEditorStaticProps {
	Header: typeof AllocateEditorHeader;
}

/**
 * Editor for single value allocation
 * @param value value editor is updating
 * @param maxValue max value we can update to
 * @param onValueChange callback on value change
 * @param recommendedValue recommended value to display
 */
const AllocationEditor: React.FC<IAllocationEditorProps> & IAllocationEditorStaticProps = (
	props
) => {
	const { value, maxValue, onValueChange, recommendedValue, children } = props;

	const header = getHeader(children);
	const marks = useMemo(() => {
		const sliderMarks: { [k: number]: string } = { 0: '0', [maxValue]: `${maxValue}` };
		if (recommendedValue && recommendedValue > 0 && recommendedValue < maxValue)
			sliderMarks[recommendedValue] = `${recommendedValue} (Recommended)`;
		return sliderMarks;
	}, [recommendedValue, maxValue]);

	const handleChange = useCallback(
		(v: number | (undefined | null)) => {
			let updatedValue = 0;
			if (isNumber(v)) updatedValue = v;
			onValueChange(updatedValue);
		},
		[onValueChange]
	);

	return (
		<>
			{header}
			<Row align="middle" gutter={8}>
				<StyledCol flex="auto" style={{ margin: '8px' }}>
					<Slider
						marks={marks}
						min={0}
						max={maxValue}
						onChange={handleChange}
						included={false}
						value={value}
					/>
				</StyledCol>
				<Col>
					<InputNumber
						min={0}
						max={maxValue}
						style={{ marginLeft: 16 }}
						value={value}
						onChange={handleChange}
					/>
				</Col>
			</Row>
		</>
	);
};

AllocationEditor.Header = AllocateEditorHeader;
export default AllocationEditor;
