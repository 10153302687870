import React, { PropsWithChildren } from 'react';
import { Spin } from 'antd';

/**
 * The props for supporting loading state.
 */
export type LoadingProps = {
	/**
	 * Determines if the component is in the loading state or not.
	 */
	isLoading: boolean;
};

/**
 * Shows the loading spinner if isLoading is true.
 * @param Component
 */
export const withLoading =
	<T,>(Component: React.FC<T>) =>
	(props: T & LoadingProps) =>
		(
			<>
				{props.isLoading ? (
					<Spin size="large" style={{ margin: 'auto', width: '100%' }} />
				) : (
					<Component {...props} />
				)}
			</>
		);

/**
 * Shows the error UI if error exists.
 * @param Component
 */
export const withErrorHandling =
	<T,>(Component: React.FC<T>) =>
	(props: PropsWithChildren<T> & { error: any }) =>
		<>{props.error ? <>Error</> : <Component {...props} />}</>;
