import { ModalFuncProps, Typography } from 'antd';
import styles from './confirm-modal.module.less';
import { InfoCircleFilled } from '@ant-design/icons';
import orderBy from 'lodash/orderBy';
import { getPluralizedEnding } from '@copilot/common/utils/stringFormat';

/**
 * Util function to show the modal for missing inMail sequences
 * @param onOk
 */
export function getInMailMissingSequenceWarningModalConfig(onOk: () => void): ModalFuncProps {
	return {
		okText: 'Set up InMail Sequence',
		title: "You haven't set up an InMail messaging sequence yet",
		onOk,
		cancelText: 'Not now',
		onCancel: () => {},
		icon: <InfoCircleFilled style={{ color: '#1677ff' }} />,
		className: styles.inMailSequenceModal,
		width: 600,
		content: (
			<div>
				If you'd like to reach these prospects with Open Profiles, go to "InMail Messaging"
				tab to get your sequence up and running.
				<br /> <br />* This sequence will be available to all team members who activate the
				“Send InMail to Open Profiles” option
			</div>
		),
		footer: (_, { OkBtn, CancelBtn }) => (
			<>
				<CancelBtn />
				<OkBtn />
			</>
		),
	};
}

/**
 * Util function to show the modal for pausing inMail sequences
 * @param onOk
 */
export function getInMailPauseSequenceWarningModalConfig(
	onOk: () => void,
	numRemaingProspects?: number
): ModalFuncProps {
	return {
		okText: 'Pause InMail Sequence',
		title: 'Pause InMail Sequence',
		onOk,
		cancelText: 'Cancel',
		onCancel: () => {},
		icon: <InfoCircleFilled style={{ color: '#1677ff' }} />,
		className: styles.inMailSequenceModal,
		width: 600,
		content: (
			<div>
				Are you ready to take a break from the InMail sequence? Don't worry,{' '}
				<b>
					the {numRemaingProspects != undefined ? numRemaingProspects : ''} remaining
					prospect
					{numRemaingProspects != 1 ? 's' : ''} won't be missed
				</b>{' '}
				- they will receive a connection request. Remember, you always have the option to
				stop automation for any contact later. Ready to pause?
			</div>
		),
		footer: (_, { OkBtn, CancelBtn }) => (
			<>
				<CancelBtn />
				<OkBtn />
			</>
		),
	};
}

type Allocation = {
	campaignName: string;
	allocated: number;
};

/**
 * Util function to show the modal for allocations for Open InMails
 * @param allocations
 */
export function getInMailAllocationModalConfig(
	allocations: ReadonlyArray<Allocation>
): ModalFuncProps {
	const sortedByCampaignName = orderBy(
		allocations,
		[({ allocated }) => allocated, ({ campaignName }) => campaignName.toLowerCase()],
		['desc', 'asc']
	);
	return {
		title: 'InMail invite allocation',
		icon: <InfoCircleFilled style={{ color: '#1677ff' }} />,
		className: styles.inMailAllocation,
		width: 468,
		centered: true,
		maskClosable: true,
		closable: true,
		footer: null,
		content: (
			<Typography.Text>
				<ul className={styles.campaignList}>
					{sortedByCampaignName.map(({ campaignName, allocated }, index) => (
						<li key={`${campaignName}-${index}`}>
							{campaignName} ({allocated} InMail invite
							{getPluralizedEnding(allocated)})
						</li>
					))}
				</ul>
			</Typography.Text>
		),
	};
}
