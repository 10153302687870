import { CSSProperties, ReactNode } from 'react';
import { useTheme } from 'styled-components';

type LargeTagPropsType = {
	/**
	 * Custom styling for the large tag
	 */
	style?: CSSProperties;
	/**
	 * Tag's content
	 */
	children: ReactNode;
};

/**
 * Custom Tag that is larger than antd tag
 * @param props
 * @returns
 */
function LargeTag(props: LargeTagPropsType) {
	const { style, children } = props;
	const theme = useTheme();
	return (
		<span
			style={{
				borderRadius: '2px',
				padding: '1px 8px',
				fontSize: theme['@font-size-base'],
				lineHeight: '22px',
				display: 'inline-block',
				userSelect: 'none',
				...style,
			}}
		>
			{children}
		</span>
	);
}

export default LargeTag;
