import React from 'react';
import { Drawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import styled from 'styled-components';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';

interface BaseDrawerProps extends DrawerProps {
	children?: React.ReactNode;
	footer?: React.ReactNode;
}

const StyledBaseDrawer = styled(Drawer)`
	display: flex;
	flexdirection: 'column';
	.copilot-drawer-body {
		-webkit-overflow-scrolling: touch;
	}
	.${(props) => props.theme['@ant-prefix']}-drawer-close {
		position: absolute;
		top: 20px;
		right: 0;
		z-index: 1;
	}
`;

const BaseDrawer: React.SFC<BaseDrawerProps> = (props: BaseDrawerProps) => {
	const { children, footer, ...rest } = props;
	return (
		<StyledBaseDrawer closable width="720px" open footer={footer} {...rest}>
			<ErrorBoundary>{children}</ErrorBoundary>
		</StyledBaseDrawer>
	);
};

export default BaseDrawer;
