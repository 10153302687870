import { createSelector } from 'redux-orm';
import Store from '..';

export namespace PushNotificationSelectors {
	export const getNotificationByCode = createSelector(
		Store.ORM,
		(_, notificationCode) => notificationCode,
		(session, notificationCode) => session.PushNotification.filter({ notificationCode }).first()
	);
}
