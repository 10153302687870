import { FC } from 'react';
import partition from 'lodash/partition';
import { Prompt } from './promptMenuTypes';
import { PromptMenu, PromptMenuHeader } from './promptMenu';
import { Divider } from 'antd';
import styles from './smartReplyPromptMenu.module.less';
import { SMART_REPLY_MENU_TEST_ID } from './constants';

const CUSTOM_OPTION = 'customWrite';

const customPromptItem = {
	key: CUSTOM_OPTION,
	label: 'Custom Write',
};

/**
 * Interface for Edit Flow Dropdown Props
 */
export type WriteFlowDropdownPropsType = Readonly<{
	/**
	 * Prompts we want to display
	 */
	prompts: ReadonlyArray<Prompt>;
	/**
	 * Callback when the user select a preset prompt to generate
	 */
	onSelectPreset: (key: string) => void;
	/**
	 * Callback to update whether the user is writing a custom prompt
	 */
	setIsWritingCustomPrompt: (isWritingCustomPrompt: boolean) => void;
}>;

/**
 * [Presentational] Dropdown to represent the write flow of smart reply
 * @param props
 */
export const WriteFlowDropdown: FC<WriteFlowDropdownPropsType> = (props) => {
	const { prompts, onSelectPreset, setIsWritingCustomPrompt } = props;

	const onClick = ({ key }: { key: string }) =>
		key === CUSTOM_OPTION ? setIsWritingCustomPrompt(true) : onSelectPreset(key);

	const [suggested, rest] = partition(prompts, (prompt) => prompt.isSuggested === true);
	return suggested.length > 0
		? toSuggestedPromptMenu(suggested, rest, onClick)
		: toPromptMenu(rest, onClick);
};

function toPromptMenu(
	prompts: ReadonlyArray<Prompt>,
	onClick: ({ key }: { key: string }) => void,
	hasCustomPrompt = true
) {
	const customPrompt = hasCustomPrompt ? customPromptItem : undefined;

	return (
		<PromptMenu
			data-testid={SMART_REPLY_MENU_TEST_ID}
			prompts={prompts}
			customPrompt={customPrompt}
			selectable={false}
			onClick={onClick}
		/>
	);
}

function toSuggestedPromptMenu(
	suggestedPrompts: ReadonlyArray<Prompt>,
	additionalPrompts: ReadonlyArray<Prompt>,
	onClick: ({ key }: { key: string }) => void
) {
	const suggestedPromptMenu = toPromptMenu(suggestedPrompts, onClick, false);
	const additionalPromptMenu = toPromptMenu(additionalPrompts, onClick, true);

	return (
		<>
			<PromptMenuHeader copy="Smart Reply (Suggested)" />
			{suggestedPromptMenu}

			<Divider className={styles.smartReplyDivider} />

			<PromptMenuHeader copy="Other options" />
			{additionalPromptMenu}
		</>
	);
}
