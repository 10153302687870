import { useTableSearchWithTableChange } from '@copilot/common/components/tables/hooks';
import { useCallback } from 'react';
import { PaginatedResults, QueryObject } from '@copilot/data/managers/base';
import { ConnectionStatusType } from '@copilot/common/utils/constant';
import { FetchState, useFetch } from '@copilot/common/hooks/common';
import { CampaignMemberManager, OrganizationMemberManager } from '@copilot/data';
import { LinkedInMetaActions } from '@copilot/common/store/actions/linkedInMeta';
import { ConnectionResponse } from '@copilot/data/responses/interface';
import { formatName } from '@copilot/common/utils';
import {
	OPEN_PROFILE_TAB_KEY,
	SearchListTableTabKeys,
} from '@copilot/common/pages/campaignDashboard/searchList/const';

type SearchListResult = Omit<ConnectionResponse, 'dateCreated'> & {
	dateCreated: Date;
	connectionStatus: string;
	name: string;
};

async function fetchSearchListResults(
	...args: Parameters<typeof CampaignMemberManager.getSearchListResultsByMember>
): Promise<PaginatedResults<SearchListResult>> {
	const result = await CampaignMemberManager.getSearchListResultsByMember(...args);

	return {
		...result,
		results:
			result.results.map((d) => ({
				...d,
				connectionStatus:
					d.connectionStatus === ConnectionStatusType.Stranger
						? 'Awaiting invite'
						: d.connectionStatus,
				dateCreated: new Date(d.dateCreated),
				name: formatName(d.firstName, d.lastName),
			})) ?? [],
	};
}

/**
 * Hooks to encapsulate logic for searching, pagination, filtering, sorting search lists
 * @param campaignId
 * @param campaignMember
 * @param orgMemberId
 * @param tableKey
 */
export function useSearchListTables(
	campaignId: string,
	campaignMember: { id: string; orgMemberId: string } | undefined,
	orgMemberId: string | undefined,
	tableKey: SearchListTableTabKeys
): [
	setSearchTerm: (term: string) => void,
	searchTerm: string | null,
	handleTableChange: (pagination: any, filters: any, sorter: any) => void,
	pagination: {
		current: number;
		total: number;
	},
	fetchSearchListResults: () => void,
	searchListResults: FetchState<PaginatedResults<SearchListResult>>
] {
	const [, fetchIsLoggedIn] = useFetch(
		OrganizationMemberManager.checkIsLoggedIn,
		LinkedInMetaActions.loadLinkedInMeta,
		(r, omid) => ({ orgMemberId: omid, isLoggedIn: r })
	);

	const fetchOpenProfiles = useCallback(
		async (
			searchQuery: string | null,
			newQuery: QueryObject | undefined,
			searchFunc: typeof fetchSearchListResults
		) => {
			if (campaignId && campaignMember?.id) {
				await searchFunc({
					campaignId,
					campaignMemberId: campaignMember.id,
					connectionStatus: ConnectionStatusType.Stranger,
					orgMemberId,
					searchTerm: searchQuery,
					query: newQuery,
					isOpenProfile: true,
				});
			}

			if (campaignMember?.orgMemberId) fetchIsLoggedIn(campaignMember.orgMemberId);
		},
		[campaignId, campaignMember?.id, campaignMember?.orgMemberId, orgMemberId, tableKey]
	);

	const fetchSearchList = useCallback(
		async (
			searchQuery: string | null,
			newQuery: QueryObject | undefined,
			searchFunc: typeof fetchSearchListResults
		) => {
			if (campaignId && campaignMember?.id) {
				await searchFunc({
					campaignId,
					campaignMemberId: campaignMember.id,
					connectionStatus: ConnectionStatusType.Stranger,
					orgMemberId,
					searchTerm: searchQuery,
					query: newQuery,
					isOpenProfile: false,
				});
			}

			if (campaignMember?.orgMemberId) fetchIsLoggedIn(campaignMember.orgMemberId);
		},
		[campaignId, campaignMember?.id, campaignMember?.orgMemberId, orgMemberId, tableKey]
	);
	const [setOpenProfileSearchTerm, ...openProfilesCallbacks] = useTableSearchWithTableChange(
		fetchOpenProfiles,
		fetchSearchListResults
	);

	const [setSearchTerm, ...callbacks] = useTableSearchWithTableChange(
		fetchSearchList,
		fetchSearchListResults
	);

	function handleUpdateSearchTerm(searchTerm: string) {
		setSearchTerm(searchTerm);
		setOpenProfileSearchTerm(searchTerm);
	}

	return [
		handleUpdateSearchTerm,
		...(tableKey === OPEN_PROFILE_TAB_KEY ? openProfilesCallbacks : callbacks),
	];
}
