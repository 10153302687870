/**
 * Number of days in a week
 */
export const NumDaysInWeek = 7;

/**
 * Mapping Weekdays to number
 */
export const WeekDays = {
	Sunday: 0,
	Monday: 1,
	Tuesday: 2,
	Wednesday: 3,
	Thursday: 4,
	Friday: 5,
	Saturday: 6,
} as const;

/**
 * Unit of time
 */
export const UnitOfTime = {
	year: 'year',
	month: 'month',
	week: 'week',
	day: 'day',
	hour: 'hour',
	minute: 'minute',
	second: 'second',
	millisecond: 'millisecond',
	isoWeek: 'isoWeek',
} as const;

//Const representing the minimum date value
export const MIN_DATE = new Date(-8640000000000000);
