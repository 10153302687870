import React from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import UserMenu from '@copilot/common/components/menus/user';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { EnvironmentType } from './constant';
import { Config } from '@copilot/common/config';
import SupportDropdown from '@copilot/cs/src/components/menus/header/support';
import { isSystemAdmin } from '@copilot/common/utils/organizationMember';
import { useAppcues, addAppcuesLaunchpad } from '@copilot/common/components/snippets/appcues';
import { OrganizationSwitcher } from '../../../../../common/pages/layouts/headerSider/header/organizationSwitcher';
import { Notifications } from '../../../../../common/pages/layouts/headerSider/header/notificationDropdown';

interface BaseHeaderProps {
	environmentType: EnvironmentType;
	showNotifications?: boolean;
	showOrgSelection?: boolean;
	showSaved?: boolean;
	showProfile: boolean;
	showCalendly: boolean;
	showSettings: boolean;
}

const BaseHeader: React.FC<BaseHeaderProps> = (props) => {
	const {
		showProfile,
		showCalendly,
		showSettings,
		environmentType,
		showNotifications = true,
		showOrgSelection = true,
		showSaved = false,
	} = props;
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	const appcuesContext = useAppcues();
	return (
		<Row gutter={16} justify="space-between">
			<Col style={{ flexGrow: 1 }} />
			{!Config.isAgency && (
				<Col
					style={{
						color: 'white',
						cursor: 'pointer',
					}}
				>
					<SupportDropdown
						isHelpPortalDisabled={!Config.helpPortalURL}
						isReportBugDisabled={!Config.intercomAppId}
						onOpenSiteStatus={
							Config.siteStatusURL
								? () => window.open(Config.siteStatusURL)
								: undefined
						}
						onOpenHelpPortal={() => window.open(Config.helpPortalURL)}
						onClickReportIssue={() => window.open(Config.reportBugURL)}
						onLoad={() =>
							Config.isAppcuesEnabled && addAppcuesLaunchpad(appcuesContext)
						}
					/>
				</Col>
			)}
			{showOrgSelection && environmentType == EnvironmentType.TEAMS && (
				<Col style={{ marginTop: '5px' }}>
					<OrganizationSwitcher />
				</Col>
			)}
			<Col style={{ marginTop: '5px' }}>
				{showSaved}
				{showNotifications && <Notifications />}
			</Col>
			<Col>
				{activeMember && (
					<UserMenu
						user={activeMember}
						admin={activeAdmin}
						fontColor="white"
						showProfile={showProfile}
						showCalendly={showCalendly}
						showSettings={showSettings}
					/>
				)}
			</Col>
		</Row>
	);
};

export default BaseHeader;

type WithCalendlyProps = PartialBy<BaseHeaderProps, 'showCalendly'>;

const withCalendly =
	(Component: React.FC<BaseHeaderProps>, isCalendly: boolean) => (props: WithCalendlyProps) => {
		const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
		const calendlyCheck =
			isCalendly && !Config.isAgency && !!activeMember && isSystemAdmin(activeMember);

		return <Component showCalendly={calendlyCheck} {...props} />;
	};

export const BaseHeaderWithCalendly = withCalendly(BaseHeader, true);
