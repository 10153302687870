import React from 'react';
import { Button, Col, Popconfirm, Row } from 'antd';
import BatchActionPanel from '@copilot/common/components/containers/batchAction';

interface IProspectListPageBatchActionProps {
	selectedCount: number;
	onTerminate: () => void;
}

/**
 * [Presentational] Batch Actions for Prospect Table - Search List and Nurture List
 * @param selectedCount number of selected data rows
 * @callback onTerminate called on terminate message automation
 */
const ProspectListPageBatchAction: React.FC<IProspectListPageBatchActionProps> = (props) => {
	const { selectedCount, onTerminate } = props;

	return (
		<BatchActionPanel total={selectedCount}>
			<Row gutter={[16, 0]}>
				<Col>
					<Popconfirm
						title="Are you sure you wish to stop automation on the selected connections?"
						onConfirm={onTerminate}
						okText="Yes"
						cancelText="No"
					>
						<Button type="primary" ghost>
							Stop Automation
						</Button>
					</Popconfirm>
				</Col>
			</Row>
		</BatchActionPanel>
	);
};
export default ProspectListPageBatchAction;
