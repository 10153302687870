import { BaseModel } from './base';
import { attr } from 'redux-orm';
import { IInstanceData } from '@copilot/data/responses/models/instances';

export class InstanceData extends BaseModel<typeof InstanceData, IInstanceData> {
	static modelName = 'InstanceData';
	static fields = {
		id: attr(),
		name: attr(),
		teamsBaseUrl: attr(),
		individualBaseUrl: attr(),
	};
}
