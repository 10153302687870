import React from 'react';
import { Card, Row, Col } from 'antd';

interface SimpleChartProps {
	title?: string | number;
	description?: string;
	showDescription?: boolean;
}

const SimpleChart: React.FC<SimpleChartProps> = (
	props: React.PropsWithChildren<SimpleChartProps>
) => {
	const { children, title = '', description = '', showDescription = true } = props;

	const CardMeta = React.useMemo(() => {
		const titleElement = (
			<Row justify="start">
				<Col style={{ lineHeight: '1' }}>
					<h2>{title}</h2>
				</Col>
			</Row>
		);
		return showDescription ? (
			<Card.Meta title={titleElement} description={description} />
		) : (
			<Card.Meta title={titleElement} />
		);
	}, [title, description, showDescription]);

	return (
		<Card bordered={false}>
			{CardMeta}
			{children}
		</Card>
	);
};

export default SimpleChart;
