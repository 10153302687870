import { AuthenticationManager } from '@copilot/data';
import { useDispatch } from 'react-redux';
import { useFetch } from '../common';
import { useCallback } from 'react';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';
import { AppActions } from '@copilot/common/store/actions/app';
import { LinkedinActions } from '@copilot/common/store/actions/linkedin';
import { CampaignActions } from '@copilot/common/store/actions/campaign';
import { useHistory } from 'react-router';
import { MessageTemplateActions } from '@copilot/common/store/actions';
import { LoginResponseToken } from '@copilot/data/responses/interface';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { getAuthRedirectRequestOptions } from '@copilot/common/utils/authenticationProvider';
import { B2CCustomPolicyRoutes } from '@copilot/data/config/routes';
import { InteractionType } from '@azure/msal-browser';
import { Config } from '@copilot/common/config';

/**
 * Hook to handle organization changing by admins
 */
export const useOrganizationChanger = () => {
	const [, changeOrg] = useFetch(AuthenticationManager.changeOrganization);
	const dispatch = useDispatch();
	const history = useHistory();

	if (Config.isB2CEnabled) {
		const { login } = useMsalAuthentication(InteractionType.Redirect);
		const { instance } = useMsal();

		return async (orgid: string) => {
			await instance.handleRedirectPromise();

			const authOptions = getAuthRedirectRequestOptions({
				authority: B2CCustomPolicyRoutes.ChangeToken,
				scopes: [],
				extraQueryParameters: {
					orgid,
				},
				redirectUri: '/',
			});

			login(InteractionType.Redirect, authOptions);
		};
	}

	const organizationChanger = useCallback(
		(orgId: string) => {
			changeOrg(orgId).then(
				(response: { access_token: string; payload: LoginResponseToken }) => {
					if (!response) return;
					document.cookie = `token=${response.access_token}`;
					dispatch(
						OrganizationMemberActions.loadOrganizationMemberFromToken(response.payload)
					);
					dispatch(AppActions.loadActiveUser(response.payload.omid));
					dispatch(AppActions.loadActiveOrganization(response.payload.oid));
					dispatch(LinkedinActions.resetMemberLinkedinProfile());
					dispatch(CampaignActions.resetCampaigns());
					dispatch(MessageTemplateActions.resetAll());
					history.replace('/');
				}
			);
		},
		[changeOrg]
	);
	return organizationChanger;
};
