import { FC } from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import { SearchProps } from 'antd/lib/input';

export const SearchInputPlaceholder = {
	Name: 'Search by name',
	Customers: 'Search for customers...',
	SearchAndEnter: 'Search & hit enter',
};

// set height manually to fix alignment issues in the antd Input.Search component
const SearchWrapper = styled.div`
	padding: 0 !important;

	&.search-wrapper-small .${(props) => props.theme['@ant-prefix']}-input-wrapper > * {
		height: ${(props) => props.theme['@height-sm']};
	}

	&.search-wrapper-middle .${(props) => props.theme['@ant-prefix']}-input-wrapper > * {
		height: ${(props) => props.theme['@height-base']};
	}

	&.search-wrapper-large .${(props) => props.theme['@ant-prefix']}-input-wrapper > * {
		height: ${(props) => props.theme['@height-lg']};
	}
`;

/**
 * [Presentational] Search Input with wrapper for style adjustments
 * @param {SizeType} size size of input
 */
const SearchInput: FC<SearchProps> = (props) => {
	const { size = 'middle' } = props;
	return (
		<SearchWrapper className={`search-wrapper-${size}`}>
			<Input.Search {...props} />
		</SearchWrapper>
	);
};

export default SearchInput;
