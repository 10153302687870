import { ColumnDefinitionAdvanced } from '../../componentModels/columnDefinition';
import { StringColumn } from './string';

const convertToValue = (values: string[]) => values.join(' ');

export class SubStringColumn<M> extends StringColumn<M> {
	constructor(c: ColumnDefinitionAdvanced) {
		super(c);

		this.render = (text: string, record: M) => {
			const { properties = [] } = c;
			const { subproperties = [] } = c;
			let caption = '';

			const values = convertToValue(this.getValues(properties, record));

			if (subproperties) caption = convertToValue(this.getValues(subproperties, record));

			return (
				<div>
					<span>{values}</span>
					{subproperties && caption != '' && (
						<span className="column-caption">{caption}</span>
					)}
				</div>
			);
		};
	}
}
