// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YcPwwuLmkAPyhcseVplv {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n.YcPwwuLmkAPyhcseVplv > * {\n  width: 800px;\n  margin: 56px 0;\n}\n.E7dIFhHyV2DRjt1sijlp {\n  padding: 16px;\n  margin-bottom: 16px;\n}\n.E7dIFhHyV2DRjt1sijlp .copilot-card-body {\n  padding: 0;\n}\n.WeMiSMKnPq0txbOclVue {\n  margin: 16px 0;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 28px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/campaignDashboard/searchListCommon/searchListCommon.module.less"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,uBAAA;EACA,WAAA;AACD;AAAC;EACC,YAAA;EACA,cAAA;AAEF;AACA;EACC,aAAA;EAIA,mBAAA;AAFD;AAHA;EAGE,UAAA;AAGF;AAEA;EACC,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAD","sourcesContent":[".instructionWrapper {\n\tdisplay: flex;\n\tjustify-content: center;\n\twidth: 100%;\n\t& > * {\n\t\twidth: 800px;\n\t\tmargin: 56px 0;\n\t}\n}\n.searchUrlModule {\n\tpadding: 16px;\n\t:global(.copilot-card-body) {\n\t\tpadding: 0;\n\t}\n\tmargin-bottom: 16px;\n}\n\n.searchListTitle {\n\tmargin: 16px 0;\n\tfont-size: 20px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tline-height: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instructionWrapper": "YcPwwuLmkAPyhcseVplv",
	"searchUrlModule": "E7dIFhHyV2DRjt1sijlp",
	"searchListTitle": "WeMiSMKnPq0txbOclVue"
};
export default ___CSS_LOADER_EXPORT___;
