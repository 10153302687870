import React, { useMemo } from 'react';
import { Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import FileUploader from '.';
import { UploadFile } from 'antd/lib/upload/interface';

class LogoFile implements UploadFile {
	public uid: UploadFile['uid'];
	public status: UploadFile['status'];
	public name: UploadFile['name'];
	public url: UploadFile['url'];
	public size: UploadFile['size'];
	public type: UploadFile['type'];
	public thumbUrl: UploadFile['thumbUrl'];
	constructor(url: string) {
		this.uid = '1';
		this.name = '';
		this.status = 'done';
		this.url = url;
		this.thumbUrl = url;
		this.size = 0;
		this.type = 'image';
	}
}

interface LogoUploadProps extends React.ComponentProps<typeof FileUploader> {
	logo?: any;
}

const LogoUploader: React.FC<LogoUploadProps> = (props) => {
	const { logo, ...fileUploaderProps } = props;
	const logoList = useMemo(() => {
		if (logo && typeof logo === 'string') return [new LogoFile(logo)];
		else return [];
	}, [logo]);
	return (
		<FileUploader
			{...fileUploaderProps}
			fileList={logoList}
			accept="image/*"
			listType="picture-card"
		>
			<Col>
				<PlusOutlined />
				<div>Upload</div>
			</Col>
		</FileUploader>
	);
};

export default LogoUploader;
