import { FC } from 'react';
import { Typography, Timeline } from 'antd';
import moment from 'moment';
import { CampaignHistoryModel } from '@copilot/common/components/drawer/wrappers/contact/model';
import isNil from 'lodash/isNil';

const { Text } = Typography;

type CampaignHistoryRowProps = {
	// a list of campaignHistory objects
	campaignHistory: CampaignHistoryModel[];
};

const CampaignHistoryRows: FC<CampaignHistoryRowProps> = (props) => {
	const { campaignHistory } = props;

	return (
		<Timeline
			items={campaignHistory.map((campaign) => {
				return {
					key: `${campaign.name} ${campaign.start}`,
					children: (
						<>
							<Text>
								{campaign.name}{' '}
								{moment.utc(campaign.start).local().format('YYYY-MM-DD')}
							</Text>
							{!isNil(campaign.end) ? (
								<Text>
									&nbsp;- {moment.utc(campaign.end).local().format('YYYY-MM-DD')}{' '}
								</Text>
							) : null}
						</>
					),
				};
			})}
		/>
	);
};

export default CampaignHistoryRows;
