import { PaymentErrorCode } from '@copilot/data/responses/models/billing';
import { Popover, Tag, Typography } from 'antd';

const { Text } = Typography;

/**
 * The description messages for each of the error codes from stripe
 * These are pulled form decline codes in the stripe documenation
 *
 * @see {@link https://stripe.com/docs/declines/codes}
 */
const PAYMENT_STATUS_MESSAGE = {
	[PaymentErrorCode.authentication_required]:
		'The card was declined as the transaction requires authentication.',
	[PaymentErrorCode.approve_with_id]: 'The payment cannot be authorized.',
	[PaymentErrorCode.call_issuer]: 'The card has been declined for an unknown reason.',
	[PaymentErrorCode.card_not_supported]: 'The card does not support this type of purchase.',
	[PaymentErrorCode.card_velocity_exceeded]:
		'The customer has exceeded the balance or credit limit available on their card.',
	[PaymentErrorCode.currency_not_supported]: 'The card does not support the specified currency.',
	[PaymentErrorCode.do_not_honor]: 'The card has been declined for an unknown reason.',
	[PaymentErrorCode.do_not_try_again]: '	The card has been declined for an unknown reason.',
	[PaymentErrorCode.duplicate_transaction]:
		'A transaction with identical amount and credit card information was submitted very recently.',
	[PaymentErrorCode.expired_card]: 'The card has expired.',
	[PaymentErrorCode.fraudulent]:
		'The payment has been declined as Stripe suspects it is fraudulent.',
	[PaymentErrorCode.generic_decline]: 'The card has been declined for an unknown reason.',
	[PaymentErrorCode.incorrect_number]: 'The card number is incorrect.',
	[PaymentErrorCode.incorrect_cvc]: 'The CVC number is incorrect.',
	[PaymentErrorCode.incorrect_pin]:
		'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
	[PaymentErrorCode.incorrect_zip]: 'The ZIP/postal code is incorrect.',
	[PaymentErrorCode.insufficient_funds]:
		'The card has insufficient funds to complete the purchase.',
	[PaymentErrorCode.invalid_account]:
		'	The card, or account the card is connected to, is invalid.',
	[PaymentErrorCode.invalid_amount]:
		'The payment amount is invalid, or exceeds the amount that is allowed.',
	[PaymentErrorCode.invalid_cvc]: '	The CVC number is incorrect.',
	[PaymentErrorCode.invalid_expiry_month]: '	The expiration month is invalid.',
	[PaymentErrorCode.invalid_expiry_year]: 'The expiration year is invalid.',
	[PaymentErrorCode.invalid_number]: 'The card number is incorrect.',
	[PaymentErrorCode.invalid_pin]:
		'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
	[PaymentErrorCode.issuer_not_available]:
		'	The card issuer could not be reached, so the payment could not be authorized.',
	[PaymentErrorCode.lost_card]:
		'The payment has been declined because the card is reported lost.',
	[PaymentErrorCode.merchant_blacklist]:
		'The payment has been declined because it matches a value on the Stripe user’s block list.',
	[PaymentErrorCode.new_account_information_available]:
		'The card, or account the card is connected to, is invalid.',
	[PaymentErrorCode.no_action_taken]: 'The card has been declined for an unknown reason.',
	[PaymentErrorCode.not_permitted]: 'The payment is not permitted.',
	[PaymentErrorCode.offline_pin_required]: 'The card has been declined as it requires a PIN.',
	[PaymentErrorCode.online_or_offline_pin_required]:
		'The card has been declined as it requires a PIN.',
	[PaymentErrorCode.pickup_card]:
		'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).',
	[PaymentErrorCode.pin_try_exceeded]: 'The allowable number of PIN tries has been exceeded.',
	[PaymentErrorCode.processing_error]: 'An error occurred while processing the card.',
	[PaymentErrorCode.reenter_transaction]:
		'The payment could not be processed by the issuer for an unknown reason.',
	[PaymentErrorCode.restricted_card]:
		'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).',
	[PaymentErrorCode.revocation_of_all_authorizations]:
		'The card has been declined for an unknown reason.',
	[PaymentErrorCode.revocation_of_authorization]:
		'The card has been declined for an unknown reason.',
	[PaymentErrorCode.security_violation]: 'The card has been declined for an unknown reason.',
	[PaymentErrorCode.service_not_allowed]: 'The card has been declined for an unknown reason.',
	[PaymentErrorCode.stolen_card]:
		'The payment has been declined because the card is reported stolen.',
	[PaymentErrorCode.stop_payment_order]: 'The card has been declined for an unknown reason.',
	[PaymentErrorCode.testmode_decline]: 'A Stripe test card number was used.',
	[PaymentErrorCode.transaction_not_allowed]: 'The card has been declined for an unknown reason.',
	[PaymentErrorCode.try_again_later]: 'The card has been declined for an unknown reason.',
	[PaymentErrorCode.withdrawal_count_limit_exceeded]:
		'The customer has exceeded the balance or credit limit available on their card.',
};

/**
 * The props required for the payment error code tag
 */
interface PaymentErrorCodeTagProps {
	status: PaymentErrorCode;
}

/**
 * Component to display the payment status error and the reason
 *
 * @param param
 * @param param.status the payment status code to display
 * @returns Payment Status Tag component
 */
const PaymentErrorCodeTag: React.FC<PaymentErrorCodeTagProps> = ({ status }) => (
	<Popover
		placement="topLeft"
		trigger="click"
		title={
			<Text type="danger" code>
				{status}
			</Text>
		}
		content={<Text>{PAYMENT_STATUS_MESSAGE[status]}</Text>}
	>
		<Tag color="error" style={{ cursor: 'pointer' }}>
			Unpaid
		</Tag>
	</Popover>
);
export default PaymentErrorCodeTag;
