import React, { ComponentProps, ReactNode, useContext } from 'react';
import {
	ResponsiveContainer, XAxis, Tooltip, AreaChart, Area,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import styled, { ThemeContext } from 'styled-components';

const StyledContainer = styled(ResponsiveContainer)`
	.recharts-tooltip-wrapper {
		z-index: 1;
	}
`;

interface AreaGraphProps {
	timeSeriesKey?: string;
	data: TypedObject<number>[];
	datakeyFilters?: TypedObject<boolean>;
	colors?: TypedObject<string>;
	ownerMap?: TypedObject<string>;
}

const AreaGraph = React.memo<AreaGraphProps>((props: AreaGraphProps) => {
	const themeContext = useContext(ThemeContext);
	const {
		datakeyFilters, data, timeSeriesKey = 'x', ownerMap = {}, colors = {},
	} = props;
	if (data.length <= 0) return null;
	const displayKeys = React.useMemo(() => {
		if (!datakeyFilters) return [];
		return Object.keys(datakeyFilters).filter(
			(key) => datakeyFilters[key]
		);
	}, [data[0], datakeyFilters]);

	const toolTipProps: {
		formatter: ComponentProps<typeof Tooltip<number, string>>['formatter']
		labelFormatter : (label: any, payload: Array<Payload<number, string>>) => ReactNode,
	} =
		Object.keys(ownerMap).length > 1
			? {
				labelFormatter: (label: string | number) => new Date(
					typeof label === 'string' ? parseInt(label) : label
				).toLocaleDateString(),
				formatter: (value: number, id: string) => [
					value,
					ownerMap[id],
				],
			}
			: {
				labelFormatter: (_label: string | number) => null,
				formatter: (
					value: number,
					_name: string,
					payload: Payload<number, string>
				) => [
					value,
					typeof payload.payload.x === 'number'
						? new Date(payload.payload.x as number).toLocaleDateString()
						: payload.payload.x,
				],
			};

	const curves = React.useMemo(
		() =>
			displayKeys.map((key) => {
				const color = colors[key] ?? themeContext['@primary-color'];
				return (
					<Area
						isAnimationActive={false}
						dataKey={key}
						fill={color}
						type="monotone"
						stroke={color}
						fillOpacity={0.3}
						key={key}
					/>
				);
			}),
		[displayKeys, colors]
	);

	return (
		<StyledContainer minHeight={125}>
			<AreaChart data={data} style={{ marginTop: '15px' }}>
				<XAxis
					hide
					dataKey={timeSeriesKey}
					type="number"
					domain={[data[0]?.x, data[data.length - 1]?.x]}
				/>
				<Tooltip<number, string>
					cursor={{ fill: 'transparent' }}
					itemStyle={{ color: 'black' }}
					allowEscapeViewBox={{ x: true, y: true }}
					formatter={toolTipProps.formatter}
					labelFormatter={toolTipProps.labelFormatter}
				/>
				{curves}
			</AreaChart>
		</StyledContainer>
	);
});

export default AreaGraph;
