import { attr, fk, oneToOne } from 'redux-orm';
import { ILinkedInProfile } from './linkedinProfile';
import { BaseModel } from './base';

interface Fields {
	dailyInvitesAllocated: number;
	dailyMessagesAllocated: number;
	isLoggedIn: boolean;
	linkedInProfile: ILinkedInProfile | null;
	orgMemberId: string;
}

interface InstanceFields {}

export type ILinkedInMeta = Fields & InstanceFields;

export class LinkedInMeta extends BaseModel<typeof LinkedInMeta, ILinkedInMeta> {
	static modelName = 'LinkedInMeta';
	static options = { idAttribute: 'orgMemberId' };
	static fields = {
		dailyInvitesAllocated: attr(),
		dailyMessagesAllocated: attr(),
		isLoggedIn: attr(),
		linkedInProfile: fk({ to: 'LinkedInProfile' }),
		orgMemberId: oneToOne({
			to: 'OrganizationMember',
			as: 'organizationMember',
			relatedName: 'linkedInMeta',
		}),
	};
}
