import { Col, Row } from 'antd';
import LinkedInLoginForm from '../../forms/linkedin/login';
import { SafetyCertificateOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Config } from '@copilot/common/config';
import Link from 'antd/lib/typography/Link';

interface IIntialLinkedInLoginStepProps {
	onSubmit: (userName: string, password: string) => Promise<void>;
	isFetching: boolean;
}

const DarkGrey = '#000000D9';

const StyledRow = styled(Row)`
	text-align: center;
`;

const IntialLinkedInLoginStep: React.FC<IIntialLinkedInLoginStepProps> = (props) => {
	const { onSubmit, isFetching } = props;
	return (
		<>
			<StyledRow>
				<Col span={18} offset={3}>
					<LinkedInLoginForm
						onSubmit={onSubmit}
						isSubmitting={isFetching}
						emailPlaceholder="Enter your LinkedIn email"
						passwordPlaceholder="Enter your LinkedIn password"
						resetPwdLabel="Forgot your password?"
						resetPwdCTALabel="Reset password"
						isButtonFullWidth
						buttonSize="large"
					/>
				</Col>
			</StyledRow>
			{!Config.isAgency && (
				<StyledRow>
					<Col span={18} offset={3}>
						<SafetyCertificateOutlined />
						<p style={{ fontSize: '0.8em' }}>
							We do not post anything on LinkedIn without your consent. You can find
							more information on our{' '}
							<Link
								href={Config.termsAndServicesURL}
								target="_blank"
								underline
								style={{ color: DarkGrey }}
							>
								terms and serivces
							</Link>{' '}
							and{' '}
							<Link
								href={Config.privacyPolicyURL}
								target="_blank"
								underline
								style={{ color: DarkGrey }}
							>
								privacy policy.
							</Link>
						</p>
					</Col>
				</StyledRow>
			)}
		</>
	);
};

export default IntialLinkedInLoginStep;
