import { Space, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { pluralString } from '@copilot/common/utils/stringFormat';

type SearchListOverviewProps = {
	/**
	 * Search list count
	 */
	count: number;
};

/**
 * Overview of the list of prospects
 * @param props
 * @constructor
 */
export function SearchListOverview({ count }: SearchListOverviewProps) {
	return (
		<Space size={'small'}>
			<MailOutlined />
			<Typography.Text>
				{`${count} ${pluralString('prospect', count)} are waiting ${pluralString(
					'invite',
					count
				)}`}
			</Typography.Text>
		</Space>
	);
}
