import { AnyAction } from 'redux';
import Store from '@copilot/common/store';
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { TaskManagerInstance } from '@copilot/data/managers/tasks/base';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import drawerManager from '@copilot/common/utils/drawerManager';
import { TaskModel } from '@copilot/data/responses/models/task';
import { TaskMonitorStatus } from '@copilot/common/utils/constant';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { CampaignDashboardTabKeys } from '@copilot/common/utils/campaign/dashboardTabs';
import { CSCustomerTypeMap } from '@copilot/common/store/models/const/map';

export const useStoreLoader = async <R>(
	action: (p: R) => AnyAction,
	getter: () => Promise<R>
): Promise<R> => {
	const response = await getter();
	Store.Dispatch(action(response));
	return response;
};

export const useStoreFetch = <R>(
	action: (p: R) => AnyAction,
	getter: () => Promise<R>,
	watchArray: Array<any>
): { data?: R; isFetching: boolean; error?: any } => {
	const [state, setState] = useState<{ data?: R; isFetching: boolean; error?: any }>({
		data: undefined,
		isFetching: true,
		error: undefined,
	});
	React.useEffect(() => {
		useStoreLoader(action, getter)
			.then((response) => setState({ data: response, isFetching: false, error: undefined }))
			.catch((err) => {
				setState({ error: err, isFetching: false, data: undefined });
				throw err;
			});
	}, watchArray);
	return state;
};

export interface FetchState<R> {
	data?: R;
	isFetching: boolean;
	error?: any;
}

/**
 * Fetch function that returns a fetch state and a function to call the fetch function
 * @param getter The fetch function
 * @param callback The callback function to call after the fetch function is called
 */
export function useFetchV2<R, G extends (...args: any[]) => Promise<R>>(
	getter: G extends (...args: any[]) => Promise<R> ? G : (...args: any[]) => Promise<R>,
	callback?: undefined
): [FetchState<R>, typeof getter];
export function useFetchV2<R, G extends (...args: any[]) => Promise<R>, CallbackReturn>(
	getter: G extends (...args: any[]) => Promise<R> ? G : (...args: any[]) => Promise<R>,
	callback: (p: R, args: Parameters<G>) => CallbackReturn
): [FetchState<R>, typeof getter];
export function useFetchV2<R, G extends (...args: any[]) => Promise<R>, CallbackReturn>(
	getter: G,
	callback?: (p: R, args: Parameters<G>) => CallbackReturn
): [FetchState<R | CallbackReturn>, typeof getter] {
	const lastAbortController = useRef<{ abort: boolean }>({ abort: false });
	const [data, setData] = useState<R>();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [error, setError] = useState<any>();
	useEffect(
		() => () => {
			lastAbortController.current.abort = true;
		},
		[]
	);
	const fetchFunction: typeof getter = React.useCallback<any>(
		(...args: Parameters<typeof getter>) => {
			if (!lastAbortController.current.abort) lastAbortController.current.abort = true;
			setIsFetching(true);
			const abortController = { abort: false };
			lastAbortController.current = abortController;

			return getter(...args)
				.then((result) => {
					if (abortController.abort) return undefined;
					setIsFetching(false);
					setData(result);
					setError(undefined);
					return callback ? callback(result, args) : result;
				})
				.catch((err) => {
					if (!abortController.abort) {
						setIsFetching(false);
						setData(undefined);
						setError(err);
					}
					throw err;
				});
		},
		[getter, callback]
	);
	return [{ data, isFetching, error }, fetchFunction];
}

export function useFetch<R, G>(
	getter: G extends (...args: any[]) => Promise<R> ? G : (...args: any[]) => Promise<R>,
	storeAction?: undefined,
	transform?: undefined
): [FetchState<R>, typeof getter];
export function useFetch<R, G>(
	getter: G extends (...args: any[]) => Promise<R> ? G : (...args: any[]) => Promise<R>,
	storeAction: (p: R) => AnyAction,
	transform?: undefined
): [FetchState<R>, typeof getter];
export function useFetch<R, G, T>(
	getter: G extends (...args: any[]) => Promise<R> ? G : (...args: any[]) => Promise<R>,
	storeAction: (p: T) => AnyAction,
	transform: (p: R, ...args: Parameters<typeof getter>) => T
): [FetchState<R>, typeof getter];
export function useFetch<R, G, T>(
	getter: G extends (...args: any[]) => Promise<R> ? G : (...args: any[]) => Promise<R>,
	storeAction?: (p: T | R) => AnyAction,
	transform?: (p: R, ...args: Parameters<typeof getter>) => T
): [FetchState<R>, typeof getter] {
	const lastAbortController = useRef<{ abort: boolean }>({ abort: false });
	const [data, setData] = useState<R>();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [error, setError] = useState<any>();
	useEffect(
		() => () => {
			lastAbortController.current.abort = true;
		},
		[]
	);
	const fetchFunction: typeof getter = React.useCallback<any>(
		(...args: Parameters<typeof getter>) => {
			if (!lastAbortController.current.abort) lastAbortController.current.abort = true;
			setIsFetching(true);
			const abortController = { abort: false };
			lastAbortController.current = abortController;

			return getter(...args)
				.then((result) => {
					if (abortController.abort) return undefined;
					setIsFetching(false);
					setData(result);
					setError(undefined);
					if (storeAction)
						Store.Dispatch(
							storeAction(transform ? transform(result, ...args) : result)
						);
					return result;
				})
				.catch((err) => {
					if (!abortController.abort) {
						setIsFetching(false);
						setData(undefined);
						setError(err);
					}
					throw err;
				});
		},
		[getter, storeAction]
	);
	return [{ data, isFetching, error }, fetchFunction];
}

/**
 * Create a state object that also watches for and updates if a value is changed.
 * @param {T} watchedValue The value we want to watch and automatically make changes
 *                         to the state if changed
 * @param {T | (() => T} initialValue The inital value we will feed into useState
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]} The state tuple that will allow us to
 *                                                         get and edit the state
 */
export const useWatchedState = <T>(
	watchedProp: T,
	initialValue: T | (() => T)
): [T, React.Dispatch<React.SetStateAction<T>>] => {
	// We can probably merge watchedProp and initialValue but we'll do that when this causes issues.
	const [state, setState] = useState(initialValue);
	useEffect(() => setState(watchedProp), [watchedProp]);
	return [state, setState];
};

/**
 * Poll for a task's status until it's completed or timedout
 * @param taskId The id of the task
 * @param timeoutSeconds Max running time of polling
 * @param refreshSeconds Poll interval
 */
export const useTaskMonitor = (
	taskId?: string,
	timeoutSeconds = 120,
	refreshSeconds = 5,
	initialTask?: TaskModel,
	onTimeout?: () => void
): [TaskModel | undefined, () => void] => {
	const [currentIteration, setCurrentIteration] = useState<number>(0);
	const [task, setTask] = useState<TaskModel | undefined>(initialTask);
	/**
	 * Base fetch task used by the fetchTask wrapper to include interval clears and task payload updates.
	 */
	const [, _baseFetchTask] = useFetch<TaskModel, typeof TaskManagerInstance.getStatelessTask>(
		TaskManagerInstance.getStatelessTask
	);
	const interval = useRef<number>();

	useEffect(() => {
		setTask(initialTask);
	}, [initialTask]);

	const resetTimeoutTimer = useCallback(() => {
		setCurrentIteration(0);
	}, [setCurrentIteration]);

	const fetchTask = useCallback(
		async (tId: string) => {
			const fetchedTask = await _baseFetchTask(tId);
			if (fetchedTask?.isComplete) window.clearInterval(interval.current);
			if (fetchedTask?.isUnsuccessful) {
				onTimeout?.();
				resetTimeoutTimer();
			}
			setTask(fetchedTask);
		},
		[_baseFetchTask]
	);

	useEffect(() => {
		interval.current = 0;
		const cleanUp = () => {
			if (interval.current) window.clearInterval(interval.current);
			interval.current = 0;
		};
		if (!taskId) cleanUp();
		else {
			fetchTask(taskId);
			interval.current = window.setInterval(() => {
				console.log(`monitor task: ${taskId}`);
				fetchTask(taskId);
				setCurrentIteration((iteration) => iteration + refreshSeconds);
			}, refreshSeconds * 1000);
		}
		return cleanUp;
	}, [taskId]);

	useEffect(() => {
		if (currentIteration > timeoutSeconds) {
			window.clearInterval(interval.current);
			onTimeout?.();
			resetTimeoutTimer();
		}
	}, [currentIteration, resetTimeoutTimer]);

	return [task, resetTimeoutTimer];
};

/**
 * Call a task initializer while using useTaskMonitor to check for existing tasks
 * @param getter task initializing getter function
 * @param tasksFetch fetch state of the task getter
 */
export const useTaskInitializerWithTaskMonitor = <G>(
	getter: G extends (...getterArgs: any[]) => Promise<string>
		? G
		: (...getterArgs: any[]) => Promise<string>,
	tasksFetch: FetchState<TaskModel>
): [typeof getter, TaskMonitorStatus] => {
	const [taskInitializer, initializeTask] = useFetch(getter);

	// Use either the started task or the existing task. It's possible for this to be undefined
	const taskId = useMemo(
		() => taskInitializer.data ?? tasksFetch.data?.taskId,
		[taskInitializer.data, tasksFetch.data?.taskId]
	);
	// Use task monitor will allow for undefined taskId right now because there isn't a way to conditionally call hooks without breaking Rule of Hooks
	const [searchTask] = useTaskMonitor(taskId, undefined, undefined, tasksFetch.data);

	const taskStatus: TaskMonitorStatus = useMemo(() => {
		if (tasksFetch.isFetching) return TaskMonitorStatus.FetchingTask;
		if (taskInitializer.isFetching) return TaskMonitorStatus.GeneratingTask;
		if (searchTask && !searchTask.isComplete)
			return taskInitializer.data
				? TaskMonitorStatus.NewTaskRunning
				: TaskMonitorStatus.ExistingTaskRunning;
		return TaskMonitorStatus.Available;
	}, [tasksFetch.isFetching, taskInitializer.isFetching, taskInitializer.data, searchTask]);

	return [initializeTask, taskStatus];
};

//TODO: update to return function to remove boolean check
export const useRedirect = (redirect: boolean, message: string): void => {
	const history = useHistory();
	useEffect(() => {
		if (redirect) {
			notificationManager.showInfoNotification({ message });
			history.push('/');
		}
	}, [redirect]);
};

/**
 * Creates debounced function - called when a user hasn't called debounced function for a given time interval
 * e.g. timeline: delay = 1000ms = 1s
 * 		         --------------------------------------------------------------------------------------------
 * 		fn call: 0s                1s      1.5s      2.0s                  3.0s
 *  actual call:                   1s                                      3.0s
 * 			the call from 0s is successfully called at 1s
 * 			each call between 1s - 2s resets delay, so the call at 2.0s is successfully called at 3.0s
 *
 * @param func function which we want to debounce
 * @param delay delay (ms) after latest execution (and no further executions) afterwhich the function will be called
 * @param immediate whether the function should be called immediately at first execution or after the delay
 * @returns debounced function
 */
export const useDebounce = <T extends unknown[], R>(
	func: (...args: T) => R,
	delay = 500,
	immediate = false
): ((...args: T) => void) => {
	const timeout = useRef<ReturnType<typeof setTimeout>>();
	const debouncedFunction = React.useCallback<(...args: T) => void>(
		(...args) => {
			if (timeout.current) clearTimeout(timeout.current);
			const params = args;
			const later = () => {
				timeout.current = undefined;
				if (!immediate) func(...params);
			};
			const callNow = immediate && !timeout.current;
			timeout.current = setTimeout(later, delay);
			if (callNow) func(...params);
		},
		[func, delay]
	);

	useEffect(() => {
		timeout.current && clearTimeout(timeout.current);
	}, []);

	return debouncedFunction;
};

/**
 * Creates throttled function - calls given function at specified time intervals while the user continues to carry out an event
 * Differs from debouncing:
 * 		throttling - Function may only be called once per interval of time
 * 		debouncing - Function only called if it hasn't been attempted to execute for delay since last attempt
 * e.g. timeline: delay = 1000ms = 1s, bufferFn = true
 * 		         --------------------------------------------------------------------------------------------
 * 		fn call: 0s      0.5s      1s      1.5s
 *  actual call: 0s                1s              2.0s
 * 		the calls at 0.5s is thrown out (only 1 call per 1000ms)
 * 		because bufferFn = true, the call at 1.5s is buffered and called after the delay of last successful call ends (2.0s)
 *
 * e.g. timeline: delay = 1000ms = 1s, bufferFn = false
 * 		         --------------------------------------------------------------------------------------------
 * 		fn call: 0s      0.5s      1s      1.5s
 *  actual call: 0s                1s
 * 		the calls at 0.5s and 1.5s are thrown out (only 1 call per 1000ms)
 *
 * @param func function that needs to be throttled
 * @param delay delay (ms) before next function call can happen
 * @param bufferFn whether a call before the delay has ended is automatically called at the end of the throttled interval
 * @returns throttled function
 */
export const useThrottle = <T extends unknown[], R>(
	func: (...args: T) => R,
	delay = 1000,
	callAtEndInterval = true
): ((...args: T) => void) => {
	let lastFunc: NodeJS.Timeout;
	let lastRan: number;
	const throttledFunction = React.useCallback<(...args: T) => void>(
		(...args) => {
			// if first call to the function
			if (!lastRan) {
				func(...args);
				lastRan = Date.now();
				// automatic call to function after last throttled interval
			} else if (callAtEndInterval) {
				clearTimeout(lastFunc);
				lastFunc = setTimeout(() => {
					if (Date.now() - lastRan >= delay) {
						func(...args);
						lastRan = Date.now();
					}
				}, delay - (Date.now() - lastRan));
				// user can only call function if delay has passed
			} else if (Date.now() - lastRan >= delay) {
				func(...args);
				lastRan = Date.now();
			}
		},
		[func, delay]
	);
	return throttledFunction;
};

/**
 * Redirect the user to template page (click and send or automated messaging).
 * When impersonating, CS cannot redirect to automated messaging tab as it cannot be accessed through URL.
 * @param templateTabName template page to redirect to (name of the tab)
 */
export function useRedirectToTemplate(
	templateTabName: CampaignDashboardTabKeys.Template | CampaignDashboardTabKeys.Sequence
): (campaignId: string) => void {
	const history = useHistory();
	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeOrg = useSelector(OrganizationSelectors.getActiveOrganization);
	return useCallback(
		(campaignId: string) => {
			let templateUrl = '/';
			if (activeAdmin && activeMember && activeOrg) {
				const csCustomerType = CSCustomerTypeMap[activeOrg.orgType];
				if (templateTabName === CampaignDashboardTabKeys.Template) {
					templateUrl = `/${csCustomerType}/${activeMember.organizationId}/${activeMember.id}`;
				} else return;
			} else if (
				templateTabName === CampaignDashboardTabKeys.Sequence ||
				!activeMember?.isOrgAdmin
			) {
				templateUrl = `/campaign/${campaignId}`;
			}
			history.push({ pathname: templateUrl, search: `tab=${templateTabName}` });
			drawerManager.closeDrawer();
		},
		[templateTabName, activeMember, activeAdmin]
	);
}

/**
 * Custom hooks for parsing and updating the query string
 *
 * @param defaultParams the default value for search params
 *
 * @returns The current values for search params and callback to update those
 */
export function useSearchParams<T>(defaultParams?: T): [T, (p: Partial<T>) => void] {
	const location = useLocation();
	const history = useHistory();
	const params = useMemo(() => {
		const searchParams = new URLSearchParams(decodeURIComponent(location.search));
		const updatedParams: Record<string, string> = {};
		searchParams.forEach((v, k) => {
			updatedParams[k] = v;
		});
		return { ...defaultParams, ...updatedParams } as unknown as T;
	}, [location.search, defaultParams]);

	const updateSearchParam = useCallback(
		(parameters: Partial<T>) => {
			const searchParams = new URLSearchParams(Object.entries(parameters));
			history.replace({ pathname: location.pathname, search: searchParams.toString() });
		},
		[location.pathname]
	);
	return [params, updateSearchParam];
}

/**
 * Hook to keep track of previous state variable(s) which can be any type
 */
export function usePreviousRef<T>(value: T) {
	const currentRef: React.MutableRefObject<T | undefined> = useRef<T>();
	useEffect(() => {
		currentRef.current = value;
	});
	return currentRef.current;
}

/**
 * Hook for using async/await in useEffect
 */
export const useEffectAsync = (asyncFunc: () => Promise<void>, deps: React.DependencyList) => {
	useEffect(() => {
		asyncFunc();
	}, deps);
};

/**
 * Hook for handling callbacks when unmounting a component (useEffect cleanup)
 * useEffect cleanup documentation: https://reactjs.org/docs/hooks-effect.html#effects-with-cleanup
 * @param {function} callback function to call on unmount
 */
export const useOnUnmount = (callback: () => void) => {
	useEffect(
		() => () => {
			callback();
		},
		[]
	);
};

/**
 * Hook for polling (separated by a delay) until a specified timeout duration
 * @param callback
 * @param delay
 * @param numIterations
 * @param onTimeout
 * @returns callback to start/stop polling
 */
type PollCallback = () => unknown;
export const usePollUntilTimeout = (
	callback: PollCallback,
	delay: number,
	timeoutDurationMs: number,
	onTimeout: () => void
) => {
	const poll = useRef<number>();
	const pollCount = useRef<number>(0);

	useEffect(() => {
		initiatePolling();
		return cancelPolling;
	}, []);

	const initiatePolling = () => {
		poll.current = window.setTimeout(() => {
			callback();
			pollCount.current++;
			if (pollCount.current * delay < timeoutDurationMs) {
				initiatePolling();
			} else {
				onTimeout();
			}
		}, delay);
	};

	const cancelPolling = () => {
		if (poll.current) {
			clearTimeout(poll.current);
		}
	};

	return cancelPolling;
};
