import { ComponentType, useState } from 'react';
import { Table as AntdTable } from 'antd';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { LoadingOutlined } from '@ant-design/icons';

export type TableProps<T> = Omit<AntdTableProps<T>, 'loading'> & { loading: boolean };

/**
 * Custom Table which replace the default spinner with the custom indicator
 * @param props
 */
const Table = <T extends Record<string, unknown>>(props: TableProps<T>): JSX.Element => {
	const { loading, ...rest } = props;
	return (
		<AntdTable
			{...rest}
			loading={{ tip: 'Loading...', spinning: loading, indicator: <LoadingOutlined /> }}
		/>
	);
};

/**
 * HOC for handling pagination
 * @param Component
 */
export const withTablePagination =
	<T,>(Component: ComponentType<TableProps<T>>) =>
	(props: Omit<TableProps<T>, 'pagination'> & { total: number }) => {
		const { onChange, total, ...rest } = props;
		const [page, setPage] = useState<number>(1);
		const handleChange: typeof onChange = (pagination, ...args) => {
			setPage(pagination?.current ?? 1);
			onChange?.(pagination, ...args);
		};
		return (
			<Component {...rest} pagination={{ total, current: page }} onChange={handleChange} />
		);
	};

export default Table;
