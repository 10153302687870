import { RouterState, connectRouter } from 'connected-react-router';
import { History } from 'history';
import { AppState, AppReducer } from './app';
import { combineReducers, ReducersMapObject, Reducer } from 'redux';
import { PermissionsReducer, IPermissionsState } from './permissions';

export interface RootState {
	app: AppState;
	router: RouterState;
	permissions: IPermissionsState;
}

export const createReducer = <T>(history: History, reducers?: ReducersMapObject<T>) =>
	combineReducers<Reducer<RootState & ReducersMapObject<T>>>({
		app: AppReducer,
		router: connectRouter(history),
		permissions: PermissionsReducer,
		...reducers,
	});
