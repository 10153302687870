import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useDispatch, useSelector } from 'react-redux';
import { ICampaignDashboardSearchListBaseProps } from '../const';
import { useEffect, useMemo } from 'react';
import { DropdownOption } from '../../teamMemberDropDown/types';
import { CampaignMemberModel, getName } from '@copilot/common/utils/campaignMember/models';
import { useCanViewPermission } from '@copilot/common/hooks/permission';
import partial from 'lodash/partial';
import { resetOneCampaignSearchListTab, updateSearchListSelectedMemberId } from '../data/actions';
import { PermissionName } from '@copilot/common/hooks/permission/interface';
import { campaignSearchListTabMemberSelector } from '../data/selector';
import { selectCampaignMembers } from '@copilot/common/utils/campaignMember/selector';
import { useOnUnmount } from '@copilot/common/hooks/common';
import { ModelState } from '@copilot/common/store/models/fetch';
import { SEARCH_STATUSES } from '@copilot/common/components/editors/searchUrl/update/consts';

type UseCampaignMemberSelectionHookProps = ICampaignDashboardSearchListBaseProps;

/**
 * Hook for providing options for selecting and listing campaign members
 * @param param0
 * @returns
 */
export default function useCampaignMemberSelection({
	initialSelectedMemberId,
	campaignId,
	onSearchListStatusChange,
}: UseCampaignMemberSelectionHookProps) {
	useEffect(() => {
		if (!isCampaignMemberOptionsVisible) {
			const campaignMemberId = campaignMembers?.data.find(
				(m) => m.orgMemberId === activeMember?.id
			)?.id;
			onCampaignMemberSelect(campaignMemberId);
		} else if (campaignMembers?.data.length === 1) {
			onCampaignMemberSelect(campaignMembers.data[0].id);
		} else {
			onCampaignMemberSelect(initialSelectedMemberId);
		}
	}, [initialSelectedMemberId]);

	/**
	 * Resets the campaign search list tab object when leaving the page
	 */
	useOnUnmount(() => {
		dispatch(resetCampaignSearchListTabInCampaign());
	});

	const dispatch = useDispatch();
	const isCampaignMemberOptionsVisible = useCanViewPermission(PermissionName.SearchList);

	// bind campaignId to the actions
	const updateSelectedMemberIdInCampaign = partial(updateSearchListSelectedMemberId, campaignId);
	const resetCampaignSearchListTabInCampaign = partial(resetOneCampaignSearchListTab, campaignId);

	// calls the action to update CampaignSearchListTab in the redux store
	function onCampaignMemberSelect(id: string | undefined) {
		dispatch(updateSelectedMemberIdInCampaign(id));
		onSearchListStatusChange?.(SEARCH_STATUSES.NOT_SEARCHING);
	}

	// Note: memoizing the selector prevents redundant calls to the selector
	const selectedMemberSelector = useMemo(
		() => campaignSearchListTabMemberSelector(campaignId),
		[campaignId]
	);
	const campaignMembersSelector = useMemo(() => selectCampaignMembers(campaignId), [campaignId]);
	const campaignMember: CampaignMemberModel | undefined = useSelector(selectedMemberSelector);
	const campaignMembers: ModelState<CampaignMemberModel> = useSelector(campaignMembersSelector);

	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);

	const campaignMemberOptions: DropdownOption[] | undefined = useMemo(() => {
		if (isCampaignMemberOptionsVisible) {
			return campaignMembers?.data.map((m) => ({ id: m.id, label: getName(m) }));
		} else return undefined;
	}, [isCampaignMemberOptionsVisible, campaignMembers?.data]);

	return {
		campaignMemberOptions,
		isCampaignMemberOptionsVisible,
		campaignMember,
		campaignMembers,
		onCampaignMemberSelect,
		isLoadingCampaignMembers:
			isCampaignMemberOptionsVisible === undefined || campaignMembers.loading,
	};
}
