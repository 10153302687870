import { IPermissionsState } from '../reducers/permissions';
import { PermissionActionTypes } from './types';

export namespace PermissionActions {
	export const setPermissions = (permissions: Partial<IPermissionsState>) => ({
		type: PermissionActionTypes.SetPermissions,
		payload: permissions,
	});
	export const resetPermissions = () => ({
		type: PermissionActionTypes.ResetPermissions,
	});
}
