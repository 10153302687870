import { ProxySelectors } from '@copilot/common/store/selectors/proxy';
import { useSelector } from 'react-redux';
import { createProxyLookup } from '../linkedin/helperMethods';
import { useMemo } from 'react';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { IProxy } from '@copilot/common/store/models/redux';

type UseRegionsProps = {
	userVpnPort?: number;
};

/**
 * Helper hook to manage regional proxies, allowing for random setting, lookups, etc
 * @param param0
 * @returns
 */
export function useRegionalProxies({ userVpnPort }: UseRegionsProps) {
	const proxyOptions = useSelector(ProxySelectors.getProxies);
	const regionMapping = createProxyLookup(proxyOptions);
	const hasProxySet = proxyOptions.some((proxy) => proxy.value === userVpnPort);

	/**
	 *  Get the current user's region based on their VPN port
	 */
	const currentUserRegion = useMemo(() => {
		return isNil(userVpnPort)
			? undefined
			: Object.keys(regionMapping).find((region) =>
					regionMapping[region].includes(userVpnPort)
			  );
	}, [userVpnPort, regionMapping]);

	/**
	 * Select a random proxy from a given region, or undefined if no region is undefined or invalid
	 * @param region
	 * @returns
	 */
	function selectProxyFromRegion(region: string | undefined) {
		if (isNil(region)) return undefined;

		const availableVPNPorts = regionMapping[region];
		if (isNil(availableVPNPorts) || isEmpty(availableVPNPorts)) {
			return undefined;
		}

		const selectedProxy: IProxy = {
			name: region,
			value: availableVPNPorts[Math.floor(Math.random() * availableVPNPorts.length)],
		};

		return selectedProxy;
	}

	return {
		currentUserRegion,
		selectProxyFromRegion,
		hasProxySet,
		regionOptions: Object.keys(regionMapping),
	};
}
