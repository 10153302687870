import Store from '..';
import { INotification } from '../models/redux';
import { createSelector } from 'redux-orm';

export namespace NotificationsSelectors {
	export const getNotificationByKey = (
		state: ReturnType<typeof Store['ReduxStore']['getState']>,
		key: string
	) => createSelector(Store.ORM, (session) => session.Notification.withId(key))(state);

	export const getNotifications = createSelector(
		Store.ORM,
		(session) => session.Notification.all().toRefArray() as INotification[]
	);
}
