import React from 'react';
import { TemplateManager } from '@copilot/data';
import { useDispatch } from 'react-redux';
import { MessageTemplateActions } from '@copilot/common/store/actions';

export const useLoadMessageTemplatesByOrg = (orgId: string) => {
	const storeDispatch = useDispatch();
	React.useEffect(() => {
		orgId &&
			TemplateManager.getTemplateMessagesByOrg(orgId).then((templates) => {
				storeDispatch(MessageTemplateActions.load(templates));
			});
	}, [orgId]);
};

export const useLoadMessageTemplatesByCampaign = (campaignId: string) => {
	const storeDispatch = useDispatch();
	React.useEffect(() => {
		campaignId &&
			TemplateManager.getTemplateMessagesByCampaign(campaignId).then((templates) => {
				storeDispatch(MessageTemplateActions.load(templates));
			});
	}, [campaignId]);
};
