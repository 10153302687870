import useTracking from '@copilot/common/hooks/tracking';

export type ConnectionsPageButtonClicks = 'Saved Templates' | 'Save Filter Template' | 'Export CSV';

interface ConnectionsPage {
	buttonClicked: ConnectionsPageButtonClicks;
}

export const useConnectionsPageTracking = (
	componentName: string,
	params: ConnectionsPage | null
): ((newParams: ConnectionsPage) => void) => useTracking(componentName, 'connections_page', params);
