import { BaseModel } from './base';
import { attr } from 'redux-orm';

export interface ICSMember {
	id: string;
	firstName: string;
	lastName: string;
	company: string;
	email: string;
}

export class CSMember extends BaseModel<typeof CSMember, ICSMember> {
	static modelName = 'CSMember';
	static fields = {
		id: attr(),
		firstName: attr(),
		lastName: attr(),
		company: attr(),
		email: attr(),
	};
}
