// Height Rules
const Height = {};
Height['@height-base'] = '32px';
Height['@height-lg'] = '40px';
Height['@height-sm'] = '24px';

const Drawer = {};
Drawer['@drawer-body-padding'] = '24px';

const Colors = {};

// Colors used in Figma

// COLOR
Colors['@PRIMARY-4'] = '#52ABFF';
Colors['@PRIMARY-6'] = '#0173FF';
Colors['@PRIMARY-8'] = '#0044B3';

// Neutral
Colors['@Neutral/9'] = '#434343';
Colors['@Neutral/10'] = '#FFFFFF';
Colors['@Neutral/20'] = '#F0F0F0';
Colors['@Neutral/30'] = '#C3CFE1';
Colors['@Neutral/50'] = '#8899B1';
Colors['@Neutral/70'] = '#515F74';
Colors['@Neutral/80'] = '#343f4f';
Colors['@Neutral/90'] = '#1B2535';

// Error
Colors['@Error/6'] = '#D7292D';
Colors['@Error/20'] = '#FFD6D6';
Colors['@Error/70'] = '#D83C40';

// Warning
Colors['@Warning/20'] = '#FFECC2';
Colors['@Warning/50'] = '#FFC547';
Colors['@Warning/70'] = '#E1A716';

// Success
Colors['@Success/6'] = '#37C337';
Colors['@Success/20'] = '#D4F0B3';
Colors['@Success/70'] = '#35AA35';

// Primary
Colors['@Primary/20'] = '#B9D8FF';
Colors['@Primary/60'] = '#0062D9';

// Polar Green
Colors['@PolarGreen/5'] = '#73D13D';

// Gray
Colors['@Gray'] = '#D9D9D9';
Colors['@Gray/2'] = '#FAFAFA';
Colors['@Gray/3'] = '#F5F5F5';
Colors['@Gray/7'] = '#8C8C8C';

// Red
Colors['@Red'] = '#F44336';

Colors['@success-color'] = '#52c41a';
Colors['@error-color'] = '#eb2f96';
Colors['@warning-color'] = Colors['@Warning/50'];
Colors['@info-color'] = '#1890ff';
Colors['@highlight-color'] = '#FFC32E';
Colors['@text-color'] = '#000000D9'; // fade(#000, 85%)
Colors['@text-color-secondary'] = '#00000073'; // fade(#000, 45%)
Colors['@disabled-color'] = '#B8B8B8';

// Outbound Links
Colors['@outbound-link-icon-color'] = '#0173FF';

// Others
Colors['@GeekBlue/1'] = '#F0F5FF';
Colors['@Lime/5'] = '#BAE637';

// layout
const Layout = {};
Layout['@layout-body-background'] = '#f0f2f5';
Layout['@layout-footer-background'] = Layout['@layout-body-background'];
Layout['@layout-header-background'] = '#001529';
Layout['@layout-header-base-background'] = Colors['@Neutral/90'];
Layout['@layout-header-base-background-v1'] = '#3fa3d2';
Layout['@layout-header-height'] = '50px';
Layout['@layout-header-padding'] = '0 50px';
Layout['@layout-footer-padding'] = '24px 50px';
Layout['@layout-sider-background'] = Layout['@layout-header-background'];
Layout['@layout-sider-width'] = '220px';
Layout['@layout-trigger-height'] = '48px';
Layout['@layout-trigger-background'] = '#002140';
Layout['@layout-trigger-color'] = '#fff';
Layout['@layout-zero-trigger-width'] = '36px';
Layout['@layout-zero-trigger-height'] = '42px';
// Layout light theme
Layout['@layout-sider-background-light'] = '#fff';
Layout['@layout-trigger-background-light'] = '#fff';
//Font Size
Layout['@layout-font-size-base'] = '16px';

Height['@outbound-link-icon-height'] = '18px';
Height['@prospect-info-box-icon-height'] = '16px';
Height['@prospect-info-header-icon-height'] = '32px';

const ScreenSizes = {};
// screen size breakpoints
ScreenSizes['@screen-xs'] = 480;
ScreenSizes['@screen-sm'] = 576;
ScreenSizes['@screen-md'] = 768;
ScreenSizes['@screen-lg'] = 992;
ScreenSizes['@screen-xl'] = 1200;
ScreenSizes['@screen-xxl'] = 1600;
// screen size min breakpoints
ScreenSizes['@screen-xs-min'] = `${ScreenSizes['@screen-xs']}px`;
ScreenSizes['@screen-sm-min'] = `${ScreenSizes['@screen-sm']}px`;
ScreenSizes['@screen-md-min'] = `${ScreenSizes['@screen-md']}px`;
ScreenSizes['@screen-lg-min'] = `${ScreenSizes['@screen-lg']}px`;
ScreenSizes['@screen-xl-min'] = `${ScreenSizes['@screen-xl']}px`;
ScreenSizes['@screen-xxl-min'] = `${ScreenSizes['@screen-xxl']}px`;
// screen size max breakpoints
ScreenSizes['@screen-xs-max'] = `${ScreenSizes['@screen-sm'] - 1}px`;
ScreenSizes['@screen-sm-max'] = `${ScreenSizes['@screen-md'] - 1}px`;
ScreenSizes['@screen-md-max'] = `${ScreenSizes['@screen-lg'] - 1}px`;
ScreenSizes['@screen-lg-max'] = `${ScreenSizes['@screen-xl'] - 1}px`;
ScreenSizes['@screen-xl-max'] = `${ScreenSizes['@screen-xxl'] - 1}px`;

// [Design System] Spacer Sizes
const Spacers = {};
// spacer numbers
Spacers['@spacer-num-xxs'] = 4;
Spacers['@spacer-num-xs'] = 8;
Spacers['@spacer-num-sm'] = 16;
Spacers['@spacer-num-md'] = 24;
Spacers['@spacer-num-lg'] = 32;
Spacers['@spacer-num-xl'] = 40;
Spacers['@spacer-num-xxl'] = 48;
// spacer strings
Spacers['@spacer-size-xxs'] = `${Spacers['@spacer-num-xxs']}px`;
Spacers['@spacer-size-xs'] = `${Spacers['@spacer-num-xs']}px`;
Spacers['@spacer-size-sm'] = `${Spacers['@spacer-num-sm']}px`;
Spacers['@spacer-size-md'] = `${Spacers['@spacer-num-md']}px`;
Spacers['@spacer-size-lg'] = `${Spacers['@spacer-num-lg']}px`;
Spacers['@spacer-size-xl'] = `${Spacers['@spacer-num-xl']}px`;
Spacers['@spacer-size-xxl'] = `${Spacers['@spacer-num-xxl']}px`;

const Charts = {
	'@connection-rate-chart-color': '#0173FF',
	'@reply-rate-chart-color': '#22BAB4',
	'@legends-icon-size': '20px',
	'@legends-font-size': '14px',
};

const Dropdowns = {
	'@dropdown-min-width': '200px',
};

module.exports = {
	'@ant-prefix': 'copilot',
	'@primary-color': '#0075e2',
	'@link-color': '#0075e2',
	'@card-head-padding': '12px',
	'@card-padding-base': '15px',
	'@card-tab-bg': '#ffffff',
	'@font-family': 'Basier Square',
	'@inverted-graph-color': '#FFC32E',
	'@font-size-base': '16px',
	'@layout-header-height': '50px',
	'@border-radius-base': 4,
	'@btn-group-border': '2px',
	'@menu-inline-toplevel-item-height': '35px',
	'@menu-item-height': '35px;',
	'@menu-dark-color': 'rgba(255, 255, 255, 0.95)',
	'@menu-dark-item-hover-bg': 'rgba(255, 255, 255, 0.2)',
	'@btn-danger-bg': 'red',
	'@tabs-card-gutter': 15,
	'@password-text-weight': 'bold',
	'@notification-display-color': '#005bbd',
	'@user-avatar-color': '#ffc32e',
	'@datetime-text-weight': 'bold',
	'@table-text-weight': 'normal',
	'@logo-width': undefined,
	'@popover-color': 'green',
	...Layout,
	...Height,
	...Drawer,
	...Colors,
	...ScreenSizes,
	...Spacers,
	...Charts,
	...Dropdowns,
};
