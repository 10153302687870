import { Checkbox, Col, Row } from 'antd';
import { ReactNode } from 'react';
import { Moment } from 'moment';
import DateDropdown from '../dateDropdown';
import { UNRESPOND_REMINDER_TOGGLE_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';

type ReminderSelectorProps = {
	/**
	 * Whether reminder is enabled
	 */
	isChecked: boolean;
	/**
	 * Callback on enable reminder
	 */
	onCheck: () => void;
	/**
	 * Callback on selecting date
	 */
	onSelectDate: (date: Moment) => void;
	/**
	 * Selectable date options
	 */
	dateOptions: { key: string; label: ReactNode; evaluator: (date: Moment) => Moment }[];
	/**
	 * Selected date to set reminder on
	 */
	selectedDate?: Moment;
	/**
	 * Whether the reminder selector is disabled
	 */
	disabled?: boolean;
};

/**
 * [Presentational] Selector for reminder
 */
export default function ReminderSelector({
	isChecked,
	onCheck,
	selectedDate,
	onSelectDate,
	dateOptions,
	disabled,
}: ReminderSelectorProps) {
	return (
		<>
			<Row gutter={16} align="middle">
				<Col>
					<Checkbox
						disabled={disabled}
						checked={isChecked}
						onClick={onCheck}
						data-tracking-id={UNRESPOND_REMINDER_TOGGLE_TRACKING_ID}
					>
						If no reply, remind me
					</Checkbox>
				</Col>
				<Col>
					<DateDropdown
						disabled={disabled}
						selectedDate={selectedDate}
						onSelectDate={onSelectDate}
						dateOptions={dateOptions}
					/>
				</Col>
			</Row>
		</>
	);
}
