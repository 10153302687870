import {
	capitalizeFirstLetter,
	convertToOrdinal,
	convertToOrdinalAbbr,
} from '@copilot/common/utils';

/**
 * Get the node name for an inmail sequence node
 * @param oneBasedIndex One based index of the node
 * @returns
 */
export function getInMailNodeName(oneBasedIndex: number): string {
	const abbr = convertToOrdinalAbbr(oneBasedIndex);
	const full = capitalizeFirstLetter(convertToOrdinal(oneBasedIndex));
	return `${full} InMail Message (${abbr} message)`;
}
