import { ConfigType } from '@copilot/data/config/types';
import BaseTheme from '@copilot/common/config/antd.theme';
import { ThemeConfig } from 'antd';
import { generate } from '@ant-design/colors';

export const Config: ConfigType & { gitHash: string } = {
	...window.runtimeConfig,
	gitHash: process.env.gitHash,
};

const primaryColor: string =
	window.themeOverride?.['@primary-color'] ?? BaseTheme['@primary-color'];
const primaryColorPalettes = generate(primaryColor);

export const Theme = {
	...BaseTheme,
	...primaryColorPalettes.reduce(
		(obj, color, index) => ({ ...obj, [`@PRIMARY-${index + 1}`]: color }),
		{}
	),
	...window.themeOverride,
};

export const AntdTheme: ThemeConfig = {
	token: {
		colorPrimary: Theme['@primary-color'],
		colorError: Theme['@error-color'],
		colorInfo: Theme['@info-color'],
		colorLink: Theme['@link-color'],
		fontFamily: Theme['@font-family'],
		borderRadius: Theme['@border-radius-base'],
	},
	components: {
		Layout: {
			headerBg: Theme['@layout-header-base-background'],
			headerPadding: `0 ${Theme['@spacer-size-sm']}`,
		},
		Menu: {
			itemHeight: Theme['@menu-item-height'],
			darkItemColor: Theme['@menu-dark-color'],
			darkItemHoverBg: Theme['@menu-dark-item-hover-bg'],
		},
		Popover: {},
		Tabs: {
			cardGutter: Theme['@tabs-card-gutter'],
		},
		Drawer: {
			paddingLG: Theme['@drawer-body-padding'],
		},
		Card: {
			padding: Theme['@card-padding-base'],
		},
		Slider: {
			railSize: 5,
			dotSize: 20,
		},
	},
};

/**
 * The theme used for account dashboard
 */
export const AccountDashboardTheme = {
	token: {
		colorPrimary: Theme['@primary-color'],
		colorError: Theme['@error-color'],
		colorInfo: Theme['@info-color'],
		colorLink: Theme['@link-color'],
		fontFamily: Theme['@font-family'],
		borderRadius: Theme['@border-radius-base'],
	},
	components: {
		Layout: {
			headerPadding: `0 ${Theme['@spacer-size-sm']}`,
			headerBg: Theme['@Gray/2'],
			headerColor: Theme['@Neutral/90'],
			bodyBg: Theme['@GeekBlue/1'],
		},
		Menu: {
			itemHeight: Theme['@menu-item-height'],
			darkItemColor: Theme['@menu-dark-color'],
			darkItemHoverBg: Theme['@menu-dark-item-hover-bg'],
		},
		Popover: {},
		Tabs: {
			cardGutter: Theme['@tabs-card-gutter'],
		},
		Drawer: {
			paddingLG: Theme['@drawer-body-padding'],
		},
		Card: {
			padding: Theme['@card-padding-base'],
		},
		Slider: {
			railSize: 5,
			dotSize: 20,
		},
		Table: {
			headerBg: 'white',
		},
	},
};
