import { call, takeLatest } from 'redux-saga/effects';
import {
	ShowNotificationAction,
	ShowNotificationActionType,
} from '@copilot/common/store/actionCreators/notification';
import { NotificationModel } from '@copilot/common/store/models/notification';
import notificationManager from '@copilot/common/utils/notificationManager';
import NotificationDescription from '@copilot/common/components/notificationDescription';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { notification as antdNotification } from 'antd';

const DEFAULT_NOTIFICATION_DURATION = 4.5;

const getNotificationObject = (config: NotificationModel, onButtonClick?: () => void) => {
	const handleButtonClick = () => {
		if (onButtonClick) onButtonClick();
		antdNotification.destroy(config.key ?? '');
	};
	return {
		key: config.key,
		duration: config.duration ?? DEFAULT_NOTIFICATION_DURATION,
		message: config.content.message,
		description: (
			<NotificationDescription
				descriptionText={config.content.description}
				buttonText={config.content.buttonLabel}
				buttonType={config.content.buttonType}
				onButtonClick={onButtonClick ? handleButtonClick : undefined}
			/>
		),
	};
};

const triggerNotification = (notification: NotificationModel, onButtonClick?: () => void) => {
	antdNotification.destroy(notification.key ?? '');
	switch (notification.type) {
		case 'SUCCESS':
			notificationManager.showSuccessNotification(
				getNotificationObject(notification, onButtonClick)
			);
			break;
		case 'ERROR':
			notificationManager.showErrorNotification(
				getNotificationObject(notification, onButtonClick)
			);
			break;
		default:
			UtilityFunctions.assertUnreachable(notification.type);
	}
};

function* showNotification(action: ShowNotificationAction) {
	yield call(triggerNotification, action.notification, action.onButtonClick);
}

export function* watchShowNotification() {
	yield takeLatest(ShowNotificationActionType, showNotification);
}
