import BaseDataManager from './base';
import { IMessageTemplate } from '@copilot/common/store/models/redux/messageTemplate';
import { MessageTemplateModel } from '../responses/models/messageTemplate';
import { MessageTemplateResponse, FilterTemplateResponse } from '../responses/interface';

class TemplateManager extends BaseDataManager {
	constructor() {
		super();

		// Bind methods to provide context when called from useFetch
		this.createTemplateMessage = this.createTemplateMessage.bind(this);
		this.editTemplateMessage = this.editTemplateMessage.bind(this);
	}

	//#region Message Template

	/**
	 *
	 * @param orgId org id
	 */
	getTemplateMessagesByOrg = (orgId: string) =>
		this.RequestManager.get<MessageTemplateResponse[]>(
			`${this.BACKEND_ROUTES.ORGANIZATION.Default}/${orgId}/templates`
		).then((response) => {
			const { data } = response;
			return data.map((d) => new MessageTemplateModel(d));
		});

	/**
	 *
	 * @param campaignId campaign id
	 */
	getTemplateMessagesByCampaign(campaignId: string) {
		return this.RequestManager.get<MessageTemplateResponse[]>(
			`${this.BACKEND_ROUTES.CAMPAIGN.Default}/${campaignId}/templates`
		).then((response) => {
			const { data } = response;
			return data.map((d) => new MessageTemplateModel(d));
		});
	}

	loadTemplateMessage(id: string) {
		return this.RequestManager.get<MessageTemplateResponse>(
			`${this.BACKEND_ROUTES.TEMPLATE.Default}/${id}`
		).then((response) => {
			const { data } = response;
			return new MessageTemplateModel(data);
		});
	}

	createTemplateMessage(messageTemplate: Partial<IMessageTemplate>) {
		return this.RequestManager.post<MessageTemplateResponse>(
			`${this.BACKEND_ROUTES.ORGANIZATION.Default}/${messageTemplate.organizationId}/templates`,
			messageTemplate
		).then((response) => {
			const { data } = response;
			return new MessageTemplateModel(data);
		});
	}

	editTemplateMessage(messageTemplate: Partial<IMessageTemplate>) {
		return this.RequestManager.put<MessageTemplateResponse>(
			`${this.BACKEND_ROUTES.ORGANIZATION.Default}/${messageTemplate.organizationId}/templates/${messageTemplate.id}`,
			messageTemplate
		).then((response) => {
			const { data } = response;
			return new MessageTemplateModel(data);
		});
	}

	deleteTemplateMessage(templateId: string) {
		return this.RequestManager.delete(
			`${this.BACKEND_ROUTES.TEMPLATE.Default}/${templateId}`
		).then((response) => {
			const { data } = response;
			return data;
		});
	}

	//#endregion Message Template

	//#region Filter Template

	/**
	 * Create a filter template
	 * @param {FilterTemplateResponse} object of filter template
	 */
	createTemplateFilter = (filterTemplate: Partial<FilterTemplateResponse>) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.FILTER_TEMPLATES.Default, 'create');
		return this.RequestManager.post<FilterTemplateResponse>(url, filterTemplate).then(
			(response) => {
				const { data } = response;
				return data;
			}
		);
	};

	/**
	 * Get a filter template by id
	 * @param {string} id Id of filter template
	 */
	getTemplateFilterById = (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.FILTER_TEMPLATES.Default, id);
		return this.RequestManager.get<FilterTemplateResponse>(url).then((r) => r.data);
	};

	/**
	 * Get all filter templates by ownerId
	 * @param {string} orgMemberId Id of orgMember we weant to get filter template from
	 */
	getTemplateFilterByOrgMemberId = (orgMemberId: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.FILTER_TEMPLATES.Default,
			'orgMember',
			orgMemberId,
			'filterTemplates'
		);
		return this.RequestManager.get<FilterTemplateResponse[]>(url).then((r) => {
			const { data } = r;
			const results: FilterTemplateResponse[] = data;
			return results;
		});
	};

	/**
	 * Delete filter template
	 * @param {string} id Id of filter template
	 */
	deleteTemplateFilter = (id: string) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.FILTER_TEMPLATES.Default, id);
		return this.RequestManager.delete(url).then(() => true);
	};

	//#endregion Filter Template
}

const instance = new TemplateManager();
export default instance;
