import { ColumnProps } from 'antd/lib/table';

import BaseColumn from './base';
import { LinkActionColumnDefinition } from '../../componentModels/columnDefinition';
import { CustomFieldActions } from '../../componentModels/columnTypeDefinition';

export class LinkActionColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];
	public sorter: ColumnProps<M>['sorter'];

	constructor(c: LinkActionColumnDefinition) {
		super(c);

		this.render = (text: string, record: M) => {
			let iconHtml = '';
			if (c.determineLinkDisplay) {
				const { properties = [], action } = c;
				const values = this.getValues(properties, record);
				if (action?.metaData?.type) {
					iconHtml = c.determineLinkDisplay(
						action.metaData.type as CustomFieldActions,
						values
					);
				}
			}

			return <span>{iconHtml}</span>;
		};
	}
}
