import { Permissions } from '@copilot/common/hooks/account/accountCreation/accountCreationTypes';

/**
 * Copy for the Main Contact tooltip in the Accounts Creation drawer
 */
export const PRIMARY_CONTACT_DESCRIPTION = `
	The primary account administrator has the ability to add new team members within the account.
`;

/**
 * Copy for the Company Information tooltip in the Accounts Creation drawer
 */
export const COMPANY_INFORMATION_TOOLTIP = `
	Choose a name that will be easily identifiable on your accounts
	dashboard (E.g. company name).
`;

/**
 * Error message to show when a required input is omitted
 */
export const ACCOUNT_CREATION_INPUT_ERROR_MESSAGE = 'Required';

/**
 * Map to fetch Account Dashboard permissions copy for a given permission level
 */
export const AccountDashboardPermissionsMap = {
	[Permissions.Admin]: ['Log in as account admin', 'Add new accounts'],
};
