import React from 'react';
import { Select } from 'antd';
import timezones from './timezones.json';

interface TimezoneSelectorProps {
	timezoneId?: string;
	onTimezoneChange: (timezoneId: string) => void;
	editable: boolean;
}
const TimezoneSelector: React.FC<TimezoneSelectorProps> = (props) => {
	const timezoneOptions = React.useMemo(
		() =>
			Object.entries(timezones).map(([utc, timezoneInUtc]) => (
				<Select.OptGroup label={utc} key={utc}>
					{Object.entries(timezoneInUtc).map(([name, id]) => (
						<Select.Option value={id} label={name} key={name}>
							{name}
						</Select.Option>
					))}
				</Select.OptGroup>
			)),
		[]
	);

	return (
		<>
			<h3>Timezone</h3>
			<Select
				showSearch
				value={props.timezoneId ?? ''}
				style={{ minWidth: '26em' }}
				optionLabelProp="label"
				optionFilterProp="label"
				onChange={props.onTimezoneChange}
				disabled={!props.editable}
				dropdownStyle={{ zIndex: 1100, position: 'relative' }}
				getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
			>
				{timezoneOptions}
			</Select>
		</>
	);
};
export default TimezoneSelector;
