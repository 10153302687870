// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aGUthej0gx3Vs2y0eqS6 {\n  padding: 0 16px;\n  height: 100%;\n}\n.UwMlwEPJ4yMLa5nPqq8u {\n  background: #f1f5fb;\n}\n.wyyihgHWY2Kej6hGYM4j {\n  padding: 16px 16px 0 16px;\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/componentModels/activities/conversationWrapper/conversationWrapper.module.less"],"names":[],"mappings":"AAEA;EACC,eAAA;EACA,YAAA;AADD;AAIA;EACC,mBAAA;AAFD;AAKA;EACC,yBAAA;EACA,kBAAA;AAHD","sourcesContent":["@import '../../../../../styles/common-variables.less';\n\n.contentWrapper {\n\tpadding: 0 @spacingMD;\n\theight: 100%;\n}\n\n.conversationWrapper {\n\tbackground: @lightBlue;\n}\n\n.contentFooter {\n\tpadding: @spacingMD @spacingMD 0 @spacingMD;\n\tposition: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": "aGUthej0gx3Vs2y0eqS6",
	"conversationWrapper": "UwMlwEPJ4yMLa5nPqq8u",
	"contentFooter": "wyyihgHWY2Kej6hGYM4j"
};
export default ___CSS_LOADER_EXPORT___;
