import { Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import React from 'react';
import { WarningInfoIcon } from '../icon';

interface IMenuOptionsProps<M extends { id: string; name: string }> {
	data: M[];
	onClick: (param: MenuInfo) => void;
	getValidationStatus: (item: M) => string;
	getDisplayIcon: (item: M) => JSX.Element;
	errorText: string;
	errorColor?: string;
}

const OptionsExist = <M extends { id: string; name: string }>(
	props: Omit<IMenuOptionsProps<M>, 'errorText'>
) => {
	const { onClick, data, getValidationStatus, getDisplayIcon, errorColor } = props;
	return (
		<Menu onClick={onClick} style={{ overflowY: 'auto', maxHeight: '200px' }}>
			{data.map((c) => {
				const warningMessage = getValidationStatus(c);
				return (
					<Menu.Item key={c.id} disabled={!!warningMessage}>
						{getDisplayIcon(c)}
						{c.name}{' '}
						{warningMessage && (
							<WarningInfoIcon errorMsg={warningMessage} color={errorColor} />
						)}
					</Menu.Item>
				);
			})}
		</Menu>
	);
};

const NoOptions: React.FC<{ errorText: string }> = (props) => {
	const { errorText } = props;
	return (
		<Menu>
			<Menu.Item disabled>{errorText}</Menu.Item>
		</Menu>
	);
};

/**
 * [Presentational] Menu and menu options for dropdown
 * @param data a list of menu rows
 * @param onClick called on clicking a menu item
 * @param getValidationStatus called to check whether a menu item is valid
 * @param getDisplayIcon called to get an icon for a menu item
 * @param errorColor an error color string for an invalid menu item
 * @param errorText a string to display when data is empty
 */
const MenuOptions = <M extends { id: string; name: string }>(props: IMenuOptionsProps<M>) => {
	const { onClick, data, getValidationStatus, getDisplayIcon, errorColor, errorText } = props;
	if (data.length > 0) {
		return (
			<OptionsExist<M>
				onClick={onClick}
				data={data}
				getValidationStatus={getValidationStatus}
				getDisplayIcon={getDisplayIcon}
				errorColor={errorColor}
			/>
		);
	} else {
		return <NoOptions errorText={errorText} />;
	}
};

export default MenuOptions;
