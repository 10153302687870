import { AnyAction, Reducer } from 'redux';
import { getLoadActionTypes } from '@copilot/common/store/actionCreators/list';
import {
	createBaseModelState,
	createBaseModelStateWithDirtyList,
	ModelState,
	ModelStateMap,
	ModelStateWithDirtyList,
} from '@copilot/common/store/models/fetch';
import { PaginatedResponse } from '@copilot/data/responses/interface';
import _ from 'lodash';
import { ReducerAction } from './app';

/**
 * Generic type of actions
 */
export type ActionType<T extends { id: string }> = {
	type: string;
	payload?: PaginatedResponse<T> | T | T[];
	removalId?: string;
	error?: any;
};

const qualifierExtractor = (action: AnyAction) => action.qualifier ?? 'default';
/**
 * Create Reducer for fetch
 * @param name State name
 */
const createFetchReducerInternal = <T extends { id: string }>(name: string) => {
	const types = getLoadActionTypes(name);
	const stateBase: ModelState<T> = createBaseModelState();

	// union payload with stateData. In case of intersection, take from value from payload
	const upsertListToState = (payload: T[], stateData?: T[]) => {
		const updatedData = _.unionBy(payload, stateData, (x) => x.id);
		return {
			...stateBase,
			...{
				data: updatedData,
				totalCount: updatedData.length,
			},
		};
	};

	return (state: ModelState<T> = stateBase, action: ActionType<T> = { type: '' }) => {
		switch (action?.type) {
			case types.loadAction:
				return {
					...state,
					...{
						loading: true,
					},
				};
			case types.loadListSuccessAction:
				if (action.payload && 'results' in action.payload) {
					return {
						...stateBase,
						...{
							data: action.payload?.results ?? [],
							totalCount: action.payload?.totalCount ?? 0,
						},
					};
				} else if (Array.isArray(action.payload)) {
					return {
						...stateBase,
						data: action.payload,
						totalCount: action.payload.length,
					};
				}
				return state;
			case types.loadOneSuccessAction: {
				if (
					action.payload &&
					!('results' in action.payload) &&
					!Array.isArray(action.payload)
				) {
					const { payload } = action;
					const index = state.data.findIndex((model) => model.id == payload.id);
					if (index > -1) {
						const newData = [
							...state.data.slice(0, index),
							action.payload,
							...state.data.slice(index + 1),
						];
						return {
							...stateBase,
							...{
								data: newData,
								totalCount: state.totalCount,
							},
						};
					} else {
						// this item doesn't exist.  simply add to the existing list.
						return {
							...stateBase,
							...{
								data: [...state.data, action.payload],
								totalCount: state.totalCount + 1,
							},
						};
					}
				}
				return state;
			}
			case types.upsertListSuccessAction:
				if (action.payload && 'results' in action.payload) {
					return upsertListToState(action.payload.results, state.data);
				} else if (Array.isArray(action.payload)) {
					return upsertListToState(action.payload, state.data);
				}
				return state;
			case types.deleteOneSuccessAction:
				if (action.removalId) {
					const { removalId } = action;
					if (state.data) {
						const index = state.data.findIndex((model) => model.id == removalId);
						if (index > -1) {
							const newData = [
								...state.data.slice(0, index),
								...state.data.slice(index + 1),
							];
							return {
								...stateBase,
								...{
									data: newData,
									totalCount: state.totalCount - 1,
								},
							};
						}
					}
				}
				return state;
			case types.loadOneErrorAction:
			case types.loadListErrorAction:
			case types.upsertListErrorAction:
			case types.deleteOneErrorAction:
				return {
					...stateBase,
					...{
						error: true,
					},
				};
			default:
				return state;
		}
	};
};

type ReducerType<T extends { id: string }> = Reducer<ModelState<T>, ActionType<T>>;
function withDirtyListSupport<T extends { id: string }>(name: string, reducer: ReducerType<T>) {
	const types = getLoadActionTypes(name);

	const stateBase: ModelStateWithDirtyList<T> = {
		state: createBaseModelState(),
		dirtyModelIds: [],
	};

	return (
		state: ModelStateWithDirtyList<T> = stateBase,
		action: ReducerAction = { type: '' }
	) => {
		switch (action?.type) {
			case types.addToDirtyListSuccessAction: {
				return {
					...state,
					dirtyModelIds: [
						...(action.dirtyModelIds ?? []),
						...(state.dirtyModelIds ?? []),
					],
				};
			}
			default: {
				const newState = reducer(state.state, action);
				if (newState !== state.state) {
					return {
						...state,
						state: newState,
					};
				}
				return state;
			}
		}
	};
}

export const createFetchReducer = <T extends { id: string }>(name: string) => {
	const baseReducer = createFetchReducerInternal<T>(name);
	const reducerWithDirtyListSupport = withDirtyListSupport(name, baseReducer);
	const stateBase = {};

	const reducer: Reducer<ModelStateMap<T>, ActionType<T>> | undefined = (
		state = stateBase,
		action = { type: '' }
	) => {
		const stateKey = qualifierExtractor(action);

		if (stateKey) {
			const modelStateWithDirtyList = state[stateKey] ?? createBaseModelStateWithDirtyList();
			const newModelStateWithDirtyList = reducerWithDirtyListSupport(
				modelStateWithDirtyList,
				action
			);
			if (newModelStateWithDirtyList !== modelStateWithDirtyList) {
				return {
					...state,
					[stateKey]: newModelStateWithDirtyList,
				};
			}
		}

		return state;
	};

	return { [name.toLowerCase()]: reducer };
};
