import { useCallback, useContext } from 'react';
import { Row, Col, Switch, Tooltip } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import InvitesEditorTiming from '@copilot/common/components/editors/connection/timing';
import { MultipleInvite } from '@copilot/data/responses/interface';
import { ThemeContext } from 'styled-components';
import { CAMPAIGN_DUPLICATE_PROSPECT_TOGGLE_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';

type MultipleInviteToggleProps = {
	toggleLabel: string;
	tooltipLabel: string;
	multipleInvite: MultipleInvite;
	disabled?: boolean;
	onUpdate: (multipleInvite: MultipleInvite) => void;
};

/**
 * [Presentational] Toggle for whether the user wants to collect duplicate prospects
 * @param {string} toggleLabel label for the toggle
 * @param {string} tooltipLabel label for the toggle tooltip
 * @param {MultipleInvite} multipleInvite multiple invite object
 * @param {function} onUpdate called when the multiple invite object is updated
 */
function MultipleInviteToggle({
	toggleLabel,
	tooltipLabel,
	multipleInvite,
	onUpdate,
	disabled,
}: MultipleInviteToggleProps) {
	const themeContext = useContext(ThemeContext);
	const handleUpdate = useCallback(
		(update: Partial<MultipleInvite>) => {
			onUpdate({ ...multipleInvite, ...update });
		},
		[onUpdate, multipleInvite]
	);

	return (
		<Row>
			<Col>
				<Row>
					<Col style={{ display: 'flex', alignItems: 'center' }}>
						<Switch
							checked={multipleInvite.multipleInvite}
							onChange={(checked) => handleUpdate({ multipleInvite: checked })}
							style={{ marginRight: '8px' }}
							disabled={disabled}
							data-tracking-id={CAMPAIGN_DUPLICATE_PROSPECT_TOGGLE_TRACKING_ID}
						/>
						{toggleLabel}
						<span style={{ marginLeft: '4px' }}>
							<Tooltip
								title={tooltipLabel}
								color="white"
								overlayInnerStyle={{ color: themeContext['@text-color'] }}
							>
								<QuestionCircleTwoTone />
							</Tooltip>
						</span>
					</Col>
				</Row>
				{multipleInvite.multipleInvite && (
					<>
						<Row style={{ padding: '10px 0' }}>
							<Col>
								Don't collect those who have been collected by the organization
								within the last:
							</Col>
						</Row>
						<Row>
							<Col>
								<InvitesEditorTiming
									disabled={disabled}
									period={multipleInvite.period}
									time={multipleInvite.time}
									onPeriodUpdate={(period) => handleUpdate({ period })}
									onTimeUpdate={(time) => handleUpdate({ time })}
								/>
							</Col>
						</Row>
					</>
				)}
			</Col>
		</Row>
	);
}

export default MultipleInviteToggle;
