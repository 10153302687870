import React, { useMemo, useState, useCallback } from 'react';
import { Col, Row } from 'antd';
import MessageEditor from '.';
import BubbleCheckbox from '../../forms/common/generics/bubblecheckbox';
import { OnboardMessage } from '@copilot/data/requests/models';
import { Template } from '@copilot/common/pages/settings/message/const';

interface IMessageEditorWithTemplatesProps extends React.ComponentProps<typeof MessageEditor> {
	templates: Template[];
	selectedTemplate: string;
	updateSelectedTemplate: (template: string) => void;
}

const MessageEditorWithTemplates: React.FC<IMessageEditorWithTemplatesProps> = (props) => {
	const { templates, selectedTemplate, updateSelectedTemplate, ...messageEditorProps } = props;
	const [unsavedMessage, setUnsavedMessage] = useState<OnboardMessage>(
		messageEditorProps.message
	);

	const handleTemplateSelect = useCallback(
		(templateLabel: string) => {
			const template = templates.find((t) => t.label === templateLabel);
			if (!template) return;
			updateSelectedTemplate(template.key);
			setUnsavedMessage({ ...messageEditorProps.message, text: template.value });
			template &&
				messageEditorProps.onUpdate &&
				messageEditorProps.onUpdate({
					...messageEditorProps.message,
					text: template.value,
				});
		},
		[templates, messageEditorProps.message, messageEditorProps.onUpdate, updateSelectedTemplate]
	);
	const templateElements = useMemo(
		() =>
			templates.map((t) => (
				<BubbleCheckbox
					handleCheckChildElement={handleTemplateSelect}
					isChecked={selectedTemplate === t.key}
					id={t.key}
					value={t.label}
					key={t.key}
					label={t.label}
				/>
			)),
		[templates, selectedTemplate, handleTemplateSelect]
	);

	return (
		<>
			<Row>
				<Col span={24}>{templateElements}</Col>
			</Row>
			<Row>
				<Col span={24}>
					<MessageEditor {...messageEditorProps} message={unsavedMessage} />
				</Col>
			</Row>
		</>
	);
};

export default MessageEditorWithTemplates;
