import Wizard from '@copilot/common/components/wizard';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import NewProductStep from '@copilot/cs/src/components/changeSubscription/newProductStep';
import ConfirmStep from '@copilot/cs/src/components/changeSubscription/confirmStep';
import {
	ICoupon,
	IPrice,
	IProduct,
	PricingDateMap,
	ProductStatusEnum,
} from '@copilot/data/responses/models/billing';
import { BillingEditWizardSteps, PricingSummaryContext } from './const';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationMemberManager } from '@copilot/data';
import { AdminManager } from '@copilot/data/managers/admin';
import {
	fetchOrgMemberAction,
	fetchBillingAction,
	fetchProductsAction,
	fetchCouponsAction,
} from '../data/saga';
import { updateSubscription } from '../data/actionCreators';
import { getInvoicePreview, fetchInvoicePreview } from '../data/reducers';
import {
	orgMemberOneSelector,
	billingOneSelector,
	productSelector,
	couponSelector,
} from '../data/selectors';
import drawerManager from '@copilot/common/utils/drawerManager';
import modalManager from '@copilot/common/utils/modalManager';
import styled from 'styled-components';

const StyledMaxBox = styled.div`
	display: block;
	// xl and larger only
	@media screen and (min-width: ${(props) => props.theme['@screen-xl-min']}) {
		width: calc(${(props) => props.theme['@screen-xl-min']} - 48px);
	}
`;

interface ChangeSubscriptionRouterParams {
	orgMemberId: string;
}

/**
 * [Smart] Page for change subscription wizard
 */
const ChangeSubscriptionWizard: React.FC = () => {
	const { orgMemberId } = useParams<ChangeSubscriptionRouterParams>();
	const history = useHistory();
	const dispatch = useDispatch();

	// Page State
	const [pricingId, setPricingId] = useState<string>();
	const [couponId, setCouponId] = useState<string>();
	const [updateNow, setUpdateNow] = useState<boolean>(false);
	const [farthestNode, setFarthestNode] = useState(BillingEditWizardSteps.Product);

	const orgMemberOne = useSelector(orgMemberOneSelector(orgMemberId));
	const billingInfo = useSelector(billingOneSelector(orgMemberOne?.stripeCustomerId ?? ''));
	const products = useSelector(productSelector);
	const coupons = useSelector(couponSelector);
	const invoicePreview = useSelector(getInvoicePreview);

	const originalPricingId = useMemo(
		() => billingInfo?.subscription.items[0].pricingId,
		[billingInfo]
	);

	const originalCouponId = useMemo(
		() => billingInfo?.subscription.discounts[0]?.couponId,
		[billingInfo]
	);

	const disableUpdateSubscription = useMemo(
		() => pricingId == originalPricingId && couponId == originalCouponId,
		[originalPricingId, originalCouponId, couponId, pricingId]
	);

	const fetchInvoicePreviewCallBack = (
		pricingIdString?: string,
		shouldUpdateNow?: boolean,
		couponIdString?: string
	) => {
		dispatch(
			fetchInvoicePreview(
				AdminManager.getInvoicePreview,
				{},
				billingInfo?.subscription.id,
				pricingIdString ?? billingInfo?.subscription,
				shouldUpdateNow,
				couponIdString
			)
		);
	};

	useEffect(() => {
		if (orgMemberOne?.stripeCustomerId) {
			dispatch(fetchProductsAction(AdminManager.getProducts, orgMemberOne.stripeCustomerId));
			dispatch(fetchCouponsAction(AdminManager.getCoupons, orgMemberOne.stripeCustomerId));
		}
	}, [orgMemberOne?.stripeCustomerId]);

	useEffect(() => {
		dispatch(fetchOrgMemberAction(OrganizationMemberManager.getMember, orgMemberId));
	}, [orgMemberId]);

	useEffect(() => {
		if (orgMemberOne?.stripeCustomerId) {
			dispatch(
				fetchBillingAction(
					AdminManager.getIndividualBillingInfo,
					orgMemberOne.stripeCustomerId
				)
			);
		}
	}, [orgMemberOne?.stripeCustomerId]);

	useEffect(() => {
		if (billingInfo) {
			setCouponId(billingInfo?.subscription.discounts[0]?.couponId);
			setPricingId(billingInfo?.subscription.items[0].pricingId);
		}
	}, [billingInfo]);

	/**
	 * Returns the pricing summary context according to pricingId from the list of products.
	 * @param pricingId Pricing id
	 * @returns PricingSummaryContext matching the pricing id
	 */
	const getPricingSummaryContextFromPricingId = (
		pricingIdString: string
	): PricingSummaryContext => {
		const product: IProduct | undefined = products.data.find((productData: IProduct) =>
			productData.prices.some((price: IPrice) => price.id === pricingIdString)
		);
		if (!product) {
			if (billingInfo) {
				return {
					productId: billingInfo.subscription.items[0].id,
					productName: billingInfo.subscription.items[0].productName,
					pricingInterval: billingInfo.subscription.items[0].price.interval,
					pricingIntervalCount: billingInfo.subscription.items[0].price.intervalCount,
					pricingName: billingInfo.subscription.items[0].price.name,
					amount: billingInfo.subscription.items[0].amount,
					formattedAmount: billingInfo.subscription.items[0].formattedAmount,
					formattedInterval: billingInfo.subscription.items[0].price.formattedInterval,
					productStatus: billingInfo.subscription.items[0].productStatus,
				};
			}
			throw new Error(
				'Product id could not be found in the list of products and billing info does not contain product information.'
			);
		}
		const price: IPrice = product.prices.find(
			(productPrice: IPrice) => productPrice.id === pricingIdString
		) as IPrice;
		return {
			productId: product.id,
			productName: product.name,
			pricingName: price.name,
			pricingInterval: price.interval,
			pricingIntervalCount: price.intervalCount,
			amount: price.amount,
			formattedAmount: price.formattedAmount,
			formattedInterval: price.formattedInterval,
			productStatus: ProductStatusEnum.Available,
		};
	};

	/**
	 * The selected pricing summary context to display in ConfirmStep.
	 */
	const selectedPricingSummaryContext = useMemo(
		() => (pricingId ? getPricingSummaryContextFromPricingId(pricingId) : undefined),
		[pricingId]
	);
	/**
	 * The original pricing summary context to display in ConfirmStep.
	 */
	const originalPricingSummaryContext = useMemo(
		() =>
			!!originalPricingId && products.data.length > 0
				? getPricingSummaryContextFromPricingId(originalPricingId)
				: undefined,
		[originalPricingId, products, billingInfo]
	);

	const isPriceIncrease: boolean | undefined = useMemo(() => {
		if (selectedPricingSummaryContext && originalPricingSummaryContext) {
			if (selectedPricingSummaryContext.amount !== originalPricingSummaryContext.amount) {
				return selectedPricingSummaryContext.amount > originalPricingSummaryContext.amount;
			}
		}
		return undefined;
	}, [selectedPricingSummaryContext, originalPricingSummaryContext]);

	const isTermIncrease: boolean | undefined = useMemo(() => {
		if (selectedPricingSummaryContext && originalPricingSummaryContext) {
			const newPricing =
				selectedPricingSummaryContext.pricingIntervalCount *
				PricingDateMap[selectedPricingSummaryContext.pricingInterval];
			const oldPricing =
				originalPricingSummaryContext.pricingIntervalCount *
				PricingDateMap[originalPricingSummaryContext.pricingInterval];
			if (newPricing !== oldPricing) {
				return newPricing > oldPricing;
			}
		}
		return undefined;
	}, [selectedPricingSummaryContext, originalPricingSummaryContext]);

	const selectedCoupon = useMemo(
		() => coupons.data.find((coupon: ICoupon) => coupon.id === couponId),
		[couponId, billingInfo]
	);

	const onCancelClicked = () => {
		modalManager.openChangesNotSavedModal({
			onConfirm: () => history.push(`/details/${orgMemberId}/billing`),
		});
	};

	const onComplete = () =>
		new Promise<void>((res, rej) => {
			if (billingInfo) {
				dispatch(
					updateSubscription(
						billingInfo.subscription.id,
						pricingId ?? billingInfo.subscription.items[0].pricingId,
						updateNow,
						couponId,
						{
							onSuccess: () => {
								history.push(`/details/${orgMemberId}/billing`);
								res();
							},
							onError: rej,
						}
					)
				);
			} else {
				rej();
			}
		});

	/* 		"modifications" - an argument of "onSave" props within Wizard component below -
		contains the value of the farthest node ("modifications.step") whenever
		"targetNode" is greater than "farthestNode". */
	return (
		<StyledMaxBox>
			<Wizard
				style={{ background: 'transparent' }}
				sidebarBreakpoint="md"
				sidebarWidth={250}
				farthestNode={farthestNode}
				onSave={(_, modifications) => {
					if (modifications) {
						const step: BillingEditWizardSteps = modifications.step;
						setFarthestNode(step);
					}
				}}
				disableUnreachedSteps
			>
				<Wizard.Step
					id={BillingEditWizardSteps.Product}
					title="Choose New Plan"
					description={
						<>
							<div>
								{selectedPricingSummaryContext?.productName ??
									originalPricingSummaryContext?.productName}
							</div>
							<div>
								{selectedPricingSummaryContext?.pricingName ??
									originalPricingSummaryContext?.pricingName}
							</div>
						</>
					}
					isDisabled={false}
				>
					<Wizard.Step.MainContent>
						<NewProductStep
							products={products.data}
							originalPricingId={originalPricingId}
							initialPricingId={pricingId}
							onPricingSelected={setPricingId}
							onCancelClicked={onCancelClicked}
							isLoading={
								products.loading ||
								!billingInfo ||
								!originalPricingSummaryContext ||
								!originalPricingId
							}
							originalPricingSummaryContext={originalPricingSummaryContext}
							onContinueClicked={() =>
								fetchInvoicePreviewCallBack(pricingId, updateNow, couponId)
							}
						/>
					</Wizard.Step.MainContent>
				</Wizard.Step>
				<Wizard.Step
					id={BillingEditWizardSteps.Confirm}
					title="Subscription Confirmation"
					isDisabled={false}
				>
					<Wizard.Step.MainContent>
						<ConfirmStep
							invoicePreview={invoicePreview.data}
							subscriptionId={billingInfo?.subscription.id}
							pricingSummaryContext={
								selectedPricingSummaryContext ?? originalPricingSummaryContext
							}
							nextBillingDate={billingInfo?.subscription.currentPeriodEndDate}
							selectedCoupon={selectedCoupon}
							updateNow={updateNow}
							trialToPaidEndDate={billingInfo?.subscription.trialEnd}
							updateNowToggled={(shouldUpdateNow: boolean) => {
								setUpdateNow(shouldUpdateNow);
								fetchInvoicePreviewCallBack(pricingId, shouldUpdateNow, couponId);
							}}
							openCouponDrawer={() => {
								drawerManager.openCouponDrawer({
									coupons: coupons.data,
									selectedCouponId: selectedCoupon?.id,
									onCouponSelect: (couponIdString: string) => {
										setCouponId(couponIdString);
										fetchInvoicePreviewCallBack(
											pricingId,
											updateNow,
											couponIdString
										);
									},
								});
							}}
							deleteCouponClicked={() => {
								setCouponId(undefined);
								fetchInvoicePreviewCallBack(pricingId, updateNow);
							}}
							continueClicked={onComplete}
							isLoading={
								coupons.loading || !originalPricingSummaryContext || !billingInfo
							}
							isLoadingInvoice={invoicePreview.loading}
							disableUpdateSubscription={disableUpdateSubscription}
							isPriceIncrease={isPriceIncrease}
							isTermIncrease={isTermIncrease}
						/>
					</Wizard.Step.MainContent>
				</Wizard.Step>
			</Wizard>
		</StyledMaxBox>
	);
};

export default ChangeSubscriptionWizard;
