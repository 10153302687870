import BaseDataManager from './base';
import { ServiceSchedule } from '../responses/interface';

class ScheduleManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Update campaign schedule
	 * @param {ServiceSchedule} schedule Part of the campaign schedule with the updated value
	 */
	public setSchedule = async (schedule: NonNullable<ServiceSchedule>) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.SCHEDULE.Default, schedule.id);

		const response = await this.RequestManager.put<ServiceSchedule>(url, schedule);
		if (response) {
			return response.data;
		} else {
			return Promise.reject();
		}
	};
}

const instance = new ScheduleManager();
export default instance;
