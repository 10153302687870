// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z7bBUZBegSOh47avjqwu {\n  padding: 8px;\n  background-color: #f5f5f5;\n  border-radius: 4px;\n}\n.Z7bBUZBegSOh47avjqwu h4,\n.Z7bBUZBegSOh47avjqwu ul {\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/drawer/wrappers/accountDashboard/listBox.module.less"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,yBAAA;EACA,kBAAA;AAFF;AADA;;EAMI,SAAA;AADJ","sourcesContent":["@import '../../../../../styles/antd-variables.less';\n@import '../../../../../styles/common-variables.less';\n\n.listBox {\n  padding: @spacer-size-xs;\n  background-color: @Gray-3;\n  border-radius: @border-radius-base;\n\n  h4 , ul {\n    margin: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listBox": "Z7bBUZBegSOh47avjqwu"
};
export default ___CSS_LOADER_EXPORT___;
