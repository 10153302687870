import { useFetch } from '../../common';
import { CampaignMemberManager } from '@copilot/data';
import { CampaignMemberActions } from '@copilot/common/store/actions/campaignMember';
import { LinkedInCampaignMemberResponse } from '@copilot/data/responses/interface';
import { ICampaignMember } from '@copilot/common/store/models/redux';

const transformLinkedInInformation = (
	member: LinkedInCampaignMemberResponse
): Partial<ICampaignMember> => ({
	id: member.campaignMemberId,
	searchUrl: member.searchUrl,
	lastSearchUrlUpdate: member.lastSearchUrlUpdate,
	dailyInvites: member.dailyInvites,
	dailyMessages: member.dailyMessages,
	dailyOpenInMailLimit: member.dailyOpenInMailLimit,
	openInMailEnabled: member.openInMailEnabled,
	linkedInProfile: member.linkedInProfile as any,
	multipleInvite: member.multipleInvite,
});

export const useCampaignMemberLinkedInfoFetch = () =>
	useFetch(
		CampaignMemberManager.getCampaignMemberLinkedInInformation,
		CampaignMemberActions.loadCampaignMember,
		transformLinkedInInformation
	);

export const useUpdateLinkedInInfoFetch = () =>
	useFetch(CampaignMemberManager.updateCampaignMemberLinkedInInformation);
