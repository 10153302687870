import * as React from 'react';
import { Checkbox } from 'antd';
import { ContactConnectionsTableColumns } from '../../tables/tableTypes/contact/column';

interface IdOptionProps {
	model: any;
	selected: boolean;
	columnKey: string;
}
const IdOption: React.FC<IdOptionProps> = (props) => {
	const selectedModel = React.useMemo(() => {
		if (props.columnKey === ContactConnectionsTableColumns.ConnectedMember) {
			return `${props.model.firstName} ${props.model.lastName}`;
		} else if (props.columnKey === ContactConnectionsTableColumns.Campaign) {
			return props.model.name;
		}
		return undefined;
	}, [props.columnKey]);

	return (
		<Checkbox checked={props.selected}>
			<span>{selectedModel}</span>
		</Checkbox>
	);
};

export default IdOption;
