import { Fragment, useEffect } from 'react';
import { Alert, Card, Divider, Flex, Skeleton, Space, Typography } from 'antd';
import { MessageEditorPanel } from './messageEditorPanel';
import { InMailSequenceHookData } from '../data/inmail';
import {
	INMAIL_COMPLIANCE_MESSAGE,
	INMAIL_DEFAULT_INITIAL_NODE,
	INMAIL_ENABLE_INSTRUCTIONS,
	INMAIL_INITIAL_NODE_ID,
} from './constants';
import { getInMailNodeName } from './utils';
import { isNil } from 'lodash';
import { InMailSequenceMessage } from '@copilot/data/requests/models';
import notificationManager from '@copilot/common/utils/notificationManager';
import styles from './inmailSequenceTab.module.less';
import Title from '@copilot/common/typography/title';

type InMailSequenceTabProps = Readonly<{
	/**
	 * The campaign id of the sequence
	 */
	campaignId: string;
	/**
	 * Whether the alert is shown for enabling open inmail
	 */
	showEnableAlert?: boolean;
	/**
	 * Whether the sequence is readonly
	 */
	readonly?: boolean;

	inMailSequenceHookData: InMailSequenceHookData;
}>;

/**
 * InMail sequence tab
 * @param param0
 * @returns
 */
export default function InMailSequenceTab({
	campaignId,
	showEnableAlert,
	readonly = false,
	inMailSequenceHookData,
}: InMailSequenceTabProps) {
	const {
		data,
		fetchState: { isFetching },
		fetchSequence,
		updateState: { isFetching: isUpdating },
		updateSequence,
	} = inMailSequenceHookData;

	const isEmptyData = isNil(data) || data.length === 0;
	const displayedNodes = isEmptyData ? [INMAIL_DEFAULT_INITIAL_NODE] : data;

	useEffect(() => {
		fetchSequence(campaignId);
	}, [campaignId]);

	/**
	 * Save the sequence for the loaded campaign.
	 * @param sequence The sequence to save
	 */
	async function saveSequence(sequence: InMailSequenceMessage[]) {
		try {
			await updateSequence(campaignId, sequence);
			notificationManager.showSuccessNotification({ message: 'Sequence saved' });
		} catch {
			notificationManager.showErrorNotification({ message: 'Failed to save sequence' });
		}
	}

	/**
	 * Update a sequence node
	 * @param index The index in the sequence of the node to update
	 * @param payload The payload to update the node with
	 */
	function updateSequenceNode(
		index: number,
		{ subject, message }: { message: string; subject: string }
	) {
		if (isNil(displayedNodes))
			saveSequence([
				{
					...INMAIL_DEFAULT_INITIAL_NODE,
					subject,
					text: message,
				},
			]);
		else {
			const newSequence: InMailSequenceMessage[] = [
				...displayedNodes.slice(0, index),
				{ ...displayedNodes[index], subject, text: message },
				...displayedNodes.slice(index + 1),
			];
			saveSequence(newSequence);
		}
	}

	/**
	 * Delete a sequence node
	 * @param idx The index in the sequence of the node to delete
	 */
	function deleteNode(idx: number) {
		updateSequence(campaignId, [
			...displayedNodes.slice(0, idx),
			...displayedNodes.slice(idx + 1),
		]);
	}

	return (
		<Card className={styles.container}>
			<Space size="middle" direction="vertical">
				<Title level={4}>
					InMails are sent directly to a person's inbox. Your lead can still look up your
					profile and company through it, which increases trust.
				</Title>
				{showEnableAlert ? <Alert message={INMAIL_ENABLE_INSTRUCTIONS} showIcon /> : null}
				<Alert message={INMAIL_COMPLIANCE_MESSAGE} type="warning" />
				<Skeleton active={isFetching} loading={isFetching}>
					<Flex gap={'middle'} className={styles.editor}>
						<div className={styles.messages}>
							{displayedNodes.map((node, idx) => {
								const oneBasedIndex = idx + 1;
								const isLast = oneBasedIndex < displayedNodes.length;
								const nodeName = getInMailNodeName(oneBasedIndex);
								return (
									<Fragment
										key={`${node.nodeId}-${node.text}-${node.subject}-${idx}`}
									>
										<MessageEditorPanel
											initialMessage={node.text}
											initialSubject={node.subject}
											nodeName={nodeName}
											nodeId={node.nodeId}
											isSaving={isUpdating}
											isDeletable={node.nodeId !== INMAIL_INITIAL_NODE_ID}
											onDelete={() => deleteNode(idx)}
											initialIsEditing={!isFetching && isEmptyData}
											onSave={(payload) => updateSequenceNode(idx, payload)}
											readonly={!readonly}
										/>
										{isLast ? <Divider /> : null}
									</Fragment>
								);
							})}
						</div>
						<Divider type={'vertical'} className={styles.divider} />
						<Space className={styles.guideline} direction={'vertical'} size={'middle'}>
							<Typography.Text strong>Increase Response Rate</Typography.Text>
							<Typography.Text>
								The character limit is shorter than LinkedIn's to account for email
								signatures. We recommend keeping messages under 200 characters for a
								22% better response rate.
							</Typography.Text>
							<Typography.Text>
								<strong>Introduction:</strong>
								<ul>
									<li>
										Clarify who you are - name, current role, and your
										professional background.
									</li>
									<li>
										Share the context of the connection, such as mutual
										connections or shared interests, as you would in your second
										message of the automated messaging sequence.
									</li>
								</ul>
							</Typography.Text>
							<Typography.Text>
								<strong>Body of message:</strong>
								<ul>
									<li>State your intention of why you are reaching out</li>
									<li>
										Make a clear request: Specify what you are seeking from the
										recipient or what you can offer.
									</li>
								</ul>
							</Typography.Text>
							<Typography.Text>
								<strong>Wrap up:</strong>
								<ul>
									<li>
										Direct request: End with a clear call to action (e.g. offer
										more information, ask to book a meeting, etc).
									</li>
								</ul>
							</Typography.Text>
						</Space>
					</Flex>
				</Skeleton>
			</Space>
		</Card>
	);
}
