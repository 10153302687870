import * as React from 'react';
import { Checkbox } from 'antd';
import { ITag } from '@copilot/common/store/models/redux';

interface TagOptionProps {
	tag: ITag;
	selected: boolean;
}

const TagOption: React.FC<TagOptionProps> = (props) => (
	<Checkbox checked={props.selected}>
		<span>{props.tag.name}</span>
	</Checkbox>
);

export default TagOption;
