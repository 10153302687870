import { CampaignMemberManager } from '@copilot/data';
import { ConnectionStatusType } from '@copilot/common/utils/constant';
import { useEffect, useState } from 'react';
import { isNil } from 'lodash';

/**
 * Hook for getter number of connection in search list
 * @param campaignId
 * @param campaignMemberId
 * @param orgMemberId
 */
export function useSearchListCount(
	campaignId: string,
	campaignMemberId?: string,
	orgMemberId?: string
): [
	{
		counts: {
			prospect?: number;
			openProfile?: number;
		};
	},
	() => Promise<void>
] {
	const [counts, setCounts] = useState<{ prospect?: number; openProfile?: number }>({});

	async function fetchFunc() {
		if (isNil(campaignMemberId)) return;

		const { totalCount: openProfileCount } =
			await CampaignMemberManager.getSearchListResultsByMember({
				campaignId,
				campaignMemberId: campaignMemberId,
				connectionStatus: ConnectionStatusType.Stranger,
				orgMemberId,
				isOpenProfile: true,
			});
		const { totalCount: prospectCount } =
			await CampaignMemberManager.getSearchListResultsByMember({
				campaignId,
				campaignMemberId: campaignMemberId,
				connectionStatus: ConnectionStatusType.Stranger,
				orgMemberId,
				isOpenProfile: false,
			});
		setCounts({ prospect: prospectCount, openProfile: openProfileCount });
	}

	useEffect(() => {
		fetchFunc();
	}, [campaignId, campaignMemberId, orgMemberId]);

	return [{ counts }, fetchFunc];
}
