import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import StatsLoader from '@copilot/common/components/statistics/loaders/base';
import StatisticsSummary from '@copilot/common/components/statistics/summary';
import { StatsManager, OrganizationManager } from '@copilot/data';
import { useStatOptions } from '@copilot/common/hooks/statistics';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { useFetch } from '@copilot/common/hooks/common';
import { OrganizationActions } from '@copilot/common/store/actions/organization';
import CampaignCollapseList from './campaignCollapse';
import BaseStatisticsSummary from '@copilot/common/components/statistics/summary/base';
import AreaGraph from '@copilot/common/components/charts/simple/area';
import withPercentageCharts from '@copilot/common/components/statistics/summary/withPercentageCharts';
import {
	CampaignPercentageStatsWithReplyOverNurture,
	OrganizationPercentageStats,
	PercentageTypeExtract,
} from '@copilot/common/components/statistics/type';
import { StatsUtilityFunctions } from '@copilot/common/utils/common/stats';
import { LinkedInCalculatedStatsType } from '@copilot/common/components/componentModels/stat';
import { LinkedInStatLabelMap } from '@copilot/common/constant/enum';
import AttentionRequiredCard from '@copilot/common/components/card/attentionRequiredCard';
import ContentWithSiderLayout from '@copilot/common/pages/layouts/contentWithSider';
import { attentionItemsSelector } from '@copilot/common/utils/attentionItem/selector';
import {
	useAttentionItemConverter,
	useAttentionRequiredHelpClick,
} from '@copilot/common/hooks/attentionItem';
import { PermissionName } from '@copilot/common/hooks/permission/interface';
import { useCanViewPermission } from '@copilot/common/hooks/permission';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { arrayToCustomMap } from '@copilot/common/utils';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useAttentionItemButtonClickTracking } from '@copilot/common/utils/attentionItem/tracking';
import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';

const loader = async (id: string, start?: Date, end?: Date) => {
	const data = await StatsManager.getStatsForOrganization(id, start, end);
	return { [id]: data };
};

/**
 * Consolidate percentage stats across selected campaigns for organization dashboard
 * @param campaignRatios percentage stats values by campaign
 * @param campaignFilters selected state of campaigns
 */
const getPercentageStats: PercentageTypeExtract = (
	campaignRatios: TypedObject<CampaignPercentageStatsWithReplyOverNurture>,
	campaignFilters: TypedObject<boolean>
) =>
	Object.keys(campaignRatios).reduce<OrganizationPercentageStats>(
		(acc, campaign) => {
			if (campaignFilters[campaign]) {
				Object.keys(acc).forEach((label) => {
					acc[label as keyof typeof acc] = StatsUtilityFunctions.combinePercentageStats(
						acc[label as keyof typeof acc],
						campaignRatios[campaign][label as keyof OrganizationPercentageStats]
					);
				});
			}
			return acc;
		},
		{
			[LinkedInCalculatedStatsType.ConnectionsOverInvites]: [
				{ name: LinkedInStatLabelMap.LinkedInConnected, value: 0 },
				{ name: LinkedInStatLabelMap.LinkedInInvite, value: 0 },
			],
			[LinkedInCalculatedStatsType.ReplyOverConnections]: [
				{ name: LinkedInStatLabelMap.UniqueReplies, value: 0 },
				{ name: LinkedInStatLabelMap.LinkedInConnected, value: 0 },
			],
			[LinkedInCalculatedStatsType.InterestedOverReply]: [
				{ name: LinkedInStatLabelMap.ReplyInterested, value: 0 },
				{ name: LinkedInStatLabelMap.UniqueReplies, value: 0 },
			],
			[LinkedInCalculatedStatsType.ReplyOverInvites]: [
				{ name: LinkedInStatLabelMap.UniqueReplies, value: 0 },
				{ name: 'Invites Sent', value: 0 },
			],
			[LinkedInCalculatedStatsType.NurtureReplyOverNurtureSent]: [
				{ name: LinkedInStatLabelMap.NurtureUniqueReplies, value: 0 },
				{ name: LinkedInStatLabelMap.NurtureSendMessage, value: 0 },
			],
		}
	);

const OrganizationStatsModule = StatsLoader(
	StatisticsSummary(withPercentageCharts(BaseStatisticsSummary, getPercentageStats), AreaGraph),
	loader
);

interface OrganizationDashboardSummaryProps {
	organizationId: string;
}

const OrganizationDashboardSummary: React.FC<OrganizationDashboardSummaryProps> = (
	props: OrganizationDashboardSummaryProps
) => {
	const { organizationId = '' } = props;
	const isTeamMembersVisible = useCanViewPermission(PermissionName.TeamMembers);
	const organization = useSelector(OrganizationSelectors.getOrganization(organizationId));
	const isAdmin = !!useSelector(OrganizationMemberSelectors.getAdminMember);
	const campaigns = useSelector((state) =>
		CampaignSelectors.getCampaignsByOrgId(state, props.organizationId)
	);
	const statOptionsFetcher = useFetch(StatsManager.getOrgStatFilters);
	const organizationUpdater = useFetch(
		OrganizationManager.updateOrganization,
		OrganizationActions.loadOrganization
	);

	const campaignIdNameMap = useMemo(
		() =>
			arrayToCustomMap(
				campaigns,
				(c) => c.id,
				(c) => c.name
			),
		[campaigns]
	);
	const updateAttentionItemTracking = useAttentionItemButtonClickTracking(
		'Organization Hub Summary',
		OrgRoleTitles.Admin
	);
	const onAttentionRequiredHelpClick = useAttentionRequiredHelpClick(updateAttentionItemTracking);
	const convertOrgMemberAttentionItem = useAttentionItemConverter(
		isTeamMembersVisible,
		!isAdmin,
		campaignIdNameMap,
		updateAttentionItemTracking
	);

	const enabledStats = React.useMemo(
		() => ({ actionStats: organization?.actionStats, tagStats: organization?.tagStats }),
		[organization]
	);

	const [statOptions, updateFilterList] = useStatOptions(
		organizationId,
		enabledStats,
		statOptionsFetcher,
		organizationUpdater
	);

	const attentionItems = useSelector(attentionItemsSelector);
	const attentionItemsByMember = useMemo(
		() => attentionItems?.data?.map((member) => convertOrgMemberAttentionItem(member)) ?? [],
		[convertOrgMemberAttentionItem, attentionItems?.data]
	);

	return (
		<>
			<ContentWithSiderLayout>
				<ContentWithSiderLayout.Main>
					<OrganizationStatsModule
						id={organizationId}
						title="Statistics Summary"
						statsOptions={statOptions}
						selectorUpdateCallback={(stats) => {
							updateFilterList(stats);
						}}
						showLegends={false}
					/>
				</ContentWithSiderLayout.Main>
				{!!attentionItemsByMember.length && (
					<ContentWithSiderLayout.Sider>
						<AttentionRequiredCard
							attentionItemsByMember={attentionItemsByMember}
							isMemberNameShown
							onHelpButtonClick={onAttentionRequiredHelpClick}
						/>
					</ContentWithSiderLayout.Sider>
				)}
			</ContentWithSiderLayout>
			<CampaignCollapseList campaigns={campaigns} />
		</>
	);
};

export default OrganizationDashboardSummary;
