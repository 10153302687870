import { EntitiesActionTypes } from './types';
import { Proxy, IProxy } from '../models/redux';

const itemType = Proxy.modelName;
export namespace ProxyActions {
	export const loadProxies = (proxys: IProxy[]) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: proxys,
	});
}
