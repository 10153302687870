import { CSSProperties, ReactNode } from 'react';
import { getChildByType } from '@copilot/common/utils/common';

type SubComponentProps = {
	children: ReactNode;
};

/**
 * Renders the content of a subcomponent.
 *
 * @param {Object} children - The content to be rendered.
 * @returns {Object} - The rendered content.
 */
function Content({ children }: SubComponentProps) {
	return <>{children}</>;
}

/**
 * Renders a footer component.
 *
 * @param {ReactNode} children - The child elements to be rendered inside the footer component.
 * @returns {JSX.Element} - The rendered footer component.
 */
function Footer({ children }: SubComponentProps) {
	return <>{children}</>;
}

type LayoutProps = {
	children: Array<ReactNode>;
	contentStyles?: CSSProperties;
	contentClassName?: string;
};

/**
 * Renders a layout component with a content area and a footer area.
 * The content and footer components can be provided as children.
 *
 * @param {LayoutProps} props - The properties for the layout component.
 * @returns {JSX.Element} - The rendered layout component.
 */
function Layout({ children, contentClassName }: LayoutProps): JSX.Element {
	const ContentComponent = getChildByType(children, Content);
	const FooterComponent = getChildByType(children, Footer);
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				minHeight: '100%',
				maxHeight: '100%',
			}}
		>
			<div
				style={{
					flexGrow: 1,
					width: '100%',
					flexShrink: 1,
					overflow: 'hidden auto',
				}}
				className={contentClassName}
			>
				{ContentComponent}
			</div>
			<div style={{ width: '100%', flexShrink: 0 }}>{FooterComponent}</div>
		</div>
	);
}

Layout.Content = Content;
Layout.Footer = Footer;
export default Layout;
