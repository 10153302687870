import React, { useState } from 'react';
import { IProduct, IPrice } from '@copilot/data/responses/models/billing';
import ItemSelectionCard, {
	DropdownOption,
} from '@copilot/common/components/card/itemSelectionCard';

/**
 * Properties to display product card
 */
interface ProductCardProps {
	/**
	 * The product to display on the product card.
	 */
	product: IProduct;
	/**
	 * The original pricing id to match against product.
	 */
	originalPricingId: string;
	/**
	 * The selected pricing id to match against product.
	 */
	selectedPricingId?: string;
	/**
	 * Emits the pricing id when its selected.
	 */
	selectedClicked: (pricingId: string) => void;
}

/**
 * [Smart] component for product card
 */
const ProductCard: React.FC<ProductCardProps> = ({
	product,
	originalPricingId,
	selectedPricingId,
	selectedClicked,
}) => {
	const originalPricing: IPrice | undefined = product.prices.find(
		(pricing: IPrice) => pricing.id === originalPricingId
	);

	const selectedPricing: IPrice | undefined = product.prices.find(
		(pricing: IPrice) => pricing.id === selectedPricingId
	);

	const defaultSelectedDropdownPricingId: string | undefined =
		selectedPricing && selectedPricingId
			? selectedPricingId
			: originalPricing
			? originalPricingId
			: product.prices[0]?.id;

	const [selectedDropdownPricingId, setSelectedDropdownProductId] = useState(
		defaultSelectedDropdownPricingId
	);

	const selectedDropdownPricing: IPrice | undefined = product.prices.find(
		(pricing: IPrice) => pricing.id === selectedDropdownPricingId
	);

	const isSelectedDropdownCurrentPricing = selectedDropdownPricingId === originalPricingId;

	const isSelectedDropdownSelectedPricing = selectedDropdownPricingId === selectedPricingId;

	const isSelectedPricingNotCurrent =
		isSelectedDropdownSelectedPricing && !isSelectedDropdownCurrentPricing;

	const onDropdownItemSelected = (value: string | undefined) => {
		if (value !== undefined) setSelectedDropdownProductId(value);
	};

	const onSelect = () => {
		if (selectedDropdownPricingId) {
			selectedClicked(selectedDropdownPricingId);
		}
	};

	const showRibbon =
		(isSelectedDropdownCurrentPricing && !isSelectedDropdownSelectedPricing) ||
		isSelectedDropdownSelectedPricing;

	const ribbonText = isSelectedPricingNotCurrent ? 'Plan Selected' : 'Current Plan';

	const hideSelectButton =
		product.prices.length === 0 ||
		isSelectedDropdownCurrentPricing ||
		isSelectedDropdownSelectedPricing;

	const activePriceOptions = product.prices.filter(
		(price) => price.id === originalPricingId || price.isActive
	);

	const dropdownOptions: DropdownOption[] = activePriceOptions.map<DropdownOption>((price) => ({
		id: price.id,
		name: price.isActive ? price.name : `${price.name} (Legacy)`,
		disabled: !price.isActive,
	}));

	return (
		<ItemSelectionCard
			title={product.name}
			primaryText={selectedDropdownPricing?.formattedAmount ?? '-'}
			secondaryText={selectedDropdownPricing?.name ?? 'No Pricing Available'}
			dropdownOptions={dropdownOptions}
			selectedDropdownItemId={selectedDropdownPricingId}
			hideSelectButton={hideSelectButton}
			showRibbonAndBorder={showRibbon}
			ribbonText={ribbonText}
			colorScheme={isSelectedPricingNotCurrent ? 'green' : 'blue'}
			dropdownSelectionChanged={onDropdownItemSelected}
			selectedClicked={onSelect}
		/>
	);
};

export default ProductCard;
