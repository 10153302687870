export enum ScheduleHierarchyType {
	System,
	Organization,
	OrganizationMember,
	Campaign,
	CampaignMember,
}

export const SYSTEM_DEFAULT_SCHEDULE = {
	hierarchyType: ScheduleHierarchyType.System,
	synchronization: true,
	timezoneCode: 'Eastern Standard Time',
	weeklySchedule: [
		{ start: 540, end: 1080, enable: false },
		{ start: 540, end: 1080, enable: true },
		{ start: 540, end: 1080, enable: true },
		{ start: 540, end: 1080, enable: true },
		{ start: 540, end: 1080, enable: true },
		{ start: 540, end: 1080, enable: true },
		{ start: 540, end: 1080, enable: false },
	],
};
