import { Reducer } from 'redux';
import { PermissionActionTypes } from '../actions/types';
import { ReducerAction } from './app';

export type IPermissionsState = Record<string, number>;
const initialState: IPermissionsState = {};

export const PermissionsReducer: Reducer<IPermissionsState> = (
	state: IPermissionsState = initialState,
	action: ReducerAction = { type: '' }
): IPermissionsState => {
	switch (action.type) {
		case PermissionActionTypes.SetPermissions:
			return { ...state, ...action.payload };
		case PermissionActionTypes.ResetPermissions:
			return { ...initialState };
	}
	return state;
};
