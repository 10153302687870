import { Spin } from 'antd';
import React, { ComponentProps, PropsWithChildren } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './spinner.module.less';

interface ILoadingIndicatorProps {
	/** Indicate whether the children is in a loading state */
	isLoading: boolean;
	/** Text to display under the spinner*/
	tip?: string;
	/**
	 * Whether to display the spinner in full screen
	 * @default false
	 */
	isFullScreen?: boolean;
}

/**
 * Spinner for displaying loading state of a page or session
 * @param {boolean} isLoading whether or not to display the spinner
 */
const LoadingIndicator: React.FC<
	PropsWithChildren<ILoadingIndicatorProps> & ComponentProps<typeof Spin>
> = (props) => {
	const { isLoading, children, tip = 'Loading...', isFullScreen = false, ...rest } = props;
	return (
		<Spin
			tip={tip}
			{...rest}
			className={isFullScreen ? styles.centeredSpin : undefined}
			spinning={isLoading}
			indicator={<LoadingOutlined />}
		>
			{children}
		</Spin>
	);
};

export default LoadingIndicator;
