// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rwDX8lB_pTkOAJsl2aSM {\n  display: flex;\n  justify-content: space-between;\n  font-size: 12px;\n  margin-bottom: 5px;\n}\n.czwzLWcLVSnmoh9lyFvi {\n  display: flex;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/componentModels/activities/scheduledMessages/scheduledMessages.module.less"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,8BAAA;EACA,eAAA;EACA,kBAAA;AACD;AAEA;EACC,aAAA;EACA,8BAAA;AAAD","sourcesContent":[".scheduledMessageHeader {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tfont-size: 12px;\n\tmargin-bottom: 5px;\n}\n\n.scheduledMessageBody {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scheduledMessageHeader": "rwDX8lB_pTkOAJsl2aSM",
	"scheduledMessageBody": "czwzLWcLVSnmoh9lyFvi"
};
export default ___CSS_LOADER_EXPORT___;
