import { IContactTag } from '@copilot/common/store/models/redux';
import { getCampaignIcon } from '@copilot/common/utils/campaign';
import { CampaignType } from '@copilot/data/responses/interface';
import moment from 'moment';
import { ContactConnectionDisplay } from './helper';
import { isNil } from 'lodash';
import { CheckOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { ITableModuleColumnProps } from '@copilot/common/components/tables/tableModule/types';

const renderLastThreadActivity = (
	lastThreadActivity: ContactConnectionDisplay['lastThreadActivity']
) => moment(lastThreadActivity).format('YYYY-MM-DD');

const renderTags = (value: any, connection: ContactConnectionDisplay) =>
	connection?.tags?.map((tag: IContactTag) => {
		const isTagFiltered = connection.filteredTags?.includes(tag.name);
		return <Tag color={isTagFiltered ? 'blue' : undefined}>{tag.name}</Tag>;
	});

const renderCampaignName = (value: any, connection: ContactConnectionDisplay) => {
	if (connection.campaignType) {
		const campaignIcon = getCampaignIcon(connection.campaignType as CampaignType);
		return (
			<span>
				{campaignIcon} {connection.campaignName}
			</span>
		);
	} else {
		return <span>{connection.campaignName}</span>;
	}
};

/**
 * Render a checkmark if the meeting is booked, otherwise render nothing
 * @param meeting
 */
const renderMeetingBooked = (meeting: ContactConnectionDisplay['meeting']) => {
	if (isNil(meeting) || !meeting.booked) return null;
	return <CheckOutlined />;
};

const nameColumn: ITableModuleColumnProps<ContactConnectionDisplay> = {
	title: 'Name',
	columnKey: 'name',
	dataIndex: 'name',
	width: '200px',
	className: 'column-name clickable',
};

const tagsColumn: ITableModuleColumnProps<ContactConnectionDisplay> = {
	title: 'Tags',
	columnKey: 'tags',
	dataIndex: 'tags',
	render: renderTags,
	width: '200px',
	className: 'column-tags clickable',
};

const titleColumn: ITableModuleColumnProps<ContactConnectionDisplay> = {
	title: 'Title',
	columnKey: 'position',
	dataIndex: 'position',
	width: '200px',
	className: 'column-title clickable',
};

const meetingBookedColumn: ITableModuleColumnProps<ContactConnectionDisplay> = {
	title: 'Meeting',
	columnKey: 'meeting',
	dataIndex: 'meeting',
	width: '95px',
	className: 'column-meeting clickable',
	render: renderMeetingBooked,
};

const companyColumn: ITableModuleColumnProps<ContactConnectionDisplay> = {
	title: 'Company',
	columnKey: 'company',
	dataIndex: 'company',
	width: '200px',
	className: 'column-company clickable',
};

const emailColumn: ITableModuleColumnProps<ContactConnectionDisplay> = {
	title: 'Email',
	columnKey: 'email',
	dataIndex: 'email',
	width: '200px',
	className: 'column-email clickable',
};

const phoneNumberColumn: ITableModuleColumnProps<ContactConnectionDisplay> = {
	title: 'Phone Number',
	columnKey: 'phoneNumber',
	dataIndex: 'phoneNumber',
	width: '200px',
	className: 'column-phoneNumber clickable',
};

const locationColumn: ITableModuleColumnProps<ContactConnectionDisplay> = {
	title: 'Location',
	columnKey: 'location',
	dataIndex: 'location',
	width: '200px',
	className: 'column-location clickable',
};

const lastUpdatedColumn: ITableModuleColumnProps<ContactConnectionDisplay> = {
	title: 'Last Updated',
	columnKey: 'lastThreadActivity',
	dataIndex: 'lastThreadActivity',
	render: renderLastThreadActivity,
	width: '200px',
	className: 'column-lastThreadActivity clickable',
};

const campaignColumn: ITableModuleColumnProps<ContactConnectionDisplay> = {
	title: 'Campaign',
	columnKey: 'campaignName',
	dataIndex: 'campaignName',
	render: renderCampaignName,
	width: '200px',
	className: 'column-campaignName clickable',
};

const teamMemberColumn: ITableModuleColumnProps<ContactConnectionDisplay> = {
	title: 'Team Member',
	columnKey: 'orgMemberName',
	dataIndex: 'orgMemberName',
	width: '200px',
	className: 'column-teamMember clickable',
};

/**
 * The default contact connections table columns.
 */
export const DefaultContactConnectionsTableColumns: ITableModuleColumnProps<ContactConnectionDisplay>[] =
	[
		nameColumn,
		tagsColumn,
		titleColumn,
		companyColumn,
		emailColumn,
		phoneNumberColumn,
		locationColumn,
		lastUpdatedColumn,
		campaignColumn,
	];

/**
 * The contact connections table columns for a user with Teams.
 */
export const TeamsContactConnectionsTableColumns: ITableModuleColumnProps<ContactConnectionDisplay>[] =
	[
		nameColumn,
		meetingBookedColumn,
		tagsColumn,
		titleColumn,
		companyColumn,
		emailColumn,
		phoneNumberColumn,
		locationColumn,
		lastUpdatedColumn,
		teamMemberColumn,
		campaignColumn,
	];
