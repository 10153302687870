import moment, { type Moment } from 'moment';

export const customKey = 'custom';
export const last7DaysOption = { key: 'lastSevenDays', label: 'Last 7 days', value: 7 } as const;
export const last14DaysOption = {
	key: 'lastFourteenDaysKey',
	label: 'Last 14 days',
	value: 14,
} as const;
export const last30DaysOption = {
	key: 'lastThirtyDays',
	label: 'Last 30 days',
	value: 30,
} as const;
export const allTimeOption = { key: 'allTime', label: 'All time', value: Infinity } as const;
export const customDateOption = { key: customKey, label: 'Custom date', value: NaN } as const;

/**
 * Helper to retrieve a UTC midnight time in ms some number of days prior
 * @param daysPrior Number of days prior to today
 */
export function getStartOfDay(daysPrior: number): number {
	return moment().subtract(daysPrior, 'd').startOf('d').valueOf();
}

export type CampaignPerformanceDateOptions =
	| typeof last7DaysOption
	| typeof last14DaysOption
	| typeof last30DaysOption
	| typeof allTimeOption
	| typeof customDateOption;
export const campaignPerformanceDateOptions = [
	last7DaysOption,
	last14DaysOption,
	last30DaysOption,
	allTimeOption,
	customDateOption,
];

/**
 * Checks if the date option given matches the key of all time date option
 */
export const isAllTime = (dateOption: string) => {
	return dateOption == allTimeOption.key;
};

export type SelectedDateFilter = {
	/**
	 * The time range option selected by the user
	 */
	dateOption: CampaignPerformanceDateOptions;
	/**
	 * The date range to filter data
	 */
	selectedDates: {
		/**
		 * The start date filter
		 * If undefined, no start date filter
		 */
		start?: Moment;
		/**
		 * The end date filter
		 * If undefined, no end date filter
		 */
		end?: Moment;
	};
};

export type DateFilters = {
	/**
	 * The start time in ms to filter campaign stat data
	 */
	startMs: number;
	/**
	 * The end time in ms to filter campaign stat data
	 * If undefined, there will be no end filter
	 */
	endMs?: number;
};
