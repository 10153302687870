import { ColumnProps } from 'antd/lib/table';

import BaseColumn from './base';
import { ColumnDefinition } from '../../componentModels/columnDefinition';

export class PercentColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];
	public sorter: ColumnProps<M>['sorter'];
	constructor(c: ColumnDefinition) {
		super(c);

		this.render = (text: string, record: M) => {
			const { properties = [] } = c;
			const percents = (record as any)[properties[0]];
			return `${(percents * 100).toFixed(1)}%`;
		};
		this.sorter = c.sort;
	}
}
