import { withLoading } from '@copilot/common/components/errorAndLoading/errorAndLoading';
import { withAppSettings, withUserInfo } from '@copilot/common/hoc/utils';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { InboxMessageCategorizationType } from '@copilot/common/utils/constant';
import { ComponentType, FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InboxMessageSelectors from '../data/selector';
import { InboxEmailPage } from './email';
import type {
	InboxBatchAction,
	InboxMessageFiltersCallback,
	InboxMessagesPageProps,
} from './types';
import {
	applyTagsToInboxMessages,
	completeRemindedInboxMessages,
	dismissInboxMessages,
	loadInbox,
	markInboxMessagesAsRead,
	migrateConnections,
	migrateConnectionTags,
	resetInboxMessagesFilter,
	snoozeInboxMessages,
	unsnoozeInboxMessages,
	updateDisplayType,
	updateInboxMessagesFilter,
	updateInboxMessagesPagination,
	updateInboxMessagesSearchTerm,
} from '../data/actionCreators';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import InboxLoadingPage from '@copilot/common/pages/inbox/ui/loading';
import { InboxDisplayType, FiltersRequestModel } from '@copilot/data/requests/models';
import { InboxCardViewByReminderPage } from '@copilot/common/pages/inbox/ui/card';
import drawerManager from '@copilot/common/utils/drawerManager';
import { ConnectionMigrationResponse } from '@copilot/data/responses/interface';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { Moment } from 'moment';
import { useSearchParams } from '@copilot/common/hooks/common';
import { useInboxPageCardViewTracking } from '../tracking/cardView';
import { useInboxPageListViewTracking } from '../tracking/emailView';
import { usePermission, validateIsVisible } from '@copilot/common/hooks/permission';
import { PermissionName } from '@copilot/common/hooks/permission/interface';
import {
	getLinkedInProfiles,
	loadLinkedInProfilesAction,
} from '@copilot/common/utils/linkedinProfile/saga';
import { RootState } from '../../../store/reducers/index';
import { isUndefined } from 'lodash';
import isEqual from 'lodash/isEqual';

type UserInfo = Readonly<{
	organizationId: string;
	activeMember: IOrganizationMember;
}>;

type AppSettings = Readonly<{
	inboxType: InboxDisplayType;
}>;

export type InboxMessagesContainerConfig = {
	/** Which view component to use */
	ViewComponent: ComponentType<InboxMessagesPageProps>;
	showSetUpAutomation: boolean;
	isImpersonating: boolean;
};

type InboxMessagesPageContainerProps = Readonly<{
	/** Container's config */
	config: InboxMessagesContainerConfig;
}>;

/**
 * Get which presentational component to use based on categorizationType
 * @param categorizationType
 */
const getViewComponentByCategorizationType = (
	categorizationType: InboxMessageCategorizationType
) => {
	switch (categorizationType) {
		case InboxMessageCategorizationType.Reminder:
			return InboxCardViewByReminderPage;
		default:
			return UtilityFunctions.assertUnreachable(categorizationType);
	}
};

/**
 * Get which presentational component to use based on the selected view and categorizationType
 * @param categorizationType
 */
const getViewComponent = (categorizationType: InboxMessageCategorizationType) => {
	const CardViewPage = getViewComponentByCategorizationType(categorizationType);
	return (view: InboxDisplayType | undefined): FC<InboxMessagesPageProps> => {
		switch (view) {
			case InboxDisplayType.Cards:
				return CardViewPage;
			case InboxDisplayType.Email:
				return InboxEmailPage;
			case undefined:
				return InboxLoadingPage;
			default:
				return UtilityFunctions.assertUnreachable(view);
		}
	};
};

export interface ProspectDrawerSearchParams {
	contact: string;
}

/**
 * Smart Component for Inbox Messages
 * @param props
 */
function InboxMessagesContainer(
	props: InboxMessagesPageContainerProps & { settings: AppSettings } & UserInfo
) {
	const { config, organizationId } = props;
	const { ViewComponent } = config;
	const dispatch = useDispatch();
	const messages = useSelector(InboxMessageSelectors);
	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	const csOrgId = activeAdmin ? organizationId : null;
	const [searchParams] = useSearchParams<ProspectDrawerSearchParams>();
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeImpersonator = useSelector((state: RootState) => state.app.impersonator);
	const [teamMembersPermission] = usePermission(PermissionName.TeamMembers);
	const handleInboxCardViewTracking = useInboxPageCardViewTracking('Inbox Card View');
	const handleInboxListViewTracking = useInboxPageListViewTracking('Inbox List View');
	const [previousContactIds, setPreviousContactIds] = useState<string[]>([]);

	useEffect(() => {
		dispatch(loadInbox(organizationId, !!activeAdmin));
	}, []);

	function handleLoadMore(currPage: number) {
		dispatch(updateInboxMessagesPagination(currPage, 10));
	}

	const handleSearch: InboxMessagesPageProps['onSearch'] = (searchTerm) =>
		dispatch(updateInboxMessagesSearchTerm(searchTerm));

	useEffect(() => {
		const contactIds = messages.data?.map((message) => message.contactId);
		if (!isEqual(contactIds, previousContactIds)) {
			setPreviousContactIds(contactIds ?? []);
			dispatch(loadLinkedInProfilesAction(getLinkedInProfiles, organizationId, contactIds));
		}
	}, [organizationId, messages]);

	const inboxBatchActionCallbacks: InboxBatchAction = useMemo(
		() => ({
			onDismiss: (items: { threadId: string }[]) =>
				dispatch(dismissInboxMessages(items.map((item) => item.threadId))),
			onMarkAsRead: (items: { threadId: string }[], isRead: boolean) =>
				dispatch(
					markInboxMessagesAsRead(
						items.map((item) => item.threadId),
						isRead
					)
				),
			onApplyTags: (connections: { id: string; name: string }[]) => {
				drawerManager.openTagsApplyDrawerSaga({
					connections,
					onApplyTag: (
						connectionIds: string[],
						tags: string[],
						overrideTags: boolean,
						filterRequest?: Partial<FiltersRequestModel>,
						onFinally?: () => void
					) =>
						dispatch(
							applyTagsToInboxMessages(
								connectionIds,
								tags,
								csOrgId,
								overrideTags,
								filterRequest,
								onFinally
							)
						),
				});
			},
			onMigrate: (campaign, _campaignMemberId, connectionIds, _omid) => {
				let resolve: any;
				let reject: any;
				const result: Promise<ConnectionMigrationResponse> = new Promise((res, rej) => {
					resolve = res;
					reject = rej;
				});
				dispatch(
					migrateConnections(connectionIds, campaign.id, {
						onSuccess: (migrationResponse) => resolve(migrationResponse),
						onError: () => reject(),
					})
				);
				return result;
			},
			onMigrateTag: (
				createCampaignTag,
				selectedTags,
				connectionIds,
				_campaign,
				_campaignMemberId
			) => {
				let resolve: any;
				let reject: any;
				const result: Promise<void> = new Promise((res, rej) => {
					resolve = res;
					reject = rej;
				});
				dispatch(
					migrateConnectionTags(createCampaignTag, selectedTags, connectionIds, csOrgId, {
						onSuccess: (migrationResponse) => resolve(migrationResponse),
						onError: () => reject(),
					})
				);
				return result;
			},
		}),
		[csOrgId]
	);

	/**
	 * Handle User action to view specific conversation
	 * @param orgId
	 * @param showSetUpAutomation
	 */
	function handleOpenConversationDetail(orgId: string, showSetUpAutomation: boolean) {
		return (contactId: string, threadIdx: number, orgMemberId: string, threadId: string) => {
			if (isUndefined(activeImpersonator))
				dispatch(markInboxMessagesAsRead([threadId], true));
			drawerManager.openContactDrawer({
				id: contactId,
				threadIdx,
				orgId,
				memberId: orgMemberId,
				threadId,
				isSagaInbox: true,
				showSetUpAutomation,
			});
		};
	}

	/**
	 * Updates the Inbox View to the specified view type
	 * @param {InboxDisplayType} viewType the view type we are switching to
	 */
	function handleViewUpdate(viewType: InboxDisplayType) {
		dispatch(updateDisplayType(viewType, !!activeAdmin));
		switch (viewType) {
			case InboxDisplayType.Cards:
				// click "Card View" while currently in list view
				handleInboxListViewTracking({ buttonClicked: 'Switch to Card View' });
				break;
			case InboxDisplayType.Email:
				// click "List View" while currently in card view
				handleInboxCardViewTracking({ buttonClicked: 'Switch to List View' });
				break;
			default:
				UtilityFunctions.assertUnreachable(viewType);
		}
	}

	const inboxFiltersCallbacks: InboxMessageFiltersCallback = useMemo(
		() => ({
			onCampaignFilterUpdate: (campaigns: FilterDefinition[]) =>
				dispatch(updateInboxMessagesFilter({ campaigns })),
			onTeamMemberFilterUpdate: (teamMembers: FilterDefinition[]) =>
				dispatch(updateInboxMessagesFilter({ teamMembers })),
			onFilterReset: () => dispatch(resetInboxMessagesFilter()),
			onTagFilterUpdate: (tags: FilterDefinition[]) =>
				dispatch(updateInboxMessagesFilter({ tags })),
			onShowSnoozedOnlyUpdate: (showSnoozedOnly: boolean) =>
				dispatch(updateInboxMessagesFilter({ showSnoozedOnly })),
			onNewMessagesOnlyUpdate: (newMessagesOnly: boolean) =>
				dispatch(updateInboxMessagesFilter({ newMessagesOnly })),
			onSentimentUpdate: (sentiment: FilterDefinition[]) =>
				dispatch(updateInboxMessagesFilter({ sentiment })),
			onShowReminderOnlyUpdate: (showReminderOnly: boolean) =>
				dispatch(updateInboxMessagesFilter({ showReminderOnly })),
		}),
		[]
	);

	useEffect(() => {
		if (!searchParams.contact || !activeMember) return;
		drawerManager.openContactDrawer({
			id: searchParams.contact,
			memberId: activeMember.id,
		});
	}, []);

	function onSnooze(threadId: string, dateSnoozeUntil: Moment) {
		dispatch(snoozeInboxMessages(threadId, dateSnoozeUntil.toISOString()));
	}

	function onCompleteReminder(threadId: string) {
		dispatch(completeRemindedInboxMessages(threadId));
	}

	return (
		<ViewComponent
			data={messages.data ?? []}
			counts={messages.counts ?? {}}
			config={config}
			loading={messages.loading}
			onLoadMore={handleLoadMore}
			onSearch={handleSearch}
			hasMore={messages.hasMore}
			batchActionCallbacks={inboxBatchActionCallbacks}
			filters={messages.filter}
			filterUpdateCallbacks={inboxFiltersCallbacks}
			isTeamMembersFilterVisible={validateIsVisible(teamMembersPermission)}
			onSnooze={onSnooze}
			onUnsnooze={(threadId: string) => dispatch(unsnoozeInboxMessages(threadId))}
			onViewUpdate={handleViewUpdate}
			onOpenConversationDetail={handleOpenConversationDetail(
				organizationId,
				config.showSetUpAutomation
			)}
			onMigrate={(connectionId: string, targetCampaignId: string) =>
				dispatch(migrateConnections([connectionId], targetCampaignId))
			}
			onCompleteReminder={onCompleteReminder}
		/>
	);
}

/**
 * Create a sent messages container component with team capability applied
 * @param Component
 */
export const withTeamCapability =
	<T extends UserInfo & { isLoading: boolean } & { settings: AppSettings }>(
		Component: FC<T & { config: InboxMessagesContainerConfig }>
	) =>
	(props: T): JSX.Element => {
		const { viewType } = useSelector(InboxMessageSelectors);
		const showSetUpAutomation = true;

		const config: InboxMessagesContainerConfig = {
			ViewComponent: getViewComponent(InboxMessageCategorizationType.Reminder)(viewType),
			showSetUpAutomation,
			isImpersonating: false,
		};
		return <Component {...props} config={config} />;
	};

/**
 * Create a inbox messages container component with CS team impersonation capability applied
 * @param Component
 */
export const withCSImpersonationTeamCapability =
	<T extends UserInfo & { isLoading: boolean } & { settings: AppSettings }>(
		Component: FC<T & { config: InboxMessagesContainerConfig }>
	) =>
	(props: T): JSX.Element => {
		const { viewType } = useSelector(InboxMessageSelectors);

		const config: InboxMessagesContainerConfig = {
			ViewComponent: getViewComponent(InboxMessageCategorizationType.Reminder)(viewType),
			showSetUpAutomation: true,
			isImpersonating: true,
		};
		return <Component {...props} config={config} />;
	};

export const CSTeamsInboxContainer = withUserInfo<{}>(
	withAppSettings<UserInfo & { isLoading: boolean }>(
		withLoading(withCSImpersonationTeamCapability(InboxMessagesContainer))
	)
);
