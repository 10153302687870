import { Children, Key, useCallback, useContext, useEffect, useMemo } from 'react';
import { Button, Table } from 'antd';
import { TableModuleTableProps, TableContextAny, TableContextColumn } from './types';
import { isTableModuleColumn } from './util';

export function TableModuleTable<T extends Record<string, any>>({
	rowSelection,
	children,
	useLoadMore = false,
	onLoadMore,
	pagination,
	hasMore,
	...rest
}: TableModuleTableProps<T>) {
	const tableContext = useContext(TableContextAny);
	const onRowSelectionChange = useCallback<
		NonNullable<NonNullable<typeof rowSelection>['onChange']>
	>(
		(selectedRowKeys: Key[], selectedRows: T[], info) => {
			rowSelection?.onChange?.(selectedRowKeys, selectedRows, info);
			tableContext.actions.updateSelectedRows(selectedRows);
		},
		[rowSelection?.onChange]
	);
	useEffect(() => {
		const columns: TableContextColumn[] = [];
		if (children) {
			Children.forEach(children, (child) => {
				if (isTableModuleColumn(child)) {
					columns.push({
						title: child.props.title ?? '',
						key: child.props.columnKey,
						dataIndex: child.props.dataIndex,
						hideable: child.props.hideable ?? true,
					});
				}
			});
		}
		tableContext.actions.updateColumnContextList(columns);
	}, [children]);

	const displayedChildren = useMemo(
		() =>
			Children.map(children, (child) =>
				isTableModuleColumn(child) &&
				!tableContext.state.hiddenColumnMap[child.props.columnKey]
					? child
					: null
			),
		[children, tableContext.state.hiddenColumnMap]
	);

	return (
		<div style={{ background: 'transparent' }}>
			<Table
				{...rest}
				rowSelection={
					rowSelection
						? { ...rowSelection, onChange: onRowSelectionChange }
						: rowSelection
				}
				pagination={useLoadMore ? false : pagination}
			>
				{displayedChildren}
			</Table>
			{useLoadMore && hasMore ? (
				<Button onClick={() => onLoadMore?.()} style={{ width: '100%', margin: '16px 0' }}>
					Load More
				</Button>
			) : null}
		</div>
	);
}
