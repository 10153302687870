import useTracking from '@copilot/common/hooks/tracking';
import { ProspectDrawerClicks, ViewProspectDrawerButtonClicks } from './tracking-types';

export interface ProspectDrawerTrackingParams {
	buttonClicked: ProspectDrawerClicks;
	/**
	 * For Set Reminder and Edit Reminder - the time span from now in ms
	 */
	interval?: number;
}

export const useProspectDrawerTracking = (
	componentName: string,
	params: ProspectDrawerTrackingParams | null = null
): ((newParams: ProspectDrawerTrackingParams) => void) =>
	useTracking(componentName, 'prospect_drawer', params);

interface IViewProspectDrawer {
	buttonClicked: ViewProspectDrawerButtonClicks;
}

export const useViewProspectDrawerTracking = (
	componentName: string,
	params: IViewProspectDrawer | null
): ((newParams: IViewProspectDrawer) => void) =>
	useTracking(componentName, 'view_prospect_drawer', params);
