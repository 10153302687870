import React, { useCallback } from 'react';
import { Col, Row, Select, Checkbox, Tag } from 'antd';
import BubbleCheckboxGroup from '@copilot/common/components/forms/common/generics/checkboxes/bubbleGroup';
import { useOnboardingWizardTracking } from './tracking';

interface OccupationDetailsProps {
	recentJobChange: boolean;
	experience: string[];
	headCount: string[];
	exclusions: string[];
	handleExclusionsChange: (exclusions: string[]) => unknown;
	handleRecentJobChangeUpdate: (status: boolean) => unknown;
	handleExperienceChange: (experience: string[]) => unknown;
	handleHeadCountChange: (headCount: string[]) => unknown;
	experienceOptions?: React.ComponentProps<typeof BubbleCheckboxGroup>['options'];
	headCountOptions?: React.ComponentProps<typeof BubbleCheckboxGroup>['options'];
}

const OnboardWizardOccupationDetails: React.FC<OccupationDetailsProps> = (props) => {
	const {
		experienceOptions = [],
		headCountOptions = [],
		recentJobChange = false,
		exclusions = [],
		experience = [],
		headCount = [],
		handleExperienceChange,
		handleHeadCountChange,
		handleRecentJobChangeUpdate,
		handleExclusionsChange,
	} = props;
	useOnboardingWizardTracking('Prospecting Onboard Occupation Details', null);
	const onRecentJobChange = useCallback(
		(event) => {
			handleRecentJobChangeUpdate?.(event.target.checked as boolean);
		},
		[handleRecentJobChangeUpdate]
	);
	const onExclusionsChange = useCallback(
		(value) => {
			handleExclusionsChange?.(value as string[]);
		},
		[handleExclusionsChange]
	);
	return (
		<Row>
			<Col span={24}>
				<Row>
					<Col>
						<Row>
							<h1>Occupation Details</h1>
							<Tag
								color="#ffc32e"
								style={{
									alignSelf: 'center',
									marginBottom: '10px',
									marginLeft: '8px',
								}}
							>
								OPTIONAL
							</Tag>
						</Row>
						<p>
							If you want to narrow your search further, add in more qualifying
							criteria below. We've marked this section as optional. This information
							can help to narrow down your target audience, but isn't essential in all
							campaigns.
						</p>
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
						<h3>Years of Total Work Experience</h3>
					</Col>
				</Row>
				<Row>
					<Col>
						<BubbleCheckboxGroup
							onUpdate={handleExperienceChange}
							selectedValues={experience}
							options={experienceOptions}
						/>
					</Col>
				</Row>
				<br />
				<br />
				<Row>
					<Col>
						<h3>Recent Job Change</h3>
					</Col>
				</Row>
				<Row>
					<Col>
						<Checkbox checked={recentJobChange} onChange={onRecentJobChange}>
							Target people who have recently switched jobs in the last year.
						</Checkbox>
					</Col>
				</Row>
				<br />
				<br />
				<Row>
					<Col>
						<h3>Company Headcount</h3>
					</Col>
				</Row>
				<Row>
					<Col>
						<BubbleCheckboxGroup
							onUpdate={handleHeadCountChange}
							selectedValues={headCount}
							options={headCountOptions}
						/>
					</Col>
				</Row>
				<br />
				<br />
				<Row>
					<Col>
						<h3>Exclusions</h3>
						<p>
							Are there any keywords that you would like to specifically{' '}
							<strong>exclude</strong> from your search?
						</p>
						<p style={{ fontSize: '14px' }}>
							Separate multiple exclusion terms with commas or press Enter.
						</p>
					</Col>
				</Row>
				<Row style={{ display: 'block' }}>
					<Col>
						<Select
							mode="tags"
							style={{ width: '100%' }}
							maxTagCount={25}
							value={exclusions}
							onChange={onExclusionsChange}
							showArrow={false}
							tokenSeparators={[',']}
							placeholder="ex. Edward Jones, Assistant, Recruiter, Recruitment Specialist"
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default OnboardWizardOccupationDetails;
