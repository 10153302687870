import {
	AdminTeamUserPermissions,
	AdvancedIndividualUserPermissions,
	AdvancedTeamUserPermissions,
	BaseTeamUserPermissions,
	IndividualUserPermissions,
} from './orgUser';
import { CSPermissions } from './admin';
import { IPermissions } from '../interface';

const PermissionProfiles: Record<string, IPermissions> = {
	IndividualUser: IndividualUserPermissions,
	AdvancedIndividualUser: AdvancedIndividualUserPermissions,
	BaseTeamUser: BaseTeamUserPermissions,
	AdvancedTeamUser: AdvancedTeamUserPermissions,
	AdminTeamUser: AdminTeamUserPermissions,
	CSUser: CSPermissions,
};

export default PermissionProfiles;
