import {
	getFormattedDate,
	LONG_DATE_FORMAT,
	SHORT_DATE_FORMAT,
} from '@copilot/common/utils/dateFormat';
import { ISubscription } from '@copilot/data/responses/models/billing';
import { Tag, Typography } from 'antd';

const { Text } = Typography;

interface CurrentSubscriptionSummaryProps {
	subscription: ISubscription;
}

/**
 * [Presentational] component for current subscription secondary text.
 */
const CurrentSubscriptionSecondaryText: React.FC<CurrentSubscriptionSummaryProps> = ({
	subscription,
}) => {
	const start = getFormattedDate(subscription.currentPeriodStartDate, LONG_DATE_FORMAT);
	const end = getFormattedDate(subscription.currentPeriodEndDate, LONG_DATE_FORMAT);

	return (
		<>
			{subscription.endedDate && <Tag style={{ marginBottom: 8 }}>Account Cancelled</Tag>}
			{subscription.trialEnd && (
				<Tag style={{ marginBottom: 8 }}>
					{`Trial Ends ${getFormattedDate(subscription.trialEnd, LONG_DATE_FORMAT)}`}
				</Tag>
			)}
			{subscription.cancelAtDate && !subscription.endedDate && (
				<Tag style={{ marginBottom: 8 }}>
					{`Cancels on ${getFormattedDate(subscription.cancelAtDate, SHORT_DATE_FORMAT)}`}
				</Tag>
			)}
			{!subscription.endedDate && !subscription.trialEnd && !subscription.cancelAtDate && (
				<Text style={{ fontSize: 14 }} type="secondary">
					{`${start} - ${end}`}
				</Text>
			)}
		</>
	);
};

export default CurrentSubscriptionSecondaryText;
