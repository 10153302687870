import { useState } from 'react';
import BaseModal, { BaseModalProps } from '../base';
import { Button, Space, Typography } from 'antd';
import styled from 'styled-components';
import { fontSizeMD } from '@copilot/common/constant/commonStyles';

const StyledBaseModal = styled(BaseModal)`
	.${(props) => props.theme['@ant-prefix']}-btn,
		.${(props) => props.theme['@ant-prefix']}-modal-title,
		.${(props) => props.theme['@ant-prefix']}-modal-body {
		font-size: ${fontSizeMD};
	}

	.${(props) => props.theme['@ant-prefix']}-modal-title,
		.${(props) => props.theme['@ant-prefix']}-modal-body {
		padding: 0px 24px;
	}

	.${(props) => props.theme['@ant-prefix']}-modal-header {
		border-bottom: none;
		padding: 16px 0px;
	}

	.${(props) => props.theme['@ant-prefix']}-modal-footer {
		border-top: none;
		text-align: center;
		padding: 16px 16px;
	}

	.${(props) => props.theme['@ant-prefix']}-modal-close-x {
		font-size: ${fontSizeMD};
	}
`;

export type SmartReplyTermsOfUseModalProps = BaseModalProps & {
	/** Callback when the user accept the terms and conditions */
	onSubmit?: () => Promise<void>;
};

/**
 * Smart reply terms and conditions modal
 * @param props
 * @constructor
 */
function SmartReplyTermsOfUseModal(props: SmartReplyTermsOfUseModalProps): JSX.Element {
	const { onCancel, onSubmit } = props;
	const [isSubmitting, setIsSubmitting] = useState(false);
	const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
		setIsSubmitting(true);
		onSubmit?.()
			.then(() => {
				onCancel?.(event);
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	return (
		<StyledBaseModal
			{...props}
			title={'Terms of use'}
			width={469}
			footer={[
				<Button key="submit" type="primary" onClick={handleSubmit} loading={isSubmitting}>
					I acknowledge
				</Button>,
				<Button key="cancel" onClick={onCancel} disabled={isSubmitting}>
					Decline
				</Button>,
			]}
			centered
		>
			<Space direction={'vertical'} size={16}>
				<Typography.Text strong>
					Smart Reply AI is an experimental AI built with OpenAI’s advanced ChatGPT model
					by leveraging our proprietary platform, research data and in house experts.
				</Typography.Text>
				<Typography.Text>
					The messages generated by Smart Reply AI serve as message templates. You are
					required to review all responses generated by Smart Reply AI and you are
					responsible for the final messages sent.
				</Typography.Text>
			</Space>
		</StyledBaseModal>
	);
}

export default SmartReplyTermsOfUseModal;
