import React from 'react';
import { Col, Row, Steps } from 'antd';

interface StepContainerProps {
	steps: {
		key: number;
		label: React.ReactNode;
		description: React.ReactNode;
		status: React.ComponentProps<typeof Steps>['status'];
	}[];
	current: number;
	footer?: React.ReactNode;
	onStepClick?: React.ComponentProps<typeof Steps>['onChange'];
	isAllDescriptionShown?: boolean;
}

const StepContainer: React.FC<StepContainerProps> = (props) => {
	const { current, onStepClick, steps, isAllDescriptionShown = true } = props;
	return (
		<>
			<Row>
				<Col span={5}>
					<Steps
						direction="vertical"
						current={current}
						size="small"
						onChange={onStepClick}
					>
						{steps.map((s, idx) => {
							const title =
								typeof s.label === 'string' ? <h4>{s.label}</h4> : s.label;
							return (
								<Steps.Step
									key={s.key}
									title={title}
									description={
										isAllDescriptionShown || current === idx
											? s.description
											: null
									}
									status={current === idx ? 'process' : s.status}
								/>
							);
						})}
					</Steps>
				</Col>
				<Col span={19}>
					{props.children}
					<br />
				</Col>
			</Row>
			{props.footer && (
				<Row>
					<Col span={20} offset={4}>
						{props.footer}
					</Col>
				</Row>
			)}
		</>
	);
};

export default StepContainer;
