import React, { useMemo, useState, useCallback } from 'react';
import FilterItem from './item';
import { FilterDefinition } from '../componentModels/filterDefinition';
import { PlusOutlined, StopOutlined } from '@ant-design/icons';
import { Tag, Row } from 'antd';
import styled from 'styled-components';

interface IFilterListProps {
	filters: FilterDefinition[];
	updateFilterSelector: (value: FilterDefinition[]) => void;
	isExcludable?: boolean;
}

const MAX_ITEM_SIZE = 5;

const StyledTag = styled(Tag)`
	&& {
		display: block;
		margin-top: 1px;
		margin-right: 0;
		padding-right: 1px;
		cursor: pointer;
	}
`;

const IconCss = `
    && { 
        margin-right: 2px;
        margin-top: 4px;
        float: right;
    }
`;

const addShowMoreOrLessItem = (
	showMore: boolean,
	isExcludeFilter: boolean,
	setShowMore: React.Dispatch<React.SetStateAction<boolean>>
) => (
	<Row style={{ display: 'block' }}>
		<StyledTag
			color={isExcludeFilter ? 'red' : 'blue'}
			visible={showMore}
			onClick={() => setShowMore(!showMore)}
		>
			{showMore ? 'Show More' : 'See Less'}
		</StyledTag>
	</Row>
);

const FilterList: React.FC<IFilterListProps> = (props) => {
	const { filters, updateFilterSelector, isExcludable = false } = props;

	const enabledFilters = useMemo(() => filters.filter((f) => f.isVisible), [filters]);
	const [showMore, setShowMore] = useState<boolean>(true);

	const updateFilterItem = (
		filter: FilterDefinition,
		isExclude?: boolean,
		isVisible?: boolean
	) => {
		const selectedFilterIndex = filters.findIndex(
			(currentFilter) => currentFilter.key === filter.key
		);
		const selectedFilter = filters[selectedFilterIndex];
		const updatedFilter = {
			...selectedFilter,
			isExclude,
			isVisible: isVisible ?? selectedFilter.isVisible,
		};
		const updatedFilters = [
			...filters.slice(0, selectedFilterIndex),
			updatedFilter,
			...filters.slice(selectedFilterIndex + 1),
		];
		updateFilterSelector(updatedFilters);
	};

	const createFilterItem = (filter: FilterDefinition) => (
		<FilterItem
			key={filter.key}
			selectedFilter={filter}
			updateFilterItem={updateFilterItem}
			isExcludable={isExcludable}
			isVisible
		/>
	);

	const convertAllFilter = useCallback(
		(isExcludeFilter: boolean) => {
			const newFilters = [...filters];
			const updatedFilters = newFilters.map((currentFilter) => {
				if (
					enabledFilters.includes(currentFilter) &&
					currentFilter.isExclude == isExcludeFilter
				) {
					const newFilter: FilterDefinition = currentFilter;
					newFilter.isExclude = !currentFilter.isExclude;
					return newFilter;
				} else return currentFilter;
			});
			updateFilterSelector(updatedFilters);
		},
		[filters]
	);

	const addConvertAllItem = (isExcludeFilter: boolean, filterItems: JSX.Element[]) => {
		if (filterItems.length == 0 || !isExcludable) return filterItems;
		const convertAllTag = isExcludeFilter ? (
			<Row style={{ display: 'block' }}>
				<StyledTag color="red" onClick={() => convertAllFilter(isExcludeFilter)}>
					Include All <PlusOutlined css={IconCss} />
				</StyledTag>
			</Row>
		) : (
			<Row style={{ display: 'block' }}>
				<StyledTag color="blue" onClick={() => convertAllFilter(isExcludeFilter)}>
					Exclude All <StopOutlined css={IconCss} />
				</StyledTag>
			</Row>
		);
		return [...filterItems, convertAllTag];
	};

	const appendShowMoreOrLess = (filterItems: JSX.Element[], isExcludeFilter: boolean) => {
		const displayedFilterItems = showMore ? filterItems.slice(0, MAX_ITEM_SIZE) : filterItems;

		const showMoreOrLessItem = addShowMoreOrLessItem(showMore, isExcludeFilter, setShowMore);

		return [...displayedFilterItems, showMoreOrLessItem];
	};

	const addExtraItems = (isExcludeFilter: boolean, filterItems: JSX.Element[]) => {
		let baseFilterItems = filterItems;
		if (baseFilterItems.length == 0) return baseFilterItems;
		if (baseFilterItems.length > MAX_ITEM_SIZE) {
			baseFilterItems = appendShowMoreOrLess(baseFilterItems, isExcludeFilter);
		}
		if (isExcludable) {
			baseFilterItems = addConvertAllItem(isExcludeFilter, baseFilterItems);
		}
		return baseFilterItems;
	};

	const includedItems = useMemo(() => {
		const items = enabledFilters
			.filter((filter) => !filter.isExclude)
			.map((filter) => createFilterItem(filter));
		return addExtraItems(false, items);
	}, [enabledFilters, showMore]);

	const excludedItems = useMemo(() => {
		const items = enabledFilters
			.filter((filter) => filter.isExclude)
			.map((filter) => createFilterItem(filter));
		return addExtraItems(true, items);
	}, [enabledFilters, showMore]);
	return (
		<>
			{includedItems}
			{excludedItems}
		</>
	);
};

export default FilterList;
