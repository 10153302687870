import { UtilityFunctions } from '@copilot/common/utils/common';
import { createContext, PropsWithChildren, useContext, useReducer } from 'react';

export type TextEditorStateType = {
	/**
	 * Text in the text editor
	 */
	text: string;
	/**
	 * Automated step's id if the current text is from an automated step
	 */
	automatedStepId?: string;
	/**
	 * Quick responses template id if the current text is from quick response
	 */
	quickResponseId?: string;
	/**
	 * Whether the text editor is disabled
	 */
	disabled: boolean;
};

/**
 * Available action types for updating textEditor context
 */
export const TextEditorContextActions = {
	setText: 'setText',
	setAutomatedStep: 'setAutomatedStep',
	setQuickResponse: 'setQuickResponse',
	reset: 'reset',
} as const;

/**
 * Actions available for dispatching to update textEditor context
 */
type TextEditorReducerActionType =
	| {
			type: typeof TextEditorContextActions.setText;
			payload: Pick<TextEditorStateType, 'text'>;
	  }
	| {
			type: typeof TextEditorContextActions.setAutomatedStep;
			payload: Pick<TextEditorStateType, 'text' | 'automatedStepId'>;
	  }
	| {
			type: typeof TextEditorContextActions.setQuickResponse;
			payload: Pick<TextEditorStateType, 'text' | 'quickResponseId'>;
	  }
	| {
			type: typeof TextEditorContextActions.reset;
	  };

/**
 * Reducer for updating textEditor context
 * @param state The previous state
 * @param action The action we want to perform
 * @returns The updated state.
 */
function textEditorReducer(
	state: TextEditorStateType,
	action: TextEditorReducerActionType
): TextEditorStateType {
	switch (action.type) {
		case TextEditorContextActions.setText:
			return { ...state, text: action.payload.text };
		case TextEditorContextActions.setAutomatedStep:
			return {
				...state,
				text: action.payload.text,
				automatedStepId: action.payload.automatedStepId,
			};
		case TextEditorContextActions.setQuickResponse:
			return {
				...state,
				text: action.payload.text,
				quickResponseId: action.payload.quickResponseId,
			};
		case TextEditorContextActions.reset:
			return { ...state, text: '', quickResponseId: undefined, automatedStepId: undefined };
		default:
			return UtilityFunctions.assertUnreachable(action);
	}
}

type TextEditorContextType = TextEditorStateType & {
	/**
	 * Update TextEditorContext
	 * @param action The action to dispatch
	 */
	dispatchUpdate: (action: TextEditorReducerActionType) => void;
};

const TextEditorContext = createContext<TextEditorContextType | undefined>(undefined);

/** Context provider for Text Editor Component */
export const TextEditorContextProvider = (
	props: PropsWithChildren<{
		value: string;
		disabled?: boolean;
		automatedStepId?: string;
		quickResponseId?: string;
	}>
): JSX.Element => {
	const { value, disabled = false, children, automatedStepId, quickResponseId } = props;
	const [state, dispatchUpdate] = useReducer(textEditorReducer, {
		text: value,
		disabled,
		automatedStepId,
		quickResponseId,
	});

	return (
		<TextEditorContext.Provider value={{ ...state, disabled, dispatchUpdate }}>
			{children}
		</TextEditorContext.Provider>
	);
};

/** hook to get Text Editor Context */
export const useTextEditorContext = (): TextEditorContextType => {
	const context = useContext(TextEditorContext);
	if (context === undefined) {
		throw new Error('useTextEditorContext must be used within TextEditorContextProvider');
	}
	return context;
};
