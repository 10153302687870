import { createFetchSaga } from '@copilot/common/store/saga';
import { CAMPAIGN_MEMBERS } from '@copilot/common/utils/campaignMember/constant';
import createSagaMiddleware from 'redux-saga';
import { CampaignMemberManager } from '@copilot/data';
import { campaignMemberLoadActions } from '@copilot/common/utils/campaignMember/actionCreators';

export const {
	oneWatcher: watchLoadOneCampaignMember,
	listWatcher: watchLoadCampaignMembers,
	upsertListWatcher: watchUpsertCampaignMembers,
	deleteOneWatcher: watchDeleteOneCampaignMember,
	segmentedLoadListAction: LoadCampaignMembersAction,
	segmentedAddToDirtyListAction: AddCampaignMemberToDirtyListAction,
	addToDirtyListWatcher: watchAddCampaignMembersToDirtyList,
	autoUpdateAction: AutoUpdateCampaignMemberAction,
	autoUpdateWatcher: watchAutoUpdateCampaignMember,
} = createFetchSaga(
	CAMPAIGN_MEMBERS,
	campaignMemberLoadActions,
	CampaignMemberManager.getCampaignMemberLinkedInInformation
);

export const loadCampaignMembersAction = (campaignId: string) =>
	LoadCampaignMembersAction(
		CampaignMemberManager.getCampaignMembersLinkedInInformation,
		campaignId
	)(campaignId);

/**
 * Apply campaign members sagas to the saga middleware
 * @param sagaMiddleware
 */
export const registerCampaignMembersSaga = (
	sagaMiddleware: ReturnType<typeof createSagaMiddleware>
): void => {
	sagaMiddleware.run(watchLoadOneCampaignMember);
	sagaMiddleware.run(watchLoadCampaignMembers);
	sagaMiddleware.run(watchUpsertCampaignMembers);
	sagaMiddleware.run(watchDeleteOneCampaignMember);
	sagaMiddleware.run(watchAutoUpdateCampaignMember);
	sagaMiddleware.run(watchAddCampaignMembersToDirtyList);
};
