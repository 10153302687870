import { Typography } from 'antd';
import { Config } from '@copilot/common/config';
import Popover from '@copilot/common/components/containers/tooltips/popover';
import { InfoCircleOutlined } from '@ant-design/icons';

const popoverFontColor = '#ffffff';
const popoverContent = (
	<div style={{ maxWidth: '321px' }}>
		<Typography.Paragraph style={{ color: popoverFontColor }}>
			This allows you to send InMails to Open Profiles for free. Open profile users are
			Premium users who permit direct messages from anyone on LinkedIn.{' '}
			{Config.openInMailArticleURL && (
				<Typography.Link
					href={Config.openInMailArticleURL}
					target="_blank"
					style={{ color: popoverFontColor }}
					strong
					underline
				>
					Learn more
				</Typography.Link>
			)}
		</Typography.Paragraph>
	</div>
);

/**
 * [Presentation] Reply Prediction information icon
 * @constructor
 */
export function OpenInMailInfoIcon() {
	return (
		<Popover
			content={popoverContent}
			trigger="click"
			color="#1b2635"
			placement={'bottomRight'}
			arrow={{ pointAtCenter: true }}
		>
			<InfoCircleOutlined />
		</Popover>
	);
}
