import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

class AppInsights {
	public reactPlugin = new ReactPlugin();
	private appInsights?: ApplicationInsights;
	public isInitialized = false;
	constructor() {}

	public initialize(history: any, appInsightsKey: string) {
		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: appInsightsKey,
				extensions: [this.reactPlugin],
				extensionConfig: {
					[this.reactPlugin.identifier]: { history },
				},
			},
		});
		this.appInsights.loadAppInsights();
		this.isInitialized = true;
	}

	public get ApplicationInsights(): ApplicationInsights | undefined {
		return this.appInsights;
	}
}

export const appInsights = new AppInsights();
