import { ReactNode, useEffect, useState } from 'react';
import { Alert, Typography } from 'antd';
import {
	WriteSmartReplyCallbackType,
	WriteSmartReplyQueryType,
	WriteSmartReplyErrors,
	EditSmartReplyQueryType,
	CUSTOM_PROMPT_KEY,
} from '@copilot/common/hooks/smartReply/smartReplyTypes';
import {
	OperationStatus,
	OperationStatuses,
	StatusIndicator,
} from '@copilot/common/components/statusIndicator/statusIndicator';
import { delay, isUndefined } from 'lodash';
import { Config } from '@copilot/common/config';
import styled from 'styled-components';
import { useSmartReplyPrompts } from '@copilot/common/hooks/smartReply/prompts';
import { SmartReplyButton } from './smartReplyButton';
import { SmartReplyPromptCategory } from './promptMenu/promptMenuTypes';

const { Text, Link } = Typography;

const StyledAlert = styled(Alert)`
	border: 0;
	padding: 2px 12px;
	background-color: ${(props) => props.theme['@Gray/3']};

	.${(props) => props.theme['@ant-prefix']}-alert-message, svg {
		color: ${(props) => props.theme['@Gray/7']};
	}
`;

type SmartReplyProps = {
	/* Whether the ai assistant is currently active */
	isActive: boolean;
	/* Whether the user can regenerate */
	canRegenerate: boolean;
	/* Callback to retry an AI query */
	onRegenerate: () => void;
	/* Update whether the AI assistant is currently active */
	setIsActive: (update: boolean) => void;
	/* Callback to send AI query */
	onWrite: WriteSmartReplyCallbackType;
	/* Data returned from a ChatGPT query */
	data: WriteSmartReplyQueryType | EditSmartReplyQueryType;
	/* Callback to send AI query */
	onEdit: (prompt: string, message?: string) => Promise<void>;
	/* Callback to handle if the user is out of credit */
	onOutOfCredit: () => void;
	/* when the ai assistance is not available for the user*/
	isAvailable: boolean;
	/* Callback when the ai assistance is not available for the user */
	onNotAvailable: () => void;
	/* Whether or not the parents text area has a message */
	hasMessage: boolean;
	/* Whether AI Assistant is disabled */
	disabled?: boolean;
	/* The prompt category that currently applies to the conversation */
	smartReplyPromptCategory?: SmartReplyPromptCategory;
};

function getOperationStatus(data: WriteSmartReplyQueryType): {
	operationStatus: OperationStatus;
	operationMessage: ReactNode;
} {
	if (data.error) {
		if (data.error.message === WriteSmartReplyErrors.moderation) {
			return {
				operationStatus: OperationStatuses.error,
				operationMessage: (
					<Text>
						The prompt or conversation history violates{' '}
						<Link href={Config.termsAndServicesURL} target="_blank">
							terms of usage
						</Link>
						.
					</Text>
				),
			};
		}

		return {
			operationStatus: OperationStatuses.error,
			operationMessage: <Text>We're not currently able to connect to this service.</Text>,
		};
	} else if (data?.loading) {
		// If data is loading, update status to in progress
		return {
			operationStatus: OperationStatuses.inProgress,
			operationMessage: <Text>AI is writing</Text>,
		};
	} else {
		// If it did not error out nor loading, set operation status to complete
		return {
			operationStatus: OperationStatuses.complete,
			operationMessage: <Text>Done!</Text>,
		};
	}
}

/**
 * Component providing Smart Reply
 * @constructor
 */
export function SmartReply({
	isActive,
	setIsActive,
	onWrite,
	onEdit,
	canRegenerate,
	onRegenerate,
	data,
	onOutOfCredit,
	isAvailable,
	onNotAvailable,
	hasMessage,
	disabled,
	smartReplyPromptCategory,
}: SmartReplyProps) {
	const [isStatusVisible, setStatusVisibility] = useState<boolean>(false);
	const { operationStatus, operationMessage } = getOperationStatus(data);
	const { getByKey: getPromptByKey } = useSmartReplyPrompts();
	useEffect(() => {
		if (data?.loading) setStatusVisibility(true);
	}, [data?.loading]);

	useEffect(() => {
		if (data.error?.message === WriteSmartReplyErrors.outOfCredit) {
			setStatusVisibility(false);
			onOutOfCredit();
		}
	}, [data?.error?.message]);

	useEffect((): (() => void) | void => {
		if (data?.data) {
			const delayId = delay(() => setStatusVisibility(false), 5000);
			return () => clearTimeout(delayId);
		}
	}, [data?.data]);

	/**
	 * Function called when user creates a custom prompt
	 * @param prompt
	 */
	function onSubmitCustomPrompt(prompt: string, isEditing: boolean) {
		setIsActive(false);
		// TODO: When removing ChatCompletion functionality, should use `onGenerate('custom', prompt)`
		isEditing
			? onEdit(CUSTOM_PROMPT_KEY, prompt).catch((err) => console.error(err))
			: onWrite(CUSTOM_PROMPT_KEY, prompt).catch((err) => console.error(err));
	}

	/**
	 * Function called when user selects a preset prompt
	 * @param promptKey
	 */
	function onSelectPreset(promptKey: string) {
		setIsActive(false);
		const prompt = getPromptByKey(promptKey);
		if (isUndefined(prompt)) throw new Error(`Prompt with key ${promptKey} not found`);
		prompt.action == 'EDIT'
			? onEdit(prompt.value).catch((err) => console.error(err))
			: onWrite(prompt.value).catch((err) => console.error(err));
	}

	return (
		<>
			{isStatusVisible && (
				<StatusIndicator status={operationStatus}>{operationMessage}</StatusIndicator>
			)}
			{!isStatusVisible && canRegenerate && (
				<StyledAlert type="info" message="Review the AI response for accuracy." showIcon />
			)}
			{!data?.loading && (
				<SmartReplyButton
					hasMessage={hasMessage}
					isAvailable={isAvailable}
					isActive={isActive}
					setIsActive={setIsActive}
					onNotAvailable={onNotAvailable}
					canRegenerate={canRegenerate}
					onRegenerate={onRegenerate}
					onSubmitCustomPrompt={onSubmitCustomPrompt}
					onSelectPreset={onSelectPreset}
					disabled={disabled}
					smartReplyPromptCategory={smartReplyPromptCategory}
				/>
			)}
		</>
	);
}
