import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Col, Row, Button, Divider, Popconfirm, Tag, Space } from 'antd';
import BasicContainer from '@copilot/common/components/containers/basic';
import {
	useLoadMessageTemplatesByOrg,
	useLoadMessageTemplatesByCampaign,
} from '@copilot/common/hooks/templateMessage';
import { MessageTemplateSelectors } from '@copilot/common/store/selectors/messageTemplate';
import notificationManager from '@copilot/common/utils/notificationManager';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { TemplateManager } from '@copilot/data';
import type { Ref, SessionBoundModel } from 'redux-orm';
import type { Campaign, OrganizationMember } from '@copilot/common/store/models/redux';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';
import { OrganizationType } from '@copilot/common/store/models/const/enum';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { TemplateDescription } from './copy';
import { MessageTemplateActions } from '@copilot/common/store/actions';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { getIsOrgAdminOrAbove } from '@copilot/common/utils/common';
import { complianceMessage } from '../../../constant/strings';
import { useQuickResponseModal } from '@copilot/common/hooks/quickResponse';
import { isUndefined } from 'lodash';
import PillCheckboxGroup, {
	PillCheckboxGroupItem,
} from '@copilot/common/components/checkbox/pill/group';
import Title from '@copilot/common/typography/title';
import {
	QUICK_RESPONSE_CREATE_TRACKING_ID,
	QUICK_RESPONSE_DELETE_TRACKING_ID,
	QUICK_RESPONSE_EDIT_TRACKING_ID,
} from '@copilot/common/tracking/userpilotEventConsts';

type TemplateMessagesProps = React.ComponentProps<typeof BasicContainer> & {
	hideHeader?: boolean;
	user: SessionBoundModel<OrganizationMember>;
	campaignId?: string;
	showCampaigns?: boolean;
};

export default function TemplateMessages({
	showCampaigns = false,
	user,
	hideHeader = false,
	campaignId = '',
	...basicContainerProps
}: TemplateMessagesProps) {
	const activeOrg = useSelector(OrganizationSelectors.getActiveOrganization);
	const isCampaignFilterVisible =
		useFeatureToggle(Features.GroupingClickAndSend) &&
		activeOrg?.orgType === OrganizationType.Enterprise;
	const campaigns = useSelector((state) =>
		CampaignSelectors.getCampaignsByOrgId(state, user.organizationId)
	);
	const [selectedCampaignIds, setSelectedCampaignIds] = useState<string[]>([]);
	useEffect(() => {
		setSelectedCampaignIds(campaigns.map((campaign) => campaign.id));
	}, [campaigns]);
	const campaignFilterOptions = useMemo(
		() =>
			campaigns.map<PillCheckboxGroupItem>((campaign) => ({
				label: campaign.name,
				value: campaign.id,
				isChecked: selectedCampaignIds.includes(campaign.id),
			})),
		[campaigns, selectedCampaignIds]
	);
	const handleCampaignFilterUpdate = useCallback((updatedOptions: PillCheckboxGroupItem[]) => {
		const updatedCampaignIds = updatedOptions.reduce<string[]>((arr, option) => {
			if (option.isChecked) {
				arr.push(option.value);
			}
			return arr;
		}, []);
		setSelectedCampaignIds(updatedCampaignIds);
	}, []);
	if (campaignId) {
		useLoadMessageTemplatesByCampaign(campaignId);
	} else {
		useLoadMessageTemplatesByOrg(user.organizationId);
	}
	const templateMessagesSelector = useCallback(
		(state: DefaultRootState, id: string, ids: string[]) => {
			if (id) {
				return MessageTemplateSelectors.getTemplatesByCampaignId(state, id);
			} else if (isCampaignFilterVisible && ids.length < campaigns.length) {
				return MessageTemplateSelectors.getTemplatesByCampaignIds(state, ids);
			} else {
				return MessageTemplateSelectors.getMessageTemplates(state);
			}
		},
		[isCampaignFilterVisible, campaigns.length]
	);
	const templateMessages = useSelector((state) =>
		templateMessagesSelector(state, campaignId, selectedCampaignIds)
	);
	const storeDispatch = useDispatch();

	const [openCreateTemplateModal, openEditTemplateModal] = useQuickResponseModal();
	const editTemplate = (templateId?: string) => {
		if (isUndefined(templateId)) {
			openCreateTemplateModal();
		} else {
			openEditTemplateModal(templateId);
		}
	};

	const deleteTemplate = (templateId: string) => {
		TemplateManager.deleteTemplateMessage(templateId).then(() => {
			notificationManager.showSuccessNotification({
				message: 'Template deleted',
				description: 'Your template has been deleted',
			});
			storeDispatch(MessageTemplateActions.deleteOne({ id: templateId }));
		});
	};
	const campaignsById = useMemo(
		() =>
			campaigns.reduce<Record<string, Ref<Campaign>>>(
				(obj, campaign) => ({ ...obj, [campaign.id]: campaign }),
				{}
			),
		[campaigns]
	);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const isOrgAdminOrAbove = getIsOrgAdminOrAbove(activeMember?.orgRoles ?? []);

	const messageTemplates = React.useMemo(() => {
		if (!templateMessages) return [];
		return templateMessages.map((m) => (
			<React.Fragment key={m.id}>
				<Divider />
				<Space direction={'vertical'}>
					<Row gutter={16}>
						<Col style={{ fontWeight: 500 }}>
							<Title level={5}>{m.name}</Title>
						</Col>
						<Col>
							{(activeMember?.id === m.orgMemberId || isOrgAdminOrAbove) && (
								<Button
									onClick={() => {
										editTemplate(m.id);
									}}
									size="small"
									data-tracking-id={QUICK_RESPONSE_EDIT_TRACKING_ID}
								>
									Edit
								</Button>
							)}
						</Col>
						<Col>
							{(activeMember?.id === m.orgMemberId || isOrgAdminOrAbove) && (
								<Popconfirm
									title="Are you sure you want to delete this template?"
									onConfirm={() => {
										deleteTemplate(m.id);
									}}
									okText="Yes"
									cancelText="No"
									placement="top"
								>
									<Button
										danger
										size="small"
										data-tracking-id={QUICK_RESPONSE_DELETE_TRACKING_ID}
									>
										Delete
									</Button>
								</Popconfirm>
							)}
						</Col>
					</Row>
					{showCampaigns && m.campaignIds.length > 0 && (
						<Row>
							<Col span={24}>
								<strong>In Campaigns: </strong>
							</Col>
							<Col span={14}>
								{m.campaignIds.map(
									(c) =>
										campaignsById[c] && (
											<Tag key={c}>{campaignsById[c].name}</Tag>
										)
								)}
							</Col>
						</Row>
					)}
					<Row>
						<Col span={14}>{m.message}</Col>
					</Row>
				</Space>
			</React.Fragment>
		));
	}, [templateMessages, showCampaigns, campaignsById]);

	return (
		<BasicContainer bordered={false} {...basicContainerProps}>
			<BasicContainer.Content style={{ padding: '0px' }}>
				<Row>
					<Col span={18}>
						{!hideHeader && <Title level={5}>Quick Responses</Title>}
						<TemplateDescription />
						<Alert message={complianceMessage} type="warning" />
						<br />
						{isCampaignFilterVisible && !campaignId && (
							<PillCheckboxGroup
								initialValues={campaignFilterOptions}
								onChange={handleCampaignFilterUpdate}
								showCheckAll
							/>
						)}
						<br />
						{messageTemplates.length > 2 && (
							<Button
								type="primary"
								onClick={() => editTemplate()}
								data-tracking-id={QUICK_RESPONSE_CREATE_TRACKING_ID}
							>
								Create a new quick response
							</Button>
						)}
					</Col>
				</Row>
				{messageTemplates}
				<br />
				<br />
				<Button
					type="primary"
					onClick={() => editTemplate()}
					data-tracking-id={QUICK_RESPONSE_CREATE_TRACKING_ID}
				>
					Create a new quick response
				</Button>
			</BasicContainer.Content>
		</BasicContainer>
	);
}
