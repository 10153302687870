import { ReactNode } from 'react';
import { Skeleton } from 'antd';
import SearchUrlStatusModule from './statusV2';
import SearchUrlUpdateModule from './update/update';
import { SearchUrlType } from '@copilot/common/utils/constant';
import { MultipleInvite } from '@copilot/data/responses/interface';
import { SEARCH_STATUSES, SearchStatus } from './update/consts';

type SearchUrlSectionProps = {
	searchType: SearchUrlType;
	numPeople?: number;
	updateDate?: string;
	url?: string;
	isInitialSearch: boolean;
	isLoading?: boolean;
	searchStatus?: SearchStatus;
	showVideoLink: boolean;
	saveAndSearchButton: (searchUrl: string) => JSX.Element;
	onVideoLinkClicked: () => void;
	saveDisableWarning?: ReactNode;
	multipleInvite?: MultipleInvite;
	updateMultipleInvite?: (multipleInvite: MultipleInvite) => void;
};

/**
 * Wrapper for the search url input and status components
 * @param param0
 * @returns
 */
export default function SearchUrlSection({
	searchType,
	numPeople = 0,
	updateDate,
	url,
	isInitialSearch,
	searchStatus = SEARCH_STATUSES.NOT_SEARCHING,
	showVideoLink,
	saveAndSearchButton,
	onVideoLinkClicked,
	saveDisableWarning,
	isLoading,
	multipleInvite,
	updateMultipleInvite,
}: SearchUrlSectionProps) {
	return (
		<Skeleton loading={!!isLoading}>
			<SearchUrlStatusModule
				searchType={searchType}
				isInitialSearch={isInitialSearch}
				numPeople={numPeople}
				updateDate={updateDate}
				url={url}
			/>
			<SearchUrlUpdateModule
				searchType={searchType}
				isInitialSearch={isInitialSearch}
				searchStatus={searchStatus}
				showVideoLink={showVideoLink}
				saveAndSearchButton={saveAndSearchButton}
				onVideoLinkClicked={onVideoLinkClicked}
				saveDisableWarning={saveDisableWarning}
				multipleInvite={multipleInvite}
				updateMultipleInvite={updateMultipleInvite}
			/>
		</Skeleton>
	);
}
