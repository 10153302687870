import * as React from 'react';
import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Row, Col, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { InputProps } from 'antd/lib/input';
import { Rule } from 'rc-field-form/lib/interface';
import AntTheme from '@copilot/common/config/antd.theme';
import { isReactElement } from '@copilot/common/utils';

const { TextArea } = Input;
interface DynamicAdditionsChildProps extends InputProps {
	label?: string;
	propertyName: string;
	span?: number;
	validateTrigger?: string | string[];
	rules?: Rule[];
}

class DynamicAdditionsChild extends React.PureComponent<DynamicAdditionsChildProps> {
	render() {
		const {
			label: _label,
			propertyName: _propertyName,
			span: _span,
			validateTrigger: _validateTrigger,
			rules: _rules,
			...rest
		} = this.props;
		return <Input {...rest} />;
	}
}

export const DA_DEFAULT_KEY = 'keys-values';
export interface DynamicAdditionsValue<T> {
	[DA_DEFAULT_KEY]: T[];
}

export interface DynamicAdditionsProps<T> {
	form: FormInstance | null;
	addButtonText: string;
	defaultLabel: string;
	initialValues: T[];
	isAddDisabled?: boolean;
	afterAddChild?: () => void;
	afterRemoveChild?: () => void;
	children?:
		| React.ReactElement<DynamicAdditionsChildProps>[]
		| React.ReactElement<DynamicAdditionsChildProps>;
}

interface DynamicAdditionsState {}

class DynamicAdditions<T = string> extends React.PureComponent<
	DynamicAdditionsProps<T>,
	DynamicAdditionsState
> {
	public static Child: typeof DynamicAdditionsChild = DynamicAdditionsChild;
	static defaultProps = {
		addButtonText: 'Add Another',
		defaultLabel: '',
		initialValues: [],
	};
	constructor(props: DynamicAdditionsProps<T>) {
		super(props);
	}

	componentDidMount = () => {
		if (this.props.form) {
			if (this.props.initialValues.length > 0) {
				this.props.form.setFieldsValue({
					[DA_DEFAULT_KEY]: this.props.initialValues,
				});
			} else if (this.props.children && Array.isArray(this.props.children)) {
				const emptyFormRow: { [k: string]: string } = {};
				React.Children.map(this.props.children, (child) => {
					const key = isReactElement(child) ? child.props.propertyName : '';
					emptyFormRow[key] = '';
				});
				this.props.form.setFieldsValue({
					[DA_DEFAULT_KEY]: [emptyFormRow],
				});
			} else {
				this.props.form.setFieldsValue({
					[DA_DEFAULT_KEY]: [''],
				});
			}
		}
	};

	createFormItems = (
		fields: FormListFieldData[],
		add: (defaultValue?: any, insertIndex?: number | undefined) => void,
		remove: (index: number | number[]) => void
	) =>
		fields.map((field: FormListFieldData, index: number) => {
			let hasLabel = false;
			return (
				<Row key={field.name} gutter={8}>
					<Col style={{ flex: 1 }}>
						<Row gutter={8}>
							{this.props.children &&
								React.Children.map(this.props.children, (c) => {
									const child =
										c as React.ReactElement<DynamicAdditionsChildProps>;
									hasLabel = hasLabel || !!child.props.label;
									return (
										<Col
											key={[
												field.name.toString(),
												child.props.propertyName,
											].join(',')}
											span={child.props.span}
										>
											<Form.Item
												name={[field.name, child.props.propertyName]} // field.name is the row index
												label={
													index === 0
														? child.props.label
														: this.props.defaultLabel
												}
												rules={child.props.rules}
												validateTrigger={child.props.validateTrigger}
												style={{ display: 'block' }}
											>
												{c}
											</Form.Item>
										</Col>
									);
								})}
							{!this.props.children &&
								((this.props.defaultLabel && (hasLabel = true)) || true) && (
									<Col span={24}>
										<Form.Item
											name={[field.name]} // field.name is the row index
											colon={false}
											label={index === 0 ? this.props.defaultLabel : ''}
											style={{ display: 'block' }}
										>
											<TextArea
												onKeyDown={(e) => {
													if (e.key === 'Enter') {
														add();
														if (this.props.afterAddChild)
															this.props.afterAddChild();
													}
												}}
												autoFocus
												autoSize
											/>
										</Form.Item>
									</Col>
								)}
						</Row>
					</Col>
					{fields.length > 1 && (
						<Col style={{ alignSelf: 'flex-end', marginBottom: '28px' }}>
							<Row>
								<Col>
									<DeleteTwoTone
										twoToneColor={`${AntTheme['@error-color']}`}
										onClick={() => {
											remove(field.name);
											if (this.props.afterRemoveChild)
												this.props.afterRemoveChild();
										}}
									/>
								</Col>
							</Row>
						</Col>
					)}
				</Row>
			);
		});

	render() {
		return (
			<Form.List name={DA_DEFAULT_KEY}>
				{(fields, { add, remove }) => (
					<>
						{this.createFormItems(fields, add, remove)}
						<Form.Item>
							<Button
								type="dashed"
								onClick={() => {
									add();
									if (this.props.afterAddChild) this.props.afterAddChild();
								}}
								disabled={this.props.isAddDisabled}
								style={{ width: '100%' }}
								icon={<PlusOutlined />}
							>
								<span>{this.props.addButtonText}</span>
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		);
	}
}

export default DynamicAdditions;
