import { IPaginatedQuery, IQuery } from '../requests/models';
import { ConnectionViewModel } from '@copilot/common/pages/connections/data/types';
import BaseDataManager from './base';
import { PaginatedResponse } from '../responses/interface';

class ConnectionsViewManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get connections by organizationId with filter/sorting
	 * @param {IPaginatedQuery} paginatedQuery query parameters specifying pagination
	 */
	public getConnections = (paginatedQuery: IPaginatedQuery) => {
		const url = `${this.BACKEND_ROUTES.CONNECTIONS_VIEW.Default}/${paginatedQuery.orgId}/${paginatedQuery.page}/${paginatedQuery.pageSize}`;
		const request: IQuery = paginatedQuery.queryModel;
		return this.RequestManager.post<PaginatedResponse<ConnectionViewModel>>(url, request).then(
			(response) => response.data
		);
	};
}

const instance = new ConnectionsViewManager();
export default instance;
