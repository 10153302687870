import React from 'react';
import {
	ExclamationCircleOutlined,
	UserDeleteOutlined,
	WarningOutlined,
	InboxOutlined,
	DashboardOutlined,
	LinkedinOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import { ErrorStatus } from '@copilot/common/store/models/const/enum';
import AntTheme from '@copilot/common/config/antd.theme';

export namespace StatusUtilityFunctions {
	export const getErrorIcon = (error: string, size: string) => {
		const style: React.CSSProperties = { fontSize: size };
		const errorColor = AntTheme['@error-color'];
		switch (error) {
			case ErrorStatus[ErrorStatus.LoggedOut]:
				return <ExclamationCircleOutlined style={style} />;
			case ErrorStatus[ErrorStatus.Blacklisted]:
				return <UserDeleteOutlined style={style} />;
			case ErrorStatus[ErrorStatus.HasFailedMessages]:
				return <InboxOutlined style={{ ...style, color: errorColor }} />;
			case ErrorStatus[ErrorStatus.InvitesThrottled]:
				return (
					<DashboardOutlined
						style={{ ...style, color: errorColor, transform: 'scaleX(-1)' }}
					/>
				);
			case ErrorStatus[ErrorStatus.IsBeingRetried]:
				return <SyncOutlined style={style} />;
			case ErrorStatus[ErrorStatus.MissingSalesNavigator]:
				return <LinkedinOutlined style={style} />;
			default:
				return <WarningOutlined style={{ ...style, color: errorColor }} />;
		}
	};
}
