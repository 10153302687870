import { useEffect, useMemo, useCallback, useState } from 'react';
import OrganizationAllTeamMembersTable from '@copilot/common/components/tables/tableTypes/organizationTeamMember/organizationTeamMemberTable';
import { useFetch } from '@copilot/common/hooks/common';
import { OrganizationManager } from '@copilot/data';
import { OrganizationActions } from '@copilot/common/store/actions/organization';
import { useDispatch, useSelector } from 'react-redux';
import DrawerManager from '@copilot/common/utils/drawerManager';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { generateAllOrgMembers, LoadTeamMembersAction } from './data/saga';
import { getAllOrganizationTeamMembers } from './data/selectors';
import { Button, Space, Typography, Flex, Switch, Input } from 'antd';
import { useOrganizationTeamMemberTracking } from './tracking';
import notificationManager from '@copilot/common/utils/notificationManager';
import { RegisterOrganizationMembersError } from '@copilot/data/responses/interface';
import { IOrganizationTeamMember } from './data/models';
import { ORG_MEMBER_ADD_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';

interface OrganizationDashboardTeamMembersProps {
	organizationId: string;
	initialOrgMemberId?: string;
}

/**
 * [Smart] Component to display the team members in an organization
 * @param organizationId id of the organization
 * @param initialOrgMemberId id of the org member to initially open the drawer for
 */
function OrganizationTeamMemberTabContent(props: OrganizationDashboardTeamMembersProps) {
	const { organizationId, initialOrgMemberId } = props;
	const dispatch = useDispatch();
	const trackButtonClicked = useOrganizationTeamMemberTracking(
		'Organization Dashboard Team Members'
	);
	const [, fetchSeatsAllocated] = useFetch(
		OrganizationManager.getNumberOfEnabledUsers,
		OrganizationActions.updateOrganization,
		(numMembers, orgId) => ({
			id: orgId,
			seatsAllocated: numMembers,
		})
	);

	const [showInactive, setShowInactive] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		if (organizationId) {
			dispatch(LoadTeamMembersAction(generateAllOrgMembers, organizationId));
		}
	}, [organizationId]);

	useEffect(() => {
		if (initialOrgMemberId) {
			DrawerManager.openAdminMemberDrawer({ id: initialOrgMemberId });
		}
	}, [initialOrgMemberId]);

	const organizationMembers = useSelector(getAllOrganizationTeamMembers);

	// Update the active seat count (total active members) every time organizationMember.data changes
	useEffect(() => {
		if (organizationMembers.data) {
			fetchSeatsAllocated(organizationId);
		}
	}, [organizationMembers.data, organizationId]);

	const organization = useSelector(OrganizationSelectors.getOrganization(organizationId));
	const maxNumMembers = organization?.maxSeats ?? 0;
	const seatsAllocated = organization?.seatsAllocated ?? 0;

	const isAddMemberDisabled = useMemo(
		() =>
			organizationMembers.loading ||
			(seatsAllocated >= maxNumMembers && organization?.maxSeats != null),
		[organizationMembers.loading, seatsAllocated, maxNumMembers]
	);

	const handleAddMember = useCallback(() => {
		if (organizationId)
			DrawerManager.openAddMemberDrawer({
				id: organizationId,
				numAddableMembers: maxNumMembers - seatsAllocated,
				onSuccess: (
					registered: IOrganizationTeamMember[],
					errors: RegisterOrganizationMembersError[]
				) => {
					if (registered.length)
						trackButtonClicked('Organization Member Add Team Member');
					errors.forEach((err) =>
						notificationManager.showErrorNotification({
							message: err.message,
							duration: 3,
						})
					);
				},
				blockMultipleOrgs: true,
			});
	}, [organizationId, maxNumMembers, seatsAllocated]);

	function filterMembers(member: IOrganizationTeamMember) {
		if (
			!member.name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
			!member.email?.toLowerCase().includes(searchTerm.toLowerCase())
		) {
			return false;
		}
		if (showInactive) {
			return true;
		}
		return member.isActive;
	}

	return (
		<OrganizationAllTeamMembersTable
			loading={organizationMembers.loading}
			dataSource={organizationMembers.data.filter(filterMembers)}
		>
			<OrganizationAllTeamMembersTable.Header>
				<Flex justify="space-between" gap="small">
					<Space>
						<Typography.Text>Total active members:</Typography.Text>
						<Typography.Text>{seatsAllocated}</Typography.Text>
						<Input
							placeholder="Search by name or email..."
							onChange={(e) => setSearchTerm(e.target.value)}
							style={{ width: '300px' }}
						/>
					</Space>

					<Space>
						<Flex gap="small">
							<label>Show Deactivated</label>
							<Switch
								checked={showInactive}
								onChange={(checked) => setShowInactive(checked)}
							/>
						</Flex>
						<Button
							type="primary"
							onClick={handleAddMember}
							disabled={isAddMemberDisabled}
							style={{ margin: '0 16px' }}
							data-tracking-id={ORG_MEMBER_ADD_TRACKING_ID}
						>
							Add Member
						</Button>
					</Space>
				</Flex>
			</OrganizationAllTeamMembersTable.Header>
		</OrganizationAllTeamMembersTable>
	);
}

export default OrganizationTeamMemberTabContent;
