import { SentResponse } from '../interface';

export class SentModel implements SentResponse {
	threadId: string;
	message: string;
	timestamp: string;
	lastSent: string;
	orgMemberId: string;
	contactId: string;
	dateCreated: string;
	status: any;
	error: any;
	firstName: string;
	lastName: string;
	company: string;
	organizationId: string;
	id: string;
	name: string;
	campaignName: string;
	campaignId: string;
	isContactDeleted: boolean;

	constructor(message: SentResponse) {
		this.id = message.id;
		this.dateCreated = message.dateCreated;
		this.orgMemberId = message.orgMemberId;
		this.contactId = message.contactId;
		this.timestamp = message.timestamp;
		this.lastSent = message.lastSent;
		this.threadId = message.threadId;
		this.message = message.message;
		this.firstName = message.firstName;
		this.name = `${message.firstName} ${message.lastName}`;
		this.lastName = message.lastName;
		this.company = message.company;
		this.organizationId = message.organizationId;
		this.status = message.status;
		this.campaignName = message.campaignName;
		this.campaignId = message.campaignId;
		this.isContactDeleted = message.isContactDeleted;
	}
}
