import { BaseModel } from './base';
import { ConnectionResponse, MeetingDetails } from '@copilot/data/responses/interface';
import { attr } from 'redux-orm';
import { IContactTag } from './contactTag';

interface Fields {
	id: string;
	contactId: string;
	name: string;
	campaignName: string;
	campaignType: string;
	campaignMemberId: string;
	orgMemberId: string;
	connectionId: string;
	campaignId: string;
	orgMemberName: string;
	orgMemberProfileId: string;
	profileId: string;
	lastThreadActivity: string;
	dateCreated: string;
	tags: IContactTag[];
	connections: ConnectionResponse[];
	position: string;
	company: string;
	email: string;
	phoneNumber: string;
	location: string;
	history: { campaignName: string; dates: string[] }[];
	meeting: MeetingDetails;
}

interface InstanceFields {}

export type IContactConnection = Fields & InstanceFields;

export class ContactConnection extends BaseModel<typeof ContactConnection, IContactConnection> {
	static modelName = 'ContactConnection';
	static fields = {
		id: attr(),
		contactId: attr(),
		name: attr(),
		campaignName: attr(),
		campaignType: attr(),
		campaignMemberId: attr(),
		orgMemberId: attr(),
		connectionId: attr(),
		campaignId: attr(),
		orgMemberName: attr(),
		orgMemberProfileId: attr(),
		profileId: attr(),
		lastThreadActivity: attr(),
		dateCreated: attr(),
		tags: attr(),
		connections: attr(),
		position: attr(),
		company: attr(),
		email: attr(),
		location: attr(),
		phoneNumber: attr(),
		history: attr(),
		meeting: attr(),
	};
}
