// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R4EzmxWUKsM6RcWQWyif {\n  margin: 8px 0 16px 0;\n  font-size: 14px;\n  display: block;\n}\ndiv .HvpW7BNOZN0SZJbnbyGK {\n  font-size: 20px;\n  margin-bottom: 0;\n  color: #8c8c8c;\n}\ndiv .Jz6DqeFZi6AMraKsGMb2 {\n  font-size: 20px;\n  margin-bottom: 0;\n  color: #8c8c8c;\n  color: unset;\n}\n.cTfc2N2MSyR3qeQ6afeC {\n  font-size: 14px;\n  margin-left: -16px;\n}\n.C3zCmVT4A23LemvZUBG0 {\n  min-width: 32px;\n}\n.Y4DZrSUOGl5kjusujCi4 {\n  min-width: 32px;\n}\n.Y4DZrSUOGl5kjusujCi4 svg {\n  color: #37c337;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/inbox/ui/component/card/messageCard/messageCard.module.less"],"names":[],"mappings":"AAGA;EACC,oBAAA;EACA,eAAA;EACA,cAAA;AAFD;AAKA;EAEE,eAAA;EACA,gBAAA;EACA,cAAA;AAJF;AAAA;EAEE,eAAA;EACA,gBAAA;EACA,cAAA;EAKA,YAAA;AAHF;AAOA;EACC,eAAA;EACA,kBAAA;AALD;AAQA;EACC,eAAA;AAND;AASA;EAHC,eAAA;AAHD;AAMA;EAGE,cAAA;AANF","sourcesContent":["@import '../../../../../../../styles/antd-variables.less';\n@import '../../../../../../../styles/common-variables.less';\n\n.recipientContainer {\n\tmargin: 8px 0 16px 0;\n\tfont-size: @fontSizeMD;\n\tdisplay: block;\n}\n\ndiv {\n\t.messageContent {\n\t\tfont-size: 20px;\n\t\tmargin-bottom: 0;\n\t\tcolor: @Gray-7;\n\t}\n\n\t.lastMessageContent {\n\t\t.messageContent();\n\t\tcolor: unset;\n\t}\n}\n\n.messageExpandButton {\n\tfont-size: @fontSizeMD;\n\tmargin-left: -16px;\n}\n\n.cardButton {\n\tmin-width: 32px;\n}\n\n.positiveCardButton {\n\t.cardButton();\n\tsvg {\n\t\tcolor: @Success-6;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recipientContainer": "R4EzmxWUKsM6RcWQWyif",
	"messageContent": "HvpW7BNOZN0SZJbnbyGK",
	"lastMessageContent": "Jz6DqeFZi6AMraKsGMb2",
	"messageExpandButton": "cTfc2N2MSyR3qeQ6afeC",
	"cardButton": "C3zCmVT4A23LemvZUBG0",
	"positiveCardButton": "Y4DZrSUOGl5kjusujCi4"
};
export default ___CSS_LOADER_EXPORT___;
