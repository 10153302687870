import { createFetchSelector } from '@copilot/common/store/selectors/fetch';
import { AttentionItemType, ATTENTION_ITEMS } from './constant';
import { AttentionItem, OrgMemberAttentionItemModel } from './models';
import { createSelector } from 'reselect';
import { ModelStateWithDirtyList } from '@copilot/common/store/models/fetch';

/**
 * Attention Item Types that Base Users see
 */
const BASE_USER_ATTENTION_ITEM_TYPES = new Set<AttentionItemType>([
	AttentionItemType.LoggedOutOfLinkedIn,
]);

/**
 * Returns true if the attention item should show up in the given campaign
 * @param {AttentionItem} attentionItem the item to check for
 * @param {string} campaignId the campaign to display attention items in
 */
const isVisibleInCampaign = (attentionItem: AttentionItem, campaignId: string) =>
	!!attentionItem.visibleInCampaigns?.includes(campaignId) ||
	attentionItem.campaignId === campaignId;

/**
 * Returns true if the attention item should be displayed based on the given error type filter
 * @param {AttentionItem} attentionItem the item to check for
 * @param {Set<AttentionItemType>} itemTypeFilter error types to filter by, undefined means no filter applied (i.e. show all errors)
 */
const isItemTypeVisible = (attentionItem: AttentionItem, itemTypeFilter?: Set<AttentionItemType>) =>
	itemTypeFilter === undefined || itemTypeFilter.has(attentionItem.type);

/**
 * Returns all OrgMemberAttentionItems in the redux store
 */
export const attentionItemsSelector =
	createFetchSelector<OrgMemberAttentionItemModel>(ATTENTION_ITEMS);

type DefaultModelStateWithDirtyList = {
	default: ModelStateWithDirtyList<OrgMemberAttentionItemModel>;
};

/**
 * Returns the data object containing all OrgMemberAttentionItems in the redux store
 */
const attentionItemsDataSelector = (state: Record<string, DefaultModelStateWithDirtyList>) =>
	state?.[ATTENTION_ITEMS.toLowerCase()]?.default?.state;

/**
 * Returns all OrgMemberAttentionItems data in the redux store with the attention items filtered by campaign and error type (if specified)
 * @param {string} campaignId the campaign to filter by
 * @param {Set<AttentionItemType>} itemTypeFilter error types to filter by, undefined means no filter applied (i.e. show all errors)
 */
export const createCampaignAttentionItemsSelector = (
	campaignId: string,
	itemTypeFilter?: Set<AttentionItemType>
) =>
	createSelector(attentionItemsDataSelector, (state) =>
		state?.data
			?.map((member) => ({
				...member,
				attentionItems: member.attentionItems.filter(
					(i) =>
						isVisibleInCampaign(i, campaignId) && isItemTypeVisible(i, itemTypeFilter)
				),
			}))
			.filter((orgMemberAttentionItems) => !!orgMemberAttentionItems.attentionItems.length)
	);

/**
 * Returns OrgMemberAttentionItems data in the redux store for base users (can only see logged out error)
 * @param {string} campaignId the campaign to filter by
 */
export const createBaseUserCampaignAttentionItemsSelector = (campaignId: string) =>
	createCampaignAttentionItemsSelector(campaignId, BASE_USER_ATTENTION_ITEM_TYPES);
