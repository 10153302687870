import React, { useCallback, useState } from 'react';
import { Col, Row } from 'antd';
import { CirclePicker } from 'react-color';

const colorOptions = [
	'#f44336',
	'#e91e63',
	'#9c27b0',
	'#673ab7',
	'#3f51b5',
	'#2196f3',
	'#03a9f4',
	'#00bcd4',
	'#009688',
	'#4caf50',
	'#8bc34a',
	'#827717',
	'#01579B',
	'#C43E00',
	'#ff9800',
	'#ff5722',
	'#795548',
	'#607d8b',
];

interface ColorSettingProps {
	settingLabel: string;
	initColor: string;
	handleColorChange: (colorHex: string) => void;
}

/**
 * Color palette for a color setting
 *
 * @param settingLabel label for the color palette
 * @param initColor initial color
 * @param handleColorChange action performed when a new color is clicked
 */
const ColorSetting: React.FC<ColorSettingProps> = (props) => {
	const { settingLabel, initColor, handleColorChange } = props;
	const [color, setColor] = useState<string>(initColor);

	const handleColorChangeComplete = useCallback<
		NonNullable<React.ComponentProps<typeof CirclePicker>['onChangeComplete']>
	>(
		(colorResult) => {
			setColor(colorResult.hex);
			handleColorChange(colorResult.hex);
		},
		[handleColorChange]
	);

	return (
		<>
			<Row>
				<Col>
					<h3>{settingLabel}</h3>
				</Col>
			</Row>
			<Row>
				<Col>
					<CirclePicker
						color={color}
						colors={colorOptions}
						onChangeComplete={handleColorChangeComplete}
					/>
				</Col>
			</Row>
		</>
	);
};

export default ColorSetting;
