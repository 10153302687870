import React, { useCallback } from 'react';
import { SessionBoundModel } from 'redux-orm';
import BaseModal, { BaseModalProps } from '../base';
import InfoCard from '@copilot/common/components/card/infoCard';
import { OrganizationMember } from '@copilot/common/store/models/redux';

interface OrganizationMemberActivationProps extends BaseModalProps {
	member: SessionBoundModel<OrganizationMember, {}>;
	onConfirm: () => void;
}

const OrganizationMemberActivation: React.FC<OrganizationMemberActivationProps> = (props) => {
	const { member, onConfirm, width = 572, ...modalProps } = props;
	if (!member) return null;

	const onCancel = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => props.onCancel?.(e),
		[props.onCancel]
	);

	const onContinue = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			onConfirm();
			onCancel(e);
		},
		[onCancel]
	);

	return (
		<BaseModal {...modalProps} width={width} centered>
			{member.isActive ? (
				<InfoCard>
					<InfoCard.Title> Deactivating {member.name}</InfoCard.Title>
					<InfoCard.Content>
						<ul style={{ paddingLeft: '16px' }}>
							<li>
								<p>
									Once you deactivate the user, their service will be discontinued
								</p>
							</li>
							<li>
								<p>
									{' '}
									You can reactivate the user at any time, and their{' '}
									<strong> data will be restored </strong>
								</p>
							</li>
						</ul>
					</InfoCard.Content>
					<InfoCard.CTA
						showConfirm
						showCancel
						cancelType="primary"
						confirmType="default"
						onCancel={onCancel}
						onConfirm={onContinue}
					/>
				</InfoCard>
			) : (
				<InfoCard>
					<InfoCard.Title> Activating {member.name}</InfoCard.Title>
					<InfoCard.Content>
						<p> Reactivate a user to start generating leads! </p>
						<ul style={{ paddingLeft: '16px' }}>
							<li>
								<div>
									{' '}
									Keep in mind, you will need to:
									<ul style={{ listStyleType: '"- "', margin: '1em auto' }}>
										<li> Resync LinkedIn </li>
										<li> Enable the Team Member Status </li>
									</ul>
								</div>
							</li>
							<li>
								<p> Prorated payment will be reflected immediately </p>
							</li>
						</ul>
					</InfoCard.Content>
					<InfoCard.CTA
						showConfirm
						showCancel
						cancelType="default"
						confirmType="primary"
						onCancel={onCancel}
						onConfirm={onContinue}
					/>
				</InfoCard>
			)}
		</BaseModal>
	);
};

export default OrganizationMemberActivation;
