import { createReduxSingleEntity } from '@copilot/common/store/common/singleEntity';
import { NurtureOnboardModel } from '@copilot/data/responses/models/nurtureOnboard';

const NURTURE_ONBOARD = 'NURTURE_ONBOARD';

export const {
	reducer: nurtureOnboardReducer,
	selector: getNurtureOnboard,
	watcher: watchFetchNurtureOnboard,
	action: fetchNurtureOnboard,
} = createReduxSingleEntity<NurtureOnboardModel>(NURTURE_ONBOARD);
