import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import TagSelector from '@copilot/common/components/forms/common/generics/tagselector';
import withCheckbox from '@copilot/common/components/common/withCheckbox';

export interface TagOptionSelectorValues {
	selectedTags: string[];
	createExtraTags: boolean;
}

export interface TagOptionSelectorProps {
	header: string;
	checkboxLabel: string;
	onSelect: (values: TagOptionSelectorValues) => void;
	description?: string;
	availableTagNames?: string[];
}

const TagSelectorWithCheckbox = withCheckbox(TagSelector);

/**
 * [Prsentational] Tag Selector with options
 * @param {string} header header of modal
 * @param {string} checkboxLabel label for checkbox
 * @callback onSelect called on selecting tag
 * @param {string} [description] description of what the tagging is for
 * @param {string[]} [availableTagNames] available tags to select from
 *
 */

const TagOptionSelector: React.FC<TagOptionSelectorProps> = (props) => {
	const { header, description, onSelect, checkboxLabel = '', availableTagNames = [] } = props;
	const [selectedTags, setSelectedTags] = useState<string[]>([]);
	const [createExtraTags, setCreateExtraTags] = useState<boolean>(false);

	useEffect(() => {
		onSelect({ selectedTags, createExtraTags });
	}, [selectedTags, createExtraTags]);

	return (
		<>
			<Row>
				<Col>
					<strong>{header}</strong>
				</Col>
			</Row>
			<Row>
				<Col>{description}</Col>
			</Row>
			<br />
			<Row>
				<Col>
					<TagSelectorWithCheckbox
						availableTags={availableTagNames}
						selectedTags={selectedTags}
						onTagUpdate={setSelectedTags}
						placeholder="Enter tags"
						onChange={setCreateExtraTags}
						checkboxLabel={checkboxLabel}
					/>
				</Col>
			</Row>
			<br />
		</>
	);
};
export default TagOptionSelector;
