import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Skeleton, Spin } from 'antd';
import HeaderContentLayout from '../layouts/headerContent';
import { useSelector, useDispatch } from 'react-redux';
import { CampaignSelectors } from '@copilot/common/store/selectors/campaign';
import CampaignDashboardHeader, { CampaignMenuOptions } from './header';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { CampaignApprovalStatus, CampaignType } from '@copilot/data/responses/interface';
import CampaignOnboardWizard from './onboard';
import CampaignDashboardContent from './campaignDashboard';
import { CampaignOnboardDetailsActions } from '@copilot/common/store/actions/campaignOnboard';
import { CampaignManager, NurtureOnboardManager } from '@copilot/data';
import { useEffectAsync, useFetch } from '@copilot/common/hooks/common';
import { CampaignOnboardSelectors } from '@copilot/common/store/selectors/campaignOnboard';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';
import { CampaignStatusEnum } from '@copilot/data/requests/models';
import {
	CampaignFields,
	ICampaignMember,
	IOrganizationMember,
} from '@copilot/common/store/models/redux';
import { useHistory, useParams, useLocation } from 'react-router';
import { History, Location } from 'history';
import { CampaignMemberSelectors } from '@copilot/common/store/selectors/campaignMember';
import { Config } from '@copilot/common/config';
import drawerManager from '@copilot/common/utils/drawerManager';
import { loadCampaignMembersAction } from '@copilot/common/utils/campaignMember/saga';
import NewUserOnboardWizard from './newUserOnboard/wizard';

interface CampaignDashboardProps {
	campaign: CampaignFields;
	activeMember: IOrganizationMember;
	isTeamUser?: boolean;
}

/**
 * Browser state type for data cached for redirects
 * Note: need separate states for different tab types to avoid redirect behaviour happening on the wrong tab
 * @param {string} usageLimitsDrawer the type of usageLimitsDrawer that must be opened (nurture/prospecting)
 * @param {string} teamMembersRedirectMemberId id of the campaign member upon redirect to the team members tab (undefined for no redirect / no member selected)
 * @param {string} searchListRedirectMemberId id of the campaign member upon redirect to the search list / nurture list tab (undefined for no redirect / no member selected)
 */
type DashboardLocationState = {
	usageLimitsDrawer?: string;
	teamMembersRedirectMemberId?: string;
	searchListRedirectMemberId?: string;
};

const CampaignDashboard: React.FC<CampaignDashboardProps> = (props) => {
	const { campaign, activeMember, isTeamUser = true } = props;
	const [, fetchOnboardDetails] = useFetch(
		CampaignManager.getCampaignOnboardStateForIndividual,
		CampaignOnboardDetailsActions.loadCampaignOnboardDetails
	);
	const location: Location<DashboardLocationState> = useLocation();
	const [teamMembersRedirectMemberId, setTeamMembersRedirectMemberId] = useState<string>();
	const [searchListRedirectMemberId, setSearchListRedirectMemberId] = useState<string>();
	const clearRedirectedMemberIds = () => {
		setTeamMembersRedirectMemberId(undefined);
		setSearchListRedirectMemberId(undefined);
	};

	const history: History = useHistory();
	const storeDispatch = useDispatch();

	useEffect(() => {
		storeDispatch(loadCampaignMembersAction(campaign.id));
	}, [campaign.id]);

	useEffect(() => {
		if (isTeamUser) return;
		const usageLimitsDrawerType = location?.state?.usageLimitsDrawer;
		if (usageLimitsDrawerType) {
			drawerManager.openUsageLimitsDrawer({ campaignType: usageLimitsDrawerType });
			history.replace(location.pathname, { ...location.state, usageLimitsDrawer: undefined });
		}
	}, [isTeamUser, location, history]);

	useEffect(() => {
		if (
			location?.state?.teamMembersRedirectMemberId ||
			location?.state?.searchListRedirectMemberId
		) {
			setTeamMembersRedirectMemberId(location.state.teamMembersRedirectMemberId);
			setSearchListRedirectMemberId(location.state.searchListRedirectMemberId);
			// state const is required, because its value cannot be written directly in history.replace
			const state = {
				...location.state,
				teamMembersRedirectMemberId: undefined,
				searchListRedirectMemberId: undefined,
			};
			// used different history.replace from usageLimitsDrawer useEffect because specifying path breaks redirect functionality
			//		(redirects to `Summary` tab instead of `Search/Nurture List`)
			history.replace({ ...location, state });
		}
	}, [location, history]);

	useEffect(() => {
		fetchOnboardDetails(campaign.id);
	}, [campaign.id]);
	const onboardDetails = useSelector((state) =>
		CampaignOnboardSelectors.getOnboardDetails(state, campaign.id)
	);

	useEffect(() => {
		CampaignManager.getPauseService(activeMember.id).then((dateObj) => {
			if (dateObj?.unpauseDate) {
				storeDispatch(
					OrganizationMemberActions.updateOrganizationMember({
						id: activeMember.id,
						unpauseDate: dateObj.unpauseDate,
					})
				);
			}
		});
	}, [activeMember.id]);

	const campaignAlert = useMemo(() => {
		if (campaign.approvalStatus === CampaignApprovalStatus.Disconnected) {
			return (
				<Alert
					message="Campaign is deactivated. Please contact your account strategist to reactivate."
					type="warning"
					showIcon
				/>
			);
		}
		if (
			campaign.status === CampaignStatusEnum.Disabled &&
			campaign.approvalStatus === CampaignApprovalStatus.Approved
		) {
			return <Alert message="Campaign is disabled" type="warning" showIcon />;
		}
		if (
			campaign.approvalStatus === CampaignApprovalStatus.Waiting &&
			onboardDetails?.meetingDate
		) {
			return (
				<Alert
					message={`Meeting booked for ${new Date(
						onboardDetails.meetingDate
					).toLocaleDateString('en-US', {
						month: 'short',
						day: 'numeric',
					})}`}
					type="warning"
					showIcon
				/>
			);
		}
		return null;
	}, [campaign.status, campaign.approvalStatus, onboardDetails?.meetingDate]);

	const dashboardContent = useMemo(() => {
		switch (campaign.type) {
			case CampaignType.Prospecting: {
				if (campaign.approvalStatus === CampaignApprovalStatus.Incomplete) {
					return <CampaignOnboardWizard campaignId={campaign.id} />;
				} else
					return (
						<CampaignDashboardContent
							campaign={campaign}
							isTeamUser={isTeamUser}
							key={campaign.id}
							teamMembersRedirectMemberId={teamMembersRedirectMemberId}
							searchListRedirectMemberId={searchListRedirectMemberId}
							clearRedirectedMemberIds={clearRedirectedMemberIds}
						/>
					);
			}
			case CampaignType.Nurture: {
				return (
					<CampaignDashboardContent
						campaign={campaign}
						isTeamUser={isTeamUser}
						key={campaign.id}
						teamMembersRedirectMemberId={teamMembersRedirectMemberId}
						searchListRedirectMemberId={searchListRedirectMemberId}
						clearRedirectedMemberIds={clearRedirectedMemberIds}
					/>
				);
			}
			default:
				throw new Error(`CampaignType: ${campaign.type} not handled`);
		}
	}, [
		campaign,
		activeMember.isOrgAdmin,
		isTeamUser,
		teamMembersRedirectMemberId,
		searchListRedirectMemberId,
	]);

	return (
		<HeaderContentLayout>
			{campaignAlert}
			<HeaderContentLayout.Header>
				<CampaignDashboardHeader campaign={campaign} />
			</HeaderContentLayout.Header>
			{dashboardContent}
		</HeaderContentLayout>
	);
};

interface CampaignRouterParams {
	campaignId: string;
}

type CampaignUserInfo = Readonly<{
	campaign: CampaignFields;
	activeMember: IOrganizationMember;
	activeCampaignMember?: ICampaignMember;
}>;

/**
 * Attaches CampaignUserInfo to the component
 * @param Component
 */
const withCampaignUserInfo =
	<T extends CampaignUserInfo>(Component: React.FC<T>) =>
	(props: T) => {
		const { campaignId } = useParams<CampaignRouterParams>();
		const campaign = useSelector(CampaignSelectors.getCampaign(campaignId));
		const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
		const activeCampaignMember = useSelector(
			CampaignMemberSelectors.getByCampaignAndOrgMember(activeMember?.id ?? '', campaignId)
		);

		return !campaign || !activeMember ? (
			<Spin size="large">
				{' '}
				<Skeleton paragraph={{ rows: 15 }} />{' '}
			</Spin>
		) : (
			<Component
				{...props}
				campaign={campaign}
				activeMember={activeMember}
				activeCampaignMember={activeCampaignMember}
			/>
		);
	};

/**
 * Displays the newUserOnboarding flow for new individual users
 * @param Component
 */
export const withNewUserOnboarding =
	<T extends CampaignDashboardProps & CampaignUserInfo>(Component: React.FC<T>) =>
	(props: T) => {
		const { activeMember, isTeamUser, campaign } = props;

		const showOnboarding = useMemo(
			() =>
				!Config.isAgency &&
				!isTeamUser &&
				!activeMember.isOnboarded &&
				campaign.approvalStatus === CampaignApprovalStatus.Incomplete &&
				campaign.type === CampaignType.Prospecting,
			[isTeamUser, activeMember.isOnboarded, campaign.approvalStatus, campaign.type]
		);

		return showOnboarding ? (
			<HeaderContentLayout style={{ height: '100%' }}>
				<HeaderContentLayout.Header>
					<CampaignDashboardHeader
						campaign={campaign}
						hideUsageLimits
						hideCampaignStatus
						dropdownMenuOptions={[CampaignMenuOptions.Rename]}
					/>
				</HeaderContentLayout.Header>
				<NewUserOnboardWizard campaignId={campaign.id} activeMember={activeMember} />
			</HeaderContentLayout>
		) : (
			<Component {...props} />
		);
	};

/**
 * Redirect from Campaign Dashboard
 * @param Component
 */
export const withIndividualRedirect =
	<T extends CampaignDashboardProps & CampaignUserInfo>(Component: React.FC<T>) =>
	(props: T) => {
		const { campaign, activeCampaignMember } = props;
		const [IsOnboardComplete, fetchIsOnboardComplete] = useFetch(
			NurtureOnboardManager.getIsOnboardingComplete
		);
		const history = useHistory();

		const onboardCampaign = useCallback(() => {
			switch (campaign.type) {
				case CampaignType.Prospecting:
					break;
				case CampaignType.Nurture: {
					if (!Config.isAgency) {
						drawerManager.closeDrawer(); // close any open drawers before redirect
						history.push(`/wizard/nurtureOnboard/${campaign.id}`);
					}
					break;
				}
				default:
					throw new Error(`CampaignType: ${campaign.type} not handled`);
			}
		}, [campaign.id, campaign.type]);

		useEffectAsync(async () => {
			const isOnboardComplete = await fetchIsOnboardComplete(activeCampaignMember?.id ?? '');
			if (isOnboardComplete === false) onboardCampaign();
		}, [activeCampaignMember?.id, onboardCampaign]);

		return (
			<Spin spinning={IsOnboardComplete.isFetching}>
				<Component {...props} />
			</Spin>
		);
	};

export const createCampaignDashboard = withCampaignUserInfo(
	withNewUserOnboarding(CampaignDashboard)
);

export const createCampaignDashboardWithRedirect = (
	withRedirect: <T extends CampaignDashboardProps & CampaignUserInfo>(
		Component: React.FC<T>
	) => (props: T) => JSX.Element
) => withCampaignUserInfo(withNewUserOnboarding(withRedirect(CampaignDashboard)));
