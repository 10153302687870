// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iuNUAY9UVUfVsG2GE0tf .copilot-picker-ranges {\n  display: none;\n}\n.gPBZ0qgSO3zbdqtNcgZ0 {\n  display: flex;\n  column-gap: 8px;\n  padding: 6px 0;\n  width: 100%;\n  justify-content: flex-end;\n  box-sizing: border-box;\n}\n.x2l5uXgI8OOkA9uwPQgL {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/selector/reminder/reminderPicker/reminderPicker.module.less"],"names":[],"mappings":"AAAA;EAEE,aAAA;AAAF;AAIA;EACC,aAAA;EACA,eAAA;EACA,cAAA;EACA,WAAA;EACA,yBAAA;EACA,sBAAA;AAFD;AAKA;EACC,WAAA;AAHD","sourcesContent":[".picker {\n\t:global(.copilot-picker-ranges) {\n\t\tdisplay: none;\n\t}\n}\n\n.pickerButtonSection {\n\tdisplay: flex;\n\tcolumn-gap: 8px;\n\tpadding: 6px 0;\n\twidth: 100%;\n\tjustify-content: flex-end;\n\tbox-sizing: border-box;\n}\n\n.pickerInput {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"picker": "iuNUAY9UVUfVsG2GE0tf",
	"pickerButtonSection": "gPBZ0qgSO3zbdqtNcgZ0",
	"pickerInput": "x2l5uXgI8OOkA9uwPQgL"
};
export default ___CSS_LOADER_EXPORT___;
