import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import Store from './store';
import Router from './router';
import AdminDataInitializer from '@copilot/common/utils/dataInitializer/admin';
import { PermissionsProvider } from '@copilot/common/hooks/permission';
import AppDataInitializer from '@copilot/common/utils/dataInitializer/app';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { appInsights } from '@copilot/common/components/snippets/applicationInsights';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';
import { IntercomProvider } from 'react-use-intercom';
import { AntdTheme, Config, Theme } from '@copilot/common/config';
import AuthenticationProvider from '@copilot/common/utils/authenticationProvider';
import Apollo from '@copilot/common/components/snippets/apollo';

ReactDOM.render(
	<Provider store={Store.ReduxStore}>
		<ConnectedRouter history={Store.History}>
			<ConfigProvider prefixCls="copilot" theme={AntdTheme}>
				<ThemeProvider theme={Theme}>
					<AppInsightsContext.Provider value={appInsights.reactPlugin}>
						{/** TODO [Copilot-4529] Intercom is required due to Nurturelist being shared. We should remove this dependency since CS Dash should not require intercom integration. */}
						<IntercomProvider appId={Config.intercomAppId}>
							<AuthenticationProvider>
								<AppDataInitializer>
									<Apollo>
										<PermissionsProvider>
											<AdminDataInitializer>
												<ErrorBoundary message="Something went wrong. Please refresh the page and try again">
													<Router />
												</ErrorBoundary>
											</AdminDataInitializer>
										</PermissionsProvider>
									</Apollo>
								</AppDataInitializer>
							</AuthenticationProvider>
						</IntercomProvider>
					</AppInsightsContext.Provider>
				</ThemeProvider>
			</ConfigProvider>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root')
);
