import { IOutboxMessage, OutboxMessage } from '../models/redux';
import { EntitiesActionTypes } from './types';

const itemType = OutboxMessage.modelName;
export namespace OutboxActions {
	export const loadOutboxMessages = (
		outboxMessage: Partial<IOutboxMessage> | Partial<IOutboxMessage>[]
	) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: outboxMessage,
	});

	export const deleteOutboxMessage = (
		outboxMessage: Partial<IOutboxMessage> | Partial<IOutboxMessage>[]
	) => ({
		type: EntitiesActionTypes.Delete,
		itemType,
		payload: outboxMessage,
	});

	export const updateOutboxMessage = (
		outboxMessage: Partial<IOutboxMessage> | Partial<IOutboxMessage>[]
	) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: outboxMessage,
	});

	export const resetOutboxMessages = () => ({
		type: EntitiesActionTypes.Reset,
		itemType,
	});
}
