import { InMailStatus } from '../../../../../constant/inMailConstant';

export type InboxTableMessage = {
	id: string;
	tags: { id: string; name: string }[];
	isSnoozed: boolean | undefined;
	lastReceived: {
		message: string;
		timestamp: string;
	};
	threadId: string;
	contactId: string;
	orgMemberId: string;
	isRead: boolean;
	connectionId: string;
	campaignId: string;
	contactName: string;
	campaignType: string;
	campaignName: string;
	inMail?: Readonly<{
		status: InMailStatus;
	}>;
	reminder?: Readonly<{
		hasReminder: boolean;
	}>;
};

export const READ_ROW_CLASSNAME = 'messageRead';
export const UNREAD_ROW_CLASSNAME = 'messageUnread';
