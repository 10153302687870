import { ColumnProps } from 'antd/lib/table';

import BaseColumn from './base';
import {
	ActionDropdownColumnLink,
	ActionDropdownColumnDefinition,
} from '../../componentModels/columnDefinition';
import { EllipsisOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button } from 'antd';

export class DropdownColumn<M> extends BaseColumn<M> {
	public render: ColumnProps<M>['render'];

	constructor(c: ActionDropdownColumnDefinition) {
		super(c);

		this.render = (text: string, record: M) => {
			let hideRecord = false;
			if (this.hideForRecord) {
				hideRecord = this.hideForRecord(record);
			}
			const menu = this.generateMenu(c.links, record);
			return hideRecord ? (
				''
			) : (
				<Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
					<Button icon={<EllipsisOutlined />} />
				</Dropdown>
			);
		};
	}

	private generateMenu = (links: ActionDropdownColumnLink[], record: M) => (
		<Menu>
			{links.map((link) => (
				<Menu.Item key={link.label}>
					<a onClick={() => link.action(record)}>{link.label}</a>
				</Menu.Item>
			))}
		</Menu>
	);
}
