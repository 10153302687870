import BaseDataManager from './base';

/**
 * Response for getting Smart Reply prompts
 */
export type PromptsResponse = Readonly<{
	general: SmartReplyPrompt[];
}>;

/**
 * Smart reply prompt
 */
type SmartReplyPrompt = Readonly<{
	key: string;
	label: string;
	value: string;
}>;

class SmartReplyManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get smart reply's prompts
	 * @returns {Promise<PromptsResponse>}
	 */
	public getPrompts = async (): Promise<PromptsResponse> => {
		const url = this.combineRoute(this.BACKEND_ROUTES.SMART_REPLY.Default, 'prompts');
		const response = await this.RequestManager.get<PromptsResponse>(url);
		return response.data;
	};
}

const instance = new SmartReplyManager();
export default instance;
