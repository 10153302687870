import {
	ApolloProvider,
	createHttpLink,
	ApolloClient,
	InMemoryCache,
	DefaultOptions,
	from,
	ApolloLink,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import getAuthToken from '@copilot/common/utils/authToken';
import { isEmpty } from 'lodash';
import { Config } from '@copilot/common/config';
import { Services } from '@copilot/data/config/services';
import { HttpStatusCode } from '@copilot/common/utils/httpStatusCode';

// disable local cache
const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
};

const endpoint = Config.graphqlURL;
const chatGptEndpoint = Config.chatGptGraphqlURL;
const connectToDevTools = Config.apolloClientDevToolsEnabled;

// http link for GraphQL backend
const defaultHttpLink = createHttpLink({
	uri: endpoint,
});

const chatGptHttpLink = createHttpLink({
	uri: chatGptEndpoint,
});

// retry link for retrying logic when network error occurs
const retryLink = new RetryLink({
	delay: {
		initial: 200,
		max: Infinity,
		jitter: true,
	},
	attempts: {
		max: 3,
		retryIf: (error, _operation) => {
			return !error.response || error.response.status !== HttpStatusCode.TooManyRequests;
		},
	},
});
// adding authorization token to the request header
const contextLink = setContext(async (_, { headers }: { headers: Record<string, string> }) => {
	const token = await getAuthToken();
	return {
		headers: {
			...headers,
			Authorization: `Bearer ${token}`,
		},
	};
});
// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			)
		);
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

// setting up Apollo api link chains by additive composition
// retry -> error -> context -> http
const links = from([
	retryLink,
	errorLink,
	contextLink,
	ApolloLink.split(
		(operation) => operation.getContext().service == Services.ChatGptService,
		chatGptHttpLink,
		defaultHttpLink
	),
]);

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: links,
	name: 'copilotfe',
	defaultOptions,
	connectToDevTools,
});

/**
 * This Apollo component enables the app to use GraphQL backend
 * @param props
 * @returns
 */
const Apollo: React.FC = (props) => {
	const { children } = props;
	const isEnabled = !isEmpty(endpoint) && !isEmpty(chatGptEndpoint);

	if (isEnabled) {
		return <ApolloProvider client={client}>{children}</ApolloProvider>;
	} else {
		return <>{children}</>;
	}
};

export default Apollo;
