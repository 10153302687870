class GoogleTagManager {
	constructor() {}

	public initialize(gtmKey: string) {
		const canUseDOM = !!(
			typeof window !== 'undefined' &&
			window.document &&
			window.document.createElement
		);
		if (!gtmKey || !canUseDOM) return;

		const layerLabel = 'dataLayer';
		window[layerLabel] = window[layerLabel] || [];

		window[layerLabel].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js',
		});
		const j = document.createElement('script');
		j.async = true;
		j.src = `https://www.googletagmanager.com/gtm.js?id=${gtmKey}`;
		document.getElementsByTagName('head')[0].appendChild(j);
	}
}

export const tagManager = new GoogleTagManager();
