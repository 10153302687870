import { Progress } from 'antd';
import React, { ComponentProps, PropsWithChildren } from 'react';

interface IProgressBarProps {
	/**
	 * Progress displayed as percentage out of 100.
	 */
	progress: number;
}

/**
 * Progress bar displaying the percentage completion.
 * @param {number} progress Progress displayed as percentage out of 100
 */
const ProgressBar: React.FC<
	PropsWithChildren<IProgressBarProps> & ComponentProps<typeof Progress>
> = (props) => {
	const { progress, ...rest } = props;
	return <Progress percent={progress} {...rest} />;
};

export default ProgressBar;
