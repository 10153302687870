import { TermsOfUse } from '@copilot/data/responses/interface';
import { useFetch } from '@copilot/common/hooks/common';
import { PermissionActions } from '@copilot/common/store/actions/permissions';
import { FullPermission, validateIsVisible } from '@copilot/common/hooks/permission';
import { useSelector } from 'react-redux';
import { PermissionSelectors } from '@copilot/common/store/selectors/permission';

/**
 * Hook for managing terms of use
 */
export function useTermsOfUse(name: ValueOf<typeof TermsOfUse>, fetcher: () => Promise<void>) {
	const permissions = useSelector(PermissionSelectors.getPermissions);
	const hasAccepted = validateIsVisible(permissions[name]);

	const [{ isFetching }, acceptTermsOfUse] = useFetch(
		fetcher,
		PermissionActions.setPermissions,
		() => ({ [name]: FullPermission })
	);
	return { hasAccepted, acceptTermsOfUse, isLoading: isFetching };
}
