import BaseDataManager from './base';
import { StatsResponse, StatsOptionsListResponse, Stats } from '../responses/interface';

class StatsManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get stats for a particular organization
	 * @param {string} organizationId Id of the organization
	 * @param {Date} startDate Starting date of range
	 * @param {Date} endDate Ending date of range
	 */
	public getStatsForOrganization = (
		organizationId: string,
		startDate: Date = new Date(0),
		endDate: Date = new Date()
	) => {
		if (!organizationId) return Promise.reject('Organization not provided');
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION.Default,
			organizationId,
			'stats'
		);
		const params = { startDate, endDate };
		return this.RequestManager.get<StatsResponse>(url, { params }).then(
			(response) => response.data
		);
	};

	/**
	 * Get stats for all campaigns for a particular organization
	 * @param {string} organizationId Id of the organization
	 * @param {Date} startDate Starting date of range
	 * @param {Date} endDate Ending date of range
	 */
	public getAllCampaignStatsForOrganization = (
		organizationId: string,
		startDate: Date = new Date(0),
		endDate: Date = new Date()
	) => {
		if (!organizationId) return Promise.reject('Organization not provided');
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION.Default,
			organizationId,
			'campaigns',
			'stats'
		);
		const params = { startDate, endDate };
		return this.RequestManager.get<{ [k: string]: StatsResponse }>(url, { params }).then(
			(response) => response.data
		);
	};

	/**
	 * Get stat filter options for an organization
	 * @param {string} organizationId Id of the organization
	 */
	public getOrgStatFilters = (organizationId: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION.Default,
			organizationId,
			'stats',
			'list'
		);
		return this.RequestManager.get<StatsOptionsListResponse>(url).then(
			(response) => response.data
		);
	};

	/**
	 * Get stats for a particular campaign
	 * @param {string} campaignId The id of the campaign we want to get stats for
	 * @param {Date} startDate Starting date of stats
	 * @param {Date} endDate Ending date of stats
	 */
	public getStatsForCampaign = (
		campaignId: string,
		startDate: Date = new Date(0),
		endDate: Date = new Date()
	) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.CAMPAIGN.Default, campaignId, 'stats');
		return this.RequestManager.get<StatsResponse>(url, { params: { startDate, endDate } }).then(
			(r) => r.data
		);
	};

	/**
	 * Get all campaign member stats for a particular campaign
	 * @param {string} campaignId The id of the campaign we want to get stats for
	 * @param {Date} startDate Starting date of stats
	 * @param {Date} endDate Ending date of stats
	 */
	public getStatsForCampaignMembers = (
		campaignId: string,
		startDate: Date = new Date(0),
		endDate: Date = new Date()
	) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.Default,
			campaignId,
			'members',
			'stats'
		);
		return this.RequestManager.get<{ [k: string]: Stats[] }>(url, {
			params: { startDate, endDate },
		}).then((r) => r.data);
	};

	/**
	 * Grabs all the stat filters on the campaign level
	 * @param {string} campaignId The id of the organization we want to get the filters for
	 */
	public getCampaignStatFilters = (campaignId: string) => {
		const url = this.combineRoute(
			this.BACKEND_ROUTES.CAMPAIGN.Default,
			campaignId,
			'stats',
			'list'
		);
		return this.RequestManager.get<StatsOptionsListResponse>(url).then((r) => r.data);
	};

	/**
	 * Get stats of all campaigns for a particular organization
	 * @param {string} organizationId Id of the organization
	 * @param {Date} startDate Starting date of range
	 * @param {Date} endDate Ending date of range
	 */
	public getOrganizationStatsByCampaign = (
		organizationId: string,
		startDate: Date = new Date(0),
		endDate: Date = new Date()
	) => {
		if (!organizationId) return Promise.reject('Organization not provided');
		const url = this.combineRoute(
			this.BACKEND_ROUTES.ORGANIZATION.Default,
			organizationId,
			'campaigns',
			'stats'
		);
		const params = { startDate, endDate };
		return this.RequestManager.get<{ [k: string]: Stats[] }>(url, { params }).then(
			(response) => response.data
		);
	};
}

const instance = new StatsManager();
export default instance;
