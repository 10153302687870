import BaseDataManager from './base';

class FeatureManager extends BaseDataManager {
	constructor() {
		super();
	}

	/**
	 * Get all status of feature toggles
	 */
	public getFeatureToggles = async () => {
		const url = this.combineRoute(this.BACKEND_ROUTES.SETTINGS.FE, 'features');
		const featureToggles = await this.RequestManager.get<TypedObject<string>>(url).then(
			(response) => response.data
		);
		return featureToggles;
	};
}

const instance = new FeatureManager();
export default instance;
