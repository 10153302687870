import { ReactElement } from 'react';
import { Button, Form, Space } from 'antd';
import BaseDrawer from '../../base/index';
import drawerManager from '@copilot/common/utils/drawerManager/index';
import NotificationManager from '@copilot/common/utils/notificationManager/index';
import { UserDetail } from '@copilot/common/pages/accountDetailsPage/types';
import UserEditForm from './userEditForm';
import {
	IUpdateUserGivenNamesInput,
	useUpdateUserGivenNamesMutation,
} from '@copilot/data/graphql/_generated';
import { isPlainJsonObject } from '@copilot/common/utils';
import isString from 'lodash/isString';
import isError from 'lodash/isError';
import DrawerHeader from '@copilot/common/components/drawer/wrappers/accountDashboard/drawerHeader';

/* The width of the team user edit drawer */
const DRAWER_WIDTH = 495;

/**
 * Footer for the team user edit drawer
 */
function UserEditDrawerFooter({
	onSave,
	onCancel,
	isLoading,
	isInvalid,
}: {
	onCancel: () => void;
	onSave: () => void;
	isLoading: boolean;
	isInvalid: boolean;
}): ReactElement {
	return (
		<footer style={{ textAlign: 'right' }}>
			<Space>
				<Button type="default" onClick={onCancel}>
					Cancel
				</Button>
				<Button type="primary" onClick={onSave} disabled={isInvalid} loading={isLoading}>
					Save
				</Button>
			</Space>
		</footer>
	);
}

/**
 * Helper function to ensure type of object is ready for GraphQL endpoint
 */
function isUpdateUserGivenNamesInput(obj: unknown): obj is IUpdateUserGivenNamesInput {
	return (
		isPlainJsonObject(obj) &&
		isString(obj.id) &&
		isString(obj.firstName) &&
		isString(obj.lastName)
	);
}

/**
 * Props for Team User Edit drawer
 */
export type UserEditDrawerProps = {
	/**
	 * Callback function for when a user is edited
	 * The id is the id of the user that was edited
	 */
	onUserEdited?: (id: string) => unknown;
	/**
	 * The user being edited
	 */
	user: UserDetail;
};

/**
 * Smart Component for the user edit Drawer
 */
export default function UserEditDrawer({ user, onUserEdited }: UserEditDrawerProps): ReactElement {
	const [updateGivenNames, { loading: isLoading }] = useUpdateUserGivenNamesMutation({
		refetchQueries: ['account'],
	});
	const [form] = Form.useForm<IUpdateUserGivenNamesInput>();
	async function onFormFinish(values: IUpdateUserGivenNamesInput) {
		const input = { ...values, id: user.id }; // append the id to the input
		if (!isUpdateUserGivenNamesInput(input))
			throw new Error('Submitted form without completing');

		try {
			const result = await updateGivenNames({ variables: { input } });

			if (result.data?.updateUserGivenNames.id) {
				if (onUserEdited) onUserEdited(result.data.updateUserGivenNames.id);
				NotificationManager.showSuccessNotification({
					message: 'User successfully updated',
				});
				drawerManager.closeDrawer();
			} else {
				//Some error from the server
				NotificationManager.showErrorNotification({
					message: 'Processing Error updating team user',
					description: result.errors,
				});
			}
		} catch (error) {
			NotificationManager.showErrorNotification({
				message: 'Server Error updating team user',
				description: isError(error) ? error.message : undefined,
			});
		}
	}

	return (
		<BaseDrawer
			width={DRAWER_WIDTH}
			title={DrawerHeader({ title: 'Edit Team User Settings' })}
			footer={UserEditDrawerFooter({
				onSave: form.submit,
				onCancel: drawerManager.closeDrawer,
				isLoading: isLoading,
				isInvalid: false,
			})}
			onClose={drawerManager.closeDrawer}
		>
			<UserEditForm initialValues={user} form={form} onFinish={(v) => void onFormFinish(v)} />
		</BaseDrawer>
	);
}
