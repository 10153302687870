import { Children, FC, PropsWithChildren, ReactNode } from 'react';
import { TextEditorContextProvider } from './context';
import { TextEditorBody } from './body';
import styled, { useTheme } from 'styled-components';
import { Card } from 'antd';
import { isReactElement, throwError } from '@copilot/common/utils';
import { TextEditorButton } from './button';
import { TextEditorDropdown } from './dropdown';

interface TextEditorProps {
	/** Default string value for the text input */
	value: string;
	/** Callback for submitting */
	onSubmit?: (value: string) => void;
	/** Whether the text editor is disabled */
	disabled?: boolean;
	automatedStepId?: string;
	quickResponseId?: string;
}

interface ITextEditorComposition {
	Body: typeof TextEditorBody;
	Header: typeof TextEditorHeader;
	Button: typeof TextEditorButton;
	Footer: typeof TextEditorFooter;
	Dropdown: typeof TextEditorDropdown;
}

/**
 * Text Editor Component
 * Form component to selectively handle message template selection, automation, and submitting/scheduling messages
 * using a template radio selector or template dropdown
 */
export const TextEditor: FC<TextEditorProps> & ITextEditorComposition = ({
	value,
	disabled,
	children,
	automatedStepId,
	quickResponseId,
}) => {
	const { header, body, footer } = getTextEditorChildren(children);
	const theme = useTheme();

	return (
		<TextEditorContextProvider
			value={value}
			disabled={disabled}
			automatedStepId={automatedStepId}
			quickResponseId={quickResponseId}
		>
			<StyledCard size="small" title={header}>
				{body}
			</StyledCard>
			<div style={{ padding: `${theme['@spacer-size-xs']} 0px` }}>{footer}</div>
		</TextEditorContextProvider>
	);
};

/**
 * Header component for the Text Editor
 * @param props
 * @returns
 */
function TextEditorHeader(props: PropsWithChildren<{}>): JSX.Element {
	return <div style={{ width: '100%', display: 'flex', columnGap: '8px' }}>{props.children}</div>;
}

TextEditor.Header = TextEditorHeader;

/**
 * Footer component for the Text Editor
 * @param props
 * @returns
 */
function TextEditorFooter(props: PropsWithChildren<{}>): JSX.Element {
	return <>{props.children}</>;
}

TextEditor.Footer = TextEditorFooter;

/**
 * Categorize the children of the TextEditor component
 * @param children
 * @returns
 */
function getTextEditorChildren(children: ReactNode): {
	header: ReactNode;
	body: ReactNode;
	footer: ReactNode;
} {
	let header: ReactNode = null;
	let body: ReactNode = null;
	let footer: ReactNode = null;
	Children.forEach(children, (child) => {
		if (isReactElement(child)) {
			switch (child.type) {
				case TextEditor.Header:
					header = child;
					break;
				case TextEditor.Body:
					body = child;
					break;
				case TextEditor.Footer:
					footer = child;
					break;
				default:
					throwError(`Invalid child component ${child.type} provided to TextEditor`);
			}
		}
	});
	return { header, body, footer };
}

TextEditor.Body = TextEditorBody;
TextEditor.Button = TextEditorButton;
TextEditor.Dropdown = TextEditorDropdown;

const StyledCard = styled(Card)`
	.${(props) => props.theme['@ant-prefix']}-card-body {
		padding: 0px;
		position: relative;
	}
`;
