import Text from 'antd/lib/typography/Text';
import { LinkedInSentMessageError, OrganizationType } from './enum';
import styled from 'styled-components';

const ErrorText = styled(Text)`
	display: block;
	color: rgba(0, 0, 0, 0.85);
	:not(:last-child) {
		margin-bottom: 10px;
	}
`;

/**
 * An error that came up when trying to send a message
 */
export interface ISendError {
	name: string;
	message: React.ReactNode;
}

export const SendErrorMap: {
	[key: string]: ISendError;
} = {
	[LinkedInSentMessageError.StateChanged]: {
		name: 'New message while in Outbox',
		message: () => (
			<>
				<ErrorText>There’s a new message in this conversation. </ErrorText>
				<ErrorText>
					Click “Retry” to send your original message, or “Dismiss” to clear your original
					message and send a new one.
				</ErrorText>
			</>
		),
	},
	[LinkedInSentMessageError.Disconnected]: {
		name: 'No longer a connection',
		message: () => (
			<>
				<ErrorText>This recipient is no longer a first-degree connection.</ErrorText>
				<ErrorText>
					Click “Dismiss” and send a reply directly via LinkedIn to continue messaging.
				</ErrorText>
			</>
		),
	},
	[LinkedInSentMessageError.AccountClosed]: {
		name: 'No LinkedIn profile',
		message: () => (
			<>
				<ErrorText>This LinkedIn profile no longer exists.</ErrorText>
				<ErrorText>Click “Dismiss” to clear this message.</ErrorText>
			</>
		),
	},
	[LinkedInSentMessageError.LoggedOut]: {
		name: 'LinkedIn account disconnected',
		message: () => (
			<>
				<ErrorText>Your LinkedIn account is disconnected.</ErrorText>
				<ErrorText>
					Go to your “Settings” page to reconnect it, then click “Retry”.
				</ErrorText>
			</>
		),
	},
	[LinkedInSentMessageError.RequireApproval]: {
		name: 'Need Review',
		message: () => (
			<>
				<ErrorText>
					Please review your outgoing messages to ensure the information you are sending
					is still accurate.
				</ErrorText>
				<ErrorText>Then click “retry” to send.</ErrorText>
			</>
		),
	},
	Default: {
		name: 'LinkedIn error',
		message: () => (
			<>
				<ErrorText>Failed to send due to Linkedin limitations.</ErrorText>
				<ErrorText>
					Click “Retry.” If the problem persists, reply directly via LinkedIn.
				</ErrorText>
			</>
		),
	},
};

// Sequence Node Ids and Templates
export const prospectingInviteNodeId = 'LINKEDIN_PROSPECTING_INVITE_NODE';
export const prospectingTerminateNodeId = 'TERMINATE_NODE';
export const prospectingInviteNodeTemplate = {
	id: '',
	name: 'Invite Step',
	triggers: [],
	actions: [],
	nextNodeIds: [],
};

export const CSCustomerTypeMap = {
	[OrganizationType.Enterprise]: 'teams',
	[OrganizationType.Individual]: 'customer',
};
