import Peer from 'simple-peer';
import { PeerConnection } from './peer';
import { CompleteSignal, SignalingServerHandler, UpdateSignal } from './signalrclient';

export class SignalRPeer {
	public peerConn: PeerConnection;
	private signalRConn: SignalingServerHandler;

	constructor(
		url: string,
		options: Peer.Options,
		onUpdate: (completeSignal: UpdateSignal) => unknown,
		onComplete: (completeSignal: CompleteSignal) => unknown,
		stream?: any
	) {
		this.signalRConn = new SignalingServerHandler(url, options, onUpdate, onComplete, stream);
		this.peerConn = this.signalRConn.getPeer();
	}
	public async initConn() {
		await this.signalRConn.initialize();
	}

	public initiateConnection(roomName: string) {
		this.peerConn.startInitiator(roomName);
	}

	public joinConnection(roomName: string) {
		//this.peerConn.startPeer(roomName);
		this.signalRConn.createOrJoinRoom(roomName);
	}

	public close() {
		this.peerConn.terminateSession();
	}

	public send(data: any) {
		this.peerConn.sendData(data);
	}

	public on(event: string, callback: any) {
		this.peerConn.setEventCallback(event, callback);
	}
}
