import { EntitiesActionTypes } from './types';
import { CSMember, ICSMember } from '../models/redux';

const itemType = CSMember.modelName;

export namespace CSMemberActions {
	export const load = (client: Partial<ICSMember> | Partial<ICSMember>[]) => ({
		type: EntitiesActionTypes.Create,
		itemType,
		payload: client,
	});

	export const update = (client: Partial<ICSMember>) => ({
		type: EntitiesActionTypes.Update,
		itemType,
		payload: client,
	});
}
