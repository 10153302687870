import { withNotification } from '@copilot/common/hoc/utils';
import { ContactManager } from '../../../../../../../core/data/managers';

/**
 * Success and error notifications for updating a contact
 */
export const ContactUpdateNotificationSuccess = { message: 'Contact updated' };
export const ContactUpdateNotificationError = {
	message: 'Failed to update contact',
	description: 'Please try again.',
};

/**
 * Update a contact and show a notification on success or error
 */
export const UpdateContactWithNotification = withNotification(
	ContactManager.updateContact,
	ContactUpdateNotificationSuccess,
	ContactUpdateNotificationError
);
